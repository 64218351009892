import React from 'react'
import { Chart } from "react-google-charts";

const CbbfPie = ({TableValue}) => {

    const data = [
        ["Units", "Count"],
        ["Camps",TableValue?.total_camp],
        ["Household", TableValue?.total_CBBF_survey],
        ["Population Screen", TableValue?.total_patients_screened],
     
      ];
      
 const options = {
    slices: {
        0: { color: "#92D050" },
        1: { color: "#00B0F0" },
        2: { color: "#FFDC00" },
        // 3: { color: "#00B0F0" },
        // 4: { color: "OliveDrab" },
      },
        // title: "Cbbf Activities",
        is3D: true,legend: { position: "bottom" },
      };
      
    return (
        <div>
            <Chart
                chartType="BarChart"
                data={data}
                options={options}
                width={"40vw"}
                height={"400px"}
            />
        </div>
    )
}

export default CbbfPie