import React, { useEffect, useState } from "react";
import { HybridInput } from "./hybrid_inputs";
import { Button, Typography } from "@mui/material";
import { useAxios } from "../hooks/useAxios";
import { Form, Formik } from "formik";
import { CampSchema } from "../validations/userSchema";
import Stack from '@mui/material/Stack';
import "./Style.css";



export default function PatientForm({ addPatientToggle }) {



  const { axios } = useAxios();
  const [stateList, setStateList] = useState([
    {
      _id: "",
      state_name: "No data found",
    },
  ]);
  const [id, setId] = useState("");
  const [districtList, setDistrictList] = useState([
    {
      _id: "",
      district_name: "No data found",
    },
  ]);
  const [mandalList, setMandalList] = useState([
    {
      _id: "",
      mandal_name: "No data found",
    },
  ]);
  const [villageList, setVillageList] = useState([
    {
      _id: "23409232343234234",
      village_name: "vil A",
    },
    {
      _id: "23409232343234234",
      village_name: "vil C",
    },
    {
      _id: "23409232343234234",
      village_name: "vil B",
    },
  ]);
  const [hospitalList, setHospitalList] = useState([
    {
      _id: "23409232343234234",
      hospital_name: "hos A",
    },
    {
      _id: "23409232343234234",
      hospital_name: "hos C",
    },
    {
      _id: "23409232343234234",
      hospital_name: "hos B",
    },
  ]);

  // const handleChange = (newValue) => {

  // };
  const initialState = {
    camp_number: "",
    camp_place: "",
    camp_pin_code: "",
    camp_date: null,
    camp_from_date: null,
    // camp_from_date: null,
    camp_to_date: null,
    state: "",
    district: "",
    mandal: "",
    village: "",
    hospital: "",
    field_worker: "",
    co_sponsor: "",
    household_target: "",
    yellow_card_target: "",
    individual_screening_target: "",
  };


  const [mobileNumber, setMobileNumber] = useState([])
  const [campDetails, setCampDetails] = useState(initialState);
  useEffect(() => {
    StateList();

  }, []);
  useEffect(() => { }, [id]);
  
  const datePicker = (value) => {
    

    setCampDetails((prevState) => ({ ...prevState, camp_to_date: value }));
  }; const handleChange = event => {
    const pattern = /^\d{10}$/;
    const input = event.target.value;
    if (pattern.test(input)) {
      setMobileNumber(input);
    }
  };

  const StateList = () => {
    axios.post("statesDropDown").then((data) => {
     
      setStateList(data.data.result);
    });
  };
  const clearFields = (setFieldValue, fields) => {
    
    fields.forEach((field) => {
      setFieldValue(field, "");
    });

    const createNullObject = (key) => {
      let object = {
        [key]: "No data found!",
        _id: "",
      };
    };
 
  };
  const DistrictList = async (value, setFieldValue) => {
 

    let fields = ["district"];
    clearFields(setFieldValue, fields);
  
    let state = {
      state_id: value,
    };
   
    await axios.post("districtDropDown", state).then((res) => {
      
      setDistrictList(res.data.result);
    });
  };
  
  const MandalList = async (value, setFieldValue) => {
    let fields = ["mandal"];
    clearFields(setFieldValue, fields);
    let district = {
      district_id: value,
    };

    await axios.post("mandalDropDown", district).then((data) => {

      //   if(data.data.results)
      setMandalList(data.data.result);
    });
  };
  const VillageList = async (value) => {
    let mandal = {
      mandal_id: value,
    };
    // let fields = ["mandal"];
    // clearFields(setFieldValue, fields);
    await axios.post("mandaltList", mandal).then((data) => {
   
      setMandalList(data.data.results);
    });
  };
  const HospitalList = (state_id, district_id) => {
    if (state_id && district_id && state_id && district_id !== "") {
      let hospitals = {
        state_id: state_id,
        district_id: district_id,
      };
      axios.post("`mandaltList", hospitals).then((data) => {
     
        setMandalList(data.data.results);
      });
    }
  };
  const formSubmit = async (values) => {
    await axios
      .post("campCreate", values)
      .then((res) => {
       
        addPatientToggle();
      })
      .catch((err) => {
        addPatientToggle();
      });
  };
  return (
    <>
      <Typography variant="h5" color="secondary">
        Add Camp
      </Typography>
      <Formik
        validationSchema={CampSchema}
        initialValues={campDetails}
        onSubmit={formSubmit}
      >
        {({ isSubmitting, setFieldValue, values, handleChange }) => (
          <Form>
            <HybridInput
              label="Name"
              name="name"
              fullWidth
              type="text"
              variant="standard"
            />
              <HybridInput
              label=" Parent Name"
              name="father_name"
              fullWidth
              type="text"
              variant="standard"
            />
            <HybridInput
              label="Age"
              name="age"
              type="number"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Gender"
              name="gender"
              type="text"
              fullWidth
              variant="standard"
            />
            {/* <HybridSelect
              label="State name"
              name="state"
              defaultValue={""}
              fullWidth
              type="text"
              variant="standard"
              options={stateList}
              setVal={setFieldValue}
              //   onChange={handleChange}
              //   onChange={
              //    (e)=>{
              //     DistrictList(e)
              //    }
              // }

              onChange={(e) => {
                DistrictList(e, setFieldValue);
              }}
              // onChange={(e) => {
              //   DistrictList(values.state, e, handleChange);
              // }}
              dropKey="state_name"
              dropId="_id"
              disabled={true}
            /> */}

            <HybridInput
              label="Door No"
              name="door_no"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Street Name"
              name="street_name"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Phone Number"
              name="phone"
              type="tel"
              onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10) }}
              fullWidth
              maxlength="10"
              InputProps={{ inputProps: { minlength: 0, maxlength: 10 } }}
              variant="standard"

            />
            <HybridInput
              label="Family Members"
              name="family_members"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="id_proof"
              name="id_proof"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Id Proof Number"
              name="id_proof_number"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Illness"
              name="illness"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Presenting Left Eye"
              name="presenting_left_eye"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Presenting Right Eye"
              name="presenting_right_eye"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Eye wise left History Of Surgery"
              name="ewl_history_of_surgery"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Eye wise left Hospital Name"
              name="ewl_hospital_name"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Eye wise left Complaints"
              name="ewl_complaints"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Eye wise left Provisional Diagnosis"
              name="ewl_provisional_diagnosis"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Eye wise left Illness"
              name="ewl_illness"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Eye wise Right History Of Surgery"
              name="ewr_history_of_surgery"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Eye wise Right Hospital Name"
              name="ewr_hospital_name"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Eye wise Right Complaints"
              name="ewr_complaints"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Eye wise Right Provisional Diagnosis"
              name="ewr_provisional_diagnosis"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Eye wise Right Illness"
              name="ewr_illness"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Torch Light left Eye Position"
              name="tll_eye_position"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Torch Light left Congestion"
              name="tll_congestion"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Torch Light left Lens"
              name="tll_lens"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Torch Light Right Eye Position"
              name="tlr_eye_position"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Torch Light Right Congestion"
              name="tlr_congestion"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Torch Light Right Lens"
              name="tlr_lens"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Referral Number"
              name="referal_number"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Follow Up"
              name="follow_up"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Forward Type"
              name="forward_type"
              type="text"
              fullWidth
              variant="standard"
            />
            <HybridInput
              label="Forward Location"
              name="forward_location"
              type="text"
              fullWidth
              variant="standard"
            />
            <div className="submit">
            <Stack spacing={2} direction="row">
              <Button
                color="primary"
                type="submit"
                //   disabled={isSubmitting}
                variant="contained" 
              >
                Submit
              </Button>
              <Button variant="contained"  onClick={addPatientToggle}>Back</Button>
            </Stack>
            </div>
          </Form>
        )}
      </Formik>

    </>
  );
}
