import React, { useState, useEffect, useRef } from 'react'
import { Loader } from "@googlemaps/js-api-loader"

import { useAxios } from "../../hooks/useAxios";

import camp_png from '../../assets/camp.png'
import hosueholds from '../../assets/households.png'
import shiftin from '../../assets/shiftin.png'
import shiftout from '../../assets/shiftout.png'
import tracking_icon from '../../assets/tracking.png'

const loader = new Loader({
  apiKey: "AIzaSyDoxlT05aCQHJP_Ni5tkXixB3JnYj6ltPY",
  version: "weekly",
  libraries: ['visualization'],
})


const FieldWorkerMap = ({ tracking, fieldWorkersTrack, TrackingDetail, reset }) => {
  const [infoWindow, setInfoWindow] = useState(null);
  const { axios } = useAxios();
  const [map, setMap] = useState(null)
  const [markers, setMarkers] = useState([]);
  const [iconMarker, setIconMarker] = useState([]);
  const [polyLines, setpolyLines] = useState();
  const [directionsDisplay, setDirectionsDisplay] = useState(null);

  const [position, setPosition] = useState({
    lat: 21.1458,
    lng: 79.0882
  })

  // console.log('tracking',tracking);
  // console.log('fieldWorkersTrack',fieldWorkersTrack);


  // const myStyles = [
  //   {
  //     featureType: "poi",
  //     elementType: "labels",
  //     stylers: [
  //       { visibility: "off" }
  //     ]
  //   }
  // ];


  const WORLD_BOUNDS = {
    north: 70,
    south: -65,
    west: -120,
    east: 170,
  };

  const mapOptions = {
    center: {
      lat: position.lat,
      lng: position.lng,
    },
    zoom: 4,
    disableDefaultUI: true,
    zoomControl: true,
    streetViewControl: false,
    fullscreenControl: true,
    // styles: myStyles,
    // maxZoom: 14,
    restriction: {
      latLngBounds: WORLD_BOUNDS,
      strictBounds: false,
    },
  }

  useEffect(() => {
    initializeFieldWorkerMap()
  }, [reset])

  async function initializeFieldWorkerMap() {

    const google_ = await loader.load();
    setInfoWindow(new google_.maps.InfoWindow());
    const mapField = new google_.maps.Map(document.getElementById("fieldworkers"), mapOptions);
    setMap(mapField);

  }

  useEffect(() => {
    setGeoTrack(tracking)
  }, [tracking])



  const handleDirectionsDisplay = (directionsDisplay) => {
    setDirectionsDisplay(directionsDisplay);
  };

  async function setGeoTrack(selectedDate) {
    const google_ = await loader.load();
    const bounds = new google_.maps.LatLngBounds();

    // Remove old markers
    markers?.forEach((marker) => {
      marker.setMap(null);
    });

    if (polyLines) {
      polyLines.setMap(null);
    }

    iconMarker?.forEach((marker) => {
      marker.setMap(null);
    });

    // polyLines.setMap(null)

    const land_marker = [];

    const tracking = {
      url: tracking_icon,
      size: new google_.maps.Size(50, 50),
      origin: new google_.maps.Point(0, 0),
      anchor: new google_.maps.Point(25, 25),
    };

    const hosuehold_icon = {
      url: hosueholds,
      size: new google_.maps.Size(50, 50),
      origin: new google_.maps.Point(0, 0),
      anchor: new google_.maps.Point(25, 25),
    };

    const punchin = {
      url: shiftin,
      size: new google_.maps.Size(50, 50),
      origin: new google_.maps.Point(0, 0),
      anchor: new google_.maps.Point(25, 25),
    };

    const punchout = {
      url: shiftout,
      size: new google_.maps.Size(50, 50),
      origin: new google_.maps.Point(0, 0),
      anchor: new google_.maps.Point(25, 25),
    };



    const coords = selectedDate?.coords

    const coordinates = coords.map(i => new google_.maps.LatLng(i.current_latitude, i.current_longitude));


    const polyline = new google_.maps.Polyline({
      path: coordinates,
      geodesic: true,
      strokeColor: '#ff7300',
      strokeOpacity: 1.0,
      strokeWeight: 2
    });

    polyline.setMap(map);
    setpolyLines(polyline)
    //add markers household and punchin , punchout ,
    coords?.map((i) => {
      let event = i.event.replace(/ /g, "").toLowerCase();

      const newMarker = new google_.maps.Marker({
        position: new google_.maps.LatLng(
          i.current_latitude,
          i.current_longitude
        ),
        map: map,
        icon: event == 'household' ? hosuehold_icon : event == 'punchin' ? punchin : event == 'punchout' ? punchout : tracking
      });
      land_marker.push(newMarker)
    });
    setIconMarker(land_marker)
    coords.forEach(marker => {
      bounds.extend(new google_.maps.LatLng(marker.current_latitude, marker.current_longitude));
    });

    map.fitBounds(bounds);

    // if (process.env.GOOGLE_WAYPOINTS_LIMITED == "TRUE") {
    //   setWayPoints(coords)
    // } else {

    //   var result_arr = []
    //   var start = 0;

    //   while (start < coords?.length) {

    //     result_arr.push(coords.slice(start, start + 20));
    //     var waypt = coords.slice(start, start + 20)
    //     setWayPoints(waypt)
    //     start += 19
    //   }
    // }

    // //add markers household and punchin , punchout ,
    // coords?.map((i) => {
    //   let event = i.event.replace(/ /g, "").toLowerCase();

    //   const newMarker = new google_.maps.Marker({
    //     position: new google_.maps.LatLng(
    //       i.current_latitude,
    //       i.current_longitude
    //     ),
    //     map: map,
    //     icon: event == 'household' ? hosuehold_icon : event == 'punchin' ? punchin : event == 'punchout' ? punchout : tracking
    //   });
    //   land_marker.push(newMarker)
    // });

    // land_marker.forEach(marker => {
    //   bounds.extend(marker.getPosition());
    // });
    // setMarkers(land_marker)

  }

  async function setWayPoints(coords) {

    const google_ = await loader.load();
    const directionsService = new google_.maps.DirectionsService();


    let first_arr = coords[0]
    let last_arr = coords[coords.length - 1]

    const startPoint = new google_.maps.LatLng(
      first_arr?.current_latitude,
      first_arr?.current_longitude
    );
    const endPoint = new google_.maps.LatLng(
      last_arr?.current_latitude,
      last_arr?.current_longitude
    );

    const waypts = coords.map((route) => ({
      location: new google_.maps.LatLng(
        route.current_latitude,
        route.current_longitude
      ),
      stopover: false
    }));

    // Remove old directions
    if (directionsDisplay !== null) {
      directionsDisplay.setMap(null);
    }

    // Create new directions
    const newDirectionsDisplay = new google_.maps.DirectionsRenderer({
      map: map,
      suppressMarkers: true,
      polylineOptions: {
        strokeColor: '#ff7300'
      }
    });

    handleDirectionsDisplay(newDirectionsDisplay);
    const request = {
      origin: startPoint,
      destination: endPoint,
      travelMode: google_.maps.TravelMode.WALKING,
      waypoints: waypts,
      optimizeWaypoints: true
    };


    directionsService.route(request, (result, status) => {
      if (status === google_.maps.DirectionsStatus.OK) {
        newDirectionsDisplay.setDirections(result);
      } else {
      }
    });
  }


  return (
    <div id='fieldworkers' > </div>
  )
}

export default FieldWorkerMap