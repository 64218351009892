import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const OptionalRoutes = () => {
  const { user } = useAuth();


  

  return user?.token == null ? <Outlet /> : <Navigate to="/masters" />;
};

export default OptionalRoutes;
