import {
  TextField,
  Stack,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import React, { useState } from "react";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";

const CampPerform = ({
  filterObject,
  hospitalDrop,
  fieldWorkers,
  fieldWorkersType,
  filterFwType,
  handleHospital,
}) => {
  const [campStartDate, setStartDate] = useState(null);
  const [campEndDate, setEndDate] = useState(null);
  const [campId, setCampId] = useState("");
  const [campPlace, setCampPlace] = useState("");
  const [fwType, setFwtype] = useState("");

  const [fieldreset, setFieldreset] = useState({
    vhv: 1,
    cbbf: 2,
  });

  // const fW = {
  //   vhv: null,
  //   cbbf: null
  // }

  // const [fieldWorkers, setFieldWorkers] = useState(fW)

  const handleSubmit = () => {
    var hospital = hospitalDrop?.find((h) => h._id == campPlace);

    let obj = {
      campStart: campStartDate
        ? moment(campStartDate.$d).format("YYYY-MM-DD")
        : "",
      campEnd: campEndDate ? moment(campEndDate.$d).format("YYYY-MM-DD") : "",
      campIds: campId ? campId : "",
      campPlaces: campPlace ? hospital?.hospital_name : "",
    };
    filterObject(obj);
  };

  const handleClose = () => {
    let obj = {
      campStart: "",
      campEnd: "",
      campIds: "",
      campPlaces: "",
    };
    setStartDate(null);
    setEndDate(null);
    setCampId(obj.campIds);
    setCampPlace(obj.campPlaces);
    filterObject(obj);
    setFieldreset({
      ...fieldreset,
      vhv: fieldreset.vhv + 0.1,
      cbbf: fieldreset?.cbbf + 0.1,
    });
    setFwtype("");
    filterFwType("");
    handleHospital();
  };

  function firstLetter(s) {
    return s?.replace(/^.{1}/g, s[0].toUpperCase());
  }

  function handleHospitalDrop(e) {
    setCampPlace(e.target.value);
    handleHospital(e);
  }

  function handleFwType(e) {
    setFwtype(e.target.value);
    filterFwType(e.target.value);
  }

  console.log("handleFwType", fwType);

  return (
    <div style={{ marginBottom: "1%", marginTop: "1%" }}>
      <Stack direction="row" spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="From Date"
            renderInput={(props) => (
              <TextField {...props} size="small" sx={{ width: "20%" }} />
            )}
            value={campStartDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="To Date"
            renderInput={(props) => (
              <TextField {...props} size="small" sx={{ width: "20%" }} />
            )}
            value={campEndDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
            minDate={campStartDate ? new Date(moment(campStartDate.$d)) : ""}
          />
        </LocalizationProvider>
        {/* <TextField size="small"
          variant="outlined"
          label="Hospital Name"
          value={campPlace}
          onChange={(e) => setCampPlace(e.target.value.toLowerCase())}
        /> */}

        <FormControl size="small" sx={{ width: "20%" }}>
          <InputLabel id="demo-simple-select-label">Select Hospital</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select Hospital"
            // onChange={(event) => {
            //   setFieldValue(field.name, event.target.value);
            // }}
            value={campPlace}
            onChange={(e) => {
              // setCampPlace(e.target.value)
              handleHospitalDrop(e);
            }}
          >
            {hospitalDrop?.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {firstLetter(option.hospital_name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* <TextField size="small"
          variant="outlined"
          label="FieldWorker Name"
          sx={{ width: '20%' }}
          value={campId}
          onChange={(e) => setCampId(e.target.value)}
        /> */}

        <Autocomplete
          size="small"
          disablePortal
          className="date"
          variant="standard"
          id="combo-box-demo"
          sx={{
            input: {
              "&::placeholder": {
                // <----- Add this.
                opacity: 1,
                marginBottom: "-5px",
                fontSize: "10px",
              },
            },
            backgroundColor: "#ffffff",
            margin: " 0 5px",
            // borderRadius: '5px',
            // width: '90%'
            width: "20%",
          }}
          key={fieldreset?.vhv}
          ListboxProps={{
            sx: { fontSize: 13 },
          }}
          options={
            fieldWorkers?.vhv?.filed_workerList?.length > 0
              ? fieldWorkers?.vhv?.filed_workerList
              : [{ field_worker_name: "No data found" }]
          }
          getOptionLabel={(option) => firstLetter(option.field_worker_name)}
          // defaultValue={searchValue?.fieldworker == "" ? null : searchValue?.fieldworker}
          onChange={(data, value) => {
            setCampId(value?.field_worker_name);
            setFieldreset({ ...fieldreset, cbbf: fieldreset.cbbf + 0.1 });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                style: {
                  fontSize: "13px", // Adjust the font size of the label here
                  paddingTop: "5px",
                },
              }}
              color="black"
              label="Select VHV FieldWorker"
            />
          )}
        />

        <Autocomplete
          size="small"
          disablePortal
          className="date"
          variant="standard"
          id="combo-box-demo"
          sx={{
            input: {
              "&::placeholder": {
                opacity: 1,
                marginBottom: "-5px",
              },
            },
            backgroundColor: "#ffffff",
            margin: " 0 5px",
            // borderRadius: '5px',
            // width: '90%'
            width: "20%",
          }}
          key={fieldreset?.cbbf}
          ListboxProps={{
            sx: { fontSize: 13 },
          }}
          options={
            fieldWorkers?.cbbf?.filed_workerList?.length > 0
              ? fieldWorkers?.cbbf?.filed_workerList
              : [{ field_worker_name: "No data found" }]
          }
          getOptionLabel={(option) => firstLetter(option.field_worker_name)}
          // defaultValue={searchValue?.fieldworker == "" ? null : searchValue?.fieldWorkers}
          onChange={(data, value) => {
            setCampId(value?.field_worker_name);
            setFieldreset({ ...fieldreset, vhv: fieldreset.vhv + 0.1 });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              color="black"
              InputLabelProps={{
                style: {
                  fontSize: "13px", // Adjust the font size of the label here
                  paddingTop: "5px",
                },
              }}
              label="Select Volunteers FieldWorker"
            />
          )}
        />

        <FormControl size="small" sx={{ width: "20%" }}>
          <InputLabel id="demo-simple-select-label">
            Select FieldWorker Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select FieldWorker Type"
            value={fwType}
            onChange={(e) => {
              handleFwType(e);
            }}
          >
            {fieldWorkersType?.map((option) => (
              <MenuItem
                key={option._id}
                value={option._id}
                sx={{ fontSize: "13px" }}
              >
                {firstLetter(option.name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          style={{ width: "11%" }}
          onClick={handleSubmit}
        >
          Search
        </Button>
        <Button
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          style={{ width: "11%" }}
          onClick={handleClose}
        >
          Reset
        </Button>
      </Stack>
    </div>
  );
};
export default CampPerform;
