import React from 'react'
import { Chart } from "react-google-charts";

const ChartCbbf = (props) => {
  const { TableValue } = props

  const data = [
    [
      "Element",
      "Density",
      { role: "style" },
      {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
      },
    ],
    ["District", TableValue?.total_district, "#FFDC00", null],
    ["Taluk", TableValue?.total_taluk, "#6CE1CB", null],
    ["Panchayat", TableValue?.total_mandal, "#92D050", null],
    ["Household", TableValue?.total_CBBF_survey, "color: #00B0F0", null],
    ["Population Screen", TableValue?.total_patients_screened, "color: OliveDrab", null],
    ["Camps", TableValue?.total_camp, "color: #e5e4e2", null],
    ["surgery Done", TableValue?.total_surgery_done, "color: #FFDC00", null],
    ["Total Cataract", TableValue?.total_cataract, "color: #6CE1CB", null],
    ["Glass Distribute", TableValue?.total_galsses_destriputed_done, "color: #92D050", null],
    ["Glass Prescription", TableValue?.total_glass_prescription, "color: #92D050", null],
    ["Total Vison center", TableValue?.total_vision_center_done, "color: #00B0F0", null],
    ["Refer to vision center", TableValue?.total_refer_to_vision_centre, "color: #OliveDrab", null],
    ["Base hospital", TableValue?.total_base_hospital_done, "color: #e5e4e2", null],
    ["Refer to Base hospital", TableValue?.total_refer_to_base_hospital, "color: #FFDC00", null],
  ];

  const options = {
    // title: "Cbbf Indicator Chart",
    width: "55vw",
    height: '65vh',
    bar: { groupWidth: "95%" },
    legend: { position: "bottom" },
  };
  const tableoptions = {
    colors: ["#FFDC00", "#00B0F0"],

  };
  const tabledata = [
    ["Indicator", "Actual", "target"],
    ["Surgery", TableValue?.total_surgery_done !== undefined ? TableValue?.total_surgery_done : '', TableValue?.total_cataract],
    ["Glass Distribute", TableValue?.total_galsses_destriputed_done !== undefined ? TableValue?.total_galsses_destriputed_done : '', TableValue?.total_glass_prescription],
    ["Vision Center", TableValue?.total_vision_center_done !== undefined ? TableValue?.total_vision_center_done : '', TableValue?.total_refer_to_vision_centre],
    ["Base Hospital", TableValue?.total_base_hospital_done !== undefined ? TableValue?.total_base_hospital_done : '', TableValue?.total_refer_to_base_hospital],
  ];
  return (
    <div>
      {TableValue?.total_vision_center_done !== undefined ? <Chart
        style={{ marginLeft: '2vw', marginTop: '-2vh' }}
        chartType="BarChart"
        width="40vw"
        height="65vh"
        data={tabledata}
        options={tableoptions}
      /> : ""
      }
    </div>
  )
}

export default ChartCbbf