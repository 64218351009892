import React, { useEffect, useState } from "react";
import { Button, Typography, Stack } from "@mui/material";
import { useAxios } from "../hooks/useAxios";
import TableComponent from "../components/TableLayout";
import Forms from "../forms/camp_form";
import ConfirmPopup from "../components/confirmPopup";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import ExcelUplaod from "../components/ExcelFormat/Excelfileupload";
import ExcelDownload from "../components/ExcelFormat/ExcelFileDownload";
import { toast, Slide } from "react-toastify";

import { dates } from "../index.js";

import {
  Table,
  makeStyles,
  FormControl,
  TableBody,
  TableCell,
  InputLabel,
  Input,
  Select,
  MenuItem,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

import "./Style.css";
import { newPasswordSchema } from "../validations/userSchema.js";

const renderInputs = (props, placeholder, classname) => (
  <Input
    sx={{
      width: "80%",
      input: {
        "&::placeholder": {
          // <----- Add this.
          opacity: 1,
          // marginBottom: '-5px',
          paddingLeft: "10px",
          fontSize: "13px",
          color: "#000000",
        },
        width: "100%",
      },
      backgroundColor: "#ffffff",
      // borderRadius: '5px',
      // maxHeight: '50px'
    }}
    type="text"
    className={classname}
    inputRef={props.inputRef}
    // inputProps={props.inputProps}
    inputProps={{
      ...props.inputProps,
      placeholder: placeholder,
    }}
    value={props.value}
    onClick={props.onClick}
    onChange={props.onChange}
    endAdornment={props.InputProps?.endAdornment}
  />
);

export default function CampManagement() {
  const [open, setOpen] = useState(false);
  const [length, setLength] = useState();
  const { axios } = useAxios();
  const initialState = {
    camp_place: "",
    camp_pin_code: "",
    camp_date: null,
    camp_from_date: null,
    // camp_from_date: null,
    camp_to_date: null,
    state: "",
    district: "",
    mandal: "",
    village: "",
    hospital: "",
    field_worker_id: "",
    co_sponsor: "",
    household_target: "",
    yellow_card_target: "",
    individual_screening_target: "",
    field_worker_type: [], //
    organizations: [], //
    field_workers: [], //
  };
  const updateState = {
    id: "",
    username: "",
    email: "",
  };
  const [header, setHeader] = useState([
    {
      id: 1,
      label: "Camp ID",
      value: "camp_number",
    },
    {
      id: 2,
      label: "Place",
      value: "camp_place",
    },
    {
      id: 3,
      label: "Date",
      value: "camp_date",
    },
    {
      id: 4,
      label: "Hospital",
      value: "hospital_name",
    },
    {
      id: 5,
      label: "Project",
      value: "project_name",
    },
    {
      id: 6,
      label: "Survey from",
      value: "camp_from_date",
    },
    {
      id: 7,
      label: "Survey to",
      value: "camp_to_date",
    },

    {
      id: 9,
      label: "Action",
      value: "action",
    },
  ]);
  let dateFields = ["camp_from_date", "camp_to_date", "camp_date"];
  const [campsList, setCampsList] = useState([]);
  const [editState, setEditState] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [userDetails, setUserDetails] = useState(initialState);
  const [progressBar, setProgressBar] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [addCamp, setAddCamp] = useState(false);
  const [campExcel, setCampExcel] = useState({});

  const [searchValue, setSearchValue] = React.useState({
    eventStart: "",
    eventEnd: "",
  });

  const [downloadTab, setDownloadTab] = useState(false);
  const [value, setValue] = useState([]);
  const [dateSearch, setDateSearch] = useState("");

  let paginationInfo = {
    page: 1,
    count: 0,
    rowsPerPage: 10,
  };
  const [pageInfo, setPageInfo] = useState(paginationInfo);
  const [addingCamp, setAddingCamp] = useState(true);
  const [filterValue, setFilterValue] = useState({
    campIds: "",
    campPlaces: "",
    campStart: "",
    campEnd: "",
  });
  useEffect(() => {
    getCampsList();
  }, [pageInfo.rowsPerPage, pageInfo.page, filterValue]); //filterValue

  const filterResponse = (val) => {
    setPageInfo((prevState) => ({ ...prevState, page: 1 }));
    setFilterValue(val);
  };
  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };

  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };

  const getCampsList = async () => {
    await axios
      .post(
        `CBBFCampList?limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}&camp_place=${filterValue.campPlaces}&fromDate=${filterValue.campStart}&toDate=${filterValue.campEnd}&camp_number=${filterValue.campIds}`
      )
      // .post(
      //   `CBBFCampList?limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}&type=2&search=${searchValue}`
      // )
      .then((res) => {
        setLength(res.data.result.length);
        setCampsList(res.data.result.list);
        setCampExcel(res.data.result.list);
        setPageInfo((prevState) => ({
          ...prevState,
          count: res.data.result.length,
        }));
      })
      .catch((err) => {
        setPageInfo(paginationInfo);
        setLength(0);
        setCampsList([]);
      });
  };

  const deleteDate = (data) => {
    setActiveId(data);
    setDeletePopup(true);
  };

  const openDialogue = () => {
    setEditState(false);

    setOpen(true);
  };
  const closeDialogue = () => {
    // resetForm();
    setUserDetails(initialState);
    setOpen(false);
  };

  const editPopUP = (data) => {
    try {
      setEditState(true);

      campsList.forEach((list) => {
        if (list._id == data) {
          setActiveId(list._id);

          // state conversion
          const field_worker_type = list?.worker_type?.map(
            ({ role_id: _id, role_name: name }) => ({
              _id,
              name,
            })
          );

          const organizations = list?.organizations?.map(
            ({
              organization_collection_id: _id,
              organization_id,
              organization_name,
            }) => ({
              _id,
              organization_id,
              organization_name,
            })
          );

          const field_workers = list?.field_worker?.map(
            ({ field_worker_id: _id, field_worker_name: user_name }) => ({
              _id,
              user_name,
            })
          );

          const village = list?.village?.map(
            ({ village_id: _id, village_name }) => ({
              _id,
              village_name,
            })
          );

          // old one
          // setUserDetails({ ...initialState, ...list });

          // updated one
          setUserDetails((prevState) => ({
            ...prevState,
            ...list,
            mandal: list?.mandal_id,
            field_worker_type,
            organizations,
            field_workers,
            village,
          }));

          setAddingCamp(false);
          setAddCamp(false);
        }
      });
    } catch (error) {
      toast.error("Something Went Wrong", {
        autoClose: 1500,
        transition: Slide,
      });
    }
  };

  const [filter, setFilter] = useState({
    camp_id: "",
    place: "",
  });

  function handleChange(e) {
    var name = e.target.name;
    var val = e.target.value;

    setFilter({
      ...filter,
      [name]: val,
    });
  }

  const addCampToggle = () => {
    setAddingCamp((prevState) => !prevState);
    getCampsList();
    setEditState(false);
    setAddCamp(true);
  };

  function deleteCancel() {
    setDeletePopup(false);
  }
  function deleteSubmit() {
    setDeletePopup(false);

    let insertData = {
      id: activeId,
    };
    axios.post(`campDelete`, insertData).then((data) => {
      getCampsList();
    });
  }

  function handleDates(val) {
    var today = new Date();
    setDateSearch(val);

    // Format the dates as "DD-MM-YYYY"
    var dateFormat = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    if (val == "last_week") {
      //==============last week ======================
      var currentDayOfWeek = today.getDay();

      var daysUntilStartOfWeek = currentDayOfWeek - 0;

      var startDateOfPreviousWeek = new Date(today);
      startDateOfPreviousWeek.setDate(
        today.getDate() - daysUntilStartOfWeek - 6
      );

      var endDateOfPreviousWeek = new Date(today);
      endDateOfPreviousWeek.setDate(today.getDate() - 1);

      // var formattedStartDate = startDateOfPreviousWeek.toLocaleDateString("en-GB", dateFormat).replace(/\//g, '-');
      // var formattedEndDate = endDateOfPreviousWeek.toLocaleDateString("en-GB", dateFormat).replace(/\//g, '-');

      var formattedStartDate = startDateOfPreviousWeek
        .toISOString()
        .split("T")[0];
      var formattedEndDate = endDateOfPreviousWeek.toISOString().split("T")[0];

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartDate,
        eventEnd: formattedEndDate,
      }));

      // setSearchDates({
      //   ...searchdates,
      //   fromDate : formattedStartDate,
      //   toDate : formattedEndDate

      // })
    } else if (val == "last_month") {
      //================last month ===============================
      const firstDayOfLastMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      );

      const lastDayOfLastMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );

      var formattedStartDate = firstDayOfLastMonth.toISOString().split("T")[0];
      var formattedEndDate = lastDayOfLastMonth.toISOString().split("T")[0];

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        // eventStart: firstDayOfLastMonth.toLocaleDateString("en-GB", dateFormat).replace(/\//g, '-'),
        // eventEnd: lastDayOfLastMonth.toLocaleDateString("en-GB", dateFormat).replace(/\//g, '-'),

        eventStart: formattedStartDate,
        eventEnd: formattedEndDate,
      }));
    } else if (val == "last_3_month") {
      //================last month ===============================
      const firstDayOfLastMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        1
      );

      const lastDayOfLastMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: firstDayOfLastMonth.toISOString().split("T")[0],
        eventEnd: lastDayOfLastMonth.toISOString().split("T")[0],
      }));
    } else if (val == "all") {
      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: "",
        eventEnd: "",
      }));
    }
  }

  /*------------------------ Excel Download ---------------*/

  function firstLetter(s) {
    return s?.replace(/^.{1}/g, s[0]?.toUpperCase());
  }

  const handleuploadExcel = async () => {
    const res = await axios.post(
      `CBBFCampList?limit=&page=${1}&camp_place=${
        filterValue.campPlaces
      }&fromDate=${searchValue.eventStart}&toDate=${
        searchValue.eventEnd
      }&camp_number=${filterValue.campIds}`
    );

    const data_ = res?.data?.result?.list;

    const newArr = data_.map((obj) => {
      const newObj = { ...obj };
      delete newObj["_id"];
      delete newObj["created_on"];
      delete newObj["state_id"];
      delete newObj["district_id"];
      delete newObj["taluk_id"];
      delete newObj["mandal_id"];
      delete newObj["_v"];
      return newObj;
    });
    let data = newArr.map((data) => {
      return {
        ...data,
        camp_date: data.camp_date.slice(0, 10),
        camp_from_date: data.camp_from_date.slice(0, 10),
        camp_to_date: data.camp_to_date.slice(0, 10),
        project_name: firstLetter(data?.project_name),
      };
    });

    let newArrayOfObj = data.map(
      ({
        project_name: ProjectName,
        camp_number: CampNumber,
        camp_place: CampPlace,
        camp_pin_code: CampPinCode,
        camp_date: CampDate,
        camp_from_date: campFromDate,
        camp_to_date: CampToDate,
        state_name: stateName,
        district_name: DistrictName,
        taluk_name: SubDivison,
        mandal_name: PanchayatName,
        village: Village,
        field_worker: FieldWorkersName,
        hospital_name: HospitalName,
        current_latitude: Latitude,
        current_longitude: Longitude,
        co_sponsor: Cosponsor,
      }) => ({
        HospitalName,
        ProjectName,
        CampNumber,
        CampPlace,
        CampPinCode,
        CampDate,
        FieldWorkersName: FieldWorkersName?.map(
          ({ field_worker_name }) => field_worker_name
        ).toString(),
        campFromDate,
        CampToDate,
        stateName,
        DistrictName,
        SubDivison,
        PanchayatName,
        Village: Village?.map(({ village_name }) => village_name).toString(),
        Latitude,
        Longitude,
        Cosponsor,
      })
    );

    // const finalValue = newArrayOfObj?.map(
    //   ({ village, field_worker, ...rest }) => ({
    //     ...rest,
    //     Village: village?.map(({ village_name }) => village_name).toString(),
    //     FieldWorkersName: field_worker
    //       ?.map(({ field_worker_name }) => field_worker_name)
    //       .toString(),
    //   })
    // );

    ExcelDownload(newArrayOfObj, "VolunteersCamp");

    setDownloadTab((o) => !o);
    setDateSearch("");
  };

  const handleSearchValue = (values) => {
    setSearchValue(values);
  };

  return (
    <>
      {/* <h1>STATUS: {addingCamp}</h1> */}
      {addingCamp ? (
        <div>
          <div className="state_head">
            <Typography variant="h5" color="secondary" className="text-left">
              {/* CBBF - Camp Management */}
              Volunteers Screening - Camp Management
            </Typography>
            <div>
              <Button
                variant="contained"
                sx={{ m: 1, width: "auto", textTransform: "capitalize" }}
                startIcon={<FileDownloadIcon />}
                // onClick={handleuploadExcel}
                onClick={() => setDownloadTab((k) => !k)}
              >
                {/* CBBF Camp Download */}
                Download Camp
              </Button>
              <Button
                variant="contained"
                sx={{
                  float: "right",
                  m: 1,
                  width: "10ch",
                  textTransform: "capitalize",
                }}
                onClick={addCampToggle}
              >
                {" "}
                Add
              </Button>
            </div>
          </div>

          {downloadTab && (
            <div className="tabbackground">
              {/* <div className='downloadtab' > */}
              <div
                className={
                  dateSearch == "custom" ? "nonreports" : "nonreports1"
                }
              >
                <div className="table">
                  <Table>
                    <TableHead>
                      <tr>
                        {/* <th><Checkbox
                      onChange={(e) => {
                        handleSelectAll(e);
                      }}
                    />
                      Select all</th> */}
                        <th> </th>
                        <th>Select Duration </th>
                      </tr>
                    </TableHead>
                  </Table>
                  <div className="filter">
                    <FormControl style={{ width: "80%" }} size="small">
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        label="Select Dates"
                        value={dateSearch}
                        onChange={(e) => {
                          handleDates(e.target.value);
                        }}
                      >
                        {dates?.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {dateSearch == "custom" && (
                      <div style={{ width: "100%" }} className="custom">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="from_date"
                            value={
                              searchValue.eventStart == ""
                                ? null
                                : searchValue.eventStart
                            }
                            inputFormat="DD/MM/YYYY"
                            onChange={(e) => {
                              const selectedDate = e.$d;
                              if (selectedDate) {
                                setSearchValue({
                                  ...searchValue,
                                  eventStart: selectedDate,
                                });
                              } else {
                                setSearchValue({
                                  ...searchValue,
                                  eventStart: null,
                                });
                              }
                            }}
                            slotProps={{
                              actionBar: {
                                actions: ["clear"],
                              },
                            }}
                            renderInput={(props) =>
                              renderInputs(props, "From date", "date")
                            }
                          />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="from_date"
                            value={
                              searchValue.eventEnd == ""
                                ? null
                                : searchValue.eventEnd
                            }
                            inputFormat="DD/MM/YYYY"
                            onChange={(e) => {
                              setSearchValue({
                                ...searchValue,
                                eventEnd: e.$d,
                              });
                            }}
                            renderInput={(props) =>
                              renderInputs(props, "To date", "date")
                            }
                          />
                        </LocalizationProvider>
                      </div>
                    )}
                  </div>
                </div>
                <div className="downloadoptions">
                  <Button
                    variant="contained"
                    onClick={() => {
                      setDownloadTab((k) => !k);
                      setDateSearch("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    disabled={dateSearch == ""}
                    onClick={() => handleuploadExcel()}
                  >
                    Download
                  </Button>{" "}
                  {/*onClick={handleuploadExcel}*/}
                </div>
              </div>
            </div>
          )}

          <TableComponent
            header={header}
            datalist={campsList}
            datalength={length}
            editData={(id) => editPopUP(id)}
            deleteData={(id) => deleteDate(id)}
            pageInfo={pageInfo}
            actions={["edit", "delete"]}
            setPageInfo={setPageInfo}
            handleTablePage={handleTablePage}
            filter="camp"
            filterResponseValue={(val) => filterResponse(val)}
            handleTableRowsPerPage={handleTableRowsPerPage}
            dateFields={dateFields}
            searchDataValue={(val) => handleSearchValue(val)}
          />
          {deletePopup ? (
            <ConfirmPopup
              message={"Are you sure, you want to delete this record"}
              deleteCancel={deleteCancel}
              deleteSubmit={deleteSubmit}
            ></ConfirmPopup>
          ) : (
            ""
          )}

          {/* <Dialog open={open} fullWidth onClose={closeDialogue}>
            <DialogTitle>Add Admin</DialogTitle>
            <Formik
              initialValues={userDetails}
              validationSchema={editState ? UpdateUserSchema : UserSchema}
              onSubmit={formSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <DialogContent>
                    <HybridInput
                      label="Username"
                      name="username"
                      type="text"
                      fullWidth
                      variant="standard"
                    />

                    <HybridInput
                      label="Email"
                      name="email"
                      type="email"
                      fullWidth
                      variant="standard"
                    />

                    {!editState && (
                      <HybridInput
                        label="Password"
                        name="password"
                        type="password"
                        fullWidth
                        variant="standard"
                      />
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                      variant="outlined"
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
            {progressBar && <LinearProgress color="primary" />}
          </Dialog> */}
        </div>
      ) : (
        <Forms
          addCampToggle={() => addCampToggle()}
          camptype={"cbbf"}
          userDetails={addCamp === true ? initialState : userDetails}
          editState={editState === true ? editState : false}
        />
      )}
    </>
  );
}
