import React, { useState, useEffect } from 'react'
import { Loader } from "@googlemaps/js-api-loader"

import { useAxios } from "../../hooks/useAxios";
import redcross from '../../assets/redcross.png'
import camp_png from '../../assets/camp.png'
import './maps.scss'
 
import hosueholds from '../../assets/households.png'
import tracking_icon from '../../assets/tracking.png'
import shiftin from '../../assets/shiftin.png'
import shiftout from '../../assets/shiftout.png'

import red from '../../assets/red.png'
import violet from '../../assets/violet.png'
import green from '../../assets/green.png'
import orange from '../../assets/orange.png'
import lightblue from '../../assets/lightblue.png'
import brown from '../../assets/brown.png'
import purple from '../../assets/purple.png'
import yellow from '../../assets/yellow.png'

// import logo from "../assets/icon.png";
import logo from "../../assets/SankaraLogo.png";
import { useNavigate } from "react-router-dom";

const loader = new Loader({
    apiKey: "AIzaSyDoxlT05aCQHJP_Ni5tkXixB3JnYj6ltPY",
    version: "weekly",
    libraries: ['visualization'],
})


const state = {
    to_date: "",
    from_date: "",
    camp: '',
    vhv: '',
    vhvId: '',
    time: 0,
    initial: true,
    customSearch: false,
}

const CommonMap = ({ hospitalData, restriction, selectedHospital, heatMap, tracking, detailData, selectedIllness, reset, campData }) => {
 
    // console.log('tracking',tracking);
    console.log('');
    console.log('');

    const { axios } = useAxios();
    const navigate = useNavigate()

    const [infoWindow, setInfoWindow] = useState(null);
    const [map, setMap] = useState(null)
    const [markerInstance, setMarkerInstance] = useState([])   //hospital marker
    const [hospitalMarker, setHospitalMarker] = useState([])   //hospital marker
    const [campmarker, setCampMarker] = useState([])   //camp marker

    const [iconMarker, setIconMarker] = useState([]);    //household marker for tracking
    const [illnessMarker, setIllnessMarker] = useState([]);    //household marker for tracking


    const [position, setPosition] = useState({
        lat: 21.1458,
        lng: 79.0882
    })
    const [cataractHeatMap, setcataractHeatMap] = useState(null)
    const [diaHeatMap, setdiaHeatMap] = useState(null)
    const [hypHeatMap, sethypHeatMap] = useState(null)
    const [heatOrHouse, setHeatOrHouse] = useState(null);
    const [polyLines, setpolyLines] = useState();

    const [markers, setMarkers] = useState([])

    // console.log('sss');

    const mYstyles = [
        {
            featureType: "poi",
            elementType: "labels",
            stylers: [
                { visibility: "off" }
            ]
        },
        {
            featureType: 'transit',
            stylers: [{ visibility: 'off' }]
        },
        { featureType: "road.highway", elementType: "labels", stylers: [{ visibility: "off" }] }, //turns off highway labels
        { featureType: "road.arterial", elementType: "labels", stylers: [{ visibility: "off" }] }, //turns off arterial roads labels
        { featureType: "road.local", elementType: "labels", stylers: [{ visibility: "off" }] },  //turns off local roads labels 
        {
            featureType: "water",
            elementType: "labels",
            stylers: [
                { visibility: "off" }
            ]
        }, {
            featureType: "road",
            elementType: "labels",
            stylers: [
                { visibility: "off" }
            ]
        }

    ];


    // var mYstyles = {
    //     hide: [
    //         {
    //             featureType: 'poi.business',
    //             stylers: [{ visibility: 'off' }]
    //         },
    //         {
    //             featureType: 'transit',
    //             elementType: 'labels.icon',
    //             stylers: [{ visibility: 'off' }]
    //         }
    //     ]
    // };

    const WORLD_BOUNDS = {
        north: 70,
        south: -65,
        west: -120,
        east: 170,
    };

    const mapOptions = {
        center: {
            lat: position.lat,
            lng: position.lng,
        },
        zoom: 4,
        disableDefaultUI: true,
        mapTypeId: 'hybrid',
        zoomControl: true,
        streetViewControl: false,
        fullscreenControl: true,
        styles: mYstyles,
        // maxZoom: 14,
        restriction: {
            latLngBounds: WORLD_BOUNDS,
            strictBounds: false,
        },

    }

    useEffect(() => {
        initializeHospitalMap()
    }, [reset])

    useEffect(() => {
        handleHospital()
    }, [hospitalData])

    useEffect(() => {
        if (campData != null && hospitalData?.length == 0 || campData != undefined && hospitalData?.length == 0) {
            handleCampMap()
        }
    }, [campData])


    useEffect(() => {
        hospitalHeatMap()
    }, [heatMap])


    useEffect(() => {
        if (heatOrHouse) {
            handleCampMap()
        } else {
            setHeatMap()
        }
    }, [heatOrHouse])

    useEffect(() => {
        if (tracking !== null) {
            setGeoTrack(tracking)
        }
    }, [tracking])


    async function initializeHospitalMap() {
        const google_ = await loader.load()

        setInfoWindow(new google_.maps.InfoWindow());

        const mapField = new google_.maps.Map(document.getElementById("hospital"), mapOptions);

        const div = document.createElement('div');
        div.style.cursor = 'pointer';
        div.innerHTML = `<img src=${logo} id='logo' />`

        div.addEventListener('click', function handleClick(e) {
            window.location = window.location.origin + '/masters'
        })

        mapField.controls[google_.maps.ControlPosition.TOP_LEFT].push(div)

        markers.forEach(marker => {
            marker.setMap(mapField);
        });
        setMap(mapField);

    }


    async function hospitalHeatMap() {
        const google_ = await loader.load();

        if (cataractHeatMap) {
            cataractHeatMap.setMap(null);
        }

        if (diaHeatMap) {
            diaHeatMap.setMap(null);
        }

        if (hypHeatMap) {
            hypHeatMap.setMap(null);
        }

        const cataract = [
            "rgba(9, 144, 255,0)",
            "rgba(0, 153, 255,1)",
            "rgba(0, 162, 255,1)",
            "rgba(0, 170, 255,1)",
            "rgba(0, 178, 255,1)",
            "rgba(0, 186, 255,1)",
            "rgba(0, 193, 255,1)",
            "rgba(0, 200, 255,1)",
            "rgba(0, 207, 255,1)",
            "rgba(8, 214, 255,1)",
        ];

        const dia = [
            "rgba(255, 114, 0,0)",
            "rgba(255, 130, 0,1)",
            "rgba(255, 146, 0,1)",
            "rgba(255, 162, 0,1)",
            "rgba(255, 178, 0,1)",
            "rgba(255, 194, 0,1)",
            "rgba(255, 209, 0,1)",
            "rgba(255, 224, 0,1)",
            "rgba(252, 240, 0,1)",
            "rgba(246, 255, 0,1)",
        ]

        const hy = [
            "rgba(205, 38, 38,0)",
            "rgba(216, 40, 57,0)",
            "rgba(225, 44, 76,1)",
            "rgba(233, 51, 94,1)",
            "rgba(239, 59, 113,1)",
            "rgba(244, 69, 132,1)",
            "rgba(248, 80, 151,1)",
            "rgba(250, 91, 170,1)",
            "rgba(250, 103, 188,1)",
            "rgba(249, 115, 206,1)",
        ]

        var intensity = 15;

        var h_diabetes = { maxIntensity: intensity, data: [] }
        var h_hypertension = { maxIntensity: intensity, data: [] }
        var h_cataract = { maxIntensity: intensity, data: [] }



        heatMap?.diabetes?.illness_coords?.forEach((coord) => {
            h_diabetes.data.push(new google_.maps.LatLng(coord.latitude, coord.longitude));
        });
        heatMap?.hypertension?.illness_coords?.forEach((coord) => {
            h_hypertension.data.push(new google_.maps.LatLng(coord.latitude, coord.longitude));
        });
        heatMap?.cataract?.illness_coords?.forEach((coord) => {
            h_cataract.data.push(new google_.maps.LatLng(coord.latitude, coord.longitude));
        });

        const dia_heatmap = new google_.maps.visualization.HeatmapLayer({
            data: h_diabetes.data,
            gradient: dia,
        });

        const hyp_heatmap = new google_.maps.visualization.HeatmapLayer({
            data: h_hypertension.data,
            gradient: hy,
            // radius :20
        });


        const heatmap = new google_.maps.visualization.HeatmapLayer({
            data: h_cataract.data,
            gradient: cataract,
            // radius : 20
        });

        setcataractHeatMap(heatmap);
        setdiaHeatMap(dia_heatmap);
        sethypHeatMap(hyp_heatmap);

        dia_heatmap.setMap(map);
        hyp_heatmap.setMap(map);
        heatmap.setMap(map);
    }


    async function handleHospital(e) {
        markers.forEach(marker => marker.setMap(null));         // illness icon marker icons
        markerInstance.forEach(marker => marker.setMap(null));  // to remove camp and househodl icons
        iconMarker.forEach(marker => marker.setMap(null));  // to remove tracking
        illnessMarker.forEach(marker => marker.setMap(null));  // to remove ilness
        campmarker.forEach(marker => marker.setMap(null));  // to remove camp 


        var illnes = document.getElementById('illness');

        if (illnes) {
            illnes.remove();
        }


        // if (legend) {
        //     legend.remove();
        // }
        const legend = document.getElementById("legend");

        if (legend == null) {
            createLegend()
        }

        if (cataractHeatMap) {
            cataractHeatMap.setMap(null);
        }

        if (diaHeatMap) {
            diaHeatMap.setMap(null);
        }

        if (hypHeatMap) {
            hypHeatMap.setMap(null);
        }


        const google_ = await loader.load();
        const bounds = new google_.maps.LatLngBounds();
        const infoWindow = new google_.maps.InfoWindow();
        const circleArray = [];

        function resizeMarkers(map) {
            markerInstance.forEach(function (marker) {
                var pixelSizeAtZoom0 = 5; // the size of the icon at zoom level 0
                var maxPixelSize = 20; // restricts the maximum size of the icon

                var zoom = map.getZoom();
                var relativePixelSize = Math.round(pixelSizeAtZoom0 * Math.pow(2, zoom));

                if (relativePixelSize > maxPixelSize) {
                    relativePixelSize = maxPixelSize;
                }

                marker.setIcon({
                    url: marker.getIcon().url, //marker's same icon graphic
                    size: null,
                    origin: null,
                    anchor: null,
                    scaledSize: new google_.maps.Size(relativePixelSize, relativePixelSize) //changes the scale
                })
            });
        }

        google_.maps.event.addListener(map, 'zoom_changed', function () {
            resizeMarkers(map);
        });

        const hospital_icon = {
            url: redcross,
            size: new google_.maps.Size(50, 50),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };

        const camp_icon = {
            url: camp_png,
            size: new google_.maps.Size(50, 50),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };

        markerInstance.forEach(marker => marker.setMap(null));
        const markerInfoWindow = new google_.maps.InfoWindow();


        hospitalData?.map((hospital) => {

            if (hospital?.hospital_id !== "1") {

                let hospitalMarker = new google_.maps.Marker({
                    position: new google_.maps.LatLng(hospital.latitude, hospital.longitude),
                    map: map,
                    icon: hospital_icon,
                });

                markerInstance.push(hospitalMarker)

                const radius = Math.floor(Math.random() * (5 - 1 + 1)) + 1; // Random number between 1 and 5 (inclusive)

                const circle = new google_.maps.Circle({
                    map: map,
                    center: hospitalMarker.getPosition(),
                    radius: hospitalData.radius,
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#FF0000',
                    fillOpacity: 0.35
                });
                circleArray.push(circle);

                // Set the circle's center to the marker's position
                circle.bindTo('center', hospitalMarker, 'position');

                if (hospitalData?.length === 1) {
                    let camp_details = [];

                    const fetchGisCamp = async () => {

                        await axios.post('getGISCampList', {
                            hospital_id: [hospital.hospital_id],
                        }).then((res) => {
                            camp_details = res.data.result.result;

                            let campBounds = new google_.maps.LatLngBounds(); // create a new bounds object for camp markers

                            camp_details?.map((camps) => {
                                camps?.campList?.map((cam) => {
                                    let campMarker = new google_.maps.Marker({
                                        position: new google_.maps.LatLng(cam.current_latitude, cam.current_longitude),
                                        map: map,
                                        icon: camp_icon,
                                    });

                                    campMarker.addListener("click", async () => {

                                        var data = {
                                            camp_id: [cam?.camp_id]
                                        }
                                        addHosueholdsViaClick(data)

                                    })

                                    markerInstance.push(campMarker);
                                    campBounds.extend(campMarker.getPosition()); // extend the bounds to include the camp marker position
                                });
                            });

                            // extend the bounds to include the hospital marker position and the camp markers positions
                            bounds.extend(hospitalMarker.getPosition());
                            bounds.union(campBounds);

                            map.fitBounds(bounds);
                        });
                    }

                    fetchGisCamp();
                } else {

                    hospitalMarker?.addListener('mouseover', () => {
                        markerInfoWindow.close();

                        const contentString = `<div class=''>
                                    <img width='100%' height='100' src=${logo} />
                                    <h3  class='mar' >Hospital Name</h3> 
                        <span>${hospital?.hospital_name}</span>
                      </div>`;
                        markerInfoWindow.setContent(contentString);
                        markerInfoWindow.open(map, hospitalMarker);

                    })

                    hospitalMarker.addListener('click', () => {
                        // Remove all existing markers
                        markerInstance.forEach(marker => marker.setMap(null));

                        let newMarkers = []

                        let camp_details = [];

                        let hospitalId = {
                            hospital_id: [hospital.hospital_id]
                        }
                        addCampviaClick(hospitalMarker, hospital)
                        selectedHospital(hospitalId)

                    });

                    bounds.extend(hospitalMarker.getPosition());
                }
            }

        });

        map.fitBounds(bounds);
    }

    async function handleCampMap() {

        markerInstance.forEach(marker => marker.setMap(null));

        const google_ = await loader.load()
        illnessMarker.forEach(marker => marker.setMap(null));         // illness icon marker icons
        campmarker.forEach(marker => marker.setMap(null));  // to remove camp and househodl icons

        const infoWindow = new google_.maps.InfoWindow();
        setInfoWindow(infoWindow);

        if (cataractHeatMap) {
            cataractHeatMap.setMap(null);
        }

        if (diaHeatMap) {
            diaHeatMap.setMap(null);
        }

        if (hypHeatMap) {
            hypHeatMap.setMap(null);
        }

        var illnes = document.getElementById('illness')
        const legend = document.getElementById("legend");

        if (illnes == null) {
            createElement()
        }
        if (legend == null) {
            createLegend()
        }

        const camp_icon = {
            url: camp_png,
            size: new google_.maps.Size(50, 50),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };
        const hosuehold = {
            url: hosueholds,
            size: new google_.maps.Size(50, 50),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };


        function resizeMarkers(map) {
            markerInstance.forEach(function (marker) {
                var pixelSizeAtZoom0 = 5; // the size of the icon at zoom level 0
                var maxPixelSize = 30; // restricts the maximum size of the icon

                var zoom = map.getZoom();
                var relativePixelSize = Math.round(pixelSizeAtZoom0 * Math.pow(2, zoom));

                if (relativePixelSize > maxPixelSize) {
                    relativePixelSize = maxPixelSize;
                }

                marker.setIcon({
                    url: marker.getIcon().url, //marker's same icon graphic
                    size: null,
                    origin: null,
                    anchor: null,
                    scaledSize: new google_.maps.Size(relativePixelSize, relativePixelSize) //changes the scale
                })
            });
        }

        google_.maps.event.addListener(map, 'zoom_changed', function () {
            resizeMarkers(map);
        });


        const bounds = new google_.maps.LatLngBounds();
        const markerInfoWindow = new google_.maps.InfoWindow();

        let newmarker = [];

        //     // Object.keys(campData)?.forEach((i) => {
        for (let i = 0; i <= Object.keys(campData).length - 1; i++) {
            let iItem = campData[Object.keys(campData)[i]];
            // Object.keys(campData[i]?.coords)?.forEach((j) => {
            if (iItem.coords) {

                for (let j = 0; j <= Object.keys(iItem.coords).length - 1; j++) {
                    let jItem = Object.keys(iItem.coords)[j];

                    if (iItem.coords?.camp_coords && iItem.coords?.camp_coords.length > 0) {
                        // campData[i]?.coords[j]?.forEach((k) => {
                        for (let k = 0; k <= iItem.coords[jItem].length - 1; k++) {
                            let kItem = iItem.coords[jItem][k];
                            let marker = ""
                            marker = new google_.maps.Marker({
                                position: new google_.maps.LatLng(
                                    kItem.current_latitude,
                                    kItem.current_longitude
                                ),
                                map: map,
                                icon: jItem == 'camp_coords' ? camp_icon : hosuehold
                            });

                            var data = {
                                lat: kItem.current_latitude,
                                lng: kItem.current_longitude
                            }

 
                            if (jItem == 'household_coords') {
                                marker.addListener("click", async () => {

                                    var data = campData[Object.keys(campData)[i]].detail?.household_detail?.find((x) => { if (x.household_id == kItem.household_id) { return x } })

                                    detailData(data, 'household')


                                    markerInfoWindow.close();
                                    const contentString = `<div><h3>Patient Name : ${data?.name}</h3>
                                                    <h4>Patient Age : ${data?.age}                    
                                    </div>`;
                                    markerInfoWindow.setContent(contentString);
                                    markerInfoWindow.open(map, marker);
                                })
                            } else {

                                marker.addListener("click", async () => {
                                    var data = campData[Object.keys(campData)[i]].detail?.camp_detail?.find((x) => { if (x.camp_id == kItem.camp_id) { return x } })

                                    detailData(data, 'camp')


                                    markerInfoWindow.close();
                                    const contentString = `<div>
                                    <img width='100%' height='100' src=${logo} />
                                    <h3 class='mar'>Camp place : ${data?.camp_place}</h3>
                                                                        
                                        </div>`;
                                    markerInfoWindow.setContent(contentString);
                                    markerInfoWindow.open(map, marker);
                                })
                            }
                            newmarker.push(marker);
                        }
                    }

                }
            }
        }

        setCampMarker(newmarker)

        newmarker.forEach(marker => {
            bounds.extend(marker.getPosition());
        });

        map.fitBounds(bounds);

        //     return () => {
        //         setMap(null); // removes the map and any added controls when the component unmounts
        //     };

    }



    async function setGeoTrack(selectedDate) {
        
        if (cataractHeatMap) {
            cataractHeatMap.setMap(null);
        }

        if (diaHeatMap) {
            diaHeatMap.setMap(null);
        }

        if (hypHeatMap) {
            hypHeatMap.setMap(null);
        }

        markerInstance.forEach(marker => marker.setMap(null));
        campmarker.forEach(marker => marker.setMap(null));  // to remove camp and househodl icons
        illnessMarker.forEach(marker => marker.setMap(null));         // illness icon marker icons

        var illnes = document.getElementById('illness');

        if (illnes) {
            illnes.remove();
        }

        var legend = document.getElementById('legend');

        // if (legend) {
        //     legend.remove();
        // }

        const google_ = await loader.load();
        const bounds = new google_.maps.LatLngBounds();

        // Remove old markers
        markers?.forEach((marker) => {
            marker.setMap(null);
        });

        if (polyLines) {
            polyLines.setMap(null);
        }

        iconMarker?.forEach((marker) => {
            marker.setMap(null);
        });

        // polyLines.setMap(null)

        const land_marker = [];

        const tracking = {
            url: tracking_icon,
            size: new google_.maps.Size(50, 50),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };

        const hosuehold_icon = {
            url: hosueholds,
            size: new google_.maps.Size(50, 50),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };

        const punchin = {
            url: shiftin,
            size: new google_.maps.Size(50, 50),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };

        const punchout = {
            url: shiftout,
            size: new google_.maps.Size(50, 50),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };



        const coords = selectedDate?.coords
        // console.log('coo',coords);

        const coordinates = coords.map(i => new google_.maps.LatLng(i.current_latitude, i.current_longitude));


        const polyline = new google_.maps.Polyline({
            path: coordinates,
            geodesic: true,
            strokeColor: '#ff7300',
            strokeOpacity: 1.0,
            strokeWeight: 2
        });

        polyline.setMap(map);
        setpolyLines(polyline)
        //add markers household and punchin , punchout ,
        coords?.map((i) => {
            let event = i.event.replace(/ /g, "").toLowerCase();

            if (event !== 'tracking') {
                const newMarker = new google_.maps.Marker({
                    position: new google_.maps.LatLng(
                        i.current_latitude,
                        i.current_longitude
                    ),
                    map: map,
                    icon: event == 'household' ? hosuehold_icon : event == 'punchin' ? punchin : event == 'punchout' ? punchout : tracking
                });
                land_marker.push(newMarker)
            }
        });
        setIconMarker(land_marker)
        coords.forEach(marker => {
            bounds.extend(new google_.maps.LatLng(marker.current_latitude, marker.current_longitude));
        });

        map.fitBounds(bounds);

        // if (process.env.GOOGLE_WAYPOINTS_LIMITED == "TRUE") {
        //   setWayPoints(coords)
        // } else {

        //   var result_arr = []
        //   var start = 0;

        //   while (start < coords?.length) {

        //     result_arr.push(coords.slice(start, start + 20));
        //     var waypt = coords.slice(start, start + 20)
        //     setWayPoints(waypt)
        //     start += 19
        //   }
        // }

        // //add markers household and punchin , punchout ,
        // coords?.map((i) => {
        //   let event = i.event.replace(/ /g, "").toLowerCase();

        //   const newMarker = new google_.maps.Marker({
        //     position: new google_.maps.LatLng(
        //       i.current_latitude,
        //       i.current_longitude
        //     ),
        //     map: map,
        //     icon: event == 'household' ? hosuehold_icon : event == 'punchin' ? punchin : event == 'punchout' ? punchout : tracking
        //   });
        //   land_marker.push(newMarker)
        // });

        // land_marker.forEach(marker => {
        //   bounds.extend(marker.getPosition());
        // });
        // setMarkers(land_marker)

    }


    async function createElement() {
        const google_ = await loader.load()
        const controlDiv = document.createElement("div");
        controlDiv.setAttribute("id", "illness");
        controlDiv.style.padding = "5px";
        controlDiv.style.backgroundColor = "#ff7100";
        controlDiv.style.border = "1px solid #ffffff";

        const controlUI = document.createElement("div");
        controlUI.style.cursor = "pointer";
        controlUI.style.textAlign = "center";
        controlUI.style.width = "100px";
        controlUI.style.fontWeight = "bold";
        controlUI.style.color = "#ffffff";
        controlUI.innerHTML = "Show Illness";

        let showMarkers = true; // initialize showMarkers variable to true

        controlUI.addEventListener("click", () => {
            showMarkers = !showMarkers; // toggle showMarkers variable on click

            if (showMarkers) {
                setHeatOrHouse(true)
                controlUI.innerHTML = "Show Illness";
            } else {
                setHeatOrHouse(false)
                controlUI.innerHTML = "Show HouseHold";
            }
        });

        controlDiv.appendChild(controlUI);
        map.controls[google_.maps.ControlPosition.TOP_RIGHT].push(controlDiv);

    }

    async function createLegend() {
        const google_ = await loader.load()

        const icons = {
            cataract: {
                name: "Cataract",
                icon: lightblue
            },
            surgery: {
                name: 'Cataract Surgery Done',
                icon: green
            },
            diabetes: {
                name: 'Diabates',
                icon: red
            },
            hypertension: {
                name: "Hypertension",
                icon: orange
            },
            candd: {
                name: 'Catarct & Dia',
                icon: violet
            },
            candh: {
                name: 'Cataract & Hypertension',
                icon: brown
            },
            hyanddia: {
                name: 'Hypertension & Diabetes',
                icon: purple
            },
            chd: {
                name: 'Cataract & Hyper & Diabets',
                icon: yellow
            }

        };


        const legend = document.createElement("div");
        legend.setAttribute("id", "legend");
        legend.style.padding = "5px";
        legend.style.backgroundColor = "#ffffff"; // You might want to change the background color to match your design
        legend.style.border = "1px solid #ffffff"; // You might want to add a border color to differentiate the legend from the map

        for (const key in icons) {
            const type = icons[key];
            const name = type.name;
            const icon = type.icon;
            const div = document.createElement("div");

            // Here, replace "icon" with the correct path to your icon images directory
            div.innerHTML = `<div><img src="${icon}" width="24" height="24"> <h6>${name}</h6></div>`;
            legend.appendChild(div);
        }

        map.controls[google_.maps.ControlPosition.RIGHT_CENTER].push(legend);
    }



    async function setHeatMap() {
        campmarker.forEach(marker => marker.setMap(null));  // to remove camp and househodl icons
        illnessMarker.forEach(marker => marker.setMap(null));  // to remove illness


        const legend = document.getElementById("legend");

        if (legend == null) {
            createLegend()
        }

        const google_ = await loader.load();
        const bounds = new google_.maps.LatLngBounds();


        if (cataractHeatMap) {
            cataractHeatMap.setMap(null);
        }

        if (diaHeatMap) {
            diaHeatMap.setMap(null);
        }

        if (hypHeatMap) {
            hypHeatMap.setMap(null);
        }


        const cataract = [
            "rgba(9, 144, 255,0)",
            "rgba(0, 153, 255,1)",
            "rgba(0, 162, 255,1)",
            "rgba(0, 170, 255,1)",
            "rgba(0, 178, 255,1)",
            "rgba(0, 186, 255,1)",
            "rgba(0, 193, 255,1)",
            "rgba(0, 200, 255,1)",
            "rgba(0, 207, 255,1)",
            "rgba(8, 214, 255,1)",
        ];

        const dia = [
            "rgba(255, 114, 0,0)",
            "rgba(255, 130, 0,1)",
            "rgba(255, 146, 0,1)",
            "rgba(255, 162, 0,1)",
            "rgba(255, 178, 0,1)",
            "rgba(255, 194, 0,1)",
            "rgba(255, 209, 0,1)",
            "rgba(255, 224, 0,1)",
            "rgba(252, 240, 0,1)",
            "rgba(246, 255, 0,1)",
        ]

        const hy = [
            "rgba(205, 38, 38,0)",
            "rgba(216, 40, 57,0)",
            "rgba(225, 44, 76,1)",
            "rgba(233, 51, 94,1)",
            "rgba(239, 59, 113,1)",
            "rgba(244, 69, 132,1)",
            "rgba(248, 80, 151,1)",
            "rgba(250, 91, 170,1)",
            "rgba(250, 103, 188,1)",
            "rgba(249, 115, 206,1)",
        ]

        var intensity = 15;

        var h_diabetes = { maxIntensity: intensity, data: [] }
        var h_hypertension = { maxIntensity: intensity, data: [] }
        var h_cataract = { maxIntensity: intensity, data: [] }


        const cataract_img = {
            url: lightblue,
            size: new google_.maps.Size(20, 20),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };

        const surgeryDone = {
            url: green,
            size: new google_.maps.Size(20, 20),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };

        const dia_img = {
            url: red,
            size: new google_.maps.Size(20, 20),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };
        const hyp_img = {
            url: orange,
            size: new google_.maps.Size(20, 20),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };

        const cataractDia = {
            url: violet,
            size: new google_.maps.Size(20, 20),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };

        const cataractHyper = {
            url: brown,
            size: new google_.maps.Size(20, 20),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };

        const diaHyper = {
            url: purple,
            size: new google_.maps.Size(20, 20),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };

        const cataractHyperDia = {
            url: yellow,
            size: new google_.maps.Size(20, 20),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };

        var allMarks = [];
        heatMap?.cataract?.illness_coords?.forEach((coord) => {
            let marker = new google_.maps.Marker({
                position: new google_.maps.LatLng(
                    coord.latitude,
                    coord.longitude
                ),
                map: map,
                icon: cataract_img
            });
            allMarks.push(marker);
        });

        heatMap?.surgerydonedetails?.illness_coords?.forEach((coord) => {
            let marker = new google_.maps.Marker({
                position: new google_.maps.LatLng(
                    coord.latitude,
                    coord.longitude
                ),
                map: map,
                icon: surgeryDone
            });
            allMarks.push(marker);
        });

        heatMap?.diabetes?.illness_coords?.forEach((coord) => {
            let marker = new google_.maps.Marker({
                position: new google_.maps.LatLng(
                    coord.latitude,
                    coord.longitude
                ),
                map: map,
                icon: dia_img
            });
            allMarks.push(marker);
        });

        heatMap?.hypertension?.illness_coords?.forEach((coord) => {
            let marker = new google_.maps.Marker({
                position: new google_.maps.LatLng(
                    coord.latitude,
                    coord.longitude
                ),
                map: map,
                icon: hyp_img
            });
            allMarks.push(marker);
        });

        heatMap?.cataractDia?.illness_coords?.forEach((coord) => {
            let marker = new google_.maps.Marker({
                position: new google_.maps.LatLng(
                    coord.latitude,
                    coord.longitude
                ),
                map: map,
                icon: cataractDia
            });
            allMarks.push(marker);
        });

        heatMap?.cataractHyper?.illness_coords?.forEach((coord) => {
            let marker = new google_.maps.Marker({
                position: new google_.maps.LatLng(
                    coord.latitude,
                    coord.longitude
                ),
                map: map,
                icon: cataractHyper
            });
            allMarks.push(marker);
        });

        heatMap?.HyperDia?.illness_coords?.forEach((coord) => {
            let marker = new google_.maps.Marker({
                position: new google_.maps.LatLng(
                    coord.latitude,
                    coord.longitude
                ),
                map: map,
                icon: diaHyper
            });
            allMarks.push(marker);
        });

        heatMap?.cataractHyperDia?.illness_coords?.forEach((coord) => {
            let marker = new google_.maps.Marker({
                position: new google_.maps.LatLng(
                    coord.latitude,
                    coord.longitude
                ),
                map: map,
                icon: cataractHyperDia
            });
            allMarks.push(marker);
        });

        setIllnessMarker(allMarks)

        heatMap?.diabetes?.illness_coords?.forEach((coord) => {
            h_diabetes.data.push(new google_.maps.LatLng(coord.latitude, coord.longitude));
        });
        heatMap?.hypertension?.illness_coords?.forEach((coord) => {
            h_hypertension.data.push(new google_.maps.LatLng(coord.latitude, coord.longitude));
        });
        heatMap?.cataract?.illness_coords?.forEach((coord) => {
            h_cataract.data.push(new google_.maps.LatLng(coord.latitude, coord.longitude));
        });

        const dia_heatmap = new google_.maps.visualization.HeatmapLayer({
            data: h_diabetes.data,
            gradient: dia,
        });

        const hyp_heatmap = new google_.maps.visualization.HeatmapLayer({
            data: h_hypertension.data,
            gradient: hy,
        });


        const heatmap = new google_.maps.visualization.HeatmapLayer({
            data: h_cataract.data,
            gradient: cataract,
        });

        setcataractHeatMap(heatmap);
        setdiaHeatMap(dia_heatmap);
        sethypHeatMap(hyp_heatmap);

        dia_heatmap.setMap(map);
        hyp_heatmap.setMap(map);
        heatmap.setMap(map);

        allMarks.forEach(marker => {
            bounds.extend(marker.getPosition());
        });
        map.fitBounds(bounds);

    }

    async function selectedHeatMap(illness) {
        const google_ = await loader.load();

        cataractHeatMap?.setMap(null)
        diaHeatMap?.setMap(null)
        hypHeatMap?.setMap(null)

        const cataract = [
            "rgba(9, 144, 255,0)",
            "rgba(0, 153, 255,1)",
            "rgba(0, 162, 255,1)",
            "rgba(0, 170, 255,1)",
            "rgba(0, 178, 255,1)",
            "rgba(0, 186, 255,1)",
            "rgba(0, 193, 255,1)",
            "rgba(0, 200, 255,1)",
            "rgba(0, 207, 255,1)",
            "rgba(8, 214, 255,1)",
        ];

        const dia = [
            "rgba(255, 114, 0,0)",
            "rgba(255, 130, 0,1)",
            "rgba(255, 146, 0,1)",
            "rgba(255, 162, 0,1)",
            "rgba(255, 178, 0,1)",
            "rgba(255, 194, 0,1)",
            "rgba(255, 209, 0,1)",
            "rgba(255, 224, 0,1)",
            "rgba(252, 240, 0,1)",
            "rgba(246, 255, 0,1)",
        ]

        const hy = [

            "rgba(205, 38, 38,0)",
            "rgba(216, 40, 57,0)",
            "rgba(225, 44, 76,1)",
            "rgba(233, 51, 94,1)",
            "rgba(239, 59, 113,1)",
            "rgba(244, 69, 132,1)",
            "rgba(248, 80, 151,1)",
            "rgba(250, 91, 170,1)",
            "rgba(250, 103, 188,1)",
            "rgba(249, 115, 206,1)",
        ]

        var data = [];

        if (illness == 'cataract') {
            heatMap[illness]?.cataract_coords?.forEach((coord) => {
                data.push(new google_.maps.LatLng(coord.latitude, coord.longitude));
            });
        } else {
            heatMap[illness]?.illness_coords?.forEach((coord) => {
                data.push(new google_.maps.LatLng(coord.latitude, coord.longitude));
            });
        }


        var heat = new google_.maps.visualization.HeatmapLayer({
            data: data,
            gradient: illness == 'cataract' ? cataract : illness == 'hypertension' ? hy : illness == 'diabetes' ? dia : "",
            // radius : 20
        });

        if (illness == 'cataract') {
            setcataractHeatMap(heat)
            heat.setMap(map)
        } else if (illness == 'hypertension') {
            sethypHeatMap(heat);
            heat.setMap(map)
        } else if (illness == 'diabetes') {
            setdiaHeatMap(heat);
            heat.setMap(map)
        }

    }

    async function addCampviaClick(hospitalMarker, hospital) {
        let newMarkers = []
        let camp_details = [];
        const google_ = await loader.load();
        const markerInfoWindow = new google_.maps.InfoWindow();


        const camp_icon = {
            url: camp_png,
            size: new google_.maps.Size(50, 50),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };

        const hospital_icon = {
            url: redcross,
            size: new google_.maps.Size(50, 50),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };


        await axios.post('getGISCampList', {
            hospital_id: [hospital.hospital_id],
        }).then((res) => {

            camp_details = res.data.result.result

            // Create marker for hospital
            let newMarker = new google_.maps.Marker({
                position: hospitalMarker.getPosition(),
                map: map,
                icon: hospital_icon,
            });
            newMarkers.push(newMarker);

            // Create markers for camps
            camp_details?.forEach((camp) => {
                camp?.campList?.forEach((cam) => {
                    let campMarker = new google_.maps.Marker({
                        position: new google_.maps.LatLng(cam.current_latitude, cam.current_longitude),
                        icon: camp_icon,
                    });


                    campMarker.addListener("click", async () => {

                        var data = {
                            camp_id: [cam?.camp_id]
                        }

                        addHosueholdsViaClick(data)
                    })

                    newMarkers.push(campMarker);

                });
            });
            setCampMarker(newMarkers)

            // Add all markers to map
            newMarkers.forEach((marker) => {
                marker.setMap(map);
            });

            setMarkerInstance(newMarkers);

            // Fit map to bounds of hospital and camps
            let bounds = new google_.maps.LatLngBounds();
            newMarkers.forEach((marker) => {
                bounds.extend(marker.getPosition());
            });
            map.fitBounds(bounds);
        });
    }

    async function addHosueholdsViaClick(data) {


        const google_ = await loader.load();
        const markerInfoWindow = new google_.maps.InfoWindow();


        const camp_icon = {
            url: camp_png,
            size: new google_.maps.Size(50, 50),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };

        await axios.post('getCampSurveyList', data).then((res) => {
            var data_ = res.data.result
            let newcampMarker = [];

            markerInstance.forEach(marker => marker.setMap(null));
            campmarker.forEach(marker => marker.setMap(null));  // to remove camp 

            const hosuehold = {
                url: hosueholds,
                size: new google_.maps.Size(50, 50),
                origin: new google_.maps.Point(0, 0),
                anchor: new google_.maps.Point(25, 25),
            };

            for (let i = 0; i <= Object.keys(data_).length - 1; i++) {
                let iItem = data_[Object.keys(data_)[i]];
                // Object.keys(data_[i]?.coords)?.forEach((j) => {
                if (iItem.coords) {

                    for (let j = 0; j <= Object.keys(iItem.coords).length - 1; j++) {
                        let jItem = Object.keys(iItem.coords)[j];

                        if (iItem.coords?.camp_coords && iItem.coords?.camp_coords.length > 0) {
                            // data_[i]?.coords[j]?.forEach((k) => {
                            for (let k = 0; k <= iItem.coords[jItem].length - 1; k++) {
                                let kItem = iItem.coords[jItem][k];
                                let marker = ""
                                marker = new google_.maps.Marker({
                                    position: new google_.maps.LatLng(
                                        kItem.current_latitude,
                                        kItem.current_longitude
                                    ),
                                    map: map,
                                    icon: jItem == 'camp_coords' ? camp_icon : hosuehold
                                });

                                var data = {
                                    lat: kItem.current_latitude,
                                    lng: kItem.current_longitude
                                }

                                if (jItem == 'household_coords') {
                                    marker.addListener("click", async () => {

                                        var data = data_[Object.keys(data_)[i]].detail?.household_detail?.find((x) => { if (x.household_id == kItem.household_id) { return x } })

                                        detailData(data, 'household')


                                        markerInfoWindow.close();
                                        const contentString = `<div><h3>Patient Name : ${data?.name}</h3>
                                                        <h4>Patient Age : ${data?.age}                    
                                        </div>`;
                                        markerInfoWindow.setContent(contentString);
                                        markerInfoWindow.open(map, marker);
                                    })
                                } else {

                                    marker.addListener("click", async () => {
                                        var data = data_[Object.keys(data_)[i]].detail?.camp_detail?.find((x) => { if (x.camp_id == kItem.camp_id) { return x } })

                                        detailData(data, 'camp')


                                        markerInfoWindow.close();
                                        const contentString = `<div>
                                                                    <img width='100%' height='100' src=${logo} />
                                                                    <h3 class='mar' >Camp place : ${data?.camp_place} </h3>
                                                                </div>`;
                                        markerInfoWindow.setContent(contentString);
                                        markerInfoWindow.open(map, marker);
                                    })
                                }
                                newcampMarker.push(marker);
                            }
                        }

                    }
                }
            }
            let bounds = new google_.maps.LatLngBounds();
            newcampMarker.forEach((marker) => {
                bounds.extend(marker.getPosition());
            });
            map.fitBounds(bounds);

        })
    }

    return (
        <>
            <div id='hospital' className='newmaps_' > </div>
            {/* <img src={logo} id='logo' onClick={() => navigate('/masters')} /> */}
        </>
    )
}

export default CommonMap