import React, { useState, useEffect } from 'react'
import { Loader } from "@googlemaps/js-api-loader"

import { useAxios } from "../../hooks/useAxios";
import redcross from '../../assets/redcross.png'
import camp_png from '../../assets/camp.png'

const loader = new Loader({
    apiKey: "AIzaSyDoxlT05aCQHJP_Ni5tkXixB3JnYj6ltPY",
    version: "weekly",
    libraries: ['visualization'],
})


const state = {
    to_date: "",
    from_date: "",
    camp: '',
    vhv: '',
    vhvId: '',
    time: 0,
    initial: true,
    customSearch: false,
}

const HospitalMap = ({ hospitalData, restriction, selectedHospital, heatMap, selectedIllness, reset, camp_list }) => {
    const { axios } = useAxios();
    const [infoWindow, setInfoWindow] = useState(null);
    const [map, setMap] = useState(null)
    const [markerInstance, setMarkerInstance] = useState([])
    const [position, setPosition] = useState({
        lat: 21.1458,
        lng: 79.0882
    })
    const [cataractHeatMap, setcataractHeatMap] = useState(null)
    const [diaHeatMap, setdiaHeatMap] = useState(null)
    const [hypHeatMap, sethypHeatMap] = useState(null)

    const [markers, setMarkers] = useState([])


    const myStyles = [
        {
            featureType: "poi",
            elementType: "labels",
            stylers: [
                { visibility: "off" }
            ]
        }
    ];

    const WORLD_BOUNDS = {
        north: 70,
        south: -65,
        west: -120,
        east: 170,
    };

    const mapOptions = {
        center: {
            lat: position.lat,
            lng: position.lng,
        },
        zoom: 4,
        disableDefaultUI: true,
        zoomControl: true,
        streetViewControl: false,
        fullscreenControl: true,
        // styles: myStyles,
        // maxZoom: 14,
        restriction: {
            latLngBounds: WORLD_BOUNDS,
            strictBounds: false,
        },
    }

    useEffect(() => {
        initializeHospitalMap()
    }, [reset])

    useEffect(() => {
        setHeatMap()
    }, [heatMap])

    useEffect(() => {
        selectedHeatMap(selectedIllness)
    }, [selectedIllness])

    useEffect(() => {
        handleHospital()
    }, [hospitalData])

    useEffect(() => {
        if (camp_list != null || camp_list != undefined) {
            handleCampMap()
        }
    }, [camp_list])


    async function handleCampMap() {
        const google_ = await loader.load()
        markers.forEach(marker => marker.setMap(null));         // illness icon marker icons
        markerInstance.forEach(marker => marker.setMap(null));  // to remove camp and househodl icons

        const circleArray = [];

        const camp_icon = {
            url: camp_png,
            size: new google_.maps.Size(50, 50),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };


        function resizeMarkers(map) {
            markerInstance.forEach(function (marker) {
                var pixelSizeAtZoom0 = 5; // the size of the icon at zoom level 0
                var maxPixelSize = 30; // restricts the maximum size of the icon

                var zoom = map.getZoom();
                var relativePixelSize = Math.round(pixelSizeAtZoom0 * Math.pow(2, zoom));

                if (relativePixelSize > maxPixelSize) {
                    relativePixelSize = maxPixelSize;
                }

                marker.setIcon({
                    url: marker.getIcon().url, //marker's same icon graphic
                    size: null,
                    origin: null,
                    anchor: null,
                    scaledSize: new google_.maps.Size(relativePixelSize, relativePixelSize) //changes the scale
                })
            });
        }

        google_.maps.event.addListener(map, 'zoom_changed', function () {
            resizeMarkers(map);
        });


        const bounds = new google_.maps.LatLngBounds();
        const infoWindow = new google_.maps.InfoWindow();
        const markerInfoWindow = new google_.maps.InfoWindow();

        let newmarker = [];

        // Object.keys(camp_list)?.forEach((i) => {
        for (let i = 0; i <= Object.keys(camp_list).length - 1; i++) {
            let iItem = camp_list[Object.keys(camp_list)[i]];
            // Object.keys(camp_list[i]?.coords)?.forEach((j) => {
            if (iItem.coords) {

                for (let j = 0; j <= Object.keys(iItem.coords).length - 1; j++) {
                    let jItem = Object.keys(iItem.coords)[j];

                    if (iItem.coords?.camp_coords && iItem.coords?.camp_coords.length > 0) {
                        // camp_list[i]?.coords[j]?.forEach((k) => {
                        for (let k = 0; k <= iItem.coords[jItem].length - 1; k++) {
                            let kItem = iItem.coords[jItem][k];
                            let marker = ""
                            marker = new google_.maps.Marker({
                                position: new google_.maps.LatLng(
                                    kItem.current_latitude,
                                    kItem.current_longitude
                                ),
                                map: map,
                                icon: camp_icon
                            });

                            var data = {
                                lat: kItem.current_latitude,
                                lng: kItem.current_longitude
                            }



                            // marker.addListener("click", async () => {
                            //     var data = camp_list[Object.keys(camp_list)[i]].detail?.camp_detail?.find((x) => { if (x.camp_id == kItem.camp_id) { return x } })

                            //     detailData(data, 'camp')
                            //     console.log('camp_coords', data);

                            //     markerInfoWindow.close();
                            //     const contentString = `<div><h3>Camp place : ${data?.camp_place}</h3>

                            //             </div>`;
                            //     markerInfoWindow.setContent(contentString);
                            //     markerInfoWindow.open(map, marker);
                            // })

                            newmarker.push(marker);


                        }
                    }

                }
            }
        }

        setMarkerInstance(newmarker)

        newmarker.forEach(marker => {
            bounds.extend(marker.getPosition());
        });

        map.fitBounds(bounds);

    }


    async function initializeHospitalMap() {
        const google_ = await loader.load()

        setInfoWindow(new google_.maps.InfoWindow());

        const mapField = new google_.maps.Map(document.getElementById("hospital"), mapOptions);

        setMap(mapField);

    }


    async function handleHospital(e) {
        markers.forEach(marker => marker.setMap(null));         // illness icon marker icons
        markerInstance.forEach(marker => marker.setMap(null));  // to remove camp and househodl icons

        const google_ = await loader.load();
        const bounds = new google_.maps.LatLngBounds();
        const infoWindow = new google_.maps.InfoWindow();
        const circleArray = [];

        function resizeMarkers(map) {
            markerInstance.forEach(function (marker) {
                var pixelSizeAtZoom0 = 5; // the size of the icon at zoom level 0
                var maxPixelSize = 30; // restricts the maximum size of the icon

                var zoom = map.getZoom();
                var relativePixelSize = Math.round(pixelSizeAtZoom0 * Math.pow(2, zoom));

                if (relativePixelSize > maxPixelSize) {
                    relativePixelSize = maxPixelSize;
                }

                marker.setIcon({
                    url: marker.getIcon().url, //marker's same icon graphic
                    size: null,
                    origin: null,
                    anchor: null,
                    scaledSize: new google_.maps.Size(relativePixelSize, relativePixelSize) //changes the scale
                })
            });
        }

        google_.maps.event.addListener(map, 'zoom_changed', function () {
            resizeMarkers(map);
        });

        const hospital_icon = {
            url: redcross,
            size: new google_.maps.Size(50, 50),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };

        const camp_icon = {
            url: camp_png,
            size: new google_.maps.Size(50, 50),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };

        markerInstance.forEach(marker => marker.setMap(null));
        const markerInfoWindow = new google_.maps.InfoWindow();


        hospitalData?.map((hospital) => {

            if (hospital?.hospital_id !== "1") {

                let hospitalMarker = new google_.maps.Marker({
                    position: new google_.maps.LatLng(hospital.latitude, hospital.longitude),
                    map: map,
                    icon: hospital_icon,
                });

                markerInstance.push(hospitalMarker)

                const radius = Math.floor(Math.random() * (5 - 1 + 1)) + 1; // Random number between 1 and 5 (inclusive)

                const circle = new google_.maps.Circle({
                    map: map,
                    center: hospitalMarker.getPosition(),
                    radius: hospitalData.radius,
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#FF0000',
                    fillOpacity: 0.35
                });
                circleArray.push(circle);

                // Set the circle's center to the marker's position
                circle.bindTo('center', hospitalMarker, 'position');

                if (hospitalData?.length === 1) {
                    let camp_details = [];

                    const fetchGisCamp = async () => {

                        await axios.post('getGISCampList', {
                            hospital_id: [hospital.hospital_id],
                        }).then((res) => {
                            camp_details = res.data.result.result;

                            let campBounds = new google_.maps.LatLngBounds(); // create a new bounds object for camp markers

                            camp_details?.map((camps) => {
                                camps?.campList?.map((cam) => {
                                    let campMarker = new google_.maps.Marker({
                                        position: new google_.maps.LatLng(cam.current_latitude, cam.current_longitude),
                                        map: map,
                                        icon: camp_icon,
                                    });

                                    markerInstance.push(campMarker);
                                    campBounds.extend(campMarker.getPosition()); // extend the bounds to include the camp marker position
                                });
                            });

                            // extend the bounds to include the hospital marker position and the camp markers positions
                            bounds.extend(hospitalMarker.getPosition());
                            bounds.union(campBounds);

                            map.fitBounds(bounds);
                        });
                    }

                    fetchGisCamp();
                } else {

                    hospitalMarker?.addListener('mouseover', () => {
                        markerInfoWindow.close();
                        const contentString = `<div style='width:250px;height:auto'>
                                                    <div style='display:flex;height:25%;align-items:center;justify-content:space-around;'>
                                                        <h3 style='font-weight: bold'>Hospital Name </h3> 
                                                        <span style='font-weight: 300; color: #ff7300' >${hospital?.hospital_name}</span>
                                                    </div>
                                                    <div >

                                                    </div>
                                                 </div>`;
                        markerInfoWindow.setContent(contentString);
                        markerInfoWindow.open(map, hospitalMarker);
                    })

                    hospitalMarker.addListener('click', () => {
                        // Remove all existing markers
                        setMarkerInstance([]);
                        markerInstance.forEach(marker => marker.setMap(null));

                        let newMarkers = []

                        let camp_details = [];

                        let hospitalId = {
                            hospital_id: [hospital.hospital_id]
                        }

                        selectedHospital(hospitalId)

                        axios.post('getGISCampList', {
                            hospital_id: [hospital.hospital_id],
                        }).then((res) => {

                            camp_details = res.data.result.result

                            // Create marker for hospital
                            let newMarker = new google_.maps.Marker({
                                position: hospitalMarker.getPosition(),
                                map: map,
                                icon: hospital_icon,
                            });
                            newMarkers.push(newMarker);

                            // Create markers for camps
                            camp_details?.forEach((camp) => {
                                camp?.campList?.forEach((cam) => {
                                    let campMarker = new google_.maps.Marker({
                                        position: new google_.maps.LatLng(cam.current_latitude, cam.current_longitude),
                                        icon: camp_icon,
                                    });
                                    newMarkers.push(campMarker);
                                });
                            });

                            // Add all markers to map
                            newMarkers.forEach((marker) => {
                                marker.setMap(map);
                            });

                            setMarkerInstance(newMarkers);

                            // Fit map to bounds of hospital and camps
                            let bounds = new google_.maps.LatLngBounds();
                            newMarkers.forEach((marker) => {
                                bounds.extend(marker.getPosition());
                            });
                            map.fitBounds(bounds);
                        });
                    });

                    bounds.extend(hospitalMarker.getPosition());
                }
            }

        });

        map.fitBounds(bounds);
    }


    async function setHeatMap() {
        const google_ = await loader.load();

        const cataract = [
            "rgba(9, 144, 255,0)",
            "rgba(0, 153, 255,1)",
            "rgba(0, 162, 255,1)",
            "rgba(0, 170, 255,1)",
            "rgba(0, 178, 255,1)",
            "rgba(0, 186, 255,1)",
            "rgba(0, 193, 255,1)",
            "rgba(0, 200, 255,1)",
            "rgba(0, 207, 255,1)",
            "rgba(8, 214, 255,1)",
        ];

        const dia = [
            "rgba(255, 114, 0,0)",
            "rgba(255, 130, 0,1)",
            "rgba(255, 146, 0,1)",
            "rgba(255, 162, 0,1)",
            "rgba(255, 178, 0,1)",
            "rgba(255, 194, 0,1)",
            "rgba(255, 209, 0,1)",
            "rgba(255, 224, 0,1)",
            "rgba(252, 240, 0,1)",
            "rgba(246, 255, 0,1)",
        ]

        const hy = [
            "rgba(205, 38, 38,0)",
            "rgba(216, 40, 57,0)",
            "rgba(225, 44, 76,1)",
            "rgba(233, 51, 94,1)",
            "rgba(239, 59, 113,1)",
            "rgba(244, 69, 132,1)",
            "rgba(248, 80, 151,1)",
            "rgba(250, 91, 170,1)",
            "rgba(250, 103, 188,1)",
            "rgba(249, 115, 206,1)",
        ]

        var intensity = 15;

        var h_diabetes = { maxIntensity: intensity, data: [] }
        var h_hypertension = { maxIntensity: intensity, data: [] }
        var h_cataract = { maxIntensity: intensity, data: [] }



        heatMap?.diabetes?.illness_coords?.forEach((coord) => {
            h_diabetes.data.push(new google_.maps.LatLng(coord.latitude, coord.longitude));
        });
        heatMap?.hypertension?.illness_coords?.forEach((coord) => {
            h_hypertension.data.push(new google_.maps.LatLng(coord.latitude, coord.longitude));
        });
        heatMap?.cataract?.illness_coords?.forEach((coord) => {
            h_cataract.data.push(new google_.maps.LatLng(coord.latitude, coord.longitude));
        });

        const dia_heatmap = new google_.maps.visualization.HeatmapLayer({
            data: h_diabetes.data,
            gradient: dia,
            // radius : 20
        });

        const hyp_heatmap = new google_.maps.visualization.HeatmapLayer({
            data: h_hypertension.data,
            gradient: hy,
            // radius :20
        });


        const heatmap = new google_.maps.visualization.HeatmapLayer({
            data: h_cataract.data,
            gradient: cataract,
            // radius : 20
        });

        setcataractHeatMap(heatmap);
        setdiaHeatMap(dia_heatmap);
        sethypHeatMap(hyp_heatmap);

        dia_heatmap.setMap(map);
        hyp_heatmap.setMap(map);
        heatmap.setMap(map);
    }

    async function selectedHeatMap(illness) {
        const google_ = await loader.load();

        cataractHeatMap?.setMap(null)
        diaHeatMap?.setMap(null)
        hypHeatMap?.setMap(null)

        const cataract = [
            "rgba(9, 144, 255,0)",
            "rgba(0, 153, 255,1)",
            "rgba(0, 162, 255,1)",
            "rgba(0, 170, 255,1)",
            "rgba(0, 178, 255,1)",
            "rgba(0, 186, 255,1)",
            "rgba(0, 193, 255,1)",
            "rgba(0, 200, 255,1)",
            "rgba(0, 207, 255,1)",
            "rgba(8, 214, 255,1)",
        ];

        const dia = [
            "rgba(255, 114, 0,0)",
            "rgba(255, 130, 0,1)",
            "rgba(255, 146, 0,1)",
            "rgba(255, 162, 0,1)",
            "rgba(255, 178, 0,1)",
            "rgba(255, 194, 0,1)",
            "rgba(255, 209, 0,1)",
            "rgba(255, 224, 0,1)",
            "rgba(252, 240, 0,1)",
            "rgba(246, 255, 0,1)",
        ]

        const hy = [

            "rgba(205, 38, 38,0)",
            "rgba(216, 40, 57,0)",
            "rgba(225, 44, 76,1)",
            "rgba(233, 51, 94,1)",
            "rgba(239, 59, 113,1)",
            "rgba(244, 69, 132,1)",
            "rgba(248, 80, 151,1)",
            "rgba(250, 91, 170,1)",
            "rgba(250, 103, 188,1)",
            "rgba(249, 115, 206,1)",
        ]

        var data = [];

        if (illness == 'cataract') {
            heatMap[illness]?.cataract_coords?.forEach((coord) => {
                data.push(new google_.maps.LatLng(coord.latitude, coord.longitude));
            });
        } else {
            heatMap[illness]?.illness_coords?.forEach((coord) => {
                data.push(new google_.maps.LatLng(coord.latitude, coord.longitude));
            });
        }


        var heat = new google_.maps.visualization.HeatmapLayer({
            data: data,
            gradient: illness == 'cataract' ? cataract : illness == 'hypertension' ? hy : illness == 'diabetes' ? dia : "",
            // radius : 20
        });

        if (illness == 'cataract') {
            setcataractHeatMap(heat)
            heat.setMap(map)
        } else if (illness == 'hypertension') {
            sethypHeatMap(heat);
            heat.setMap(map)
        } else if (illness == 'diabetes') {
            setdiaHeatMap(heat);
            heat.setMap(map)
        }

    }

    return (
        <>
            <div id='hospital' > </div>
        </>
    )
}

export default HospitalMap