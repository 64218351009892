// import { TextField, Stack, Button } from "@mui/material";
import {
  TextField,
  Stack,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import React, { useState } from "react";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const FieldworkerReportFilter = ({
  filterObject,
  handleuploadExcel,
  hospitaldrop,
  rows,
  fieldWorkers,
  handleHospital,
}) => {
  const [campStartDate, setStartDate] = useState(null);
  const [campEndDate, setEndDate] = useState(null);
  const [hospitalname, setHospitalName] = useState("");
  const [field_worker_name, setFieldworkerName] = useState("");
  const [campPlace, setCampPlace] = useState("");
  const [campNumber, setCampNumber] = useState("");

  const [fieldreset, setFieldreset] = useState({
    vhv: 1,
    cbbf: 2,
  });

  const handleSubmit = () => {
    var hospital = hospitaldrop?.find((x) => x._id == hospitalname);

    let obj = {
      campStart: campStartDate
        ? moment(campStartDate.$d).format("YYYY-MM-DD")
        : "",
      campEnd: campEndDate ? moment(campEndDate.$d).format("YYYY-MM-DD") : "",
      hospitalName: hospital == undefined ? "" : hospital?.hospital_name,
      // hospitalName: hospital?.hospital_name,
      name: field_worker_name,
      camp_num: campNumber,
      camp_place: campPlace,
    };
    filterObject(obj);
  };

  const handleClose = () => {
    let obj = {
      campStart: "",
      campEnd: "",
      hospitalName: "",
      name: "",
      camp_num: "",
      camp_place: "",
    };
    setStartDate(null);
    setEndDate(null);
    setHospitalName(obj.hospitalName);
    setFieldworkerName(obj.name);
    setCampPlace(obj.camp_place);
    setCampNumber(obj.camp_num);
    filterObject(obj);
    // searchValue()
    setFieldreset({
      ...fieldreset,
      vhv: fieldreset.vhv + 0.1,
      cbbf: fieldreset?.cbbf + 0.1,
    });
  };

  function firstLetter(s) {
    return s?.replace(/^.{1}/g, s[0].toUpperCase());
  }

  function handleHospitalDrop(e) {
    setHospitalName(e.target.value);
    handleHospital(e);
  }

  return (
    <div
      style={{
        marginBottom: "0.5%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        style={{
          width: "98%",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            placeholder="From Date"
            renderInput={(props) => (
              <TextField {...props} size="small" style={{ width: "15%" }} />
            )}
            value={campStartDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            placeholder="To Date"
            renderInput={(props) => (
              <TextField {...props} size="small" style={{ width: "15%" }} />
            )}
            value={campEndDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
            minDate={campStartDate ? new Date(moment(campStartDate.$d)) : ""}
          />
        </LocalizationProvider>
        {/* <TextField
                    size="small"
                    variant="outlined"
                    placeholder="Hospital Name"
                    style={{ width: '10%' }}
                    value={hospitalname}
                    onChange={(e) => setHospitalName(e.target.value)}
                /> */}

        <FormControl style={{ width: "15%" }} size="small">
          <InputLabel id="select-label">Select Hospitals</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            variant="outlined"
            // onChange={(event) => {
            //   setFieldValue(field.name, event.target.value);
            // }}
            // value={hospitalname}
            // onChange={(e) => {
            //     setHospitalName(e.target.value)
            // }
            // }
            label="Select Hospitals"
            value={hospitalname}
            onChange={(e) => {
              handleHospitalDrop(e);
            }}
          >
            {hospitaldrop?.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {firstLetter(option.hospital_name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* <TextField
                    size="small"
                    variant="outlined"
                    style={{ width: '10%' }}
                    placeholder="Fieldworker Name"
                    value={fieldworkerName}
                    onChange={(e) => setFieldworkerName(e.target.value)}
                /> */}

        <Autocomplete
          size="small"
          disablePortal
          className="date"
          variant="standard"
          id="combo-box-demo"
          sx={{
            input: {
              "&::placeholder": {
                // <----- Add this.
                opacity: 1,
                marginBottom: "-5px",
                fontSize: "10px",
              },
            },
            backgroundColor: "#ffffff",
            margin: " 0 5px",
            // borderRadius: '5px',
            // width: '90%'
            width: "12%",
          }}
          key={fieldreset?.vhv}
          ListboxProps={{
            sx: { fontSize: 13 },
          }}
          options={
            fieldWorkers?.vhv?.filed_workerList?.length > 0
              ? fieldWorkers?.vhv?.filed_workerList
              : [{ field_worker_name: "No data found" }]
          }
          getOptionLabel={(option) => firstLetter(option.field_worker_name)}
          // defaultValue={searchValue?.fieldworker == "" ? null : searchValue?.fieldworker}
          onChange={(data, value) => {
            setFieldworkerName(value?.field_worker_name);
            setFieldreset({ ...fieldreset, cbbf: fieldreset.cbbf + 0.1 });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                style: {
                  fontSize: "13px", // Adjust the font size of the label here
                  paddingTop: "5px",
                },
              }}
              color="black"
              label="Select VHV FieldWorker"
            />
          )}
        />

        <Autocomplete
          size="small"
          disablePortal
          className="date"
          variant="standard"
          id="combo-box-demo"
          sx={{
            input: {
              "&::placeholder": {
                opacity: 1,
                marginBottom: "-5px",
              },
            },
            backgroundColor: "#ffffff",
            margin: " 0 5px",
            // borderRadius: '5px',
            // width: '90%'
            width: "12%",
          }}
          key={fieldreset?.cbbf}
          ListboxProps={{
            sx: { fontSize: 13 },
          }}
          options={
            fieldWorkers?.cbbf?.filed_workerList?.length > 0
              ? fieldWorkers?.cbbf?.filed_workerList
              : [{ field_worker_name: "No data found" }]
          }
          getOptionLabel={(option) => firstLetter(option.field_worker_name)}
          // defaultValue={searchValue?.fieldworker == "" ? null : searchValue?.fieldWorkers}
          onChange={(data, value) => {
            setFieldworkerName(value?.field_worker_name);
            setFieldreset({ ...fieldreset, vhv: fieldreset.vhv + 0.1 });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              color="black"
              InputLabelProps={{
                style: {
                  fontSize: "13px", // Adjust the font size of the label here
                  paddingTop: "5px",
                },
              }}
              /* label="Select CBBF FieldWorker" */
              label="Select Volunteers FieldWorker"
            />
          )}
        />

        <TextField
          size="small"
          variant="outlined"
          style={{ width: "10%" }}
          placeholder="Camp Place"
          value={campPlace}
          onChange={(e) => setCampPlace(e.target.value)}
        />
        <TextField
          size="small"
          variant="outlined"
          style={{ width: "10%" }}
          placeholder="Camp Number"
          value={campNumber}
          onChange={(e) => setCampNumber(e.target.value)}
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, "").slice(0, 6);
          }}
        />
        <Button
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          // style={{ width: "5%", height: "5vh", marginTop: "5px" }}
          style={{ height: "50%", width: "10%" }}
          onClick={handleSubmit}
        >
          Search
        </Button>
        <Button
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          style={{ height: "50%", width: "10%" }}
          // style={{ width: "5%", height: "5vh", marginTop: "5px" }}
          onClick={handleClose}
        >
          Reset
        </Button>
        <Button
          // size="small"
          sx={{ textTransform: "capitalize" }}
          style={{ height: "50%", width: "10%" }}
          variant="contained"
          // startIcon={<FileDownloadIcon />}
          onClick={handleuploadExcel}
          disabled={rows?.length == 0}
        >
          Excel Download
        </Button>
      </Stack>
      {/* <Stack direction="row" spacing={2} style={{ width : '48%', justifyContent:'space-around' }}>
                <TextField
                    variant="outlined"
                    style={{ width: '12.5vw' }}
                    placeholder="Fieldworker Name"
                    value={fieldworkerName}
                    onChange={(e) => setFieldworkerName(e.target.value)}
                />
                <TextField
                    variant="outlined"
                    style={{ width: '12.5vw' }}
                    placeholder="Camp Place"
                    value={campPlace}
                    onChange={(e) => setCampPlace(e.target.value)}
                />
                <TextField
                    variant="outlined"
                    style={{ width: '12.5vw' }}
                    placeholder="Camp Number"
                    value={campNumber}
                    onChange={(e) => setCampNumber(e.target.value)}
                    onInput={(e) => {
                        e.target.value = e.target.value
                            .replace(/[^0-9]/g, "")
                            .slice(0, 6);
                    }}
                />
                <Button
                    sx={{ textTransform: 'capitalize' }}
                    variant="contained"
                    style={{ width: "5%", height: "5vh", marginTop: "5px" }}
                    onClick={handleSubmit}
                >
                    Search
                </Button>
                <Button
                    sx={{ textTransform: 'capitalize' }}
                    variant="contained"
                    style={{ width: "5%", height: "5vh", marginTop: "5px" }}
                    onClick={handleClose}
                >
                    Reset
                </Button>
            </Stack> */}
    </div>
  );
};
export default FieldworkerReportFilter;
