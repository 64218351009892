import * as React from "react";
import {
  Button,
  CardActions,
  CardContent,
  Typography,
  Alert,
} from "@mui/material";
import "../assets/styles/authentication.scss";
import axios from "axios";
import "../assets/styles/global.scss";
// import { Form, Formik } from "formik";
import { Form, Formik } from "formik";
import { HybridInput } from "../components/hybrid_inputs";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ResetPasswordSchema } from "../validations/userSchema";

export default function ResetPassword() {
  const { token } = useParams();
  const [resError, setResError] = React.useState(false);
  const [resErrorMsg, setResErrorMsg] = React.useState("");
  const navigate = useNavigate();
 
  // const iii = () => {

  // };
 

  const formSubmit = async (values, actions) => {
    setResError(false);

    values["token"] = token;
    

    await axios
      .post(process.env.REACT_APP_BASE_URL_LOGIN + "restPassword", values)
      .then((res) => {
  
        if (res.data.success === true) {
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
        setResError(true);
        setResErrorMsg(err.response.data.message);
      }); // actions.resetForm();
  };
  return (
    <>
      {/* <button onClick={iii}>eeeeeeeeeeeeee</button> */}
      <Formik
        initialValues={{
          password: "",
          confirm_password: "",
        }}
        validationSchema={ResetPasswordSchema}
        onSubmit={formSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <CardContent className="p-1">
              <Typography
                variant="h5"
                gutterBottom
                className="text-left colored-header"
              >
                Reset Password
              </Typography>
              {resError ? (
                <Alert
                  severity="error"
                  onClose={() => {
                    setResError(false);
                  }}
                  className="mb-1"
                >
                  {resErrorMsg}
                </Alert>
              ) : (
                ""
              )}
              <HybridInput
                label="Password"
                name="password"
                type="password"
                fullWidth
                variant="standard"
              />
              <HybridInput
                label="Confirm password"
                name="confirm_password"
                fullWidth
                type="password"
                variant="standard"
              />
            </CardContent>
            <CardActions className="justify-content-center p-1">
              <Button
                variant="contained"
                type="submit"
                fullWidth
                color="primary"
                disabled={isSubmitting}
              >
                Reset
              </Button>
            </CardActions>
          </Form>
        )}
      </Formik>
    </>
  );
}
