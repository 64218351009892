// import { TextField, Stack, Button } from "@mui/material";
import {
  TextField,
  Stack,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import React, { useState } from "react";
import { useAxios } from "../../hooks/useAxios.js";

const ageRange = [
  {
    id: 1,
    range: "10 to 20 yrs",
    value: "10-20",
  },
  {
    id: 2,
    range: "20 to 30 yrs",
    value: "20-30",
  },
  {
    id: 3,
    range: "30 to 40 yrs",
    value: "30-40",
  },
  {
    id: 4,
    range: "40 to 50 yrs",
    value: "40-50",
  },
  {
    id: 5,
    range: "50 to 60 yrs",
    value: "50-60",
  },
  {
    id: 6,
    range: "60 to 70 yrs",
    value: "60-70",
  },
  {
    id: 7,
    range: "70 to 80 yrs",
    value: "70-80",
  },
  {
    id: 8,
    range: "80 to 90 yrs",
    value: "80-90",
  },
  {
    id: 9,
    range: "90 to 100 yrs",
    value: "90-100",
  },
];

const GisSummaryFilter = ({ filterObject, resetDatesFilter }) => {
  const [filterState, setFilterState] = useState({
    age_range: "",
    gender: "",
  });
  const [reset, setReset] = useState(false);

  const { axios } = useAxios();

  const handleFilter = (e) => {
    const { name, value } = e.target;
    setFilterState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    filterObject(filterState);
    resetDatesFilter({
      eventStart: "",
      eventEnd: "",
    });
  };

  const handleClose = () => {
    let obj = {
      age_range: "",
      gender: "",
    };
    setFilterState((prevState) => ({
      ...prevState,
      age_range: "",
      gender: "",
    }));
    filterObject(obj);
    resetDatesFilter({
      eventStart: "",
      eventEnd: "",
    });
    setReset(!reset);
  };

  return (
    <div style={{ marginBottom: "0.5%" }}>
      <Stack direction="row" spacing={2}>
        <FormControl style={{ width: "15%" }} size="small">
          <InputLabel id="select-label">Select Age Range</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            variant="outlined"
            label="Select Age Range"
            value={filterState?.age_range}
            name="age_range"
            onChange={handleFilter}
          >
            {ageRange?.map(({ id, range, value }) => (
              <MenuItem key={id} value={value}>
                {range}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={{ width: "15%" }} size="small">
          <InputLabel id="select-label">Select Gender</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            variant="outlined"
            label="Select Gender"
            value={filterState?.gender}
            name="gender"
            onChange={handleFilter}
          >
            <MenuItem key="1" value="male">
              Male
            </MenuItem>
            <MenuItem key="2" value="female">
              FeMale
            </MenuItem>
            <MenuItem key="3" value="others">
              Others
            </MenuItem>
          </Select>
        </FormControl>

        <Button
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          style={{ width: "5%" }}
          onClick={handleSubmit}
        >
          Search
        </Button>
        <Button
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          style={{ width: "5%" }}
          onClick={handleClose}
        >
          Reset
        </Button>
      </Stack>
    </div>
  );
};
export default GisSummaryFilter;
