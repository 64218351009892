import React, { useEffect, useRef, useState } from "react";

// location details
import geocoding from "geocoding";

import {
  HybridDatePicker,
  HybridInput,
  HybridSelect,
  MultiSelect,
} from "../components/hybrid_inputs";
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  DialogTitle,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Grid,
  TextField,
  Autocomplete,
  Input,
  Stack,
  FormHelperText,
} from "@mui/material";
import { useAxios } from "../hooks/useAxios";
import { Form, Formik, Field } from "formik";
import { CampSchema } from "../validations/userSchema";
import LowerCase from "../pages/LowerCase";
import { addDays, previousSaturday } from "date-fns";
import { valueToNode } from "@babel/types";
import { toast, Slide } from "react-toastify";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function Forms({
  addCampToggle,
  userDetails,
  editState,
  camptype,
}) {
  const [disabledDistrict, setDisabledDistrict] = useState(true);

  const [disabledHospital, setDisabledHospital] = useState(true);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [targetDate, setTargetDate] = useState(0);
  const [induvidualScreeningTarget, setInduvidualScreeningTargets] =
    useState(0);
  const [yellowCardTarget, setYellowCardTargets] = useState(0);
  // const [value, setValue] = React.useState(null);

  const [date, setDate] = useState({
    minDate: new Date(),
    maxDate: null,
  });

  const dateFormat = "dd/MM/yyyy";
  const referenceDate = new Date(1970, 0, 1, 0, 0, 0);
  const { axios } = useAxios();
  const [hospitalIds, setHospitalIds] = useState({
    state_id: null,
    district_id: null,
  });
  let stateModel = {
    _id: "",
    state_name: "No data found",
  };
  const [stateList, setStateList] = useState([stateModel]);
  let districtModel = {
    _id: "",
    district_name: "No data found",
  };
  const [districtList, setDistrictList] = useState([]);
  let mandalModel = {
    _id: "",
    mandal_name: "No data found",
  };
  const [mandalList, setMandalList] = useState([]);
  let villageModel = {
    _id: "",
    village_name: "No data found",
  };
  let talukModel = {
    _id: "",
    taluk_name: "No data found",
  };
  const [talukList, setTalukList] = useState([]);

  const [villageList, setVillageList] = useState([villageModel]);
  let hospitalModel = {
    _id: "",
    hospital_name: "No data found",
  };
  let fieldWorkerModel = {
    _id: "",
    field_worker_name: "",
  };

  const [hospitalList, setHospitalList] = useState([hospitalModel]);
  const [fieldWorkersList, setFieldWorkerList] = useState([fieldWorkerModel]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [HospitalValue, setHospitalValue] = useState([]);
  const [HospitalDBValue, setHospitalDBValue] = useState([]);
  const [fieldWorkerValue, setfieldWorkerValue] = useState([]);
  const [medixCampNo, setCampNo] = useState([]);
  const [medixData, setMedixData] = useState();
  const [sponserData, setSponser] = useState("");
  const [campPlace, setCampPlace] = useState("");
  const [campDate, setCampDate] = useState(null);
  const [medicsCampCode, setCampMedicsCode] = useState("");

  const [medicsCampCodeText, setCampMedicsCodeText] = useState("");

  // state and district state utility
  const [stDtManage, setStDtManage] = useState({
    state_id: "",
    district_id: "",
    taluk_id: "",
  });

  // project dropdown state
  const [projDrop, setProjDrop] = useState([]);

  // mandal dropdown state
  const [mandals, setMandals] = useState([]);

  // field worker type state
  const [fieldWorkerType, setFieldWorkerType] = useState([]);

  // organizations state
  const [organizationsList, setOrganizationsList] = useState([]);
  const [organizationsValue, setOrganizationsValue] = useState([]);

  // fieldworkers state
  const [organiFieldWorkersList, setOrganiFieldWorkersList] = useState([]);
  const [organiFieldWorkersValue, setOrganiFieldWorkersValue] = useState([]);

  // village state
  const [villageV, setVillageV] = useState([]);

  // fieldWorkerValidation state
  const [fwTypeIsApplicable, setFwTypeIsApplicable] = useState();

  // fieldWorkers if (fwType == optem || campsite) state
  const [fwOptemCampSite, setFwOptemCampSite] = useState(false);

  // panchayat lat long multi (for vhv) state management
  const [panchayatDetailsMulti, setPanchayatDetailsMulti] = useState([]);

  // panchayat lat long single (for cbbf) state management
  const [panchayatDetailsSingle, setPanchayatDetailsSingle] = useState({
    mandal_lat: 0.0,
    mandal_lng: 0.0,
  });

  // check cbbf form state
  const [villMan, setVillMan] = useState(false);

  const campRef = useRef();

  // based on camptype initialize formState
  let iniFormValues;
  if (camptype === "cbbf") {
    iniFormValues = {
      camp_number: "",
      camp_place: "",
      camp_pin_code: "",
      camp_date: "",
      camp_from_date: null,
      camp_to_date: null,
      state: "",
      district: "",
      mandal: "",
      village: "",
      state_id: "",
      district_id: "",
      village_id: "",
      taluk_id: "",
      hospital_id: "",
      field_worker_id: "",
      field_worker_name: "",
      co_sponsor: "",
      household_target: 0,
      yellow_card_target: 0,
      individual_screening_target: 0,
      camp_type: "",
      project_name: "", //
      field_worker_type: [], //
      organizations: [], //
      field_workers: [], //
    };
  } else {
    iniFormValues = {
      camp_number: "",
      camp_place: "",
      camp_pin_code: "",
      camp_date: "",
      camp_from_date: null,
      camp_to_date: null,
      state: "",
      district: "",
      mandal: "",
      village: "",
      state_id: "",
      district_id: "",
      mandal_id: [], //
      village_id: "",
      taluk_id: "",
      hospital_id: "",
      field_worker_id: "",
      field_worker_name: "",
      co_sponsor: "",
      household_target: 0,
      yellow_card_target: 0,
      individual_screening_target: 0,
      camp_type: "",
      project_name: "", //
      field_worker_type: [], //
      organizations: [], //
      field_workers: [],
    };
  }

  // type fieldWorkerTypeList
  const fieldWorkerTypeList = [
    {
      _id: 3,
      name: "VHV",
    },
    {
      _id: 4,
      name: "Volunteers",
    },
    {
      _id: 5,
      name: "Optometrists",
    },
    {
      _id: 6,
      name: "Campsite Registration",
    },
  ];

  const [initialState, setInitialState] = useState(iniFormValues);

  const renderInputs = (props, placeholder, classname) => (
    <Input
      // sx={{
      //   input: {
      //     "&::placeholder": {    // <----- Add this.
      //       opacity: 1,
      //       // marginBottom: '-5px',
      //       paddingLeft: '10px',
      //       fontSize: '13px',
      //       color: '#000000'
      //     },
      //     width: '80%'
      //   },
      //   backgroundColor: '#ffffff',
      //   // borderRadius: '5px',
      //   maxHeight: '50px'
      // }}
      sx={{
        input: {
          "&::placeholder": {
            color: "#000000",
          },
        },
        width: "100%",
        height: "48px",
      }}
      type="text"
      className={classname}
      inputRef={props.inputRef}
      // inputProps={props.inputProps}
      inputProps={{
        ...props.inputProps,
        placeholder: placeholder,
      }}
      value={props.value}
      onClick={props.onClick}
      onChange={props.onChange}
      endAdornment={props.InputProps?.endAdornment}
    />
  );

  useEffect(() => {}, [campDate]);

  useEffect(() => {
    StateList();
    calcTargets();
    induvidualScreeningTargets();
    yellowCardTargets();
    HospitalList();
    getMedixCampNo();
  }, [fromDate, toDate, hospitalIds]);

  useEffect(() => {
    if (editState) {
      DistrictList(userDetails.hospital_id);
      hospitalListFetch(userDetails.state_id);
      TalukList(userDetails.district_id);
      MandalList(userDetails.taluk_id);
      // VillageList(userDetails.mandal_id);
      // getfieldWorkerList(userDetails.hospital_id);
      setFromDate(userDetails.camp_from_date);
      setToDate(userDetails.camp_to_date);
      // load hospital based projects drop
      getProjectList(userDetails.hospital_id);

      // for geocoding
      setStDtManage((prevState) => ({
        ...prevState,
        state_id: userDetails?.state_id,
        district_id: userDetails?.district_id,
        taluk_id: userDetails?.taluk_id,
      }));
    }
  }, []);

  useEffect(() => {
    if (editState) {
      setSponser(userDetails.co_sponsor);
      setCampDate(userDetails.camp_date);
      setCampPlace(userDetails?.camp_place);
      setDate({ ...date, maxDate: maxDate(userDetails?.camp_date) });
    }
  }, [editState]);

  // getOrgnisationBasedOnFieldWorkerType
  useEffect(() => {
    console.log("hit getOrgnisationBasedOnFieldWorkerType");
    getTypeOrganizations(fieldWorkerType);
  }, [fieldWorkerType]);

  // getFieldWorkersBasedOnFieldWorkersTypeAndOrganisations
  useEffect(() => {
    console.log("hit getFieldWorkersBasedOnFieldWorkersTypeAndOrganisations");
    getOrganiWiseFieldWorkers(organizationsValue);
  }, [organizationsValue]);

  // getFieldWorkers if (fieldWorkersType == optem || campsite)
  useEffect(() => {
    console.log(
      "hit getFieldWorkers if (fieldWorkersType == optem || campsite)"
    );
    getFieldWorkersOptemCampSite(fieldWorkerType);
  }, [fwOptemCampSite]);

  // edit data fetching
  useEffect(() => {
    if (editState) {
      if (camptype == "cbbf") {
        VillageList(userDetails?.mandal);

        setFieldWorkerType(userDetails?.field_worker_type);
        getTypeOrganizations(userDetails?.field_worker_type);

        getTypeId(userDetails?.field_worker_type);

        setOrganizationsValue(userDetails?.organizations);
        getOrganiWiseFieldWorkers(userDetails?.organizations);

        setOrganiFieldWorkersValue(userDetails?.field_workers);
        setVillageV(userDetails?.village);
      } else {
        console.log("hit mesiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii");

        setMandals(userDetails?.mandal_id);

        getPanchayatMultiVillage(userDetails?.mandal_id);

        setFieldWorkerType(userDetails?.field_worker_type);
        getTypeOrganizations(userDetails?.field_worker_type);

        getTypeId(userDetails?.field_worker_type);

        setOrganizationsValue(userDetails?.organizations);
        getOrganiWiseFieldWorkers(userDetails?.organizations);

        setOrganiFieldWorkersValue(userDetails?.field_workers);
        setVillageV(userDetails?.village);
      }
    }
  }, []);

  useEffect(() => {
    if (editState) {
      if (camptype == "cbbf") {
        console.log("hit getPanchayatLatLong if mandalList get updated");
        getPanchayatLatLong(userDetails?.mandal_id);
      } else {
        console.log("hit getPanchayatLatLong multi if mandalList get updated");
        getPanchayatLatLong(userDetails?.mandal_id, "multi");
      }
    }
  }, [mandalList]);

  useEffect(() => {
    if (camptype == "cbbf") {
      setVillMan(true);
    } else {
      setVillMan(false);
    }
  }, []);

  console.log("userDetails", userDetails);

  console.log("date", date);

  let data = Object.assign(initialState, userDetails);
  // const datePicker = (value) => {
  //   setCampDetails((prevState) => ({ ...prevState, camp_to_date: value }));
  // };

  const getMedixCampNo = () => {
    axios.post("MedixCamp").then((res) => {
      if (res.status === 200) {
        setCampNo(res.data.result);
        setMedixData(res.data.result);
      }
    });
  };

  useEffect(() => {}, [campDate]);

  const getSponsor = (eventData) => {
    let sponserName = medixData?.filter((data) => {
      return data.campNo === eventData;
    });

    if (sponserName.length > 0) {
      setCampMedicsCode(eventData);
      setSponser(sponserName[0]?.sponsor);
      setCampPlace(sponserName[0]?.campPlace);
      setCampDate(() => sponserName[0]?.campDate);

      setInitialState((x) => ({
        ...x,
        camp_date: sponserName[0]?.campDate,
      }));
    }
  };

  function firstLetter(s) {
    return s?.replace(/^.{1}/g, s[0].toUpperCase());
  }

  const calcTargets = () => {
    if (fromDate && toDate) {
      const date1 = new Date(fromDate);
      const date2 = new Date(toDate);
      const finalDay = addDays(date2, 1);
      const diffInMs = Math.abs(finalDay.getTime() - date1.getTime());
      const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
      setTargetDate(diffInDays * 30);
    }
  };

  const induvidualScreeningTargets = () => {
    if (fromDate && toDate) {
      const date1 = new Date(fromDate);
      const date2 = new Date(toDate);
      const finalDay = addDays(date2, 1);
      const diffInMs = Math.abs(finalDay.getTime() - date1.getTime());
      const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
      setInduvidualScreeningTargets(diffInDays * 40);
    }
  };

  const yellowCardTargets = () => {
    if (fromDate && toDate) {
      const date1 = new Date(fromDate);
      const date2 = new Date(toDate);
      const finalDay = addDays(date2, 1);
      const diffInMs = Math.abs(finalDay.getTime() - date1.getTime());
      const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
      setYellowCardTargets(diffInDays * 15);
    }
  };

  const StateList = () => {
    axios.post("statesDropDown").then((data) => {
      if (data.data.result.length > 0) {
        let result = LowerCase(data.data.result, "state_name");
        setStateList(result);
      } else {
        setStateList([stateModel]);
      }
    });
  };
  const clearFields = (setFieldValue, fields) => {
    fields.forEach((field) => {
      setFieldValue(field, "");
    });

    const createNullObject = (key) => {
      let object = {
        [key]: "No data found!",
        _id: "",
      };
    };
  };

  // const disableWeekends = (data) => {
  //   let blackoutDates = ["2022-12-22"];
  //   return blackoutDates.includes(date);
  // };

  const DistrictList = async (value) => {
    let state = {
      hospital_id: value,
    };

    await axios.post("HospitalBasedDistrictDropDown", state).then((data) => {
      if (data.data.result.length > 0) {
        let result = LowerCase(data.data.result[0].district, "district_name");

        setDistrictList(result);
      }
    });
  };

  const TalukList = async (value) => {
    let district = {
      district_id: value,
    };

    await axios.post("talukDropDown", district).then((data) => {
      if (data.data.result.length > 0) {
        let result = LowerCase(data.data.result, "taluk_name");
        setTalukList(result);
      }
    });
  };
  const MandalList = async (value) => {
    let mandal = {
      taluk_id: value,
    };
    await axios.post("mandalDropDown", mandal).then((data) => {
      if (data.data.result.length > 0) {
        let result = LowerCase(data.data.result, "mandal_name");

        setMandalList(result);
      }
    });
  };

  const VillageList = async (value) => {
    let mandal = {
      mandal_id: value,
    };
    await axios.post("villageDropDown", mandal).then((data) => {
      if (data.data.result.length > 0) {
        let result = LowerCase(data.data.result, "village_name");
        setVillageList(result);
      } else {
        setVillageList([villageModel]);
      }
    });
  };

  const HospitalList = () => {
    setHospitalList([hospitalModel]);
    if (hospitalIds.state_id && hospitalIds.district_id) {
      let hospitals = {
        state_id: hospitalIds.state_id,
        district_id: hospitalIds.district_id,
      };
      axios.post("hospitalDropDown", hospitals).then((data) => {
        if (data.data.result.length > 0) {
          let result = LowerCase(data.data.result, "hospital_name");
          setHospitalList(result);
        }
        setDisabledHospital(false);
      });
    }
  };
  const getfieldWorkerList = async (value) => {
    let hospital = {
      hospital_id: value,
    };
    let axios_point;
    if (camptype == "cbbf") {
      axios_point = axios.post("CbbfFieldWorkers ", hospital);
    } else {
      axios_point = axios.post("HospitalBasedWorkerDropDown ", hospital);
    }
    await axios_point.then((data) => {
      if (data.data.result.length > 0) {
        let newArrayOfObj = data.data.result.map(
          ({ user_name: field_worker_name, ...rest }) => ({
            field_worker_name,
            ...rest,
          })
        );
        let result = LowerCase(newArrayOfObj, "field_worker_name");
        setFieldWorkerList(result);
      } else {
        setFieldWorkerList([fieldWorkerModel]);
      }
    });
  };

  // get project list dependency on hospital
  const getProjectList = async (hospital_id) => {
    try {
      const response = await axios.get(`projectDropdown`, {
        params: { hospital_id },
      });
      console.log("projectDropdown response", response);
      const { success, result } = response?.data;
      if (success) {
        setProjDrop(result);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // getPanchayatMultiVillage
  const getPanchayatMultiVillage = async (mandals) => {
    try {
      if (mandals.length > 0) {
        const mandal_id = mandals?.map(({ _id }) => {
          return _id;
        });
        const response = await axios.get(`getPanchayatMulti`, {
          params: {
            mandal_id,
          },
        });
        console.log("getPanchayatMulti response", response);
        const { success, result } = response?.data;
        if (success) {
          setVillageList(result);
        }
      }
    } catch (error) {
      console.log("errors", error);
    }
  };

  // getTypeWiseOrganizations
  const getTypeOrganizations = async (fieldWorkerType) => {
    try {
      if (fieldWorkerType.length > 0) {
        const designation = fieldWorkerType?.map(({ _id }) => {
          return _id;
        });
        const response = await axios.get(`getTypeWiseOrganization`, {
          params: {
            designation,
          },
        });
        console.log("getTypeOrganizations response", response);
        const { success, result } = response?.data;
        if (success) {
          const orG = result?.map(
            ({ organization_collection_id: _id, ...rest }) => ({
              _id,
              ...rest,
            })
          );
          setOrganizationsList(orG);
        }
      }
    } catch (error) {
      console.log("errors", error);
    }
  };

  // getOrganiWiseFieldWorkers
  const getOrganiWiseFieldWorkers = async (organizationsValue) => {
    const organization_id = organizationsValue?.map(({ organization_id }) => {
      return organization_id;
    });
    const role_id = fieldWorkerType?.map(({ _id }) => {
      return _id;
    });
    try {
      if (organizationsValue.length > 0) {
        const response = await axios.get(`getUserWiseOrganization`, {
          params: {
            organization_id,
            role_id,
          },
        });
        console.log("getUserWiseOrganization response", response);
        const { success, result } = response?.data;
        if (success) {
          setOrganiFieldWorkersList(result);
        }
      }
    } catch (error) {
      console.log("erorrs", error);
    }
  };

  // getFieldWorkers if (fieldWorkerType == optem || campsite)
  const getFieldWorkersOptemCampSite = async (fieldWorkerType) => {
    try {
      if (fieldWorkerType.length > 0) {
        const designation = fieldWorkerType?.map(({ _id }) => {
          return _id;
        });
        const response = await axios.get(`getRoleWiseFieldworkers`, {
          params: {
            designation,
          },
        });
        console.log("getFieldWorkersOptemCampSite response", response);
        const { success, result } = response?.data;
        if (success) {
          setOrganiFieldWorkersList(result);
          setFwOptemCampSite(false);
        }
      }
      setFwOptemCampSite(false);
    } catch (error) {
      console.log("erorrs", error);
    }
  };

  const handleSelectChange = (data, value) => {
    let newArrayOfObj = value.map(({ _id: village_id, ...rest }) => ({
      village_id,
      ...rest,
    }));
    setSelectedValue(newArrayOfObj);
  };

  const handleFieldWorkerChange = (data, value) => {
    let newArrayOfObj = value.map(
      ({ _id: field_worker_id, user_name: field_worker_name, ...rest }) => ({
        field_worker_id,
        field_worker_name,
        ...rest,
      })
    );
    setfieldWorkerValue(newArrayOfObj);
  };

  const handleHospitalChange = (data, value) => {
    DistrictList(value);
    // getfieldWorkerList(value);
    getProjectList(value);
  };

  const hospitalListFetch = (value) => {
    let obj = {
      state_id: value,
    };
    axios.post(`StateBasedHospitalDropDown`, obj).then((res) => {
      setHospitalValue(res.data.result);
      setProjDrop([]);
    });
  };

  const formSubmit = (values) => {
    var state = stateList.find((i) => {
      if (i._id == values.state_id) {
        return i;
      }
    });
    var district = districtList.find((i) => {
      if (i.district_id == values.district_id) {
        return i;
      }
    });
    var hospital = HospitalValue.find((i) => {
      if (i._id == values.hospital_id) {
        return i;
      }
    });

    // project dependancy on hospital
    var projectDetails = projDrop.find(({ _id, project_name, ...rest }) => {
      if (project_name == values.project_name) {
        return { _id, project_name, ...rest };
      }
    });

    var taluk = talukList.find((i) => {
      if (i._id == values.taluk_id) {
        return i;
      }
    });

    let mandal;
    if (camptype !== "cbbf") {
      // mandals updated multiselect payload
      mandal = values?.mandal_id.map(({ _id: mandal_id, ...rest }) => ({
        mandal_id,
        mandal_lat: 0.0,
        mandal_lng: 0.0,
        ...rest,
      }));
    } else {
      mandal = mandalList.find((i) => {
        if (i._id == values?.mandal) {
          return i;
        }
      });
    }

    var field_worker = fieldWorkersList.find((i) => {
      if (i._id == values.field_worker_id) {
        return i;
      }
    });
    let field_worker_name = field_worker ? field_worker.field_worker_name : "";
    let field_worker_id = values.field_worker_id;

    let villageListData, fieldworkerDatas;

    if (userDetails.village?.length > 0) {
      villageListData = userDetails.village.map((obj) => {
        const newObj = { ...obj };
        delete newObj["_id"];
        return newObj;
      });
    }

    if (userDetails.fieldworkerData?.length > 0) {
      fieldworkerDatas = userDetails.fieldworkerData.map((obj) => {
        const newObj = { ...obj };
        delete newObj["_id"];
        return newObj;
      });
    }

    let campType = camptype === "cbbf" ? 2 : 1;
    let fieldWorker;

    if (camptype === "cbbf") {
      fieldWorker =
        fieldWorkerValue.length > 0 ? fieldWorkerValue : fieldworkerDatas;
      delete values.field_worker_id;
      delete values.field_worker_name;
    } else {
      values.field_worker_name = field_worker.user_name;
      values.field_worker_id = values.field_worker_id;
    }

    let campNumber =
      medicsCampCodeText === 0 || medicsCampCodeText === undefined
        ? medicsCampCode === undefined || medicsCampCode === ""
          ? values.camp_number
          : medicsCampCode
        : medicsCampCodeText;
    let cbbfcampNumber = values.camp_number;

    // values.camp_place =
    //   campPlace === undefined || campPlace === ""
    //     ? values.camp_place
    //     : campPlace;

    values.camp_place = campPlace;

    // values.co_sponsor =
    // sponserData === "" || sponserData === undefined
    //   ? values.co_sponsor
    //   : sponserData;

    values.co_sponsor = sponserData;

    values.camp_number = camptype === "cbbf" ? cbbfcampNumber : campNumber;
    values.state_name = state.state_name;
    values.district_name = district?.district_name;
    values.taluk_name = taluk.taluk_name;
    values.state_id = values.state_id;
    values.district_id = district?.district_id;
    values.taluk_id = values.taluk_id;

    if (camptype === "cbbf") {
      // mandals old payload
      values.mandal_id = mandal?._id;
      values.mandal_name = mandal?.mandal_name;
      if (
        panchayatDetailsSingle?.mandal_lat == 0 ||
        panchayatDetailsSingle?.mandal_lng == 0
      ) {
        toast.error("Invalid Panchayat, Please check and try again", {
          autoClose: 1500,
          transition: Slide,
        });
        return;
      } else {
        values.mandal_lat = panchayatDetailsSingle?.mandal_lat;
        values.mandal_lng = panchayatDetailsSingle?.mandal_lng;
      }
    } else {
      // mandals updated payload
      // delete values.mandal_id;
      if (panchayatDetailsMulti.length > 0) {
        values.mandal = panchayatDetailsMulti;
      } else {
        toast.error("Invalid Panchayat, Please check and try again", {
          autoClose: 1500,
          transition: Slide,
        });
        return;
        // values.mandal = mandal;
      }
    }

    values.hospital_name = hospital?.hospital_name;
    values.hospital_id = hospital?._id;

    // project details
    values.project_id = projectDetails?.project_id;
    values.project_namee = projectDetails?.project_name;
    values.project_collecton_id = projectDetails?._id;

    // old one
    // values.village = selectedValue.length > 0 ? selectedValue : villageListData;

    values.field_worker =
      fieldWorkerValue.length > 0 ? fieldWorkerValue : fieldworkerDatas;

    // values.field_worker_name = field_worker.field_worker_name
    // values.field_worker_id = values.field_worker_id
    delete values.state;
    delete values.district;

    // old one commented
    // delete values.mandal;

    delete values.hospital;

    delete values.village_id;
    values.camp_type = campType;
    let household_target =
      targetDate !== 0 ? targetDate : values.household_target;
    let yellow_card_target =
      yellowCardTarget !== 0 ? yellowCardTarget : values.yellow_card_target;
    let individual_screening_target =
      induvidualScreeningTarget !== 0
        ? induvidualScreeningTarget
        : values.individual_screening_target;
    values["camp_from_date"] = new Date(
      new Date(values.camp_from_date).setHours(6, 0, 0)
    );
    values["camp_to_date"] = new Date(
      new Date(values.camp_to_date).setHours(12, 59, 59)
    );

    if (camptype !== "cbbf") {
      values["camp_date"] = new Date(new Date(campDate).setHours(23, 59, 59));
    } else {
      new Date(values.camp_date).setHours(12, 59, 59);
    }

    // payload updation
    // field worker type payload
    values.worker_type = values?.field_worker_type?.map(
      ({ _id: role_id, name: role_name }) => ({
        role_id,
        role_name,
      })
    );
    // delete values?.field_worker_type;

    // organisation payload
    values.organizations = values?.organizations?.map(
      ({ _id: organization_collection_id, ...rest }) => ({
        organization_collection_id,
        ...rest,
      })
    );

    // fieldworkers payload
    values.field_worker = values?.field_workers?.map(
      ({ _id: field_worker_id, user_name: field_worker_name }) => ({
        field_worker_id,
        field_worker_name,
      })
    );
    // delete values?.field_workers;

    if (Array.isArray(values?.village)) {
      values.village = values?.village?.map(({ _id: village_id, ...rest }) => ({
        village_id,
        ...rest,
      }));
    } else {
      values.village = [];
    }

    let finalDate = {
      ...values,
      field_worker_name,
      household_target,
      field_worker_id,
      yellow_card_target,
      individual_screening_target,
    };
    let data = {
      ...values,

      household_target,
      yellow_card_target,
      individual_screening_target,
    };

    if (editState === false) {
      if (camptype === "cbbf") {
        axios
          .post("cbbfCampCreate ", data)
          .then((res) => {
            if (res.data.success === true) {
              addCampToggle();
            } else {
              if (res.status.code == 501) {
                toast.error(res.data.message, {
                  autoClose: 1500,
                  transition: Slide,
                });
              }
            }
          })
          .catch((err) => {
            console.log(err, "err");
          });
      } else {
        axios
          .post("campCreate", finalDate)
          .then((res) => {
            if (res.data.success === true) {
              addCampToggle();
            } else {
              if (res.status.code == 501) {
                toast.error(res.data.message, {
                  autoClose: 1500,
                  transition: Slide,
                });
              }
            }
          })
          .catch((err) => {
            console.log(err, "err");
          });
      }
    } else if (editState === true) {
      delete data.created_on;
      delete data.modify_on;
      delete data.__v;
      if (camptype === "cbbf") {
        axios
          .post("cbbfCampUpdate", data)
          .then((res) => {
            addCampToggle();
          })
          .catch((err) => {
            console.log(err, "err");
          });
      } else {
        delete finalDate.created_on;
        delete finalDate.modify_on;
        delete finalDate.__v;
        axios
          .post("campEdit", finalDate)
          .then((res) => {
            addCampToggle();
          })
          .catch((err) => {
            console.log(err, "err");
          });
      }
    }
  };
  const handleInputChange = (event) => {
    setCampMedicsCodeText(event);
    console.log("ev", event);
  };

  function convertTime(time, val) {
    if (time !== null) {
      const [year, month, day] = time.substring(0, 10).split("-");
      var newday = day;
      if (val) {
        newday = day - 1;
      }
      const formattedDate = `${year}/${month}/${newday}`;
      return formattedDate;
    } else {
      return null;
    }
  }

  function maxDate(val) {
    const newDate = new Date(val);
    newDate.setDate(newDate.getDate() - 1);
    return newDate;
  }

  // get getTypeId to check organisations validations
  const getTypeId = (value) => {
    const selectedTypesIds = value?.map(({ _id }) => {
      return _id;
    });
    if (selectedTypesIds.includes(3) || selectedTypesIds.includes(4)) {
      setFwTypeIsApplicable(true);
    } else {
      setFwTypeIsApplicable(false);
      setFwOptemCampSite(true);
    }
  };

  // get panchayay latlong
  const getPanchayatLatLong = async (val, type = null) => {
    try {
      let response;

      let stateData = stateList.find((i) => {
        if (i._id == stDtManage.state_id) {
          return i;
        }
      });

      let districtData = districtList.find((i) => {
        if (i.district_id == stDtManage.district_id) {
          return i;
        }
      });

      let talukData = talukList.find((i) => {
        if (i._id == stDtManage.taluk_id) {
          return i;
        }
      });

      let panchayatData = [];
      if (type == "multi") {
        if (mandalList.length > 0) {
          if (val.length > 0) {
            for (const panchayat of val) {
              let address = `${panchayat?.mandal_name || ""}, ${
                districtData?.district_name || ""
              }, ${stateData?.state_name || ""}`;

              response = await geocoding({
                address: address,
                key: process.env.REACT_APP_GOOGLE_API_KEY,
                components: {
                  administrative_area_level_1: stateData?.state_name,
                  administrative_area_level_2: districtData?.district_name,
                  locality: talukData?.taluk_name,
                  sublocality: panchayat?.mandal_name,
                },
              });
              console.log("response", response);
              const [
                {
                  geometry: { location: { lat, lng } = {} } = {},
                  partial_match: isPartial = false,
                },
              ] = response;

              // invalid panchayat check
              if (response.length === 0) {
                throw new Error(
                  `Not able to fetch Latitude and Longitude for this ${panchayat?.mandal_name} panchayat, please check panchayat and try again`
                );
              }

              // when partial match found
              if (isPartial) {
                throw new Error(
                  `Exact Latitude and Longitude for this ${panchayat?.mandal_name} panchayat not found`
                );
              }

              panchayatData.push({
                mandal_id: panchayat?._id,
                mandal_name: panchayat?.mandal_name,
                mandal_lat: lat,
                mandal_lng: lng,
              });
            }
          }
        }
        setPanchayatDetailsMulti(panchayatData);
      } else {
        if (mandalList.length > 0) {
          const panchayat = mandalList?.find(({ _id }) => _id == val);

          let address = `${panchayat?.mandal_name || ""}, ${
            districtData?.district_name || ""
          }, ${stateData?.state_name || ""}`;

          response = await geocoding({
            address: address,
            key: process.env.REACT_APP_GOOGLE_API_KEY,
            components: {
              administrative_area_level_1: stateData?.state_name,
              administrative_area_level_2: districtData?.district_name,
              locality: talukData?.taluk_name,
              sublocality: panchayat?.mandal_name,
            },
          });
          console.log("response", response);
          const [
            {
              geometry: { location: { lat, lng } = {} } = {},
              partial_match: isPartial = false,
            },
          ] = response;

          // invalid panchayat check
          if (response.length === 0) {
            throw new Error(
              `Not able to fetch Latitude and Longitude for this ${panchayat?.mandal_name} panchayat, please check panchayat and try again`
            );
          }

          // when partial match found
          if (isPartial) {
            throw new Error(
              `Exact Latitude and Longitude for this ${panchayat?.mandal_name} panchayat not found`
            );
          }

          setPanchayatDetailsSingle((prev) => ({
            ...prev,
            mandal_lat: lat,
            mandal_lng: lng,
          }));
        }
      }
    } catch (error) {
      console.log("catch geoLocation finder", error);
      toast.error(error?.message, {
        autoClose: 1500,
        transition: Slide,
      });
      setPanchayatDetailsMulti([]);
      setPanchayatDetailsSingle((prev) => ({
        ...prev,
        mandal_lat: 0.0,
        mandal_lng: 0.0,
      }));
    }
  };

  console.log("setPanchayatDetailsSingle", panchayatDetailsSingle);
  console.log("setPanchayatDetailsMulti", panchayatDetailsMulti);

  return (
    <>
      <Typography variant="h5" align="left" color="secondary">
        {/* {camptype === "cbbf" ? "CBBF Camp Form " : "Camp Form"} */}
        {camptype === "cbbf" ? "Volunteers Screening Camp Form " : "Camp Form"}
      </Typography>
      <Formik
        validationSchema={CampSchema(fwTypeIsApplicable, villMan)}
        initialValues={editState === true ? userDetails : initialState}
        onSubmit={formSubmit}
      >
        {({
          isSubmitting,
          setFieldValue,
          values,
          handleChange,
          errors,
          touched,
        }) => (
          <Form className="mt-2">
            {/* <div className="d-flex"> */}
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                {camptype !== "cbbf" ? (
                  <FormControl fullWidth>
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      value={
                        medicsCampCode
                          ? medicsCampCode
                          : userDetails.camp_number
                          ? userDetails.camp_number
                          : values.camp_number
                      }
                      options={medixCampNo.map((option) => option.campNo)}
                      onChange={(data, event) => {
                        getSponsor(event);
                      }}
                      onInputChange={(event) => {
                        handleInputChange(event?.target?.value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          type="number"
                          {...params}
                          label="Camp No."
                          value={
                            medicsCampCode
                              ? medicsCampCode
                              : userDetails.camp_number
                              ? userDetails.camp_number
                              : values.camp_number
                          }
                          variant="standard"
                          onChange={(data, event) => {
                            getSponsor(event);
                          }}
                          onInput={(event) => {
                            event.target.value = event.target.value
                              .replace(/[^0-9]/g, "")
                              ?.slice(0, 10);
                          }}
                          onInputChange={(event) => {
                            handleInputChange(event?.target?.value);
                          }}
                          name="camp_number"
                        />
                      )}
                    />
                  </FormControl>
                ) : (
                  <HybridInput
                    label="Camp Number"
                    name="camp_number"
                    type="text"
                    style={{ width: "100%" }}
                    setVal={setFieldValue}
                    variant="standard"
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/[^0-9]/g, "")
                        ?.slice(0, 10);
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {camptype === "cbbf" ? (
                  <HybridInput
                    label="Camp Place"
                    name="camp_place"
                    type="text"
                    fullWidth
                    setVal={setFieldValue}
                    variant="standard"
                    onChange={(val) => {
                      setCampPlace(val);
                    }} //setCampPlace(e.target.value)
                  />
                ) : (
                  <TextField
                    id="standard-basic"
                    label="Camp Place"
                    variant="standard"
                    name="camp_place"
                    fullWidth
                    defaultValue={
                      // userDetails.camp_place
                      //   ? "userDetails.camp_place"
                      //   : campPlace
                      //   ? "campPlace 2"
                      //   : ""
                      userDetails.camp_place
                        ? userDetails.camp_place
                        : campPlace
                        ? campPlace
                        : ""
                    }
                    // value={
                    //   userDetails.camp_place
                    //     ? userDetails.camp_place
                    //     : campPlace
                    //       ? campPlace
                    //       : ""
                    // }
                    value={campPlace}
                    onChange={(e) => setCampPlace(e.target.value)}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <HybridInput
                  label="Camp Place Pin Code"
                  name="camp_pin_code"
                  type="tel"
                  fullWidth
                  setVal={setFieldValue}
                  maxlength="6"
                  InputProps={{ inputProps: { minlength: 0, maxlength: 6 } }}
                  variant="standard"
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9]/g, "")
                      ?.slice(0, 10);
                  }}
                />
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                {camptype !== "cbbf" ? (
                  <HybridDatePicker
                    label="Camp Date"
                    name="camp_date"
                    fullWidth
                    disablePast
                    variant="standard"
                    value={campDate}
                    onChange={(newValue) => {
                      setCampDate(newValue);
                      setDate({ ...date, maxDate: maxDate(newValue) });
                    }}
                    setVal={setFieldValue}
                    initialDate={campDate}
                  />
                ) : (
                  // <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}  >
                  //   <DatePicker
                  //     fullWidth
                  //     disablePast
                  //     variant="standard"
                  //     value={
                  //       userDetails.camp_date
                  //         ? userDetails.camp_date
                  //         : campDate
                  //           ? campDate
                  //           : ""
                  //     }
                  //     inputFormat="DD/MM/YYYY"
                  //     onChange={(e) => {
                  //       setCampDate(e)
                  //     }}
                  //     defaultValue={null}
                  //     label="Camp Date"
                  //     name="camp_date"
                  //     renderInput={(props) => renderInputs(props, 'Camp Date', 'fieldDate')} // props , lablename , classname
                  //     initialDate={
                  //       userDetails.camp_date
                  //         ? userDetails.camp_date
                  //         : campDate
                  //           ? campDate
                  //           : null
                  //     }
                  //   />
                  // </LocalizationProvider>

                  <HybridDatePicker
                    label="Camp Date"
                    name="camp_date"
                    fullWidth
                    disablePast
                    variant="standard"
                    setVal={setFieldValue}
                    onChange={(newValue) => {
                      setDate({ ...date, maxDate: maxDate(newValue) });
                      console.log("newValue", newValue);
                    }}
                    initialDate={initialState.camp_date}
                  />
                )}
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                {camptype === "cbbf" ? (
                  <HybridDatePicker
                    label="Survey From Date"
                    name="camp_from_date"
                    disabled={!values.camp_date}
                    maxDate={date.maxDate}
                    minDate={date.minDate}
                    fullWidth
                    disablePast
                    variant="standard"
                    setVal={setFieldValue}
                    onChange={(e) => setFromDate(e)}
                    initialDate={initialState.camp_from_date}
                  />
                ) : (
                  <HybridDatePicker
                    label="Survey From Date"
                    name="camp_from_date"
                    disabled={values.camp_date ? !values.camp_date : !campDate}
                    maxDate={date.maxDate}
                    minDate={date.minDate}
                    fullWidth
                    disablePast
                    variant="standard"
                    setVal={setFieldValue}
                    onChange={(e) => setFromDate(e)}
                    initialDate={initialState.camp_from_date}
                  />
                )}
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                {camptype === "cbbf" ? (
                  <HybridDatePicker
                    label="Survey To Date"
                    name="camp_to_date"
                    variant="standard"
                    fullWidth
                    // maxDate={
                    //   values.camp_date ? values.camp_date - 1 : undefined
                    // }
                    // minDate={new Date(values.camp_from_date)}
                    maxDate={date.maxDate}
                    minDate={new Date(values.camp_from_date)}
                    disabled={values.camp_date ? !values.camp_date : !campDate}
                    disablePast
                    setVal={setFieldValue}
                    onChange={(e) => setToDate(e)}
                    initialDate={convertTime(initialState.camp_to_date)}
                  />
                ) : (
                  <HybridDatePicker
                    label="Survey To Date"
                    name="camp_to_date"
                    variant="standard"
                    fullWidth
                    // maxDate={
                    //   campDate
                    //     ? campDate - 1
                    //     : values.camp_date
                    //       ? !values.camp_date - 1
                    //       : ""
                    // }
                    // maxDate={date.maxDate}
                    // minDate={new Date(values.camp_from_date)}
                    disabled={!values.camp_from_date}
                    maxDate={date.maxDate}
                    minDate={new Date(values.camp_from_date)}
                    // disabled={!values.camp_to_date}
                    disablePast
                    setVal={setFieldValue}
                    onChange={(e) => setToDate(e)}
                    initialDate={convertTime(initialState.camp_to_date)}
                  />
                )}
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <Field name="state_id">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel
                        id="select-label"
                        style={{ marginLeft: "-2%" }}
                      >
                        Select State
                      </InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        {...field}
                        value={field.value}
                        defaultValue={
                          userDetails.state_name !== ""
                            ? userDetails.state_name
                            : ""
                        }
                        onChange={(event) => {
                          setFieldValue(field.name, event.target.value);
                          hospitalListFetch(event.target.value);
                          setStDtManage((prevState) => ({
                            ...prevState,
                            state_id: event.target.value,
                          }));
                        }}
                      >
                        {stateList.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {firstLetter(option.state_name)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <Field name="hospital_id">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel
                        id="select-label"
                        style={{ marginLeft: "-2%" }}
                      >
                        Select Hospital
                      </InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        {...field}
                        value={field.value}
                        defaultValue={
                          userDetails.hospital_name !== ""
                            ? userDetails.hospital_name
                            : ""
                        }
                        onChange={(event) => {
                          setFieldValue(field.name, event.target.value);
                          // getfieldWorkerList(event.target.value);
                          handleHospitalChange(data, event.target.value);
                        }}
                      >
                        {HospitalValue.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {/* {option.hospital_name} */}
                            {firstLetter(option.hospital_name)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
              </Grid>
              {/* project dropdown*/}
              <Grid item xs={12} md={6}>
                <Field name="project_name">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel
                        id="select-label"
                        style={{ marginLeft: "-2%" }}
                      >
                        Select Project
                      </InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        {...field}
                        value={field.value}
                        defaultValue={
                          userDetails.project_collecton_id !== ""
                            ? userDetails.project_collecton_id
                            : ""
                        }
                        onChange={(event) => {
                          setFieldValue(field.name, event.target.value);
                        }}
                      >
                        {projDrop.map(({ _id, project_name }) => (
                          <MenuItem key={_id} value={project_name}>
                            {firstLetter(project_name)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
                {errors?.project_name && touched?.project_name ? (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.project_name}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Grid>
              {/* project dropdown*/}
              <Grid item xs={12} md={6}>
                <Field name="district_id">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel
                        id="select-label"
                        style={{ marginLeft: "-2%" }}
                      >
                        Select District
                      </InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        {...field}
                        onChange={(event) => {
                          setFieldValue(field.name, event.target.value);
                          TalukList(event.target.value);
                          setStDtManage((prevState) => ({
                            ...prevState,
                            district_id: event.target.value,
                          }));
                        }}
                      >
                        {districtList.map((option) => (
                          <MenuItem
                            key={option.district_id}
                            value={option.district_id}
                          >
                            {option.district_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <Field name="taluk_id">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel
                        id="select-label"
                        style={{ marginLeft: "-2%" }}
                      >
                        Select Subdivison/Taluk/Mandal{" "}
                      </InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        {...field}
                        value={field.value}
                        onChange={(event) => {
                          setFieldValue(field.name, event.target.value);
                          MandalList(event.target.value);
                          setStDtManage((prevState) => ({
                            ...prevState,
                            taluk_id: event.target.value,
                          }));

                          if (camptype === "cbbf") {
                            setFieldValue("mandal", "");
                            setMandalList([]);
                          } else {
                            setFieldValue("mandal_id", []);
                            setMandalList([]);
                            setMandals([]);
                          }
                        }}
                      >
                        {talukList.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.taluk_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                {camptype === "cbbf" ? (
                  <Field name="mandal">
                    {({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel
                          id="select-label"
                          style={{ marginLeft: "-2%" }}
                        >
                          Select Municipal Corporation/Gram Panchayat{" "}
                        </InputLabel>
                        <Select
                          name="mandal"
                          labelId="select-label"
                          id="select"
                          variant="standard"
                          {...field}
                          value={field.value}
                          onChange={(event) => {
                            setFieldValue(field.name, event.target.value);
                            VillageList(event.target.value);

                            setFieldValue("village", []);
                            setVillageList([]);
                            setVillageV([]);

                            getPanchayatLatLong(event.target.value);
                          }}
                        >
                          {mandalList.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.mandal_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                ) : (
                  <Field name="mandal_id">
                    {({ field }) => (
                      <Autocomplete
                        multiple
                        style={{ marginTop: "0%" }}
                        id="tags-standard"
                        options={mandalList}
                        getOptionLabel={(option) => option.mandal_name}
                        value={mandals}
                        onChange={(data, value) => {
                          setFieldValue(field.name, value);
                          setMandals(value);

                          setFieldValue("village", []);
                          setVillageList([]);
                          setVillageV([]);

                          getPanchayatLatLong(value, "multi");
                        }}
                        onBlur={() => getPanchayatMultiVillage(mandals)}
                        isOptionEqualToValue={(option, value) =>
                          option._id === value._id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Select Municipal Corporation/Gram Panchayat"
                            placeholder=""
                          />
                        )}
                      />
                    )}
                  </Field>
                )}
                {errors?.mandal_id && touched?.mandal_id ? (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.mandal_id}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <Autocomplete
                  name="village"
                  multiple
                  style={{ marginTop: "0%" }}
                  id="tags-standard"
                  options={villageList}
                  value={villageV}
                  getOptionLabel={(option) => option.village_name}
                  onChange={(data, value) => {
                    setFieldValue("village", value);
                    handleSelectChange(data, value);
                    setVillageV(value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Village Name/Ward Name"
                      placeholder=""
                    />
                  )}
                />
                {errors?.village && touched?.village ? (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.village}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <HybridInput
                  label="Camp Latitude"
                  name="current_latitude"
                  type="text"
                  fullWidth
                  setVal={setFieldValue}
                  variant="standard"
                />
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <HybridInput
                  label="Camp Longitude"
                  name="current_longitude"
                  type="text"
                  fullWidth
                  setVal={setFieldValue}
                  variant="standard"
                />
              </Grid>{" "}
              {/* fieldworker type */}
              <Grid item xs={12} md={6}>
                <Field name="field_worker_type">
                  {({ field }) => (
                    <Autocomplete
                      multiple
                      style={{ marginTop: "0%" }}
                      id="tags-standard"
                      options={fieldWorkerTypeList}
                      getOptionLabel={(option) => option.name}
                      value={fieldWorkerType}
                      onChange={(data, value) => {
                        setFieldValue(field.name, value);

                        setFieldValue("organizations", []);
                        setOrganizationsList([]);
                        setOrganizationsValue([]);

                        setFieldValue("field_workers", []);
                        setOrganiFieldWorkersList([]);
                        setOrganiFieldWorkersValue([]);

                        setFieldWorkerType(value);
                        getTypeId(value);
                      }}
                      // onBlur={() => getTypeOrganizations(fieldWorkerType)}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Select FieldWorker Type"
                          placeholder=""
                        />
                      )}
                    />
                  )}
                </Field>
                {errors?.field_worker_type && touched?.field_worker_type ? (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.field_worker_type}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Grid>
              {/* fieldworker type */}
              {/* organizations */}
              <Grid item xs={12} md={6}>
                <Field name="organizations">
                  {({ field }) => (
                    <Autocomplete
                      multiple
                      style={{ marginTop: "0%" }}
                      id="tags-standard"
                      options={organizationsList}
                      getOptionLabel={(option) => option.organization_name}
                      value={organizationsValue}
                      onChange={(data, value) => {
                        setFieldValue(field.name, value);
                        setOrganizationsValue(value);

                        setFieldValue("field_workers", []);
                        setOrganiFieldWorkersList([]);
                        setOrganiFieldWorkersValue([]);
                      }}
                      // onBlur={() => getOrganiWiseFieldWorkers(organizationsValue)}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Select Organisation"
                          placeholder=""
                        />
                      )}
                    />
                  )}
                </Field>
                {errors?.organizations && touched?.organizations ? (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.organizations}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Grid>
              {/* organizations */}
              {/* fieldWorkers */}
              <Grid item xs={12} md={6}>
                <Field name="field_workers">
                  {({ field }) => (
                    <Autocomplete
                      multiple
                      style={{ marginTop: "0%" }}
                      id="tags-standard"
                      options={organiFieldWorkersList}
                      getOptionLabel={(option) => option.user_name}
                      value={organiFieldWorkersValue}
                      onChange={(data, value) => {
                        setFieldValue(field.name, value);
                        setOrganiFieldWorkersValue(value);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Select FieldWorkers"
                          placeholder=""
                        />
                      )}
                    />
                  )}
                </Field>
                {errors?.field_workers && touched?.field_workers ? (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.field_workers}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Grid>{" "}
              {/* fieldWorkers */}
              <Grid item xs={12} md={6}>
                {camptype === "cbbf" ? (
                  <HybridInput
                    label="Co Sponsor"
                    name="co_sponsor"
                    type="text"
                    fullWidth
                    setVal={setFieldValue}
                    variant="standard"
                    onChange={(val) => setSponser(val)}
                  />
                ) : (
                  <TextField
                    label="Co Sponsors"
                    name="co_sponsor"
                    type="text"
                    // defaultValue={
                    //   userDetails.co_sponsor
                    //     ? userDetails.co_sponsor
                    //     : sponserData
                    //       ? sponserData
                    //       : ""
                    // }
                    // value={
                    //   userDetails.co_sponsor
                    //     ? userDetails.co_sponsor
                    //     : sponserData
                    //       ? sponserData
                    //       : ""
                    // }
                    value={sponserData}
                    fullWidth
                    // onInputChange={(e) => { setFieldValue("co_sponsor", e.target.value); }}
                    setVal={setFieldValue}
                    onChange={(e) => setSponser(e.target.value)}
                    variant="standard"
                  />
                )}
              </Grid>{" "}
              {camptype == "cbbf" ? (
                ""
              ) : (
                <>
                  <Grid item xs={12} md={6}>
                    <HybridInput
                      label="Household Target"
                      name="household_target"
                      type="text"
                      fullWidth
                      // value={targetDate !== 0 ? userDetails.household_target : targetDate}
                      value={
                        userDetails.household_target !== 0 && targetDate === 0
                          ? userDetails.yellow_card_target
                          : targetDate
                      }
                      variant="standard"
                      disabled={true}
                      dropVal=""
                    />
                  </Grid>{" "}
                  <Grid item xs={12} md={6}>
                    <HybridInput
                      label="Yellow Card Target"
                      name="yellow_card_target"
                      type="text"
                      fullWidth
                      value={
                        userDetails.yellow_card_target !== 0 &&
                        yellowCardTarget === 0
                          ? userDetails.yellow_card_target
                          : yellowCardTarget
                      }
                      variant="standard"
                      disabled={true}
                      dropVal=""
                    />
                  </Grid>{" "}
                  <Grid item xs={12} md={6}>
                    <HybridInput
                      label="Individual Screening Target"
                      name="individual_screening_target"
                      type="text"
                      fullWidth
                      value={
                        userDetails.individual_screening_target !== 0 &&
                        induvidualScreeningTarget === 0
                          ? userDetails.individual_screening_target
                          : induvidualScreeningTarget
                      }
                      // value={userDetails.individual_screening_target !== "" ? userDetails.individual_screening_target : induvidualScreeningTarget}
                      variant="standard"
                      disabled={true}
                      dropVal=""
                    />
                  </Grid>
                </>
              )}
              {/* </div> */}
            </Grid>
            <Grid container justifyContent="flex-end" spacing={2} p={2}>
              <Grid item>
                <Button onClick={addCampToggle}>Back</Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  type="submit"
                  //   disabled={isSubmitting}
                  variant="contained"
                  onSubmit={formSubmit}
                >
                  {editState ? "Update" : "Add"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
