// import { TextField, Stack, Button } from "@mui/material";
import {
  TextField,
  Stack,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import React, { useState, useEffect } from "react";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import { useAxios } from "../../hooks/useAxios.js";

const UnitWisePerformanceFilter = ({ filterObject }) => {
  const [campStartDate, setStartDate] = useState(null);
  const [campEndDate, setEndDate] = useState(null);
  const [hospitalname, setHospitalName] = useState("");
  const [reset, setReset] = useState(false);

  const [hospitals, setHospital] = useState([]);
  const { axios } = useAxios();

  const handleSubmit = () => {
    var hospital = hospitals?.find((x) => x._id == hospitalname);
    let obj = {
      campStart: campStartDate
        ? moment(campStartDate.$d).format("YYYY-MM-DD")
        : "",
      campEnd: campEndDate ? moment(campEndDate.$d).format("YYYY-MM-DD") : "",
      hospital_name: hospital == undefined ? "" : hospital?.hospital_name,
    };
    filterObject(obj);
  };

  const handleClose = () => {
    let obj = {
      campStart: "",
      campEnd: "",
      hospital_name: "",
    };
    setStartDate(null);
    setEndDate(null);
    setHospitalName(obj.hospital_name);
    filterObject(obj);
    setReset(!reset);
  };

  useEffect(() => {
    loadHospital();
  }, []);

  async function loadHospital() {
    try {
      await axios.post("AllHospitalDropDown").then((res) => {
        let result = res.data.result;
        console.log("result", result);

        setHospital(
          result.map((data) => {
            return {
              _id: data._id,
              hospital_name: data.hospital_name,
            };
          })
        );
      });
    } catch (error) {
      console.log("hos", error);
    }
  }

  function handleHospitalDrop(e) {
    setHospitalName(e.target.value);
  }

  function firstLetter(s) {
    return s?.replace(/^.{1}/g, s[0].toUpperCase());
  }

  return (
    <div style={{ marginBottom: "0.5%" }}>
      <Stack direction="row" spacing={2}>
        {/* no need date filter here */}
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            placeholder="From Date"
            renderInput={(props) => (
              <TextField {...props} sx={{ width: "10%" }} size="small" />
            )}
            value={campStartDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            placeholder="To Date"
            renderInput={(props) => (
              <TextField {...props} sx={{ width: "10%" }} size="small" />
            )}
            value={campEndDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
            minDate={campStartDate ? new Date(moment(campStartDate.$d)) : ""}
          />
        </LocalizationProvider> */}

        <FormControl style={{ width: "10%" }} size="small">
          <InputLabel id="select-label">Select Hospital</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            variant="outlined"
            label="Select Hospitals"
            value={hospitalname}
            onChange={(e) => {
              handleHospitalDrop(e);
            }}
          >
            {hospitals?.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {firstLetter(option.hospital_name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          style={{ width: "5%" }}
          onClick={handleSubmit}
        >
          Search
        </Button>
        <Button
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          style={{ width: "5%" }}
          onClick={handleClose}
        >
          Reset
        </Button>
      </Stack>
    </div>
  );
};
export default UnitWisePerformanceFilter;
