import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  DialogTitle,
  TextField,
  Stack,
  Select,
  MenuItem,
  FormControl,
  Grid,
  InputLabel,
  Autocomplete,
} from "@mui/material";
import { Form, Formik, Field } from "formik";
import { HybridInput, HybridSelect } from "../components/hybrid_inputs";
import { useAxios } from "../hooks/useAxios";
import { HospitalSchema } from "../validations/userSchema";
import TableComponent from "../components/TableLayout";
import LowerCase from "./LowerCase";
import ConfirmPopup from "../components/confirmPopup";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

export default function HospitalManagement() {
  const [initialState, setInitialValue] = useState({
    hospital_name: "",
    phone: "",
    email: "",
    password: "",
    latitude: "",
    longitude: "",
    survey_radius: "",
    state_id: "",
    district_id: "",
    state_name: "",
    district_name: "",
    hospital_address: "",
    pin_code: "",
  });
  const [header, setHeader] = useState([
    {
      id: 1,
      label: "Hospital Name",
      value: "hospital_name",
    },
    {
      id: 2,
      label: "Hospital Code",
      value: "hospital_code",
    },
    {
      id: 3,
      label: "Phone",
      value: "phone",
    },
    {
      id: 4,
      label: "Address",
      value: "hospital_address",
    },
    {
      id: 5,
      label: "Action",
      value: "action",
    },
  ]);
  let stateModel = {
    _id: "",
    state_name: "No data found",
  };
  let districtModel = {
    _id: "",
    district_name: "No data found",
  };
  let districtValue = [];
  let dummydata = [];
  const [districtList, setDistrictList] = useState([districtModel]);

  const [hospitalDetails, setHospitalDetails] = useState(initialState);
  const [stateList, setStateList] = useState([]);
  const [progressBar, setProgressBar] = useState(false);
  const [open, setOpen] = useState(false);
  const { axios } = useAxios();
  const [hospitalList, setHospitalList] = useState([]);
  const [activeId, setActiveId] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [length, setLength] = useState();
  const [searchValue, setSearchValue] = React.useState("");
  const [stateSelected, setStateSelected] = useState([]);
  const [DistrictData, setDistrictData] = useState([]);
  const [DistrictSelcted, setDistrictSelected] = useState([]);
  const [DistrictEdit, setDistrictEdit] = useState([]);
  const [StateEdit, setStateEdit] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [editState, setEditState] = useState(false);
  const [disabledDistrict, setDisabledDistrict] = useState(true);
  const [stateId, setStateIds] = useState([]);
  const [disable, setDisable] = useState(true);
  const [disableAdd, setDisableAdd] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  let paginationInfo = {
    page: 1,
    count: 0,
    rowsPerPage: 10,
  };
  const [pageInfo, setPageInfo] = useState(paginationInfo);

  useEffect(() => {
    var hospital = localStorage.getItem("hospital_id");
    if (hospital != "undefined") {
      setDisableAdd(true);
    }
  }, []);

  useEffect(() => {
    getHospitalList();
    getStateList();
    setDistrictData([]);
  }, [pageInfo.rowsPerPage, pageInfo.page, editState, searchValue]);
  const getHospitalList = () => {
    axios
      .post(
        `hospitalList?limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}&search=${searchValue}`
      )
      .then((data) => {
        setHospitalList(data.data.result.list);
        setLength(data.data.result.length ? data.data.result.length : 0);
        setPageInfo((prevState) => ({
          ...prevState,
          count: data.data.result.length ? data.data.result.length : 0,
        }));
      })
      .catch(() => {
        setHospitalList([]);
        setLength(0);
        setPageInfo(paginationInfo);
      });
  };
  const getStateList = () => {
    axios.post("statesDropDown").then((data) => {
      if (data.data.result.length > 0) {
        let result = LowerCase(data.data.result, "state_name");
        setStateList(result);
      }
    });
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const getDistrictList = async (value) => {
    setDistrictList([districtModel]);
    let state = {
      state_id: value,
    };
    await axios.post("districtDropDown", state).then((data) => {
      if (data.data.result.length > 0) {
        let result = LowerCase(data.data.result, "district_name");
        setDistrictList(result);
      } else {
        setDistrictList([districtModel]);
      }
    });
    setDisabledDistrict(false);
  };
  const addStates = (actions) => {
    setDistrictEdit([]);
    setStateEdit([]);
    getStateList();
    setDisable(false);
    setEditState(false);
    setOpen(true);
    setInitialValue({
      hospital_name: "",
      phone: "",
      email: "",
      password: "",
      latitude: "",
      longitude: "",
      survey_radius: "",
      state_id: "",
      district_id: "",
      state_name: "",
      district_name: "",
      hospital_address: "",
      pin_code: "",
    });
  };
  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };

  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };
  const closeDialogue = () => {
    setHospitalDetails(initialState);
    setOpen(false);
  };
  const handleSearchValue = (values) => {
    setPageInfo(paginationInfo);
    setSearchValue(values);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // prevent backDrop of dialog
  const handleClosePrevent = (reason) => {
    if (reason == "escapeKeyDown" || reason == "backdropClick") {
      setOpen(true);
    }
  };

  const editData = (data) => {
    setEditState(true);
    setDisable(false);

    hospitalList.forEach((list) => {
      delete list.password;

      if (list._id === data) {
        var alterState = list?.state?.map((x) => {
          var state = { _id: x?.state_id, state_name: x?.state_name };
          return state;
        });

        setSelectedValues(alterState);

        setActiveId(list._id);
        getDistrictList(list.state_id);
        setDistrictEdit(list.district);
        setStateEdit(list.state);
        setInitialValue({ ...list });
        setOpen(true);
      }
    });
  };

  const getOptionDisabled = (option, selectedValues) =>
    selectedValues.some(
      (selectedOption) => selectedOption.state_name === option.state_name
    );

  function deleteCancel() {
    setDeletePopup(false);
  }
  function deleteSubmit() {
    setDeletePopup(false);
    let insertData = {
      _id: activeId,
    };
    axios.post(`hospitalDelete`, insertData).then((data) => {
      if (data.data.success == true) {
        getHospitalList();
      }
    });
  }
  const deleteDate = (data) => {
    setActiveId(data);
    setDeletePopup(true);
  };
  const clear = () => {
    setDistrictData([]);
  };
  const handleDistrictFetch = async (dataValue) => {
    let setValue = [];
    for (let i = 0; i <= dataValue.length - 1; i++) {
      let arrayValue = dataValue[i];
      let state = {
        state_id: arrayValue,
      };
      let res = await axios.post(`districtDropDown`, state).catch((error) => {
        console.log("error in handleDistrictFetch==>", error);
      });
      let result = LowerCase(res.data.result, "district_name");
      setValue.push(...result);
    }
    setDistrictData(setValue);
  };

  const handlDistrictData = (data, value) => {
    let newArrayOfObj = value.map(({ _id: district_id, ...rest }) => ({
      district_id,
      ...rest,
    }));
    setDistrictSelected(newArrayOfObj);
  };

  const handleState = (data, value) => {
    let newArrayOfObj = value.map(({ _id: state_id, ...rest }) => ({
      state_id,
      ...rest,
    }));
    setStateSelected(newArrayOfObj);
    let dataValue = value.map((data) => {
      return data._id;
    });
    setStateIds(dataValue);
    handleDistrictFetch(dataValue);
  };

  const formSubmit = async (values) => {
    if (editState === false) {
      delete values.state_id;
      delete values.state_name;
      delete values.district_id;
      delete values.district_name;
      values.state = stateSelected;
      values.district = DistrictSelcted;

      await axios.post("hospitalCreate", values).then((data) => {
        setDisable(true);
        if (data.data.success === true) {
          closeDialogue();
          getHospitalList();
        }
      });
    } else {
      delete values.role_id;
      delete values.status;
      delete values.created_on;
      delete values.__v;
      delete values.state_id;
      delete values.state_name;
      delete values.district_id;
      delete values.district_name;
      delete values.isOTPVerified;
      delete values.otpSentDate;
      delete values.otpVerifiedDate;
      let state = stateSelected.length > 0 ? stateSelected : StateEdit;
      const newArrOfObj = state.map((obj) => {
        delete obj["_id"];
        return obj;
      });
      let district =
        DistrictSelcted.length > 0 ? DistrictSelcted : DistrictEdit;
      const newArrOfObjDist = district.map((obj) => {
        delete obj["_id"];
        return obj;
      });
      values.state = newArrOfObj;
      values.district = newArrOfObjDist;
      setDisable(true);
      await axios.post("hospitalUpdate", values).then((data) => {
        closeDialogue();
        getHospitalList();
      });
    }
  };
  return (
    <>
      <div className="state_head">
        <Typography variant="h5" color="secondary" className="text-left">
          Hospital Management
        </Typography>
        {
          <Button
            variant="contained"
            sx={{ float: "right", m: 1, width: "10ch" }}
            onClick={() => addStates()}
          >
            Add
          </Button>
        }
      </div>

      <TableComponent
        header={header}
        datalist={hospitalList}
        datalength={length}
        actions={[]}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        handleTablePage={handleTablePage}
        handleTableRowsPerPage={handleTableRowsPerPage}
        editData={(id) => editData(id)}
        deleteData={(id) => deleteDate(id)}
        searchDataValue={(val) => handleSearchValue(val)}
        searchValue={() => {
          setSearchValue("");
          setPageInfo(paginationInfo);
        }}
      />
      {deletePopup ? (
        <ConfirmPopup
          message={"Are you sure, you want to delete this record"}
          deleteCancel={deleteCancel}
          deleteSubmit={deleteSubmit}
        ></ConfirmPopup>
      ) : (
        ""
      )}

      {/* <Dialog open={open} fullWidth onClose={closeDialogue}> */}
      <Dialog open={open} fullWidth onClose={handleClosePrevent}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle style={{ marginLeft: "12rem" }}>
            Add Hospital
          </DialogTitle>
          <DialogTitle>
            <CloseIcon onClick={handleClose} />
          </DialogTitle>
        </div>

        <Formik
          initialValues={initialState}
          validationSchema={HospitalSchema}
          onSubmit={formSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <DialogContent>
                <HybridInput
                  label="Hospital Name"
                  name="hospital_name"
                  type="text"
                  setVal={setFieldValue}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  fullWidth
                  variant="standard"
                />
                <HybridInput
                  label="Hospital Code"
                  name="hospital_code"
                  type="text"
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/[^A-Z a-z 0-9 -]/g, "")
                      .slice(0, 7);
                  }}
                  fullWidth
                  setVal={setFieldValue}
                  variant="standard"
                />

                <HybridInput
                  label="Phone Number"
                  name="phone"
                  type="text"
                  fullWidth
                  setVal={setFieldValue}
                  maxlength="10"
                  InputProps={{ inputProps: { minlength: 0, maxlength: 10 } }}
                  variant="standard"
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9]/g, "")
                      .slice(0, 10);
                  }}
                />
                <HybridInput
                  label="Email"
                  name="email"
                  type="email"
                  setVal={setFieldValue}
                  // inputProps={{style: {textTransform: 'capitalize'}}}
                  fullWidth
                  variant="standard"
                />
                {/* <HybridInput
                  label="Password"
                  name="password"
                  type="password"
                  setVal={setFieldValue}
                  fullWidth
                  variant="standard"
                /> */}

                {!editState && (
                  <HybridInput
                    label="Password"
                    name="password"
                    type="password"
                    setVal={setFieldValue}
                    fullWidth
                    variant="standard"
                  />
                )}
                {editState && (
                  <HybridInput
                    label="New Password"
                    name="password"
                    type="password"
                    setVal={setFieldValue}
                    fullWidth
                    variant="standard"
                  />
                )}
                <HybridInput
                  label="Medics Customer ID"
                  name="medix_customer_id"
                  type="text"
                  setVal={setFieldValue}
                  fullWidth
                  variant="standard"
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9]/g, "")
                      .slice(0, 10);
                  }}
                />
                <HybridInput
                  label="Medics Username"
                  name="medix_user_name"
                  type="text"
                  setVal={setFieldValue}
                  fullWidth
                  variant="standard"
                />
                {!editState && (
                  <HybridInput
                    label="Medics Password"
                    name="medix_password"
                    type="password"
                    setVal={setFieldValue}
                    fullWidth
                    variant="standard"
                  />
                )}
                {editState && (
                  <HybridInput
                    label="Medics New Password"
                    type={showPassword ? "text" : "password"}
                    name="medix_password"
                    setVal={setFieldValue}
                    fullWidth
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ marginRight: "5px" }}
                        >
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}

                <HybridInput
                  label="Latitude"
                  name="latitude"
                  type="text"
                  setVal={setFieldValue}
                  fullWidth
                  variant="standard"
                />
                <HybridInput
                  label="Longitude"
                  name="longitude"
                  setVal={setFieldValue}
                  type="text"
                  fullWidth
                  variant="standard"
                />
                <HybridInput
                  label="Survey Radius"
                  name="survey_radius"
                  type="text"
                  setVal={setFieldValue}
                  fullWidth
                  variant="standard"
                />

                <Autocomplete
                  multiple
                  style={{ marginTop: "3%" }}
                  id="tags-standard"
                  options={stateList}
                  freeSolo
                  // getOptionDisabled={(option) =>
                  //   option === StateEdit ? StateEdit : ""
                  // }
                  value={selectedValues}
                  getOptionDisabled={(option) =>
                    getOptionDisabled(option, selectedValues)
                  }
                  getOptionLabel={(option) => option.state_name}
                  onChange={(data, value) => {
                    handleState(data, value);
                    setSelectedValues(value);
                  }}
                  defaultValue={StateEdit.length > 0 ? StateEdit : []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="State Name"
                      placeholder=""
                    />
                  )}
                />
                <Autocomplete
                  multiple
                  style={{ marginTop: "3%" }}
                  id="tags-standard"
                  options={DistrictData}
                  getOptionLabel={(option) => option.district_name}
                  onChange={(data, value) => handlDistrictData(data, value)}
                  defaultValue={DistrictEdit.length > 0 ? DistrictEdit : []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="District Name"
                      placeholder=""
                    />
                  )}
                />
                {/* <HybridSelect
                  style={{ marginBottom: '10px' }}
                  label="District name"
                  name="district_id"
                  fullWidth
                  type="text"
                  variant="standard"
                  setVal={setFieldValue}
                  options={districtList}
                  disabledDistrict
                  dropKey="district_name"
                  dropVal=""
                /> */}

                {/* <Field name="district_id">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id="select-label" style={{ marginLeft: '-2%', marginTop: '2%' }}>Select district</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        {...field}
                        value={field.value}
                        onChange={(event) => { setFieldValue(field.name, event.target.value) }}
                      >
                        {DistrictData.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.district_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field> */}
                <HybridInput
                  style={{ marginBottom: "10px" }}
                  label="Hospital Address"
                  name="hospital_address"
                  type="text"
                  setVal={setFieldValue}
                  fullWidth
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  variant="standard"
                />
                <HybridInput
                  style={{ marginBottom: "10px" }}
                  label="Pincode"
                  name="pin_code"
                  type="number"
                  fullWidth
                  setVal={setFieldValue}
                  variant="standard"
                  // InputProps={{ inputProps: { minlength: 0, maxlength: 7 } }}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9]/g, "")
                      .slice(0, 6);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <div className="submit">
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      type="button"
                      color="primary"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      onSubmit={formSubmit}
                    >
                      {editState === true ? "Update" : "Add"}
                    </Button>
                  </Stack>
                </div>
              </DialogActions>
            </Form>
          )}
        </Formik>
        {progressBar && <LinearProgress color="primary" />}
      </Dialog>
    </>
  );
}
