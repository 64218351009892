import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import {
  Dialog,
  DialogActions,
  Button,
  CardContent,
  Stack,
  DialogTitle,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Autocomplete,
  Select,
  TableBody,
  TableCell,
  Input,
  Table,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";

import TableComponent from "../components/TableLayout";
import { useAxios } from "../hooks/useAxios";
import DynamicInput from "../components/dy_input";
import {
  HybridInput,
  HybridSelect,
  HybridDatePicker,
} from "../components/hybrid_inputs";
import { EventSchema } from "../validations/userSchema";
import { Form, Formik, Field } from "formik";
import { toast, Slide } from "react-toastify";
import { Box, Typography, Grid } from "@mui/material";
import ConfirmPopup from "../components/confirmPopup";
import LowerCase from "./LowerCase";
import CloseIcon from "@mui/icons-material/Close";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelDownload from "../components/ExcelFormat/ExcelFileDownload";

import { dates } from "../index.js";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

import moment from "moment";

const renderInputs = (props, placeholder, classname) => (
  <Input
    sx={{
      width: "80%",
      input: {
        "&::placeholder": {
          // <----- Add this.
          opacity: 1,
          // marginBottom: '-5px',
          paddingLeft: "10px",
          fontSize: "13px",
          color: "#000000",
        },
        width: "100%",
      },
      backgroundColor: "#ffffff",
      // borderRadius: '5px',
      // maxHeight: '50px'
    }}
    type="text"
    className={classname}
    inputRef={props.inputRef}
    // inputProps={props.inputProps}
    inputProps={{
      ...props.inputProps,
      placeholder: placeholder,
    }}
    value={props.value}
    onClick={props.onClick}
    onChange={props.onChange}
    endAdornment={props.InputProps?.endAdornment}
  />
);

export const EventManagement = () => {
  const [header] = useState([
    {
      id: 1,
      label: "Event Date",
      value: "event_date",
      isChecked: false,
    },
    {
      id: 2,
      label: "Hospital Name",
      value: "hospital_name",
      isChecked: false,
    },
    {
      id: 3,
      label: "Field Worker's Name",
      value: "field_worker",
      isChecked: false,
    },
    {
      id: 4,
      label: "Field Worker's Type",
      value: "field_worker_type",
      isChecked: false,
    },
    {
      id: 5,
      label: "Event ID",
      value: "event_id",
      isChecked: false,
    },
    {
      id: 6,
      label: "Event Title",
      value: "event_name",
      isChecked: false,
    },
    {
      id: 7,
      label: "Description",
      value: "description",
      isChecked: false,
    },
    {
      id: 8,
      label: "Attendance Privilege",
      value: "attendance_access",
      isChecked: false,
    },
    {
      id: 9,
      label: "Action",
      value: "action",
    },
  ]);

  const { axios } = useAxios();
  const [popup, setPopup] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [length, setLength] = useState();
  const [editState, setEditState] = useState(false);
  const [Editable] = useState(true);
  const [DropDown] = useState([
    {
      _id: "Active",
      status: "Active",
    },
    {
      _id: "Inactive",
      status: "Inactive",
    },
  ]);

  const [downloadTab, setDownloadTab] = useState(false);
  const [value, setValue] = useState([]);
  const [dateSearch, setDateSearch] = useState("");

  const [initialValues, setInitialValue] = useState({
    event_name: "",
    description: "",
    hospital_id: "",
    event_date: "",
  });
  const [dynamicInputData] = useState([
    {
      text: true,
      select: false,
      label: "State name",
      name: "state_name",
      type: "text",
      variant: "standard",
    },
    {
      text: false,
      select: true,
      label: "Status",
      name: "status",
      type: "text",
      variant: "standard",
      options: ["Active", "Inactive"],
    },
  ]);
  const [eventList, setEventList] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  let paginationInfo = {
    page: 1,
    count: 0,
    rowsPerPage: 10,
  };
  const [pageInfo, setPageInfo] = useState(paginationInfo);
  let hospitalModel = {
    _id: "",
    hospital_name: "No data found",
  };
  let fieldWorkerModel = {
    _id: "",
    field_worker_id: "No data found",
  };
  let dateFields = ["event_date"];
  const [hospitalList, setHospitalList] = useState([hospitalModel]);

  const [searchValue, setSearchValue] = React.useState({
    eventStart: "",
    eventEnd: "",
  });

  const [fieldWorkersList, setFieldWorkerList] = useState([fieldWorkerModel]);
  const [filterValue, setFilterValue] = useState({
    eventtitle: "",
    hospital: "",
    eventStart: "",
    eventEnd: "",
  });

  // attendance pop-up state management
  const [attendPopup, setAttendPopup] = useState(false);

  // attendance details state management
  const [attendanceDetails, setAttendanceDetails] = useState("");

  // attendance state management (utility)
  const [attendUtility, setAttendUtility] = useState(false);

  useEffect(
    (values) => {
      EventList();
      HospitalList();
    },
    [pageInfo.rowsPerPage, pageInfo.page, filterValue, attendUtility] //filterValue
  );
  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };
  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };
  const EventList = () => {
    axios
      .post(
        `EventAllList?limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}&hospital_name=${filterValue.hospital}&event_name=${filterValue.eventtitle}&fromDate=${filterValue.eventStart}&toDate=${filterValue.eventEnd}`
      )
      .then((data) => {
        let eventValue = data.data.result.list;
        let newArray = eventValue.map((data) => {
          return {
            ...data,
            field_worker: data.field_worker?.map((data) => {
              return data.field_worker_name;
            }),
            field_worker_type: data.field_worker?.map((data) => {
              return data.field_worker_type;
            }),
          };
        });

        let newArray1 = newArray.map((data) => {
          return {
            ...data,
            field_worker: data.field_worker?.join(",\n"),
            field_worker_type: data.field_worker_type?.join(",\n"),
          };
        });

        setEventList(newArray1);
        setLength(data.data.result.length);
        setPageInfo((prevState) => ({
          ...prevState,
          count: data.data.result.length,
        }));
      });
  };

  const addStates = (actions) => {
    setEditState(false);
    // reset();
    // actions.resetForm();
    setInitialValue({
      event_name: "",
      description: "",
      hospital_id: "",
      event_date: "",
    });

    setPopup(true);
  };
  const handleClose = () => {
    setPopup(false);
  };

  // prevent backDrop of dialog
  const handleClosePrevent = (reason) => {
    if (reason == "escapeKeyDown" || reason == "backdropClick") {
      setPopup(true);
    }
  };

  const searchValues = (values) => {};

  const handleSubmit = (values) => {
    let user_id = localStorage.getItem("sankara_admin_id");

    if (editState === false) {
      values["created_by"] = user_id;

      axios.post(`EventAdminCreate`, values).then((data) => {
        if (data.data.success === true) {
          setPopup(false);
          EventList();
        }
      });
    } else {
      let insertData = {
        event_date: values.event_date,
        hospital_id: values.hospital_id,
        status: values.status,
        event_name: values.event_name,
        description: values.description,
        modify_on: new Date(),
        _id: activeId,
        hospital_name: values.hospital_name,
      };

      axios.post(`EventUpdate`, insertData).then((data) => {
        if (data.data.success == true) {
          setPopup(false);

          EventList();
        }
      });
    }
  };
  const reset = () => {
    setInitialValue({ state_name: "", status: "" });
  };
  const editData = (data) => {
    setEditState(true);
    eventList.forEach((list) => {
      if (list._id === data) {
        setActiveId(list._id);
        HospitalList();
        console.log("da", list);
        setInitialValue({ ...initialValues, ...list });
        setPopup(true);
      }
    });
  };
  const deleteDate = (data) => {
    setActiveId(data);
    setDeletePopup(true);
  };
  function deleteCancel(params) {
    setDeletePopup(false);
  }
  function deleteSubmit(params) {
    setDeletePopup(false);
    let insertData = {
      _id: activeId,
    };

    axios.post(`EventDelete`, insertData).then((data) => {
      EventList();
      // setStateList(data.data.data);
    });
  }
  function SearchFunction(params) {}
  const HospitalList = () => {
    axios.post("AllHospitalDropDown").then((data) => {
      let result = LowerCase(data.data.result, "hospital_name");
      setHospitalList(result);
    });
  };

  const getfieldWorkerList = async (value) => {
    let hospital = {
      hospital_id: value,
    };
    await axios.post("HospitalBasedWorkerDropDown ", hospital).then((data) => {
      if (data.data.result.length > 0) {
        setFieldWorkerList(data.data.result);
      } else {
        setFieldWorkerList([fieldWorkerModel]);
      }
    });
  };

  const filterResponse = (val) => {
    setFilterValue(val);
  };

  const handleSearchValue = (values) => {
    setPageInfo(paginationInfo);
    setFilterValue(values);
  };

  function alterScreenName(name) {
    var menu_name = name.replaceAll("_", " ");
    var name = menu_name?.replace(/^.{1}/g, menu_name[0].toUpperCase());
    return name;
  }

  function handleDates(val) {
    const today = new Date(); // Get the current date
    setDateSearch(val);

    // Format the dates as "DD-MM-YYYY"
    var dateFormat = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    if (val == "last_week") {
      //==============last week ======================

      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7); // Subtract 7 days to get the date for last week

      // Find the start date (Monday) of last week
      const startOfWeek = new Date(lastWeek);
      startOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7));

      // Find the end date (Sunday) of last week
      const endOfWeek = new Date(lastWeek);
      endOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7) + 6);

      // Format the dates
      var formattedStartOfWeek = startOfWeek.toISOString().split("T")[0];
      var formattedEndOfWeek = endOfWeek.toISOString().split("T")[0];

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartOfWeek,
        eventEnd: formattedEndOfWeek,
      }));
    } else if (val == "last_month") {
      //================last month ===============================
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

      // Set the day to 1 to get the first day of the previous month
      lastMonth.setDate(2);

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: lastMonth.toISOString().split("T")[0],
        eventEnd: moment().date(0).toISOString().split("T")[0],
      }));
    } else if (val == "last_3_month") {
      //================last month ===============================

      const threeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        1
      );

      // Set the day to 1 to get the first day of three months ago
      threeMonthsAgo.setDate(1);

      const lastDayOfThreeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );

      const formattedStartDate = threeMonthsAgo.toLocaleDateString("en-IN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "Asia/Kolkata",
      });
      const formattedEndDate = lastDayOfThreeMonthsAgo.toLocaleDateString(
        "en-IN",
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone: "Asia/Kolkata",
        }
      );

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
        eventEnd: formattedEndDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
      }));
    } else if (val == "all") {
      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: "",
        eventEnd: "",
      }));
    }
  }

  const handleChange = (e, val, position, headerName) => {
    const index = value?.findIndex((x) => x.value == val);

    if (e.target.checked) {
      if (index < 0) {
        setValue(() => {
          var values = { position: position, value: val, header: headerName };
          var data = { ...value, values };
          return Object.values(data);
        });
      } else {
        value.splice(index, 1);
      }
    } else {
      value.splice(index, 1);
    }
  };

  const handleuploadExcel = async () => {
    await axios
      .post(
        `EventAllList?limit=&page=${1}&hospital_name=${
          filterValue.hospital
        }&event_name=${filterValue.eventtitle}&fromDate=${
          searchValue.eventStart
        }&toDate=${searchValue.eventEnd}`
      )
      .then((res) => {
        let eventValue = res.data.result.list;

        console.log("eventValue*", eventValue);
        const data = eventValue.map((x, index) => {
          return {
            ...x,
            sno: (index += 1),
            event_date: x.event_date.slice(0, 10),
            field_worker_name: x.field_worker
              ?.map(({ field_worker_name }) => field_worker_name)
              .toString(),
            field_worker_type: x.field_worker
              ?.map(({ field_worker_type }) => field_worker_type)
              .toString(),
          };
        });
        let newArrayOfObj = data.map(
          ({
            sno: Sno,
            event_date: EventDate,
            hospital_id: HospitalID,
            hospital_name: Hospitalname,
            field_worker_name: FieldWorkersName,
            field_worker_type: FieldWorkersType,
            event_id: EventId,
            event_name: EventTitle,
            description: Description,
          }) => ({
            Sno,
            EventDate,
            HospitalID,
            Hospitalname,
            FieldWorkersName,
            FieldWorkersType,
            EventId,
            EventTitle,
            Description,
          })
        );

        ExcelDownload(newArrayOfObj, "Event");

        setDownloadTab((o) => !o);
        setDateSearch("");
      });
  };

  // handle attendance status updation endpoint
  const updateAttendance = async () => {
    try {
      const postData = {
        _id: attendanceDetails?._id,
        status: attendanceDetails?.attendance_access == 1 ? 0 : 1,
      };

      const response = await axios.post("EventStatusUpdate", postData);
      console.log("updateAttendance reponse", response);
      const { success } = response?.data;
      if (success) {
        setAttendPopup(false);
        setAttendanceDetails("");

        // attendance utility
        setAttendUtility(true);
      }
    } catch (e) {
      console.log("updateAttendance catch err", e);
      const { success, message } = e?.response?.data;
      setAttendPopup(false);
      setAttendanceDetails("");

      // attendance utility
      setAttendUtility(false);

      if (!success) {
        toast.error(message, {
          autoClose: 1500,
          transition: Slide,
        });
      }
    }
  };

  // handle attendance (utility)
  const handleAttendanceAccess = (params) => {
    setAttendPopup(true);
    setAttendanceDetails(params);

    // attendance utility
    setAttendUtility(false);
  };

  // handle attendance cancel (utility)
  const handleAttendanceAccessCancel = () => {
    setAttendPopup(false);
    setAttendanceDetails("");
  };

  // prevent backDrop of dialog (utility)
  const handleClosePreventAttend = (reason) => {
    if (reason == "escapeKeyDown" || reason == "backdropClick") {
      setAttendPopup(true);
    }
  };

  return (
    <div>
      <div className="state_head">
        <Typography variant="h5" color="secondary" className="text-left">
          Event Management
        </Typography>
        <div>
          <Button
            variant="contained"
            sx={{ m: 1, width: "28ch", textTransform: "capitalize" }}
            startIcon={<FileDownloadIcon />}
            // onClick={handleuploadExcel}
            onClick={() => setDownloadTab((k) => !k)}
          >
            Download Event
          </Button>
          <Button
            variant="contained"
            sx={{
              float: "right",
              m: 1,
              width: "10ch",
              textTransform: "capitalize",
            }}
            onClick={() => addStates()}
          >
            {" "}
            Add
          </Button>
        </div>
      </div>

      {downloadTab && (
        <div className="tabbackground">
          {/* <div className='downloadtab' > */}
          <div
            className={dateSearch == "custom" ? "nonreports" : "nonreports1"}
          >
            <div className="table">
              <Table>
                <TableHead>
                  <tr>
                    {/* <th><Checkbox
                      onChange={(e) => {
                        handleSelectAll(e);
                      }}
                    />
                      Select all</th> */}
                    <th> </th>
                    <th>Select Duration</th>
                  </tr>
                </TableHead>
              </Table>
              <div className="filter">
                <FormControl style={{ width: "80%" }} size="small">
                  <Select
                    labelId="select-label"
                    id="select"
                    variant="standard"
                    label="Select Dates"
                    value={dateSearch}
                    onChange={(e) => {
                      handleDates(e.target.value);
                    }}
                  >
                    {dates?.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {dateSearch == "custom" && (
                  <div style={{ width: "100%" }} className="custom">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="from_date"
                        value={
                          searchValue.eventStart == ""
                            ? null
                            : searchValue.eventStart
                        }
                        inputFormat="DD/MM/YYYY"
                        onChange={(e) => {
                          const selectedDate = e.$d;
                          if (selectedDate) {
                            setSearchValue({
                              ...searchValue,
                              eventStart: selectedDate,
                            });
                          } else {
                            setSearchValue({
                              ...searchValue,
                              eventStart: null,
                            });
                          }
                        }}
                        slotProps={{
                          actionBar: {
                            actions: ["clear"],
                          },
                        }}
                        renderInput={(props) =>
                          renderInputs(props, "From date", "date")
                        }
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="from_date"
                        value={
                          searchValue.eventEnd == ""
                            ? null
                            : searchValue.eventEnd
                        }
                        inputFormat="DD/MM/YYYY"
                        onChange={(e) => {
                          setSearchValue({ ...searchValue, eventEnd: e.$d });
                        }}
                        renderInput={(props) =>
                          renderInputs(props, "To date", "date")
                        }
                      />
                    </LocalizationProvider>
                  </div>
                )}
              </div>
            </div>
            <div className="downloadoptions">
              <Button
                variant="contained"
                onClick={() => {
                  setDownloadTab((k) => !k);
                  setDateSearch("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={dateSearch == ""}
                onClick={() => handleuploadExcel()}
              >
                Download
              </Button>{" "}
              {/*onClick={handleuploadExcel}*/}
            </div>
          </div>
        </div>
      )}

      <TableComponent
        header={header}
        filterManage={"event"}
        Editable={Editable}
        datalist={eventList}
        datalength={length}
        editData={(id) => editData(id)}
        actions={["edit", "delete"]}
        deleteData={(id) => deleteDate(id)}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        handleTablePage={handleTablePage}
        handleTableRowsPerPage={handleTableRowsPerPage}
        dateFields={dateFields}
        filterResponseValue={(val) => filterResponse(val)}
        searchDataValue={(val) => handleSearchValue(val)}
        handleAttendanceAccess={(val) => handleAttendanceAccess(val)}
        filter="event"
        collapse={true}
      ></TableComponent>
      {deletePopup ? (
        <ConfirmPopup
          message={"Are you sure, you want to delete this record"}
          deleteCancel={deleteCancel}
          deleteSubmit={deleteSubmit}
        ></ConfirmPopup>
      ) : (
        ""
      )}
      <Dialog
        open={popup}
        scroll="paper"
        fullWidth={true}
        onClose={handleClosePrevent}
        style={{ height: "500px" }}
      >
        {" "}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle style={{ marginLeft: "12rem" }}>Add Event</DialogTitle>
          <DialogTitle>
            <CloseIcon onClick={handleClose} />
          </DialogTitle>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={EventSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <CardContent>
                <Grid item xs={12} md={6}>
                  <HybridInput
                    label="Event Name"
                    name="event_name"
                    inputProps={{ style: { textTransform: "capitalize" } }}
                    type="text"
                    fullWidth
                    setVal={setFieldValue}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <HybridDatePicker
                    label="Event Date"
                    name="event_date"
                    fullWidth
                    disablePast
                    variant="standard"
                    initialDate={initialValues.event_date}
                    setVal={setFieldValue}
                    //   onChange={(e) => setFromDate(e)}
                  />
                </Grid>{" "}
                <Grid item xs={12} md={6}>
                  <Field name="hospital_id">
                    {({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel
                          id="select-label"
                          style={{ marginLeft: "-3%", marginTop: "2%" }}
                        >
                          Select Hospital
                        </InputLabel>
                        <Select
                          labelId="select-label"
                          id="select"
                          variant="standard"
                          label="Select Hospital"
                          {...field}
                          value={field.value}
                          onChange={(event) => {
                            setFieldValue(field.name, event.target.value);
                            getfieldWorkerList(event.target.value);
                          }}
                        >
                          {hospitalList.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.hospital_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                </Grid>{" "}
                <Grid item xs={12} md={6}>
                  <HybridInput
                    label="Description"
                    name="description"
                    type="text"
                    inputProps={{ style: { textTransform: "capitalize" } }}
                    fullWidth
                    setVal={setFieldValue}
                    variant="standard"
                  />
                </Grid>{" "}
                <div className="submit">
                  <Stack direction="row" spacing={2}>
                    <Button
                      color="primary"
                      type="button"
                      variant="contained"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    <Button color="primary" type="submit" variant="contained">
                      {editState ? "Update" : "Add"}
                    </Button>
                  </Stack>
                </div>
              </CardContent>
            </Form>
          )}
        </Formik>
      </Dialog>

      {/* dialog attendance */}
      <div>
        <Dialog
          open={attendPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={handleClosePreventAttend}
        >
          <DialogTitle id="alert-dialog-title">
            Do you want to {attendanceDetails?.attendance_access_lable}{" "}
            attendance access for this fieldworker?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleAttendanceAccessCancel}>Cancel</Button>
            <Button onClick={() => updateAttendance()} autoFocus>
              {" "}
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
