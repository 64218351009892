import * as React from "react";

// axios
import { useAxios } from "../../hooks/useAxios";

// styling
import "./Style.scss";

// toast
import { toast, Slide } from "react-toastify";

// backdrop component
import BackDrop from "../../components/BackDrop.js";

// mui
import {
  Typography,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
} from "@mui/material";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

// mui icons
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// filter component
import HistoricalTrendFilter from "../../components/Filter/HistoricalTrendFilter";

// excel utility
import ExcelDownload from "../../components/ExcelFormat/ExcelFileDownload";

// export headers options
const head = [
  {
    field: "all",
    headerName: "ALL",
    width: 100,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "key_performance_indicator",
    headerName: "Key Performance Indicator",
    width: 400,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "april",
    headerName: "April",
    width: 100,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "may",
    headerName: "May",
    width: 100,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "june",
    headerName: "June",
    width: 100,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "july",
    headerName: "July",
    width: 100,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "august",
    headerName: "August",
    width: 100,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "september",
    headerName: "September",
    width: 100,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "october",
    headerName: "October",
    width: 100,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "november",
    headerName: "November",
    width: 100,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "december",
    headerName: "December",
    width: 100,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "january",
    headerName: "January",
    width: 100,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "february",
    headerName: "February",
    width: 100,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "march",
    headerName: "March",
    width: 100,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "total",
    headerName: "Total",
    width: 100,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
];

// fields mapping in after response
const fields = {
  total_no_of_camps: "Total no. of Camps",
  total_no_of_household_survey_done: "Total no. of Household Survey Done",
  total_no_of_individuals_screened: "Total no. of Individuals Screened",
  total_no_of_yc_issued: "Total no.of Yellow Cards Issued",
  total_no_of_pc_issued: "Total no.of Pink Cards Issued",
  total_no_of_yc_reported_at_camp: "Total no. of Yellow Cards Reported at Camp",
  total_no_of_pc_collected_at_vision_center:
    "Total no. of Pink Cards Collected at Vision Center",
  total_no_of_yc_selected_for_surgery:
    "Total no. of Yellow Cards Selected for Surgery",
  total_pink_cards_selected_for_surgery:
    "Total no. of Pink Cards Selected for Surgery",
  total_no_of_yc_converted_to_gov_surgery:
    "Total no. of Yellow Cards Converted to GOV Surgery",
  total_no_of_pc_converted_to_gov_surgery:
    "Total no. of Pink Cards Converted to GOV Surgery",
};

function HistoricalTrend() {
  // axios interceptors
  const { axios } = useAxios();

  // grid headers
  const columns = [
    {
      field: "sno",
      headerName: "S.No",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "key_performance_indicator",
      headerName: "Key Performance Indicator",
      width: 400,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "april",
      headerName: "April",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "may",
      headerName: "May",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "june",
      headerName: "June",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "july",
      headerName: "July",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "august",
      headerName: "August",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "september",
      headerName: "September",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "october",
      headerName: "October",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "november",
      headerName: "November",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "december",
      headerName: "December",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "january",
      headerName: "January",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "february",
      headerName: "February",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "march",
      headerName: "March",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total",
      headerName: "Total",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
  ];

  // loader state
  const [backDropOpen, setBackDropOpen] = React.useState(false);

  // filter initial state
  const [filterValue, setFilterValue] = React.useState({
    hospital_name: "",
    fromDate: "",
    toDate: "",
  });

  // excel download popup state management
  const [isDownPopup, setIsDownPopup] = React.useState(false);
  const [headerValue, setHeaderValue] = React.useState(head);
  const [value, setValue] = React.useState([]);

  // data state
  const [rows, setRows] = React.useState([]);

  // initial pagination state
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
  const [length, setLength] = React.useState(0);

  // fetching datas
  React.useEffect(() => {
    fetchHistoricalTrendList();
  }, [filterValue, paginationModel, head]);

  // fetching fetchHistoricalTrendList list
  async function fetchHistoricalTrendList() {
    try {
      const response = await axios.get(`HistoricalReport`, {
        params: {
          fromDate: filterValue.fromDate,
          toDate: filterValue.toDate,
          hospital_name: filterValue.hospital_name,
          limit: paginationModel.pageSize,
          page: paginationModel.page + 1,
        },
      });
      console.log("fetchHistoricalTrendList response", response);
      const listLength = response?.data?.result?.length || 0;
      if (listLength > 0) {
        setLength(listLength);
        const result = response?.data?.result?.data?.map(
          ({ key_performance_indicator, ...rest }, index) => ({
            id: generateUniqueId(),
            sno: (index += 1),
            key_performance_indicator: fields[key_performance_indicator],
            ...rest,
          })
        );
        setRows(result);
      } else {
        setRows([]);
        setLength(0);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  // excel down api req
  const handleExcelDownload = async () => {
    try {
      setBackDropOpen(true);
      const response = await axios.get(
        `HistoricalReport?fromDate=${filterValue.fromDate}&toDate=${
          filterValue.toDate
        }&hospital_name=${filterValue.hospital_name}&limit=&page=${1}`
      );
      console.log("fetchHistoricalTrendList response", response);
      const listLength = response?.data?.result?.length || 0;
      if (listLength > 0) {
        const result = response?.data?.result?.data?.map(
          ({ key_performance_indicator, ...rest }, index) => ({
            sno: (index += 1),
            key_performance_indicator: fields[key_performance_indicator],
            ...rest,
          })
        );
        console.log("result", result);

        if (value.length > 1) {
          value.sort((x, y) => x.position - y.position);
        }
        let endResult = [];
        result?.map((row) => {
          let filteredObj = {};
          value?.map((val) => {
            if (Array.isArray(row[val.value])) {
              filteredObj[val.header] = row[val.value]
                ?.map((val) => val)
                .toString();
            } else {
              filteredObj[val.header] = row[val.value];
            }
          });
          endResult.push(filteredObj);
        });
        console.log("endResult", endResult);
        ExcelDownload(endResult, "HistoricalTrendReport");
        setIsDownPopup((k) => !k);
        setValue([]);
        setBackDropOpen(false);
      } else {
        setBackDropOpen(false);
        toast.warning("No Data Found", {
          autoClose: 1500,
          transition: Slide,
        });
      }
    } catch (e) {
      console.log("e", e);
      setBackDropOpen(false);
    }
  };

  // filter utility functions
  const filteredObjectArray = (val) => {
    setFilterValue(val);
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  // grid utility functions
  const CustomToolBar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarContainer />
        {/* <GridToolbarFilterButton /> */}
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  // handle excel downlaod popup checkbox headers
  const handleChange = (e, val, position, headerName) => {
    const index = value?.findIndex((x) => x.value == val);
    console.log("index", index);
    if (e.target.checked) {
      if (index < 0) {
        if (headerName == "ALL") {
          let valueReference = [];
          head.forEach((data, index) => {
            data.isChecked = true;
            var values = {
              position: index,
              value: data?.field,
              header: data?.headerName,
            };
            valueReference.push(values);
            console.log("valueReference", valueReference);
            let sliceData = valueReference.slice(1);
            setValue(sliceData);
          });
          console.log("value", value);
        } else {
          head[position].isChecked = true;
          setValue(() => {
            var values = { position: position, value: val, header: headerName };
            var data = { ...value, values };
            console.log("value------", value);
            return Object.values(data);
          });
        }
      } else {
        // value.splice(index, 1);
        if (headerName == "ALL") {
          head.forEach((data, index) => {
            data.isChecked = false;
          });
          setValue([]);
        } else {
          head[position].isChecked = false;
          value.splice(index, 1);
          setHeaderValue(head);
        }
      }
    } else {
      // value.splice(index, 1);
      if (headerName == "ALL") {
        head.forEach((data, index) => {
          data.isChecked = false;
        });
        setValue([]);
      } else {
        let headReference = [...head];
        headReference[position].isChecked = false;
        value.splice(index, 1);
        setHeaderValue(headReference);
      }
    }
  };

  // utility functions
  const alterScreenName = (name) => {
    var menu_name = name.replaceAll("_", " ");
    var name = menu_name?.replace(/^.{1}/g, menu_name[0].toUpperCase());
    return name;
  };

  // unique id utility function
  const generateUniqueId = () =>
    `${Date.now()}-${Math.floor(Math.random() * 10000)}`;

  return (
    <div className="main-report">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0",
        }}
      >
        <Typography variant="h5" color="secondary" className="text-left">
          Historical Trend
        </Typography>
        <Button
          sx={{ textTransform: "capitalize", width: "auto", margin: "0 10px" }}
          disabled={rows?.length == 0}
          size="small"
          variant="contained"
          startIcon={<FileDownloadIcon />}
          onClick={() => {
            head.forEach((data) => {
              data.isChecked = false;
            });
            setIsDownPopup((k) => !k);
          }}
        >
          Excel Download
        </Button>
      </div>

      {/* master report filter */}
      <div>
        <HistoricalTrendFilter
          filterObject={(val) => filteredObjectArray(val)}
        />
      </div>

      {/* excel downlaod container */}
      {isDownPopup && (
        <div className="tabbackground">
          <div className="downloadtab">
            <div className="table">
              <Table>
                <TableHead>
                  <tr>
                    <th> </th>
                    <th>Columns </th>
                  </tr>
                </TableHead>
              </Table>
              <Table>
                <TableBody>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  {headerValue?.map((head, index) => {
                    if (head.headerName !== "S no") {
                      return (
                        <TableRow key={index}>
                          <TableCell sx={{ textAlign: "center", width: "25%" }}>
                            <Checkbox
                              checked={head.isChecked}
                              onChange={(e) => {
                                handleChange(
                                  e,
                                  head.field,
                                  index,
                                  head.headerName
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {alterScreenName(head?.headerName)}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </div>
            <div className="downloadoptions">
              <Button
                variant="contained"
                onClick={() => {
                  setIsDownPopup((k) => !k);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={value.length == 0}
                onClick={() => handleExcelDownload()}
              >
                Download
              </Button>{" "}
            </div>
          </div>
        </div>
      )}

      {/* fetchHistoricalTrend report datas */}
      <div className="table">
        <DataGrid
          style={{ height: "75vh", width: "95vw" }}
          className="table-pagination"
          rowCount={length}
          page={paginationModel.page}
          sx={{
            boxShadow: 2,
            overflowX: "auto",
            border: 2,
            textTransform: "capitalize",
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .super-app-theme--header": {
              backgroundColor: "LightBlue",
            },
          }}
          // autoHeight
          rows={rows}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          slots={{ toolbar: CustomToolBar }}
          paginationMode="server"
          disableDensitySelector
          initialState={{
            columns: {
              columnVisibilityModel: {
                total: false,
              },
            },
          }}
          hideFooterPagination={true}
          hideFooterSelectedRowCount={true}
        />
      </div>

      {/* backdrop */}
      <BackDrop open={backDropOpen} />
    </div>
  );
}

export default HistoricalTrend;
