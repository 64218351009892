import React, { useState, useEffect } from 'react'
import { Loader } from "@googlemaps/js-api-loader"
import { TextField } from '@mui/material'
import camp_png from '../../assets/camp.png'
import cataract from '../../assets/cataract.png'
import hyper from '../../assets/hyper.png'
import diaMarker from '../../assets/dia.png'
import cataractdia from '../../assets/cataractdia.png'
import cataracthyper from '../../assets/cataracthyper.png'
import cataracthyperdia from '../../assets/cataracthyperdia.png'
import diahper from '../../assets/diahyper.png'
import surgerydone from '../../assets/surgerydone.png'
import hosueholds from '../../assets/households.png'


const loader = new Loader({
    apiKey: "AIzaSyDoxlT05aCQHJP_Ni5tkXixB3JnYj6ltPY",
    version: "weekly",
    libraries: ['visualization'],
})

const CampMap = ({ camp_list, heatMap, detailData, reset }) => {
    const [infoWindow, setInfoWindow] = useState(null);
    const [camps, setCamps] = useState(null)
    const [map, setMap] = useState(null)
    const [markers, setMarkers] = useState([])
    const [heatOrHouse, setHeatOrHouse] = useState(null);
    const [markerInstance, setMarkerInstance] = useState([])
    const [position, setPosition] = useState({
        // lat: 17.3850,
        // lng: 78.4867 
        lat: 21.1458,
        lng: 79.0882
    })


    const WORLD_BOUNDS = {
        north: 70,
        south: -65,
        west: -120,
        east: 170,
    };
    const mapOptions = {
        center: {
            lat: position.lat,
            lng: position.lng,
        },
        zoom: 4,
        disableDefaultUI: true,
        zoomControl: true,
        streetViewControl: false,
        fullscreenControl: true,
        // styles: myStyles,
        // maxZoom: 14,
        restriction: {
            latLngBounds: WORLD_BOUNDS,
            strictBounds: false,
        },
    }

    useEffect(() => {
        initializeCampMap()
    }, [reset])

    useEffect(() => {
        if (camp_list != null || camp_list != undefined) {
            handleCampMap()
        }
    }, [camp_list])

    useEffect(() => {
        if (heatOrHouse) {
            handleCampMap()
        } else {
            setHeatMap()
        }
    }, [heatOrHouse])

    async function initializeCampMap() {
        const google_ = await loader.load()
        const infoWindow = new google_.maps.InfoWindow();

        const map = new google_.maps.Map(document.getElementById("camps"), mapOptions);
        setMap(map);
        setInfoWindow(infoWindow);

        const controlDiv = document.createElement("div");
        controlDiv.style.padding = "5px";
        controlDiv.style.backgroundColor = "#ff7100";
        controlDiv.style.border = "1px solid #ffffff";

        const controlUI = document.createElement("div");
        controlUI.style.cursor = "pointer";
        controlUI.style.textAlign = "center";
        controlUI.style.width = "100px";
        controlUI.style.fontWeight = "bold";
        controlUI.style.color = "#ffffff";
        controlUI.innerHTML = "Show Illness";

        let showMarkers = true; // initialize showMarkers variable to true

        controlUI.addEventListener("click", () => {
            showMarkers = !showMarkers; // toggle showMarkers variable on click

            if (showMarkers) {
                setHeatOrHouse(true)
                controlUI.innerHTML = "Show Illness";
            } else {
                setHeatOrHouse(false)
                controlUI.innerHTML = "Show HouseHold";
            }
        });

        controlDiv.appendChild(controlUI);
        map.controls[google_.maps.ControlPosition.TOP_RIGHT].push(controlDiv);

        markers.forEach(marker => {
            marker.setMap(map);
        });

        return () => {
            setMap(null); // removes the map and any added controls when the component unmounts
        };
    }

    async function setHeatMap() {
        const google_ = await loader.load();
        const heatmapArray = [];

        markers.forEach(marker => marker.setMap(null));  // illness icon marker icons
        markerInstance.forEach(marker => marker.setMap(null))  // to remove camp and househodl icons

        const bounds = new google_.maps.LatLngBounds();
        const infoWindow = new google_.maps.InfoWindow();
        const markerInfoWindow = new google_.maps.InfoWindow();

        const cataract = [
            "rgba(9, 144, 255,0)",
            "rgba(0, 153, 255,1)",
            "rgba(0, 162, 255,1)",
            "rgba(0, 170, 255,1)",
            "rgba(0, 178, 255,1)",
            "rgba(0, 186, 255,1)",
            "rgba(0, 193, 255,1)",
            "rgba(0, 200, 255,1)",
            "rgba(0, 207, 255,1)",
            "rgba(8, 214, 255,1)",
        ];

        const dia = [
            "rgba(255, 114, 0,0)",
            "rgba(255, 130, 0,1)",
            "rgba(255, 146, 0,1)",
            "rgba(255, 162, 0,1)",
            "rgba(255, 178, 0,1)",
            "rgba(255, 194, 0,1)",
            "rgba(255, 209, 0,1)",
            "rgba(255, 224, 0,1)",
            "rgba(252, 240, 0,1)",
            "rgba(246, 255, 0,1)",
        ]

        const hy = [
            "rgba(150, 0, 255,0)",
            "rgba(127, 57, 255,1)",
            "rgba(103, 81, 255,1)",
            "rgba(75, 99, 255,1)",
            "rgba(41, 113, 255,1)",
            "rgba(0, 125, 255,1)",
            "rgba(0, 136, 255,1)",
            "rgba(0, 146, 255,1)",
            "rgba(0, 154, 255,1)",
            "rgba(0, 162, 255,1)",
        ]


        var intensity = 15

        var h_diabetes = { maxIntensity: intensity, data: [] }
        var h_hypertension = { maxIntensity: intensity, data: [] }
        var h_cataract = { maxIntensity: intensity, data: [] }

        const dia_m = {
            url: diaMarker,
            size: new google_.maps.Size(60, 60),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };
        const hy_m = {
            url: hyper,
            size: new google_.maps.Size(60, 60),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };
        const cataractDia = {
            url: cataractdia,
            size: new google_.maps.Size(60, 60),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };
        const cataractHyper = {
            url: cataracthyper,
            size: new google_.maps.Size(60, 60),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };
        const cataractHyperDia = {
            url: cataracthyperdia,
            size: new google_.maps.Size(60, 60),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };
        const diaHyper = {
            url: diahper,
            size: new google_.maps.Size(60, 60),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };
        const surgeryDone = {
            url: surgerydone,
            size: new google_.maps.Size(60, 60),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };

        var allMarks = [];
        heatMap?.cataract?.illness_coords?.forEach((coord) => {
            let marker = new google_.maps.Marker({
                position: new google_.maps.LatLng(
                    coord.latitude,
                    coord.longitude
                ),
                map: map,
                icon: dia_m
            });
            allMarks.push(marker);
        });

        heatMap?.hypertension?.illness_coords?.forEach((coord) => {
            let marker = new google_.maps.Marker({
                position: new google_.maps.LatLng(
                    coord.latitude,
                    coord.longitude
                ),
                map: map,
                icon: hy_m
            });
            allMarks.push(marker);
        });
        heatMap?.diabetes?.illness_coords?.forEach((coord) => {
            let marker = new google_.maps.Marker({
                position: new google_.maps.LatLng(
                    coord.latitude,
                    coord.longitude
                ),
                map: map,
                icon: hy_m
            });
            allMarks.push(marker);
        });
        heatMap?.cataractDia?.illness_coords?.forEach((coord) => {
            let marker = new google_.maps.Marker({
                position: new google_.maps.LatLng(
                    coord.latitude,
                    coord.longitude
                ),
                map: map,
                icon: cataractDia
            });
            allMarks.push(marker);
        });
        heatMap?.cataractHyper?.illness_coords?.forEach((coord) => {
            let marker = new google_.maps.Marker({
                position: new google_.maps.LatLng(
                    coord.latitude,
                    coord.longitude
                ),
                map: map,
                icon: cataractHyper
            });
            allMarks.push(marker);
        });
        heatMap?.surgerydonedetails?.illness_coords?.forEach((coord) => {
            let marker = new google_.maps.Marker({
                position: new google_.maps.LatLng(
                    coord.latitude,
                    coord.longitude
                ),
                map: map,
                icon: surgeryDone
            });
            allMarks.push(marker);
        });
        heatMap?.HyperDia?.illness_coords?.forEach((coord) => {
            let marker = new google_.maps.Marker({
                position: new google_.maps.LatLng(
                    coord.latitude,
                    coord.longitude
                ),
                map: map,
                icon: diaHyper
            });
            allMarks.push(marker);
        });
        heatMap?.cataractHyperDia?.illness_coords?.forEach((coord) => {
            let marker = new google_.maps.Marker({
                position: new google_.maps.LatLng(
                    coord.latitude,
                    coord.longitude
                ),
                map: map,
                icon: cataractHyperDia
            });
            allMarks.push(marker);
        });


        heatMap?.diabetes?.illness_coords?.forEach((coord) => {
            h_diabetes.data.push(new google_.maps.LatLng(coord.latitude, coord.longitude));
        });
        heatMap?.hypertension?.illness_coords?.forEach((coord) => {
            h_hypertension.data.push(new google_.maps.LatLng(coord.latitude, coord.longitude));
        });
        heatMap?.cataract?.illness_coords?.forEach((coord) => {
            h_cataract.data.push(new google_.maps.LatLng(coord.latitude, coord.longitude));
        });

        setMarkers(allMarks)

        const dia_heatmap = new google_.maps.visualization.HeatmapLayer({
            data: h_diabetes.data,
            gradient: dia
        });

        const hyp_heatmap = new google_.maps.visualization.HeatmapLayer({
            data: h_hypertension.data,
            gradient: hy
        });

        const heatmap = new google_.maps.visualization.HeatmapLayer({
            data: h_cataract.data,
            gradient: cataract

        });

        allMarks.forEach(marker => {
            bounds.extend(marker.getPosition());
        });

        map.fitBounds(bounds);

        dia_heatmap.setMap(map);
        hyp_heatmap.setMap(map);
        heatmap.setMap(map);
    }

    async function handleCampMap() {
        const google_ = await loader.load()
        markers.forEach(marker => marker.setMap(null));         // illness icon marker icons
        markerInstance.forEach(marker => marker.setMap(null));  // to remove camp and househodl icons

        const circleArray = [];

        const camp_icon = {
            url: camp_png,
            size: new google_.maps.Size(50, 50),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };

        const hosuehold = {
            url: hosueholds,
            size: new google_.maps.Size(50, 50),
            origin: new google_.maps.Point(0, 0),
            anchor: new google_.maps.Point(25, 25),
        };


        function resizeMarkers(map) {
            markerInstance.forEach(function (marker) {
                var pixelSizeAtZoom0 = 5; // the size of the icon at zoom level 0
                var maxPixelSize = 30; // restricts the maximum size of the icon

                var zoom = map.getZoom();
                var relativePixelSize = Math.round(pixelSizeAtZoom0 * Math.pow(2, zoom));

                if (relativePixelSize > maxPixelSize) {
                    relativePixelSize = maxPixelSize;
                }

                marker.setIcon({
                    url: marker.getIcon().url, //marker's same icon graphic
                    size: null,
                    origin: null,
                    anchor: null,
                    scaledSize: new google_.maps.Size(relativePixelSize, relativePixelSize) //changes the scale
                })
            });
        }

        google_.maps.event.addListener(map, 'zoom_changed', function () {
            resizeMarkers(map);
        });


        const bounds = new google_.maps.LatLngBounds();
        const infoWindow = new google_.maps.InfoWindow();
        const markerInfoWindow = new google_.maps.InfoWindow();

        let newmarker = [];

        // Object.keys(camp_list)?.forEach((i) => {
        for (let i = 0; i <= Object.keys(camp_list).length - 1; i++) {
            let iItem = camp_list[Object.keys(camp_list)[i]];
            // Object.keys(camp_list[i]?.coords)?.forEach((j) => {
            if (iItem.coords) {

                for (let j = 0; j <= Object.keys(iItem.coords).length - 1; j++) {
                    let jItem = Object.keys(iItem.coords)[j];

                    if (iItem.coords[jItem] && iItem.coords[jItem].length > 0) {
                        // camp_list[i]?.coords[j]?.forEach((k) => {
                        for (let k = 0; k <= iItem.coords[jItem].length - 1; k++) {
                            let kItem = iItem.coords[jItem][k];
                            let marker = ""
                            marker = new google_.maps.Marker({
                                position: new google_.maps.LatLng(
                                    kItem.current_latitude,
                                    kItem.current_longitude
                                ),
                                map: map,
                                icon: jItem == 'camp_coords' ? camp_icon : hosuehold
                            });

                            var data = {
                                lat: kItem.current_latitude,
                                lng: kItem.current_longitude
                            }


                            if (jItem == 'household_coords') {
                                marker.addListener("click", async () => {
                                    
                                    var data = camp_list[Object.keys(camp_list)[i]].detail?.household_detail?.find((x) => { if (x.household_id == kItem.household_id) { return x } })

                                    detailData(data, 'household')
                                    

                                    markerInfoWindow.close();
                                    const contentString = `<div><h3>Patient Name : ${data?.name}</h3>
                                                    <h4>Patient Age : ${data?.age}                    
                                    </div>`;
                                    markerInfoWindow.setContent(contentString);
                                    markerInfoWindow.open(map, marker);
                                })
                            } else {

                                marker.addListener("click", async () => {
                                    var data = camp_list[Object.keys(camp_list)[i]].detail?.camp_detail?.find((x) => { if (x.camp_id == kItem.camp_id) { return x } })

                                    detailData(data, 'camp')
                                   

                                    markerInfoWindow.close();
                                    const contentString = `<div><h3>Camp place : ${data?.camp_place}</h3>
                                                                        
                                        </div>`;
                                    markerInfoWindow.setContent(contentString);
                                    markerInfoWindow.open(map, marker);
                                })
                            }

                            newmarker.push(marker);


                        }
                    }

                }
            }
        }

        setMarkerInstance(newmarker)

        newmarker.forEach(marker => {
            bounds.extend(marker.getPosition());
        });

        map.fitBounds(bounds);

    }

    return (
        <>
            <div id='camps' > </div>
        </>
    )
}

export default CampMap