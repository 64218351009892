import * as React from "react";

// axios
import { useAxios } from "../../hooks/useAxios";

// styling
import "./Style.scss";

// toast
import { toast, Slide } from "react-toastify";

// backdrop component
import BackDrop from "../../components/BackDrop.js";

// mui
import {
  Typography,
  Button,
  useScrollTrigger,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Input,
} from "@mui/material";

// mui date pickers
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

// mui icons
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// filter component
import GisSummaryFilter from "../../components/Filter/GisSummaryFilter";

// utility
import { dates } from "../../index";

// moment library to handle dates
import moment from "moment";

// excel utility
import ExcelDownload from "../../components/ExcelFormat/ExcelFileDownload";
import { useEffect } from "react";

// utility functions
const renderInputs = (props, placeholder, classname) => (
  <Input
    sx={{
      width: "100%",
      input: {
        "&::placeholder": {
          // <----- Add this.
          opacity: 1,
          // marginBottom: '-5px',
          paddingLeft: "10px",
          fontSize: "13px",
          color: "#000000",
        },
        width: "100%",
      },
      backgroundColor: "#ffffff",
      // borderRadius: '5px',
      // maxHeight: '50px'
    }}
    type="text"
    className={classname}
    inputRef={props.inputRef}
    // inputProps={props.inputProps}
    inputProps={{
      ...props.inputProps,
      placeholder: placeholder,
    }}
    value={props.value}
    onClick={props.onClick}
    onChange={props.onChange}
    endAdornment={props.InputProps?.endAdornment}
  />
);

function GisSummary() {
  // axios interceptors
  const { axios } = useAxios();

  // loader state
  const [backDropOpen, setBackDropOpen] = React.useState(false);

  // dynamic grid headers state
  const [columns, setColumns] = React.useState([]);

  // filter initial state
  const [filterValue, setFilterValue] = React.useState({
    age_range: "",
    gender: "",
  });

  // excel download popup state management
  const [isDownPopup, setIsDownPopup] = React.useState(false);
  const [dateSearch, setDateSearch] = React.useState("");
  const [searchValue, setSearchValue] = React.useState({
    eventStart: "",
    eventEnd: "",
  });

  const [head, setHead] = React.useState([]);
  const [headerValue, setHeaderValue] = React.useState(head);
  const [value, setValue] = React.useState([]);

  // data state
  const [rows, setRows] = React.useState([]);

  // initial pagination state
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
  const [length, setLength] = React.useState(0);

  // fetching hospitals for dynamic columns
  React.useEffect(() => {
    fetchHospitals();
  }, []);

  // fetching datas
  React.useEffect(() => {
    fetchGisSummaryList();
  }, [filterValue, paginationModel, head]);

  // to update headerValue
  React.useEffect(() => {
    setHeaderValue(head);
  }, [head]);

  // fetching fetchUnitWisePerformanceList list
  async function fetchGisSummaryList() {
    try {
      const response = await axios.get(`getGISReport`, {
        params: {
          fromDate: searchValue.eventStart,
          toDate: searchValue.eventEnd,
          age_range: filterValue.age_range,
          gender: filterValue.gender,
          limit: paginationModel.pageSize,
          page: paginationModel.page + 1,
        },
      });
      console.log("fetchGisSummaryList response", response);
      const listLength = response?.data?.result?.length || 0;
      if (listLength > 0) {
        setLength(listLength);
        const result = response?.data?.result?.map(({ ...rest }, index) => ({
          id: generateUniqueId(),
          sno: (index += 1),
          ...rest,
        }));
        setRows(result);
      } else {
        setRows([]);
        setLength(0);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  // fetching units/hospital list to
  async function fetchHospitals() {
    const response = await axios.post("AllHospitalDropDown");
    console.log("response", response);
    const { result, success } = response?.data;
    if (success) {
      if (result?.length > 0) {
        const units = result?.map(({ _id, hospital_name }) => ({
          _id,
          hospital_name,
        }));
        console.log("units", units);

        // set dynamic columns for grid
        const dynamicColumns = [
          {
            field: "sno",
            headerName: "S.No",
            width: 100,
            headerClassName: "super-app-theme--header",
          },
          {
            field: "illness_type",
            headerName: "Illness Type",
            width: 250,
            headerClassName: "super-app-theme--header",
          },
          ...(units?.map(({ hospital_name }) => ({
            field: hospital_name,
            headerName: hospital_name,
            width: 160,
            headerClassName: "super-app-theme--header",
          })) ?? []),
          {
            field: "Total",
            headerName: "Total",
            width: 100,
            headerClassName: "super-app-theme--header",
          },
        ];

        console.log("dynamicColumns", dynamicColumns);
        setColumns(dynamicColumns);

        // set dynamic head for export popup
        const dynamicHeads = [
          {
            field: "all",
            headerName: "ALL",
            width: 100,
            headerClassName: "super-app-theme--header",
            isChecked: false,
          },
          {
            field: "illness_type",
            headerName: "Illness Type",
            width: 100,
            headerClassName: "super-app-theme--header",
            isChecked: false,
          },
          ...(units?.map(({ hospital_name }) => ({
            field: hospital_name,
            headerName: hospital_name,
            width: 160,
            headerClassName: "super-app-theme--header",
            isChecked: false,
          })) ?? []),
          {
            field: "Total",
            headerName: "Total",
            width: 100,
            headerClassName: "super-app-theme--header",
            isChecked: false,
          },
        ];

        console.log("dynammicHeads", dynamicHeads);
        setHead(dynamicHeads);
      }
    }
  }

  // excel down api req
  const handleExcelDownload = async () => {
    try {
      setBackDropOpen(true);
      const response = await axios.get(
        `getGISReport?fromDate=${searchValue.eventStart}&toDate=${
          searchValue.eventEnd
        }&age_range=${filterValue.age_range}&gender=${
          filterValue.gender
        }&limit=&page=${1}`
      );
      console.log("unitWisePerformance response", response);
      const listLength = response?.data?.result?.length || 0;
      if (listLength > 0) {
        const responseData = response?.data?.result;
        console.log("responseData", responseData);

        if (value.length > 1) {
          value.sort((x, y) => x.position - y.position);
        }
        let endResult = [];
        responseData?.map((row) => {
          let filteredObj = {};
          value?.map((val) => {
            if (Array.isArray(row[val.value])) {
              filteredObj[val.header] = row[val.value]
                ?.map((val) => val)
                .toString();
            } else {
              filteredObj[val.header] = row[val.value];
            }
          });
          endResult.push(filteredObj);
        });
        console.log("endResult", endResult);
        ExcelDownload(endResult, "GisSummaryReport");
        setIsDownPopup((k) => !k);
        setDateSearch("");
        setValue([]);
        setBackDropOpen(false);
      } else {
        setBackDropOpen(false);
        toast.warning("No Data Found", {
          autoClose: 1500,
          transition: Slide,
        });
      }
    } catch (e) {
      setBackDropOpen(false);
      console.log("e", e);
    }
  };

  // filter utility functions
  const filteredObjectArray = (val) => {
    setFilterValue(val);
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  const resetDatesFil = (val) => {
    setSearchValue(val);
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  // grid utility functions
  const CustomToolBar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarContainer />
        {/* <GridToolbarFilterButton /> */}
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  // handle excel download popup dates utility functions
  const handleDates = (val) => {
    const today = new Date(); // Get the current date
    setDateSearch(val);

    // Format the dates as "DD-MM-YYYY"
    var dateFormat = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    if (val == "last_week") {
      //==============last week ======================

      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7); // Subtract 7 days to get the date for last week

      // Find the start date (Monday) of last week
      const startOfWeek = new Date(lastWeek);
      startOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7));

      // Find the end date (Sunday) of last week
      const endOfWeek = new Date(lastWeek);
      endOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7) + 6);

      // Format the dates
      var formattedStartOfWeek = startOfWeek.toISOString().split("T")[0];
      var formattedEndOfWeek = endOfWeek.toISOString().split("T")[0];

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartOfWeek,
        eventEnd: formattedEndOfWeek,
      }));
    } else if (val == "last_month") {
      //================last month ===============================
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

      // Set the day to 1 to get the first day of the previous month
      lastMonth.setDate(2);

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: lastMonth.toISOString().split("T")[0],
        eventEnd: moment().date(0).toISOString().split("T")[0],
      }));
    } else if (val == "last_3_month") {
      //================last month ===============================

      const threeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        1
      );

      // Set the day to 1 to get the first day of three months ago
      threeMonthsAgo.setDate(1);

      const lastDayOfThreeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );

      const formattedStartDate = threeMonthsAgo.toLocaleDateString("en-IN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "Asia/Kolkata",
      });
      const formattedEndDate = lastDayOfThreeMonthsAgo.toLocaleDateString(
        "en-IN",
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone: "Asia/Kolkata",
        }
      );

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
        eventEnd: formattedEndDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
      }));
    } else if (val == "all") {
      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: "",
        eventEnd: "",
      }));
    }
  };

  // handle excel downlaod popup checkbox headers
  const handleChange = (e, val, position, headerName) => {
    const index = value?.findIndex((x) => x.value == val);
    console.log("index", index);
    if (e.target.checked) {
      if (index < 0) {
        if (headerName == "ALL") {
          let valueReference = [];
          head.forEach((data, index) => {
            data.isChecked = true;
            var values = {
              position: index,
              value: data?.field,
              header: data?.headerName,
            };
            valueReference.push(values);
            console.log("valueReference", valueReference);
            let sliceData = valueReference.slice(1);
            setValue(sliceData);
          });
          console.log("value", value);
        } else {
          head[position].isChecked = true;
          setValue(() => {
            var values = { position: position, value: val, header: headerName };
            var data = { ...value, values };
            console.log("value------", value);
            return Object.values(data);
          });
        }
      } else {
        // value.splice(index, 1);
        if (headerName == "ALL") {
          head.forEach((data, index) => {
            data.isChecked = false;
          });
          setValue([]);
        } else {
          head[position].isChecked = false;
          value.splice(index, 1);
          setHeaderValue(head);
        }
      }
    } else {
      // value.splice(index, 1);
      if (headerName == "ALL") {
        head.forEach((data, index) => {
          data.isChecked = false;
        });
        setValue([]);
      } else {
        let headReference = [...head];
        headReference[position].isChecked = false;
        value.splice(index, 1);
        setHeaderValue(headReference);
      }
    }
  };

  // utility functions
  const alterScreenName = (name) => {
    var menu_name = name.replaceAll("_", " ");
    var name = menu_name?.replace(/^.{1}/g, menu_name[0].toUpperCase());
    return name;
  };

  // unique id utility function
  const generateUniqueId = () =>
    `${Date.now()}-${Math.floor(Math.random() * 10000)}`;

  return (
    <div className="main-report">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0",
        }}
      >
        <Typography variant="h5" color="secondary" className="text-left">
          GIS Summary
        </Typography>
        <Button
          sx={{ textTransform: "capitalize", width: "auto", margin: "0 10px" }}
          disabled={rows?.length == 0}
          size="small"
          variant="contained"
          startIcon={<FileDownloadIcon />}
          onClick={() => {
            head.forEach((data) => {
              data.isChecked = false;
            });
            setSearchValue({
              eventStart: "",
              eventEnd: "",
            });
            setIsDownPopup((k) => !k);
          }}
        >
          Excel Download
        </Button>
      </div>

      {/* fetchGisSummary filter */}
      <div>
        <GisSummaryFilter
          filterObject={(val) => filteredObjectArray(val)}
          resetDatesFilter={(val) => resetDatesFil(val)}
        />
      </div>

      {/* excel downlaod container */}
      {isDownPopup && (
        <div className="tabbackground">
          <div className="downloadtab">
            <div className="table">
              <Table>
                <TableHead>
                  <tr>
                    <th> </th>
                    <th>Columns </th>
                  </tr>
                </TableHead>
              </Table>
              <div className="filter">
                <FormControl style={{ width: "90%" }} size="small">
                  <Select
                    labelId="select-label"
                    id="select"
                    variant="standard"
                    label="Select Dates"
                    value={dateSearch}
                    onChange={(e) => {
                      handleDates(e.target.value);
                    }}
                  >
                    {dates?.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {dateSearch == "custom" && (
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "45%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="from_date"
                          value={
                            searchValue.eventStart == ""
                              ? null
                              : searchValue.eventStart
                          }
                          inputFormat="DD/MM/YYYY"
                          onChange={(e) => {
                            const selectedDate = e.$d;
                            if (selectedDate) {
                              setSearchValue({
                                ...searchValue,
                                eventStart: selectedDate,
                              });
                            } else {
                              setSearchValue({
                                ...searchValue,
                                eventStart: null,
                              });
                            }
                          }}
                          slotProps={{
                            actionBar: {
                              actions: ["clear"],
                            },
                          }}
                          renderInput={(props) =>
                            renderInputs(props, "From date", "date")
                          }
                        />
                      </LocalizationProvider>
                    </div>
                    <div style={{ width: "45%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="from_date"
                          value={
                            searchValue.eventEnd == ""
                              ? null
                              : searchValue.eventEnd
                          }
                          inputFormat="DD/MM/YYYY"
                          onChange={(e) => {
                            setSearchValue({ ...searchValue, eventEnd: e.$d });
                          }}
                          renderInput={(props) =>
                            renderInputs(props, "To date", "date")
                          }
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                )}
              </div>
              <Table>
                <TableBody>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  {headerValue?.map((head, index) => {
                    if (head.headerName !== "S no") {
                      return (
                        <TableRow key={index}>
                          <TableCell sx={{ textAlign: "center", width: "25%" }}>
                            <Checkbox
                              checked={head.isChecked}
                              onChange={(e) => {
                                handleChange(
                                  e,
                                  head.field,
                                  index,
                                  head.headerName
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {alterScreenName(head?.headerName)}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </div>
            <div className="downloadoptions">
              <Button
                variant="contained"
                onClick={() => {
                  setIsDownPopup((k) => !k);
                  setDateSearch("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={dateSearch == "" || value.length == 0}
                onClick={() => handleExcelDownload()}
              >
                Download
              </Button>{" "}
            </div>
          </div>
        </div>
      )}

      {/* fetchGisSummaryList datas */}
      <div className="table">
        <DataGrid
          style={{ height: "75vh", width: "95vw" }}
          className="table-pagination"
          rowCount={length}
          page={paginationModel.page}
          sx={{
            boxShadow: 2,
            overflowX: "auto",
            border: 2,
            textTransform: "capitalize",
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .super-app-theme--header": {
              backgroundColor: "LightBlue",
            },
          }}
          // autoHeight
          rows={rows}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          slots={{ toolbar: CustomToolBar }}
          paginationMode="server"
          disableDensitySelector
          initialState={{
            columns: {
              columnVisibilityModel: {
                Total: false,
              },
            },
          }}
          hideFooterSelectedRowCount={true}
        />
      </div>

      {/* backdrop */}
      <BackDrop open={backDropOpen} />
    </div>
  );
}

export default GisSummary;
