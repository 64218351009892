import React, { useState, useEffect, useCallback, useMemo } from "react";
import format from "date-fns/format";

import { useAxios } from "../hooks/useAxios";
import "./gis.scss";

import { Formik } from "formik";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Male,
  FemaleTwoTone,
  TwoWheeler,
  Visibility,
  TransgenderTwoTone,
  Colorize,
  Bolt,
  Diversity1Rounded,
  LocalHospitalSharp,
  ThumbUp,
} from "@mui/icons-material";
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import { BiRename } from "react-icons/bi";
import { AiOutlineCaretRight, AiOutlineCaretLeft } from "react-icons/ai";

import {
  LocalHospital,
  Festival,
  HowToReg,
  DirectionsBike,
  RestartAlt,
  ArrowLeft,
  ArrowRight,
  AssignmentInd,
} from "@mui/icons-material";

// import { FieldWorkerMap, CampMap, HospitalMap, CommonMap } from "./Maps";
import { CommonMap as Map } from "./Maps";

import {
  Typography,
  TextField,
  MenuItem,
  InputLabel,
  Button,
  Input,
  Autocomplete,
} from "@mui/material";

import { createFilterOptions } from "@mui/material/Autocomplete";
import cataractdia from "../assets/cataractdia.png";
import cataracthyper from "../assets/cataracthyper.png";
import cataracthyperdia from "../assets/cataracthyperdia.png";
import diahper from "../assets/diahyper.png";
import logo from "../assets/icon.png";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { icons } from "react-icons/lib";
import { useNavigate } from "react-router-dom";

const state = {
  // fromDate: "",
  // toDate: "",
  hospital_id: [],
  field_worker_id: "",
  camp_id: [],
};
const dateFormat = process.env.REACT_APP_DATE_FORMAT;

const renderInputs = (props, placeholder, classname) => (
  <Input
    sx={{
      input: {
        "&::placeholder": {
          // <----- Add this.
          opacity: 1,
          // marginBottom: '-5px',
          paddingLeft: "10px",
          fontSize: "13px",
          color: "#000000",
        },
        width: "80%",
      },
      backgroundColor: "#ffffff",
      // borderRadius: '5px',
      maxHeight: "50px",
    }}
    type="text"
    className={classname}
    inputRef={props.inputRef}
    // inputProps={props.inputProps}
    inputProps={{
      ...props.inputProps,
      placeholder: placeholder,
    }}
    value={props.value}
    onClick={props.onClick}
    onChange={props.onChange}
    endAdornment={props.InputProps?.endAdornment}
  />
);

const filter = createFilterOptions();

const Gis_report = ({}) => {
  const { axios } = useAxios();

  const navigate = useNavigate();

  const [expand, setExpand] = useState(1);
  const [initialValues, setInitialValue] = useState(state);
  const [trackingDate, setTrackingDate] = useState();
  const [hospitals, setHospitals] = useState(null); //hospital dropdown
  const [villages, setVillages] = useState([]);
  const [filter, setFilter] = useState(true);
  const [filtered, setFiltered] = useState(0);
  const [village_id, setVillageId] = useState("");

  const filterdate = {
    fromDate: "",
    toDate: "",
  };

  const [initialDate, setInitailDate] = useState(filterdate);

  const dates = {
    minDate: null,
    maxDate: null,
  };

  const [date, setDate] = useState(dates);

  //camp list on camp map
  const camps = {
    vhv: [],
    cbbf: [],
  };

  const [camp, setCamp] = useState(camps);

  //reset

  const [fieldreset, setFieldreset] = useState({
    vhv: 1,
    cbbf: 2,
  });
  const [campreset, setCampreset] = useState({
    vhv: 5,
    cbbf: 6,
  });
  const [reset, setReset] = useState(false);
  const [villagereset, setVillageReset] = useState(8);

  //fieldworkers list on field worker map

  const fW = {
    vhv: null,
    cbbf: null,
  };

  const [fieldWorkers, setFieldWorkers] = useState(fW);

  const ill = {
    cataract: null,
    surgerydonedetails: null,
    diabetes: null,
    none: null,
    hypertension: null,
    asthma: null,
    cataractDia: null,
    cataractHyper: null,
    HyperDia: null,
    cataractHyperDia: null,
  };

  const [illness, setIllness] = useState({ ...ill }); //common illness
  const [hospitalData, setHospitalData] = useState(null); // hospital name and other detail
  const [houseHoldData, setHouseHoldData] = useState(null); // household detail
  const [campData, setCampData] = useState(null); // campdata to google map
  const [campDetail, setCampDetail] = useState(null); // camp detail to show panel
  const [campDataDetail, setcampDataDetail] = useState([]); //hospital related camp detail
  const [campRHouse, setcampRhouse] = useState(true); // camp and house detail

  const [allTrackData, setAllTrackData] = useState(null); // all days fieldworker data to google map
  const [trackDetail, setTrackDetail] = useState(0); // field worker detail for right panel
  const [daytrack, setDayTrack] = useState(null); // particular date tracking date
  const [fieldWorkerName, setfieldWorkerName] = useState(null); // fieldworker name detail
  const [hosCamp, sethosCamp] = useState([]); //hospital camp data

  const shifts = {
    // shiftin-out detail
    shiftIn: null,
    shiftout: null,
  };

  const [shiftdetail, setshiftdetail] = useState(shifts);

  const patientsD = {
    // hospital/camp patient details
    vhv: null,
    cbbf: null,
    male: 0,
    female: 0,
    others: 0,
    household: 0,
    individual: 0,
    camp: 0,
  };

  const [patientDetails, setPatientDetails] = useState(patientsD);

  const MAP_RESTRICTION = {
    // world map bound restriction
    latLngBounds: {
      north: 37.6,
      south: 6.4,
      west: 68.7,
      east: 97.25,
    },
  };

  const [selectedIll, setSelectedIll] = useState(null);

  useEffect(() => {
    var hospital = localStorage.getItem("hospital_id");
    var role_id = localStorage.getItem("role_id");
    if (role_id == "7") {
      var data;
      data = {
        hospital_id: [hospital],
      };
      loadHospital(data);
      // handleFieldWorkerDropdown(data)
      // handleCampDropdown(data);
      // loadVillages(data)
      initialValues.hospital_id.push(hospital);
    } else {
      var dates = {
        fromDate: new Date(),
        toDate: new Date(),
      };
      setTimeout(() => {
        loadTodayCamp(dates);
      }, 2000);

      // campSurveyList(dates)
      loadHospital(initialValues);
      // handleFieldWorkerDropdown(initialValues)
      // handleCampDropdown(initialValues)
      // loadVillages(initialValues)
    }
  }, [reset]);

  // campSurveyList
  useEffect(() => {
    if (
      initialValues.hospital_id?.length != 0 &&
      Array.isArray(initialValues.hospital_id) &&
      initialValues.hospital_id[0] != "1"
    ) {
      if (initialValues.hospital_id != "1") {
        var data = {
          hospital_id: initialValues.hospital_id,
        };
        handleIllness(data);
      }
    }
    if (
      initialValues.camp_id?.length != 0 &&
      initialValues.hospital_id?.length != 0
    ) {
      if (initialValues.hospital_id != "1") {
        var data = {
          hospital_id: initialValues.hospital_id,
          camp_id: initialValues.camp_id,
        };
        handleIllness(data);
      }
    }
    if (
      initialValues.camp_id?.length != 0 &&
      Array.isArray(initialValues.camp_id)
    ) {
      if (
        initialValues?.camp_id[0] !== "1" &&
        initialValues?.camp_id[0] !== "2"
      ) {
        var data = {
          camp_id: initialValues.camp_id,
        };
      }
      handleIllness(data);
    }

    if (initialValues.field_worker_id != "") {
      var data = {
        field_worker_id: initialValues.field_worker_id,
      };
      handleIllness(data);
    }
  }, [initialValues]);

  useEffect(() => {
    if (village_id != "") {
      var data = {
        village_id: village_id,
      };
      handleIllness(data, "local");
    }
  }, [village_id]);

  useEffect(() => {
    // if (initialDate.fromDate != "" && initialDate.toDate != "" && initialValues.camp_id == "" && initialValues.hospital_id == "" && village_id == "") {
    if (initialDate.fromDate != "" && initialDate.toDate != "") {
      var dates = {
        fromDate: initialDate.fromDate,
        toDate: initialDate.toDate,
      };
      // loadHospital(dates)
      handleCampDropdown(dates);
    }
  }, [initialDate]);

  async function loadHospital(list) {
    await axios.post("getGISHospitalList", list).then((res) => {
      var hospitals = res.data.result.result;

      var select = { hospital_name: "Select All", hospital_id: "1" };
      hospitals.unshift(select);

      setHospitals(hospitals);
    });
  }

  async function loadTodayCamp(data) {
    await axios.post("getCampSurveyList", data).then((res) => {
      var data_ = res.data.result;
      sethosCamp(data_);
    });
  }

  async function loadVillages(data) {
    await axios.post("getVillageList", data).then((res) => {
      var data = res.data.result[0].village;
      setVillages(data);
    });
  }

  async function handleIllness(data, ill) {
    await axios.post("getIllnessList", data).then((res) => {
      var value = res.data.result.result;
      setIllness({
        ...illness,
        diabetes: value.FinalD[0] != undefined ? value.FinalD[0] : 0,
        none: value.None != undefined ? value.None : 0,
        hypertension: value.FinalH[0] != undefined ? value.FinalH[0] : 0,
        asthma: value.Asthma != undefined ? value.Asthma : 0,
        surgerydonedetails:
          value.surgeryDoneDetails != undefined ? value.surgeryDoneDetails : 0,
        cataract: value.FinalC[0] != undefined ? value.FinalC[0] : 0,
        cataractDia: value.FinalCD[0] != undefined ? value.FinalCD[0] : 0,
        cataractHyperDia:
          value.FinalCDH[0] != undefined ? value.FinalCDH[0] : 0,
        cataractHyper: value.FinalCH[0] != undefined ? value.FinalCH[0] : 0,
        HyperDia: value.FinalDH[0] != undefined ? value.FinalDH[0] : 0,
      });

      return value;
    });
  }

  async function campSurveyList(data) {
    await axios.post("getCampSurveyList", data).then((res) => {
      var data_ = res.data.result;
      setCampData(data_);
    });
  }

  async function getFieldTrack(data) {
    await axios.post("getWorkerList", data).then((res) => {
      var trackingdata = res.data.result.result;
      setAllTrackData(trackingdata);

      var dates = [];
      trackingdata?.map((i) => dates.push(i._id));

      // Convert the date strings to Date objects
      const dateObjects = dates.map(
        (date) => new Date(date.split("-").reverse().join("-"))
      );

      // Find the minimum and maximum dates
      const minDate = new Date(Math.min.apply(null, dateObjects));
      const maxDate = new Date(Math.max.apply(null, dateObjects));

      // Format the dates as strings
      const minDateString = `${minDate.getDate()}-${
        minDate.getMonth() + 1
      }-${minDate.getFullYear()}`;
      const maxDateString = `${maxDate.getDate()}-${
        maxDate.getMonth() + 1
      }-${maxDate.getFullYear()}`;

      setDate({ ...date, minDate: minDate, maxDate: maxDate });
    });
  }

  useEffect(() => {}, [initialValues]);

  async function handleReset() {
    setInitialValue({ ...state });
    setInitailDate({ ...filterdate });
    setCamp({ ...camps });
    setDate({ ...dates });
    // setVillages([])
    // setHospitals(null)
    setTrackingDate(null);
    setFieldWorkers({ ...fW });
    setIllness({ ...ill });
    setHospitalData(null);
    setHouseHoldData(null);
    setCampData(null);
    setCampDetail(null);
    setcampDataDetail([]);
    setcampRhouse(true);
    setAllTrackData(null);
    setTrackDetail(0);
    setDayTrack(null);
    setfieldWorkerName(null);
    setshiftdetail({ ...shifts });
    setPatientDetails({ ...patientsD });
    setSelectedIll(null);
    // setFieldWorkers(fieldWorkers +1 )
    setReset(!reset);
    setCampreset({
      ...campreset,
      vhv: campreset.vhv + 0.1,
      cbbf: campreset?.cbbf + 0.1,
    });
    setFieldreset({
      ...fieldreset,
      vhv: fieldreset.vhv + 0.1,
      cbbf: fieldreset?.cbbf + 0.1,
    });
    setFiltered(0);
    setVillageReset(villagereset * 0.2);
  }

  const disableDates = (date) => {
    return moment(date).format("DD") > 10 && moment(date).format("DD") < 18;
  };

  async function handleCampDropdown(list) {
    await axios.post("getGISCampList", list).then((res) => {
      var camps = res.data.result.result;

      var vhvvalue = camps?.find((i) => i.camp_type === "VHV");
      var cbbfvalue = camps?.find((i) => i.camp_type === "CBBF");

      vhvvalue = vhvvalue == undefined ? {} : vhvvalue;
      cbbfvalue = cbbfvalue == undefined ? {} : cbbfvalue;

      var select = { camp_place: "Select All", camp_id: "1" };
      vhvvalue?.campList?.unshift(select);
      cbbfvalue?.campList?.unshift(select);

      setCamp({ ...camp, vhv: vhvvalue, cbbf: cbbfvalue });
    });
  }

  async function handleFieldWorkerDropdown(data) {
    await axios.post("getGISFieldWorkerList", data).then((res) => {
      var field_w = res.data.result.result;

      var vhvvalue = field_w?.find((i) => i.field_worker_type === "VHV");
      var cbbfvalue = field_w?.find((i) => i.field_worker_type === "CBBF");

      vhvvalue = vhvvalue == undefined ? {} : vhvvalue;
      cbbfvalue = cbbfvalue == undefined ? {} : cbbfvalue;

      setFieldWorkers({ ...fieldWorkers, vhv: vhvvalue, cbbf: cbbfvalue });
    });
  }

  async function patientsCount(h_data, val) {
    await axios.post("getMembersCountList", h_data).then((res) => {
      var data = res.data.result;
      if (Object.keys(data).length == 0) {
        vhvmale = 0;
        cbbfmale = 0;
        vhvfemale = 0;
        cbbffemale = 0;
        vhvother = 0;
        cbbfother = 0;
        vhvhousehold = 0;
        cbbfhousehold = 0;
        vhvcamp = 0;
        cbbfcamp = 0;
      } else {
        var vhvdata = Object.keys(data["VHV"])?.length == 0 ? 0 : data["VHV"];
        var cbbfdata =
          Object.keys(data["CBBF"])?.length == 0 ? 0 : data["CBBF"];

        var vhvmale =
          vhvdata?.total_males != undefined ? vhvdata?.total_males : 0;
        var cbbfmale =
          cbbfdata?.total_males != undefined ? cbbfdata?.total_males : 0;

        var vhvfemale =
          vhvdata?.total_females != undefined ? vhvdata?.total_females : 0;
        var cbbffemale =
          cbbfdata?.total_females != undefined ? cbbfdata?.total_females : 0;

        var vhvother =
          vhvdata?.total_others != undefined ? vhvdata?.total_others : 0;
        var cbbfother =
          cbbfdata?.total_others != undefined ? cbbfdata?.total_others : 0;

        var vhvhousehold =
          vhvdata?.totalHouseHold != undefined ? vhvdata?.totalHouseHold : 0;
        var cbbfhousehold =
          cbbfdata?.totalHouseHold != undefined ? cbbfdata?.totalHouseHold : 0;

        var vhvIndividualHouseHold =
          vhvdata?.totalIndividualHouseHold != undefined
            ? vhvdata?.totalIndividualHouseHold
            : 0;
        var cbbfIndividualHouseHold =
          cbbfdata?.totalIndividualHouseHold != undefined
            ? cbbfdata?.totalIndividualHouseHold
            : 0;

        var vhvcamp = vhvdata?.totalCamp != undefined ? vhvdata?.totalCamp : 0;
        var cbbfcamp =
          cbbfdata?.totalCamp != undefined ? cbbfdata?.totalCamp : 0;

        vhvmale = vhvmale != undefined ? vhvmale : 0;
        cbbfmale = cbbfmale != undefined ? cbbfmale : 0;
        vhvfemale = vhvfemale != undefined ? vhvfemale : 0;
        cbbffemale = cbbffemale != undefined ? cbbffemale : 0;
        vhvother = vhvother != undefined ? vhvother : 0;
        cbbfother = cbbfother != undefined ? cbbfother : 0;
        vhvhousehold = vhvhousehold != undefined ? vhvhousehold : 0;
        cbbfhousehold = cbbfhousehold != undefined ? cbbfhousehold : 0;
        vhvcamp = vhvcamp != undefined ? vhvcamp : 0;
        cbbfcamp = cbbfcamp != undefined ? cbbfcamp : 0;
      }

      var total_male;
      var total_fmale;
      var t_other;

      if (val == "hospital") {
        total_male = vhvmale + cbbfmale;
        total_fmale = vhvfemale + cbbffemale;
        t_other = vhvother + cbbfother;

        setPatientDetails({
          ...patientDetails,
          male: total_male,
          female: total_fmale,
          others: t_other,
          household: vhvhousehold + cbbfhousehold,
          camp: vhvcamp + cbbfcamp,
          individual: vhvIndividualHouseHold + cbbfIndividualHouseHold,
        });
      } else {
        setPatientDetails({
          ...patientDetails,
          household: vhvhousehold + cbbfhousehold,
          camp: vhvcamp + cbbfcamp,
          individual: vhvIndividualHouseHold + cbbfIndividualHouseHold,
        });
      }
    });
  }

  async function handleChangeHospital(e, data) {
    if (e.target.value != undefined) {
      // to handle no data found

      setTimeout(() => {
        setFiltered(1);
      }, 1000);

      setVillages([]);
      var value = data?.hospital_id;
      setHouseHoldData(null);
      setCampreset({
        ...campreset,
        vhv: campreset.vhv + 0.1,
        cbbf: campreset?.cbbf + 0.1,
      });
      setFieldreset({
        ...fieldreset,
        vhv: fieldreset.vhv + 0.1,
        cbbf: fieldreset?.cbbf + 0.1,
      });
      setCampDetail(null);

      if (value) {
        setInitialValue({ ...state });

        if (value == "1") {
          setHospitalData(hospitals);
          let allHospital = [];

          hospitals?.map((i) => {
            if (i?.hospital_id != "1") {
              allHospital.push(i.hospital_id);
            }
          });

          var hos = {
            hospital_id: allHospital,
          };

          handleFieldWorkerDropdown(hos);
          handleIllness(hos);
          patientsCount(hos, "hospital");
          handleCampDropdown(hos);

          setInitialValue((i) => ({
            ...i,
            hospital_id: [value],
          }));
        } else {
          setcampDataDetail(data.hospital_name);

          setInitialValue((i) => ({
            ...i,
            hospital_id: [value],
          }));

          var p_load;

          p_load = {
            hospital_id: [value],
          };

          handleFieldWorkerDropdown(p_load);
          handleCampDropdown(p_load);
          loadVillages(p_load);

          var val = hospitals.filter((i) => {
            if (i.hospital_id == value) {
              return i;
            }
          });
          setHospitalData(val);
          campSurveyList(p_load);

          handleIllness(p_load);
          patientsCount(p_load, "hospital");
        }
      }
    }
  }

  function handleDate() {
    if (initialValues.hospital_id == "" && initialValues.camp_id == "") {
      // if (initialValues.fromDate == "" && initialValues.toDate == "") {
      if (initialDate.fromDate == "" && initialDate.toDate == "") {
        const dateConverter = (dateIn) => {
          dateIn.setHours(12);
          let dateFor10days = new Date(dateIn.setDate(dateIn.getDate() - 10));
          return dateFor10days;
        };

        let today = new Date();
        let last10Day = dateConverter(today);

        setInitialValue({
          ...initialValues,
          fromDate: last10Day,
          toDate: today,
        });
      }
    }
  }

  async function handleCampAndFieldWorker(e, camptype, val) {
    if (e.target.value != undefined) {
      // to handle no data found

      setTimeout(() => {
        setFiltered(2);
      }, 1000);

      setTrackingDate(null);
      setHospitalData([]);

      setInitialValue({
        ...initialValues,
        camp_id: [],
      });

      var value = val?.camp_id;

      if (value) {
        if (value == "1" || value == "2") {
          let allCamp = [];
          camp[camptype]?.campList?.map((i) => {
            if (i?.camp_id != "1") {
              allCamp.push(i.camp_id);
            }
          });

          var val = [];
          val.push(value);

          setInitialValue({
            ...initialValues,
            camp_id: val,
            field_worker_id: "",
          });

          var a_camps = {
            camp_id: allCamp,
          };
          campSurveyList(a_camps);
          handleIllness(a_camps);
          handleFieldWorkerDropdown(a_camps);
        } else {
          var camp_d = camp[camptype]?.campList.find((i) => {
            if (i.camp_id == value) {
              return i;
            }
          });

          var hospital_data = hospitals?.find((i) => {
            if (i.hospital_id == camp_d.hospital_id) {
              return i;
            }
          });

          var d = {
            camp_detail: camp_d,
            hospital_detail: hospital_data,
          };

          setCampDetail(camp_d);

          var val = [];
          val.push(value);

          setInitialValue({
            ...initialValues,
            camp_id: val,
            field_worker_id: "",
          });

          var camplist = {};
          if (initialValues.hospital_id?.length == 0) {
            camplist = {
              camp_id: [value],
            };
          } else if (initialValues.hospital_id != "1") {
            camplist = {
              hospital_id: initialValues.hospital_id,
              camp_id: [value],
            };
          } else {
            camplist = {
              camp_id: [value],
            };
          }
          handleIllness(camplist);
          patientsCount(camplist);

          handleFieldWorkerDropdown(camplist);
          campSurveyList(camplist);
          getFieldTrack(camplist);
        }
      }
    }
  }

  async function handleFieldWorkersTrack(e, field_type, value) {
    if (e.target.value != undefined) {
      // to handle no data found

      setTimeout(() => {
        setFiltered(3);
      }, 1000);

      var val = value.field_worker_id;

      setTrackingDate(null);

      setInitialValue({ ...initialValues, field_worker_id: val });

      var fieldworkerid = "";

      if (initialValues.camp_id.length == 0) {
        fieldworkerid = {
          field_worker_id: val,
        };
      } else {
        fieldworkerid = {
          field_worker_id: val,
          camp_id: initialValues.camp_id != "1" ? initialValues.camp_id : "",
        };
      }

      var name = fieldWorkers[field_type]?.filed_workerList?.find((i) => {
        if (i.field_worker_id == val) {
          return i;
        }
      });
      setfieldWorkerName(name.field_worker_name);

      getFieldTrack(fieldworkerid);

      patientsCount(fieldworkerid);
    }
  }

  function villageChange(e, val) {
    if (e.target.value != undefined) {
      // to handle no data found

      setTimeout(() => {
        setFiltered(4);
      }, 1000);

      var value = val?.village_id;
      if (value == null) {
        value = "";
      }
      setVillageId(value);
    }
  }

  const handleDetail = useCallback((val, type) => {
    if (type == "household") {
      setcampRhouse(false);

      setHouseHoldData(val);
    } else {
      setcampRhouse(true);
      setcampDataDetail(val);
    }
  }, []);

  function firstLetter(s) {
    if (s != undefined && s != null && s?.length > 0) {
      return s?.replace(/^.{1}/g, s[0]?.toUpperCase());
    }
  }

  function handleTrackingDate(e) {
    setTrackingDate(e.$d);
    var date = format(new Date(e.$d), dateFormat);
    var dateValue = new Date(new Date(e.$d).setHours(6, 0, 0));

    var list = {
      field_worker_id: initialValues.field_worker_id,
      fromDate: dateValue,
      toDate: dateValue,
    };

    patientsCount(list);

    date = date?.replaceAll("/", "-");

    var detail = allTrackData?.find((i) => i._id == date);

    let dd = {
      ...detail,
      coords: detail?.coords?.filter((x) => x.current_latitude != "0.0"),
    };

    setDayTrack(dd);
    setTrackDetail(dd);

    var shiftin = dd?.coords?.find((i) => {
      if (i.event.replace(/ /g, "").toLowerCase() == "punchin") {
        return i;
      }
    });
    var shiftout = dd?.coords?.find((i) => {
      if (i.event.replace(/ /g, "").toLowerCase() == "punchout") {
        return i;
      }
    });

    var inn;
    var out;

    inn = shiftin != undefined ? convertTime(shiftin?.loc_entry_time) : "";
    out = shiftout != undefined ? convertTime(shiftout?.loc_entry_time) : "";

    //================= convert into indian standart time ==================

    // dayjs.extend(utc);

    // var inn = shiftin != undefined ? dayjs.utc(shiftin?.loc_entry_time).local().format('h:mm A') : null;
    // var out = shiftout != undefined ? dayjs.utc(shiftout?.loc_entry_time).local().format('h:mm A') : null;

    // ======================================================================

    setshiftdetail({ ...shiftdetail, shiftIn: inn, shiftout: out });
  }

  function convertTime(time) {
    var currentDate = new Date(time);
    var hours = currentDate.getUTCHours();
    var minutes = currentDate.getUTCMinutes();

    var amOrPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Convert 0 to 12

    var time =
      hours.toString().padStart(2, "0") +
      ":" +
      minutes.toString().padStart(2, "0") +
      " " +
      amOrPm;

    return time;
  }

  const handleselectedHospital = useCallback(
    (value) => {
      // Call other functions and set state
      campSurveyList(value);
      handleCampDropdown(value);
      handleFieldWorkerDropdown(value);
      handleIllness(value);
      patientsCount(value, "hospital");
      setInitialValue((prevValues) => ({
        ...prevValues,
        hospital_id: value.hospital_id,
      }));
    },
    [
      campSurveyList,
      handleCampDropdown,
      handleFieldWorkerDropdown,
      handleIllness,
      patientsCount,
    ]
  );

  function handleHeatMap(ill) {
    setSelectedIll(ill);
  }

  const MemoizedMap = useMemo(
    () => (
      <Map
        reset={reset}
        hospitalData={hospitalData}
        detailData={handleDetail}
        heatMap={illness}
        tracking={daytrack}
        selectedHospital={handleselectedHospital}
        campData={campData}
      />
    ),
    [
      reset,
      hospitalData,
      handleDetail,
      illness,
      daytrack,
      handleselectedHospital,
      campData,
    ]
  );

  const handleInputChange = async (event) => {
    const inputValue = event.target.value;
    if (inputValue.length == 0) {
      // if empty or no letters are types
      setCamp({ ...camp, vhv: [], cbbf: [] });
    }

    if (inputValue.length > 0) {
      if (inputValue.length % 3 == 0) {
        var data = {
          hospital_id: [],
          field_worker_id: "",
          search: inputValue,
          fromDate: "",
          toDate: "",
        };

        handleCampDropdown(data);
      }
    }
  };

  const handleVillageChange = async (event) => {
    const inputValue = event.target.value;

    if (inputValue) {
      if (inputValue.length > 0) {
        if (inputValue.length % 3 == 0) {
          var data = {
            search: inputValue,
          };

          // "hospital_id": [initialValues?.hospital_id],
          if (initialValues?.hospital_id != "") {
            data["hospital_id"] = [initialValues?.hospital_id];
          }

          loadVillages(data);
        }
      } else {
        setVillages([]);
      }
    }
  };

  return (
    <>
      <Formik initialValues={initialValues}>
        {({ isSubmitting, setFieldValue, values }) => (
          <div className="gis">
            <div
              className={filter ? "arrow" : "arrowin"}
              onClick={() => setFilter(!filter)}
            >
              <ArrowLeft />
            </div>
            <div
              className={filter ? "gis_filter" : "gis_filter_in"}
              onMouseLeave={() => {
                setTimeout(() => {
                  setFilter(!filter);
                }, 2000);
              }}
            >
              <div className="filter_head">
                <div className="head" onClick={() => navigate("/masters")}>
                  <img
                    src={logo}
                    width="50"
                    height={50}
                    style={{ borderRadius: "50%" }}
                  />
                </div>

                {/* <div className={filtered == 1 ? 'head bg' : 'head'} onClick={() => setFiltered(1)}>
                  <LocalHospital />
                </div>
                <div className={filtered == 2 ? 'head bg' : 'head'} onClick={() => setFiltered(2)}>
                  <Festival />
                </div>
                <div className={filtered == 3 ? 'head bg' : 'head'} onClick={() => setFiltered(3)}>
                  <AssignmentInd />
                </div>*/}
              </div>
              <div className="filter_body">
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="from_date"
                      value={
                        initialDate.fromDate == "" ? null : initialDate.fromDate
                      }
                      inputFormat="DD/MM/YYYY"
                      onChange={(e) => {
                        const selectedDate = e.$d;
                        if (selectedDate) {
                          setInitailDate({
                            ...initialDate,
                            fromDate: selectedDate,
                          });
                        } else {
                          setInitailDate({ ...initialDate, fromDate: null });
                        }
                      }}
                      slotProps={{
                        actionBar: {
                          actions: ["clear"],
                        },
                      }}
                      renderInput={(props) =>
                        renderInputs(props, "From date", "date")
                      }
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="from_date"
                      value={
                        initialDate.toDate == "" ? null : initialDate.toDate
                      }
                      inputFormat="DD/MM/YYYY"
                      onChange={(e) => {
                        setInitailDate({ ...initialDate, toDate: e.$d });
                      }}
                      renderInput={(props) =>
                        renderInputs(props, "To date", "date")
                      }
                    />
                  </LocalizationProvider>
                </div>
                <div className="icons">
                  <LocalHospital />
                </div>
                <div>
                  <Autocomplete
                    size="small"
                    disablePortal
                    className="hide"
                    variant="standard"
                    id="combo-box-demo"
                    sx={{
                      input: {
                        "&::placeholder": {
                          // <----- Add this.
                          opacity: 1,
                          // marginBottom: '-5px',
                          color: "#000",
                        },
                      },
                      backgroundColor: "#ffffff",
                      // borderRadius: '5px',
                      width: "90%",
                    }}
                    ListboxProps={{
                      sx: { fontSize: 13 },
                    }}
                    options={
                      hospitals?.length > 0
                        ? hospitals
                        : [{ hospital_name: "No data found" }]
                    }
                    defaultValue={
                      initialValues.hospital_id?.length == 0
                        ? null
                        : initialValues.hospital_id[0]
                    }
                    key={reset}
                    getOptionLabel={(option) =>
                      option.hospital_name?.charAt(0)?.toUpperCase() +
                      option.hospital_name?.slice(1)
                    }
                    onChange={(data, value) => {
                      handleChangeHospital(data, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        color="black"
                        InputLabelProps={{
                          // shrink: false,
                          style: {
                            fontSize: "13px", // Adjust the font size of the label here
                            paddingTop: "5px",
                          },
                        }}
                        label="Select Hospitals"
                      />
                    )}
                  />
                </div>
                <div className="icons">
                  <Festival />
                </div>

                <div>
                  <Autocomplete
                    size="small"
                    disablePortal
                    className="date"
                    variant="standard"
                    id="combo-box-demo"
                    key={campreset?.vhv}
                    sx={{
                      input: {
                        "&::placeholder": {
                          // <----- Add this.
                          opacity: 1,
                          marginBottom: "-5px",
                        },
                      },
                      backgroundColor: "#ffffff",
                      // borderRadius: '5px',
                      width: "90%",
                      margin: " 0 5px",
                    }}
                    ListboxProps={{
                      sx: { fontSize: 13 },
                    }}
                    options={
                      camp?.vhv?.campList?.length > 0
                        ? camp?.vhv?.campList
                        : [{ camp_place: "No data found" }]
                    }
                    onInputChange={(e) => handleInputChange(e)}
                    defaultValue={
                      initialValues.camp_id?.length == 0
                        ? null
                        : initialValues.camp_id[0]
                    }
                    getOptionLabel={(option) =>
                      option.camp_place?.charAt(0)?.toUpperCase() +
                      option.camp_place?.slice(1)
                    }
                    onChange={(data, value) => {
                      handleCampAndFieldWorker(data, "vhv", value);
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.camp_id}>
                          {option.camp_place}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        color="black"
                        InputLabelProps={{
                          // shrink: false,
                          style: {
                            fontSize: "10px", // Adjust the font size of the label here
                            paddingTop: "5px",
                          },
                        }}
                        label="Select VHV Camp"
                      />
                    )}
                  />
                  <Autocomplete
                    size="small"
                    disablePortal
                    className="date"
                    variant="standard"
                    id="combo-box-demo"
                    sx={{
                      input: {
                        "&::placeholder": {
                          // <----- Add this.
                          opacity: 1,
                          marginBottom: "-5px",
                        },
                      },
                      backgroundColor: "#ffffff",
                      margin: " 0 5px",
                      // borderRadius: '5px',
                      width: "90%",
                    }}
                    key={campreset?.cbbf}
                    ListboxProps={{
                      sx: { fontSize: 13 },
                    }}
                    options={
                      camp?.cbbf?.campList?.length > 0
                        ? camp?.cbbf?.campList
                        : [{ camp_place: "No data found" }]
                    }
                    defaultValue={
                      initialValues.camp_id?.length == 0
                        ? null
                        : initialValues.camp_id[0]
                    }
                    getOptionLabel={(option) =>
                      option.camp_place?.charAt(0)?.toUpperCase() +
                      option.camp_place?.slice(1)
                    }
                    onInputChange={(e) => handleInputChange(e)}
                    onChange={(data, value) => {
                      handleCampAndFieldWorker(data, "cbbf", value);
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.camp_id}>
                          {option.camp_place}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        color="black"
                        InputLabelProps={{
                          // shrink: false,
                          style: {
                            fontSize: "13px", // Adjust the font size of the label here
                            paddingTop: "5px",
                          },
                        }}
                        /* label="Select CBBF Camp" */
                        label="Select Volunteers Camp"
                      />
                    )}
                  />
                </div>
                <div className="icons">
                  <AssignmentInd />
                </div>
                <div>
                  <Autocomplete
                    size="small"
                    disablePortal
                    className="date"
                    variant="standard"
                    id="combo-box-demo"
                    sx={{
                      input: {
                        "&::placeholder": {
                          // <----- Add this.
                          opacity: 1,
                          marginBottom: "-5px",
                          fontSize: "10px",
                        },
                      },
                      backgroundColor: "#ffffff",
                      margin: " 0 5px",
                      // borderRadius: '5px',
                      width: "90%",
                    }}
                    key={fieldreset?.vhv}
                    ListboxProps={{
                      sx: { fontSize: 13 },
                    }}
                    options={
                      fieldWorkers?.vhv?.filed_workerList?.length > 0
                        ? fieldWorkers?.vhv?.filed_workerList
                        : [{ field_worker_name: "No data found" }]
                    }
                    getOptionLabel={(option) => option.field_worker_name}
                    defaultValue={
                      initialValues?.field_worker_id == ""
                        ? null
                        : initialValues?.field_worker_id
                    }
                    onChange={(data, value) => {
                      handleFieldWorkersTrack(data, "vhv", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          // shrink: false,
                          style: {
                            fontSize: "13px", // Adjust the font size of the label here
                            paddingTop: "5px",
                          },
                        }}
                        label="Select VHV FieldWorker"
                      />
                    )}
                  />

                  <Autocomplete
                    size="small"
                    disablePortal
                    className="date"
                    variant="standard"
                    id="combo-box-demo"
                    sx={{
                      input: {
                        "&::placeholder": {
                          opacity: 1,
                          marginBottom: "-5px",
                        },
                      },
                      backgroundColor: "#ffffff",
                      margin: " 0 5px",
                      // borderRadius: '5px',
                      width: "90%",
                    }}
                    key={fieldreset?.cbbf}
                    ListboxProps={{
                      sx: { fontSize: 13 },
                    }}
                    options={
                      fieldWorkers?.cbbf?.filed_workerList?.length > 0
                        ? fieldWorkers?.cbbf?.filed_workerList
                        : [{ field_worker_name: "No data found" }]
                    }
                    getOptionLabel={(option) => option.field_worker_name}
                    defaultValue={
                      initialValues?.field_worker_id == ""
                        ? null
                        : initialValues?.field_worker_id
                    }
                    onChange={(data, value) => {
                      handleFieldWorkersTrack(data, "cbbf", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        color="black"
                        InputLabelProps={{
                          // shrink: false,
                          style: {
                            fontSize: "13px", // Adjust the font size of the label here
                            paddingTop: "5px",
                          },
                        }}
                        /* label="Select CBBF FieldWorker" */
                        label="Select Volunteers FieldWorker"
                      />
                    )}
                  />
                </div>
                {initialValues.field_worker_id !== "" ? (
                  <div>
                    <LocalizationProvider
                      size="small"
                      dateAdapter={AdapterDayjs}
                    >
                      <DatePicker
                        className="from_date"
                        value={trackingDate}
                        inputFormat="DD/MM/YYYY"
                        onChange={(e) => {
                          handleTrackingDate(e);
                        }}
                        defaultValue={null}
                        disabled={initialValues.field_worker_id == ""}
                        minDate={date?.minDate}
                        maxDate={date?.maxDate}
                        renderInput={(props) =>
                          renderInputs(props, "Date", "fieldDate")
                        } // props , lablename , classname
                      />
                    </LocalizationProvider>
                  </div>
                ) : (
                  ""
                )}
                <div>
                  <Autocomplete
                    size="small"
                    // disablePortal
                    className="hide"
                    variant="standard"
                    id="combo-box-demo"
                    sx={{
                      input: {
                        "&::placeholder": {
                          // <----- Add this.
                          opacity: 1,
                          // marginBottom: '-5px',
                          color: "#000000",
                        },
                      },
                      backgroundColor: "#ffffff",
                      // borderRadius: '5px',
                      width: "90%",
                      // '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                      //   fontSize: 10,
                      // },
                    }}
                    ListboxProps={{
                      sx: { fontSize: 13 },
                    }}
                    options={
                      villages?.length > 0
                        ? villages
                        : [{ village_name: "No data found" }]
                    }
                    key={villagereset}
                    getOptionLabel={(option) =>
                      option.village_name?.charAt(0)?.toUpperCase() +
                      option.village_name?.slice(1)
                    }
                    onChange={(data, value) => {
                      villageChange(data, value);
                    }}
                    onInputChange={(e) => handleVillageChange(e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        color="black"
                        InputLabelProps={{
                          // shrink: false,
                          style: {
                            fontSize: "13px", // Adjust the font size of the label here
                            paddingTop: "5px",
                            zIndex: "2",
                          },
                        }}
                        label="Select Location"
                      />
                    )}
                  />
                </div>
              </div>
              <Button
                variant="contained"
                sx={{
                  float: "right",
                  m: 1,
                  width: "8ch",
                  textTransform: "none",
                }}
                onClick={() => handleReset()}
                className="search_btn reset"
              >
                {" "}
                Reset{" "}
              </Button>
            </div>
            <div className="map_section">
              {/* <Map reset={reset} hospitalData={hospitalData} detailData={(val, type) => handleDetail(val, type)} heatMap={illness} tracking={daytrack} selectedHospital={(data) => handleselectedHospital(data)} campData={campData} /> */}
              {MemoizedMap}
              <div className="commondetails_">
                <div className="map_details">
                  <div
                    className={
                      filtered == 1 ||
                      filtered == 2 ||
                      filtered == 3 ||
                      filtered == 4
                        ? "hos anime"
                        : "hos vhidden"
                    }
                  >
                    <div className="symbol">
                      <LocalHospital style={{ width: "60%" }} />
                    </div>
                    <div className="card_">
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <LocalHospital />
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Hospital Name
                        </Typography>
                        <Typography variant="caption">
                          {hospitalData?.length <= 1
                            ? hospitalData[0]?.hospital_name == "" ||
                              hospitalData[0]?.hospital_name == undefined
                              ? firstLetter(campDataDetail)
                              : firstLetter(hospitalData[0]?.hospital_name)
                            : "All"}
                        </Typography>
                      </div>
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <Festival />
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Total Camp{" "}
                        </Typography>
                        <Typography variant="caption">
                          {camp?.vhv?.campList?.length > 0
                            ? camp?.vhv?.campList?.length
                            : 0 + camp?.cbbf?.campList?.length > 0
                            ? camp?.cbbf?.campList?.length
                            : 0}
                        </Typography>
                      </div>
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <Male />
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Total Males{" "}
                        </Typography>
                        <Typography variant="caption">
                          {patientDetails?.male}
                        </Typography>
                      </div>
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <FemaleTwoTone />
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Total Females{" "}
                        </Typography>
                        <Typography variant="caption">
                          {patientDetails?.female}
                        </Typography>
                      </div>
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <TransgenderTwoTone />
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Others{" "}
                        </Typography>
                        <Typography variant="caption">
                          {patientDetails?.others}
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      filtered == 2 || filtered == 3 || filtered == 4
                        ? "camp anime"
                        : "camp vhidden"
                    }
                  >
                    <Festival />
                    <div className="card_">
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <Festival />
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Camp Name
                        </Typography>
                        <Typography variant="caption">
                          {firstLetter(campDetail?.camp_place)}
                        </Typography>
                      </div>
                      {campDataDetail != "" && (
                        <div className=" section_">
                          {" "}
                          {/*icon_*/}
                          <LocalHospitalSharp />
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: "bold" }}
                          >
                            Assigned Hospital
                          </Typography>
                          <Typography variant="caption">
                            {firstLetter(campDataDetail)}
                          </Typography>
                        </div>
                      )}

                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <HomeTwoToneIcon />
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Total Household{" "}
                        </Typography>
                        <Typography variant="caption">
                          {patientDetails?.household}
                        </Typography>
                      </div>
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <HomeTwoToneIcon />
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Total Individuals{" "}
                        </Typography>
                        <Typography variant="caption">
                          {patientDetails?.individual}
                        </Typography>
                      </div>
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <BiRename />
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Patient name
                        </Typography>
                        <Typography variant="caption">
                          {firstLetter(houseHoldData?.name)}
                        </Typography>
                      </div>
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <TransgenderTwoTone />
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Patient age{" "}
                        </Typography>
                        <Typography variant="caption">
                          {houseHoldData?.age}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  {/* <div className="filedworker">  */}
                  <div
                    className={
                      filtered == 3 || filtered == 4
                        ? "filedworker anime"
                        : " filedworker vhidden"
                    }
                  >
                    <DirectionsBike />
                    <div className="card_">
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <TwoWheeler />
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Travelled Km
                        </Typography>
                        <Typography variant="caption">
                          {trackDetail != ""
                            ? trackDetail?.traveledDistance?.toFixed(2)
                            : 0}{" "}
                          km
                        </Typography>
                      </div>
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <BiRename />
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Field Worker Name{" "}
                        </Typography>
                        <Typography variant="caption">
                          {fieldWorkerName}
                        </Typography>
                      </div>
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <AiOutlineCaretRight />
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Shift In
                        </Typography>
                        <Typography variant="caption">
                          {shiftdetail?.shiftIn}{" "}
                        </Typography>
                      </div>
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <AiOutlineCaretLeft />
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Shift out
                        </Typography>
                        <Typography variant="caption">
                          {shiftdetail?.shiftout}{" "}
                        </Typography>
                      </div>
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <HomeTwoToneIcon />
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Total Household{" "}
                        </Typography>
                        <Typography variant="caption">
                          {patientDetails?.household}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      filtered == 4 ? "illness anime" : " illness vhidden"
                    }
                  >
                    <div className="card_">
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Cataract Identified
                        </Typography>
                        <Typography variant="caption">
                          {illness?.cataract?.count == undefined
                            ? 0
                            : illness?.cataract?.count}
                        </Typography>
                      </div>
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Cataract Surgery Done
                        </Typography>
                        <Typography variant="caption">
                          {illness?.surgerydonedetails?.surgery_done_count ==
                          undefined
                            ? 0
                            : illness?.surgerydonedetails?.surgery_done_count}
                        </Typography>
                      </div>
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Diabetes
                        </Typography>
                        <Typography variant="caption">
                          {illness?.diabetes?.count == undefined
                            ? 0
                            : illness?.diabetes?.count}
                        </Typography>
                      </div>
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Hypertension
                        </Typography>
                        <Typography variant="caption">
                          {illness?.hypertension?.count == undefined
                            ? 0
                            : illness?.hypertension?.count}
                        </Typography>
                      </div>
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Cataract & Diabetes
                        </Typography>
                        <Typography variant="caption">
                          {illness?.cataractDia?.count == undefined
                            ? 0
                            : illness?.cataractDia?.count}
                        </Typography>
                      </div>
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Hypertension & Diabetes
                        </Typography>
                        <Typography variant="caption">
                          {illness?.HyperDia?.count == undefined
                            ? 0
                            : illness?.HyperDia?.count}
                        </Typography>
                      </div>
                      <div className=" section_">
                        {" "}
                        {/*icon_*/}
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          Cataract & Hypertension & Diabetes{" "}
                        </Typography>
                        <Typography variant="caption">
                          {illness?.cataractHyperDia?.count == undefined
                            ? 0
                            : illness?.cataractHyperDia?.count}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default Gis_report;
