import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  DialogTitle,
  Stack,
  Select,
  MenuItem,
  FormControl,
  Grid,
  InputLabel,
  DialogContentText,
  Autocomplete,
  TextField,
  FormHelperText,
} from "@mui/material";
import { Form, Formik, Field } from "formik";
import { HybridInput, HybridSelect } from "../components/hybrid_inputs";
import { useAxios } from "../hooks/useAxios";
import {
  MobileUserSchemaValidation,
  MobileUserSchemaEditValidation,
} from "../validations/userSchema";
// import TableComponent from "../components/table";
import TableComponent from "../components/TableLayout";
import "./Style.css";
import { toast, Slide } from "react-toastify";
import LowerCase from "./LowerCase";
import ConfirmPopup from "../components/confirmPopup";
import CloseIcon from "@mui/icons-material/Close";
export default function MobileUserManagement() {
  const [initialState, setInitialValue] = useState({
    user_name: "",
    phone: "",
    email: "",
    emp_code: "",
    password: "",
    mandal_id: "",
    state_id: "",
    district_id: "",
    hospital_id: "",
    village_id: "",
    designation: "",
    hospital_address: "",
    pin_code: "",
    gender: "",
    role_id: "",
    organization: [],
  });

  const [header, setHeader] = useState([
    {
      id: 1,
      label: "Name",
      value: "user_name",
    },
    {
      id: 2,
      label: "Employee Code",
      value: "emp_code",
    },
    {
      id: 3,
      label: "Employee Type",
      value: "role_type",
    },
    {
      id: 4,
      label: "Phone",
      value: "phone",
    },
    {
      id: 5,
      label: "Email",
      value: "email",
    },
    {
      id: 6,
      label: "Hospital Name",
      value: "hospital_name",
    },
    // {
    //   id: 6,
    //   label: "State Name",
    //   value: "state_name",
    // },
    // {
    //   id: 7,
    //   label: "District Name",
    //   value: "district_name",
    // },
    // {
    //   id: 8,
    //   label: "Mandal Name",
    //   value: "mandal_name",
    // },
    // {
    //   id: 9,
    //   label: "Village Name",
    //   value: "village_name",
    // },
    {
      id: 7,
      label: "Designation",
      value: "designation",
    },
    // {
    //   id: 11,
    //   label: "Hospital Address",
    //   value: "hospital_address",
    // },
    {
      id: 12,
      label: "Gender",
      value: "gender",
    },
    {
      id: 13,
      label: "Action",
      value: "action",
    },
  ]);
  let stateModel = {
    _id: "",
    state_name: "No data found",
  };
  let districtModel = {
    _id: "",
    district_name: "No data found",
  };
  let MandalModel = {
    _id: "",
    mandal_name: "No data found",
  };
  let TalukModel = {
    _id: "",
    taluk_name: "No data found",
  };
  let VIllageModel = {
    _id: "",
    village_name: "No data found",
  };
  let HospitalModel = {
    _id: "",
    hospital_name: "No data found",
  };
  let Email = ["email"];
  const [districtList, setDistrictList] = useState([districtModel]);
  const [talukList, setTalukList] = useState([TalukModel]);

  const [length, setLength] = useState();
  const [mandalList, setMandalList] = useState([MandalModel]);
  const [villageList, setVillageList] = useState([VIllageModel]);
  const [activeId, setActiveId] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [userState, setUserState] = useState(false);
  const [disable, setDisable] = useState(true);
  const [fieldWorkerStatus, setFieldWorkerStatus] = useState(false);
  const [fWdetails, setFWdetails] = useState("");
  const [designation] = useState([
    {
      _id: 1,
      name: "Head",
    },
    {
      _id: 2,
      name: "Assistance",
    },
  ]);
  const [type] = useState([
    {
      _id: 3,
      name: "VHV",
    },
    {
      _id: 4,
      name: "Volunteers",
    },
    {
      _id: 5,
      name: "Optometrists",
    },
    {
      _id: 6,
      name: "Campsite Registration",
    },
  ]);
  const [gender] = useState([
    {
      _id: 1,
      name: "Male",
    },
    {
      _id: 2,
      name: "Female",
    },
    {
      _id: 2,
      name: "Others",
    },
  ]);
  const [mobileUserList, setMobileUserList] = useState([]);
  const [MobileUserDetails, setMobileUserDetails] = useState(initialState);
  const [stateList, setStateList] = useState([stateModel]);
  const [progressBar, setProgressBar] = useState(false);
  const [open, setOpen] = useState(false);
  const { axios } = useAxios();
  const [hospitalList, setHospitalList] = useState([HospitalModel]);
  const [editState, setEditState] = useState(false);
  const [disabledDistrict, setDisabledDistrict] = useState(true);
  let paginationInfo = {
    page: 1,
    count: 0,
    rowsPerPage: 10,
  };
  const [pageInfo, setPageInfo] = useState(paginationInfo);

  // organisation state management
  const [iniOrganisation, setIniOrganisation] = useState([]);
  const [selectedOrgani, setSelectedOrgani] = useState([]);
  const [typeCheck, setTypeCheck] = useState(true);
  const [isValidate, setIsValidate] = useState(false);
  const [organiOptions, setOrganiOptions] = useState([]);

  // employee code state
  const [isValidateEmpCode, setIsValidateEmpCode] = useState(false);

  useEffect(() => {
    getMobileUserList();
  }, [pageInfo.rowsPerPage, pageInfo.page, searchValue]);

  useEffect(() => {
    getMobileUserList();
  }, [userState]);

  const getMobileUserList = () => {
    axios
      .post(
        `mobileUserList?limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}&search=${searchValue}`
      )
      .then((data) => {
        setMobileUserList(data.data.result.list);
        setLength(data.data.result.length ? data.data.result.length : 0);
        setPageInfo((prevState) => ({
          ...prevState,
          count: data.data.result.length ? data.data.result.length : 0,
        }));
      })
      .catch(() => {
        setMobileUserList([]);
        setLength(0);
        setPageInfo(paginationInfo);
      });
  };
  const getStateList = () => {
    axios.post("statesDropDown").then((data) => {
      if (data.data.result.length > 0) {
        let result = LowerCase(data.data.result, "state_name");
        setStateList(result);
      } else {
        setStateList([stateModel]);
      }
    });
  };
  const handleSearchValue = (values) => {
    setPageInfo(paginationInfo);
    setSearchValue(values);
  };
  const getDistrictList = async (value) => {
    setDistrictList([districtModel]);
    let state = {
      state_id: value,
    };
    await axios.post("districtDropDown", state).then((data) => {
      if (data.data.result.length > 0) {
        let result = LowerCase(data.data.result, "district_name");
        setDistrictList(result);
      } else {
        setDistrictList([districtModel]);
      }
    });
    setDisabledDistrict(false);
  };
  const getTalukList = async (value) => {
    let district = {
      district_id: value,
    };
    await axios.post("talukDropDown", district).then((data) => {
      if (data.data.result.length > 0) {
        let result = LowerCase(data.data.result, "taluk_name");
        setTalukList(result);
      } else {
        setTalukList([MandalModel]);
      }
    });
  };
  const getMandalList = async (value) => {
    let mandal = {
      taluk_id: value,
    };
    await axios.post("mandalDropDown", mandal).then((data) => {
      if (data.data.result.length > 0) {
        let result = LowerCase(data.data.result, "mandal_name");

        setMandalList(result);
      } else {
        setMandalList([VIllageModel]);
      }
    });
  };

  const getVillageList = async (value) => {
    let mandal = {
      mandal_id: value,
    };
    await axios.post("villageDropDown", mandal).then((data) => {
      if (data.data.result.length > 0) {
        let result = LowerCase(data.data.result, "village_name");
        setVillageList(result);
      } else {
        setVillageList([VIllageModel]);
      }
    });
  };

  const getHospitalList = async (value) => {
    await axios.post("AllHospitalDropDown").then((data) => {
      if (data.data.result.length > 0) {
        let result = LowerCase(data.data.result, "hospital_name");
        setHospitalList(result);
      } else {
        setHospitalList([VIllageModel]);
      }
    });
  };

  // get orgnisation list
  const getOrganisationList = async () => {
    try {
      const response = await axios.get(`getAllOrganization`);
      console.log("getAllOrganization response", response);
      const { success, result } = response?.data;
      if (success) {
        let organisations = LowerCase(result, "organization_name");
        setIniOrganisation(organisations);
      }
    } catch (error) {
      toast.error("Something Went Wrong", {
        autoClose: 1500,
        transition: Slide,
      });
    }
  };

  const addStates = (actions) => {
    setEditState(false);
    getStateList();
    getHospitalList();
    setDisable(false);
    setInitialValue({
      user_name: "",
      phone: "",
      email: "",
      emp_code: "",
      password: "",
      mandal_id: "",
      state_id: "",
      district_id: "",
      hospital_id: "",
      village_id: "",
      designation: "",
      hospital_address: "",
      pin_code: "",
      gender: "",
      role_id: "",
      device_id: "",
      organization: [],
    });

    // initial state
    setOrganiOptions([]);

    // get organisation list
    getOrganisationList();

    setOpen(true);
  };
  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };

  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };
  const closeDialogue = () => {
    setMobileUserDetails(initialState);
    setOpen(false);
  };

  const formSubmit = async (values) => {
    var state = stateList.find((i) => {
      if (i._id == values.state_id) {
        return i;
      }
    });
    var district = districtList.find((i) => {
      if (i._id == values.district_id) {
        return i;
      }
    });
    var hospital = hospitalList.find((i) => {
      if (i._id == values.hospital_id) {
        return i;
      }
    });
    // var taluk = talukList.find((i) => { if (i._id == values.taluk_id) { return i } })
    // var mandal = mandalList.find((i) => { if (i._id == values.mandal_id) { return i } })
    // var village = villageList.find((i) => { if (i._id == values.village_id) { return i } })

    if (editState === false) {
      setDisable(true);
      values.state_name = state.state_name;
      values.district_name = district.district_name;
      // values.taluk_name = taluk.taluk_name
      // values.mandal_name = mandal.mandal_name
      // values.village_name = village.village_name
      values.state_id = values.state_id;
      values.district_id = values.district_id;
      // values.taluk_id = values.taluk_id
      // values.mandal_id = values.mandal_id
      // values.village_id = values.village_id
      values.hospital_name = hospital.hospital_name;
      values.hospital_id = values.hospital_id;
      values.status = 1;
      delete values.device_id;

      delete values.mandal_id;
      delete values.village_id;
      delete values.taluk_id;

      // add organisation details to payload
      // delete values.organizations;
      values.organization = selectedOrgani;

      if (values.role_id !== 3) {
        await axios
          .post("mobileUserCreate", values)
          .then((data) => {
            if (data.data.success === true) {
              closeDialogue();
              getMobileUserList();
              setInitialValue({
                user_name: "",
                phone: "",
                email: "",
                emp_code: "",
                password: "",
                mandal_id: "",
                state_id: "",
                district_id: "",
                hospital_id: "",
                village_id: "",
                designation: "",
                hospital_address: "",
                pin_code: "",
                gender: "",
                role_id: "",
                organization: [],
              });
            }
          })
          .catch((data) => {
            if (!data?.response?.data?.success) {
              toast.warning(data?.response?.data?.message, {
                autoClose: 3000,
                transition: Slide,
              });
            }
          });
      } else {
        if (values.emp_code.length > 0) {
          if (values.emp_code.length === 6) {
            await axios
              .post("mobileUserCreate", values)
              .then((data) => {
                if (data.data.success === true) {
                  closeDialogue();
                  getMobileUserList();
                  setInitialValue({
                    user_name: "",
                    phone: "",
                    email: "",
                    emp_code: "",
                    password: "",
                    mandal_id: "",
                    state_id: "",
                    district_id: "",
                    hospital_id: "",
                    village_id: "",
                    designation: "",
                    hospital_address: "",
                    pin_code: "",
                    gender: "",
                    role_id: "",
                    organization: [],
                  });
                }
              })
              .catch((data) => {
                if (!data?.response?.data?.success) {
                  toast.warning(data?.response?.data?.message, {
                    autoClose: 3000,
                    transition: Slide,
                  });
                }
              });
          } else {
            toast.warning("Employee Code must be 6 Digits ", {
              autoClose: 3000,
              transition: Slide,
            });
          }
        } else {
          toast.warning("Employee Code Required", {
            autoClose: 3000,
            transition: Slide,
          });
        }
      }
    } else {
      setDisable(true);

      values.state_name = state.state_name;
      values.district_name = district.district_name;
      // values.taluk_name = taluk.taluk_name
      // values.mandal_name = mandal.mandal_name
      // values.village_name = village.village_name
      values.state_id = values.state_id;
      values.district_id = values.district_id;
      // values.taluk_id = values.taluk_id
      // values.mandal_id = values.mandal_id
      // values.village_id = values.village_id
      values.hospital_name = hospital.hospital_name;
      values.hospital_id = values.hospital_id;
      delete values.device_id;
      delete values.role_type;
      delete values.mandal_id;
      delete values.village_id;
      delete values.taluk_id;

      // edit organisation details to payload
      // delete values.organizations;
      values.organization = selectedOrgani;

      values.status = 1;
      // delete values._id
      delete values.create_on;
      delete values.modify_on;
      if (values.role_id !== 3) {
        await axios.post("mobileUserUpdate", values).then((data) => {
          if (data.status === 200) {
            closeDialogue();
            getMobileUserList();
            setInitialValue({
              user_name: "",
              phone: "",
              email: "",
              emp_code: "",
              password: "",
              mandal_id: "",
              state_id: "",
              district_id: "",
              hospital_id: "",
              village_id: "",
              designation: "",
              hospital_address: "",
              pin_code: "",
              gender: "",
              role_id: "",
              organization: [],
            });
          }
        });
      } else {
        if (values.emp_code.length > 0) {
          if (values.emp_code.length === 6) {
            await axios.post("mobileUserUpdate", values).then((data) => {
              if (data.status === 200) {
                closeDialogue();
                getMobileUserList();
                setInitialValue({
                  user_name: "",
                  phone: "",
                  email: "",
                  emp_code: "",
                  password: "",
                  mandal_id: "",
                  state_id: "",
                  district_id: "",
                  hospital_id: "",
                  village_id: "",
                  designation: "",
                  hospital_address: "",
                  pin_code: "",
                  gender: "",
                  role_id: "",
                  organization: [],
                });
              }
            });
          } else {
            toast.warning("Employee Code must be 6 Digits ", {
              autoClose: 3000,
              transition: Slide,
            });
          }
        } else {
          toast.warning("Employee Code Required", {
            autoClose: 3000,
            transition: Slide,
          });
        }
      }
    }
  };
  const deviceSet = (val) => {
    let value = {
      _id: val,
    };
    if (val === "" || val === undefined) {
      toast.warn("Device Id is Not Registed Yet");
    } else {
      axios.post("resetDeviceId", value).then((data) => {
        getMobileUserList();
      });
    }
  };

  const editData = (data) => {
    try {
      setEditState(true);
      setDisable(false);
      getHospitalList();
      getStateList();

      // get organizations list
      getOrganisationList();

      mobileUserList.forEach((list) => {
        if (list._id === data) {
          setActiveId(list._id);
          getDistrictList(list.state_id);
          getTalukList(list.district_id);
          getMandalList(list.taluk_id);
          getVillageList(list.mandal_id);
          setInitialValue({ ...initialState, ...list });

          const orG = list?.organization?.map(
            ({ organization_collection_id: _id, ...rest }) => ({
              _id,
              ...rest,
            })
          );

          // selectedorgani when user didn't perform any actions on organisation field
          const orGD = list?.organization?.map(
            ({
              organization_collection_id,
              organization_name,
              organization_id,
            }) => ({
              organization_collection_id,
              organization_name,
              organization_id,
            })
          );

          setOrganiOptions(orG);
          setSelectedOrgani(orGD);

          // type and validations updation for organizations
          if (list?.role_id == 3 || list?.role_id == 4) {
            setTypeCheck(false);
            setIsValidate(true);
          } else {
            setTypeCheck(true);
            setIsValidate(false);
            // setSelectedOrgani([]);
            // setOrganiOptions([]);
          }

          // type and validation updation for employee code
          if (list?.role_id == 3) {
            setIsValidateEmpCode(true);
          } else {
            setIsValidateEmpCode(false);
          }

          setOpen(true);
        }
      });
    } catch (error) {
      toast.error("Something Went Wrong", {
        autoClose: 1500,
        transition: Slide,
      });
    }
  };

  function deleteCancel() {
    setDeletePopup(false);
  }
  function deleteSubmit() {
    setDeletePopup(false);
    let insertData = {
      _id: activeId,
    };
    axios.post(`mobileUserDelete`, insertData).then((data) => {
      if (data.data.success == true) {
        getMobileUserList();
      }
    });
  }
  const deleteDate = (data) => {
    setActiveId(data);
    setDeletePopup(true);
  };
  const handleClose = () => {
    setOpen(false);
    setInitialValue({
      user_name: "",
      phone: "",
      email: "",
      emp_code: "",
      password: "",
      mandal_id: "",
      state_id: "",
      district_id: "",
      hospital_id: "",
      village_id: "",
      designation: "",
      hospital_address: "",
      pin_code: "",
      gender: "",
      role_id: "",
      organization: [],
    });
  };

  // prevent backDrop of dialog
  const handleClosePrevent = (reason) => {
    if (reason == "escapeKeyDown" || reason == "backdropClick") {
      setOpen(true);
    }
  };

  // async function handleFielder(val) {
  //   setFieldWorkerStatus(true)
  //   // let boolen = val.status == 1 ? 0 : 1
  //   // var data = { _id: val._id, status: boolen }
  //   // await axios.post('mobileUserStatus', data).then((res) => {
  //   //   if (res?.data?.message == 'Status Updated Successfully') {
  //   //     setUserState(!userState)
  //   //   }
  //   // })
  // }

  async function handleFielder() {
    let boolen = fWdetails.status == 1 ? 0 : 1;
    var data = { _id: fWdetails._id, status: boolen };
    await axios.post("mobileUserStatus", data).then((res) => {
      if (res?.data?.message == "Status Updated Successfully") {
        setUserState(!userState);
        setFieldWorkerStatus(false);
      }
    });
  }

  // users type check
  const handleTypeCallbk = (value) => {
    // organizations validation check
    if (value == 3 || value == 4) {
      setTypeCheck(false);
      setIsValidate(true);
    } else {
      setTypeCheck(true);
      setIsValidate(false);
      setSelectedOrgani([]);
      setOrganiOptions([]);
    }

    // employee code validation check
    if (value == 3) {
      setIsValidateEmpCode(true);
    } else {
      setIsValidateEmpCode(false);
    }
  };

  // handle organisation
  const handleOrganisation = (value) => {
    const selectedOrganisations = value.map(
      ({ _id: organization_collection_id, ...rest }) => ({
        organization_collection_id,
        ...rest,
      })
    );
    setSelectedOrgani(selectedOrganisations);
  };

  // make selected values disabled
  const getOptionDisabled = (option, organiOptions) =>
    organiOptions.some(
      (organiOptions) =>
        organiOptions.organization_name === option.organization_name
    );

  console.log("initialState", initialState);

  return (
    <>
      <div>
        <Dialog
          open={fieldWorkerStatus}
          onClose={() => setFieldWorkerStatus(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Do you want to {fWdetails?.activeinactive} this user?
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => setFieldWorkerStatus(false)}>Cancel</Button>
            <Button onClick={() => handleFielder()} autoFocus>
              {" "}
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className="state_head">
        <Typography variant="h5" color="secondary" className="text-left">
          Mobile User Management
        </Typography>
        <Button
          variant="contained"
          sx={{
            float: "right",
            m: 1,
            width: "10ch",
            textTransform: "capitalize",
          }}
          onClick={() => addStates()}
        >
          {" "}
          Add
        </Button>
      </div>

      <TableComponent
        header={header}
        datalist={mobileUserList}
        datalength={length}
        actions={[]}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        handleTablePage={handleTablePage}
        handleTableRowsPerPage={handleTableRowsPerPage}
        deviceReset={(id) => deviceSet(id)}
        mobileIcon={true}
        activeInactive={true}
        email={Email}
        editData={(id) => editData(id)}
        deleteData={(id) => deleteDate(id)}
        searchDataValue={(val) => handleSearchValue(val)}
        handleFielder={(boo) => {
          setFieldWorkerStatus(true);
          setFWdetails(boo);
        }}
        searchValue={() => {
          setSearchValue("");
          setPageInfo(paginationInfo);
        }}
      />
      {deletePopup ? (
        <ConfirmPopup
          message={"Are you sure, you want to delete this record"}
          deleteCancel={deleteCancel}
          deleteSubmit={deleteSubmit}
        ></ConfirmPopup>
      ) : (
        ""
      )}

      {/* <Dialog open={open} fullWidth onClose={closeDialogue}> */}
      <Dialog open={open} fullWidth onClose={handleClosePrevent}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle style={{ marginLeft: "12rem" }}>
            {editState ? "Edit Mobile User" : "Add Mobile User"}
          </DialogTitle>
          <DialogTitle>
            <CloseIcon onClick={handleClose} />
          </DialogTitle>
        </div>
        <Formik
          initialValues={initialState}
          validationSchema={
            !editState
              ? MobileUserSchemaValidation(isValidate, isValidateEmpCode)
              : MobileUserSchemaEditValidation(isValidate, isValidateEmpCode)
          }
          onSubmit={formSubmit}
        >
          {({ isSubmitting, setFieldValue, errors, touched }) => (
            <Form>
              <DialogContent>
                <HybridInput
                  label="Name"
                  name="user_name"
                  type="text"
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  fullWidth
                  variant="standard"
                  setVal={setFieldValue}
                />
                <HybridInput
                  label="Employee Code"
                  name="emp_code"
                  type="text"
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9]/g, "")
                      .slice(0, 6);
                  }}
                  fullWidth
                  variant="standard"
                  setVal={setFieldValue}
                />

                <HybridInput
                  label="Phone Number"
                  name="phone"
                  type="tel"
                  fullWidth
                  maxlength="10"
                  setVal={setFieldValue}
                  InputProps={{ inputProps: { minlength: 0, maxlength: 10 } }}
                  variant="standard"
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9]/g, "")
                      .slice(0, 10);
                  }}
                />
                <HybridInput
                  label="Email"
                  name="email"
                  type="email"
                  setVal={setFieldValue}
                  fullWidth
                  variant="standard"
                />
                {!editState && (
                  <HybridInput
                    label="Password"
                    name="password"
                    type="password"
                    setVal={setFieldValue}
                    fullWidth
                    variant="standard"
                  />
                )}
                {editState && (
                  <HybridInput
                    label="New Password"
                    name="password"
                    type="password"
                    setVal={setFieldValue}
                    fullWidth
                    variant="standard"
                  />
                )}

                {/* <HybridSelect
                  label="Hospital Name"
                  name="hospital_id"
                  fullWidth
                  type="text"
                  variant="standard"
                  setVal={setFieldValue}
                  defaultValue={""}
                  options={hospitalList}
                  dropKey="hospital_name"
                  dropVal=""
                /> */}

                <Field name="hospital_id">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel
                        id="select-label"
                        style={{ marginLeft: "-3%", marginTop: "2%" }}
                      >
                        Select Hospital
                      </InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        {...field}
                        value={field.value}
                        onChange={(event) => {
                          setFieldValue(field.name, event.target.value);
                        }}
                      >
                        {hospitalList.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.hospital_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>

                {/* <HybridSelect
                  label="State Name"
                  name="state_id"
                  fullWidth
                  type="text"
                  variant="standard"
                  setVal={setFieldValue}
                  defaultValue={""}
                  onChange={(e) => {
                    getDistrictList(e, setFieldValue);
                  }}
                  options={stateList}
                  dropKey="state_name"
                  dropVal=""
                /> */}

                <Field name="state_id">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel
                        id="select-label"
                        style={{ marginLeft: "-3%", marginTop: "2%" }}
                      >
                        Select State
                      </InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        {...field}
                        value={field.value}
                        onChange={(event) => {
                          setFieldValue(field.name, event.target.value);
                          getDistrictList(event.target.value);
                        }}
                      >
                        {stateList.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.state_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>

                {/* <HybridSelect
                  label="District Name"
                  name="district_id"
                  fullWidth
                  type="text"
                  variant="standard"
                  setVal={setFieldValue}
                  defaultValue={""}
                  onChange={(e) => {
                    getTalukList(e, setFieldValue);
                  }}
                  options={districtList}
                  dropKey="district_name"
                  dropVal=""
                /> */}

                <Field name="district_id">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel
                        id="select-label"
                        style={{ marginLeft: "-3%", marginTop: "2%" }}
                      >
                        Select District
                      </InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        {...field}
                        value={field.value}
                        onChange={(event) => {
                          setFieldValue(field.name, event.target.value);
                          getTalukList(event.target.value);
                        }}
                      >
                        {districtList.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.district_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>

                {/* <HybridSelect
                  label="Taluk Name"
                  name="taluk_id"
                  fullWidth
                  type="text"
                  variant="standard"
                  setVal={setFieldValue}
                  defaultValue={""}
                  onChange={(e) => {
                    getMandalList(e, setFieldValue);
                  }}
                  options={talukList}
                  dropKey="taluk_name"
                  dropVal=""
                /> */}

                {/* <Field name="taluk_id">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id="select-label" style={{ marginLeft: '-3%', marginTop: '2%' }}>Select SubDivison</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        {...field}
                        value={field.value}
                        onChange={(event) => { setFieldValue(field.name, event.target.value); getMandalList(event.target.value) }}
                      >
                        {talukList.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.taluk_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field> */}

                {/* <HybridSelect
                  label="Mandal Name"
                  name="mandal_id"
                  fullWidth
                  type="text"
                  variant="standard"
                  defaultValue=""
                  setVal={setFieldValue}
                  onChange={(e) => {
                    getVillageList(e, setFieldValue);
                  }}
                  options={mandalList}
                  dropKey="mandal_name"
                  dropVal=""
                /> */}

                {/* <Field name="mandal_id">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id="select-label" style={{ marginLeft: '-3%', marginTop: '2%' }}>Select Municipal Corporation</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        {...field}
                        value={field.value}
                        onChange={(event) => { setFieldValue(field.name, event.target.value); getVillageList(event.target.value) }}
                      >
                        {mandalList.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.mandal_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>

                <Field name="village_id">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id="select-label" style={{ marginLeft: '-3%', marginTop: '2%' }}>Select Village</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        {...field}
                        value={field.value}
                        onChange={(event) => { setFieldValue(field.name, event.target.value); }}
                      >
                        {villageList.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.village_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field> */}

                <HybridInput
                  label="Designation"
                  name="designation"
                  type="text"
                  fullWidth
                  variant="standard"
                  setVal={setFieldValue}
                />

                <HybridInput
                  label="Hospital Address"
                  name="hospital_address"
                  type="text"
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  fullWidth
                  variant="standard"
                  setVal={setFieldValue}
                />

                <HybridInput
                  label="Pin Code"
                  name="pin_code"
                  type="text"
                  fullWidth
                  maxlength="10"
                  setVal={setFieldValue}
                  InputProps={{ inputProps: { minlength: 0, maxlength: 10 } }}
                  variant="standard"
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9]/g, "")
                      .slice(0, 6);
                  }}
                  dropKey="pin_code"
                  dropVal="pin_code"
                />

                <HybridSelect
                  label="Gender"
                  name="gender"
                  fullWidth
                  type="text"
                  variant="standard"
                  setVal={setFieldValue}
                  defaultValue={""}
                  options={gender}
                  dropKey="name"
                  dropVal="name"
                />

                <HybridSelect
                  label="Type"
                  name="role_id"
                  fullWidth
                  defaultValue={{ label: "VHV", value: 0 }}
                  type="text"
                  variant="standard"
                  setVal={setFieldValue}
                  options={type}
                  dropKey="name"
                  dropVal="_id"
                  onChange={(e) => {
                    handleTypeCallbk(e);
                    if (e == 5 || e == 6) {
                      setFieldValue("organization", []);
                    }
                  }}
                />

                <Autocomplete
                  required
                  multiple
                  id="tags-standard"
                  name="organization"
                  options={iniOrganisation}
                  getOptionLabel={(option) => option.organization_name}
                  value={organiOptions}
                  getOptionDisabled={(option) =>
                    getOptionDisabled(option, organiOptions)
                  }
                  onChange={(data, value) => {
                    setFieldValue("organization", value);
                    handleOrganisation(value);
                    setOrganiOptions(value);
                  }}
                  defaultValue={[]}
                  // disabled={typeCheck}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Select Organisation"
                      placeholder=""
                    />
                  )}
                />
                {errors?.organization && touched?.organization ? (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.organization}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </DialogContent>
              <DialogActions>
                <div className="submit">
                  <Stack direction="row" spacing={2}>
                    <Button
                      color="primary"
                      type="button"
                      variant="contained"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    <Button color="primary" type="submit" variant="contained">
                      {" "}
                      {/* disabled={disable ? true : false}*/}
                      {editState ? "Update" : "Add"}
                    </Button>
                  </Stack>
                </div>
              </DialogActions>
            </Form>
          )}
        </Formik>
        {progressBar && <LinearProgress color="primary" />}
      </Dialog>
    </>
  );
}
