import React from "react"
import PropTypes from 'prop-types';
import { TextField } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment } from '@mui/material';
// component
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import CampFilter from "../Filter/CampFilter";
import EventFilter from "../Filter/EventFilter";


// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    // boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

TableListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function TableListToolbar({ numSelected, filterName, onFilterName, filterRow,filteredData ,onFilterData}) {
  const [open, setOpen] = React.useState(false)
  const [data, setValue] = React.useState('')
  


  const handleFilter = () => {
    setOpen(true)

  }

  const handleFilterData=(val)=>{

   filteredData(val)
  }

  const handleClose = (data) => {
    
    setOpen(data)
  }

  const handleChange=(data)=>{
    setValue(data)
    onFilterData(data)
  }


  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >

      {open ? filterRow==="camp" ? <CampFilter
        filterObject={(val)=>handleFilterData(val)}
        filterClose={(val) => { handleClose(val) }} /> :filterRow==="event"?  <EventFilter
        filterObject={(val)=>handleFilterData(val)}
        filterClose={(val) => { handleClose(val) }} /> :<StyledSearch
        value={data}
        // onChange={onFilterName}
        onChange={(e)=>handleChange(e.target.value)}
        placeholder="Search user..."
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
        : numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <StyledSearch
            value={data}
            // onChange={onFilterName}
            onChange={(e)=>handleChange(e.target.value)}
            placeholder="Search user..."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        )}

      {open ? "" : numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon onClick={handleFilter} />
          </IconButton>
        </Tooltip>
      )}
    </StyledRoot>
  );
}
