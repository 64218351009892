import * as React from "react";
import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { HybridInput } from "./hybrid_inputs";
import { Form, Formik } from "formik";
import { ChangePassword } from "../validations/userSchema";

export const FormDialogue = ({ open, close, formData }) => {

  let initialValues = {
    old_password: "",
    new_password: "",
  };
  const [progressBar, setProgressBar] = React.useState(false);

  const handleSubmit = (values, actions) => {
  
    setProgressBar(true);

 
    formData(values);
    close();
    setProgressBar((prevState) => (prevState = false));
    actions.resetForm();
  };
  return (
    <Dialog open={open} onClose={close}>
      <Formik
        initialValues={initialValues}
        validationSchema={ChangePassword}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
              <CardContent style={{ display: "flex" }}>
                <HybridInput
                  label="Old Password"
                  name="old_password"
                  fullWidth
                  type="text"
                  variant="standard"
                />
                <HybridInput
                  label="New Password"
                  name="new_password"
                  fullWidth
                  type="text"
                  variant="standard"
                />
              </CardContent>
            </DialogContent>
            <DialogActions>
              <Button onClick={close}>Cancel</Button>
              <Button type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
      {progressBar && <LinearProgress color="primary" />}
    </Dialog>
  );
};
