import React, { useState, useEffect } from "react";

// customized excel
import ExcelfileCustomized from "../components/ExcelFormat/ExcelfileCustomized.js";

import {
  Dialog,
  Button,
  CardContent,
  Stack,
  DialogTitle,
  TextField,
  Autocomplete,
  Typography,
} from "@mui/material";

import TableComponent from "../components/table";
import BackDrop from "../components/BackDrop.js";
import { useAxios } from "../hooks/useAxios";

import { toast, Slide } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
// import { Dialog, Button, CardContent, Typography } from "@mui/material";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelDownload from "../components/ExcelFormat/ExcelFileDownload";

import { dates } from "../index.js";

import {
  Table,
  makeStyles,
  FormControl,
  TableBody,
  TableCell,
  InputLabel,
  Input,
  Select,
  MenuItem,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const renderInputs = (props, placeholder, classname) => (
  <Input
    sx={{
      width: "80%",
      input: {
        "&::placeholder": {
          // <----- Add this.
          opacity: 1,
          // marginBottom: '-5px',
          paddingLeft: "10px",
          fontSize: "13px",
          color: "#000000",
        },
        width: "100%",
      },
      backgroundColor: "#ffffff",
      // borderRadius: '5px',
      // maxHeight: '50px'
    }}
    type="text"
    className={classname}
    inputRef={props.inputRef}
    // inputProps={props.inputProps}
    inputProps={{
      ...props.inputProps,
      placeholder: placeholder,
    }}
    value={props.value}
    onClick={props.onClick}
    onChange={props.onChange}
    endAdornment={props.InputProps?.endAdornment}
  />
);

const Camp_performance = () => {
  const [header] = useState([
    {
      id: 1,
      name: "Camp Date",
      value: "camp_date",
    },

    {
      id: 2,
      name: "Hospital Name",
      value: "hospital_name",
    },
    {
      id: 3,
      name: "Camp No",
      value: "camp_no",
    },
    {
      id: 4,
      name: "Camp Place",
      value: "camp_place",
    },
    {
      id: 5,
      name: "Fieldworker Name",
      value: "field_worker_name",
    },
    {
      id: 6,
      name: "Male Count",
      value: "male_count",
    },
    {
      id: 7,
      name: "Female Count",
      value: "female_count",
    },
    {
      id: 8,
      name: "Others Count",
      value: "transgender_count",
    },
    {
      id: 9,
      name: "Child Count",
      value: "child_count_male",
    },
    {
      id: 10,
      name: "Total OPD ",
      value: "total_opd",
    },
    // {
    //   id: 11,
    //   name: "Total Suggested for Surgery",
    //   value: "total_surgery",
    // },

    {
      id: 11,
      name: "Yellow Card Collected",
      value: "yellow_card_collected_camp",
    },
    {
      id: 12,
      name: "Yellow Card Selected for Surgery",
      value: "yellow_card_selected_camp_for_surgery",
    },
    {
      id: 13,
      name: "Yellow Card Converted to Surgery",
      value: "yellow_card_convert_camp_for_surgery",
    },
  ]);
  const { axios } = useAxios();
  const [campList, setCampList] = useState([]);
  const [length, setLength] = useState();
  const [filterValue, setFilterValue] = useState({
    campStart: "",
    campEnd: "",
    campIds: "",
    campPlaces: "",
  });
  const [filteredFwValue, setFilteredFwValue] = useState("");
  let paginationSetup = {
    page: 1,
    count: 0,
    rowsPerPage: 10,
  };

  const [searchValue, setSearchValue] = React.useState({
    eventStart: "",
    eventEnd: "",
  });

  const [downloadTab, setDownloadTab] = useState(false);
  const [value, setValue] = useState([]);
  const [dateSearch, setDateSearch] = useState("");

  // campPerformanceConsolidated Report StateManagement
  const [isConsolidatedCpr, setIsConsolidatedCpr] = useState(false);

  // loader state
  const [backDropOpen, setBackDropOpen] = useState(false);

  const [pageInfo, setPageInfo] = useState(paginationSetup);
  let dateFields = ["camp_date", "date_of_review_camp"];

  useEffect(() => {
    CampList();
  }, [pageInfo.rowsPerPage, pageInfo.page, filterValue]); //filterValue
  const CampList = () => {
    axios
      .post(
        `campPerAllList?limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}&fromDate=${filterValue.campStart}&toDate=${filterValue.campEnd}&field_worker_name=${filterValue.campIds}&field_worker_type=${filteredFwValue}&hospital_name=${filterValue.campPlaces}`
      )
      .then((data) => {
        setCampList(data.data.result.list);
        setLength(data.data.result.length);

        setPageInfo((prevState) => ({
          ...prevState,
          count: data.data.result.length,
        }));
      })
      .catch((err) => {
        setCampList([]);
        setLength(0);
        setPageInfo(paginationSetup);
      });
  };
  const editData = (data) => {};
  const deleteDate = (data) => {};
  function deleteCancel(params) {}
  function deletesubmit(params) {}
  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };
  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };

  const filterResponse = (val) => {
    setPageInfo((prevState) => ({ ...prevState, page: 1 }));
    setFilterValue(val);
  };

  const searchFwtype = (val) => {
    setPageInfo((prevState) => ({ ...prevState, page: 1 }));
    setFilteredFwValue(val);
  };

  function firstLetter(s) {
    return s?.replace(/^.{1}/g, s[0]?.toUpperCase());
  }

  function handleDates(val) {
    const today = new Date(); // Get the current date
    setDateSearch(val);

    // Format the dates as "DD-MM-YYYY"
    var dateFormat = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    if (val == "last_week") {
      //==============last week ======================

      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7); // Subtract 7 days to get the date for last week

      // Find the start date (Monday) of last week
      const startOfWeek = new Date(lastWeek);
      startOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7));

      // Find the end date (Sunday) of last week
      const endOfWeek = new Date(lastWeek);
      endOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7) + 6);

      // Format the dates
      var formattedStartOfWeek = startOfWeek.toISOString().split("T")[0];
      var formattedEndOfWeek = endOfWeek.toISOString().split("T")[0];

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartOfWeek,
        eventEnd: formattedEndOfWeek,
      }));
    } else if (val == "last_month") {
      //================last month ===============================
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

      // Set the day to 1 to get the first day of the previous month
      lastMonth.setDate(2);

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: lastMonth.toISOString().split("T")[0],
        eventEnd: moment().date(0).toISOString().split("T")[0],
      }));
    } else if (val == "last_3_month") {
      //================last month ===============================

      const threeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        1
      );

      // Set the day to 1 to get the first day of three months ago
      threeMonthsAgo.setDate(1);

      const lastDayOfThreeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );

      const formattedStartDate = threeMonthsAgo.toLocaleDateString("en-IN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "Asia/Kolkata",
      });
      const formattedEndDate = lastDayOfThreeMonthsAgo.toLocaleDateString(
        "en-IN",
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone: "Asia/Kolkata",
        }
      );

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
        eventEnd: formattedEndDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
      }));
    } else if (val == "all") {
      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: "",
        eventEnd: "",
      }));
    }
  }

  const handleuploadExcel = async () => {
    setBackDropOpen(true);
    await axios
      .post(
        `campPerAllList?limit=&page=${1}&fromDate=${
          searchValue.eventStart
        }&toDate=${searchValue.eventEnd}&field_worker_name=${
          filterValue.campIds
        }&field_worker_type=${filteredFwValue}&hospital_name=${
          filterValue.campPlaces
        }`
      )
      .then((res) => {
        let campPerformance = res.data.result.list;

        var i = 0;

        let data = campPerformance.map((data) => {
          i++;
          return {
            ...data,
            camp_date: data.camp_date.slice(0, 10),
            hospital_name: firstLetter(data.hospital_name),
            camp_no: data.camp_no,
            camp_place: firstLetter(data?.camp_place),
            field_worker_name: firstLetter(data?.field_worker_name),
            male_count: data.male_count,
            female_count: data.female_count,
            others_count: data.transgender_count,
            child_count: data.child_count_female + data.child_count_male,
            total_opd: data.total_opd,
            yellow_card_collected: data?.yellow_card_collected_camp,
            yellow_card_selected_surgery:
              data?.yellow_card_selected_camp_for_surgery,
            yellow_card_converted_surgery:
              data?.yellow_card_convert_camp_for_surgery,
          };
        });

        let newArrayOfObj = data.map(
          ({
            camp_date: CampDate,
            hospital_name: Hospitalname,
            camp_no: CampNumber,
            camp_place: CampPlace,
            field_worker_name: FieldWorkerName,
            male_count: MaleCount,
            female_count: FemaleCount,
            others_count: OthersCount,
            child_count: ChildCount,
            total_opd: TotalOPD,
            yellow_card_collected: YellowCardCollected,
            yellow_card_selected_surgery: YellowCardCollectedForSurgery,
            yellow_card_converted_surgery: YellowCardConvertedtoSurgery,
          }) => ({
            CampDate,
            Hospitalname,
            CampNumber,
            CampPlace,
            FieldWorkerName,
            MaleCount,
            FemaleCount,
            OthersCount,
            ChildCount,
            TotalOPD,
            YellowCardCollected,
            YellowCardCollectedForSurgery,
            YellowCardConvertedtoSurgery,
          })
        );
        ExcelDownload(newArrayOfObj, "CampPerfromanceSummaryDetails");

        setDownloadTab((o) => !o);
        setDateSearch("");
        setBackDropOpen(false);
      });
  };

  // consolidated camp performance report handler
  const consolidatedSummary = async () => {
    try {
      // consolidated endpoint
      setBackDropOpen(true);
      const response = await axios.get(
        `campPerConList?limit=&page=${1}&fromDate=${
          searchValue.eventStart
        }&toDate=${searchValue.eventEnd}&field_worker_name=${
          filterValue.campIds
        }&field_worker_type=${filteredFwValue}&hospital_name=${
          filterValue.campPlaces
        }`
      );

      console.log("response", response);
      const { success, result } = response?.data;

      if (success) {
        // add excel cell styling
        const styleWithBold = {
          alignment: { vertical: "center", horizontal: "center" },
          font: { bold: true },
          border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
          },
        };
        const styleWithBoldBg = {
          alignment: { vertical: "center", horizontal: "center" },
          font: { bold: true },
          fill: { fgColor: { rgb: "FF7300" } },
          border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
          },
        };
        const styleWithoutBold = {
          alignment: { vertical: "center", horizontal: "center" },
          border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
          },
        };

        // add excel cell details and styling
        const head = {
          A: {
            A3: {
              value: "Camp Performance Report (Consolidated)",
              mergeRange: { s: { r: 2, c: 0 }, e: { r: 3, c: 9 } },
              styles: styleWithBoldBg,
            },
            A5: {
              value: "No of Camps",
              mergeRange: { s: { r: 4, c: 0 }, e: { r: 4, c: 4 } },
              styles: styleWithBold,
            },
            A6: {
              value: "No. of Distance Travelled",
              mergeRange: { s: { r: 5, c: 0 }, e: { r: 5, c: 4 } },
              styles: styleWithBold,
            },
            A7: {
              value: "No. of Household Surveyed",
              mergeRange: { s: { r: 6, c: 0 }, e: { r: 6, c: 4 } },
              styles: styleWithBold,
            },
            A8: {
              value: "No. of Individuals Screened",
              mergeRange: { s: { r: 7, c: 0 }, e: { r: 7, c: 4 } },
              styles: styleWithBold,
            },
            A9: {
              value: "",
              mergeRange: { s: { r: 8, c: 0 }, e: { r: 8, c: 9 } },
              styles: styleWithoutBold,
            },
            A10: {
              value: "",
              mergeRange: { s: { r: 9, c: 0 }, e: { r: 9, c: 3 } },
            },
            A11: {
              value: "No. of Out Patients in Camp",
              mergeRange: { s: { r: 10, c: 0 }, e: { r: 10, c: 3 } },
              styles: styleWithBold,
            },
            A12: {
              value: "",
              mergeRange: { s: { r: 11, c: 0 }, e: { r: 11, c: 3 } },
            },
            A13: {
              value: "No. of Cards Distributed",
              mergeRange: { s: { r: 12, c: 0 }, e: { r: 12, c: 3 } },
              styles: styleWithBold,
            },
            A14: {
              value: "Yellow Cards",
              mergeRange: { s: { r: 13, c: 0 }, e: { r: 13, c: 3 } },
              styles: styleWithoutBold,
            },
            A15: {
              value: "Pink Cards",
              mergeRange: { s: { r: 14, c: 0 }, e: { r: 14, c: 3 } },
              styles: styleWithoutBold,
            },
            A16: {
              value: "No. of Cards Reported at Camp",
              mergeRange: { s: { r: 15, c: 0 }, e: { r: 15, c: 3 } },
              styles: styleWithBold,
            },
            A17: {
              value: "Yellow Cards",
              mergeRange: { s: { r: 16, c: 0 }, e: { r: 16, c: 3 } },
              styles: styleWithoutBold,
            },
            A18: {
              value: "Pink Cards",
              mergeRange: { s: { r: 17, c: 0 }, e: { r: 17, c: 3 } },
              styles: styleWithoutBold,
            },
            A19: {
              value: "No. of Cards Selected for GOV Surgery",
              mergeRange: { s: { r: 18, c: 0 }, e: { r: 18, c: 3 } },
              styles: styleWithBold,
            },
            A20: {
              value: "Yellow Cards",
              mergeRange: { s: { r: 19, c: 0 }, e: { r: 19, c: 3 } },
              styles: styleWithoutBold,
            },
            A21: {
              value: "Pink Cards",
              mergeRange: { s: { r: 20, c: 0 }, e: { r: 20, c: 3 } },
              styles: styleWithoutBold,
            },
            A22: {
              value: "Camp Drop Out",
              mergeRange: { s: { r: 21, c: 0 }, e: { r: 21, c: 3 } },
              styles: styleWithBold,
            },
            A23: {
              value: "No. of Cards Converted to GOV Surgery",
              mergeRange: { s: { r: 22, c: 0 }, e: { r: 22, c: 3 } },
              styles: styleWithBold,
            },
            A24: {
              value: "Yellow Cards",
              mergeRange: { s: { r: 23, c: 0 }, e: { r: 23, c: 3 } },
              styles: styleWithoutBold,
            },
            A25: {
              value: "Pink Cards",
              mergeRange: { s: { r: 24, c: 0 }, e: { r: 24, c: 3 } },
              styles: styleWithoutBold,
            },
            A26: {
              value: "Hospital Drop Out",
              mergeRange: { s: { r: 25, c: 0 }, e: { r: 25, c: 3 } },
              styles: styleWithBold,
            },
            A27: {
              value: "Surgery Conversion Rate",
              mergeRange: { s: { r: 26, c: 0 }, e: { r: 26, c: 3 } },
              styles: styleWithBold,
            },
            A28: {
              value: "No. of Cards Reported to Vision Center",
              mergeRange: { s: { r: 27, c: 0 }, e: { r: 27, c: 3 } },
              styles: styleWithBold,
            },
            A29: {
              value: "No. of Cards Ordered Glasses",
              mergeRange: { s: { r: 28, c: 0 }, e: { r: 28, c: 3 } },
              styles: styleWithBold,
            },
          },
          B: {
            B3: {
              value: "",
              styles: styleWithBoldBg,
            },
            B4: {
              value: "",
              styles: styleWithBoldBg,
            },
            B5: {
              value: "",
              styles: styleWithBold,
            },
            B6: {
              value: "",
              styles: styleWithBold,
            },
            B7: {
              value: "",
              styles: styleWithBold,
            },
            B8: {
              value: "",
              styles: styleWithBold,
            },
            B9: {
              value: "",
              styles: styleWithoutBold,
            },
            B11: {
              value: "",
              styles: styleWithoutBold,
            },
            B12: {
              value: "",
              styles: styleWithoutBold,
            },
            B13: {
              value: "",
              styles: styleWithoutBold,
            },
            B14: {
              value: "",
              styles: styleWithoutBold,
            },
            B15: {
              value: "",
              styles: styleWithoutBold,
            },
            B16: {
              value: "",
              styles: styleWithoutBold,
            },
            B17: {
              value: "",
              styles: styleWithoutBold,
            },
            B18: {
              value: "",
              styles: styleWithoutBold,
            },
            B19: {
              value: "",
              styles: styleWithoutBold,
            },
            B20: {
              value: "",
              styles: styleWithoutBold,
            },
            B21: {
              value: "",
              styles: styleWithoutBold,
            },
            B22: {
              value: "",
              styles: styleWithoutBold,
            },
            B23: {
              value: "",
              styles: styleWithoutBold,
            },
            B24: {
              value: "",
              styles: styleWithoutBold,
            },
            B25: {
              value: "",
              styles: styleWithoutBold,
            },
            B26: {
              value: "",
              styles: styleWithoutBold,
            },
            B27: {
              value: "",
              styles: styleWithoutBold,
            },
            B28: {
              value: "",
              styles: styleWithoutBold,
            },
            B29: {
              value: "",
              styles: styleWithoutBold,
            },
          },
          C: {
            C3: {
              value: "",
              styles: styleWithBoldBg,
            },
            C4: {
              value: "",
              styles: styleWithBoldBg,
            },
            C5: {
              value: "",
              styles: styleWithBold,
            },
            C6: {
              value: "",
              styles: styleWithBold,
            },
            C7: {
              value: "",
              styles: styleWithBold,
            },
            C8: {
              value: "",
              styles: styleWithBold,
            },
            C9: {
              value: "",
              styles: styleWithoutBold,
            },
            C11: {
              value: "",
              styles: styleWithoutBold,
            },
            C12: {
              value: "",
              styles: styleWithoutBold,
            },
            C13: {
              value: "",
              styles: styleWithoutBold,
            },
            C14: {
              value: "",
              styles: styleWithoutBold,
            },
            C15: {
              value: "",
              styles: styleWithoutBold,
            },
            C16: {
              value: "",
              styles: styleWithoutBold,
            },
            C17: {
              value: "",
              styles: styleWithoutBold,
            },
            C18: {
              value: "",
              styles: styleWithoutBold,
            },
            C19: {
              value: "",
              styles: styleWithoutBold,
            },
            C20: {
              value: "",
              styles: styleWithoutBold,
            },
            C21: {
              value: "",
              styles: styleWithoutBold,
            },
            C22: {
              value: "",
              styles: styleWithoutBold,
            },
            C23: {
              value: "",
              styles: styleWithoutBold,
            },
            C24: {
              value: "",
              styles: styleWithoutBold,
            },
            C25: {
              value: "",
              styles: styleWithoutBold,
            },
            C26: {
              value: "",
              styles: styleWithoutBold,
            },
            C27: {
              value: "",
              styles: styleWithoutBold,
            },
            C28: {
              value: "",
              styles: styleWithoutBold,
            },
            C29: {
              value: "",
              styles: styleWithoutBold,
            },
          },
          D: {
            D3: {
              value: "",
              styles: styleWithBoldBg,
            },
            D4: {
              value: "",
              styles: styleWithBoldBg,
            },
            D5: {
              value: "",
              styles: styleWithBold,
            },
            D6: {
              value: "",
              styles: styleWithBold,
            },
            D7: {
              value: "",
              styles: styleWithBold,
            },
            D8: {
              value: "",
              styles: styleWithBold,
            },
            D9: {
              value: "",
              styles: styleWithoutBold,
            },
            D11: {
              value: "",
              styles: styleWithoutBold,
            },
            D12: {
              value: "",
              styles: styleWithoutBold,
            },
            D13: {
              value: "",
              styles: styleWithoutBold,
            },
            D14: {
              value: "",
              styles: styleWithoutBold,
            },
            D15: {
              value: "",
              styles: styleWithoutBold,
            },
            D16: {
              value: "",
              styles: styleWithoutBold,
            },
            D17: {
              value: "",
              styles: styleWithoutBold,
            },
            D18: {
              value: "",
              styles: styleWithoutBold,
            },
            D19: {
              value: "",
              styles: styleWithoutBold,
            },
            D20: {
              value: "",
              styles: styleWithoutBold,
            },
            D21: {
              value: "",
              styles: styleWithoutBold,
            },
            D22: {
              value: "",
              styles: styleWithoutBold,
            },
            D23: {
              value: "",
              styles: styleWithoutBold,
            },
            D24: {
              value: "",
              styles: styleWithoutBold,
            },
            D25: {
              value: "",
              styles: styleWithoutBold,
            },
            D26: {
              value: "",
              styles: styleWithoutBold,
            },
            D27: {
              value: "",
              styles: styleWithoutBold,
            },
            D28: {
              value: "",
              styles: styleWithoutBold,
            },
            D29: {
              value: "",
              styles: styleWithoutBold,
            },
          },
          E: {
            E3: {
              value: "",
              styles: styleWithBoldBg,
            },
            E4: {
              value: "",
              styles: styleWithBoldBg,
            },
            E5: {
              value: "",
              styles: styleWithBold,
            },
            E6: {
              value: "",
              styles: styleWithBold,
            },
            E7: {
              value: "",
              styles: styleWithBold,
            },
            E8: {
              value: "",
              styles: styleWithBold,
            },
            E9: {
              value: "",
              styles: styleWithoutBold,
            },
            E10: {
              value: "Male",
              styles: styleWithBold,
            },
            E11: {
              value: result?.no_of_out_patients_in_camp?.male ?? 0,
              styles: styleWithoutBold,
            },
            E12: {
              value: "Male",
              styles: styleWithBold,
            },
            E13: {
              value: result?.no_of_cards_distributed_in_camp?.male ?? 0,
              styles: styleWithoutBold,
            },
            E14: {
              value:
                result?.no_of_cards_distributed_in_camp?.yellow_cards?.male ??
                0,
              styles: styleWithoutBold,
            },
            E15: {
              value:
                result?.no_of_cards_distributed_in_camp?.pink_cards?.male ?? 0,
              styles: styleWithoutBold,
            },
            E16: {
              value: result?.no_of_cards_reported_at_camp?.male ?? 0,
              styles: styleWithoutBold,
            },
            E17: {
              value:
                result?.no_of_cards_reported_at_camp?.yellow_cards?.male ?? 0,
              styles: styleWithoutBold,
            },
            E18: {
              value:
                result?.no_of_cards_reported_at_camp?.pink_cards?.male ?? 0,
              styles: styleWithoutBold,
            },
            E19: {
              value: result?.no_of_cards_selected_for_gov_surgery?.male ?? 0,
              styles: styleWithoutBold,
            },
            E20: {
              value:
                result?.no_of_cards_selected_for_gov_surgery?.yellow_cards
                  ?.male ?? 0,
              styles: styleWithoutBold,
            },
            E21: {
              value:
                result?.no_of_cards_selected_for_gov_surgery?.pink_cards
                  ?.male ?? 0,
              styles: styleWithoutBold,
            },
            E22: {
              value: result?.camp_drop_out?.male ?? 0,
              styles: styleWithoutBold,
            },
            E23: {
              value: result?.no_of_cards_converted_to_gov_surgery?.male ?? 0,
              styles: styleWithoutBold,
            },
            E24: {
              value:
                result?.no_of_cards_converted_to_gov_surgery?.yellow_cards
                  ?.male ?? 0,
              styles: styleWithoutBold,
            },
            E25: {
              value:
                result?.no_of_cards_converted_to_gov_surgery?.pink_cards
                  ?.male ?? 0,
              styles: styleWithoutBold,
            },
            E26: {
              value: result?.hospital_drop_out?.male ?? 0,
              styles: styleWithoutBold,
            },
            E27: {
              value: result?.surgery_conversion_rate?.male ?? 0,
              styles: styleWithoutBold,
            },
            E28: {
              value: result?.no_of_cards_reported_to_vision_center?.male ?? 0,
              styles: styleWithoutBold,
            },
            E29: {
              value: result?.no_of_cards_ordered_glasses?.male ?? 0,
              styles: styleWithoutBold,
            },
          },
          F: {
            F3: {
              value: "",
              styles: styleWithBoldBg,
            },
            F4: {
              value: "",
              styles: styleWithBoldBg,
            },
            F5: {
              value: result?.no_of_camps ?? 0,
              mergeRange: { s: { r: 4, c: 5 }, e: { r: 4, c: 9 } },
              styles: styleWithoutBold,
            },
            F6: {
              value: Math.round(result?.no_of_distance_travelled) ?? 0,
              mergeRange: { s: { r: 5, c: 5 }, e: { r: 5, c: 9 } },
              styles: styleWithoutBold,
            },
            F7: {
              value: result?.no_of_household_survey ?? 0,
              mergeRange: { s: { r: 6, c: 5 }, e: { r: 6, c: 9 } },
              styles: styleWithoutBold,
            },
            F8: {
              value: result?.no_of_individuals_screened ?? 0,
              mergeRange: { s: { r: 7, c: 5 }, e: { r: 7, c: 9 } },
              styles: styleWithoutBold,
            },
            F9: {
              value: "",
              styles: styleWithoutBold,
            },
            F10: {
              value: "Female",
              styles: styleWithBold,
            },
            F11: {
              value: result?.no_of_out_patients_in_camp?.female ?? 0,
              styles: styleWithoutBold,
            },
            F12: {
              value: "Female",
              styles: styleWithBold,
            },
            F13: {
              value: result?.no_of_cards_distributed_in_camp?.female ?? 0,
              styles: styleWithoutBold,
            },
            F14: {
              value:
                result?.no_of_cards_distributed_in_camp?.yellow_cards?.female ??
                0,
              styles: styleWithoutBold,
            },
            F15: {
              value:
                result?.no_of_cards_distributed_in_camp?.pink_cards?.female ??
                0,
              styles: styleWithoutBold,
            },
            F16: {
              value: result?.no_of_cards_reported_at_camp?.female ?? 0,
              styles: styleWithoutBold,
            },
            F17: {
              value:
                result?.no_of_cards_reported_at_camp?.yellow_cards?.female ?? 0,
              styles: styleWithoutBold,
            },
            F18: {
              value:
                result?.no_of_cards_reported_at_camp?.pink_cards?.female ?? 0,
              styles: styleWithoutBold,
            },
            F19: {
              value: result?.no_of_cards_selected_for_gov_surgery?.female ?? 0,
              styles: styleWithoutBold,
            },
            F20: {
              value:
                result?.no_of_cards_selected_for_gov_surgery?.yellow_cards
                  ?.female ?? 0,
              styles: styleWithoutBold,
            },
            F21: {
              value:
                result?.no_of_cards_selected_for_gov_surgery?.pink_cards
                  ?.female ?? 0,
              styles: styleWithoutBold,
            },
            F22: {
              value: result?.camp_drop_out?.female ?? 0,
              styles: styleWithoutBold,
            },
            F23: {
              value: result?.no_of_cards_converted_to_gov_surgery?.female ?? 0,
              styles: styleWithoutBold,
            },
            F24: {
              value:
                result?.no_of_cards_converted_to_gov_surgery?.yellow_cards
                  ?.female ?? 0,
              styles: styleWithoutBold,
            },
            F25: {
              value:
                result?.no_of_cards_converted_to_gov_surgery?.pink_cards
                  ?.female ?? 0,
              styles: styleWithoutBold,
            },
            F26: {
              value: result?.hospital_drop_out?.female ?? 0,
              styles: styleWithoutBold,
            },
            F27: {
              value: result?.surgery_conversion_rate?.female ?? 0,
              styles: styleWithoutBold,
            },
            F28: {
              value: result?.no_of_cards_reported_to_vision_center?.female ?? 0,
              styles: styleWithoutBold,
            },
            F29: {
              value: result?.no_of_cards_ordered_glasses?.female ?? 0,
              styles: styleWithoutBold,
            },
          },
          G: {
            G3: {
              value: "",
              styles: styleWithBoldBg,
            },
            G4: {
              value: "",
              styles: styleWithBoldBg,
            },
            G5: {
              value: "",
              styles: styleWithoutBold,
            },
            G6: {
              value: "",
              styles: styleWithoutBold,
            },
            G7: {
              value: "",
              styles: styleWithoutBold,
            },
            G8: {
              value: "",
              styles: styleWithoutBold,
            },
            G9: {
              value: "",
              styles: styleWithoutBold,
            },
            G10: {
              value: "Others",
              styles: styleWithBold,
            },
            G11: {
              value: result?.no_of_out_patients_in_camp?.others ?? 0,
              styles: styleWithoutBold,
            },
            G12: {
              value: "Others",
              styles: styleWithBold,
            },
            G13: {
              value: result?.no_of_cards_distributed_in_camp?.others ?? 0,
              styles: styleWithoutBold,
            },
            G14: {
              value:
                result?.no_of_cards_distributed_in_camp?.yellow_cards?.others ??
                0,
              styles: styleWithoutBold,
            },
            G15: {
              value:
                result?.no_of_cards_distributed_in_camp?.pink_cards?.others ??
                0,
              styles: styleWithoutBold,
            },
            G16: {
              value: result?.no_of_cards_reported_at_camp?.others ?? 0,
              styles: styleWithoutBold,
            },
            G17: {
              value:
                result?.no_of_cards_reported_at_camp?.yellow_cards?.others ?? 0,
              styles: styleWithoutBold,
            },
            G18: {
              value:
                result?.no_of_cards_reported_at_camp?.pink_cards?.others ?? 0,
              styles: styleWithoutBold,
            },
            G19: {
              value: result?.no_of_cards_selected_for_gov_surgery?.others ?? 0,
              styles: styleWithoutBold,
            },
            G20: {
              value:
                result?.no_of_cards_selected_for_gov_surgery?.yellow_cards
                  ?.others ?? 0,
              styles: styleWithoutBold,
            },
            G21: {
              value:
                result?.no_of_cards_selected_for_gov_surgery?.pink_cards
                  ?.others ?? 0,
              styles: styleWithoutBold,
            },
            G22: {
              value: result?.camp_drop_out?.others ?? 0,
              styles: styleWithoutBold,
            },
            G23: {
              value: result?.no_of_cards_converted_to_gov_surgery?.others ?? 0,
              styles: styleWithoutBold,
            },
            G24: {
              value:
                result?.no_of_cards_converted_to_gov_surgery?.yellow_cards
                  ?.others ?? 0,
              styles: styleWithoutBold,
            },
            G25: {
              value:
                result?.no_of_cards_converted_to_gov_surgery?.pink_cards
                  ?.others ?? 0,
              styles: styleWithoutBold,
            },
            G26: {
              value: result?.hospital_drop_out?.others ?? 0,
              styles: styleWithoutBold,
            },
            G27: {
              value: result?.surgery_conversion_rate?.others ?? 0,
              styles: styleWithoutBold,
            },
            G28: {
              value: result?.no_of_cards_reported_to_vision_center?.others ?? 0,
              styles: styleWithoutBold,
            },
            G29: {
              value: result?.no_of_cards_ordered_glasses?.others ?? 0,
              styles: styleWithoutBold,
            },
          },
          H: {
            H3: {
              value: "",
              styles: styleWithBoldBg,
            },
            H4: {
              value: "",
              styles: styleWithBoldBg,
            },
            H5: {
              value: "",
              styles: styleWithoutBold,
            },
            H6: {
              value: "",
              styles: styleWithoutBold,
            },
            H7: {
              value: "",
              styles: styleWithoutBold,
            },
            H8: {
              value: "",
              styles: styleWithoutBold,
            },
            H9: {
              value: "",
              styles: styleWithoutBold,
            },
            H10: {
              value: "Child",
              styles: styleWithBold,
            },
            H11: {
              value: result?.no_of_out_patients_in_camp?.child ?? 0,
              styles: styleWithoutBold,
            },
            H12: {
              value: "Child",
              styles: styleWithBold,
            },
            H13: {
              value: result?.no_of_cards_distributed_in_camp?.child ?? 0,
              styles: styleWithoutBold,
            },
            H14: {
              value:
                result?.no_of_cards_distributed_in_camp?.yellow_cards?.child ??
                0,
              styles: styleWithoutBold,
            },
            H15: {
              value:
                result?.no_of_cards_distributed_in_camp?.pink_cards?.child ?? 0,
              styles: styleWithoutBold,
            },
            H16: {
              value: result?.no_of_cards_reported_at_camp?.child ?? 0,
              styles: styleWithoutBold,
            },
            H17: {
              value:
                result?.no_of_cards_reported_at_camp?.yellow_cards?.child ?? 0,
              styles: styleWithoutBold,
            },
            H18: {
              value:
                result?.no_of_cards_reported_at_camp?.pink_cards?.child ?? 0,
              styles: styleWithoutBold,
            },
            H19: {
              value: result?.no_of_cards_selected_for_gov_surgery?.child ?? 0,
              styles: styleWithoutBold,
            },
            H20: {
              value:
                result?.no_of_cards_selected_for_gov_surgery?.yellow_cards
                  ?.child ?? 0,
              styles: styleWithoutBold,
            },
            H21: {
              value:
                result?.no_of_cards_selected_for_gov_surgery?.pink_cards
                  ?.child ?? 0,
              styles: styleWithoutBold,
            },
            H22: {
              value: result?.camp_drop_out?.child ?? 0,
              styles: styleWithoutBold,
            },
            H23: {
              value: result?.no_of_cards_converted_to_gov_surgery?.child ?? 0,
              styles: styleWithoutBold,
            },
            H24: {
              value:
                result?.no_of_cards_converted_to_gov_surgery?.yellow_cards
                  ?.child ?? 0,
              styles: styleWithoutBold,
            },
            H25: {
              value:
                result?.no_of_cards_converted_to_gov_surgery?.pink_cards
                  ?.child ?? 0,
              styles: styleWithoutBold,
            },
            H26: {
              value: result?.hospital_drop_out?.child ?? 0,
              styles: styleWithoutBold,
            },
            H27: {
              value: result?.surgery_conversion_rate?.child ?? 0,
              styles: styleWithoutBold,
            },
            H28: {
              value: result?.no_of_cards_reported_to_vision_center?.child ?? 0,
              styles: styleWithoutBold,
            },
            H29: {
              value: result?.no_of_cards_ordered_glasses?.child ?? 0,
              styles: styleWithoutBold,
            },
          },
          I: {
            I3: {
              value: "",
              styles: styleWithBoldBg,
            },
            I4: {
              value: "",
              styles: styleWithBoldBg,
            },
            I5: {
              value: "",
              styles: styleWithoutBold,
            },
            I6: {
              value: "",
              styles: styleWithoutBold,
            },
            I7: {
              value: "",
              styles: styleWithoutBold,
            },
            I8: {
              value: "",
              styles: styleWithoutBold,
            },
            I9: {
              value: "",
              styles: styleWithoutBold,
            },
            I10: {
              value: "Total",
              mergeRange: { s: { r: 9, c: 8 }, e: { r: 9, c: 9 } },
              styles: styleWithBold,
            },
            I11: {
              value: result?.no_of_out_patients_in_camp?.total ?? 0,
              mergeRange: { s: { r: 10, c: 8 }, e: { r: 10, c: 9 } },
              styles: styleWithoutBold,
            },
            I12: {
              value: "Total",
              mergeRange: { s: { r: 11, c: 8 }, e: { r: 11, c: 9 } },
              styles: styleWithBold,
            },
            I13: {
              value: result?.no_of_cards_distributed_in_camp?.total ?? 0,
              mergeRange: { s: { r: 12, c: 8 }, e: { r: 12, c: 9 } },
              styles: styleWithoutBold,
            },
            I14: {
              value:
                result?.no_of_cards_distributed_in_camp?.yellow_cards?.total ??
                0,
              mergeRange: { s: { r: 13, c: 8 }, e: { r: 13, c: 9 } },
              styles: styleWithoutBold,
            },
            I15: {
              value:
                result?.no_of_cards_distributed_in_camp?.pink_cards?.total ?? 0,
              mergeRange: { s: { r: 14, c: 8 }, e: { r: 14, c: 9 } },
              styles: styleWithoutBold,
            },
            I16: {
              value: result?.no_of_cards_reported_at_camp?.total ?? 0,
              mergeRange: { s: { r: 15, c: 8 }, e: { r: 15, c: 9 } },
              styles: styleWithoutBold,
            },
            I17: {
              value:
                result?.no_of_cards_reported_at_camp?.yellow_cards?.total ?? 0,
              mergeRange: { s: { r: 16, c: 8 }, e: { r: 16, c: 9 } },
              styles: styleWithoutBold,
            },
            I18: {
              value:
                result?.no_of_cards_reported_at_camp?.pink_cards?.total ?? 0,
              mergeRange: { s: { r: 17, c: 8 }, e: { r: 17, c: 9 } },
              styles: styleWithoutBold,
            },
            I19: {
              value: result?.no_of_cards_selected_for_gov_surgery?.total ?? 0,
              mergeRange: { s: { r: 18, c: 8 }, e: { r: 18, c: 9 } },
              styles: styleWithoutBold,
            },
            I20: {
              value:
                result?.no_of_cards_selected_for_gov_surgery?.yellow_cards
                  ?.total ?? 0,
              mergeRange: { s: { r: 19, c: 8 }, e: { r: 19, c: 9 } },
              styles: styleWithoutBold,
            },
            I21: {
              value:
                result?.no_of_cards_selected_for_gov_surgery?.pink_cards
                  ?.total ?? 0,
              mergeRange: { s: { r: 20, c: 8 }, e: { r: 20, c: 9 } },
              styles: styleWithoutBold,
            },
            I22: {
              value: result?.camp_drop_out?.total ?? 0,
              mergeRange: { s: { r: 21, c: 8 }, e: { r: 21, c: 9 } },
              styles: styleWithoutBold,
            },
            I23: {
              value: result?.no_of_cards_converted_to_gov_surgery?.total ?? 0,
              mergeRange: { s: { r: 22, c: 8 }, e: { r: 22, c: 9 } },
              styles: styleWithoutBold,
            },
            I24: {
              value:
                result?.no_of_cards_converted_to_gov_surgery?.yellow_cards
                  ?.total ?? 0,
              mergeRange: { s: { r: 23, c: 8 }, e: { r: 23, c: 9 } },
              styles: styleWithoutBold,
            },
            I25: {
              value:
                result?.no_of_cards_converted_to_gov_surgery?.pink_cards
                  ?.total ?? 0,
              mergeRange: { s: { r: 24, c: 8 }, e: { r: 24, c: 9 } },
              styles: styleWithoutBold,
            },
            I26: {
              value: result?.hospital_drop_out?.total ?? 0,
              mergeRange: { s: { r: 25, c: 8 }, e: { r: 25, c: 9 } },
              styles: styleWithoutBold,
            },
            I27: {
              value: result?.surgery_conversion_rate?.total ?? 0,
              mergeRange: { s: { r: 26, c: 8 }, e: { r: 26, c: 9 } },
              styles: styleWithoutBold,
            },
            I28: {
              value: result?.no_of_cards_reported_to_vision_center?.total ?? 0,
              mergeRange: { s: { r: 27, c: 8 }, e: { r: 27, c: 9 } },
              styles: styleWithoutBold,
            },
            I29: {
              value: result?.no_of_cards_ordered_glasses?.total ?? 0,
              mergeRange: { s: { r: 28, c: 8 }, e: { r: 28, c: 9 } },
              styles: styleWithoutBold,
            },
          },
          J: {
            J3: {
              value: "",
              styles: styleWithBoldBg,
            },
            J4: {
              value: "",
              styles: styleWithBoldBg,
            },
            J5: {
              value: "",
              styles: styleWithoutBold,
            },
            J6: {
              value: "",
              styles: styleWithoutBold,
            },
            J7: {
              value: "",
              styles: styleWithoutBold,
            },
            J8: {
              value: "",
              styles: styleWithoutBold,
            },
            J9: {
              value: "",
              styles: styleWithoutBold,
            },
            J10: {
              value: "",
              styles: styleWithoutBold,
            },
            J11: {
              value: "",
              styles: styleWithoutBold,
            },
            J12: {
              value: "",
              styles: styleWithoutBold,
            },
            J13: {
              value: "",
              styles: styleWithoutBold,
            },
            J14: {
              value: "",
              styles: styleWithoutBold,
            },
            J15: {
              value: "",
              styles: styleWithoutBold,
            },
            J16: {
              value: "",
              styles: styleWithoutBold,
            },
            J17: {
              value: "",
              styles: styleWithoutBold,
            },
            J18: {
              value: "",
              styles: styleWithoutBold,
            },
            J19: {
              value: "",
              styles: styleWithoutBold,
            },
            J20: {
              value: "",
              styles: styleWithoutBold,
            },
            J21: {
              value: "",
              styles: styleWithoutBold,
            },
            J22: {
              value: "",
              styles: styleWithoutBold,
            },
            J23: {
              value: "",
              styles: styleWithoutBold,
            },
            J24: {
              value: "",
              styles: styleWithoutBold,
            },
            J25: {
              value: "",
              styles: styleWithoutBold,
            },
            J26: {
              value: "",
              styles: styleWithoutBold,
            },
            J27: {
              value: "",
              styles: styleWithoutBold,
            },
            J28: {
              value: "",
              styles: styleWithoutBold,
            },
            J29: {
              value: "",
              styles: styleWithoutBold,
            },
          },
        };

        // customized excel download utility function
        ExcelfileCustomized(head, "CampPerformance (Consolidated)");

        setBackDropOpen(false);
        setDownloadTab((o) => !o);
        setIsConsolidatedCpr((prevState) => !prevState);
        setDateSearch("");
      }
    } catch (error) {
      console.log("catchBlockCPCEndpoint", error);
      toast.warning(error?.response?.data?.message ?? "Something Went Wrong", {
        autoClose: 1500,
        transition: Slide,
      });
      setBackDropOpen(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" color="secondary" className="text-left">
          Camp Performance Summary
        </Typography>{" "}
        <div style={{ float: "left" }}>
          <Button
            variant="contained"
            sx={{ m: 1, width: "auto", textTransform: "capitalize" }}
            startIcon={<FileDownloadIcon />}
            // onClick={handleuploadExcel}
            onClick={() => setDownloadTab((k) => !k)}
          >
            Camp Performance Summary
          </Button>
          <Button
            variant="contained"
            sx={{ m: 1, width: "auto", textTransform: "capitalize" }}
            startIcon={<FileDownloadIcon />}
            // onClick={consolidatedSummary}
            onClick={() => {
              setDownloadTab((k) => !k);
              setIsConsolidatedCpr((prevState) => !prevState);
            }}
          >
            Camp Performance Summary (Consolidated)
          </Button>
        </div>
      </div>

      {downloadTab && (
        <div className="tabbackground">
          <div
            className={dateSearch == "custom" ? "nonreports" : "nonreports1"}
          >
            <div className="table">
              <Table>
                <TableHead>
                  <tr>
                    {/* <th><Checkbox
                      onChange={(e) => {
                        handleSelectAll(e);
                      }}
                    />
                      Select all</th> */}
                    <th> </th>
                    <th>Select Duration </th>
                  </tr>
                </TableHead>
              </Table>
              <div className="filter">
                <FormControl style={{ width: "80%" }} size="small">
                  <Select
                    labelId="select-label"
                    id="select"
                    variant="standard"
                    label="Select Dates"
                    value={dateSearch}
                    onChange={(e) => {
                      handleDates(e.target.value);
                    }}
                  >
                    {dates?.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {dateSearch == "custom" && (
                  <div style={{ width: "100%" }} className="custom">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="from_date"
                        value={
                          searchValue.eventStart == ""
                            ? null
                            : searchValue.eventStart
                        }
                        inputFormat="DD/MM/YYYY"
                        onChange={(e) => {
                          const selectedDate = e.$d;
                          if (selectedDate) {
                            setSearchValue({
                              ...searchValue,
                              eventStart: selectedDate,
                            });
                          } else {
                            setSearchValue({
                              ...searchValue,
                              eventStart: null,
                            });
                          }
                        }}
                        slotProps={{
                          actionBar: {
                            actions: ["clear"],
                          },
                        }}
                        renderInput={(props) =>
                          renderInputs(props, "From date", "date")
                        }
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="from_date"
                        value={
                          searchValue.eventEnd == ""
                            ? null
                            : searchValue.eventEnd
                        }
                        inputFormat="DD/MM/YYYY"
                        onChange={(e) => {
                          setSearchValue({ ...searchValue, eventEnd: e.$d });
                        }}
                        renderInput={(props) =>
                          renderInputs(props, "To date", "date")
                        }
                      />
                    </LocalizationProvider>
                  </div>
                )}
              </div>
            </div>
            <div className="downloadoptions">
              <Button
                variant="contained"
                onClick={() => {
                  setDownloadTab((k) => !k);
                  setIsConsolidatedCpr((prevState) => !prevState);
                  setDateSearch("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={dateSearch == ""}
                onClick={() =>
                  !isConsolidatedCpr
                    ? handleuploadExcel()
                    : consolidatedSummary()
                }
              >
                Download
              </Button>{" "}
              {/*onClick={handleuploadExcel}*/}
            </div>
          </div>
        </div>
      )}

      <TableComponent
        header={header}
        // addList={addStates}
        datalist={campList}
        datalength={length}
        actions={[]}
        editData={(id) => editData(id)}
        deleteData={(id) => deleteDate(id)}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        handleTablePage={handleTablePage}
        handleTableRowsPerPage={handleTableRowsPerPage}
        dateFields={dateFields}
        filter={"camp_perform"}
        filterResponseValue={(val) => filterResponse(val)}
        filterFwTypee={(e) => searchFwtype(e)}
      ></TableComponent>

      {/* backdrop */}
      <BackDrop open={backDropOpen} />
    </>
  );
};

export default Camp_performance;
