import React from 'react'

import { Box, Paper, Typography } from '@mui/material';

import CoverageHome from './CoverageIndicator/CoverageHome'
import ReferralHome from './ReferalConversionReport/ReferralHome'
import YellowCardHome from './YellowCard/YellowCardHome'

const Home = ( { coverage, referral, yellowCardOrder }) => {

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap',justifyContent:'space-between', '& > :not(style)': {   width: "30%", height: "55vh", mt: '2%', mb: '2%' } }}        >
      <Paper elevation={1} sx={{border:'1px solid #e4e4e4' }}>
          <p style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '5%', fontWeight: 'bold' }}>Coverage Indicator</p>
          <CoverageHome coverage={coverage} />
      </Paper>
      <Paper elevation={1} sx={{border:'1px solid #e4e4e4' }}>
          <ReferralHome referral={referral} />
      </Paper>
      <Paper elevation={1} sx={{border:'1px solid #e4e4e4',overflow:'hidden' }}>
          <YellowCardHome yellowCardOrder={yellowCardOrder} />
      </Paper>
    </Box>
  )
}

export default Home