import React, { useState, useEffect } from "react"
import format from "date-fns/format";

import { useAxios } from "../hooks/useAxios";
import './Style.css'

import { Formik } from "formik";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Male, FemaleTwoTone, TwoWheeler, Visibility, TransgenderTwoTone, Colorize, Bolt, Diversity1Rounded, LocalHospitalSharp, ThumbUp } from '@mui/icons-material';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import { BiRename } from 'react-icons/bi';
import { AiOutlineCaretRight, AiOutlineCaretLeft } from 'react-icons/ai';

import { LocalHospital, Festival, HowToReg, RestartAlt } from "@mui/icons-material";

import { FieldWorkerMap, CampMap, HospitalMap } from "./Maps";

import { Typography, TextField, MenuItem, InputLabel, Button, Input, Autocomplete } from "@mui/material";

import { createFilterOptions } from "@mui/material/Autocomplete";
import cataractdia from '../assets/cataractdia.png'
import cataracthyper from '../assets/cataracthyper.png'
import cataracthyperdia from '../assets/cataracthyperdia.png'
import diahper from '../assets/diahyper.png'

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

const state = {
  fromDate: "",
  toDate: "",
  hospital_id: [],
  field_worker_id: "",
  camp_id: [],
  village_id: '',
}
const dateFormat = process.env.REACT_APP_DATE_FORMAT;

const renderInput = (props, placeholder) => (
  <Input
    sx={{
      input: {
        "&::placeholder": {    // <----- Add this.
          opacity: 1,
          marginBottom: '-5px',
        },
      },
      backgroundColor: '#ffffff',
      borderRadius: '5px',

    }}
    type="text"
    className="svgs"
    inputRef={props.inputRef}
    // inputProps={props.inputProps}
    inputProps={{
      ...props.inputProps,
      placeholder: placeholder
    }}
    value={props.value}
    onClick={props.onClick}
    onChange={props.onChange}
    endAdornment={props.InputProps?.endAdornment}
  />
);

const filter = createFilterOptions();

const renderInputAutoComplete = (props) => {
  <Input
    sx={{ backgroundColor: '#ffffff' }}
    type="text"
    className="svgs"
    inputRef={props.inputRef}
    inputProps={props.inputProps}
    // inputProps={{
    //   ...props.inputProps,
    //   placeholder: placeholder
    // }}
    value={props.value}
    onClick={props.onClick}
    onChange={props.onChange}
    endAdornment={props.InputProps?.endAdornment}
  />
}

const Gis_report = ({ }) => {
  const { axios } = useAxios();

  const [expand, setExpand] = useState(1)
  const [reset, setReset] = useState(false)
  const [initialValues, setInitialValue] = useState(state);
  const [trackingDate, setTrackingDate] = useState()
  const [hospitals, setHospitals] = useState(null)        //hospital dropdown
  const [villages, setVillages] = useState([])


  const dates = {
    minDate: null,
    maxDate: null
  }

  const [date, setDate] = useState(dates)

  //camp list on camp map
  const camps = {
    vhv: [],
    cbbf: []
  }
  const [camp, setCamp] = useState(camps)
  const [fieldreset, setFieldreset] = useState(5)
  const [campreset, setCampreset] = useState(1)

  //fieldworkers list on field worker map

  const fW = {
    vhv: null,
    cbbf: null
  }

  const [fieldWorkers, setFieldWorkers] = useState(fW)



  const ill = {
    cataract: null,
    surgerydonedetails: null,
    diabetes: null,
    none: null,
    hypertension: null,
    asthma: null,
    cataractDia: null,
    cataractHyper: null,
    HyperDia: null,
    cataractHyperDia: null
  }

  const [illness, setIllness] = useState({ ...ill })                     //common illness
  const [hospitalData, setHospitalData] = useState(null)               // hospital name and other detail
  const [houseHoldData, setHouseHoldData] = useState(null)             // household detail
  const [campData, setCampData] = useState(null)                       // campdata to google map
  const [campDetail, setCampDetail] = useState(null)                   // camp detail to show panel
  const [campDataDetail, setcampDataDetail] = useState([])             //hospital related camp detail
  const [campRHouse, setcampRhouse] = useState(true)                   // camp and house detail


  const [allTrackData, setAllTrackData] = useState(null)                // all days fieldworker data to google map
  const [trackDetail, setTrackDetail] = useState(0)                     // field worker detail for right panel
  const [daytrack, setDayTrack] = useState(null)                        // particular date tracking date
  const [fieldWorkerName, setfieldWorkerName] = useState(null)          // fieldworker name detail
  const [hosCamp, sethosCamp] = useState([])             //hospital camp data

  const shifts = {                      // shiftin-out detail
    shiftIn: null,
    shiftout: null
  }

  const [shiftdetail, setshiftdetail] = useState(shifts)

  const patientsD = {                // hospital/camp patient details
    vhv: null,
    cbbf: null,
    male: 0,
    female: 0,
    others: 0,
    household: 0,
    individual: 0,
    camp: 0
  }

  const [patientDetails, setPatientDetails] = useState(patientsD)

  const MAP_RESTRICTION = {                                             // world map bound restriction 
    latLngBounds: {
      north: 37.6,
      south: 6.4,
      west: 68.7,
      east: 97.25
    }
  }

  const [selectedIll, setSelectedIll] = useState(null)

  useEffect(() => {
    var hospital = localStorage.getItem('hospital_id');
    var role_id = localStorage.getItem('role_id');
    if (role_id == "7") {
      var data;
      data = {
        hospital_id: [hospital]
      }
      loadHospital(data)
      handleFieldWorkerDropdown(data)
      handleCampDropdown(data);
      loadVillages(data)
      initialValues.hospital_id.push(hospital)

    } else {
      var dates = {
        fromDate: new Date(),
        toDate: new Date()
      }
      setTimeout(() => {
        loadTodayCamp(dates)
      }, 2000)


      // campSurveyList(dates)
      loadHospital(initialValues)
      handleFieldWorkerDropdown(initialValues)
      handleCampDropdown(initialValues)
      loadVillages(initialValues)
    }

  }, [reset])


  // campSurveyList
  useEffect(() => {
    if (initialValues.hospital_id?.length != 0 && Array.isArray(initialValues.hospital_id) && initialValues.hospital_id[0] != "1") {
      if (initialValues.hospital_id != "1") {
        var data = {
          hospital_id: initialValues.hospital_id,
        }
        handleIllness(data)
      }
    }
    if (initialValues.camp_id?.length != 0 && initialValues.hospital_id?.length != 0) {
      if (initialValues.hospital_id != "1") {
        var data = {
          hospital_id: initialValues.hospital_id,
          camp_id: initialValues.camp_id
        }
        handleIllness(data)
      }
    }
    if (initialValues.camp_id?.length != 0 && Array.isArray(initialValues.camp_id)) {
      if (initialValues?.camp_id[0] !== "1" && initialValues?.camp_id[0] !== "2") {
        var data = {
          camp_id: initialValues.camp_id
        }
      }
      handleIllness(data)
    }

    if (initialValues.field_worker_id != "") {
      var data = {
        field_worker_id: initialValues.field_worker_id
      }
      handleIllness(data)
    }

    if (initialValues.fromDate != "" && initialValues.toDate != "" && initialValues.camp_id == "" && initialValues.hospital_id == "" && initialValues.village_id == "") {
      var dates = {
        fromDate: initialValues.fromDate,
        toDate: initialValues.toDate
      }
      loadHospital(dates)
      handleCampDropdown(dates)
    }


  }, [initialValues])

  useEffect(() => {
    if (initialValues.village_id != "") {
      var data = {
        village_id: initialValues.village_id
      }
      handleIllness(data, 'local')
    }

  }, [initialValues])

  async function loadHospital(list) {
    await axios.post('getGISHospitalList', list).then((res) => {
      var hospitals = res.data.result.result;

      var select = { "hospital_name": "Select All", "hospital_id": "1" }
      hospitals.unshift(select)

      setHospitals(hospitals)
    })
  }


  async function loadTodayCamp(data) {
    await axios.post('getCampSurveyList', data).then((res) => {
      var data_ = res.data.result
      sethosCamp(data_)
    })
  }


  async function loadVillages(data) {
    await axios.post('getVillageList', data).then((res) => {
      var data = res.data.result[0].village
      setVillages(data);
    })
  }


  async function handleIllness(data, ill) {

    await axios.post('getIllnessList', data).then((res) => {
      var value = res.data.result.result
      setIllness({
        ...illness,
        diabetes: value.FinalD[0] != undefined ? value.FinalD[0] : 0,
        none: value.None != undefined ? value.None : 0,
        hypertension: value.FinalH[0] != undefined ? value.FinalH[0] : 0,
        asthma: value.Asthma != undefined ? value.Asthma : 0,
        surgerydonedetails: value.surgeryDoneDetails != undefined ? value.surgeryDoneDetails : 0,
        cataract: value.FinalC[0] != undefined ? value.FinalC[0] : 0,
        cataractDia: value.FinalCD[0] != undefined ? value.FinalCD[0] : 0,
        cataractHyperDia: value.FinalCDH[0] != undefined ? value.FinalCDH[0] : 0,
        cataractHyper: value.FinalCH[0] != undefined ? value.FinalCH[0] : 0,
        HyperDia: value.FinalDH[0] != undefined ? value.FinalDH[0] : 0
      })

      return value

    })
  }

  async function campSurveyList(data) {
    await axios.post('getCampSurveyList', data).then((res) => {
      var data_ = res.data.result
      setCampData(data_)

    })
  }

  async function getFieldTrack(data) {
    await axios.post('getWorkerList', data).then((res) => {
      var trackingdata = res.data.result.result
      setAllTrackData(trackingdata)

      var dates = [];
      trackingdata?.map((i) => dates.push(i._id))

      // Convert the date strings to Date objects
      const dateObjects = dates.map(date => new Date(date.split('-').reverse().join('-')));

      // Find the minimum and maximum dates
      const minDate = new Date(Math.min.apply(null, dateObjects));
      const maxDate = new Date(Math.max.apply(null, dateObjects));

      // Format the dates as strings
      const minDateString = `${minDate.getDate()}-${minDate.getMonth() + 1}-${minDate.getFullYear()}`;
      const maxDateString = `${maxDate.getDate()}-${maxDate.getMonth() + 1}-${maxDate.getFullYear()}`;

      setDate({ ...date, minDate: minDate, maxDate: maxDate })

    })
  }

  useEffect(() => { }, [initialValues])

  async function handleReset() {
    setInitialValue({ ...state })
    setCamp({ ...camps })
    setDate({ ...dates })
    // setVillages([])
    // setHospitals(null)
    setTrackingDate(null)
    setFieldWorkers({ ...fW })
    setIllness({ ...ill })
    setHospitalData(null)
    setHouseHoldData(null)
    setCampData(null)
    setCampDetail(null)
    setcampDataDetail([])
    setcampRhouse(true)
    setAllTrackData(null)
    setTrackDetail(0)
    setDayTrack(null)
    setfieldWorkerName(null)
    setshiftdetail({ ...shifts })
    setPatientDetails({ ...patientsD })
    setSelectedIll(null)
    // setFieldWorkers(fieldWorkers +1 )
    setReset(!reset)
    setCampreset(campreset + 0.1)
    setFieldreset(fieldreset + 1)
  }



  const disableDates = (date) => {
    return moment(date).format('DD') > 10 && moment(date).format('DD') < 18
  }

  async function handleCampDropdown(list) {
    axios.post('getGISCampList', list).then((res) => {
      var camps = res.data.result.result


      var vhvvalue = camps?.find((i) => i.camp_type === 'VHV');
      var cbbfvalue = camps?.find((i) => i.camp_type === 'CBBF');

      vhvvalue = vhvvalue == undefined ? {} : vhvvalue;
      cbbfvalue = cbbfvalue == undefined ? {} : cbbfvalue;



      var select = { "camp_place": "Select All", "camp_id": "1" }
      vhvvalue?.campList?.unshift(select)
      cbbfvalue?.campList?.unshift(select)

      setCamp({ ...camp, vhv: vhvvalue, cbbf: cbbfvalue })
    })
  }

  async function handleFieldWorkerDropdown(data) {

    await axios.post('getGISFieldWorkerList', data).then((res) => {
      var field_w = res.data.result.result

      var vhvvalue = field_w?.find((i) => i.field_worker_type === 'VHV');
      var cbbfvalue = field_w?.find((i) => i.field_worker_type === 'CBBF');

      vhvvalue = vhvvalue == undefined ? {} : vhvvalue;
      cbbfvalue = cbbfvalue == undefined ? {} : cbbfvalue;

      setFieldWorkers({ ...fieldWorkers, vhv: vhvvalue, cbbf: cbbfvalue })

    })

  }

  async function handleChangeHospital(e, data) {

    var value = data?.hospital_id
    if (value) {

      setInitialValue({ ...state })


      if (value == "1") {
        setHospitalData(hospitals)
        let allHospital = [];


        // var removeSelect = hospitals?.shift();
        // hospitals?.map((i) => {

        //   allHospital.push(i.hospital_id)
        // })



        hospitals?.map((i) => {
          if (i?.hospital_id != "1") {
            allHospital.push(i.hospital_id)
          }
        })

        var hos = {
          hospital_id: allHospital
        }

        handleFieldWorkerDropdown(hos)
        handleIllness(hos)
        patientsCount(hos)
        handleCampDropdown(hos)

        setInitialValue((i) => ({
          ...i, hospital_id: [value]
        }))

      } else {

        setInitialValue((i) => ({
          ...i, hospital_id: [value]
        }))

        var p_load = {
          hospital_id: [value]
        }

        handleFieldWorkerDropdown(p_load)
        handleCampDropdown(p_load)

        var val = hospitals.filter((i) => { if (i.hospital_id == value) { return i } })
        setHospitalData(val)
        campSurveyList(p_load);

        handleIllness(p_load)
        patientsCount(p_load)
      }

    }

  }

  async function patientsCount(h_data) {
    await axios.post('getMembersCountList', h_data).then((res) => {
      var data = res.data.result
      if (Object.keys(data).length == 0) {
        vhvmale = 0
        cbbfmale = 0
        vhvfemale = 0
        cbbffemale = 0
        vhvother = 0
        cbbfother = 0
        vhvhousehold = 0
        cbbfhousehold = 0
        vhvcamp = 0
        cbbfcamp = 0
      } else {
        var vhvdata = Object.keys(data['VHV'])?.length == 0 ? 0 : data['VHV']
        var cbbfdata = Object.keys(data['CBBF'])?.length == 0 ? 0 : data['CBBF']

        var vhvmale = vhvdata?.total_males != undefined ? vhvdata?.total_males : 0;
        var cbbfmale = cbbfdata?.total_males != undefined ? cbbfdata?.total_males : 0;

        var vhvfemale = vhvdata?.total_females != undefined ? vhvdata?.total_females : 0;
        var cbbffemale = cbbfdata?.total_females != undefined ? cbbfdata?.total_females : 0;

        var vhvother = vhvdata?.total_others != undefined ? vhvdata?.total_others : 0;
        var cbbfother = cbbfdata?.total_others != undefined ? cbbfdata?.total_others : 0;

        var vhvhousehold = vhvdata?.totalHouseHold != undefined ? vhvdata?.totalHouseHold : 0;
        var cbbfhousehold = cbbfdata?.totalHouseHold != undefined ? cbbfdata?.totalHouseHold : 0;

        var vhvIndividualHouseHold = vhvdata?.totalIndividualHouseHold != undefined ? vhvdata?.totalIndividualHouseHold : 0;
        var cbbfIndividualHouseHold = cbbfdata?.totalIndividualHouseHold != undefined ? cbbfdata?.totalIndividualHouseHold : 0;

        var vhvcamp = vhvdata?.totalCamp != undefined ? vhvdata?.totalCamp : 0;
        var cbbfcamp = cbbfdata?.totalCamp != undefined ? cbbfdata?.totalCamp : 0;

        vhvmale = vhvmale != undefined ? vhvmale : 0
        cbbfmale = cbbfmale != undefined ? cbbfmale : 0
        vhvfemale = vhvfemale != undefined ? vhvfemale : 0
        cbbffemale = cbbffemale != undefined ? cbbffemale : 0
        vhvother = vhvother != undefined ? vhvother : 0
        cbbfother = cbbfother != undefined ? cbbfother : 0
        vhvhousehold = vhvhousehold != undefined ? vhvhousehold : 0
        cbbfhousehold = cbbfhousehold != undefined ? cbbfhousehold : 0
        vhvcamp = vhvcamp != undefined ? vhvcamp : 0
        cbbfcamp = cbbfcamp != undefined ? cbbfcamp : 0
      }
      setPatientDetails({
        ...patientDetails,
        male: vhvmale + cbbfmale,
        female: vhvfemale + cbbffemale,
        others: vhvother + cbbfother,
        household: vhvhousehold + cbbfhousehold,
        camp: vhvcamp + cbbfcamp,
        individual: vhvIndividualHouseHold + cbbfIndividualHouseHold
      })

    })
  }


  function handleDate() {
    if (initialValues.hospital_id == "" && initialValues.camp_id == "") {
      if (initialValues.fromDate == "" && initialValues.toDate == "") {
        const dateConverter = (dateIn) => {
          dateIn.setHours(12);
          let dateFor10days = new Date(dateIn.setDate(dateIn.getDate() - 10));
          return dateFor10days;
        }

        let today = new Date();
        let last10Day = dateConverter(today)

        setInitialValue({ ...initialValues, fromDate: last10Day, toDate: today })
      }
    }
  }

  async function handleCampAndFieldWorker(e, camptype, val) {
    setTrackingDate(null)

    setInitialValue({
      ...initialValues,
      camp_id: []
    })

    var value = val?.camp_id

    if (value) {

      if (value == '1' || value == "2") {

        let allCamp = [];
        camp[camptype]?.campList?.map((i) => {
          if (i?.camp_id != '1') {

            allCamp.push(i.camp_id)
          }
        })

        var val = [];
        val.push(value)

        setInitialValue({
          ...initialValues, camp_id: val,
          field_worker_id: ""
        })

        var a_camps = {
          camp_id: allCamp
        }
        campSurveyList(a_camps)
        handleIllness(a_camps)
        handleFieldWorkerDropdown(a_camps)

      } else {

        var camp_d = camp[camptype]?.campList.find((i) => { if (i.camp_id == value) { return i } })

        var hospital_data = hospitals?.find((i) => { if (i.hospital_id == camp_d.hospital_id) { return i } })

        var d = {
          camp_detail: camp_d,
          hospital_detail: hospital_data
        }

        setCampDetail(camp_d)

        var val = [];
        val.push(value)

        setInitialValue({
          ...initialValues, camp_id: val,
          field_worker_id: ""
        })


        var camplist = {}
        if (initialValues.hospital_id?.length == 0) {
          camplist = {
            camp_id: [value]
          }
        } else if (initialValues.hospital_id != "1") {
          camplist = {
            hospital_id: initialValues.hospital_id,
            camp_id: [value]
          }
        } else {
          camplist = {
            camp_id: [value]
          }
        }
        handleIllness(camplist)
        patientsCount(camplist)

        handleFieldWorkerDropdown(camplist)
        campSurveyList(camplist);
        getFieldTrack(camplist)
      }
    }

  }


  async function handleFieldWorkersTrack(e, field_type, value) {

    var val = value.field_worker_id

    setTrackingDate(null)

    setInitialValue({ ...initialValues, field_worker_id: val })

    var fieldworkerid = "";

    if (initialValues.camp_id.length == 0) {
      fieldworkerid = {
        field_worker_id: val
      }
    } else {

      fieldworkerid = {
        field_worker_id: val,
        camp_id: initialValues.camp_id != "1" ? initialValues.camp_id : ""
      }

    }

    var name = fieldWorkers[field_type]?.filed_workerList?.find(i => { if (i.field_worker_id == val) { return i } })
    setfieldWorkerName(name.field_worker_name)

    getFieldTrack(fieldworkerid)

    patientsCount(fieldworkerid)
  }

  function villageChange(e, val) {

    var value = val?.village_id
    if (value == null) {
      value = ""
    }
    setInitialValue({ ...initialValues, village_id: value })

  }

  function handleDetail(val, type) {
    if (type == 'household') {
      setcampRhouse(false)

      setHouseHoldData(val)
    } else {
      setcampRhouse(true)
      setcampDataDetail(val)
    }
  }

  function firstLetter(s) {
    return s?.replace(/^.{1}/g, s[0].toUpperCase());
  }

  function handleTrackingDate(e) {
    setTrackingDate(e.$d)
    var date = format(new Date(e.$d), dateFormat)
    var dateValue = new Date(new Date(e.$d).setHours(6, 0, 0))

    var list = {
      field_worker_id: initialValues.field_worker_id,
      fromDate: dateValue,
      toDate: dateValue

    }

    patientsCount(list)

    date = date?.replaceAll('/', '-')

    var detail = allTrackData?.find((i) => { if (i._id == date) { return i } })

    setDayTrack(detail)

    setTrackDetail(detail)

    var shiftin = detail?.coords?.find((i) => { if (i.event.replace(/ /g, "").toLowerCase() == 'punchin') { return i } })
    var shiftout = detail?.coords?.find((i) => { if (i.event.replace(/ /g, "").toLowerCase() == 'punchout') { return i } })

    var inn;
    var out;

    inn = shiftin != undefined ? convertTime(shiftin?.loc_entry_time) : ""
    out = shiftout != undefined ? convertTime(shiftout?.loc_entry_time) : ""

    //================= if convert into indian standart time ==================

    // dayjs.extend(utc);

    // var inn = shiftin != undefined ? dayjs.utc(shiftin?.loc_entry_time).local().format('h:mm A') : null;
    // var out = shiftout != undefined ? dayjs.utc(shiftout?.loc_entry_time).local().format('h:mm A') : null;

    // ======================================================================


    setshiftdetail({ ...shiftdetail, shiftIn: inn, shiftout: out })

  }


  function convertTime(time) {
    var currentDate = new Date(time);
    var hours = currentDate.getUTCHours();
    var minutes = currentDate.getUTCMinutes();

    var amOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // Convert 0 to 12

    var time = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ' ' + amOrPm;

    return time
  }

  function handleselectedHospital(value) {
    campSurveyList(value);
    handleCampDropdown(value)
    handleFieldWorkerDropdown(value)
    handleIllness(value)
    patientsCount(value)

    setInitialValue({
      ...initialValues, hospital_id: value.hospital_id
    })

  }

  function handleHeatMap(ill) {
    setSelectedIll(ill)
  }

  // function handleSearch(){

  // }


  return (
    <>

      <Formik
        initialValues={initialValues}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <div>
            <Typography variant="h4" color="secondary" className="text-left">
              GIS Report
            </Typography>

            <div className='map_section'>
              <div className="maps_">
                <div className={expand == 1 ? "map_enlarge" : 'map_mini'} onClick={() => setExpand(1)}>
                  <div className="title_">
                    <div className='icons_'>
                      <LocalHospital className="svg" />
                      <h3>Hospitals</h3>
                    </div >
                  
                    <Autocomplete
                      size="small"
                      disablePortal
                      className="hide"
                      variant='standard'
                      id="combo-box-demo"
                      sx={{
                        input: {
                          "&::placeholder": {    // <----- Add this.
                            opacity: 1,
                            marginBottom: '-5px',
                          },
                        },
                        backgroundColor: '#ffffff',
                        borderRadius: '5px',
                        width: '18%'
                      }}

                      ListboxProps={{
                        sx: { fontSize: 13 },
                      }}
                      options={hospitals?.length > 0 ? hospitals : [{ "hospital_name": "No data found" }]}
                      defaultValue={initialValues.hospital_id?.length == 0 ? null : initialValues.hospital_id[0]}
                      key={reset}
                      getOptionLabel={(option) => option.hospital_name}
                      onChange={(data, value) => { handleChangeHospital(data, value) }}
                      renderInput={(params) => <TextField {...params} color="black" InputLabelProps={{
                        style: {
                          fontSize: '13px',  // Adjust the font size of the label here
                          paddingTop: '5px'
                        }
                      }} label="Select Hospitals" />}
                    />

                    <div className="hide dateborder"  >
                      <LocalizationProvider size="small" dateAdapter={AdapterDayjs}  >
                        <DatePicker className="from_date"
                          sx={{ border: '1px solid #000000' }}
                          // TextFieldProps={{ variant: 'standard' }}
                          value={initialValues.fromDate == "" ? null : initialValues.fromDate}
                          inputFormat="DD/MM/YYYY"
                          onChange={(e) => {
                            const selectedDate = e.$d;
                            if (selectedDate) {
                              setInitialValue({ ...initialValues, fromDate: selectedDate });
                            } else {
                              setInitialValue({ ...initialValues, fromDate: null });
                            }
                          }}
                          slotProps={{
                            actionBar: {
                              actions: ['clear']
                            }
                          }}
                          renderInput={(props) => renderInput(props, 'From date')}
                          style={{ border: '1px solid', borderRadius: '4px', padding: '6px 10px' }}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="hide dateborder">
                      <LocalizationProvider size="small" dateAdapter={AdapterDayjs}>
                        <DatePicker className="from_date"
                          value={initialValues.toDate == "" ? null : initialValues.toDate}
                          inputFormat="DD/MM/YYYY"
                          onChange={(e) => {
                            setInitialValue({ ...initialValues, toDate: e.$d });

                          }}
                          renderInput={(props) => renderInput(props, 'To date')}

                        // renderInput={(params) => (
                        //   <TextField
                        //     {...params}
                        //     label='To Date'
                        //     variant='standard'
                        //     fullWidth
                        //   />
                        // )}
                        />
                      </LocalizationProvider>
                    </div>
                    {/* <RestartAlt onClick={() => handleReset()} /> */}
                    {/* <Button variant="contained" sx={{ float: "right", m: 1, width: "8ch" }} onClick={() => handleSearch()} className='search_btn reset' > Submit</Button>  onClick={() => handleReset()} */}
                    <Button variant="contained" sx={{ float: "right", m: 1, width: "8ch", textTransform: 'none' }} onClick={() => handleReset()} className='search_btn reset' > Reset</Button> {/*  onClick={() => handleReset()} */}
                  </div>
                  <HospitalMap reset={reset} camp_list={hosCamp} restriction={MAP_RESTRICTION} hospitalData={hospitalData} heatMap={illness} selectedIllness={selectedIll} selectedHospital={(data) => handleselectedHospital(data)} />   {/* camplists={camplists} handleHospital={(id) => handleSearch(id)} hospitalData={(val) => handlehospitalData(val)}  hospitalsLatLng={hospital_lat_lan} */}
                </div>
                <div className={expand == 2 ? "map_enlarge" : 'map_mini'} onClick={() => { setExpand(2); }}>
                  <div className="title_">
                    <div className="icons_">
                      <Festival className="svg" />
                      <h3>Camps</h3>
                    </div >
                    <Autocomplete
                      size="small"
                      disablePortal
                      className="hide"
                      variant='standard'
                      id="combo-box-demo"
                      key={reset}
                      sx={{
                        input: {
                          "&::placeholder": {    // <----- Add this.
                            opacity: 1,
                            marginBottom: '-5px',
                          },
                        },
                        backgroundColor: '#ffffff',
                        borderRadius: '5px',
                        width: '18%'
                      }}

                      ListboxProps={{
                        sx: { fontSize: 13 },
                      }}
                      options={camp?.vhv?.campList?.length > 0 ? camp?.vhv?.campList : [{ "camp_place": "No data found" }]}
                      // defaultValue={initialValues.camp_id?.length == 0 ? null : initialValues.camp_id[0]}
                      getOptionLabel={(option) => option.camp_place}
                      onChange={(data, value) => { handleCampAndFieldWorker(data, 'vhv', value) }}
                      renderInput={(params) => <TextField {...params} color="black" InputLabelProps={{
                        style: {
                          fontSize: '13px',  // Adjust the font size of the label here
                          paddingTop: '5px'
                        }
                      }} label="Select VHV Camp" />}
                    />
                    <Autocomplete
                      size="small"
                      disablePortal
                      className="hide"
                      variant='standard'
                      id="combo-box-demo"
                      sx={{
                        input: {
                          "&::placeholder": {    // <----- Add this.
                            opacity: 1,
                            marginBottom: '-5px',
                          },
                        },
                        backgroundColor: '#ffffff',
                        borderRadius: '5px',
                        width: '18%'
                      }}
                      key={campreset}
                      ListboxProps={{
                        sx: { fontSize: 13 },
                      }}
                      options={camp?.cbbf?.campList?.length > 0 ? camp?.cbbf?.campList : [{ "camp_place": "No data found" }]}
                      // defaultValue={initialValues.camp_id?.length == 0 ? null : initialValues.camp_id[0]}
                      getOptionLabel={(option) => option.camp_place}
                      onChange={(data, value) => { handleCampAndFieldWorker(data, 'cbbf', value) }}
                      renderInput={(params) => <TextField {...params} color="black" InputLabelProps={{
                        style: {
                          fontSize: '13px',  // Adjust the font size of the label here
                          paddingTop: '5px'
                        }
                      }} label="Select CBBF Camp" />}
                    />

                    <div className="hide dateborder" >
                      <LocalizationProvider size="small" dateAdapter={AdapterDayjs}>
                        <DatePicker className="from_date"
                          // value={initialValues.fromDate}
                          value={initialValues.fromDate == "" ? null : initialValues.fromDate}
                          inputFormat="DD/MM/YYYY"
                          onChange={(e) => {
                            setInitialValue({ ...initialValues, fromDate: e.$d });

                          }}
                          slotProps={{
                            actionBar: {
                              actions: ['clear']
                            }
                          }}
                          renderInput={(props) => renderInput(props, 'From date')}

                        // renderInput={(params) => (
                        //   <TextField
                        //     {...params}
                        //     label='From Date'
                        //     variant='standard'
                        //     fullWidth
                        //   />
                        // )}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="hide dateborder">
                      <LocalizationProvider size="small" dateAdapter={AdapterDayjs}>
                        <DatePicker className="from_date"
                          // value={initialValues.toDate}
                          value={initialValues.toDate == "" ? null : initialValues.toDate}
                          inputFormat="DD/MM/YYYY"
                          onChange={(e) => {
                            setInitialValue({ ...initialValues, toDate: e.$d });

                          }}
                          renderInput={(props) => renderInput(props, 'To date')}

                        />
                      </LocalizationProvider>
                    </div>

                    <Button variant="contained" sx={{ float: "right", m: 1, width: "10ch", textTransform: 'none' }} onClick={() => handleReset()} className='search_btn reset'  >  Reset</Button>
                  </div>
                  <CampMap reset={reset} camp_list={campData} heatMap={illness} detailData={(val, type) => handleDetail(val, type)} />
                </div>
                <div className={expand == 3 ? "map_enlarge" : 'map_mini'} onClick={() => { setExpand(3); }}>
                  <div className="title_">
                    <div className="icons_">
                      <HowToReg className="svg" />
                      <h3>Field Workers</h3>
                    </div>
                    <Autocomplete
                      size="small"
                      disablePortal
                      className="hide"
                      variant='standard'
                      id="combo-box-demo"
                      sx={{
                        input: {
                          "&::placeholder": {    // <----- Add this.
                            opacity: 1,
                            marginBottom: '-5px',
                            fontSize: '10px'
                          },

                        },
                        backgroundColor: '#ffffff',
                        borderRadius: '5px',
                        width: '18%'
                      }}
                      key={reset}

                      ListboxProps={{
                        sx: { fontSize: 13 },
                      }}
                      options={fieldWorkers?.vhv?.filed_workerList?.length > 0 ? fieldWorkers?.vhv?.filed_workerList : [{ "field_worker_name": "No data found" }]}
                      getOptionLabel={(option) => option.field_worker_name}
                      onChange={(data, value) => { handleFieldWorkersTrack(data, 'vhv', value) }}
                      renderInput={(params) => <TextField {...params} InputLabelProps={{
                        style: {
                          fontSize: '13px',  // Adjust the font size of the label here
                          paddingTop: '5px'
                        }
                      }} color="black" label="Select VHV FieldWorker" />}
                    />

                    <Autocomplete
                      size="small"
                      disablePortal
                      className="hide"
                      variant='standard'
                      id="combo-box-demo"
                      sx={{
                        input: {
                          "&::placeholder": {    // <----- Add this.
                            opacity: 1,
                            marginBottom: '-5px',
                          },
                        },
                        backgroundColor: '#ffffff',
                        borderRadius: '5px',
                        width: '18%'
                      }}
                      // key={initialValues.field_worker_id}
                      key={fieldreset}
                      ListboxProps={{
                        sx: { fontSize: 13 },
                      }}
                      options={fieldWorkers?.cbbf?.filed_workerList?.length > 0 ? fieldWorkers?.cbbf?.filed_workerList : [{ "field_worker_name": "No data found" }]}
                      getOptionLabel={(option) => option.field_worker_name}
                      onChange={(data, value) => { handleFieldWorkersTrack(data, 'cbbf', value) }}
                      renderInput={(params) => <TextField {...params} color="black" InputLabelProps={{
                        style: {
                          fontSize: '13px',  // Adjust the font size of the label here
                          paddingTop: '5px'
                        }
                      }} label="Select CBBF FieldWorker" />}
                    />

                    {
                      date.minDate == null && date.maxDate == null
                        ?
                        <>
                          <div className="hide dateborder" >
                            {/* <Typography variant="subtitle2" textAlign={'left'} gutterBottom>From</Typography> */}
                            <LocalizationProvider size="small" dateAdapter={AdapterDayjs}>
                              <DatePicker className="from_date"
                                // value={initialValues.fromDate}
                                value={initialValues.fromDate == "" ? null : initialValues.fromDate}
                                inputFormat="DD/MM/YYYY"
                                onChange={(e) => {
                                  setInitialValue({ ...initialValues, fromDate: e.$d });

                                }}
                                disabled={initialValues.fromDate == "" && initialValues.toDate == ""}
                                // disabled={!values.camp_date}
                                minDate={date?.minDate}
                                maxDate={date?.maxDate}
                                renderInput={(props) => renderInput(props, 'From date')}

                              // renderInput={(params) => (
                              //   <TextField
                              //     {...params}
                              //     sx={{ marginTop: '5px' }}
                              //     label='From Date'
                              //     variant='standard'
                              //     fullWidth
                              //   />
                              // )}
                              />
                            </LocalizationProvider>
                          </div>
                          <div className="hide dateborder">
                            <LocalizationProvider size="small" dateAdapter={AdapterDayjs}>
                              <DatePicker className="from_date"
                                // value={initialValues.toDate}
                                value={initialValues.toDate == "" ? null : initialValues.toDate}
                                inputFormat="DD/MM/YYYY"
                                onChange={(e) => {
                                  setInitialValue({ ...initialValues, toDate: e.$d });

                                }}

                                // disabled={!values.camp_date}
                                disabled={initialValues.fromDate == "" && initialValues.toDate == ""}
                                minDate={date?.minDate}
                                maxDate={date?.maxDate}
                                renderInput={(props) => renderInput(props, 'To date')}
                              // renderInput={(params) => (
                              //   <TextField
                              //     {...params}
                              //     label='To Date'
                              //     variant='standard'
                              //     fullWidth
                              //   />
                              // )}
                              />
                            </LocalizationProvider>
                          </div>
                        </>
                        :
                        <div className="hide dateborder">
                          <LocalizationProvider size="small" dateAdapter={AdapterDayjs}>
                            <DatePicker className="from_date"
                              value={trackingDate}
                              inputFormat="DD/MM/YYYY"
                              onChange={(e) => {
                                handleTrackingDate(e)
                              }}

                              disabled={initialValues.field_worker_id == ""}
                              minDate={date?.minDate}
                              maxDate={date?.maxDate}
                              renderInput={(props) => renderInput(props, 'date')}

                            // renderInput={(params) => (
                            //   <TextField
                            //     {...params}
                            //     label='Date'
                            //     variant='standard'
                            //     fullWidth
                            //   />
                            // )}
                            />
                          </LocalizationProvider>
                        </div>
                    }

                    {/* <RestartAlt onClick={() => handleReset()} /> */}
                    <Button variant="contained" sx={{ float: "right", m: 1, width: "10ch", textTransform: 'none' }} onClick={() => handleReset()} className='search_btn reset' >  Reset</Button>

                    {/* <Button variant="contained" sx={{ float: "right", m: 1, width: "10ch" }} className='search_btn' >  Search</Button>  onClick={() => { handleMarker(); }} */}
                  </div>
                  <FieldWorkerMap reset={reset} tracking={daytrack} fieldWorkersTrack={fieldWorkers} /> {/*TrackingDetail={(val) =>   } TrackingDetail={(val) => handletrackDetail(val)}  */}
                </div>
                <div className="details_" >
                  <div className="title_sub">
                    {/* <FormControl className="dropdown hide" sx={{ width: '95%', backgroundColor: '#ffffff', borderRadius: '5px' }}>
                      <InputLabel id="select-label" >Select Location</InputLabel>
                      <Select className="selectmargin"
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        // {...field}
                        value={initialValues.village_id}
                        fullWidth
                        onChange={(e) => setInitialValue({ ...initialValues, village_id: e.target.value })}
                      >
                        {
                          villages?.length == 0 &&
                          <MenuItem className='select_all' value={"1"} >No location found</MenuItem>
                        }
                        {villages?.map((option) => (
                          <MenuItem key={option.village_id} value={option.village_id}>
                            {option.village_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}

                    <Autocomplete
                      size="small"
                      disablePortal
                      className="hide"
                      variant='standard'
                      id="combo-box-demo"
                      sx={{
                        input: {
                          "&::placeholder": {    // <----- Add this.
                            opacity: 1,
                            marginBottom: '-5px',
                          },
                        },
                        backgroundColor: '#ffffff',
                        borderRadius: '5px',
                        width: '95%',
                        // '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                        //   fontSize: 10,
                        // },
                      }}
                      ListboxProps={{
                        sx: { fontSize: 13 },
                      }}
                      options={villages?.length > 0 ? villages : [{ "village_name": "No data found" }]}
                      key={reset}
                      getOptionLabel={(option) => option.village_name}
                      onChange={(data, value) => { villageChange(data, value) }}
                      renderInput={(params) => <TextField {...params} color="black" InputLabelProps={{
                        style: {
                          fontSize: '13px',  // Adjust the font size of the label here
                          paddingTop: '5px'
                        }
                      }} label="Select Location" />}
                    />
                  </div>
                  <div style={{ width: '100%', height: 'auto' }}>
                    <div className="identity_ yellow">
                      <div className="icon_" onClick={() => handleHeatMap('cataract')} >
                        <Visibility className="eyeIcon" />
                      </div>
                      <div className="detail_">
                        <Typography>Cataract Identified</Typography>

                        <Typography variant='caption' >{illness?.cataract?.count == undefined ? 0 : illness?.cataract?.count}</Typography>
                      </div>
                    </div>
                    <div className="identity_ orange- blue" >
                      <div className="icon_">
                        <ThumbUp className="thumbsupIcons" />
                      </div>
                      <div className="detail_">
                        <Typography>Cataract Surgery Done</Typography>

                        <Typography variant='caption' >{illness?.surgerydonedetails?.surgery_done_count == undefined ? 0 : illness?.surgerydonedetails?.surgery_done_count}</Typography>
                      </div>
                    </div>
                    <div className="identity_ red- orange">
                      <div className="icon_" onClick={() => handleHeatMap('diabetes')}>
                        <Colorize className="fillIcon" />
                      </div>
                      <div className="detail_">
                        <Typography>Diabetes</Typography>

                        <Typography variant='caption' >{illness?.diabetes?.count == undefined ? 0 : illness?.diabetes?.count}</Typography>
                      </div>
                    </div>
                    <div className="identity_ blue- red">
                      <div className="icon_" onClick={() => handleHeatMap('hypertension')}>
                        <Bolt className="thunderIcon" />
                      </div>
                      <div className="detail_">
                        <Typography>Hypertension</Typography>

                        <Typography variant='caption' >{illness?.hypertension?.count == undefined ? 0 : illness?.hypertension?.count}</Typography>
                      </div>
                    </div>


                    <div className="identity_ yellow">
                      <div className="icon_"  >
                        {/* <Visibility /> */}
                        <img src={cataractdia} />
                      </div>
                      <div className="detail_">
                        <Typography>Cataract + Diabetes</Typography>

                        <Typography variant='caption' >{illness?.cataractDia?.count == undefined ? 0 : illness?.cataractDia?.count}</Typography>
                      </div>
                    </div>
                    <div className="identity_ orange" >
                      <div className="icon_">
                        {/* <ThumbUp /> */}
                        <img src={cataracthyper} />

                      </div>
                      <div className="detail_">
                        <Typography>Cataract + Hypertension</Typography>

                        <Typography variant='caption' >{illness?.cataractHyper?.count == undefined ? 0 : illness?.cataractHyper?.count}</Typography>
                      </div>
                    </div>
                    <div className="identity_ red">
                      <div className="icon_" >
                        {/* <Colorize /> */}
                        <img src={diahper} />
                      </div>
                      <div className="detail_">
                        <Typography>Hypertension + Diabetes</Typography>

                        <Typography variant='caption' >{illness?.HyperDia?.count == undefined ? 0 : illness?.HyperDia?.count}</Typography>
                      </div>
                    </div>
                    <div className="identity_ blue">
                      <div className="icon_" >
                        {/* <Bolt /> */}
                        <img src={cataracthyperdia} />
                      </div>
                      <div className="detail_">
                        <Typography>Cataract + Hypertension + Diabetes </Typography>

                        <Typography variant='caption' >{illness?.cataractHyperDia?.count == undefined ? 0 : illness?.cataractHyperDia?.count}</Typography>
                      </div>
                    </div>
                    {/* <div className="identity_ green">
                      <div className="icon_">
                        <Favorite />
                      </div>
                      <div className="detail_">
                        <Typography>Ischemic heart disease</Typography>
                        <Typography variant='caption' >0</Typography>

                      </div>
                    </div> */}
                  </div>
                  <div className="card_container">
                    {
                      expand == 1 ?
                        hospitalData != null ?
                          <>
                            <div className="card_ violet">
                              <div className="icon_">
                                <LocalHospital />
                              </div>
                              <div className="detail_">
                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>{
                                  hospitalData?.length == 1 ?
                                    'Hospital Name' : 'Total Hospitals'}</Typography>
                                <Typography variant='caption'>{
                                  hospitalData?.length == 1 ?
                                    firstLetter(hospitalData[0]?.hospital_name)
                                    :
                                    hospitalData?.length
                                }</Typography>
                              </div>
                            </div>
                            <div className="card_ blue_lightblue">
                              <div className="icon_">
                                <Male />
                              </div>
                              <div className="detail_">
                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Total Males </Typography>

                                <Typography variant='caption' >{patientDetails?.male}</Typography>
                              </div>
                            </div>
                            <div className="card_ violet_orange">
                              <div className="icon_">
                                <FemaleTwoTone />
                              </div>
                              <div className="detail_">
                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Total Females </Typography>

                                <Typography variant='caption' >{patientDetails?.female}</Typography>
                              </div>
                            </div>
                            <div className="card_ redmix">
                              <div className="icon_">
                                <TransgenderTwoTone />
                              </div>
                              <div className="detail_">
                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Others </Typography>

                                <Typography variant='caption' >{patientDetails?.others}</Typography>
                              </div>
                            </div>
                            <div className="card_ violetmix">
                              <div className="icon_">
                                <HomeTwoToneIcon />
                              </div>
                              <div className="detail_">
                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Total Household </Typography>
                                <Typography variant='caption' >{patientDetails?.household}</Typography>
                              </div>
                            </div>

                            {/* <div className="card_ pinkviolet">
                              <div className="icon_">
                                <FaCampground />
                              </div>
                              <div className="detail_">
                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Total Camps </Typography>
                                <Typography variant='caption' >{camp?.vhv?.length + camp?.cbbf?.length}</Typography>
                              </div>
                            </div> */}
                          </>
                          : ''

                        :
                        expand == 2 ?
                          campRHouse ?
                            <>
                              < div className="card_ violet">
                                <div className="icon_">
                                  <Festival />
                                </div>
                                <div className="detail_">
                                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Camp Name</Typography>
                                  <Typography variant='caption' >{firstLetter(campDetail?.camp_place)}</Typography>
                                </div>
                              </div>
                              {
                                campDataDetail?.hospital_name &&
                                <div className="card_ orange">
                                  <div className="icon_">
                                    <LocalHospitalSharp />
                                  </div>
                                  <div className="detail_">
                                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Assigned Hospital</Typography>
                                    <Typography variant='caption' >{campDataDetail?.hospital_name}</Typography>
                                  </div>
                                </div>
                              }
                              {
                                campDataDetail.camp_type &&
                                <div className="card_ green">
                                  <div className="icon_">
                                    <Diversity1Rounded />
                                  </div>
                                  <div className="detail_">
                                    <Typography sx={{ fontWeight: 'bold' }}>Camp Type</Typography>
                                    <Typography variant='caption' >{firstLetter(campDataDetail.camp_type)}</Typography>
                                  </div>
                                </div>
                              }

                              <div className="card_ violetmix">
                                <div className="icon_">
                                  <HomeTwoToneIcon />
                                </div>
                                <div className="detail_">
                                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>No of Households </Typography>
                                  {/* <Typography variant='caption' >{campData?.CBBF?.coords?.household_coords?.length + campData?.VHV?.coords?.household_coords?.length}</Typography> */}
                                  <Typography variant='caption' >{patientDetails?.household}</Typography>

                                </div>
                              </div>
                              <div className="card_ violetmix">
                                <div className="icon_">
                                  <HomeTwoToneIcon />
                                </div>
                                <div className="detail_">
                                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Total Individuals </Typography>
                                  {/* <Typography variant='caption' >{campData?.CBBF?.coords?.household_coords?.length + campData?.VHV?.coords?.household_coords?.length}</Typography> */}
                                  <Typography variant='caption' >{patientDetails?.individual}</Typography>

                                </div>
                              </div>

                              <div className="card_ violetmix">
                                <div className="icon_">
                                  <HomeTwoToneIcon />
                                </div>
                                <div className="detail_">
                                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Households Target </Typography>
                                  <Typography variant='caption' >{campDetail?.household_target}</Typography>

                                </div>
                              </div>
                              <div className="card_ violetmix">
                                <div className="icon_">
                                  <HomeTwoToneIcon />
                                </div>
                                <div className="detail_">
                                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Individual Screen target </Typography>
                                  <Typography variant='caption' >{campDetail?.individual_screening_target}</Typography>

                                </div>
                              </div>
                            </>
                            :
                            <>
                              <div className="card_ yellow">
                                <div className="icon_">
                                  <BiRename />
                                </div>
                                <div className="detail_">
                                  <Typography sx={{ fontWeight: 'bold' }}>Patient name</Typography>
                                  <Typography variant='caption' >{firstLetter(houseHoldData?.name)}</Typography>
                                </div>
                              </div>
                              <div className="card_ red">
                                <div className="icon_">
                                  <TransgenderTwoTone />
                                </div>
                                <div className="detail_">
                                  <Typography sx={{ fontWeight: 'bold' }}>Patient age </Typography>
                                  <Typography variant='caption' >{houseHoldData?.age}</Typography>
                                </div>
                              </div>
                              <div className="card_ blue">
                                <div className="icon_">
                                  <Visibility />
                                </div>
                                <div className="detail_">
                                  <Typography sx={{ fontWeight: 'bold' }}>Gender </Typography>
                                  <Typography variant='caption' >{firstLetter(houseHoldData?.gender)}</Typography>
                                </div>
                              </div>
                              <div className="card_ blue_lightblue">
                                <div className="icon_">
                                  <Visibility />
                                </div>
                                <div className="detail_">
                                  <Typography sx={{ fontWeight: 'bold' }}>Surgery Status </Typography>
                                  <Typography variant='caption' >{firstLetter(houseHoldData?.surgery_status)}</Typography>
                                </div>
                              </div>
                              <div className="card_ violetmix">
                                <div className="icon_">
                                  <HomeTwoToneIcon />
                                </div>
                                <div className="detail_">
                                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>No of Households </Typography>
                                  <Typography variant='caption' >{patientDetails?.household}</Typography>

                                </div>
                              </div>

                              <div className="card_ green">
                                <div className="icon_">
                                  <HomeTwoToneIcon />
                                </div>
                                <div className="detail_">
                                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Households Target </Typography>
                                  <Typography variant='caption' >{campDetail?.household_target}</Typography>

                                </div>
                              </div>
                              <div className="card_ orange">
                                <div className="icon_">
                                  <HomeTwoToneIcon />
                                </div>
                                <div className="detail_">
                                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Individual Screen target </Typography>
                                  <Typography variant='caption' >{campDetail?.individual_screening_target}</Typography>

                                </div>
                              </div>
                            </>

                          :
                          expand == 3 ?
                            <>
                              <div className="card_ violet">
                                <div className="icon_">
                                  <TwoWheeler />
                                </div>
                                <div className="detail_">
                                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Travelled Km</Typography>
                                  <Typography variant='caption' >{trackDetail != "" ? trackDetail?.traveledDistance.toFixed(2) : 0} km</Typography>
                                </div>
                              </div>
                              <div className="card_ orange">
                                <div className="icon_">
                                  <BiRename />
                                </div>
                                <div className="detail_">
                                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Field Worker Name </Typography>
                                  <Typography variant='caption' >{fieldWorkerName}</Typography>
                                </div>
                              </div>
                              <div className="card_ blue_lightblue">
                                <div className="icon_">
                                  <AiOutlineCaretRight />
                                </div>
                                <div className="detail_">
                                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Shift In</Typography>
                                  <Typography variant='caption'>{shiftdetail?.shiftIn} </Typography>
                                </div>
                              </div>
                              <div className="card_ green">
                                <div className="icon_">
                                  <AiOutlineCaretLeft />
                                </div>
                                <div className="detail_">
                                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Shift out</Typography>
                                  <Typography variant='caption'>{shiftdetail?.shiftout} </Typography>
                                </div>
                              </div>
                              <div className="card_ violetmix">
                                <div className="icon_">
                                  <HomeTwoToneIcon />
                                </div>
                                <div className="detail_">
                                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Total Household </Typography>
                                  <Typography variant='caption' >{patientDetails?.household}</Typography>
                                </div>
                              </div>
                            </>
                            :
                            ""
                    }
                  </div>
                </div>
              </div>
            </div >
          </div >
        )}
      </Formik >
    </>
  )
}

export default Gis_report
