import { TextField, Stack, Button } from "@mui/material";
import React, { useState } from "react";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";

const VisionFilter = ({ filterObject }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleSubmit = () => {
    // let obj = {
    //     campStart: campStartDate
    //       ? moment(campStartDate.$d).format("YYYY-MM-DD")
    //       : "",
    //     campEnd: campEndDate ? moment(campEndDate.$d).format("YYYY-MM-DD") : "",
    //     campIds: campId ? campId : "",
    //     campPlaces: campPlace ? campPlace : "",
    //   };
    let obj = {
      eventStart: startDate ? moment(startDate.$d).format("YYYY-MM-DD") : "",
      eventEnd: endDate ? moment(endDate.$d).format("YYYY-MM-DD") : "",
    };
    filterObject(obj);
  };

  const handleClose = () => {
    let obj = {
      eventStart: "",
      eventEnd: "",
    };
    setStartDate(null);
    setEndDate(null);
    filterObject(obj);
  };

  return (
    <div style={{ marginBottom: "2vh" }}>
      <Stack direction="row" spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="From Date"
            renderInput={(props) => <TextField {...props} size="small" />}
            defaultValue=""
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="To Date"
            renderInput={(props) => <TextField  {...props}  size="small" />}
            defaultValue=""
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
            minDate={startDate ? new Date(moment(startDate.$d)) : ""}
          />
        </LocalizationProvider>

        <Button
        sx={{textTransform:'capitalize'}}
          variant="contained"
          style={{ width: "11%"   }}
          onClick={handleSubmit}
        >
          Search
        </Button>
        <Button
        sx={{textTransform:'capitalize'}}
          variant="contained"
          style={{ width: "11%"  }}
          onClick={handleClose}
        >
          Reset
        </Button>
      </Stack>
    </div>
  );
};
export default VisionFilter;
