import React, { useState, useEffect } from "react";
import TableComponent from "../components/table";
// import {
//   Button, Input
// } from "@mui/material";
import { Table, makeStyles, Button, FormControl, TableBody, TableCell, InputLabel, Input, Select, MenuItem, TableContainer, TableHead, TableRow, Checkbox } from "@mui/material";

import { useAxios } from "../hooks/useAxios";
import "react-toastify/dist/ReactToastify.css";
import { Typography } from "@mui/material";
import { dates } from '../index.js'
import moment from "moment";


import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelDownload from "../components/ExcelFormat/ExcelFileDownload";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";



const renderInputs = (props, placeholder, classname) => (
  <Input
    sx={{
      width: '80%',
      input: {
        "&::placeholder": {    // <----- Add this.
          opacity: 1,
          // marginBottom: '-5px',
          paddingLeft: '10px',
          fontSize: '13px',
          color: '#000000'
        },
        width: '100%'
      },
      backgroundColor: '#ffffff',
      // borderRadius: '5px',
      // maxHeight: '50px'
    }}
    type="text"
    className={classname}
    inputRef={props.inputRef}
    // inputProps={props.inputProps}
    inputProps={{
      ...props.inputProps,
      placeholder: placeholder
    }}
    value={props.value}
    onClick={props.onClick}
    onChange={props.onChange}
    endAdornment={props.InputProps?.endAdornment}
  />
);

const VisionCenter_performance = () => {
  const [length, setLength] = useState();


  const [header] = useState([
    {
      id: 1,
      name: "Camp Name",
      value: "camp_name",
      isChecked: false
    },
    {
      id: 2,
      name: "Fieldworker Name",
      value: 'field_worker_name',
      isChecked: false
    },
    {
      id: 2,
      name: "No. of Patient Referred to Vision Center",
      value: "patient_referred_vc",
      isChecked: false
    },
    {
      id: 3,
      name: "No. of Patient Reported to Vision Center",
      value: "patient_reported_vc",
      isChecked: false
    },
    {
      id: 4,
      name: " No.of Patient Referred to Camp",
      value: "patient_referred_camp",
      isChecked: false
    },
    {
      id: 5,
      name: "No.of Patients Referred to Base Hospital ",
      value: "patient_referred_BH",
      isChecked: false
    },
    {
      id: 6,
      name: "No.of Patient Received Glasses",
      value: "patient_received_glass",
      isChecked: false
    },
  ]);

  const [downloadTab, setDownloadTab] = useState(false)
  const [value, setValue] = useState([]);
  const [dateSearch, setDateSearch] = useState('')

  const { axios } = useAxios();
  const [visionCenterList, setVisionCenterList] = useState([]);
  let paginationInfo = {
    page: 1,
    count: 0,
    rowsPerPage: 10,
  };
  const [pageInfo, setPageInfo] = useState(paginationInfo);

  const [filterValue, setFilterValue] = useState({
    eventStart: "",
    eventEnd: "",
  });


  const [searchValue, setSearchValue] = React.useState({
    eventStart: "",
    eventEnd: ""
  });


  useEffect(() => {
    VisionCenterList();
  }, [pageInfo.rowsPerPage, pageInfo.page,filterValue]); //
  const VisionCenterList = () => {
    axios
      .post(
        `vcAllList?limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}&fromDate=${filterValue.eventStart}&toDate=${filterValue.eventEnd}`
      )
      .then((data) => {
        setVisionCenterList(data.data.result.list);
        setLength(data.data.result.length);
        setPageInfo((prevState) => ({
          ...prevState,
          count: data.data.result.length,
        }));
      })
      .catch(() => {
        // setLength(0);
        // setPageInfo(paginationInfo);
      });
  };
  const editData = (data) => { };
  const deleteDate = (data) => { };
  const filterResponse = (val) => {
    setPageInfo((prevState) => ({ ...prevState, page: 1 }));
    setFilterValue(val);
  };
  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };
  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };



  function alterScreenName(name) {
    var menu_name = name.replaceAll("_", " ");
    var name = menu_name?.replace(/^.{1}/g, menu_name[0].toUpperCase());
    return name;
  }


  function handleDates(val) {
    const today = new Date(); // Get the current date
    setDateSearch(val)






    // Format the dates as "DD-MM-YYYY"
    var dateFormat = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    if (val == 'last_week') {

      //==============last week ======================

      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7); // Subtract 7 days to get the date for last week

      // Find the start date (Monday) of last week
      const startOfWeek = new Date(lastWeek);
      startOfWeek.setDate(lastWeek.getDate() - (lastWeek.getDay() + 6) % 7);

      // Find the end date (Sunday) of last week
      const endOfWeek = new Date(lastWeek);
      endOfWeek.setDate(lastWeek.getDate() - (lastWeek.getDay() + 6) % 7 + 6);

      // Format the dates
      var formattedStartOfWeek = startOfWeek.toISOString().split('T')[0];
      var formattedEndOfWeek = endOfWeek.toISOString().split('T')[0];


      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartOfWeek,
        eventEnd: formattedEndOfWeek,
      }));

    } else if (val == 'last_month') {

      //================last month ===============================
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

      // Set the day to 1 to get the first day of the previous month
      lastMonth.setDate(2);

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: lastMonth.toISOString().split('T')[0],
        eventEnd: moment().date(0).toISOString().split('T')[0],
      }));


    } else if (val == 'last_3_month') {
      //================last month ===============================


      const threeMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 3, 1);

      // Set the day to 1 to get the first day of three months ago
      threeMonthsAgo.setDate(1);
  
      const lastDayOfThreeMonthsAgo = new Date(today.getFullYear(), today.getMonth(), 0);
   
      const formattedStartDate = threeMonthsAgo.toLocaleDateString('en-IN', { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'Asia/Kolkata' });
      const formattedEndDate = lastDayOfThreeMonthsAgo.toLocaleDateString('en-IN', { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'Asia/Kolkata' });
   
      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart:  formattedStartDate.replaceAll('/','-').split('-').reverse().join('-'),
        eventEnd: formattedEndDate.replaceAll('/','-').split('-').reverse().join('-'),
      }));
    } else if (val == 'all') {
      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: "",
        eventEnd: "",
      }));
    }
  }


  const handleChange = (e, val, position, headerName) => {
    const index = value?.findIndex((x) => x.value == val);


    if (e.target.checked) {

      if (index < 0) {
        setValue(() => {

          var values = { position: position, value: val, header: headerName }
          var data = { ...value, values }
          return Object.values(data)

        })
      } else {
        value.splice(index, 1)
      }
    } else {
      value.splice(index, 1)
    }
  }



  const handleuploadExcel = async () => {
    await axios
      .post(
        `vcAllList?limit=&page=${1}&fromDate=${searchValue.eventStart}&toDate=${searchValue.eventEnd}`
      )
      .then((res) => {
        const eventValue = res.data?.result?.list;


        // if (value.length > 1) {
        //   value.sort((x, y) => x.position - y.position)
        // }


        // var result = [];


        // eventValue?.map((x) => {
        // var data = {};
        // value?.map((i) => {
        //       data[i.header] = x[i.value]
        //   })
        //   result.push(data)
        // })

        let newArrayOfObj = eventValue.map(
          ({
            camp_name: CampName,
            field_worker_id: FieldWorkerId,
            field_worker_name: FieldWorkerName,
            patient_received_glass: PatientReceivedGlass,
            patient_referred_BH: PatientReferredBH,
            patient_referred_camp: PatientReferredCamp,
            patient_referred_vc: PatientReferredVc,
            patient_reported_vc: PatientReportedVc,

          }) => ({
            CampName,
            FieldWorkerId,
            FieldWorkerName,
            PatientReceivedGlass,
            PatientReferredBH,
            PatientReferredCamp,
            PatientReferredVc,
            PatientReportedVc,
          })
        );

        ExcelDownload(newArrayOfObj, "Vision Center");
        setDownloadTab((o) => !o)
        setDateSearch("")

      })
  }


  return (
    <>
      {" "}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" color="secondary" className="text-left">
          Vision Center Referral Performance Summary
        </Typography>{" "}
        <Button
          variant="contained"
          sx={{ m: 1, width: "28ch", textTransform: 'capitalize' }}
          startIcon={<FileDownloadIcon />}
          // onClick={handleuploadExcel}
          onClick={() => setDownloadTab(k => !k)}
        >
          Download Vision Center
        </Button>
      </div>


      {
        downloadTab &&
        <div className='tabbackground'>
          {/* <div className='downloadtab' > */}
          <div className={dateSearch == 'custom' ? 'nonreports' : 'nonreports1'} >
            <div className='table'>
              <Table>
                <TableHead>
                  <tr>
                    {/* <th><Checkbox
                      onChange={(e) => {
                        handleSelectAll(e);
                      }}
                    />
                      Select all</th> */}
                    <th> </th>
                    <th>Select Duration </th>
                  </tr>
                </TableHead>
              </Table>
              <div className='filter'>
                <FormControl style={{ width: "80%" }} size="small" >
                  <Select
                    labelId="select-label"
                    id="select"
                    variant="standard"
                    label="Select Dates"
                    value={dateSearch}
                    onChange={(e) => { handleDates(e.target.value) }}
                  >
                    {dates?.map((option) => (
                      <MenuItem key={option.id} value={option.value} >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {
                  dateSearch == 'custom' &&
                  <div style={{ width: '100%' }} className="custom">

                    <LocalizationProvider dateAdapter={AdapterDayjs}  >
                      <DatePicker className="from_date"
                        value={searchValue.eventStart == "" ? null : searchValue.eventStart}
                        inputFormat="DD/MM/YYYY"
                        onChange={(e) => {
                          const selectedDate = e.$d;
                          if (selectedDate) {
                            setSearchValue({ ...searchValue, eventStart: selectedDate });
                          } else {
                            setSearchValue({ ...searchValue, eventStart: null });
                          }
                        }}
                        slotProps={{
                          actionBar: {
                            actions: ['clear']
                          }
                        }}
                        renderInput={(props) => renderInputs(props, 'From date', "date")}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker className="from_date"
                        value={searchValue.eventEnd == "" ? null : searchValue.eventEnd}
                        inputFormat="DD/MM/YYYY"
                        onChange={(e) => {
                          setSearchValue({ ...searchValue, eventEnd: e.$d });

                        }}
                        renderInput={(props) => renderInputs(props, 'To date', "date")}

                      />
                    </LocalizationProvider>
                  </div>
                }
              </div>
            </div>
            <div className='downloadoptions'>
              <Button variant="contained" onClick={() => { setDownloadTab(k => !k); setDateSearch("") }} >Cancel</Button>
              <Button variant="contained" disabled={dateSearch == ""}  onClick={() => handleuploadExcel()} >Download</Button> {/*onClick={handleuploadExcel}*/}
            </div>
          </div>
        </div>
      }


      <TableComponent
        header={header}
        // addList={addStates}
        datalist={visionCenterList}
        datalength={length}
        actions={[]}
        editData={(id) => editData(id)}
        deleteData={(id) => deleteDate(id)}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        handleTablePage={handleTablePage}
        handleTableRowsPerPage={handleTableRowsPerPage}
        filterResponseValue={(val) => filterResponse(val)}
        filter="visioncenter"
      ></TableComponent>
    </>
  );
};

export default VisionCenter_performance;
