import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Dialog, Button, CardContent } from "@mui/material";
import TableComponent from "../components/table";
import { useAxios } from "../hooks/useAxios";
import DynamicInput from "../components/dy_input";
import { HybridInput, HybridSelect } from "../components/hybrid_inputs";
import { StateSchema } from "../validations/userSchema";
import { Form, Formik } from "formik";
import { toast, Slide } from "react-toastify";
import ConfirmPopup from "../components/confirmPopup";

export const ManageHospitals = () => {
  const [header] = useState([
    {
      id: 1,
      name: "Hospital Code ",
      value: "hospital_code",
    },
    {
      id: 2,
      name: "Hospital Name",
      value: "hospital_name",
    },
    {
      id: 3,
      name: "Phone Number",
      value: "phone_number",
    },
    {
      id: 4,
      name: "Email",
      value: "Email",
    },
    {
      id: 5,
      name: "Latitude",
      value: "latitude",
    },
    {
      id: 6,
      name: "Longitude",
      value: "latitude",
    },
    {
      id: 7,
      name: "Survey Radius",
      value: "survey_radius",
    },
    {
      id: 8,
      name: "State Name",
      value: "survey_radius",
    },
    {
      id: 9,
      name: "District Name",
      value: "survey_radius",
    },
    {
      id: 10,
      name: "Status",
      value: "status",
    },
    {
      id: 11,
      name: "Hospital Address",
      value: "hospital_address",
    },
    {
      id: 12,
      name: "Pin code",
      value: "pincode",
    },
  ]);
  const { axios } = useAxios();
  const [popup, setPopup] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [editState, setEditState] = useState(false);
  const [Editable] = useState(true);
  const [DropDown] = useState([
    {
      _id: "Active",
      status: "Active",
    },
    {
      _id: "Inactive",
      status: "Inactive",
    },
  ]);
  const [initialValues, setInitialValue] = useState({
    state_name: "",
    status: "",
  });
  const [dynamicInptdata] = useState([
    {
      text: true,
      select: false,
      lable: "State name",
      name: "state_name",
      type: "text",
      variant: "standard",
    },
    {
      text: false,
      select: true,
      lable: "Status",
      name: "status",
      type: "text",
      variant: "standard",
      options: ["Active", "Inactive"],
    },
  ]);
  const [hospitalList, setHospitalList] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    count: 0,
    rowsPerPage: 10,
  });
  useEffect(
    (values) => {
     
      HospitalList();
    },
    [pageInfo.rowsPerPage, pageInfo.page]
  );
  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };
  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };
  const HospitalList = () => {


    axios
      .post(`hospitalList?limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}`)
      .then((data) => {
      
        setHospitalList(data.data.result[0].data);
        setPageInfo((prevState) => ({
          ...prevState,
          count: data.data.result.length,
        }));
      });
  };
  const addStates = (actions) => {
    setEditState(false);
    // reset();
    // actions.resetForm();

    setPopup(true);
  };
  const handleClose = () => {
    setPopup(false);
  };

  const searchValues = (values) => {
   
  };
  const handleSubmit = (values) => {
    if (editState === false) {
      axios.post(`stateCreate`, values).then((data) => {
    
        if (data.data.statuscode === 200) {
          setPopup(false);
          toast.success("State Added Sucessfully", {
            autoClose: 3000,
          });
          HospitalList();
        }
      });
    } else {
      
      let insertData = {
        state_name: values.state_name,
        status: values.status,
        id: activeId,
      };
      
      axios.post(`stateUpdate`, insertData).then((data) => {
      
        if (data.data.statuscode === 200) {
          setPopup(false);
          toast.success("State Updated Sucessfully", {
            autoClose: 5000,
          });
          HospitalList();
        }
      });
    }
  };
  const reset = () => {
    setInitialValue({ state_name: "", status: "" });
  };
  const editData = (data) => {
    setEditState(true);
    hospitalList.forEach((list) => {
      if (list._id === data) {
       
        setActiveId(list._id);
        setInitialValue({ ...initialValues, ...list });
        setPopup(true);
      }
    });
  };
  const deleteDate = (data) => {
    setActiveId(data);
    setDeletePopup(true);
  };
  function deleteCancel(params) {
    setDeletePopup(false);
  }
  function deletesubmit(params) {
    setDeletePopup(false);
    let insertData = {
      id: activeId,
    };
    axios.post(`stateDelete`, insertData).then((data) => {
     
      toast.success("Deleted sucessfully", {
        autoClose: 3000,
        transition: Slide,
      });
      HospitalList();
      // setStateList(data.data.data);
    });
  }
  function Searchfunction(params) {
  
  }
  return (
    <div>
      <DynamicInput
        name={"Hospital Management"}
        // DropDown={DropDown}
        dynamicInptdata={dynamicInptdata}
        initialValues={initialValues}
        searchValues={(values) => searchValues(values)}
        // Searchfunction={(event) => Searchfunction(event)}
      ></DynamicInput>
      <Button
        variant="contained"
        sx={{ float: "right", m: 1, width: "10ch" }}
        onClick={() => addStates()}
      >
        Add
      </Button>
      <TableComponent
        header={header}
        // addList={addStates}
        Editable={Editable}
        datalist={hospitalList}
        datalength = {hospitalList.length}
        editData={(id) => editData(id)}
        deleteData={(id) => deleteDate(id)}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        handleTablePage={handleTablePage}
        handleTableRowsPerPage={handleTableRowsPerPage}
      ></TableComponent>
      {deletePopup ? (
        <ConfirmPopup
          message={"Are you sure, you want to delete this record"}
          deleteCancel={deleteCancel}
          deleteSubmit={deletesubmit}
        ></ConfirmPopup>
      ) : (
        ""
      )}
      <Dialog
        open={popup}
        scroll="paper"
        fullWidth={true}
        onClose={handleClose}
      >
        {" "}
        <Formik
          initialValues={initialValues}
          validationSchema={StateSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting,setFieldValue }) => (
          <Form>
            <CardContent style={{ display: "flex" }}>
              <HybridInput
                label="State Name"
                name="state_name"
                fullWidth
                type="text"
                setVal={setFieldValue}
                variant="standard"
              />
              <HybridSelect
                label="Status"
                name="status"
                fullWidth
                type="text"
                variant="standard"
                options={DropDown}
                dropKey="status"
                
              />
              <div
                style={{
                  width: "100px",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "10px",
                  display: "flex",
                }}
              >
                <Button variant="contained" type="submit" color="primary">
                  Submit
                </Button>
              </div>
            </CardContent>
          </Form>
          )} 
        </Formik>
        {/* <DialogTitle>State details</DialogTitle>
        <DialogContent>
          <FormControl fullWidth className="mt-1">
            <TextField
              label="Standard"
              variant="standard"
              name="state_name"
              type="text"
              value={data.state_name}
              onChange={(event) => handleChange(event)}
            />
          </FormControl>
          <HybridInput
            label="State details"
            name="state_name"
            type="text"
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};
