import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Dialog, Button, CardContent, Typography } from "@mui/material";
import TableComponent from "../components/table";
import { useAxios } from "../hooks/useAxios";
import DynamicInput from "../components/dy_input";
import { HybridInput, HybridSelect } from "../components/hybrid_inputs";
import { StateSchema } from "../validations/userSchema";
import { Form, Formik } from "formik";
import { toast, Slide } from "react-toastify";
import ConfirmPopup from "../components/confirmPopup";
import PatientForm from "../components/patientform";
import PatientView from "../forms/paient_view";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelDownload from "../components/ExcelFormat/ExcelFileDownload";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";

// QrComponent
import QrComponent from "../components/QrComponent.js";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { dates } from "../index.js";

import {
  Table,
  makeStyles,
  FormControl,
  TableBody,
  TableCell,
  InputLabel,
  Input,
  Select,
  MenuItem,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

import moment from "moment";

const renderInputs = (props, placeholder, classname) => (
  <Input
    sx={{
      width: "80%",
      input: {
        "&::placeholder": {
          // <----- Add this.
          opacity: 1,
          // marginBottom: '-5px',
          paddingLeft: "10px",
          fontSize: "13px",
          color: "#000000",
        },
        width: "100%",
      },
      backgroundColor: "#ffffff",
      // borderRadius: '5px',
      // maxHeight: '50px'
    }}
    type="text"
    className={classname}
    inputRef={props.inputRef}
    // inputProps={props.inputProps}
    inputProps={{
      ...props.inputProps,
      placeholder: placeholder,
    }}
    value={props.value}
    onClick={props.onClick}
    onChange={props.onChange}
    endAdornment={props.InputProps?.endAdornment}
  />
);

function Patient_management() {
  const [header1] = useState([
    {
      id: 1,
      name: "Patient Name",
      value: "patient_id",
    },
    {
      id: 2,
      name: "Name",
      value: "name",
    },
    {
      id: 3,
      name: "Age",
      value: "age",
    },
    {
      id: 4,
      name: "Gender",
      value: "gender",
    },
    {
      id: 5,
      name: "Door No",
      value: "door_no",
    },
    {
      id: 6,
      name: "Street Name",
      value: "street_name",
    },
    {
      id: 7,
      name: "Phone",
      value: "phone",
    },
    {
      id: 9,
      name: "ID Proof",
      value: "id_proof",
    },
    {
      id: 10,
      name: "ID Proof Number",
      value: "id_proof_number",
    },
    {
      id: 11,
      name: "Illness",
      value: "illness",
    },
    {
      id: 12,
      name: "Presenting Left Eye",
      value: "presenting_left_eye",
    },
    {
      id: 13,
      name: "Presenting Right Eye",
      value: "presenting_right_eye",
    },
    {
      id: 14,
      name: "Presenting Right Eye",
      value: "presenting_right_eye",
    },
    {
      id: 15,
      name: "Eye wise left hospital name",
      value: "ewl_hospital_name",
    },
    {
      id: 16,
      name: "Eye wise left history of surgery",
      value: "ewl_history_of_surgery",
    },
    {
      id: 17,
      name: "Eye wise left complaints",
      value: "ewl_complaints",
    },
    {
      id: 18,
      name: "Eye wise left provisional diagnosis",
      value: "ewl_provisional_diagnosis",
    },
    {
      id: 19,
      name: "Eye wise left illness",
      value: "ewl_illness",
    },
    {
      id: 20,
      name: "Eye wise Right history of surgery",
      value: "ewr_history_of_surgery",
    },
    {
      id: 21,
      name: "Eye wise Right hospital name",
      value: "ewr_hospital_name",
    },
    {
      id: 22,
      name: "Eye wise Right hospital name",
      value: "ewr_hospital_name",
    },
    {
      id: 23,
      name: "Eye wise Right complaints",
      value: "ewr_complaints",
    },
    {
      id: 24,
      name: "Eye wise Right provisional diagnosis",
      value: "ewr_provisional_diagnosis",
    },
    {
      id: 25,
      name: "Eye wise Right illness",
      value: "ewr_illness",
    },
    {
      id: 26,
      name: "Torch light left eye position",
      value: "tll_eye_position",
    },
    {
      id: 27,
      name: "Torch light left congestion",
      value: "tll_congestion",
    },
    {
      id: 28,
      name: "Torch light left lens",
      value: "tll_lens",
    },
    {
      id: 29,
      name: "Torch light right eye position",
      value: "tlr_eye_position",
    },
    {
      id: 30,
      name: "Torch light right congestion",
      value: "tlr_congestion",
    },
    {
      id: 31,
      name: "Torch light right lens",
      value: "tlr_lens",
    },
    {
      id: 32,
      name: "referal number",
      value: "referal_number",
    },
    // {
    //   id: 33,
    //   name: "follow up",
    //   value: "follow_up",
    // },
    {
      id: 34,
      name: "forward type",
      value: "forward_type",
    },
    {
      id: 35,
      name: "forward_location",
      value: "forward_location",
    },
  ]);
  const [header] = useState([
    {
      id: 1,
      name: "Patient ID",
      value: "patient_id",
    },
    {
      id: 2,
      name: "Patient Name",
      value: "name",
    },
    {
      id: 3,
      name: "Hospital Name",
      value: "hospital_name",
    },
    {
      id: 4,
      name: "Fieldworker Name",
      value: "field_worker",
    },
    {
      id: 5,
      name: "Entry Date",
      value: "current_date",
    },
  ]);
  let dateFields = ["current_date"];

  const { axios } = useAxios();

  // qr state management
  const iniQrState = {
    open: false,
    dialogTitle: "",
    qrTitle: "",
    qrValue: "",
    patientName: "",
    patientId: "",
  };
  const [qr, setQr] = useState(iniQrState);

  const [popup, setpopup] = useState(false);
  const [patientPopup, openPatientPopup] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [length, setLength] = useState();
  const [editState, setEditState] = useState(false);
  const [Editable] = useState(true);
  const [viewType, setViewType] = useState(0);
  const [DropDown] = useState([
    {
      _id: "Active",
      status: "Active",
    },
    {
      _id: "Inactive",
      status: "Inactive",
    },
  ]);
  const [initialValues, setInitialValue] = useState({
    state_name: "",
    status: "",
  });

  const [downloadTab, setDownloadTab] = useState(false);
  const [value, setValue] = useState([]);
  const [dateSearch, setDateSearch] = useState("");

  const [dynamicInptdata] = useState([
    {
      text: true,
      select: false,
      lable: "State name",
      name: "state_name",
      type: "text",
      variant: "standard",
    },
    {
      text: false,
      select: true,
      lable: "Status",
      name: "status",
      type: "text",
      variant: "standard",
      options: ["Active", "Inactive"],
    },
  ]);
  const [HHSList, setHHSList] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [filterValue, setFilterValue] = useState({
    campStart: "",
    campEnd: "",
    hospital_name: "",
    name: "",
  });
  let paginationSetup = {
    page: 1,
    count: 0,
    rowsPerPage: 10,
  };

  const [searchValue, setSearchValue] = React.useState({
    eventStart: "",
    eventEnd: "",
  });

  const [pageInfo, setPageInfo] = useState(paginationSetup);
  const [patientId, setPatientId] = useState("");
  const [patientDetails, setPatientDetails] = useState([HHSList]);
  const [patientGlass, setPatientGlassDistribute] = useState();

  const [addItem, setAddItem] = useState(false);
  // const [searchValue, setSearchValue] = React.useState("");
  const [glassDetails, setGlassDetails] = React.useState("");
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  useEffect(() => {
    PatientList();
  }, [pageInfo.rowsPerPage, pageInfo.page, filterValue]); //filterValue

  const toggleDrawer = (anchor, open) => {
    if (open) {
      setState({ ...state, [anchor]: open });
    }
  };

  const handleGlassChange = (event) => {
    setGlassDetails(event.target.value);
  };
  const handleGlassSubmit = () => {};
  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };
  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };
  const PatientList = async () => {
    try {
      await axios
        .post(
          `HHSList?limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}&fromDate=${filterValue.campStart}&toDate=${filterValue.campEnd}&name=${filterValue.name}&hospital_name=${filterValue.hospital_name}`
        )
        .then((data) => {
          let newArrayOne = data.data?.result?.list.map((data) => {
            return {
              ...data,
              camp_from_date: data.camp_from_date.slice(0, 10),
            };
          });
          setHHSList(newArrayOne);

          setLength(data.data.result.length);
          setPageInfo((prevState) => ({
            ...prevState,
            count: data.data.result.length,
          }));
        })
        .catch((err) => {
          setHHSList([]);
          setLength(0);
          setPageInfo(paginationSetup);
        });
    } catch (error) {
      console.log("HHS", error);
    }
  };

  const addPatientToggle = () => {
    setAddItem((prevState) => !prevState);
    PatientList();
  };
  const handleClose = () => {
    setpopup(false);
  };

  const names = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];
  const searchValues = (values) => {};
  const handleSubmit = (values) => {
    if (editState === false) {
      axios.post(`stateCreate`, values).then((data) => {
        if (data.data.statuscode === 200) {
          setpopup(false);
          toast.success("State Added Sucessfully", {
            autoClose: 3000,
          });
          PatientList();
        }
      });
    } else {
      let insertData = {
        state_name: values.state_name,
        status: values.status,
        id: activeId,
      };

      axios.post(`stateUpdate`, insertData).then((data) => {
        if (data.data.statuscode === 200) {
          setpopup(false);
          toast.success("State Updated Sucessfully", {
            autoClose: 5000,
          });
          PatientList();
        }
      });
    }
  };
  const reset = () => {
    setInitialValue({ state_name: "", status: "" });
  };
  const editData = (data) => {
    setEditState(true);
    HHSList.forEach((list) => {
      if (list._id === data) {
        setActiveId(list._id);
        setInitialValue({ ...initialValues, ...list });
        setpopup(true);
      }
    });
  };
  const deleteDate = (data) => {
    setActiveId(data);
    setDeletePopup(true);
  };
  function deleteCancel(params) {
    setDeletePopup(false);
  }
  function deletesubmit(params) {
    setDeletePopup(false);
    let insertData = {
      id: activeId,
    };
    axios.post(`stateDelete`, insertData).then((data) => {
      toast.success("Deleted sucessfully", {
        autoClose: 3000,
        transition: Slide,
      });
      PatientList();
    });
  }
  const closePatientView = () => {
    openPatientPopup((prevState) => (prevState = !prevState));
  };
  const Glassdistribute = (data) => {
    setPatientGlassDistribute(data);
    toggleDrawer("right", true);
  };
  const viewPatient = (details) => {
    setPatientId(details._id);
    setPatientDetails(details);
    setViewType(1);
    closePatientView();
  };

  // handle patient QR
  const handlePatientQr = (data) => {
    setQr((prevState) => ({
      ...prevState,
      open: true,
      dialogTitle: "Scan Patient QR Code",
      qrTitle: data?.referral_number,
      qrValue: data?.referral_number,
      patientName: data?.name,
      patientId: data?.patient_id,
    }));
  };

  const viewFamily = (details) => {
    setPatientId(details);
    setPatientDetails(details);

    setViewType(2);
    closePatientView();
  };
  function Searchfunction(params) {}

  function firstLetter(s) {
    return s?.replace(/^.{1}/g, s[0].toUpperCase());
  }

  function handleDates(val) {
    const today = new Date(); // Get the current date
    setDateSearch(val);

    // Format the dates as "DD-MM-YYYY"
    var dateFormat = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    if (val == "last_week") {
      //==============last week ======================

      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7); // Subtract 7 days to get the date for last week

      // Find the start date (Monday) of last week
      const startOfWeek = new Date(lastWeek);
      startOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7));

      // Find the end date (Sunday) of last week
      const endOfWeek = new Date(lastWeek);
      endOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7) + 6);

      // Format the dates
      var formattedStartOfWeek = startOfWeek.toISOString().split("T")[0];
      var formattedEndOfWeek = endOfWeek.toISOString().split("T")[0];

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartOfWeek,
        eventEnd: formattedEndOfWeek,
      }));
    } else if (val == "last_month") {
      //================last month ===============================
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

      // Set the day to 1 to get the first day of the previous month
      lastMonth.setDate(2);

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: lastMonth.toISOString().split("T")[0],
        eventEnd: moment().date(0).toISOString().split("T")[0],
      }));
    } else if (val == "last_3_month") {
      //================last month ===============================

      const threeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        1
      );

      // Set the day to 1 to get the first day of three months ago
      threeMonthsAgo.setDate(1);

      const lastDayOfThreeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );

      const formattedStartDate = threeMonthsAgo.toLocaleDateString("en-IN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "Asia/Kolkata",
      });
      const formattedEndDate = lastDayOfThreeMonthsAgo.toLocaleDateString(
        "en-IN",
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone: "Asia/Kolkata",
        }
      );

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
        eventEnd: formattedEndDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
      }));
    } else if (val == "all") {
      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: "",
        eventEnd: "",
      }));
    }
  }

  /*------------- excel download---------*/
  const handleuploadExcel = async () => {
    let response = await axios.post(
      `HHSList?limit=&page=${1}&fromDate=${searchValue.eventStart}&toDate=${
        searchValue.eventEnd
      }&name=${filterValue.name}&hospital_name=${filterValue.hospital_name}`
    );

    data = response?.data?.result?.list?.map((data) => {
      return {
        ...data,
        camp_date: data.camp_date.slice(0, 10),
        camp_from_date: data.camp_from_date.slice(0, 10),
        hospital_name: firstLetter(data.hospital_name),
        name: firstLetter(data.name),
        field_worker: firstLetter(data.field_worker),
      };
    });

    var data;

    const newArr = data.map((obj) => {
      const newObj = { ...obj };
      delete newObj["_id"];
      delete newObj["dial_count"];
      delete newObj["camp_id"];
      delete newObj["modify_on"];
      delete newObj["created_on"];
      return newObj;
    });

    let newArrayOfObj = newArr.map(
      ({
        hospital_name: HospitalName,
        name: PatientName,
        patient_id: PatientId,
        field_worker: FieldWorkerName,
        camp_date: campDate,
      }) => ({
        HospitalName,
        PatientName,
        PatientId,
        FieldWorkerName,
        campDate,
      })
    );

    ExcelDownload(newArrayOfObj, "PatientDetails");
    setDateSearch("");
    setDownloadTab((k) => !k);
  };

  const toggleDrawerClose = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  const handleSearchValue = (values) => {
    setSearchValue(values);
  };
  const filterResponse = (val) => {
    setPageInfo((prevState) => ({ ...prevState, page: 1 }));
    setFilterValue(val);
  };

  // qr-popup utilities
  const handleClosePrevent = (reason) => {
    if (reason == "escapeKeyDown" || reason == "backdropClick") {
      setQr((prevState) => ({
        ...prevState,
        open: true,
      }));
    }
  };
  const handleCloseQr = () => {
    console.log("hit handleCloseQr");
    setQr((prevState) => ({
      ...prevState,
      open: false,
      dialogTitle: "",
      qrTitle: "",
      qrValue: "",
      patientName: "",
      patientId: "",
    }));
  };
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Typography variant="h5" color="secondary" className="text-left">
            Patient Management
          </Typography>
        </div>
        <div>
          <Button
            variant="contained"
            sx={{ width: "auto", textTransform: "capitalize" }}
            startIcon={<FileDownloadIcon />}
            onClick={() => {
              setDownloadTab((k) => !k);
            }}
            // onClick={handleuploadExcel}
          >
            Download Patient Details
          </Button>
        </div>
      </div>

      {downloadTab && (
        <div className="tabbackground">
          <div
            className={dateSearch == "custom" ? "nonreports" : "nonreports1"}
          >
            <div className="table">
              <Table>
                <TableHead>
                  <tr>
                    {/* <th><Checkbox
                      onChange={(e) => {
                        handleSelectAll(e);
                      }}
                    />
                      Select all</th> */}
                    <th> </th>
                    <th>Select Duration </th>
                  </tr>
                </TableHead>
              </Table>
              <div className="filter">
                <FormControl style={{ width: "80%" }} size="small">
                  <Select
                    labelId="select-label"
                    id="select"
                    variant="standard"
                    label="Select Dates"
                    value={dateSearch}
                    onChange={(e) => {
                      handleDates(e.target.value);
                    }}
                  >
                    {dates?.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {dateSearch == "custom" && (
                  <div style={{ width: "100%" }} className="custom">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="from_date"
                        value={
                          searchValue.eventStart == ""
                            ? null
                            : searchValue.eventStart
                        }
                        inputFormat="DD/MM/YYYY"
                        onChange={(e) => {
                          const selectedDate = e.$d;
                          if (selectedDate) {
                            setSearchValue({
                              ...searchValue,
                              eventStart: selectedDate,
                            });
                          } else {
                            setSearchValue({
                              ...searchValue,
                              eventStart: null,
                            });
                          }
                        }}
                        slotProps={{
                          actionBar: {
                            actions: ["clear"],
                          },
                        }}
                        renderInput={(props) =>
                          renderInputs(props, "From date", "date")
                        }
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="from_date"
                        value={
                          searchValue.eventEnd == ""
                            ? null
                            : searchValue.eventEnd
                        }
                        inputFormat="DD/MM/YYYY"
                        onChange={(e) => {
                          setSearchValue({ ...searchValue, eventEnd: e.$d });
                        }}
                        renderInput={(props) =>
                          renderInputs(props, "To date", "date")
                        }
                      />
                    </LocalizationProvider>
                  </div>
                )}
              </div>
            </div>
            <div className="downloadoptions">
              <Button
                variant="contained"
                onClick={() => {
                  setDownloadTab((k) => !k);
                  setDateSearch("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={dateSearch == ""}
                onClick={() => {
                  handleuploadExcel();
                }}
              >
                Download
              </Button>{" "}
              {/*onClick={handleuploadExcel}*/}
            </div>
          </div>
        </div>
      )}

      <PatientView
        isOpen={patientPopup}
        filter={"patient"}
        patientClose={closePatientView}
        viewType={viewType}
        patientId={patientId}
        prePopulate={patientDetails}
      />

      {addItem == false ? (
        <div style={{ width: "100%" }}>
          <div className="add-button"></div>

          <TableComponent
            header={header}
            // addList={addStates}
            actions={["view", "family_view", "glass", "patient_qr"]}
            datalist={HHSList}
            datalength={length}
            editData={(id) => editData(id)}
            deleteData={(id) => deleteDate(id)}
            viewDetails={(details) => viewPatient(details)}
            patientQrView={(data) => handlePatientQr(data)}
            OpenDialog={(data) => Glassdistribute(data)}
            viewFamily={(id) => viewFamily(id)}
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
            handleTablePage={handleTablePage}
            handleTableRowsPerPage={handleTableRowsPerPage}
            style={{ width: "100%" }}
            dateFields={dateFields}
            filter={"patient"}
            searchDataValue={(val) => handleSearchValue(val)}
            filterResponseValue={(val) => filterResponse(val)}
          ></TableComponent>
          {deletePopup ? (
            <ConfirmPopup
              message={"Are you sure, you want to delete this record"}
              deleteCancel={deleteCancel}
              deleteSubmit={deletesubmit}
            ></ConfirmPopup>
          ) : (
            ""
          )}
          <Dialog
            open={popup}
            scroll="paper"
            fullWidth={true}
            onClose={handleClose}
          >
            {" "}
            <Formik
              initialValues={initialValues}
              validationSchema={StateSchema}
              onSubmit={handleSubmit}
            >
              {/* {({ isSubmitting }) => ( */}
              <Form>
                <CardContent style={{ display: "flex" }}>
                  <HybridInput
                    label="State Name"
                    name="state_name"
                    fullWidth
                    type="text"
                    variant="standard"
                  />
                  <HybridSelect
                    label="Status"
                    name="status"
                    fullWidth
                    type="text"
                    variant="standard"
                    options={DropDown}
                    dropKey="status"
                  />
                  <div
                    style={{
                      width: "100px",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "10px",
                      display: "flex",
                    }}
                  >
                    <Button variant="contained" type="submit" color="primary">
                      Submit
                    </Button>
                  </div>
                </CardContent>
              </Form>
              {/* )} */}
            </Formik>
          </Dialog>
        </div>
      ) : (
        <>
          <PatientForm addPatientToggle={addPatientToggle}></PatientForm>
        </>
      )}

      {/* Qr Component */}
      <QrComponent
        qrProps={qr}
        onClosePrevent={(reason) => handleClosePrevent(reason)}
        onCloseIcon={() => handleCloseQr()}
      />

      {/* <Button onClick={toggleDrawer("right", true)}>data</Button> */}
      {/* <Drawer
        anchor={'right'}
        open={state['right']}
        onClose={toggleDrawer('right', false)}>


        <div style={{ marginTop: '25%', width: '30vw', marginLeft: '2%' }}>
          <Button variant="contained" type="submit" color="primary" onClick={() => toggleDrawerClose('right', false)} startIcon={<ArrowBackIosIcon />}>
            Back
          </Button>

          <div style={{ justifyContent: "center", marginTop: '20%', width: '40vw', marginLeft: '30%' }}>
            <h3>Glass Distributed Details</h3>
          </div>


          <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10%' }}>
            <Typography> <span style={{ fontWeight: 'bold' }}> Patient Name </span>  : {patientGlass?.name}</Typography>
            <Typography> <span style={{ fontWeight: 'bold' }}> Patient ID </span>  : {patientGlass?.patient_id}</Typography>
          </div>

          <div style={{ marginTop: '5%', marginLeft: '25%' }}>
         
            <FormControl variant="standard"  sx={{ m: 1, width:'60%' }}>
              <InputLabel id="demo-simple-select-standard-label"> Glass Distributed Details </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={glassDetails}
                label="glass_details"
                onChange={handleGlassChange}
              >
                <MenuItem value="glass_distribute_done">Glass Distribution Done</MenuItem>
                <MenuItem value="glass_prescription_follow_up">Glass Prescription-Follow-up</MenuItem>
                <MenuItem value="surgery_done">Surgery Done</MenuItem>

              
              </Select>
            </FormControl>
          </div>

          <div style={{ marginTop: '12%', marginLeft: '30%' }}>
            <Stack direction="row" spacing={2} >
              <Button variant="contained" type="submit" color="primary" onClick={() => handleGlassSubmit()}> Submit</Button>
              <Button variant="contained" type="submit" color="primary" onClick={() => toggleDrawerClose('right', false)}> Cancel</Button>
            </Stack>
          </div>
        </div>
      </Drawer> */}
    </>
  );
}

export default Patient_management;
