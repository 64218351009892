import {
  Dialog,
  Button,
  CardContent,
  Grid,
  Typography,
  Divider,
  IconButton,
  DialogContent,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useAxios } from "../hooks/useAxios";
import "../assets/styles/global.scss";
import "../assets/styles/viewForm.scss";
import { type } from "@testing-library/user-event/dist/type";


export default function PatientView({
  isOpen,
  patientClose,
  viewType,
  patientId,
  prePopulate,
  cbbf,

}) {
  const { axios } = useAxios();
  const [patientData, setPatientData] = useState([]);
  const [patientFamilyMembers, setPatientFamilyMembers] = useState([]);
  const [preparedPatientData, setPreparedPatientData] = useState([]);
  const [torchExamination, setTorchExamination] = useState([]);
  const [eyeWiseInfo, setEyeWiseInfo] = useState([]);
  const [referralType, setReferralType] = useState([]);
  const [information, setInformation] = useState([]);

  const [familyMemberNames, setFamilyMemberNames] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [spinner, setSpinner] = useState(true);
  const [subSpinner, setSubSpinner] = useState(false);
  const [open, setOpen] = useState(isOpen)

  useEffect(() => {
   
    constructView();
    return () => {
      deconstructView();
    };
  }, [isOpen, viewType]);

  const getPatientsData = async () => {
    let id = {
      id: patientId,
    };

    if (cbbf !== "cbbf") {
      await axios.post(`patientsDetails`, id).then((data) => {
    
        setPatientData(data.data.result);
        preparePatientDetailsView(data.data.result)
      });
      setSpinner(false);
    }
    else {
      let id = {
        _id: patientId,
      };
      await axios.post(`cbbfViewList`, id).then((data) => {
        
        setPatientData(data.data.result[0]);
        cbbfPreparePatientDetailsView(data.data.result[0])
      });
      setSpinner(false);
    }
  }


  const constructView = () => {
    if (isOpen && viewType === 1) {
    
      getPatientsData();
    } else if (viewType === 2) { getPatientsFamilyMembers(); }
  };


  const deconstructView = () => {
    setPreparedPatientData([]);
    setTorchExamination([]);
    setEyeWiseInfo([]);
    setReferralType([]);
    setSelectedMember("");
  };

  

  const preparePatientDetailsView = (patientValue) => {
    
    let p_details = [
      {
        id: 1,
        label: "Name",
        value: patientValue.name,
      },
      {
        id: 2,
        label: "Patient Id",
        value: patientValue.patient_id,
      },
      {
        id: 4,
        label: "Age",
        value: patientValue.age,
      },
      {
        id: 3,
        label: "Father's Name",
        value: patientValue.father_name,
      },
      {
        id: 5,
        label: "Gender",
        value: patientValue.gender,
      },
      {
        id: 6,
        label: "Phone",
        value: patientValue.phone,
      },
      // {
      //   id: 7,
      //   label: "State name",
      //   value: prePopulate.state,
      // },
      // {
      //   id: 8,
      //   label: "District name",
      //   value: prePopulate.district,
      // },
      // {
      //   id: 9,
      //   label: "Village name",
      //   value: prePopulate.village,
      // },
      {
        id: 7,
        label: "Street name",
        value: patientValue.street_name,
      },
      {
        id: 8,
        label: "Door no",
        value: patientValue.door_no,
      },
      {
        id: 9,
        label: "Id proof",
        value: patientValue.id_proof,
      },
      {
        id: 10,
        label: "Id proof number",
        value: patientValue.id_proof_number,
      },
      {
        id: 11,
        label: "Camp Place",
        value: prePopulate.camp_place,
      },
    ];
    let p_torchLight_examination = [
      {
        id: 1,
        label: "Eye position(L)",
        value: patientValue.tll_eye_position,
      },
      {
        id: 2,
        label: "Eye position(R)",
        value: patientValue.tlr_eye_position,
      },
      {
        id: 3,
        label: "Congestion(L)",
        value: patientValue.tll_congestion,
      },
      {
        id: 4,
        label: "Congestion(R)",
        value: patientValue.tlr_congestion,
      },
      {
        id: 5,
        label: "Lens(L)",
        value: patientValue.tll_lens,
      },
      {
        id: 6,
        label: "Lens(R)",
        value: patientValue.tlr_lens,
      },
    ];
    let eyeWise_info = [
      {
        id: 1,
        label: "History of surgery(LE)",
        value: patientValue.ewl_history_of_surgery,
      },
      {
        id: 2,
        label: "History of surgery(RE)",
        value: patientValue.ewr_history_of_surgery,
      },
      {
        id: 3,
        label: "Surgery hospital(LE)",
        value: patientValue.ewl_hospital_name,
      },
      {
        id: 4,
        label: "Surgery hospital(RE)",
        value: patientValue.ewr_hospital_name,
      },
      {
        id: 5,
        label: "Complaints(LE)",
        value: patientValue.ewl_complaints,
      },
      {
        id: 6,
        label: "Complaints(RE)",
        value: patientValue.ewr_hospital_name,
      },
      {
        id: 7,
        label: "Provisional diagnosis(LE)",
        value: patientValue.ewl_provisional_diagnosis,
      },
      {
        id: 8,
        label: "Provisional diagnosis(RE)",
        value: patientValue.ewr_provisional_diagnosis,
      },
    ];
    let referral_type = [

      {
        id: 1,
        label: "Ref number",
        value: patientValue.referral_number,
      },
      {
        id: 2,
        label: "Follow up",
        value: patientValue.follow_up,
      },
      {
        id: 3,
        label: "Forward location",
        value: patientValue.forward_location,
      },
      {
        id: 4,
        label: "Forward type",
        value: patientValue.forward_type,
      },
    ];
    // let information=[
    //   {
    //     id: 1,
    //     label: "Distant vision(LE) :",
    //     value: patientValue.presenting_left_eye,
    //   },
    //   {
    //     id: 2,
    //     label: "Distant vision(RE) :",
    //     value: patientValue.presenting_right_eye,
    //   },
    // ]
    setPreparedPatientData(p_details);
    setTorchExamination(p_torchLight_examination);
    setEyeWiseInfo(eyeWise_info);
    setReferralType(referral_type);
    setInformation(information)
  };
  const cbbfPreparePatientDetailsView = (patientValue) => {
   
    let p_details = [
      {
        id: 1,
        label: "Name",
        value: patientValue.name,
      },
      {
        id: 2,
        label: "Patient Id",
        value: patientValue.s_no,
      },
      {
        id: 4,
        label: "Age",
        value: patientValue.age,
      },
      {
        id: 5,
        label: "Gender",
        value: patientValue.sex,
      },
      {
        id: 6,
        label: "Phone",
        value: patientValue.phone,
      },
      {
        id: 8,
        label: "Door no",
        value: patientValue.door_no,
      },
      {
        id: 9,
        label: "Id proof",
        value: patientValue.aadhar_number,
      },
      {
        id: 11,
        label: "Camp Place",
        value: patientValue.camp_place,
      },
    ];
    let p_torchLight_examination = [
      {
        id: 1,
        label: "Eye position(L)",
        value: patientValue.tll_eye_position,
      },
      {
        id: 2,
        label: "Eye position(R)",
        value: patientValue.tlr_eye_position,
      },
      {
        id: 3,
        label: "Congestion(L)",
        value: patientValue.tll_congestion,
      },
      {
        id: 4,
        label: "Congestion(R)",
        value: patientValue.tlr_congestion,
      },
      {
        id: 5,
        label: "Lense(L)",
        value: patientValue.tll_lens,
      },
      {
        id: 6,
        label: "Lense(R)",
        value: patientValue.tlr_lens,
      },
    ];
    let eyeWise_info = [
      {
        id: 1,
        label: "Already wearing Glass",
        value: patientValue.already_wearing_glass,
      },
      {
        id: 2,
        label: "Cataract Surgery(LE)",
        value: patientValue.cataract_surgery_left_eye,
      },
      {
        id: 3,
        label: "Cataract Surgery(RE)",
        value: patientValue.cataract_surgery_right_eye,
      },
      {
        id: 4,
        label: "Optometrists Status",
        value: patientValue.optometrists_status,
      },
      {
        id: 5,
        label: "Visual Acuity(LE)",
        value: patientValue.visual_acuity_left,
      },
      {
        id: 7,
        label: "Visual Acuity(RE)",
        value: patientValue.visual_acuity_right,
      },
      {
        id: 8,
        label: "History Of Diabetic",
        value: patientValue.history_of_diabetic,
      },
      {
        id: 9,
        label: "History Of Hyper Tension",
        value: patientValue.history_of_hyper_tension,
      },
    ];

    setPreparedPatientData(p_details);
    // setTorchExamination(p_torchLight_examination);
    setEyeWiseInfo(eyeWise_info);
    // setReferralType(referral_type);
  };


  const getPatientsFamilyMembers = async () => {
    let id = {
      id: patientId,
    };
    await axios.post(`patientsFamilyDetails`, id).then((data) => {
      // console.log("data", data);
      if (data.data.success === true) {
        setPatientFamilyMembers(data.data.result.members);

        preparePatientFamilyDropDown(data.data.result.members);
      }
    });
    setSpinner(false);
  };
  const preparePatientFamilyDropDown = (data) => {
    let familyMemberName = data.map((val) => val["name"]);
    setFamilyMemberNames(familyMemberName);
  };

  const prepareFamilyMemberView = (event) => {
    setSubSpinner(true);
    setSelectedMember(event.target.value);
    let selectedFamilyMember = patientFamilyMembers[event.target.value];
    let p_details = [
      {
        id: 1,
        label: "Name",
        value: selectedFamilyMember.name,
      },
      {
        id: 2,
        label: "Patient Id",
        value: selectedFamilyMember.patient_id,
      },
      {
        id: 4,
        label: "Age",
        value: selectedFamilyMember.age,
      },
      {
        id: 3,
        label: "Father's Name",
        value: selectedFamilyMember.relation_name,
      },

      {
        id: 5,
        label: "Gender",
        value: selectedFamilyMember.gender,
      },
      {
        id: 6,
        label: "Phone",
        value: selectedFamilyMember.phone,
      },
      // {
      //   id: 7,
      //   label: "State name",
      //   value: prePopulate.state,
      // },
      // {
      //   id: 8,
      //   label: "District name",
      //   value: prePopulate.district,
      // },
      // {
      //   id: 9,
      //   label: "Village name",
      //   value: prePopulate.village,
      // },
      {
        id: 7,
        label: "Street name",
        value: selectedFamilyMember.street_name,
      },
      {
        id: 8,
        label: "Door no",
        value: selectedFamilyMember.door_no,
      },
      {
        id: 9,
        label: "Id proof",
        value: selectedFamilyMember.id_proof,
      },
      {
        id: 10,
        label: "Id proof number",
        value: selectedFamilyMember.id_proof_number,
      },
      {
        id: 11,
        label: "Camp name",
        value: prePopulate.name,
      },
    ];
    let p_torchLight_examination = [
      {
        id: 1,
        label: "Eye position(L)",
        value: selectedFamilyMember.tll_eye_position,
      },
      {
        id: 2,
        label: "Eye position(R)",
        value: selectedFamilyMember.tlr_eye_position,
      },
      {
        id: 3,
        label: "Congestion(L)",
        value: selectedFamilyMember.tll_congestion,
      },
      {
        id: 4,
        label: "Congestion(R)",
        value: selectedFamilyMember.tlr_congestion,
      },
      {
        id: 5,
        label: "Lense(L)",
        value: selectedFamilyMember.tll_lens,
      },
      {
        id: 6,
        label: "Lense(R)",
        value: selectedFamilyMember.tlr_lens,
      },
    ];
    let eyeWise_info = [
      {
        id: 1,
        label: "History of surgery(LE)",
        value: selectedFamilyMember.ewl_history_of_surgery,
      },
      {
        id: 2,
        label: "History of surgery(RE)",
        value: selectedFamilyMember.ewr_history_of_surgery,
      },
      {
        id: 3,
        label: "Surgery hospital(LE)",
        value: selectedFamilyMember.ewl_hospital_name,
      },
      {
        id: 4,
        label: "Surgery hospital(RE)",
        value: selectedFamilyMember.ewr_hospital_name,
      },
      {
        id: 5,
        label: "Complaints(LE)",
        value: selectedFamilyMember.ewl_complaints,
      },
      {
        id: 6,
        label: "Complaints(RE)",
        value: selectedFamilyMember.ewr_hospital_name,
      },
      {
        id: 7,
        label: "Provisional diagnosis(LE)",
        value: selectedFamilyMember.ewl_provisional_diagnosis,
      },
      {
        id: 8,
        label: "Provisional diagnosis(RE)",
        value: selectedFamilyMember.ewr_provisional_diagnosis,
      },
    ];
    let referral_type = [

      {
        id: 1,
        label: "Ref number",
        value: selectedFamilyMember.referral_number,
      },
      {
        id: 2,
        label: "Follow up",
        value: selectedFamilyMember.follow_up,
      },
      {
        id: 3,
        label: "Forward location",
        value: selectedFamilyMember.forward_location,
      },
      {
        id: 4,
        label: "Forward type",
        value: selectedFamilyMember.forward_type,
      },
    ];
    setPreparedPatientData(p_details);
    setTorchExamination(p_torchLight_examination);
    setEyeWiseInfo(eyeWise_info);
    setReferralType(referral_type);
    setSubSpinner(false);
  };

  return (
    <Dialog
      open={isOpen}
      scroll="paper"
      fullWidth={true}
      maxWidth= {cbbf === "cbbf" ? 'md' :"xl"}
      onClose={() => patientClose()}
      style={{marginLeft:'15%'}}
    >
      {" "}
      <div className="d-flex justify-content-between  p-1">
        <Typography variant="h5" color="secondary" className="text-left">
          {viewType == 1 ? "Patient Details" : "Patient's family details"}
        </Typography>
        <IconButton aria-label="delete" onClick={() => patientClose()}>
          <CloseIcon />
        </IconButton>
      </div>{" "}
      <Divider />
      <DialogContent className="overflowY-hidden h-50">
        {spinner ? (
          <div className="text-center">
            <CircularProgress color="primary" />
          </div>
        ) : (
          <>
            {viewType == 1 ? (
              <div className="miniCard-wrapper">
                <div className="mini-card" >
                  <p className="card-header primary-color">
                    Patient Information
                  </p>{" "}
                  {preparedPatientData.map((data) => {
                    return (
                      <span key={data.id} className="card-span">
                        <strong>{data.label} :</strong>
                        {data.value}
                      </span>
                    );
                  })}
                </div>
                <div className="mini-card">
                  <p className="card-header primary-color">
                    Patient Illness Details
                  </p>{" "}
                  <span className="card-span">
                    <strong>Systemic Illness :</strong>
                    <p className="m-0 mb-1">{patientData.illness !== '' ? patientData.illness:'none' }</p>
                  </span>
                  
                    {
                      cbbf === "cbbf" ? '' :
                        <>
                          <p className="card-header primary-color">
                            Present Vision Details
                          </p>{" "}
                          <span className="card-span">
                            <strong>Distant Vision(RE) :</strong>
                            <p className="m-0 mb-1">{patientData.presenting_right_eye}</p>
                          </span>
                          <span className="card-span">
                            <strong>Distant Vision(LE) :</strong>
                            <p className="m-0 mb-1">{patientData.presenting_left_eye}</p>
                          </span>
                        </>

                    }
               
                  <p className="card-header primary-color">
                    Eye Wise Information
                  </p>{" "}
                  {eyeWiseInfo.map((data) => {
                    return (
                      <span key={data.id} className="card-span">
                        <strong>{data.label} :</strong>
                        <p className="m-0 mb-1">{typeof (data.value) == 'boolean' ? data?.value ? 'True' : 'False' : data?.value}</p>
                      </span>
                    );
                  })}
                </div>
                {
                  cbbf === "cbbf" ? "" :

                    <div className="mini-card">
                      <p className="card-header primary-color">
                        {cbbf === "cbbf" ? "" : "Torch Examination Details"}
                      </p>{" "}
                      {torchExamination.map((data) => {
                        return (
                          <span key={data.id} className="card-span">
                            <strong>{data.label} :</strong>
                            <p className="m-0 mb-1">{data.value}</p>
                          </span>
                        );
                      })}
                      <p className="card-header primary-color">
                        {cbbf === "cbbf" ? "" : "Referral Information"}

                      </p>{" "}
                      {referralType.map((data) => {
                        return (
                          <span key={data.id} className="card-span">
                            <strong>{data.label} :</strong>
                            <p className="m-0 mb-1">{data.value}</p>
                          </span>
                        );
                      })}
                    </div>
                }
              </div>
            ) : (
              <>
                <FormControl
                  fullWidth
                  className="mb-2"
                  variant="standard"
                  sx={{ m: 1, minWidth: 45 }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Family Member
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    value={selectedMember}
                    onChange={(event) => prepareFamilyMemberView(event)}
                    label="Age"
                    fullWidth
                  >
                    {familyMemberNames.map((val, index) => {
                      return (
                        <MenuItem key={index} value={index}>
                          {val}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {subSpinner ? (
                  <div className="text-center">
                    <CircularProgress color="primary" />
                  </div>
                ) : (
                  <>
                    {" "}
                    {selectedMember !== "" ? (
                      <div className="miniCard-wrapper">
                        <div className="mini-card">
                          <p className="card-header primary-color">
                            Patient Information
                          </p>{" "}

                          {preparedPatientData.map((data) => {
                            return (
                              <span key={data.id} className="card-span">
                                <strong>{data.label} :</strong>
                                <p className="m-0 mb-1">{data.value}</p>
                              </span>
                            );
                          })}
                        </div>
                        <div className="mini-card">
                          <p className="card-header primary-color">
                            Patient Illness Details
                          </p>{" "}
                          <span className="card-span">
                            <strong>Systemic Illness :</strong>
                            <p className="m-0 mb-1">{patientData.illness}</p>
                          </span>
                          {
                            cbbf === "cbbf" ? '' :
                              <>
                                <p className="card-header primary-color">
                                  Present Vision Details
                                </p>{" "}
                                <span className="card-span">
                                  <strong>Distant Vision(RE) :</strong>
                                  <p className="m-0 mb-1">{patientData.presenting_right_eye}</p>
                                </span>
                                <span className="card-span">
                                  <strong>Distant Vision(LE) :</strong>
                                  <p className="m-0 mb-1">{patientData.presenting_left_eye}</p>
                                </span>
                              </>

                          }
                          {/* <p className="card-header primary-color">
                            Present Vision Details
                          </p>{" "}
                          <span className="card-span">
                            <strong>Distant Vision(RE) :</strong>
                            <p className="m-0 mb-1">{patientData.presenting_right_eye}</p>
                          </span>
                          <span className="card-span">
                            <strong>Distant Vision(LE) :</strong>
                            <p className="m-0 mb-1">{patientData.presenting_left_eye}</p>
                          </span> */}
                        </div>
                        <div className="mini-card">
                          <p className="card-header primary-color">
                            Eye Wise Information
                          </p>{" "}
                          {eyeWiseInfo.map((data) => {
                            return (
                              <span key={data.id} className="card-span">
                                <strong>{data.label} :</strong>
                                <p className="m-0 mb-1">{data.value}</p>
                              </span>
                            );
                          })}
                        </div>
                        <div className="mini-card">
                          <p className="card-header primary-color">
                            Torch Examination Details
                          </p>{" "}
                          {torchExamination.map((data) => {
                            return (
                              <span key={data.id} className="card-span">
                                <strong>{data.label} :</strong>
                                <p className="m-0 mb-1">{data.value}</p>
                              </span>
                            );
                          })}

                          <p className="card-header primary-color">
                            Referral Information
                          </p>{" "}
                          {referralType.map((data) => {
                            return (
                              <span key={data.id} className="card-span">
                                <strong>{data.label} :</strong>
                                <p className="m-0 mb-1">{data.value}</p>
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
