import { createTheme } from "@mui/material/styles";
// import colors from "./assets/theme.scss";
// import colors from "./assets/styles/theme.scss";
import colors from "../styles/theme.scss";

const theme = createTheme({
  palette: {
    primary: {
      light: colors.lightPrimary,
      main: colors.mainPrimary,
      dark: colors.darkPrimary,
      shade: colors.shadePrimary,
      contrastText: colors.contrastTextPrimary,
    },
    secondary: {
      light: colors.lightSecondary,
      main: colors.mainSecondary,
      dark: colors.darkSecondary,
      contrastText: colors.contrastTextSecondary,
    },
    black  : {
      main : colors.black
    }
  },
});

export default theme;
