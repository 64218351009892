import React from 'react'
import { Box, Paper } from '@mui/material';

import VisionHome from './VisionCenter/VisionHome'
import InduvidualHome from './InduvidualScreen/InduvidualHome'
import Gsi from './Gsi/GsiHome'
import CotaractHome from './Cotaract/CotaractHome'





const Home = (props) => {
  const { InduvidualScreen, visionCenter, Cotaract } = props
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', '& > :not(style)': { mt: 2, mb: 2, width: "30%", } }}  >
      <Paper elevation={1} sx={{ border: '1px solid #e4e4e4' }}>
        <InduvidualHome visionCenter={visionCenter} />
      </Paper>
      <Paper elevation={1} sx={{ border: '1px solid #e4e4e4' }}>
        <Gsi InduvidualScreen={InduvidualScreen} Cotaract={Cotaract} />
      </Paper>
      <Paper elevation={1} sx={{ border: '1px solid #e4e4e4' }}>
        <VisionHome InduvidualScreen={InduvidualScreen} Cotaract={Cotaract} />
      </Paper>
    </Box >
  )
}

export default Home