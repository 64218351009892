import React from 'react'
import { Chart } from "react-google-charts";
import './Style.css'

import CotaractHome from '../Cotaract/CotaractHome';
import PublicIcon from '@mui/icons-material/Public';


const VisionHome = (props) => {
    const { InduvidualScreen, Cotaract } = props
    /*---------------- induvisal screen data ------------*/
    const data = [
        ["gender", "data"],

        ["Male", InduvidualScreen?.total_males],
        ["Female", InduvidualScreen?.total_females],
        // ["others", InduvidualScreen?.total_others],
        ["others", InduvidualScreen?.total_others],

    ];


    const options = {
        slices: { 0: { color: '#00B0F0' }, 1: { color: '#92D050' }, 2: { color: 'darkblue' } },
    };

    return (
        <div  >
            <p style={{ margin: ' 10px', fontWeight: 'bold',textAlign:'start' }} >Total No. of Individual Screened</p>

            <Chart
                chartType="PieChart"
                data={data}
                options={options}
                width={"100%"}
                height={"48vh"}
            />
        </div>
    )
}

export default VisionHome