import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Dialog, Button, CardContent, Typography } from "@mui/material";
import TableComponent from "../components/table";
import { useAxios } from "../hooks/useAxios";
import DynamicInput from "../components/dy_input";
import { HybridInput, HybridSelect } from "../components/hybrid_inputs";
import { StateSchema } from "../validations/userSchema";
import { Form, Formik } from "formik";
import { toast, Slide } from "react-toastify";
import ConfirmPopup from "../components/confirmPopup";
import PatientForm from "../components/patientform";
import PatientView from "../forms/paient_view";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelDownload from "../components/ExcelFormat/ExcelFileDownload";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";

import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const CBBFOptem = () => {
  // visual acuity dropdown
  const visualAcuity = [
    {
      id: 1,
      value: "PL Negative",
    },
    {
      id: 2,
      value: "PL Positive",
    },
    {
      id: 3,
      value: "HMCF",
    },
    {
      id: 4,
      value: "FCCF",
    },
    {
      id: 5,
      value: "1/60",
    },
    {
      id: 6,
      value: "2/60",
    },
    {
      id: 7,
      value: "3/60",
    },
    {
      id: 8,
      value: "4/60",
    },
    {
      id: 9,
      value: "5/60",
    },
    {
      id: 10,
      value: "6/60",
    },
    {
      id: 11,
      value: "6/36",
    },
    {
      id: 12,
      value: "6/24",
    },
    {
      id: 13,
      value: "6/18",
    },
    {
      id: 14,
      value: "6/12",
    },
    {
      id: 15,
      value: "6/9",
    },
    {
      id: 16,
      value: "6/6",
    },
  ];

  const [header] = useState([
    {
      id: 1,
      name: "Patient ID",
      value: "referral_number",
    },
    {
      id: 2,
      name: "Patient Name",
      value: "name",
    },
    {
      id: 3,
      name: "Hospital Name",
      value: "hospital_name",
    },
    {
      id: 4,
      name: "Fieldworker Name",
      value: "field_worker_name",
    },
    {
      id: 5,
      name: "Entry Date",
      value: "create_on",
    },
  ]);
  let dateFields = ["create_on"];
  const [patientPopup, openPatientPopup] = useState(false);

  const { axios } = useAxios();
  const [optem, setOptem] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [popup, setpopup] = useState(false);
  const [patientDetails, setPatientDetails] = useState([optem]);
  const [addItem, setAddItem] = useState(false);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [patientGlass, setPatientGlassDistribute] = useState();
  const [glassDetails, setGlassDetails] = React.useState("");

  // const [color, setColor] = useState("");
  const [patientId, setPatientId] = useState("");
  const [viewType, setViewType] = useState(0);
  const [length, setLength] = useState();

  const [visualAcuityVal, setVisualAcuityVal] = useState({
    web_visual_acuity_left: "",
    web_visual_acuity_right: "",
  });

  let paginationInfo = {
    page: 1,
    count: 0,
    rowsPerPage: 10,
  };
  const [pageInfo, setPageInfo] = useState(paginationInfo);

  const [filterValue, setFilterValue] = useState({
    campEnd: "",
    campStart: "",
    hospital_name: "",
    name: "",
  });

  const handleGlassChange = (event) => {
    setGlassDetails(event.target.value);
    if (event.target.value == "nsds") {
      setVisualAcuityVal((prevState) => ({
        ...prevState,
        web_visual_acuity_left: "",
        web_visual_acuity_right: "",
      }));
    }
  };

  const handleVisualAcuity = (event) => {
    const { name, value } = event?.target;
    setVisualAcuityVal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchOptemdata();
  }, [state, filterValue]);

  async function fetchOptemdata() {
    try {
      // AttendanceList?limit=10&page=1&fromDate=&toDate=&field_worker_name=bava&hospital_name=
      await axios
        .post(
          `optometristsSurveyList?limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}&fromDate=${filterValue.campStart}&toDate=${filterValue.campEnd}&name=${filterValue.name}&hospital_name=${filterValue.hospital_name}`
        )
        .then((res) => {
          var data = res.data.result;

          setPageInfo((prevState) => ({
            ...prevState,
            count: data.length,
          }));
          setLength(data.length);
          setOptem(data?.list);
        });
    } catch (error) {
      console.log("optemerror", error);
    }
  }

  const toggleDrawerClose = (anchor, open) => {
    setState({ ...state, [anchor]: open });
    setVisualAcuityVal((prevState) => ({
      ...prevState,
      web_visual_acuity_left: "",
      web_visual_acuity_right: "",
    }));
  };

  const toggleDrawer = (anchor, open) => {
    if (open) {
      setState({ ...state, [anchor]: open });
    }
  };

  const Glassdistribute = (data) => {
    var base_hospital_status = data?.base_hospital_status;
    var glass_prescription_status = data?.glass_prescription_status;
    var vision_center_status = data?.vision_center_status;
    var surgery_status = data?.surgery_status;
    var nsds = data?.nsds;

    if (base_hospital_status) {
      setGlassDetails("3");
    } else if (glass_prescription_status) {
      setGlassDetails("1");
    } else if (vision_center_status) {
      setGlassDetails("4");
    } else if (surgery_status == "4") {
      setGlassDetails("2");
    } else if (nsds == "nsds") {
      setGlassDetails("nsds");
    } else {
      setGlassDetails("");
    }

    setVisualAcuityVal((prevState) => ({
      ...prevState,
      web_visual_acuity_left: data?.web_visual_acuity_left || "",
      web_visual_acuity_right: data?.web_visual_acuity_right || "",
    }));

    // setGlassDetails()
    setPatientGlassDistribute(data);
    toggleDrawer("right", true);
  };

  const addPatientToggle = () => {
    setAddItem((prevState) => !prevState);
    fetchOptemdata();
  };

  const viewPatient = (details) => {
    setPatientId(details.patient_id);
    setPatientDetails(details);
    setViewType(1);
    closePatientView();
  };

  const closePatientView = () => {
    openPatientPopup((prevState) => (prevState = !prevState));
  };

  const viewFamily = (details) => {
    setPatientId(details);
    setPatientDetails(details);

    setViewType(2);
    closePatientView();
  };

  const handleGlassSubmit = (data) => {
    let obj = {
      patient_id: data,
      status: glassDetails,
      web_visual_acuity_left: visualAcuityVal.web_visual_acuity_left,
      web_visual_acuity_right: visualAcuityVal.web_visual_acuity_right,
    };
    axios.post(`CBBFStatusChange`, obj).then((res) => {
      if (res.data.success === true) {
        setState({ ...state, ["right"]: false });
        setGlassDetails("");
        setVisualAcuityVal((prevState) => ({
          ...prevState,
          web_visual_acuity_left: "",
          web_visual_acuity_right: "",
        }));
      }
    });
  };

  useEffect(() => {
    fetchOptemdata();
  }, [pageInfo.page, pageInfo.rowsPerPage]);

  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };
  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };

  const filterResponse = (val) => {
    setPageInfo(paginationInfo);

    setFilterValue({
      ...filterValue,
      campEnd: val.campEnd,
      campStart: val.campStart,
      hospital_name: val.hospital_name,
      name: val.name,
    });
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Typography variant="h5" color="secondary" className="text-left">
            {/* CBBF Optem */}
            {/* Volunteers Screening Optem */}
            Intervention
          </Typography>
        </div>
      </div>

      <PatientView
        isOpen={patientPopup}
        filter={"patient"}
        patientClose={closePatientView}
        viewType={viewType}
        patientId={patientId}
        prePopulate={patientDetails}
        cbbf="cbbf"
      />

      {addItem == false ? (
        <div style={{ width: "100%" }}>
          <div className="add-button"></div>

          <TableComponent
            header={header}
            actions={["view", "family_view", "glass"]}
            datalist={optem}
            datalength={length}
            colors={true}
            // editData={(id) => editData(id)}
            // deleteData={(id) => deleteDate(id)}
            viewDetails={(details) => viewPatient(details)}
            OpenDialog={(data) => Glassdistribute(data)}
            viewFamily={(id) => viewFamily(id)}
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
            handleTablePage={handleTablePage}
            handleTableRowsPerPage={handleTableRowsPerPage}
            style={{ width: "100%" }}
            dateFields={dateFields}
            filter={"patient"}
            fieldworker={false}
            filterResponseValue={(val) => filterResponse(val)}
          ></TableComponent>
          {deletePopup ? (
            <ConfirmPopup
              message={"Are you sure, you want to delete this record"}
              // deleteCancel={deleteCancel}
              // deleteSubmit={deletesubmit}
            ></ConfirmPopup>
          ) : (
            ""
          )}
          {/* <Dialog
                        open={popup}
                        scroll="paper"
                        fullWidth={true}
                        onClose={handleClose}
                    >
                        {" "}
                        <Formik
                            initialValues={initialValues}
                            validationSchema={StateSchema}
                            onSubmit={handleSubmit}
                        >
                            <Form>
                                <CardContent style={{ display: "flex" }}>
                                    <HybridInput
                                        label="State Name"
                                        name="state_name"
                                        fullWidth
                                        type="text"
                                        variant="standard"
                                    />
                                    <HybridSelect
                                        label="Status"
                                        name="status"
                                        fullWidth
                                        type="text"
                                        variant="standard"
                                        options={DropDown}
                                        dropKey="status"
                                    />
                                    <div
                                        style={{
                                            width: "100px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            margin: "10px",
                                            display: "flex",
                                        }}
                                    >
                                        <Button variant="contained" type="submit" color="primary">
                                            Submit
                                        </Button>
                                    </div>
                                </CardContent>
                            </Form>
                        </Formik>

                    </Dialog> */}
        </div>
      ) : (
        <>
          <PatientForm addPatientToggle={addPatientToggle}></PatientForm>
        </>
      )}
      {/* <Button onClick={toggleDrawer("right", true)}>data</Button> */}

      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        <div style={{ marginTop: "25%", width: "30vw", marginLeft: "2%" }}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            onClick={() => toggleDrawerClose("right", false)}
            startIcon={<ArrowBackIosIcon />}
          >
            Back
          </Button>
          <div
            style={{
              justifyContent: "center",
              marginTop: "20%",
              width: "40vw",
              marginLeft: "30%",
            }}
          >
            {/* <h3>Glass Distributed Details</h3> */}
            <h3>Intervention Details</h3>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "10%",
            }}
          >
            <Typography>
              {" "}
              <span style={{ fontWeight: "bold" }}> Patient Name </span> :{" "}
              {patientGlass?.name}
            </Typography>
          </div>
          <div
            style={{
              marginTop: "5%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <FormControl variant="standard" sx={{ m: 1, width: "80%" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Select{" "}
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={glassDetails}
                label="glass_details"
                onChange={handleGlassChange}
              >
                {patientGlass?.advised_to == "Glass Prescription" && (
                  <MenuItem value="1">Glass Distribution Done</MenuItem>
                )}
                {patientGlass?.advised_to == "Cataract" && (
                  <MenuItem value="2">Surgery Done</MenuItem>
                )}
                {patientGlass?.advised_to == "Refer to Base Hospital" && (
                  <MenuItem value="3">Reported to Base hospital</MenuItem>
                )}
                {patientGlass?.advised_to == "Refer to Vision Center" && (
                  <MenuItem value="4">Reported to vision center</MenuItem>
                )}
                <MenuItem value="nsds">NSDS</MenuItem>
              </Select>
            </FormControl>
          </div>
          {(patientGlass?.advised_to == "Glass Prescription" ||
            patientGlass?.advised_to == "Cataract") && (
            <div
              style={{
                marginTop: "5%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FormControl variant="standard" sx={{ mr: 0.5, width: "40%" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Visual Acuity - Left
                </InputLabel>
                <Select
                  disabled={glassDetails == "nsds" ? true : false}
                  name="web_visual_acuity_left"
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={visualAcuityVal.web_visual_acuity_left}
                  label="Visual Acuity - Left"
                  onChange={handleVisualAcuity}
                >
                  {visualAcuity?.map(({ id, value }) => (
                    <MenuItem key={id} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ ml: 0.5, width: "40%" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Visual Acuity - Right
                </InputLabel>
                <Select
                  disabled={glassDetails == "nsds" ? true : false}
                  name="web_visual_acuity_right"
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={visualAcuityVal.web_visual_acuity_right}
                  label="Visual Acuity - Right"
                  onChange={handleVisualAcuity}
                >
                  {visualAcuity?.map(({ id, value }) => (
                    <MenuItem key={id} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}

          <div style={{ marginTop: "12%", marginLeft: "30%" }}>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                onClick={() => handleGlassSubmit(patientGlass?.patient_id)}
              >
                {" "}
                Submit
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                onClick={() => toggleDrawerClose("right", false)}
              >
                {" "}
                Cancel
              </Button>
            </Stack>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default CBBFOptem;
