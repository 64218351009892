import { TextField, Stack, Button } from "@mui/material";
import React, { useState } from "react";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";

const CampFilter = ({ filterObject }) => {
  const [campStartDate, setStartDate] = useState(null);
  const [campEndDate, setEndDate] = useState(null);
  const [campId, setCampId] = useState("");
  const [campPlace, setCampPlace] = useState("");

  const handleSubmit = () => {
    let obj = {
      campStart: campStartDate
        ? moment(campStartDate.$d).format("YYYY-MM-DD")
        : "",
      campEnd: campEndDate ? moment(campEndDate.$d).format("YYYY-MM-DD") : "",
      campIds: campId,
      campPlaces: campPlace,
    };
    filterObject(obj);
  };

  const handleClose = () => {
    let obj = {
      campStart: "",
      campEnd: "",
      campIds: "",
      campPlaces: "",
    };
    setStartDate(null);
    setEndDate(null);
    setCampId(obj.campIds);
    setCampPlace(obj.campPlaces);
    filterObject(obj);
  };

  return (
    <div style={{ marginBottom: "2%" }}>
      <Stack direction="row" spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="From Date"
            renderInput={(props) => <TextField {...props}  size="small" style={{width:'15%'}} />}
            value={campStartDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="To Date"
            renderInput={(props) => <TextField {...props}  size="small" style={{width:'15%'}} />}
            value={campEndDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
            minDate={campStartDate ? new Date(moment(campStartDate.$d)) : ""}
          />
        </LocalizationProvider>
        <TextField
        style={{width:'15%'}}
          variant="outlined"
          label="Camp place"  size="small"
          value={campPlace}
          onChange={(e) => setCampPlace(e.target.value)}
        />
        <TextField
          variant="outlined"  size="small"
          label="Camp Number"
          value={campId}
          onChange={(e) => setCampId(e.target.value)}
          style={{width:'15%'}}
        />
        <Button
        sx={{textTransform:'capitalize'}}  size="small"
          variant="contained"
          style={{ width: "11%"   }}
          onClick={handleSubmit}
        >
          Search
        </Button>
        <Button
        sx={{textTransform:'capitalize'}}
          variant="contained"
          style={{ width: "11%" }}
          onClick={handleClose}
        >
          Reset
        </Button>
      </Stack>
    </div>
  );
};
export default CampFilter;
