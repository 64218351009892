import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Box, Button, Divider, Paper, Typography, Link } from "@mui/material";
import "./Style.css";
import { Link as RouterLink } from "react-router-dom";
import image from "../assets/servis.png";
import images from "../assets/logo.png";
import axios from "axios";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { HybridInput } from "../components/hybrid_inputs";
import { LoginSchema } from "../validations/userSchema";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { toast, Slide } from "react-toastify";

const LoginPage = () => {
  const navigate = useNavigate();
  const { dispatch } = useAuth();
  const [resError, setResError] = React.useState(false);
  const [resErrorMsg, setResErrorMsg] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/masters/");
    }
  }, []);
  const formSubmit = async (values, actions) => {
    setResError(false);

    await axios
      .post(process.env.REACT_APP_BASE_URL_LOGIN + "login", values, {
        headers: {
          "Access-Control-Allow-Private-Network": "true",
        },
      })
      .then((res) => {
        const { username, token, _id, hospital_id, hospital_name } =
          res.data.result;

        var roles = res.data.result.role_permission[0].permission;

        localStorage.setItem("name", username);
        localStorage.setItem("token", token);
        localStorage.setItem("sankara_admin_id", _id);
        // localStorage.setItem("roles", roles);
        localStorage.setItem("hospital_id", hospital_id);
        localStorage.setItem("hospital_name", hospital_name);

        var role = [];

        roles.map((i) => {
          var name = i.screen_name.replaceAll("_", "");
          name = name.toLowerCase();
          var b = { screen_name: name, action: i.action, _id: i._id };
          role.push(b);
        });

        localStorage.setItem("roles", JSON.stringify(role));

        if (token && token !== undefined) {
          let data = {
            name: username,
            token: token,
            roles: role,
          };
          dispatch({
            type: "LOGIN",
            payload: data,
          });
          window.location.reload();
        }

        actions.resetForm();
      })
      .catch((err) => {
        toast.error("Incorrect Username or Password", {
          autoClose: 2000,
          transition: Slide,
        });
        console.log(err);
        setResError(true);
        setResErrorMsg(err.response.data.message);
      });
  };

  return (
    <div className="login">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={formSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Box
            sx={{
              width: "55rem",
              display: "inline-block",
              p: 1,
              mx: 1,
              marginLeft: "10%",
            }}
          >
            <Form>
              <Paper elevation={24}>
                <div className="login-content">
                  <div>
                    <img src={images} width="340" />
                    <img src={image} width="340" className="image-content" />
                    {/* <p className="Servis">SERVIS</p> */}
                  </div>
                  <div className="text-content">
                    <div
                      style={{
                        color: "orange",
                        marginTop: "10%",
                        marginBottom: "11%",
                        marginLeft: "40%",
                      }}
                    >
                      {" "}
                      <Typography
                        style={{ fontSize: "2rem", fontWeight: "bold" }}
                      >
                        Log In{" "}
                      </Typography>
                    </div>
                    <div className="form">
                      <div className="text-field">
                        <HybridInput
                          className="inputRounded"
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                          setVal={setFieldValue}
                          fullwidth
                          name="email"
                          type="email"
                        />
                      </div>
                      <div>
                        <HybridInput
                          className="inputRounded"
                          id="outlined-basic"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          label="Password"
                          variant="outlined"
                          setVal={setFieldValue}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{ marginRight: "5px" }}
                              >
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div className="divider">
                        <Divider variant="middle" sx={{ bgcolor: "orange" }} />
                      </div>
                    </div>
                    <div className="button">
                      <Button
                        variant="contained"
                        style={{
                          width: "141%",
                          marginLeft: "-16px",
                          borderRadius: "24px",
                          fontWeight: "bold",
                        }}
                        type="submit"
                      >
                        {" "}
                        Log In
                      </Button>
                    </div>
                    <div className="button">
                      <Link
                        style={{
                          color: "#4b4b9d",
                          textDecoration: "underline",
                          marginLeft: "5%",
                        }}
                        className="cursor-pointer"
                        underline="none"
                        component={RouterLink}
                        to="forgot_password"
                        color="secondary"
                      >
                        Forgot password?
                      </Link>
                    </div>
                  </div>
                </div>
              </Paper>
            </Form>
          </Box>
        )}
      </Formik>
    </div>
  );
};
export default LoginPage;
