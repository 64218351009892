import React, { useState, useEffect } from "react";
import {
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  Button,
  MenuItem,
  ButtonGroup,
  TablePagination,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  MenuList,
  ClickAwayListener,
  Grow,
  Popper,
  InputBase,
  InputAdornment,
  Box,
  CardContent,
  Link,
  CardActions,
  Divider,
  Input,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

import { Form, Formik } from "formik";
import { HybridInput, HybridSelect } from "../components/hybrid_inputs";
import { StateSchema } from "../validations/userSchema";
import { Margin } from "@mui/icons-material";

export default function Dy_input({
  name,
  dynamicInptdata,
  searchValues,
  initialValues,
  // Searchfunction,
}) {
  const [initialValue] = useState(initialValues);
  const [dynamicInput, setDynamicInput] = useState([
    {
      text: false,
      select: false,
      lable: "",
      name: "",
      type: "",
      variant: "",
      options: [],
    },
  ]);
  useEffect(() => {
    setDynamicInput(dynamicInptdata);
  }, []);
  const formSubmit = (values, action) => {
  
    // e.preventDefault();
    searchValues(values);
  };


  return (
    <Box className=" my-1 p-half d-flex justify-content-between">
      <div
        style={{
          float: "left",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h5" color="secondary" className="text-left m">
          {name}
        </Typography>
      </div>
      <div style={{ width: "50%" }}>
        <Formik
          initialValues={initialValue}
          // validationSchema={StateSchema}
          onSubmit={formSubmit}
        >
          {/* {({ isSubmitting }) => ( */}
          <Form style={{ width: "100%" }}>
            <CardContent style={{ display: "flex", width: "100%" }}>
              {dynamicInput?.map((data, index) => {
                return (
                  <div key={index}>
                    {data.text === true ? (
                      <HybridInput
                        label={data.lable}
                        name={data.lable}
                        type="text"
                        variant="standard"
                        style={{ width: "100%" }}
                        // key={index}
                      />
                    ) : (
                      ""
                    )}
                    {data.select === true ? (
                      <HybridSelect
                        label={data.lable}
                        name={
                          data.lable !== "" ||
                          data.lable !== undefined ||
                          data.lable !== null
                            ? data.lable
                            : ""
                        }
                        type="text"
                        variant="standard"
                        options={data.options}
                        style={{ width: "100%" }}
                        // key={index}
                      />
                    ) : (
                      ""
                    )}
                    {/* <HybridSelect
                        label="Status"
                        name="Status"
                        type="text"
                        variant="standard"
                        options={DropDown}
                        style={{ width: "100%" }}
                      /> */}
                  </div>
                );
              })}
              {/* <HybridInput
                label="State name"
                name="stateName"
                type="text"
                variant="standard"
                style={{ width: "100%" }}
              />
              <HybridSelect
                label="Status"
                name="Status"
                type="text"
                variant="standard"
                options={DropDown}
                style={{ width: "100%" }}
              /> */}
              <div
                style={{
                  width: "100px",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "10px",
                  display: "flex",
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  // onClick={SearchFunction}
                >
                  Submit
                </Button>
              </div>
            </CardContent>
          </Form>
          {/* )} */}
        </Formik>
      </div>
    </Box>
  );
}
