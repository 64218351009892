

// ----------------------------------------------------------------------

const users = [].map((_, index) => ({
  id: "12",
 
  name: "12334567888",
  company: "12334567888",
  isVerified: "faker.datatype.boolean()",
  status:'active',
  role: 'Leader',

}));

export default users;
