import React, { useState, useEffect } from "react";

import {
  Dialog,
  Button,
  InputLabel,
  Select,
  MenuItem,
  CardContent,
  Typography,
  FormControl,
} from "@mui/material";
import TableComponent from "../components/TableLayout";
import { useAxios } from "../hooks/useAxios";
import DynamicInput from "../components/dy_input";
import {
  HybridInput,
  HybridSelect,
  MuiSelect,
  SelectField,
} from "../components/hybrid_inputs";
import { DistrictSchema } from "../validations/userSchema";
import { Form, Formik, Field } from "formik";
import { toast, Slide } from "react-toastify";
import ConfirmPopup from "../components/confirmPopup";
import Stack from "@mui/material/Stack";
import LowerCase from "./LowerCase";
import CloseIcon from "@mui/icons-material/Close";

export const DistrictManagement = () => {
  const [header] = useState([
    {
      id: 1,
      label: "State Name",
      value: "state_name",
    },
    {
      id: 2,
      label: "District Name",
      value: "district_name",
    },
    // {
    //   id: 3,
    //   label: "Status",
    //   value: "status",
    // },
    {
      id: 3,
      label: "Action",
      value: "action",
    },
  ]);
  const { axios } = useAxios();
  const [popup, setPopup] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [length, setLength] = useState();
  const [editState, setEditState] = useState(false);
  const [handletype, setHandleType] = useState("");
  const [DropDown] = useState([
    {
      _id: 1,
      status: "Active",
    },
    {
      _id: 0,
      status: "In active",
    },
  ]);
  let demoval = {
    __v: 0,
    _id: "641480758da673eaf36be2df,",
    created_on: "2023-03-17T14:16:43.687Z",
    modify_on: "2023-03-19T08:01:00.851Z",
    state_name: "Tamilnadu",
    status: 1,
  };
  const [initialValues, setInitialValue] = useState({
    state_id: "",
    district_name: "",
    status: "",
  });

  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  let paginationInfo = {
    page: 1,
    count: 0,
    rowsPerPage: 10,
  };
  const [pageInfo, setPageInfo] = useState(paginationInfo);
  useEffect(() => {
    StateList();
  }, []);
  useEffect(() => {
    DistrictList();
  }, [pageInfo.rowsPerPage, pageInfo.page, searchValue]);
  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };
  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };

  const StateList = () => {
    axios.post(`dropState`).then((data) => {
      let result = LowerCase(data.data.result, "state_name");
      setStateList(result);
    });
  };
  const DistrictList = () => {
    axios
      .post(
        `districtList?limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}&search=${searchValue}`
      )
      .then((data) => {
        setDistrictList(data.data.result.list);
        setLength(data.data.result.length ? data.data.result.length : 0);
      })
      .catch(() => {
        setDistrictList([]);
        setLength(0);
        setPageInfo(paginationInfo);
      });
  };
  const handleSearchValue = (values) => {
    setPageInfo(paginationInfo);

    setSearchValue(values);
  };
  const addStates = () => {
    setHandleType("add");
    setEditState(false);
    reset();
    setPopup(true);
  };
  const handleClose = () => {
    setPopup(false);
  };

  // prevent backDrop of dialog
  const handleClosePrevent = (reason) => {
    if (reason == "escapeKeyDown" || reason == "backdropClick") {
      setPopup(true);
    }
  };

  const searchValues = (values) => {};
  const handleSubmit = (values) => {
    var data = stateList.filter((i) => {
      if (i._id === values.state_id) {
        return i;
      }
    });

    if (editState === false) {
      // if (handletype === 'add') {
      values.state_name = data[0].state_name;
      values.state_id = values.state_id;

      axios.post(`districtCreate`, values).then((data) => {
        if (data.data.success == true) {
          DistrictList();
        }
      });
    } else {
      let insertData = {
        state_name: data[0].state_name,
        state_id: values.state_id,
        district_name: values.district_name,
        status: values.status,
        _id: activeId,
      };

      axios.post(`districtUpdate`, insertData).then((data) => {
        if (data.data.success == true) {
          DistrictList();
        }
      });
    }
    setPopup(false);
  };
  const reset = () => {
    setInitialValue({ state_id: "", district_name: "", status: "" });
  };

  useEffect(() => {
    // setEditState(true)
    if (handletype == "edit") {
      setEditState(false);
    }
  }, [editState == false]);

  const editData = (data) => {
    setEditState(true);
    districtList.forEach((list) => {
      if (list._id === data) {
        setActiveId(list._id);

        // let val = {

        //   __v: 0, _id: "641480758da673eaf36be2df",
        //   created_on: "2023-03-17T14:16:43.687Z",
        //   modify_on: "2023-03-19T08:01:00.851Z",
        //   state_name: "Tamilnadu",
        //   status: "",
        // }
        setInitialValue({ ...initialValues, ...list });
        {
          /**, state_id:val */
        }
        setPopup(true);
      }
    });
  };

  function handleState() {
    alert(editState);
    setHandleType("edit");
    setEditState(false);
  }
  const deleteDate = (data) => {
    setActiveId(data);
    setDeletePopup(true);
  };
  function deleteCancel(params) {
    setDeletePopup(false);
  }
  function deleteSubmit(params) {
    setDeletePopup(false);
    let insertData = {
      _id: activeId,
    };
    axios.post(`districtDelete`, insertData).then((data) => {
      if (data.data.success == true) {
        DistrictList();
      }
      // setStateList(data.data.data);
    });
  }
  function SearchFunction(params) {}
  return (
    <div>
      <div className="state_head">
        <Typography variant="h5" color="secondary" className="text-left">
          District Management
        </Typography>
        <Button
          variant="contained"
          sx={{
            float: "right",
            m: 1,
            width: "10ch",
            textTransform: "capitalize",
          }}
          onClick={() => addStates()}
        >
          {" "}
          Add
        </Button>
      </div>

      <TableComponent
        header={header}
        // addList={addStates}
        datalist={districtList}
        datalength={length}
        actions={["edit", "delete"]}
        editData={(id) => editData(id)}
        deleteData={(id) => deleteDate(id)}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        handleTablePage={handleTablePage}
        handleTableRowsPerPage={handleTableRowsPerPage}
        searchDataValue={(val) => handleSearchValue(val)}
        searchValue={() => {
          setSearchValue("");
          setPageInfo(paginationInfo);
        }}
      ></TableComponent>
      {deletePopup ? (
        <ConfirmPopup
          message={"Are you sure, you want to delete this record"}
          deleteCancel={deleteCancel}
          deleteSubmit={deleteSubmit}
        ></ConfirmPopup>
      ) : (
        ""
      )}
      <Dialog
        open={popup}
        scroll="paper"
        fullWidth={true}
        onClose={handleClosePrevent}
      >
        {" "}
        <Formik
          initialValues={initialValues}
          validationSchema={DistrictSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, values, formikProps }) => (
            <Form>
              <CardContent>
                <CloseIcon
                  onClick={handleClose}
                  style={{ marginLeft: "33rem" }}
                />
                {/* <h1> value ========={values.state_id.state_name}</h1> */}
                {/* <HybridSelect
                  label="State Name"
                  name="state_id"
                  fullWidth
                  setVal={setFieldValue}
                  type="text"
                  variant="standard"
                  options={stateList}
                  dropKey="state_name"
                  dropVal=""
                  initialValues={initialValues}
                /> */}
                <Field name="state_id">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel
                        id="select-label"
                        style={{ marginLeft: "-2%" }}
                      >
                        Select State
                      </InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        {...field}
                        value={field.value}
                        onChange={(event) =>
                          setFieldValue(field.name, event.target.value)
                        }
                      >
                        {stateList.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.state_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>

                {/* <SelectField
                          name='state_id'
                          label={'Select State'}
                          options={stateList}
                          variant='standard'
                          dropKey='_id'
                          displayName='state_name'
                        /> */}

                {/* <MuiSelect
                  label ={'Select State'}
                  name='state_id'
                  setVal={setFieldValue}
                /> */}

                {/* <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select an option</InputLabel>
                  <Select
                  defaultValue={demoval}
                    value={values.state_id}
                    onChange={(e) => setInitialValue( 'state_id'  , e.target.value)}
                    // onChange={(e) => setFieldValue({...initialValues, state_id  : e.target.value})}
                  >
                    {stateList.map((option) => (
                      <MenuItem key={option.value} value={option.state_id}>
                        {option.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}

                <HybridInput
                  label="District Name"
                  name="district_name"
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  fullWidth
                  type="text"
                  variant="standard"
                  setVal={setFieldValue}
                />
                <HybridSelect
                  label="Status"
                  setVal={setFieldValue}
                  name="status"
                  fullWidth
                  type="text"
                  variant="standard"
                  options={DropDown}
                  dropKey="status"
                  dropVal="_id"
                />

                {/* <Field name="status">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id="select-label">Select an option</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        {...field}
                        value={field.value}
                        onChange={(event) => setFieldValue(field.name, event.target.value)}
                      >
                        {DropDown.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.status}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field> */}
                <div className="submit">
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      type="button"
                      color="primary"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    <Button variant="contained" type="submit" color="primary">
                      {editState ? "Update" : "Add"}
                    </Button>
                  </Stack>
                </div>
              </CardContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};
