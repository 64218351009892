import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

import InboxIcon from "@mui/icons-material/MoveToInbox";

import ExpandLess from "@mui/icons-material/ExpandLess";
import { ExpandMore } from "@mui/icons-material";
import MapIcon from "@mui/icons-material/Map";
import Box from "@mui/material/Box";
import {
  Popper,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grow,
  Paper,
  MenuItem,
  MenuList,
} from "@mui/material";

import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import SpatialTrackingIcon from "@mui/icons-material/SpatialTracking";
import CssBaseline from "@mui/material/CssBaseline";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import PlaceIcon from "@mui/icons-material/Place";
import PollIcon from "@mui/icons-material/Poll";
import SummarizeIcon from "@mui/icons-material/Summarize";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import GroupIcon from "@mui/icons-material/Group";
import CabinIcon from "@mui/icons-material/Cabin";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AirlineSeatIndividualSuiteIcon from "@mui/icons-material/AirlineSeatIndividualSuite";
import EventIcon from "@mui/icons-material/Event";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import Collapse from "@mui/material/Collapse";
import AirlineSeatFlatIcon from "@mui/icons-material/AirlineSeatFlat";
import "../assets/styles/masters.scss";
import { useLogout } from "../hooks/useLogout";
import { useAxios } from "../hooks/useAxios";
import { FormDialogue } from "../components/formDialogue";
import logo from "../assets/icon.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GiteIcon from "@mui/icons-material/Gite";
import { useAuth } from "../hooks/useAuth";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Diversity1Icon from "@mui/icons-material/Festival";
import ChaletIcon from "@mui/icons-material/Chalet";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import { FaCampground } from "react-icons/fa";
import { AiOutlineDashboard } from "react-icons/ai";
import { BsHospital } from "react-icons/bs";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import DescriptionIcon from "@mui/icons-material/Description";
import TimelineIcon from "@mui/icons-material/Timeline";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";

// import { IconName } from "react-icons/go";

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(6)} + 2px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(6)} + 5px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Masters() {
  const { user } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const { logout } = useLogout();
  const anchorRef = React.useRef(null);

  const [dropDownOpen, setDropDownOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openDialogue, setOpenDialogue] = React.useState(false);
  const [roles, setRoles] = React.useState([]);
  const { axios } = useAxios();
  const [date, setDate] = useState(new Date());
  const [menu, setMenu] = useState({
    dashboard: "Dashboard",
    administrative_unit: "Location Master",
    state_management: "State",
    district_management: "District",
    talukmaster: "Subdivision",
    mandals_management: "Panchayat",
    panchayatmaster: "Municipal Corporation",
    village_management: "Village/Ward ",
    referal_master: "Referral Master",
    hospital_management: "Hospital",
    admin_accuracy: "User Management",
    project_management: "Project Management", // Project Management
    organisation: "Organisation Master", // Organisation Master
    admin_management: "Admin",
    roles: "Role",
    mobile_user_management: "Mobile User",
    visionCenterPerformance: "Vision Center",
    camping: "Camp",
    camp_management: "Camp Creation",
    camp_performance: "Camp Performance",
    patient_management: "Patient Details",
    attendance: "Fieldworker’s Attendance",
    event_management: "Events",
    /* cbbf_patient_management: "CBBF Patient",
    cbbf: "CBBF Camp ",
    cbbf_camp: "CBBF Camp Creation",
    cbbf_attendance: "CBBF Attendance",
    cbbf_optem: "CBBF Optem", */
    cbbf_patient_management: "Patient",
    cbbf: "Volunteers Screening ",
    cbbf_camp: "Camp Creation",
    cbbf_attendance: "Attendance",
    cbbf_optem: "Intervention",
    cbbf_dashboard: "Dashboard",
    gis_report: "GIS Report",
    patient_report: "Patient's Report",
    fieldWorker_report: "Fieldworker Campwise Performance Report",
    unitwise_performance: "Unit Wise Report",
    historical_trend: "Historical Trend",
    gis_summary: "GIS Summary",
    // fieldWorker_report: 'FieldWorker Report'
  });

  const [menus, setMenus] = useState({
    dashboard: false,
    administrative_unit: false,
    state_management: false,
    district_management: false,
    talukmaster: false,
    mandals_management: false,
    panchayatmaster: false,
    village_management: false,
    referal_master: false,
    hospital_management: false,
    admin_accuracy: false,
    admin_management: false,
    roles: false,
    mobile_user_management: false,
    visionCenterPerformance: false,
    camping: false,
    camp_management: false,
    camp_performance: false,
    patient_management: false,
    attendance: false,
    event_management: false,
    cbbf_patient_management: false,
    gis_report: false,
    patient_report: false,
    fieldWorker_report: false,
  });

  useEffect(() => {
    setRoles(user?.roles);
  }, [user]);

  const [openList, setOpenList] = React.useState(false);
  const [openCamp, setOpenCampList] = React.useState(false);
  const [openReport, setOpenReportList] = React.useState(false);
  const [openAdmin, setOpenAdminList] = React.useState(false);
  const [openCbbf, setOpenCbbf] = React.useState(false);

  const handleClick = () => {
    setOpenList(!openList);
  };
  const handleCampClick = () => {
    setOpenCampList(!openCamp);
  };
  const handleReportClick = () => {
    setOpenReportList(!openReport);
  };
  const handleAdminOpen = () => {
    setOpenAdminList(!openAdmin);
  };
  const handleCbbfOpen = () => {
    setOpenCbbf(!openCbbf);
  };

  const navigateCall = (params) => {
    if (params == "gis_report") {
      navigate("/gis_report");
    } else {
      if (!params) navigate(`/masters`);
      else navigate(`/masters/${params}`);
    }
  };

  const options = [
    {
      tittle: "Change Password",
      click: () => {
        handleDialogueOpen();
      },
    },
    {
      tittle: "Logout",
      click: () => {
        logout();
      },
    },
  ];

  const handleDialogueOpen = () => {
    setOpenDialogue(true);
  };

  const handleDialogueClose = () => {
    setOpenDialogue(false);
  };
  const changePassword = async (values) => {
    await axios
      .post("changePassword", values)
      .then((res) => {})
      .catch((err) => console.log(err));
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setDropDownOpen((dropDownOpen) => !dropDownOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setDropDownOpen(false);
  };

  function checkRoleMenu(menu_name) {
    menu_name = menu_name.replaceAll("_", "");
    menu_name = menu_name.toLowerCase();
    var id = roles?.findIndex((i) => i.screen_name === menu_name);
    if (id >= 0) {
      return true;
    }
  }
  let capitalizeFirstLetter = (string) => {
    if (string?.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return string;
    }
  };
  let textCaptalize = capitalizeFirstLetter(user?.username);
  console.log(textCaptalize, "textCaptalize");

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar className="d-flex justify-content-between">
          <div
            className="d-flex align-items-center"
            style={{ marginTop: "-0.2vh" }}
          >
            {" "}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={logo}
              width={30}
              height={30}
              style={{
                marginRight: "12px",
                borderRadius: "50%",
                marginTop: "-0.6vh",
              }}
            />
            <Typography variant="h6" noWrap component="div">
              Sankara Admin Panel{" "}
            </Typography>
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>
              Welcome{" "}
              {user?.username !== "undefined"
                ? textCaptalize
                : localStorage.getItem("hospital_name")}
            </span>
            <IconButton
              aria-label="delete"
              sx={{ fontSize: "35px" }}
              color="inherit"
              ref={anchorRef}
              onClick={handleToggle}
            >
              <AccountCircleRoundedIcon fontSize="inherit" />
            </IconButton>
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={dropDownOpen}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {options.map((option, index) => (
                          <MenuItem key={option.tittle} onClick={option.click}>
                            {option.tittle}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.secondary.contrastText,
          },
        }}
      >
        <DrawerHeader
          variant="permanent"
          sx={{ justifyContent: "space-between", padding: "0px 1rem" }}
        >
          {" "}
          <Typography variant="h5">Menu</Typography>
          <IconButton
            onClick={handleDrawerClose}
            sx={{ color: theme.palette.secondary.contrastText }}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List sx={{ width: "100%", maxWidth: 360 }} component="nav">
          {/* {
            checkRoleMenu('dashboard') ? */}
          <ListItemButton onClick={() => navigateCall("")}>
            <ListItemIcon sx={{ color: theme.palette.secondary.contrastText }}>
              <AiOutlineDashboard size={24} />
            </ListItemIcon>
            <ListItemText primary={menu.dashboard} />
          </ListItemButton>

          {checkRoleMenu("cbbf_dashboard") && (
            <ListItemButton onClick={() => navigateCall("cbbfdashboard")}>
              <ListItemIcon
                sx={{ color: theme.palette.secondary.contrastText }}
              >
                <DashboardIcon />
                {/* <AiOutlineDashboard size={24} /> */}
              </ListItemIcon>
              {/* <ListItemText primary={"CBBF"} /> */}
              <ListItemText primary={"Volunteers Screening"} />
            </ListItemButton>
          )}
          {checkRoleMenu("state_management") ||
          checkRoleMenu("district_management") ||
          checkRoleMenu("talukmaster") ||
          checkRoleMenu("mandals_management") ||
          checkRoleMenu("village_management") ? (
            <ListItemButton
              onClick={handleClick}
              sx={{ color: theme.palette.secondary.contrastText }}
            >
              <ListItemIcon
                sx={{ color: theme.palette.secondary.contrastText }}
              >
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={menu.administrative_unit} />
              {openList ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          ) : (
            ""
          )}
          <Collapse in={openList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {checkRoleMenu("state_management") && (
                <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => navigateCall("state")}
                >
                  <ListItemIcon
                    sx={{ color: theme.palette.secondary.contrastText }}
                  >
                    <PlaceIcon />
                  </ListItemIcon>
                  <ListItemText primary={menu.state_management} />
                </ListItemButton>
              )}

              {checkRoleMenu("district_management") && (
                <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => navigateCall("district")}
                >
                  <ListItemIcon
                    sx={{ color: theme.palette.secondary.contrastText }}
                  >
                    <CorporateFareIcon />
                  </ListItemIcon>
                  <ListItemText primary={menu.district_management} />
                </ListItemButton>
              )}

              {checkRoleMenu("talukmaster") && (
                <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => navigateCall("subdivison")}
                >
                  <ListItemIcon
                    sx={{ color: theme.palette.secondary.contrastText }}
                  >
                    <GiteIcon />
                  </ListItemIcon>
                  <ListItemText primary={menu.talukmaster} />
                </ListItemButton>
              )}

              {checkRoleMenu("mandals_management") && (
                <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => navigateCall("council")}
                >
                  <ListItemIcon
                    sx={{ color: theme.palette.secondary.contrastText }}
                  >
                    <HolidayVillageIcon />
                  </ListItemIcon>
                  <ListItemText primary={menu.mandals_management} />
                </ListItemButton>
              )}
              {checkRoleMenu("village_management") && (
                <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => navigateCall("village")}
                >
                  <ListItemIcon
                    sx={{ color: theme.palette.secondary.contrastText }}
                  >
                    <GiteIcon />
                  </ListItemIcon>
                  <ListItemText primary={menu.village_management} />
                </ListItemButton>
              )}
            </List>
          </Collapse>
          {checkRoleMenu("referal_master") && (
            <ListItemButton onClick={() => navigateCall("referral_type")}>
              <ListItemIcon
                sx={{ color: theme.palette.secondary.contrastText }}
              >
                <InterpreterModeIcon />
              </ListItemIcon>
              <ListItemText primary={menu.referal_master} />
            </ListItemButton>
          )}
          {checkRoleMenu("hospital_management") && (
            <ListItemButton onClick={() => navigateCall("hospital_management")}>
              <ListItemIcon
                sx={{ color: theme.palette.secondary.contrastText }}
              >
                {/* <LocalHospitalIcon /> */}
                <BsHospital size={24} />
              </ListItemIcon>
              <ListItemText primary={menu.hospital_management} />
            </ListItemButton>
          )}

          {(checkRoleMenu("adminmanagement") ||
            checkRoleMenu("roles") ||
            checkRoleMenu("mobileusermanagement")) && (
            <ListItemButton onClick={handleAdminOpen}>
              <ListItemIcon
                sx={{ color: theme.palette.secondary.contrastText }}
              >
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary={menu.admin_accuracy} />
              {openAdmin ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          )}

          <Collapse in={openAdmin} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {checkRoleMenu("adminmanagement") && (
                <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => navigateCall("admin_management")}
                >
                  <ListItemIcon
                    sx={{ color: theme.palette.secondary.contrastText }}
                  >
                    <AdminPanelSettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary={menu.admin_management} />
                </ListItemButton>
              )}
              {checkRoleMenu("roles") && (
                <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => navigateCall("roles")}
                >
                  <ListItemIcon
                    sx={{ color: theme.palette.secondary.contrastText }}
                  >
                    <ControlPointIcon />
                  </ListItemIcon>
                  <ListItemText primary={menu.roles} />
                </ListItemButton>
              )}
              {checkRoleMenu("mobileusermanagement") && (
                <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => navigateCall("mobile_user_management")}
                >
                  <ListItemIcon
                    sx={{ color: theme.palette.secondary.contrastText }}
                  >
                    <SendToMobileIcon />
                  </ListItemIcon>
                  <ListItemText primary={menu.mobile_user_management} />
                </ListItemButton>
              )}
            </List>
          </Collapse>

          {/* Project Management project_management*/}
          {checkRoleMenu("projectmanagement") && (
            <ListItemButton onClick={() => navigateCall("project_management")}>
              <ListItemIcon
                sx={{ color: theme.palette.secondary.contrastText }}
              >
                {/* <LocalHospitalIcon /> */}
                {/* <BsHospital size={24} /> */}
                <AccountTreeIcon />
              </ListItemIcon>
              <ListItemText primary={menu.project_management} />
            </ListItemButton>
          )}

          {/* Organisation master organisation*/}
          {checkRoleMenu("organisationmaster") && (
            <ListItemButton onClick={() => navigateCall("organisation")}>
              <ListItemIcon
                sx={{ color: theme.palette.secondary.contrastText }}
              >
                <AddBusinessIcon />
              </ListItemIcon>
              <ListItemText primary={menu.organisation} />
            </ListItemButton>
          )}

          {checkRoleMenu("visionCenterPerformance") && (
            <ListItemButton
              onClick={() => navigateCall("visionCenterPerformance")}
            >
              <ListItemIcon
                sx={{ color: theme.palette.secondary.contrastText }}
              >
                <RemoveRedEyeIcon />
              </ListItemIcon>
              <ListItemText primary={menu.visionCenterPerformance} />
            </ListItemButton>
          )}
          {checkRoleMenu("event_management") && (
            <ListItemButton onClick={() => navigateCall("event_management")}>
              <ListItemIcon
                sx={{ color: theme.palette.secondary.contrastText }}
              >
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary={menu.event_management} />
            </ListItemButton>
          )}
          {(checkRoleMenu("camp_management") ||
            checkRoleMenu("camp_performance")) && (
            <ListItemButton onClick={handleCampClick}>
              <ListItemIcon
                sx={{ color: theme.palette.secondary.contrastText }}
              >
                <Diversity1Icon />
              </ListItemIcon>
              <ListItemText primary={menu.camping} />
              {openCamp ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          )}
          <Collapse in={openCamp} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {checkRoleMenu("camp_management") && (
                <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => navigateCall("camp_management")}
                >
                  <ListItemIcon
                    sx={{ color: theme.palette.secondary.contrastText }}
                  >
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText primary={menu.camp_management} />
                </ListItemButton>
              )}
              {checkRoleMenu("attendance") && (
                <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => navigateCall("attendance")}
                >
                  <ListItemIcon
                    sx={{ color: theme.palette.secondary.contrastText }}
                  >
                    <HowToRegIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ display: "inline" }}>
                    <Typography>Fieldworker </Typography>Attendance
                  </ListItemText>
                </ListItemButton>
              )}
              {checkRoleMenu("patient_management") && (
                <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => navigateCall("patient_management")}
                >
                  <ListItemIcon
                    sx={{ color: theme.palette.secondary.contrastText }}
                  >
                    <AirlineSeatIndividualSuiteIcon />
                  </ListItemIcon>
                  <ListItemText primary={menu.patient_management} />
                </ListItemButton>
              )}
              {checkRoleMenu("camp_performance") && (
                <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => navigateCall("campPerformance")}
                >
                  <ListItemIcon
                    sx={{ color: theme.palette.secondary.contrastText }}
                  >
                    <CabinIcon />
                  </ListItemIcon>
                  <ListItemText primary={menu.camp_performance} />
                </ListItemButton>
              )}

              {checkRoleMenu("patient_report") && (
                <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => navigateCall("patient_report")}
                >
                  <ListItemIcon
                    sx={{ color: theme.palette.secondary.contrastText }}
                  >
                    <SpatialTrackingIcon />
                  </ListItemIcon>
                  <ListItemText primary={menu.patient_report} />
                </ListItemButton>
              )}
              {checkRoleMenu("fieldWorker_report") && (
                <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => navigateCall("fieldWorker_report")}
                >
                  <ListItemIcon
                    sx={{ color: theme.palette.secondary.contrastText }}
                  >
                    <AddToHomeScreenIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ display: "inline" }}>
                    <Typography>Fieldworker Campwise</Typography> Performance
                    Report
                  </ListItemText>
                  {/* <ListItemText primary="Fieldworker Campwise Performance Report" /> */}
                </ListItemButton>
              )}
            </List>
          </Collapse>

          {(checkRoleMenu("cbbf_patient_management") ||
            checkRoleMenu("cbbf_camp") ||
            checkRoleMenu("cbbf_attendance")) && (
            <ListItemButton onClick={handleCbbfOpen}>
              <ListItemIcon
                sx={{ color: theme.palette.secondary.contrastText }}
              >
                <ChaletIcon />
              </ListItemIcon>
              <ListItemText sx={{ textWrap: "pretty" }} primary={menu.cbbf} />
              {openCbbf ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          )}
          <Collapse in={openCbbf} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {checkRoleMenu("cbbf_camp") && (
                <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => navigateCall("cbbf_camp")}
                >
                  <ListItemIcon
                    sx={{ color: theme.palette.secondary.contrastText }}
                  >
                    <FaCampground />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ textWrap: "pretty" }}
                    primary={menu.cbbf_camp}
                  />
                </ListItemButton>
              )}
              {checkRoleMenu("cbbf_attendance") && (
                <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => navigateCall("cbbf_attendance")}
                >
                  <ListItemIcon
                    sx={{ color: theme.palette.secondary.contrastText }}
                  >
                    <AccessibilityIcon />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ textWrap: "pretty" }}
                    primary={menu.cbbf_attendance}
                  />
                </ListItemButton>
              )}
              {checkRoleMenu("cbbf_patient_management") && (
                <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => navigateCall("cbbf_patient_management")}
                >
                  <ListItemIcon
                    sx={{ color: theme.palette.secondary.contrastText }}
                  >
                    <AirlineSeatFlatIcon />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ textWrap: "pretty" }}
                    primary={menu.cbbf_patient_management}
                  />
                </ListItemButton>
              )}

              <ListItemButton
                sx={{ pl: 6 }}
                onClick={() => navigateCall("cbbf_optem")}
              >
                <ListItemIcon
                  sx={{ color: theme.palette.secondary.contrastText }}
                >
                  <AccountBoxIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{ textWrap: "pretty" }}
                  primary={menu.cbbf_optem}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 6 }}
                onClick={() => navigateCall("optem_report")}
              >
                <ListItemIcon
                  sx={{ color: theme.palette.secondary.contrastText }}
                >
                  <AdUnitsIcon />
                </ListItemIcon>
                <ListItemText primary="Optom ID Report" />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 6 }}
                onClick={() => navigateCall("survey_report")}
              >
                <ListItemIcon
                  sx={{ color: theme.palette.secondary.contrastText }}
                >
                  <PollIcon />
                </ListItemIcon>
                <ListItemText sx={{ display: "inline" }}>
                  Survey Report
                </ListItemText>
                {/* <ListItemText primary="Fieldworker Campwise Performance Report" /> */}
              </ListItemButton>

              {/* end to end report */}
              <ListItemButton
                sx={{ pl: 6 }}
                onClick={() => navigateCall("volunteers_master_report")}
              >
                <ListItemIcon
                  sx={{ color: theme.palette.secondary.contrastText }}
                >
                  <SummarizeIcon />
                </ListItemIcon>
                <ListItemText sx={{ display: "inline" }}>
                  End to End Report
                </ListItemText>
                {/* <ListItemText primary="Fieldworker Campwise Performance Report" /> */}
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton onClick={() => navigateCall("unitwise_performance")}>
            <ListItemIcon sx={{ color: theme.palette.secondary.contrastText }}>
              <DescriptionIcon size={24} />
            </ListItemIcon>
            <ListItemText primary={menu.unitwise_performance} />
          </ListItemButton>

          <ListItemButton onClick={() => navigateCall("historical_trend")}>
            <ListItemIcon sx={{ color: theme.palette.secondary.contrastText }}>
              <TimelineIcon size={24} />
            </ListItemIcon>
            <ListItemText primary={menu.historical_trend} />
          </ListItemButton>

          <ListItemButton onClick={() => navigateCall("gis_summary")}>
            <ListItemIcon sx={{ color: theme.palette.secondary.contrastText }}>
              <WhereToVoteIcon size={24} />
            </ListItemIcon>
            <ListItemText primary={menu.gis_summary} />
          </ListItemButton>

          {checkRoleMenu("gis_report") && (
            <ListItemButton onClick={() => navigateCall("gis_report")}>
              <ListItemIcon
                sx={{ color: theme.palette.secondary.contrastText }}
              >
                <MapIcon />
              </ListItemIcon>
              <ListItemText primary={menu.gis_report} />
            </ListItemButton>
          )}
          {/* {
            (checkRoleMenu('patient_report') || checkRoleMenu('fieldWorker_report')) &&

            <ListItemButton onClick={handleReportClick}>
              <ListItemIcon sx={{ color: theme.palette.secondary.contrastText }}>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary="Reports" />
              {openReport ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          } */}
          {/* <Collapse in={openReport} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {
                checkRoleMenu('patient_report') &&
                <ListItemButton onClick={() => navigateCall("patient_report")}>
                  <ListItemIcon sx={{ color: theme.palette.secondary.contrastText }}>
                    <SpatialTrackingIcon />
                  </ListItemIcon>
                  <ListItemText primary={menu.patient_report} />
                </ListItemButton>
              }
              {
                checkRoleMenu('fieldWorker_report') &&
                <ListItemButton onClick={() => navigateCall("fieldWorker_report")}>
                  <ListItemIcon sx={{ color: theme.palette.secondary.contrastText }}>
                    <AddToHomeScreenIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ display: 'inline' }}><Typography>Fieldworker Campwise</Typography> Performance Report</ListItemText>
        
                </ListItemButton>
              }
            </List>
          </Collapse> */}
        </List>
      </Drawer>

      {/* <List>
          {MenuItems.map((items, index) => (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={items.click}
                // to={items.link}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: theme.palette.secondary.contrastText,
                  }}
                >
                  {items.icon}
                </ListItemIcon>
                <ListItemText
                  primary={items.tittle}
                  sx={{ opacity: open ? 1 : 0 }}
                  className="text-ellipsis"
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      {/* <Divider />
        <List>
          {MenuItems.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.tittle}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      {/* </List>
      </Drawer> */}

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <FormDialogue
          open={openDialogue}
          close={handleDialogueClose}
          formData={changePassword}
        />
        <Outlet />
      </Box>
    </Box>
  );
}

/* ---- navigate code 


const MenuItems = [
    {
      tittle: "States Management",
      icon: <PlaceIcon />,
      link: "/masters/",
      click: () => {
        navigate("/masters/");
        // navigate("/masters/state_management");
      },
    },
    {
      tittle: "District Management",
      icon: <CorporateFareIcon />,
      link: "",
      click: () => {
        navigate("/masters/district_management");
      },
    },
    {
      tittle: "Mandals Management",
      icon: <AssuredWorkloadIcon />,
      link: "",
      click: () => {
        navigate("/masters/mandals_management");
      },
    },
    {
      tittle: "Village Management",
      icon: <HolidayVillageIcon />,
      link: "",
      click: () => {
        navigate("/masters/village_management");
      },
    },
    {
      tittle: "Hospital Management",
      icon: <LocalHospitalIcon />,
      link: "",
      click: () => {
        navigate("/masters/hospital_management");
      },
    },
    {
      tittle: "Taluk Master",
      icon: <CommentBankIcon />,
      link: "",
      click: () => {
        navigate("/masters/TalukMaster");
      },
    },
    {
      tittle: "Referral Type Management",
      icon: <CommentBankIcon />,
      link: "",
      click: () => {
        navigate("/masters/referral_type");
      },
    },
    {
      tittle: "Admin Management",
      icon: <AdminPanelSettingsIcon />,
      link: "",
      click: () => {
        navigate("/masters/admin_management");
      },
    },
    {
      tittle: "Camp Management",
      icon: <GroupIcon />,
      link: "",
      click: () => {
        navigate("/masters/camp_management");
      },
    },
    {
      tittle: "Mobile User Management",
      icon: <SendToMobileIcon />,
      link: "",
      click: () => {
        navigate("/masters/mobile_user_management");
      },
    },
    {
      tittle: "Role Management",
      icon: <ControlPointIcon />,
      link: "",
      click: () => {
        navigate("/masters/roles");
      },
    },
    {
      tittle: "Camp Performance ",
      icon: <CabinIcon />,
      link: "",
      click: () => {
        navigate("/masters/campPerformance");
      },
    },
    {
      tittle: "Vision Center Performance ",
      icon: <RemoveRedEyeIcon />,
      link: "",
      click: () => {
        navigate("/masters/visionCenterPerformance");
      },
    },
    {
      tittle: "Patient Management",
      icon: <AirlineSeatIndividualSuiteIcon />,
      link: "",
      click: () => {
        navigate("/masters/patient_management");
      },
    },
    {
      tittle: "Event Management",
      icon: <EventIcon />,
      link: "",
      click: () => {
        navigate("/masters/event_management");
      },
    },
    {
      tittle: "Attendance Management",
      icon: <HowToRegIcon />,
      link: "",
      click: () => {
        navigate("/masters/attendance");
      },
    },
  ];

  */
