import React, { useEffect, useState } from "react";
import { Button, Typography, Stack } from "@mui/material";
import { useAxios } from "../hooks/useAxios";
import TableComponent from "../components/TableLayout";
import Forms from "../forms/camp_form";
import ConfirmPopup from "../components/confirmPopup";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelUplaod from "../components/ExcelFormat/Excelfileupload";
import ExcelDownload from "../components/ExcelFormat/ExcelFileDownload";
import { toast, Slide } from "react-toastify";

import moment from "moment";

import "./Style.css";
import { dates } from "../index.js";

import {
  Table,
  makeStyles,
  FormControl,
  TableBody,
  TableCell,
  InputLabel,
  Input,
  Select,
  MenuItem,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

const renderInputs = (props, placeholder, classname) => (
  <Input
    sx={{
      width: "80%",
      input: {
        "&::placeholder": {
          // <----- Add this.
          opacity: 1,
          // marginBottom: '-5px',
          paddingLeft: "10px",
          fontSize: "13px",
          color: "#000000",
        },
        width: "100%",
      },
      backgroundColor: "#ffffff",
      // borderRadius: '5px',
      // maxHeight: '50px'
    }}
    type="text"
    className={classname}
    inputRef={props.inputRef}
    // inputProps={props.inputProps}
    inputProps={{
      ...props.inputProps,
      placeholder: placeholder,
    }}
    value={props.value}
    onClick={props.onClick}
    onChange={props.onChange}
    endAdornment={props.InputProps?.endAdornment}
  />
);

export default function CampManagement() {
  const [open, setOpen] = useState(false);
  const [length, setLength] = useState();
  const { axios } = useAxios();

  const [downloadTab, setDownloadTab] = useState(false);
  const [value, setValue] = useState([]);
  const [dateSearch, setDateSearch] = useState("");

  const initialState = {
    camp_place: "",
    camp_pin_code: "",
    camp_date: null,
    camp_from_date: null,
    // camp_from_date: null,
    camp_to_date: null,
    state_id: "",
    district_id: "",
    mandal_id: [], //
    village_id: "",
    taluk_id: "",
    hospital_id: "",
    state: "",
    district: "",
    mandal: "",
    village: "",
    hospital: "",
    field_worker_id: "",
    co_sponsor: "",
    household_target: "",
    yellow_card_target: "",
    individual_screening_target: "",
    project_name: "", //
    field_worker_type: [], //
    organizations: [], //
    field_workers: [], //
  };
  const updateState = {
    id: "",
    username: "",
    email: "",
  };
  const [header, setHeader] = useState([
    {
      id: 1,
      label: "Camp ID",
      value: "camp_number",
      isChecked: false,
    },
    {
      id: 2,
      label: "Place",
      value: "camp_place",
      isChecked: false,
    },
    {
      id: 3,
      label: "Camp Date",
      value: "camp_date",
      isChecked: false,
    },
    {
      id: 4,
      label: "Hospital",
      value: "hospital_name",
      isChecked: false,
    },
    {
      id: 5,
      label: "Project",
      value: "project_name",
      isChecked: false,
    },
    /* {
      id: 10,
      label: "Fieldworker Name",
      value: "field_worker_name",
      isChecked: false,
    }, */
    {
      id: 6,
      label: "Survey from",
      value: "camp_from_date",
      isChecked: false,
    },
    {
      id: 7,
      label: "Survey to",
      value: "camp_to_date",
      isChecked: false,
    },
    {
      id: 8,
      label: "HHT",
      value: "household_target",
      isChecked: false,
    },
    {
      id: 9,
      label: "YCT",
      value: "yellow_card_target",
      isChecked: false,
    },

    {
      id: 10,
      label: "Action",
      value: "action",
      isChecked: false,
    },
  ]);
  let dateFields = ["camp_from_date", "camp_to_date", "camp_date"];
  const [campsList, setCampsList] = useState([]);
  const [editState, setEditState] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [userDetails, setUserDetails] = useState(initialState);
  const [progressBar, setProgressBar] = useState(false);

  const [searchValue, setSearchValue] = React.useState({
    eventStart: "",
    eventEnd: "",
  });

  const [deletePopup, setDeletePopup] = useState(false);
  const [addCamp, setAddCamp] = useState(false);
  const [campExcel, setCampExcel] = useState({});
  const [filterValue, setFilterValue] = useState({
    campIds: "",
    campPlaces: "",
    campStart: "",
    campEnd: "",
  });
  let paginationInfo = {
    page: 1,
    count: 0,
    rowsPerPage: 10,
  };
  const [pageInfo, setPageInfo] = useState(paginationInfo);
  const [addingCamp, setAddingCamp] = useState(true);

  useEffect(() => {
    getCampsList();
  }, [pageInfo.rowsPerPage, pageInfo.page, filterValue]); //filterValue

  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };

  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };

  const getCampsList = async () => {
    await axios
      .post(
        `campList?limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}&camp_place=${filterValue.campPlaces}&fromDate=${filterValue.campStart}&toDate=${filterValue.campEnd}&camp_number=${filterValue.campIds}`
      )
      .then((res) => {
        setCampsList(res.data.result.list);
        setCampExcel(res.data.result.list);
        setLength(res.data.result.length);
        setPageInfo((prevState) => ({
          ...prevState,
          count: res.data.result.length,
        }));
      })
      .catch((err) => {
        setCampsList([]);
        setLength(0);
        setPageInfo(paginationInfo);
      });
  };

  const deleteDate = (data) => {
    setActiveId(data);
    setDeletePopup(true);
  };

  const openDialogue = () => {
    setEditState(false);

    setOpen(true);
  };
  const closeDialogue = () => {
    // resetForm();
    setUserDetails(initialState);
    setOpen(false);
  };

  const editPopUP = (data) => {
    try {
      setEditState(true);
      campsList.forEach((list) => {
        if (list._id == data) {
          setActiveId(list._id);
          let data = { ...initialState, ...list };

          // state conversion
          const mandal_id = list?.mandal?.map(
            ({ mandal_id: _id, mandal_name }) => ({
              _id,
              mandal_name,
            })
          );

          const field_worker_type = list?.worker_type?.map(
            ({ role_id: _id, role_name: name }) => ({
              _id,
              name,
            })
          );

          const organizations = list?.organizations?.map(
            ({
              organization_collection_id: _id,
              organization_id,
              organization_name,
            }) => ({
              _id,
              organization_id,
              organization_name,
            })
          );

          const field_workers = list?.field_worker?.map(
            ({ field_worker_id: _id, field_worker_name: user_name }) => ({
              _id,
              user_name,
            })
          );

          const village = list?.village?.map(
            ({ village_id: _id, village_name }) => ({
              _id,
              village_name,
            })
          );

          // old one
          // setUserDetails({ ...initialState, ...list });

          // updated one
          delete list?.field_worker;
          delete list?.mandal;
          delete list?.worker_type;
          setUserDetails((prevState) => ({
            ...prevState,
            ...list,
            mandal_id,
            field_worker_type,
            organizations,
            field_workers,
            village,
          }));

          setAddingCamp(false);
          setAddCamp(false);
        }
      });
    } catch (error) {
      console.log("erros", error);
      toast.error("Something Went Wrong", {
        autoClose: 1500,
        transition: Slide,
      });
    }
  };

  const [filter, setFilter] = useState({
    camp_id: "",
    place: "",
  });
  const searchFilter = () => {
    getCampsList();
  };

  // function handleChange(e) {
  //   var name = e.target.name;
  //   var val = e.target.value;

  //   setFilter({
  //     ...filter,
  //     [name]: val,
  //   });
  // }

  const addCampToggle = () => {
    setAddingCamp((prevState) => !prevState);
    getCampsList();
    setAddCamp(true);
    setEditState(false);
  };

  function deleteCancel() {
    setDeletePopup(false);
  }
  function deleteSubmit() {
    setDeletePopup(false);

    let insertData = {
      id: activeId,
    };
    axios.post(`campDelete`, insertData).then((data) => {
      getCampsList();
    });
  }

  /*---- bulk file upload call  for Excel --- */

  async function handleImport(event) {
    const filedata = await ExcelUplaod(event);

    axios.post(`campBulkUpload`, filedata.data).then((res) => {
      if (res.data.statuscode === 200) {
        // setPopup(false)
        getCampsList();
        toast.success("Camp Added Sucessfully", {
          autoClose: 3000,
          transition: Slide,
        });
      }
    });
  }

  /*------------------------ Excel Download ---------------*/

  function firstLetter(s) {
    return s?.replace(/^.{1}/g, s[0]?.toUpperCase());
  }

  function alterScreenName(name) {
    var menu_name = name.replaceAll("_", " ");
    var name = menu_name?.replace(/^.{1}/g, menu_name[0].toUpperCase());
    return name;
  }

  function handleDates(val) {
    const today = new Date(); // Get the current date
    setDateSearch(val);

    // Format the dates as "DD-MM-YYYY"
    var dateFormat = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    if (val == "last_week") {
      //==============last week ======================

      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7); // Subtract 7 days to get the date for last week

      // Find the start date (Monday) of last week
      const startOfWeek = new Date(lastWeek);
      startOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7));

      // Find the end date (Sunday) of last week
      const endOfWeek = new Date(lastWeek);
      endOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7) + 6);

      // Format the dates
      var formattedStartOfWeek = startOfWeek.toISOString().split("T")[0];
      var formattedEndOfWeek = endOfWeek.toISOString().split("T")[0];

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartOfWeek,
        eventEnd: formattedEndOfWeek,
      }));
    } else if (val == "last_month") {
      //================last month ===============================
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

      // Set the day to 1 to get the first day of the previous month
      lastMonth.setDate(2);

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: lastMonth.toISOString().split("T")[0],
        eventEnd: moment().date(0).toISOString().split("T")[0],
      }));
    } else if (val == "last_3_month") {
      //================last month ===============================

      const threeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        1
      );

      // Set the day to 1 to get the first day of three months ago
      threeMonthsAgo.setDate(1);

      const lastDayOfThreeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );

      const formattedStartDate = threeMonthsAgo.toLocaleDateString("en-IN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "Asia/Kolkata",
      });
      const formattedEndDate = lastDayOfThreeMonthsAgo.toLocaleDateString(
        "en-IN",
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone: "Asia/Kolkata",
        }
      );

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
        eventEnd: formattedEndDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
      }));
    } else if (val == "all") {
      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: "",
        eventEnd: "",
      }));
    }
  }

  console.log("searchValue", searchValue);

  const handleChange = (e, val, position, headerName) => {
    const index = value?.findIndex((x) => x.value == val);

    if (e.target.checked) {
      if (index < 0) {
        setValue(() => {
          var values = { position: position, value: val, header: headerName };
          var data = { ...value, values };
          return Object.values(data);
        });
      } else {
        value.splice(index, 1);
      }
    } else {
      value.splice(index, 1);
    }
  };

  const handleuploadExcel = async () => {
    const res = await axios.post(
      `campList?limit=&page=${1}&camp_place=${
        filterValue.campPlaces
      }&fromDate=${searchValue.eventStart}&toDate=${
        searchValue.eventEnd
      }&camp_number=${filterValue.campIds}`
    );

    const data_ = res?.data?.result?.list;

    let data = data_.map((data) => {
      return {
        ...data,
        project_name: firstLetter(data.project_name),
        camp_date: data.camp_date.slice(0, 10),
        camp_from_date: data.camp_from_date.slice(0, 10),
        camp_to_date: data.camp_to_date.slice(0, 10),
        hospital_name: firstLetter(data.hospital_name),
        camp_place: firstLetter(data.camp_place),
        state_name: firstLetter(data.state_name),
        district_name: firstLetter(data.district_name),
        taluk_name: firstLetter(data.taluk_name),
        mandal_name: firstLetter(data.mandal_name),
        co_sponsor: firstLetter(data.co_sponsor),
      };
    });

    // if (value.length > 1) {
    //   value.sort((x, y) => x.position - y.position)
    // }

    // var result = [];

    // var response = {};

    // data?.map((x) => {
    //   value?.map((i) => {
    //     response[i.header] = x[i.value]
    //   })
    //   result.push(response)
    // })

    // console.log('res',result);

    let newArrayOfObj = data.map(
      ({
        project_name: ProjectName,
        camp_number: CampNumber,
        camp_place: CampPlace,
        camp_pin_code: CampPinCode,
        camp_date: CampDate,
        camp_from_date: campFromDate,
        camp_to_date: CampToDate,
        state_name: stateName,
        district_name: DistrictName,
        taluk_name: SubDivison,
        mandal: Panchayats,
        village: Village,
        field_worker: FieldWorkersName,
        hospital_name: HospitalName,
        current_latitude: Latitude,
        current_longitude: Longitude,
        co_sponsor: Cosponsor,
        household_target: HouseHoldTarget,
        individual_screening_target: IndividualScreeningTarget,
        yellow_card_target: YellowCardTarget,
      }) => ({
        HospitalName,
        ProjectName,
        CampNumber,
        CampPlace,
        CampPinCode,
        CampDate,
        FieldWorkersName: FieldWorkersName?.map(
          ({ field_worker_name }) => field_worker_name
        ).toString(),
        campFromDate,
        CampToDate,
        stateName,
        DistrictName,
        SubDivison,
        Panchayats: Panchayats?.map(
          ({ mandal_name }) => mandal_name
        ).toString(),
        Village: Village?.map(({ village_name }) => village_name).toString(),
        Latitude,
        Longitude,
        Cosponsor,
        HouseHoldTarget,
        IndividualScreeningTarget,
        YellowCardTarget,
      })
    );

    // let Excel = newArrayOfObj?.map(
    //   ({ mandal, village, field_worker, ...rest }) => ({
    //     ...rest,
    //     Panchayats: mandal?.map(({ mandal_name }) => mandal_name).toString(),
    //     Village: village?.map(({ village_name }) => village_name).toString(),
    //     FieldWorkersName: field_worker
    //       ?.map(({ field_worker_name }) => field_worker_name)
    //       .toString(),
    //   })
    // );

    const newArr = newArrayOfObj.map((obj) => {
      const newObj = { ...obj };
      delete newObj["created_on"];
      delete newObj["modify_on"];
      delete newObj["state_id"];
      delete newObj["district_id"];
      delete newObj["_id"];
      delete newObj["taluk_id"];
      delete newObj["mandal_id"];
      delete newObj["village_id"];
      delete newObj["hospital_id"];
      delete newObj["field_worker_id"];
      delete newObj["__v"];
      delete newObj["camp_type"];

      return newObj;
    });

    ExcelDownload(newArr, "campDetails");
    setDownloadTab((e) => !e);
    setDateSearch("");
  };

  const filterResponse = (val) => {
    setPageInfo((prevState) => ({ ...prevState, page: 1 }));
    setFilterValue(val);
  };

  const handleSearchValue = (values) => {
    setSearchValue(values);
  };

  return (
    <>
      {addingCamp ? (
        <div>
          <div className="state_head">
            <Typography variant="h5" color="secondary" className="text-left">
              Camp Management
            </Typography>
            <div style={{ width: "25%" }}>
              <Button
                variant="contained"
                sx={{ m: 1, width: "auto", textTransform: "capitalize" }}
                startIcon={<FileDownloadIcon />}
                // onClick={handleuploadExcel}
                onClick={() => setDownloadTab((k) => !k)}
              >
                Download Camp
              </Button>
              <Button
                variant="contained"
                sx={{
                  float: "right",
                  m: 1,
                  width: "10%",
                  textTransform: "capitalize",
                }}
                onClick={addCampToggle}
              >
                {" "}
                Add
              </Button>
            </div>
          </div>

          {downloadTab && (
            <div className="tabbackground">
              <div
                className={
                  dateSearch == "custom" ? "nonreports" : "nonreports1"
                }
              >
                <div className="table">
                  <Table>
                    <TableHead>
                      <tr>
                        {/* <th><Checkbox
                      onChange={(e) => {
                        handleSelectAll(e);
                      }}
                    />
                      Select all</th> */}
                        <th> </th>
                        <th>Select Duration </th>
                      </tr>
                    </TableHead>
                  </Table>
                  <div className="filter">
                    <FormControl style={{ width: "80%" }} size="small">
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        label="Select Dates"
                        value={dateSearch}
                        onChange={(e) => {
                          handleDates(e.target.value);
                        }}
                      >
                        {dates?.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {dateSearch == "custom" && (
                      <div style={{ width: "100%" }} className="custom">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="from_date"
                            value={
                              searchValue.eventStart == ""
                                ? null
                                : searchValue.eventStart
                            }
                            inputFormat="DD/MM/YYYY"
                            onChange={(e) => {
                              const selectedDate = e.$d;
                              if (selectedDate) {
                                setSearchValue({
                                  ...searchValue,
                                  eventStart: selectedDate,
                                });
                              } else {
                                setSearchValue({
                                  ...searchValue,
                                  eventStart: null,
                                });
                              }
                            }}
                            slotProps={{
                              actionBar: {
                                actions: ["clear"],
                              },
                            }}
                            renderInput={(props) =>
                              renderInputs(props, "From date", "date")
                            }
                          />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="from_date"
                            value={
                              searchValue.eventEnd == ""
                                ? null
                                : searchValue.eventEnd
                            }
                            inputFormat="DD/MM/YYYY"
                            onChange={(e) => {
                              setSearchValue({
                                ...searchValue,
                                eventEnd: e.$d,
                              });
                            }}
                            renderInput={(props) =>
                              renderInputs(props, "To date", "date")
                            }
                          />
                        </LocalizationProvider>
                      </div>
                    )}
                  </div>
                </div>
                <div className="downloadoptions">
                  <Button
                    variant="contained"
                    onClick={() => {
                      setDownloadTab((k) => !k);
                      setDateSearch("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    disabled={dateSearch == ""}
                    onClick={() => handleuploadExcel()}
                  >
                    Download
                  </Button>{" "}
                  {/*onClick={handleuploadExcel}*/}
                </div>
              </div>
            </div>
          )}

          <TableComponent
            header={header}
            filterManage={"camp"}
            datalist={campsList}
            datalength={length}
            editData={(id) => editPopUP(id)}
            deleteData={(id) => deleteDate(id)}
            pageInfo={pageInfo}
            actions={["edit", "delete"]}
            setPageInfo={setPageInfo}
            handleTablePage={handleTablePage}
            handleTableRowsPerPage={handleTableRowsPerPage}
            dateFields={dateFields}
            filter="camp"
            filterResponseValue={(val) => filterResponse(val)}
            searchDataValue={(val) => handleSearchValue(val)}
          />
          {deletePopup ? (
            <ConfirmPopup
              message={"Are you sure, you want to delete this record"}
              deleteCancel={deleteCancel}
              deleteSubmit={deleteSubmit}
            ></ConfirmPopup>
          ) : (
            ""
          )}
        </div>
      ) : (
        <Forms
          addCampToggle={() => addCampToggle()}
          userDetails={addCamp === true ? initialState : userDetails}
          editState={editState === true ? editState : false}
        />
      )}
    </>
  );
}
