import React, { useState, useEffect } from "react";

import {
  TextField,
  Stack,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useAxios } from "../../hooks/useAxios.js";
import moment from "moment/moment";

const UnitWisePerformanceFilter = ({ filterObject }) => {
  const currentYear = moment().year("YYYY");
  const [hospitalname, setHospitalName] = useState("");
  const [fYear, setFYear] = useState({
    year: "",
    fromDate: "",
    toDate: "",
  });
  const [reset, setReset] = useState(false);

  const [hospitals, setHospital] = useState([]);
  const { axios } = useAxios();

  const handleSubmit = () => {
    var hospital = hospitals?.find((x) => x._id == hospitalname);
    let obj = {
      hospital_name: hospital == undefined ? "" : hospital?.hospital_name,
      fromDate: fYear.fromDate,
      toDate: fYear.toDate,
    };
    filterObject(obj);
  };

  const handleClose = () => {
    let obj = {
      hospital_name: "",
      fromDate: "",
      toDate: "",
    };
    setHospitalName(obj.hospital_name);
    setFYear((prevState) => ({
      ...prevState,
      year: "",
      fromDate: "",
      toDate: "",
    }));
    filterObject(obj);
    setReset(!reset);
  };

  useEffect(() => {
    loadHospital();
  }, []);

  async function loadHospital() {
    try {
      await axios.post("AllHospitalDropDown").then((res) => {
        let result = res.data.result;
        console.log("result", result);

        setHospital(
          result.map((data) => {
            return {
              _id: data._id,
              hospital_name: data.hospital_name,
            };
          })
        );
      });
    } catch (error) {
      console.log("hos", error);
    }
  }

  function handleHospitalDrop(e) {
    setHospitalName(e.target.value);
  }

  function getFinancialYear(val) {
    try {
      const fromDate = moment(`04-01-${val.$y}`, "MM-DD-YYYY").format(
        "YYYY-MM-DD"
      );
      const toDate = moment(`03-31-${val.$y + 1}`, "MM-DD-YYYY").format(
        "YYYY-MM-DD"
      );
      setFYear((prevState) => ({
        ...prevState,
        year: val,
        fromDate,
        toDate,
      }));
    } catch (error) {
      console.log("error*", error);
    }
  }

  function firstLetter(s) {
    return s?.replace(/^.{1}/g, s[0].toUpperCase());
  }

  return (
    <div style={{ marginBottom: "0.5%" }}>
      <Stack direction="row" spacing={2}>
        <FormControl style={{ width: "20%" }} size="small">
          <InputLabel id="select-label">Select Hospital</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            variant="outlined"
            label="Select Hospitals"
            value={hospitalname}
            onChange={(e) => {
              handleHospitalDrop(e);
            }}
          >
            {hospitals?.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {firstLetter(option.hospital_name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={["year"]}
            label="Select Financial Year"
            value={fYear.year ? fYear.year : null}
            onChange={(newValue) => {
              getFinancialYear(newValue);
            }}
            maxDate={currentYear}
            renderInput={(params) => (
              <TextField {...params} sx={{ width: "20%" }} size="small" />
            )}
          />
        </LocalizationProvider>

        <Button
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          style={{ width: "5%" }}
          onClick={handleSubmit}
        >
          Search
        </Button>
        <Button
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          style={{ width: "5%" }}
          onClick={handleClose}
        >
          Reset
        </Button>
      </Stack>
    </div>
  );
};
export default UnitWisePerformanceFilter;
