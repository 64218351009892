import React from "react";
import { Outlet } from "react-router-dom";
import "./Style.css"
import { Container, Card } from "@mui/material";
export default function AuthLayout() {
  return (
    <div className="background ">
          <Outlet />
    </div>
  );
}