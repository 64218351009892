import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import format from "date-fns/format";
import Paper from "@mui/material/Paper";
// import { useAxios } from "../hooks/useAxios";
import PreviewIcon from "@mui/icons-material/Preview";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IconButton,
  TablePagination,
  InputBase,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import "./Style.css";
import PatientFilter from "./Filter/PatientFilter";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ArrowRight, Delete, KeyboardArrowDown } from "@mui/icons-material";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import CampPerformFilter from "../components/Filter/CampPerform";
import VisionFilter from "./Filter/VisionCenter";
import { useAxios } from "../hooks/useAxios";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.primary.shade,
//   },

//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));
const TableComponent = ({
  name,
  header,
  colors,
  datalist,
  editData,
  deleteData,
  pageInfo,
  viewDetails,
  patientQrView,
  OpenDialog,
  viewFamily,
  changePassword,
  handleTableRowsPerPage,
  handleTablePage,
  dateFields,
  actions,
  filter,
  filteredData,
  searchDataValue,
  filterResponseValue,
  filterFwTypee,
  fieldworker,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dateFormat = process.env.REACT_APP_DATE_FORMAT;
  const [rows, setRows] = useState(datalist);
  const [search, setSearchValue] = useState("");
  const [searchText, setSearchText] = useState("");
  // const [patientView,setPatientView]=useState('')
  const [hospitalName, setHospital] = useState([]);
  const { axios } = useAxios();

  // type fieldWorkerTypeList
  const fieldWorkerTypeList = [
    {
      _id: 3,
      name: "VHV",
    },
    {
      _id: 4,
      name: "Volunteers",
    },
    {
      _id: 5,
      name: "Optometrists",
    },
    {
      _id: 6,
      name: "Campsite Registration",
    },
  ];

  const fW = {
    vhv: null,
    cbbf: null,
  };

  const [fieldWorkers, setFieldWorkers] = useState(fW);

  useEffect(() => {
    setRows(datalist);
  }, [datalist]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleTablePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    handleTableRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    handleTablePage(page);
  };

  function editRecord(data) {
    editData(data._id);
  }
  function deleteRecord(data, name) {
    deleteData(data._id);
  }
  function viewRecord(datalist) {
    viewDetails(datalist);
  }
  function viewPatientQr(datalist = null) {
    patientQrView(datalist);
  }
  function openGlass(datalist) {
    OpenDialog(datalist);
    OpenDialog(datalist);
  }
  function viewFamilyPopUp(datalist) {
    viewFamily(datalist);
  }

  const handlePateintFilter = () => {
    return <PatientFilter />;
  };
  const handleFilterData = (val) => {
    filteredData(val);
  };
  const handleSubmit = () => {
    searchDataValue(searchText);

    setPage(1);
  };
  const handleClear = () => {
    searchDataValue("");
    setSearchText("");
  };

  /* --- search filter for master  Globally , In future filter method have to do ----- */

  const filterData = rows?.filter((data) => {
    if (search === "") {
      return data;
    } else if (
      data.camp_no?.toLowerCase().includes(search.toLowerCase()) ||
      data.camp_place?.toLowerCase().includes(search.toLowerCase()) ||
      data.hospital_name?.toLowerCase().includes(search.toLowerCase()) ||
      data.name?.toLowerCase().includes(search.toLowerCase()) ||
      data.referral_number?.toLowerCase().includes(search.toLowerCase()) ||
      data.field_worker?.toLowerCase().includes(search.toLowerCase())
    ) {
      return data;
    }
  });

  const filteredObjectArray = (val) => {
    setPage(0);
    filterResponseValue(val);
  };

  const filterFwType = (e) => {
    setPage(0);
    filterFwTypee(e);
  };

  useEffect(() => {
    loadHospital();
    loadFieldWorkers();
  }, []);

  async function loadHospital() {
    try {
      await axios.post("AllHospitalDropDown").then((res) => {
        let result = res.data.result;
        console.log("result", result);

        setHospital(
          result.map((data) => {
            return {
              _id: data._id,
              hospital_name: data.hospital_name,
            };
          })
        );
      });
    } catch (error) {
      console.log("hos", error);
    }
  }

  function handleHospital(val) {
    var value = {
      hospital_id: val == undefined ? "" : [val?.target?.value],
    };
    console.log("value", value);

    loadFieldWorkers(value);
  }

  async function loadFieldWorkers(data) {
    try {
      await axios.post("getGISFieldWorkerList", data).then((res) => {
        var field_w = res.data.result.result;

        var vhvvalue = field_w?.find((i) => i.field_worker_type === "VHV");
        var cbbfvalue = field_w?.find((i) => i.field_worker_type === "CBBF");

        vhvvalue = vhvvalue == undefined ? {} : vhvvalue;
        cbbfvalue = cbbfvalue == undefined ? {} : cbbfvalue;

        setFieldWorkers({ ...fieldWorkers, vhv: vhvvalue, cbbf: cbbfvalue });
      });
    } catch (error) {
      console.log("fw-error", error);
    }
  }

  return (
    <div>
      <div className="assests">
        <div style={{ width: "80%" }}>
          {filter === "camp_perform" ? (
            <CampPerformFilter
              filterObject={(val) => filteredObjectArray(val)}
              hospitalDrop={hospitalName}
              fieldWorkers={fieldWorkers}
              fieldWorkersType={fieldWorkerTypeList}
              filterFwType={(e) => filterFwType(e)}
              handleHospital={(val) => handleHospital(val)}
            />
          ) : filter === "patient" ? (
            <PatientFilter
              filterObject={(val) => filteredObjectArray(val)}
              fieldworker={fieldworker}
              hospitalDrop={hospitalName}
              fieldWorkers={fieldWorkers}
              handleHospital={(val) => handleHospital(val)}
            />
          ) : filter === "visioncenter" ? (
            <VisionFilter filterObject={(val) => filteredObjectArray(val)} />
          ) : (
            <Card style={{ width: "85vw", marginBottom: "-1%" }}>
              <CardContent>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <TextField
                    id="standard-basic"
                    placeholder="Search text ..."
                    variant="outlined"
                    onChange={(e) => setSearchText(e.target.value)}
                    value={searchText}
                    style={{ width: "30%" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),

                      style: {
                        height: "6vh",
                        marginTop: "2%",
                      },
                    }}
                  />
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={handleSubmit}
                      style={{
                        cursor: "pointer",
                        textTransform: "capitalize",
                        width: "50%",
                        marginLeft: "2%",
                      }}
                      variant="contained"
                      startIcon={<SearchIcon />}
                    >
                      {" "}
                      Search{" "}
                    </Button>
                    <Button
                      onClick={handleClear}
                      style={{
                        cursor: "pointer",
                        textTransform: "capitalize",
                        marginTop: "6%",
                      }}
                      variant="contained"
                      startIcon={<Delete />}
                    >
                      {" "}
                      Reset{" "}
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
        </div>
      </div>
      <div style={{ width: "94vw" }}>
        {/* {datalist !== undefined ? ( */}
        <>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    key="0001"
                    align="center"
                    width="10%"
                    className="tomato"
                  >
                    S.No
                  </StyledTableCell>
                  {header.map((data, index) => {
                    return (
                      <StyledTableCell
                        align="center"
                        key={data.id}
                        className="tomato"
                      >
                        {data.name}
                      </StyledTableCell>
                    );
                  })}

                  {actions.length > 0 ? (
                    <StyledTableCell align="center" className="tomato">
                      Action
                    </StyledTableCell>
                  ) : (
                    <></>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows !== undefined ? (
                  <>
                    {rows.map((datalist, index) => {
                      return (
                        <TableRow key={index + 1}>
                          <StyledTableCell
                            align="center"
                            sx={
                              colors
                                ? datalist?.base_hospital_status ||
                                  datalist?.glass_prescription_status ||
                                  datalist?.vision_center_status ||
                                  datalist?.surgery_status == "4"
                                  ? // ? { backgroundColor: "#ff8d8d" }
                                    { backgroundColor: "#38aa3880" }
                                  : datalist?.advised_to == "Normal"
                                  ? { backgroundColor: "#e4e4e4ae" }
                                  : // : { backgroundColor: "#38aa3880" }
                                  datalist?.nsds == "nsds"
                                  ? { backgroundColor: "#d8e934" }
                                  : { backgroundColor: "#ff8d8d" }
                                : ""
                            }
                          >
                            {index + 1}
                          </StyledTableCell>
                          {header.map((headers, index) => {
                            // setPatientView(headers.value === "referral_number"?datalist._id:'')
                            return (
                              <StyledTableCell
                                key={headers.id}
                                align="center"
                                sx={
                                  colors
                                    ? datalist?.base_hospital_status ||
                                      datalist?.glass_prescription_status ||
                                      datalist?.vision_center_status ||
                                      datalist?.surgery_status == "4"
                                      ? // ? { backgroundColor: "#ff8d8d" }
                                        { backgroundColor: "#38aa3880" }
                                      : datalist?.advised_to == "Normal"
                                      ? { backgroundColor: "#e4e4e4ae" }
                                      : // : { backgroundColor: "#38aa3880" }
                                      datalist?.nsds == "nsds"
                                      ? { backgroundColor: "#d8e934" }
                                      : { backgroundColor: "#ff8d8d" }
                                    : ""
                                }
                                style={{
                                  textTransform:
                                    headers.value === "email"
                                      ? "lowercase"
                                      : "capitalize",
                                }}
                              >
                                {dateFields
                                  ? dateFields.includes(headers.value)
                                    ? format(
                                        new Date(datalist[headers.value]),
                                        dateFormat
                                      )
                                    : headers.value == "child_count_male"
                                    ? datalist[headers.value] +
                                      datalist["child_count_female"]
                                    : datalist[headers.value]
                                  : headers.value === "status"
                                  ? datalist[headers.value] === 0
                                    ? "In active"
                                    : "Active"
                                  : datalist[headers.value]}
                              </StyledTableCell>
                            );
                          })}
                          {}
                          {actions.length > 0 ? (
                            <StyledTableCell
                              align="center"
                              sx={
                                colors
                                  ? datalist?.base_hospital_status ||
                                    datalist?.glass_prescription_status ||
                                    datalist?.vision_center_status ||
                                    datalist?.surgery_status == "4"
                                    ? // ? { backgroundColor: "#ff8d8d" }
                                      { backgroundColor: "#38aa3880" }
                                    : datalist?.advised_to == "Normal"
                                    ? { backgroundColor: "#e4e4e4ae" }
                                    : // : { backgroundColor: "#38aa3880" }
                                    datalist?.nsds == "nsds"
                                    ? { backgroundColor: "#d8e934" }
                                    : { backgroundColor: "#ff8d8d" }
                                  : ""
                              }
                            >
                              {actions.includes("edit") ? (
                                <IconButton
                                  aria-label="edit"
                                  size="small"
                                  onClick={() => editRecord(datalist, name)}
                                >
                                  <EditIcon fontSize="inherit" />
                                </IconButton>
                              ) : (
                                ""
                              )}
                              {actions.includes("delete") ? (
                                <IconButton
                                  aria-label="delete"
                                  size="small"
                                  onClick={() => deleteRecord(datalist, name)}
                                >
                                  <DeleteIcon fontSize="inherit" />
                                </IconButton>
                              ) : (
                                ""
                              )}
                              {actions.includes("view") ? (
                                <IconButton
                                  size="small"
                                  onClick={() => viewRecord(datalist)}
                                >
                                  <PreviewIcon fontSize="inherit" />
                                </IconButton>
                              ) : (
                                ""
                              )}

                              {/* patient QR */}
                              {actions.includes("patient_qr") ? (
                                <IconButton
                                  size="small"
                                  onClick={() => viewPatientQr(datalist)}
                                >
                                  <QrCodeScannerIcon fontSize="inherit" />
                                </IconButton>
                              ) : (
                                ""
                              )}

                              {datalist?.advised_to == "Normal" ? (
                                ""
                              ) : actions.includes("glass") ? (
                                <IconButton
                                  size="small"
                                  onClick={() => openGlass(datalist)}
                                >
                                  <MoreVertIcon fontSize="inherit" />
                                </IconButton>
                              ) : (
                                ""
                              )}
                              {/* {actions.includes("family_view") ?  (
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    viewFamilyPopUp(datalist._id)
                                  }
                                >
                                  <FamilyRestroomIcon fontSize="inherit" />
                                </IconButton>
                              ) : (
                                ""
                              )} */}
                            </StyledTableCell>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <TableRow>
                      <StyledTableCell
                        align="center"
                        colSpan={header?.length + 2}
                      >
                        No Data found
                      </StyledTableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={datalist !== undefined ? pageInfo.count : 0}
            rowsPerPage={rowsPerPage}
            page={page !== undefined ? page : 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
        {/* ) : (
        ""
      )} */}
      </div>
    </div>
  );
};

export default TableComponent;
