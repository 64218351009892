import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useAxios } from "../../../../hooks/useAxios";


// const YellowCardHome = (props) => {
const YellowCardHome = ({ yellowCardOrder }) => {


  function firstLetter(s) {
    return s?.replace(/^.{1}/g, s[0].toUpperCase());
  }

  const [yellowcard, setYellowCard] = useState([])

  let yellowCard = yellowCardOrder[0];
  let dataB = [
    `${yellowCardOrder?.length > 0 ? yellowCardOrder[0]?.hospital_name ? yellowCardOrder[0]?.hospital_name : "" : ""}`,
    yellowCardOrder?.length > 0 ? yellowCardOrder[0]?.total_issued : 0,
    yellowCardOrder?.length > 0 ? yellowCardOrder[0]?.total_surgery ? yellowCardOrder[0]?.total_surgery : 0 : 0
  ]


  useEffect(() => {
    yellowCardChartData()
  }, [yellowCardOrder])


  function yellowCardChartData() {

    var sorted = yellowCardOrder?.list?.sort((a, b) => {
      if (a.hospital_name < b.hospital_name) {
        return -1
      }
      if (a.hospital_name > b.hospital_name) {
        return 1
      }
      return 0
    })

    var array = []


    sorted?.map((x, index) => {

      array[0] = [
        "Hospital",
        "No. of Yellow Card Distributed ",
        "No.of Yellow Card Converted to GOV Surgery ",
      ]

      var hospitalname = x?.hospital_name !== undefined ? firstLetter(x?.hospital_name) : ""
      var total_issued = x?.total_issued == 0 ? 0 : x?.total_issued
      var total_surgery = x?.total_surgery == 0 ? 0 : x?.total_surgery

      return array[index + 1] = [hospitalname, total_issued, total_surgery]

    })

    setYellowCard(array)
  }


  const options = {
    colors: ["#fcc203", "green"],
    chartArea: { width: "50%" },
    isStacked: true,
    hAxis: {
      minValue: 0,
    },
    legend: { position: 'bottom' }
  };

  return (
    <div>
      <p style={{ display: "flex", justifyContent: "center", marginLeft: "3%", fontWeight: "bold", }}>        Yellow Cards converted to GOV Surgery      </p>
      <div      >
        <div style={{ width: "30vw", height: "40vh", }}        >
          {yellowCardOrder?.list?.length > 0 ? (
            <Chart chartType="BarChart" width={'100%'} height="40vh" data={yellowcard} options={options} />
          ) : (
            <div style={{ width: "100%", height: "40vh", display: "flex", justifyContent: "center", alignItems: "center", }}            >
              {" "}
              No yellow card issued/converted
            </div>
          )}
        </div>

        <div style={{ fontSize: "0.7rem", marginTop: "-2.3%" }}>
          <p>
            {" "}
            No. of Yellow Card Distributed :{" "}
            {yellowCardOrder?.total_number_yellow_distributed}{" "}
          </p>
          <p style={{ marginTop: "-2%" }}>
            {" "}
            No.of Yellow Card Converted to GOV Surgery :
            {yellowCardOrder?.surgery}{" "}
          </p>
          <p style={{ marginTop: "-2%" }}>Convertion rate :{Math.round(yellowCardOrder?.percentage)} </p>
        </div>
      </div>
    </div>
  );
};

export default YellowCardHome;
