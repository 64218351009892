import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./context/auth_context";


export const dates = [
  { id: 1, value: 'all', label: 'All' },
  { id: 2, value: 'last_week', label: 'Last Week' },
  { id: 3, value: 'last_month', label: 'Last Month' },
  { id: 4, value: 'last_3_month', label: 'Last 3 Month' },
  { id: 5, value: 'custom', label: 'Custom' },
]


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <App />
  </AuthProvider>
);

