import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const CotaractHome = (props) => {
  const { Cotaract, InduvidualScreen } = props;
  let malePercentage = Math.ceil(
    (Cotaract?.total_males / InduvidualScreen?.total_males) *
    100
  );
  let femalePercentage = Math.ceil(
    (Cotaract?.total_females / InduvidualScreen?.total_females) *100
  )
  let othersPercentage = Math.ceil(
    (Cotaract?.total_others / InduvidualScreen?.total_others) *
    100
  )

  return (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer style={{ width:'100%' }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead sx={{ backgroundColor: "peru" }}>
              <TableRow>
                <TableCell
                  align="left"
                  style={{ backgroundColor: "peru", fontWeight: "bold" }}
                >
                  Indicator
                </TableCell>
                <TableCell
                  align="center"
                  style={{ backgroundColor: "peru", fontWeight: "bold" }}
                >
                  Cataract Count
                </TableCell>
                <TableCell
                  align="center"
                  style={{ backgroundColor: "peru", fontWeight: "bold" }}
                >
                  Individual Screened Count
                </TableCell>
                <TableCell
                  align="center"
                  style={{ backgroundColor: "peru", fontWeight: "bold" }}
                >
                  Percentage
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left"><b>Male</b></TableCell>
                <TableCell align="center">{Cotaract?.total_males}</TableCell>
                <TableCell align="center">
                  {InduvidualScreen?.total_males}
                </TableCell>
                <TableCell align="center">
                  {malePercentage === Infinity ? 0 : malePercentage}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left"><b>Female</b></TableCell>
                <TableCell align="center">{Cotaract?.total_females}</TableCell>
                <TableCell align="center">
                  {InduvidualScreen?.total_females}
                </TableCell>
                <TableCell align="center">
                  {femalePercentage !== Infinity ? femalePercentage : 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left"><b>Others</b></TableCell>
                <TableCell align="center">{Cotaract?.total_others}</TableCell>
                <TableCell align="center">
                  {InduvidualScreen?.total_others}
                </TableCell>
                <TableCell align="center">
                  {othersPercentage !== Infinity ? othersPercentage : 0}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default CotaractHome;
