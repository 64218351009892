import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  DialogTitle,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
} from "@mui/material";
import { Form, Formik, Field } from "formik";

import { HybridInput, HybridSelect } from "../components/hybrid_inputs";
// import { axios } from "../api/axios";
import { UserSchema, UpdateUserSchema } from "../validations/userSchema";
import { useAxios } from "../hooks/useAxios";
import TableComponent from "../components/TableLayout";
import { FormDialogue } from "../components/formDialogue";
import ConfirmPopup from "../components/confirmPopup";
import LowerCase from "./LowerCase";
import CloseIcon from "@mui/icons-material/Close";

export default function AdminManagement() {
  const [open, setOpen] = useState(false);
  const { axios } = useAxios();
  const initialState = {
    username: "",
    email: "",
    password: "",
    // state: '',
    // district: '',
    // branch: ''
    state_id: "",
    district_id: "",
    hospital_id: "",
    state_name: "",
    district_name: "",
    hospital_name: "",
    status: "",
  };
  const updateState = {
    id: "",
    username: "",
    email: "",
    state_id: "",
    district_id: "",
    hospital_id: "",
    state_name: "",
    district_name: "",
    hospital_name: "",
    status: "",
  };

  const [DropDown] = useState([
    {
      _id: 1,
      status: "Active",
    },
    {
      _id: 0,
      status: "In active",
    },
  ]);
  const [header, setHeader] = useState([
    {
      id: 1,
      label: "User Name",
      value: "username",
    },
    {
      id: 2,
      label: "Email",
      value: "email",
    },
    {
      id: 3,
      label: "ID Creation Date",
      value: "created_on",
    },
    {
      id: 4,
      label: "Action",
      value: "action",
    },
  ]);

  let stateModel = {
    _id: "",
    state_name: "No data found",
  };

  let districtModel = {
    _id: "",
    district_name: "No data found",
  };

  let hospitalModel = {
    _id: "",
    hospital_name: "No data found",
  };

  let dateFields = ["created_on"];
  const [userList, setUserList] = useState([]);
  const [editState, setEditState] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [userDetails, setUserDetails] = useState(initialState);
  const [progressBar, setProgressBar] = useState(false);
  // const [changePassPopUp, setChangePasswordPopUp] = useState(false);
  const [openPassDialogue, setOpenPassDialogue] = useState(false);
  const [selectedAdminId, setSelectedAminId] = useState();
  const [deletePopup, setDeletePopup] = useState(false);

  const [stateList, setStateList] = useState([stateModel]);
  const [length, setLength] = useState();
  const [districtList, setDistrictList] = useState([districtModel]);
  const [hospitallist, setHospitalList] = useState([hospitalModel]);
  const [disabledDistrict, setDisabledDistrict] = useState(true);
  const [searchValue, setSearchValue] = React.useState("");
  const [HospitalValue, setHospitalValue] = useState([]);
  const [DistrictValue, setDistrictListData] = useState([]);
  const [disable, setDisable] = useState(true);

  // const [listLength, setListLength] = useState(0);
  let paginationInfo = {
    page: 1,
    count: 0,
    rowsPerPage: 10,
  };
  const [pageInfo, setPageInfo] = useState(paginationInfo);
  useEffect(() => {
    usersList();
  }, [pageInfo.rowsPerPage, pageInfo.page, searchValue]);

  const handleTablePage = (page) => {
    // setPageInfo((prevState) => ({
    //   ...prevState,
    //   page: parseInt(event.target.value, 10),
    // }));
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));

    // usersList();
  };
  const handleClose = () => {
    setOpen(false);
  };

  // prevent backDrop of dialog
  const handleClosePrevent = (reason) => {
    if (reason == "escapeKeyDown" || reason == "backdropClick") {
      setOpen(true);
    }
  };

  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows, page: 1 }));
  };

  const handleSearchValue = (values) => {
    setPageInfo(paginationInfo);
    setSearchValue(values);
  };
  const usersList = async () => {
    await axios
      .post(
        `usersList?limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}&search=${searchValue}`,
        filter
      )
      .then((res) => {
        setUserList(res.data.result.list);
        setLength(res.data.result.length ? res.data.result.length : 0);
        setPageInfo((prevState) => ({
          ...prevState,
          count: res.data.result.length ? res.data.result.length : 0,
        }));
      })
      .catch(() => {
        setUserList([]);
        setLength(0);
        setPageInfo(paginationInfo);
      });
  };

  // const reset = () => {
  //   setInitialValue({ state_name: "", status: "" });
  // };

  const deleteDate = (data) => {
    setActiveId(data);
    setDeletePopup(true);
  };
  function deleteCancel() {
    setDeletePopup(false);
  }
  const deleteSubmit = async (id) => {
    let insertData = {
      id: activeId,
    };
    await axios
      .post("deleteUser", insertData)
      .then((data) => {
        // setUserList(data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    usersList();
    setDeletePopup(false);
  };
  const openDialogue = () => {
    setEditState(false);
    setDisable(false);
    getStateList();
    getHospitalList();
    setOpen(true);
    setUserDetails(initialState);
  };

  const getStateList = () => {
    axios.post("statesDropDown").then((data) => {
      if (data.data.result.length > 0) {
        let result = LowerCase(data.data.result, "state_name");
        setStateList(result);
        // setStateList(data.data.result);
      } else {
        setStateList([stateModel]);
      }
    });
  };

  const getHospitalList = () => {
    axios.post("AllHospitalDropDown").then((data) => {
      if (data.data.result.length > 0) {
        let result = LowerCase(data.data.result, "hospital_name");
        setHospitalList(result);
        // setStateList(data.data.result);
      } else {
        setHospitalList([hospitalModel]);
      }
    });
  };

  const getDistrictList = async (value) => {
    setDistrictList([districtModel]);
    let state = {
      state_id: value,
    };
    await axios.post("districtDropDown", state).then((data) => {
      if (data.data.result.length > 0) {
        let result = LowerCase(data.data.result, "district_name");
        setDistrictList(result);
      } else {
        setDistrictList([districtModel]);
      }
    });
    setDisabledDistrict(false);
  };

  const closeDialogue = () => {
    // resetForm();
    setUserDetails(initialState);
    setOpen(false);
  };

  // const hospitalListFetch = (value) => {
  //   let obj = {
  //     state_id: value,
  //   };

  //   axios.post(`StateBasedHospitalDropDown`, obj).then((res) => {
  //     setHospitalValue(res.data.result);
  //   });
  // };

  // const DistrictListFetch = async (value) => {
  //   let state = {
  //     hospital_id: value,
  //   };
  //   await axios.post("HospitalBasedDistrictDropDown", state).then((data) => {
  //     if (data.data.result.length > 0) {
  //       let result = LowerCase(data.data.result[0].district, "district_name");

  //       setDistrictListData(result);
  //     }
  //   });
  // };

  const editPopUP = (data) => {
    setEditState(true);
    setDisable(false);
    getStateList();
    getHospitalList();
    setUserDetails(updateState);

    userList.forEach((list) => {
      if (list._id == data) {
        setActiveId(list._id);
        // hospitalListFetch(list.state_id);
        // DistrictListFetch(list.hospital_id);
        getDistrictList(list.state_id);
        setUserDetails((prevState) => ({
          ...prevState,
          id: list._id,
          username: list.username,
          email: list.email,
          state_id: list.state_id,
          district_id: list.district_id,
          hospital_id: list.hospital_id,
          state: list.state,
          district: list.district,
          branch: list.branch,
          status: list.status,
        }));
        setOpen(true);
      }
    });
  };

  const changePasswordPopUp = (id) => {
    setOpenPassDialogue(true);
    setSelectedAminId(id);
  };
  const changePassword = async (values) => {
    values["id"] = selectedAdminId;

    await axios
      .post("changeOtherPassword", values)
      .then((res) => {})
      .catch((err) => console.log(err));
  };
  const handleDialogueClose = () => {
    setOpenPassDialogue(false);
  };

  const formSubmit = async (values, actions) => {
    var state = stateList.find((i) => {
      if (i._id == values.state_id) {
        return i;
      }
    });
    var district = DistrictValue.find((i) => {
      if (i._id == values.district_id) {
        return i;
      }
    });
    var hospital = HospitalValue.find((i) => {
      if (i._id == values.hospital_id) {
        return i;
      }
    });
    var data = {
      // state_name: state.state_name,
      // district_name: district.district_name,
      // hospital_name: hospital.hospital_name,
      // state_id: values.state_id,
      // district_id: values.district_id,
      // hospital_id: values.hospital_id,
      username: values.username,
      password: values.password,
      email: values.email,
    };
    if (editState) {
      data = { ...data, status: values.status };
    }

    setProgressBar(true);

    if (editState) {
      setDisable(true);

      data.id = activeId;
      await axios
        .post("editUser", data)
        .then((res) => {
          console.log("edituser", res);
          closeDialogue();
          usersList();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setDisable(true);
      await axios
        .post("userCreate", data)
        .then((res) => {
          closeDialogue();
          usersList();
        })
        .catch((err) => {
          console.log(err);
        });
    }
    actions.resetForm();

    setProgressBar((prevState) => {
      prevState = false;
    });
    setEditState((prevState) => {
      prevState = false;
    });
    usersList();
  };

  const [filter, setFilter] = useState({
    username: "",
    email: "",
    employee_code: "",
  });
  const searchFilter = () => {
    usersList();
  };

  function handleChange(e) {
    var name = e.target.name;
    var val = e.target.value;

    setFilter({
      ...filter,
      [name]: val,
    });
  }

  return (
    <div>
      <div className="state_head">
        <Typography variant="h5" color="secondary" className="text-left">
          Admin Management
        </Typography>
        <Button
          variant="contained"
          sx={{
            float: "right",
            m: 1,
            width: "10ch",
            textTransform: "capitalize",
          }}
          onClick={openDialogue}
        >
          {" "}
          Add
        </Button>
      </div>
      <TableComponent
        header={header}
        datalist={userList}
        datalength={length}
        editData={(id) => editPopUP(id)}
        deleteData={(id) => deleteDate(id)}
        actions={["edit", "delete", "forget_password"]}
        changePassword={(id) => changePasswordPopUp(id)}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        handleTablePage={handleTablePage}
        handleTableRowsPerPage={handleTableRowsPerPage}
        dateFields={dateFields}
        searchDataValue={(val) => handleSearchValue(val)}
        searchValue={() => {
          setSearchValue("");
          setPageInfo(paginationInfo);
        }}
      />

      {/* <Dialog open={open} fullWidth onClose={closeDialogue}> */}
      <Dialog open={open} fullWidth onClose={handleClosePrevent}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle style={{ marginLeft: "12rem" }}>Add Admin</DialogTitle>
          <DialogTitle>
            <CloseIcon onClick={handleClose} />
          </DialogTitle>
        </div>
        <Formik
          initialValues={userDetails}
          validationSchema={editState ? UpdateUserSchema : UserSchema}
          onSubmit={formSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <DialogContent>
                <HybridInput
                  label="Username"
                  name="username"
                  type="text"
                  setVal={setFieldValue}
                  fullWidth
                  variant="standard"
                />

                <HybridInput
                  label="Email"
                  name="email"
                  type="email"
                  setVal={setFieldValue}
                  fullWidth
                  variant="standard"
                />

                {!editState && (
                  <HybridInput
                    label="Password"
                    name="password"
                    type="password"
                    setVal={setFieldValue}
                    fullWidth
                    variant="standard"
                  />
                )}
                {editState && (
                  <HybridInput
                    label="New Password"
                    name="password"
                    type="password"
                    setVal={setFieldValue}
                    fullWidth
                    variant="standard"
                  />
                )}

                {/* <Field name="state_id">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel
                        id="select-label"
                        style={{ marginLeft: "-2%", marginTop: "1.7%" }}
                      >
                        Select State
                      </InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        {...field}
                        value={field.value}
                        onChange={(event) => {
                          setFieldValue(field.name, event.target.value);
                          hospitalListFetch(event.target.value);
                        }}
                      >
                        {stateList.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.state_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
                <Field name="hospital_id">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel
                        id="select-label"
                        style={{ marginLeft: "-2%", marginTop: "1.7%" }}
                      >
                        Hospital Name
                      </InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        {...field}
                        value={field.value}
                        onChange={(event) => {
                          setFieldValue(field.name, event.target.value);
                          DistrictListFetch(event.target.value);
                        }}
                      >
                        {HospitalValue?.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.hospital_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
                <Field name="district_id">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel
                        id="select-label"
                        style={{ marginLeft: "-2%", marginTop: "1.7%" }}
                      >
                        Select District
                      </InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        {...field}
                        value={field.value}
                        onChange={(event) =>
                          setFieldValue(field.name, event.target.value)
                        }
                      >
                        {DistrictValue.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.district_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field> */}

                <HybridSelect
                  label="Status"
                  name="status"
                  fullWidth
                  type="text"
                  defaultValue={""}
                  setVal={setFieldValue}
                  variant="standard"
                  options={DropDown}
                  dropKey="status"
                  dropVal="_id"
                />
              </DialogContent>
              <DialogActions>
                <div className="submit">
                  <Stack direction="row" spacing={2}>
                    <Button
                      color="primary"
                      type="button"
                      variant="contained"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={disable ? true : false}
                    >
                      {editState ? "Update" : "Add"}
                    </Button>
                  </Stack>
                </div>
              </DialogActions>
            </Form>
          )}
        </Formik>
        {progressBar && <LinearProgress color="primary" />}
      </Dialog>

      {/* <EnhancedTable /> */}
      <FormDialogue
        open={openPassDialogue}
        close={handleDialogueClose}
        formData={changePassword}
      />
      {deletePopup ? (
        <ConfirmPopup
          message={"Are you sure, you want to delete this record"}
          deleteCancel={deleteCancel}
          deleteSubmit={deleteSubmit}
        ></ConfirmPopup>
      ) : (
        ""
      )}
    </div>
  );
}
