import React, { useEffect, useState, useRef } from "react";
import "./Style.scss";

// backdrop component
import BackDrop from "../../components/BackDrop.js";

/*---- Components imports ----------- */
import { DataGrid, GridToolbar, GridFooter } from "@mui/x-data-grid";
import { useAxios } from "../../hooks/useAxios";
import FieldWorkerFilter from "../../components/Filter/FieldWorkerFilter";
import { Button, Box } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import format from "date-fns/format";
import ExcelDownload from "../../components/ExcelFormat/ExcelFileDownload";
import FieldworkerReportFilter from "../../components/Filter/FieldworkerReportFilter";
import { toast, Slide } from "react-toastify";

import moment from "moment";

import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
// import dates from '../../index.js'

import { dates } from "../../index";

import {
  Table,
  makeStyles,
  FormControl,
  TableBody,
  TableCell,
  InputLabel,
  Input,
  Select,
  MenuItem,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";

const head = [
  // { field: 'lineNo', headerName: 'S no', flex: 0, editable: false, renderCell: (params: GridRenderCellParams<DatasetEntryEntity>) => params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1, headerClassName: 'super-app-theme--header' },
  {
    field: "lineNo",
    headerName: "S.No",
    flex: 0,
    editable: false,
    renderCell: (params: GridRenderCellParams<DatasetEntryEntity>) =>
      params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "ALL",
    headerName: "ALL",
    isChecked: false,
    width: 150,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "hospital_name",
    headerName: "Hospital Name",
    isChecked: false,
    width: 150,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "fieldworker_type",
    headerName: "Fieldworker Type",
    isChecked: false,
    width: 150,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "field_worker_name",
    headerName: "Field Worker's Name",
    isChecked: false,
    width: 170,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "camp_number",
    headerName: "Camp No.",
    isChecked: false,
    width: 120,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "camp_date",
    headerName: "Camp Date",
    isChecked: false,
    width: 120,
    headerClassName: "super-app-theme--header",
  },
  // { field: 'date_of_review_camp', headerName: 'Date of Review Camp', width: 190, headerClassName: 'super-app-theme--header', },
  {
    field: "daysCount",
    headerName: "No. of Household Survey Days",
    isChecked: false,
    width: 120,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "TravelDistance",
    headerName: "Distance Travelled (Km)",
    isChecked: false,
    width: 170,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "household_target",
    headerName: "Household Survey(Target)",
    isChecked: false,
    width: 180,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Household_Survey_Actual",
    headerName: "Household Survey (Actuals)",
    isChecked: false,
    width: 190,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "individual_screening_target",
    headerName: "Individual Screening (Target)",
    isChecked: false,
    width: 200,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Individuals_Screening_Actual",
    headerName: "Individuals Screening (Actuals)",
    isChecked: false,
    width: 220,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "yellow_card_target",
    headerName: "YC Distribution (Target)",
    isChecked: false,
    width: 230,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "YelloCard_Distributed_Actual",
    headerName: "YC Distribution (Actuals)",
    isChecked: false,
    width: 230,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "total_males",
    headerName: "Male Count",
    isChecked: false,
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "total_females",
    headerName: "Female Count",
    isChecked: false,
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "total_others",
    headerName: "Others Count",
    isChecked: false,
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "total_children",
    headerName: "Child Count",
    isChecked: false,
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "total_op",
    headerName: "Total OP",
    isChecked: false,
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "PinkCard_Distributed_Actual",
    headerName: "PC Distribution (Actuals)",
    isChecked: false,
    width: 230,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "total_yellow_card_collected",
    headerName: "YC Reported to camp",
    isChecked: false,
    width: 180,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "total_pink_collected_camp",
    headerName: "PC Reported to Camp",
    isChecked: false,
    width: 220,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "pc_collected_at_vision_center",
    headerName: "PC Collected at Vision Center",
    isChecked: false,
    width: 220,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "total_yellow_card_selected",
    headerName: "YC Selected for GOV Surgery",
    isChecked: false,
    width: 280,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "total_pink_card_surgery",
    headerName: "PC Selected for GOV Surgery",
    isChecked: false,
    width: 280,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "total_surgery_done",
    headerName: "YC Converted for GOV Surgery",
    isChecked: false,
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "pc_converted_to_gov_surgery",
    headerName: "PC Converted for GOV Surgery",
    isChecked: false,
    width: 300,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Other_camp_count",
    headerName: "Other Camp",
    isChecked: false,
    width: 130,
    headerClassName: "super-app-theme--header",
  },
];

const renderInputs = (props, placeholder, classname) => (
  <Input
    sx={{
      width: "100%",
      input: {
        "&::placeholder": {
          // <----- Add this.
          opacity: 1,
          // marginBottom: '-5px',
          paddingLeft: "10px",
          fontSize: "13px",
          color: "#000000",
        },
        width: "100%",
      },
      backgroundColor: "#ffffff",
      // borderRadius: '5px',
      // maxHeight: '50px'
    }}
    type="text"
    className={classname}
    inputRef={props.inputRef}
    // inputProps={props.inputProps}
    inputProps={{
      ...props.inputProps,
      placeholder: placeholder,
    }}
    value={props.value}
    onClick={props.onClick}
    onChange={props.onChange}
    endAdornment={props.InputProps?.endAdornment}
  />
);

const FieldWorkersReports = () => {
  const handleExcel = useRef();

  const dateFormat = process.env.REACT_APP_DATE_FORMAT;

  function CustomToolBar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarContainer />
        <GridToolbarExport />
        {/* <GridToolbarFilterButton /> */}
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  const CustomFooter = () => {
    return (
      <>
        <div className="footer">
          <div>
            <h5>
              HH Actual : <span>{householdActual}</span>{" "}
            </h5>
          </div>
          <div>
            <h5>
              {" "}
              HH Target :<span>{householdTarget} </span>
            </h5>
          </div>
          <div>
            <h5>
              {" "}
              YC Actual : <span>{YellowDistributeActual}</span>{" "}
            </h5>
          </div>
          <div>
            <h5>
              {" "}
              YC Target :<span>{yellowCardTarget} </span>
            </h5>
          </div>
          <div>
            <h5>
              {" "}
              Individual Screen Target :<span>
                {IndividualScreenTarget}
              </span>{" "}
            </h5>
          </div>
          <div>
            <h5>
              {" "}
              Individual Screen Actual : <span>
                {IndividualScreenActual}{" "}
              </span>{" "}
            </h5>
          </div>
          <div>
            <h5>
              {" "}
              Total YCard Collected : <span>{yellowCard}</span>{" "}
            </h5>
          </div>
          <div>
            <h5>
              {" "}
              Total YC Selected for GOV :<span>{yellowCardConvert} </span>{" "}
            </h5>
          </div>
          <div>
            <h5>
              {" "}
              Total YC Converted for GOV : <span>{yellowCardGovSur}</span>{" "}
            </h5>
          </div>
          <div>
            <h5>
              {" "}
              PC Distribution Actual : <span>{pinkCardActual}</span>{" "}
            </h5>
          </div>
          <div>
            <h5>
              {" "}
              PC Collected at Camp :<span>{pinkCardTarget} </span>
            </h5>
          </div>
        </div>
        <GridFooter sx={{ p: 1, display: "flex" }}></GridFooter>
      </>
    );
  };

  const { axios } = useAxios();

  // loader state
  const [backDropOpen, setBackDropOpen] = React.useState(false);

  const [rows, setRows] = React.useState([]);
  const [yellowCard, setYellowcard] = React.useState(0);
  const [yellowCardConvert, setYellowconvertGov] = React.useState(0);
  const [yellowCardGovSur, setYellowDistributeGOv] = React.useState(0);

  const [householdActual, setHouseHoldActual] = React.useState(0);
  const [householdTarget, setHouseHoldTarget] = React.useState(0);
  const [yellowCardTarget, setYellowcardTarget] = React.useState(0);
  const [YellowDistributeActual, setYellowDistributeActual] = React.useState(0);
  const [IndividualScreenActual, setIndividualScreenActual] = React.useState(0);
  const [IndividualScreenTarget, setIndividualScreenTarget] = React.useState(0);
  const [pinkCardActual, setpinkCardActual] = React.useState(0);
  const [pinkCardTarget, setPinkCardTarget] = React.useState(0);

  const [length, setLength] = React.useState(0);

  const [hospitals, setHospital] = useState([]);

  const [downloadTab, setDownloadTab] = useState(false);
  const [value, setValue] = useState([]);
  const [headerValue, setHeaderValue] = useState(head);
  const [dateSearch, setDateSearch] = useState("");

  const fW = {
    vhv: null,
    cbbf: null,
  };

  const [fieldWorkers, setFieldWorkers] = useState(fW);

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 0,
  });

  const search = {
    campStart: "",
    campEnd: "",
    hospitalName: "",
    name: "",
    camp_num: "",
    camp_place: "",
  };

  const [filterValue, setFilterValue] = React.useState(search);

  const [searchValue, setSearchValue] = React.useState({
    eventStart: "",
    eventEnd: "",
  });

  const columns = [
    // { field: 'lineNo', headerName: 'S no', flex: 0, editable: false, renderCell: (params: GridRenderCellParams<DatasetEntryEntity>) => params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1, headerClassName: 'super-app-theme--header' },
    {
      field: "lineNo",
      headerName: "S.No",
      flex: 0,
      editable: false,
      renderCell: (params: GridRenderCellParams<DatasetEntryEntity>) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "hospital_name",
      headerName: "Hospital Name",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "fieldworker_type",
      headerName: "Fieldworker Type",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "field_worker_name",
      headerName: "Field Worker's Name",
      width: 170,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "camp_number",
      headerName: "Camp No.",
      width: 120,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "camp_date",
      headerName: "Camp Date",
      width: 120,
      headerClassName: "super-app-theme--header",
    },
    // { field: 'date_of_review_camp', headerName: 'Date of Review Camp', width: 190, headerClassName: 'super-app-theme--header', },
    {
      field: "daysCount",
      headerName: "No. of Household Survey Days",
      width: 120,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "TravelDistance",
      headerName: "Distance Travelled (Km)",
      width: 170,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "household_target",
      headerName: "Household Survey(Target)",
      width: 180,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Household_Survey_Actual",
      headerName: "Household Survey (Actuals)",
      width: 190,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "individual_screening_target",
      headerName: "Individual Screening (Target)",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Individuals_Screening_Actual",
      headerName: "Individuals Screening (Actuals)",
      width: 220,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "yellow_card_target",
      headerName: "YC Distribution (Target)",
      width: 230,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "YelloCard_Distributed_Actual",
      headerName: "YC Distribution (Actuals)",
      width: 230,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_males",
      headerName: "Male Count",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_females",
      headerName: "Female Count",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_others",
      headerName: "Others Count",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_children",
      headerName: "Child Count",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_op",
      headerName: "Total OP",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "PinkCard_Distributed_Actual",
      headerName: "PC Distribution (Actuals)",
      width: 230,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_yellow_card_collected",
      headerName: "YC Reported to camp",
      width: 180,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_pink_collected_camp",
      headerName: "PC Reported to Camp",
      width: 220,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "pc_collected_at_vision_center",
      headerName: "PC Collected at Vision Center",
      width: 220,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_yellow_card_selected",
      headerName: "YC Selected for GOV Surgery",
      width: 280,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_pink_card_surgery",
      headerName: "PC Selected for GOV Surgery",
      width: 280,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_surgery_done",
      headerName: "YC Converted for GOV Surgery",
      width: 300,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "pc_converted_to_gov_surgery",
      headerName: "PC Converted for GOV Surgery",
      width: 300,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Other_camp_count",
      headerName: "Other Camp",
      width: 130,
      headerClassName: "super-app-theme--header",
    },
  ];
  /*-------------   Rows -----------------------*/
  useEffect(() => {
    fetchData();
  }, [paginationModel, filterValue]); //filterValue

  useEffect(() => {
    loadHospital();
    loadFieldWorkers();
  }, []);

  async function fetchData() {
    try {
      const res = await axios.post(
        `CampWiseVHVReport?fromDate=${filterValue.campStart}&toDate=${
          filterValue.campEnd
        }&hospital_name=${filterValue.hospitalName}&field_worker_name=${
          filterValue.name
        }&camp_number=${filterValue.camp_num}&camp_place=${
          filterValue.camp_place
        }&limit=${paginationModel.pageSize}&page=${paginationModel.page + 1}`
      );

      const totalCount = res?.data?.result?.length || 0;
      if (totalCount == 0) {
        setRows([]);
        setLength(totalCount);
      } else {
        setLength(totalCount);

        let newArrayOfObj = res.data.result.list?.map(
          ({ _id: id, ...rest }) => ({ id, ...rest })
        );
        newArrayOfObj.forEach((object) => {
          delete object["houseHold"];
        });
        let newArr = newArrayOfObj.filter((data) => {
          return data.id !== null;
        });
        let newArray = newArr.map((data) => {
          return {
            ...data,
            camp_date: format(new Date(data.camp_date), "yyyy-MM-dd"),
          };
        });

        let newArrayDate = newArray.map((data) => {
          return {
            ...data,
            date_of_review_camp:
              data?.date_of_review_camp?.length > 0
                ? data?.date_of_review_camp.slice(0, 10)
                : data?.date_of_review_camp,
          };
        });

        let distanceTravel = newArrayDate.map((data) => {
          return {
            ...data,
            TravelDistance: Number(data.TravelDistance).toFixed(5),
            fieldworker_type: data?.worker_type
              ?.map(({ role_name }) => role_name)
              .toString(),
            field_worker_name: data?.field_worker
              ?.map(({ field_worker_name }) => field_worker_name)
              .toString(),
          };
        });

        setRows(distanceTravel);

        console.log("newArrayOfObj", newArrayOfObj);

        setYellowcard(
          newArrayDate.reduce((accumulator, object) => {
            return accumulator + object.total_yellow_card_collected; // yellowcard_collected
          }, 0)
        );
        setYellowconvertGov(
          newArrayDate.reduce((accumulator, object) => {
            return accumulator + object.total_yellow_card_selected; // yellowcard selected for gov
          }, 0)
        );
        setYellowDistributeGOv(
          newArrayDate.reduce((accumulator, object) => {
            console.log("surgery_done", object.total_surgery_done);

            return accumulator + object.total_surgery_done; // yellowcard converted for gov
          }, 0)
        );

        setHouseHoldActual(
          newArrayDate.reduce((accumulator, object) => {
            return accumulator + object.Household_Survey_Actual; // household_actual
          }, 0)
        );
        setHouseHoldTarget(
          newArrayDate.reduce((accumulator, object) => {
            return accumulator + object.household_target; //household_target
          }, 0)
        );
        setYellowcardTarget(
          newArrayDate.reduce((accumulator, object) => {
            return accumulator + object.yellow_card_target; // yellowcard_target
          }, 0)
        );
        setYellowDistributeActual(
          newArrayDate.reduce((accumulator, object) => {
            return accumulator + object.YelloCard_Distributed_Actual; // yellowcard_distributed_actual
          }, 0)
        );

        setpinkCardActual(
          newArrayDate.reduce((accumulator, object) => {
            return accumulator + object.PinkCard_Distributed_Actual; //pinkcard distributed actual
          }, 0)
        );
        setPinkCardTarget(
          newArrayDate.reduce((accumulator, object) => {
            return accumulator + object.total_pink_collected_camp; //pinkcard collected at camp
          }, 0)
        );

        setIndividualScreenTarget(
          newArrayDate.reduce((accumulator, object) => {
            return accumulator + object.individual_screening_target; //individual_sc_target
          }, 0)
        );
        setIndividualScreenActual(
          newArrayDate.reduce((accumulator, object) => {
            return accumulator + object.Individuals_Screening_Actual; //individual_sc_actual
          }, 0)
        );
      }
    } catch (error) {}
  }

  function handleDates(val) {
    const today = new Date(); // Get the current date
    setDateSearch(val);

    // Format the dates as "DD-MM-YYYY"
    var dateFormat = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    if (val == "last_week") {
      //==============last week ======================

      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7); // Subtract 7 days to get the date for last week

      // Find the start date (Monday) of last week
      const startOfWeek = new Date(lastWeek);
      startOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7));

      // Find the end date (Sunday) of last week
      const endOfWeek = new Date(lastWeek);
      endOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7) + 6);

      // Format the dates
      var formattedStartOfWeek = startOfWeek.toISOString().split("T")[0];
      var formattedEndOfWeek = endOfWeek.toISOString().split("T")[0];

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartOfWeek,
        eventEnd: formattedEndOfWeek,
      }));
    } else if (val == "last_month") {
      //================last month ===============================
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

      // Set the day to 1 to get the first day of the previous month
      lastMonth.setDate(2);

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: lastMonth.toISOString().split("T")[0],
        eventEnd: moment().date(0).toISOString().split("T")[0],
      }));
    } else if (val == "last_3_month") {
      //================last month ===============================

      const threeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        1
      );

      // Set the day to 1 to get the first day of three months ago
      threeMonthsAgo.setDate(1);

      const lastDayOfThreeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );

      const formattedStartDate = threeMonthsAgo.toLocaleDateString("en-IN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "Asia/Kolkata",
      });
      const formattedEndDate = lastDayOfThreeMonthsAgo.toLocaleDateString(
        "en-IN",
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone: "Asia/Kolkata",
        }
      );

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
        eventEnd: formattedEndDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
      }));
    } else if (val == "all") {
      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: "",
        eventEnd: "",
      }));
    }
  }

  async function loadHospital() {
    try {
      await axios.post("AllHospitalDropDown").then((res) => {
        let result = res.data.result;
        console.log("result", result);

        setHospital(
          result.map((data) => {
            return {
              _id: data._id,
              hospital_name: data.hospital_name,
            };
          })
        );
      });
    } catch (error) {
      console.log("hos", error);
    }
  }

  function handleHospitals(e) {
    var value = {
      hospital_id: e == "" ? "" : [e.target.value],
    };

    loadFieldWorkers(value);
  }

  async function loadFieldWorkers(data) {
    try {
      await axios.post("getGISFieldWorkerList", data).then((res) => {
        var field_w = res.data.result.result;

        var vhvvalue = field_w?.find((i) => i.field_worker_type === "VHV");
        var cbbfvalue = field_w?.find((i) => i.field_worker_type === "CBBF");

        vhvvalue = vhvvalue == undefined ? {} : vhvvalue;
        cbbfvalue = cbbfvalue == undefined ? {} : cbbfvalue;

        setFieldWorkers({ ...fieldWorkers, vhv: vhvvalue, cbbf: cbbfvalue });
      });
    } catch (error) {
      console.log("fw-error", error);
    }
  }

  function firstLetter(s) {
    return s?.replace(/^.{1}/g, s[0].toUpperCase());
  }

  function alterScreenName(name) {
    var menu_name = name.replaceAll("_", " ");
    var name = menu_name?.replace(/^.{1}/g, menu_name[0].toUpperCase());
    return name;
  }

  const handleChange = (e, val, position, headerName) => {
    const index = value?.findIndex((x) => x.value == val);

    // const ind = headerValue

    // setHeaderValue((x) => {
    //   {
    //     ...x,
    //     isChecked : false
    //   }

    // })

    if (e.target.checked) {
      if (index < 0) {
        if (headerName == "ALL") {
          let valueReference = [];
          head.forEach((data, index) => {
            data.isChecked = true;
            var values = {
              position: index,
              value: data?.field,
              header: data?.headerName,
            };
            valueReference.push(values);
            console.log("valueReference", valueReference);
            let sliceData = valueReference.slice(2);
            setValue(sliceData);
            // setValue(() => {
            //   var values = {
            //     position: index,
            //     value: data?.field,
            //     header: data?.headerName,
            //   };
            //   var data = { ...value, values };
            //   let returnData = Object.values(data);
            //   console.log("returnData", returnData);
            //   return returnData;
            // });
          });
          console.log("value", value);
        } else {
          head[position].isChecked = true;
          setValue(() => {
            var values = {
              position: position,
              value: val,
              header: headerName,
            };
            var data = { ...value, values };
            console.log("value------", value);
            return Object.values(data);
          });
        }
      } else {
        // value.splice(index, 1);
        if (headerName == "ALL") {
          head.forEach((data, index) => {
            data.isChecked = false;
          });
          setValue([]);
        } else {
          head[position].isChecked = false;
          value.splice(index, 1);
          setHeaderValue(head);
        }
      }
    } else {
      // value.splice(index, 1);
      if (headerName == "ALL") {
        head.forEach((data, index) => {
          data.isChecked = false;
        });
        setValue([]);
      } else {
        let headReference = [...head];
        headReference[position].isChecked = false;
        value.splice(index, 1);
        setHeaderValue(headReference);
      }
    }
  };

  const handleuploadExcel = async () => {
    setBackDropOpen(true);
    const res = await axios.post(
      `CampWiseVHVReport?fromDate=${searchValue.eventStart}&toDate=${
        searchValue.eventEnd
      }&hospital_name=${filterValue.hospitalName}&field_worker_name=${
        filterValue.name
      }&camp_number=${filterValue.camp_num}&camp_place=${
        filterValue.camp_place
      }&limit=&page=${1}`
    );

    const row = res?.data?.result?.list?.map((x) => {
      return {
        ...x,
        hospital_name: firstLetter(x.hospital_name),
        camp_date: format(new Date(x.camp_date), "yyyy-MM-dd"),
        fieldworker_type: x?.worker_type
          ?.map(({ role_name }) => role_name)
          .toString(),
        field_worker_name: x?.field_worker
          ?.map(({ field_worker_name }) => field_worker_name)
          .toString(),
      };
    });

    if (value.length > 1) {
      value.sort((x, y) => x.position - y.position);
    }

    var result = [];

    row?.map((x) => {
      var data = {};
      value?.map((i) => {
        data[i.header] = x[i.value];
      });
      result.push(data);
    });
    ExcelDownload(result, "FieldwokerReport");
    setDownloadTab((o) => !o);
    setDateSearch("");
    setValue([]);
    setBackDropOpen(false);
    // let newArr = data?.map((obj) => {
    //     const newObj = { ...obj };
    //     delete newObj["id"]
    //     delete newObj["camp_id"]
    //     delete newObj["field_worker_id"]
    //     return newObj;
    // })

    // let excelDownload = newArr.map(({
    //     hospital_name: HospitalName,
    //     field_worker_name: FieldworkerName,
    //     camp_number: CampNumber,
    //     camp_date: CampDate,
    //     daysCount: HouseHolddays,
    //     TravelDistance: TravelDistance,
    //     household_target: HouseHoldTarget,
    //     Household_Survey_Actual: HouseholdActual,
    //     individual_screening_target: InduvidualScreenTarget,
    //     Individuals_Screening_Actual: InduvidualScreenActual,
    //     yellow_card_target: YellowCardTarget,
    //     YelloCard_Distributed_Actual: YellowCardActual,
    //     total_males: MaleCount,
    //     total_females: FemaleCount,
    //     total_others: Others,
    //     total_children: ChildCount,
    //     total_op: TotalOp,
    //     PinkCard_Distributed_Actual: PinkCardActual,
    //     total_yellow_card_collected: YellowCardCollected,
    //     total_pink_collected_camp: PinkCardCollectedCamp,
    //     total_yellow_card_selected: YellowCardSelecetd,
    //     total_surgery_done: TotalSurgeryDone
    // }) => ({
    //     HospitalName, FieldworkerName, CampNumber, CampDate, HouseHolddays, TravelDistance, HouseHoldTarget, HouseholdActual, InduvidualScreenTarget, InduvidualScreenActual, YellowCardTarget, YellowCardActual, MaleCount, FemaleCount, Others, ChildCount, TotalOp, PinkCardActual, YellowCardCollected, PinkCardCollectedCamp, YellowCardSelecetd, TotalSurgeryDone
    // }));

    // ExcelDownload(excelDownload, "FieldwokerReport");
  };

  const filteredObjectArray = (val) => {
    setFilterValue({ ...val });
    setPaginationModel({
      ...paginationModel,
      page: 0,
    });
  };

  return (
    <div className="main-report">
      <div>
        <p
          style={{
            fontSize: "1.7rem",
            fontWeight: "500",
            display: "flex",
            marginTop: "-0.8%",
          }}
        >
          Fieldworkers Campwise Performance Report{" "}
        </p>
      </div>
      <div>
        <FieldworkerReportFilter
          filterObject={(val) => filteredObjectArray(val)}
          //  handleuploadExcel={() => handleuploadExcel()}
          handleuploadExcel={() => {
            head.forEach((data) => {
              data.isChecked = false;
            });
            setSearchValue({
              eventStart: "",
              eventEnd: "",
            });
            setDownloadTab((k) => !k);
          }}
          rows={rows}
          filterValue={() => setFilterValue({ ...search })}
          hospitaldrop={hospitals}
          fieldWorkers={fieldWorkers}
          handleHospital={(e) => handleHospitals(e)}
        />
      </div>
      <div style={{ marginTop: "-1vh" }}></div>

      {downloadTab && (
        <div className="tabbackground">
          <div className="downloadtab">
            <div className="table">
              <Table>
                <TableHead>
                  <tr>
                    {/* <th><Checkbox
                      onChange={(e) => {
                        handleSelectAll(e);
                      }}
                    />
                      Select all</th> */}
                    <th> </th>
                    <th>Columns </th>
                  </tr>
                </TableHead>
              </Table>
              <div className="filter">
                <FormControl style={{ width: "90%" }} size="small">
                  <Select
                    labelId="select-label"
                    id="select"
                    variant="standard"
                    label="Select Dates"
                    value={dateSearch}
                    onChange={(e) => {
                      handleDates(e.target.value);
                    }}
                  >
                    {dates?.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {dateSearch == "custom" && (
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "45%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="from_date"
                          value={
                            searchValue.eventStart == ""
                              ? null
                              : searchValue.eventStart
                          }
                          inputFormat="DD/MM/YYYY"
                          onChange={(e) => {
                            const selectedDate = e.$d;
                            if (selectedDate) {
                              setSearchValue({
                                ...searchValue,
                                eventStart: selectedDate,
                              });
                            } else {
                              setSearchValue({
                                ...searchValue,
                                eventStart: null,
                              });
                            }
                          }}
                          slotProps={{
                            actionBar: {
                              actions: ["clear"],
                            },
                          }}
                          renderInput={(props) =>
                            renderInputs(props, "From date", "date")
                          }
                        />
                      </LocalizationProvider>
                    </div>
                    <div style={{ width: "45%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="from_date"
                          value={
                            searchValue.eventEnd == ""
                              ? null
                              : searchValue.eventEnd
                          }
                          inputFormat="DD/MM/YYYY"
                          onChange={(e) => {
                            setSearchValue({ ...searchValue, eventEnd: e.$d });
                          }}
                          renderInput={(props) =>
                            renderInputs(props, "To date", "date")
                          }
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                )}
              </div>
              <Table>
                <TableBody>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  {headerValue?.map((head, index) => {
                    if (head.headerName !== "S no") {
                      return (
                        <TableRow key={index}>
                          <TableCell sx={{ textAlign: "center", width: "25%" }}>
                            <Checkbox
                              checked={head.isChecked}
                              onChange={(e) => {
                                handleChange(
                                  e,
                                  head.field,
                                  index,
                                  head.headerName
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {alterScreenName(head?.headerName)}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </div>
            <div className="downloadoptions">
              <Button
                variant="contained"
                onClick={() => {
                  setDownloadTab((k) => !k);
                  setDateSearch("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={dateSearch == "" || value.length == 0}
                onClick={() => handleuploadExcel()}
              >
                Download
              </Button>{" "}
              {/*onClick={handleuploadExcel}*/}
            </div>
          </div>
        </div>
      )}

      <div
        className="table"
        style={{ marginTop: "1%", textTransform: "capitalize" }}
      >
        <DataGrid
          style={{ height: "75vh", width: "95vw" }}
          sx={{
            boxShadow: 2,
            overflowX: "auto",
            border: 2,
            textTransform: "capitalize",
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .super-app-theme--header": {
              backgroundColor: "LightBlue",
            },
          }}
          rows={rows}
          columns={columns}
          rowCount={length}
          page={paginationModel.page}
          slots={{
            toolbar: CustomToolBar,
            footer: CustomFooter,
          }}
          disableDensitySelector
          // pagination={false}
          disableToolbarButton
          ColumnFilter={<FieldWorkerFilter />}
          components={{
            FilterPanel: <FieldWorkerFilter />,
          }}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          ref={handleExcel}
          initialState={{
            columns: {
              columnVisibilityModel: {
                campDate: false,
                reviewCamp: false,
                householdDays: false,
                campPlace: false,
                HSSTarget: false,
                HSSActual: false,
                ISTarget: false,
                ISActual: false,
                YCDTarget: false,
                YCDActual: false,
                total_males: false,
                total_females: false,
                Household_Survey_Actual: false,
                individual_screening_target: false,
                YelloCard_Distributed_Actual: false,
                Individuals_Screening_Actual: false,
                yellow_card_target: false,
                total_yellow_card_collected: false,
                total_others: false,
                total_children: false,
                total_op: false,
                PCDActual: false,
                PCCCamp: false,
                YCSelceted: false,
                PCSelected: false,
                YCSugery: false,
                PCSurgery: false,
              },
              pagination: { paginationModel: { paginationModel } },
            },
          }}
        />
      </div>

      {/* backdrop */}
      <BackDrop open={backDropOpen} />
    </div>
  );
};

export default FieldWorkersReports;
