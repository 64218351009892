import { TextField, Stack, Button } from "@mui/material";
import React, { useState } from "react";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Form, Formik, Field } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import { useAxios } from "../../hooks/useAxios.js";

const DashboardFilter = ({
  filterClose,
  filterObject,
  hospital,
  fieldworkerList,
  filterPerform,
}) => {
  const [campStartDate, setStartDate] = useState(null);
  const [campEndDate, setEndDate] = useState(null);
  // const [campStartDate, setStartDate] = useState(moment().startOf("month"));
  // const [campEndDate, setEndDate] = useState(new Date());
  const [fieldValue, setFieldValue] = useState("");
  const [fieldWorker, setFieldWorkerValue] = useState("");

  const { axios } = useAxios();
  const [reset, setReset] = useState(false);

  const [fieldreset, setFieldreset] = useState({
    vhv: 1,
    cbbf: 2,
  });

  // const state = {
  //   field_worker_id: "",
  // }
  //   const [field, setInitialValue] = useState(state);

  const fW = {
    vhv: null,
    cbbf: null,
  };

  const [fieldWorkers, setFieldWorkers] = useState(fW);

  const handleSubmit = () => {
    let obj = {
      fromDate: campStartDate
        ? moment(campStartDate.$d).format("YYYY-MM-DD")
        : "",
      toDate: campEndDate ? moment(campEndDate.$d).format("YYYY-MM-DD") : "",
      hospital_id: fieldValue ? fieldValue : "",
      field_worker_id: fieldWorker ? fieldWorker : "",
    };
    let perform = {
      fromDate: campStartDate
        ? moment(campStartDate.$d).format("YYYY-MM-DD")
        : "",
      toDate: campEndDate ? moment(campEndDate.$d).format("YYYY-MM-DD") : "",
      hospital_id: fieldValue ? fieldValue : "",
      field_worker_id: fieldWorker ? fieldWorker : "",
    };
    filterObject(obj);
    filterPerform(perform);
  };

  const handleClose = () => {
    let obj = {
      fromDate: "",
      toDate: "",
      hospital_id: "",
      field_worker_id: "",
    };
    let perform = {
      hospital_id: "",
      field_worker_id: "",
    };
    filterObject(obj);
    filterPerform(perform);
    setFieldWorkerValue("");
    setFieldValue("");
    setStartDate(null);
    setEndDate(null);
    // filterClose(false)
  };

  async function handleChangeHospital(e, data) {
    if (e.target.value != undefined) {
      // to handle no data found

      var value = data?.hospital_id;
      setFieldreset({
        ...fieldreset,
        vhv: fieldreset.vhv + 0.1,
        cbbf: fieldreset?.cbbf + 0.1,
      });

      if (value) {
        setFieldValue(e.target.value);

        var p_load;

        p_load = {
          hospital_id: [value],
        };

        handleFieldWorkerDropdown(p_load);
      }
    }
  }

  async function handleFieldWorkerDropdown(data) {
    await axios.post("getGISFieldWorkerList", data).then((res) => {
      var field_w = res.data.result.result;

      var vhvvalue = field_w?.find((i) => i.field_worker_type === "VHV");
      var cbbfvalue = field_w?.find((i) => i.field_worker_type === "CBBF");

      vhvvalue = vhvvalue == undefined ? {} : vhvvalue;
      cbbfvalue = cbbfvalue == undefined ? {} : cbbfvalue;

      setFieldWorkers({ ...fieldWorkers, vhv: vhvvalue, cbbf: cbbfvalue });
    });
  }

  return (
    <div style={{ width: "65vw", margin: "10px 0" }}>
      <Stack direction="row" spacing={2} sx={{ alignItems: "end" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="From Date"
            renderInput={(props) => <TextField {...props} size="small" />}
            value={campStartDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="To Date"
            renderInput={(props) => <TextField {...props} size="small" />}
            value={campEndDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
          />
        </LocalizationProvider>

        <FormControl style={{ width: "30%" }}>
          <InputLabel
            id="select-label"
            // style={{ marginLeft: "-3%", marginTop: "1%" }}
          >
            Select Hospital
          </InputLabel>
          <Select
            labelId="select-label"
            id="select"
            variant="standard"
            value={fieldValue}
            placeholder="Select Hospital"
            onChange={(event) => {
              setFieldValue(event.target.value);
            }}
          >
            {hospital.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {option.hospital_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* <Autocomplete
        size="small"
        disablePortal
        className="hide"
        variant='standard'
        id="combo-box-demo"
        sx={{
          input: {
            "&::placeholder": {    // <----- Add this.
              opacity: 1,
              // marginBottom: '-5px',
              color: '#000000'
            },
          },
          backgroundColor: '#ffffff',
          // borderRadius: '5px',
          width: '90%',
        }}

        ListboxProps={{
          sx: { fontSize: 13 },
        }}
        options={hospital?.length > 0 ? hospital : [{ "hospital_name": "No data found" }]}
        defaultValue={fieldValue == "" ? null : fieldValue}
        key={reset}
        getOptionLabel={(option) => option.hospital_name?.charAt(0)?.toUpperCase() + option.hospital_name?.slice(1)}
        onChange={(data, value) => { handleChangeHospital(data, value) }}
        renderInput={(params) => <TextField {...params} color="black" InputLabelProps={{
          style: {
            fontSize: '13px',  // Adjust the font size of the label here
            paddingTop: '5px',
          }
        }} label="Select Hospitals" />}
      /> */}

        {/* 
      <Autocomplete
        size="small"
        disablePortal
        className="date"
        variant='standard'
        id="combo-box-demo"
        sx={{
          input: {
            "&::placeholder": {    // <----- Add this.
              opacity: 1,
              marginBottom: '-5px',
              fontSize: '10px'
            },

          },
          backgroundColor: '#ffffff',
          margin: ' 0 5px',
          // borderRadius: '5px',
          width: '90%'
        }}
        key={fieldreset?.vhv}

        ListboxProps={{
          sx: { fontSize: 13 },
        }}
        options={fieldWorkers?.vhv?.filed_workerList?.length > 0 ? fieldWorkers?.vhv?.filed_workerList : [{ "field_worker_name": "No data found" }]}
        getOptionLabel={(option) => option.field_worker_name}
        defaultValue={fieldWorker == "" ? null : fieldWorker}
        onChange={(data, value) => { setFieldWorkerValue(value ) }}
        renderInput={(params) => <TextField {...params} InputLabelProps={{
          style: {
            fontSize: '13px',  // Adjust the font size of the label here
            paddingTop: '5px'
          }
        }} color="black" label="Select VHV FieldWorker" />}
      />

      <Autocomplete
        size="small"
        disablePortal
        className="date"
        variant='standard'
        id="combo-box-demo"
        sx={{
          input: {
            "&::placeholder": {
              opacity: 1,
              marginBottom: '-5px',
            },
          },
          backgroundColor: '#ffffff',
          margin: ' 0 5px',
          // borderRadius: '5px',
          width: '90%'
        }}
        key={fieldreset?.cbbf}
        ListboxProps={{
          sx: { fontSize: 13 },
        }}
        options={fieldWorkers?.cbbf?.filed_workerList?.length > 0 ? fieldWorkers?.cbbf?.filed_workerList : [{ "field_worker_name": "No data found" }]}
        getOptionLabel={(option) => option.field_worker_name}
        defaultValue={fieldWorker == "" ? null : fieldWorker}
        onChange={(data, value) => { setFieldWorkerValue(value ) }}
        renderInput={(params) => <TextField {...params} color="black" InputLabelProps={{
          style: {
            fontSize: '13px',  // Adjust the font size of the label here
            paddingTop: '5px'
          }
        }} label="Select CBBF FieldWorker" />}
      />
 */}

        <FormControl style={{ width: "30%" }}>
          <InputLabel
            id="select-label"
            // style={{ marginLeft: "-3%", marginTop: "1%" }}
          >
            Fieldworker Name
          </InputLabel>
          <Select
            labelId="select-label"
            id="select"
            variant="standard"
            value={fieldWorker}
            placeholder="Select Hospital"
            onChange={(event) => {
              setFieldWorkerValue(event.target.value);
            }}
          >
            {fieldworkerList.map((option) => (
              <MenuItem
                key={option.field_worker_id}
                value={option.field_worker_id}
              >
                {option.field_worker_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          style={{ width: "11%" }}
          onClick={handleSubmit}
        >
          Search
        </Button>
        <Button
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          style={{ width: "11%" }}
          onClick={handleClose}
        >
          Reset
        </Button>
      </Stack>
    </div>
  );
};
export default DashboardFilter;
