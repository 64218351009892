import { filter } from "lodash";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Box,
  Popover,
  Checkbox,
  TableRow,
  CardContent,
  Collapse,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Switch,
  Divider,
  Select,
  FormControl,
  InputLabel,
  Autocomplete,
} from "@mui/material";

import format from "date-fns/format";
import { styled } from "@mui/material/styles";
import { addHours } from "date-fns";

import { tableCellClasses } from "@mui/material/TableCell";
import {
  TableListHead,
  TableListToolbar,
  userList,
} from "../components/TableStructure/index";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CampFilter from "./Filter/CampFilter";

/*---------------- mui icons ----------------*/
import EditIcon from "@mui/icons-material/Edit";
//  import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import MobileOffIcon from "@mui/icons-material/MobileOff";
import { ArrowRight, Delete, KeyboardArrowDown } from "@mui/icons-material";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import SearchIcon from "@mui/icons-material/Search";

// import { TextField, Stack ,Button} from '@mui/material'
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PatientFilter from "./Filter/PatientFilter";
import moment from "moment/moment";
import EventFilter from "./Filter/EventFilter";
import ReferralFilter from "./Filter/referralFilter";
import { useAxios } from "../hooks/useAxios";

// mock
// import userList from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "company", label: "Company", alignRight: false },
  { id: "role", label: "Role", alignRight: false },
  { id: "isVerified", label: "Verified", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
const dateFormat = process.env.REACT_APP_DATE_FORMAT;
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    size="small"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#65C466" : "#ff7100",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 15,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#000000" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function TableLayout({
  header,
  handleFielder,
  handleAttendanceAccess,
  handleDate,
  date,
  searchValue,
  datewiseSearch,
  handleFieldDate,
  datalist,
  handleClearDate,
  activeInactive,
  editData,
  datalength,
  disableData,
  handleTableRowsPerPage,
  handleTablePage,
  dateFields,
  collapse,
  filterManage,
  filterResponseValue,
  filterResponse,
  searchDataValue,
  mobileIcon,
  deviceReset,
  filter,
  reset,
  referral,
  // hospitaldropdown
}) {
  const [open, setOpen] = useState(false);
  // const [reset, setReset] = useState(false);

  const [openCollapse, setOpenCollapse] = useState(0);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [table_data, setTable_data] = useState(datalist);
  const [searchData, setSearchData] = useState("");

  const [data, setData] = useState();
  const [searchText, setSearchText] = useState("");
  const [hospitalName, setHospital] = useState([]);

  const [fieldreset, setFieldreset] = useState({
    vhv: 1,
    cbbf: 2,
  });

  const val = {
    fieldworker: "",
    hospital: "",
    startDate: "",
    endDate: "",
    hospital_id: "",
    field_worker_id: "",
  };

  const [fieldattendance, setFieldAttendance] = useState(val);

  const { axios } = useAxios();

  //fieldworkers list on field worker map

  const fW = {
    vhv: null,
    cbbf: null,
  };

  const [fieldWorkers, setFieldWorkers] = useState(fW);

  useEffect(() => {
    let data = datalist?.map((data) => {
      let retunObj = {};
      header.forEach((header) => {
        if (header.value == "camp_from_date") {
          function addHours(date, hours) {
            const dateCopy = new Date(date);
            dateCopy.setHours(23);
            dateCopy.setMinutes(30);
            const utcDate = dateCopy.toISOString();
            return utcDate;
          }
          const date = new Date(data[header.value]);
          const newDate = addHours(date, 18);
          retunObj[header.value] = newDate;
        } else {
          retunObj[header.value] = data[header.value];
        }
      });
      retunObj["_id"] = data["_id"];
      // retunObj['status'] = data['status'] == 1 ? true : false;
      retunObj["status"] = data["status"];
      retunObj["created_on"] = data["created_on"];
      retunObj["action"] = "action";
      retunObj["isChecked"] = false;
      retunObj["device_id"] = data["device_id"];
      return retunObj;
    });
    setTable_data(data);
  }, [datalist]);

  function handleFieldDateSubmit() {
    console.log("filterValues", fieldattendance);
    searchDataValue(fieldattendance);

    setPage(0);
    // handleFieldDate();
  }
  function handleClearDateChild() {
    setPage(0);
    handleClearDate();
    setFieldAttendance({ ...val });
    searchDataValue({ ...val });
    // setReset(!reset)
    setFieldreset({
      ...fieldreset,
      vhv: fieldreset.vhv + 0.1,
      cbbf: fieldreset?.cbbf + 0.1,
    });
  }
  function covertDate(date) {
    dayjs.extend(utc);
    var newdate = dayjs.utc(date).local().format("YYYY-MM-DDTHH:mm:ss");
    return newdate;
  }

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table_data?.map((n) => ({
        ...n,
        isChecked: event.target.checked,
      }));
      setTable_data(newSelecteds);
      setSelected(newSelecteds);
      return;
    } else {
      const newSelecteds = table_data?.map((n) => ({
        ...n,
        isChecked: event.target.checked,
      }));
      setTable_data(newSelecteds);
      setSelected([]);
    }
  };

  const handleClick = (event, value, index_id) => {
    setTable_data((prev) => {
      var updated_data = prev?.map((i, index) =>
        index_id === index
          ? {
              ...i,
              isChecked: event.target.checked,
            }
          : i
      );
      return updated_data;
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleTablePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    handleTableRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    handleTablePage(page);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList?.length) : 0;

  const filteredUsers = applySortFilter(
    userList,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredUsers?.length && !!filterName;

  const handleEdit = (data) => {
    setOpen(false);
    editData(data);
  };

  // const handleDelete = () => {
  const handleDeactive = (status) => {
    setOpen(false);
    // deleteData(data)
    disableData(data, status);
  };

  const filteredObjectArray = (val) => {
    // if (field == "hospital") {
    //   setFieldAttendance({
    //     ...searchValue,
    //     hospital: values,
    //   });
    // } else {
    //   setFieldAttendance({
    //     ...searchValue,
    //     fieldworker: values,
    //   });
    // }

    setPage(0);

    filterResponseValue(val);
    searchDataValue(val);
  };

  /*-------------------------------- Global Search --------------------------*/

  const hanldleFilter = (dataValue) => {
    setSearchData(dataValue);
  };

  const deviceId = (val) => {
    deviceReset(val);
  };

  const filterData = table_data?.filter((data) => {
    if (searchData === "") {
      return data;
    } else if (
      data.village_name?.toLowerCase().includes(searchData.toLowerCase()) ||
      data.mandal_name?.toLowerCase().includes(searchData.toLowerCase()) ||
      data.taluk_name?.toLowerCase().includes(searchData.toLowerCase()) ||
      data.district_name?.toLowerCase().includes(searchData.toLowerCase()) ||
      data.hospital_id?.toLowerCase().includes(searchData.toLowerCase()) ||
      data.refferal_name?.toLowerCase().includes(searchData.toLowerCase()) ||
      data.state_name?.toLowerCase().includes(searchData.toLowerCase()) ||
      data.status
        ?.toString()
        .toLowerCase()
        .includes(searchData.toLowerCase()) ||
      data.hospital_name
        ?.toString()
        .toLowerCase()
        .includes(searchData.toLowerCase()) ||
      data.hospital_code
        ?.toString()
        .toLowerCase()
        .includes(searchData.toLowerCase()) ||
      data.employee_code
        ?.toString()
        .toLowerCase()
        .includes(searchData.toLowerCase()) ||
      data.email?.toString().toLowerCase().includes(searchData.toLowerCase()) ||
      data.camp_number
        ?.toString()
        .toLowerCase()
        .includes(searchData.toLowerCase()) ||
      data.role_name
        ?.toString()
        .toLowerCase()
        .includes(searchData.toLowerCase()) ||
      data.user_name?.toLowerCase().includes(searchData.toLowerCase()) ||
      data.vision_center
        ?.toString()
        .toLowerCase()
        .includes(searchData.toLowerCase()) ||
      data.device_id?.toLowerCase().includes(searchData.toLowerCase()) ||
      data.field_worker_name
        ?.toString()
        .toLowerCase()
        .includes(searchData.toLowerCase()) ||
      data.event_name?.toLowerCase().includes(searchData.toLowerCase()) ||
      data.camp_number?.toLowerCase().includes(searchData.toLowerCase())
    ) {
      return data;
    }
  });

  /*------------------------------------ Search  --------------------------*/

  const handleSearchText = (data) => {
    setSearchText(data);
  };

  const handleSubmit = () => {
    searchDataValue(searchText);
    setPage(0);
  };

  const handleClear = () => {
    setPage(0);
    searchDataValue("");
    setSearchText("");
    searchValue({ hospital: "", fieldworker: "" });

    // searchValue.fieldworker = "";
  };

  // console.log('hos', hospitaldropdown);

  useEffect(() => {
    loadHospital();
    loadFieldWorkers();
  }, [reset]);

  function handleHospital(e) {
    var hospital = hospitalName?.find((h) => h._id == e.target.value);
    setFieldAttendance({
      ...fieldattendance,
      hospital: hospital?.hospital_name,
      hospital_id: e.target.value,
    });

    var value = {
      hospital_id: [hospital?._id],
    };

    loadFieldWorkers(value);
  }

  async function loadHospital() {
    try {
      await axios.post("AllHospitalDropDown").then((res) => {
        let result = res.data.result;

        setHospital(
          result.map((data) => {
            return {
              _id: data._id,
              hospital_name: data.hospital_name,
            };
          })
        );
      });
    } catch (error) {
      console.log("hos", error);
    }
  }

  async function loadFieldWorkers(value) {
    try {
      await axios.post("getGISFieldWorkerList", value).then((res) => {
        var field_w = res.data.result.result;

        var vhvvalue = field_w?.find((i) => i.field_worker_type === "VHV");
        var cbbfvalue = field_w?.find((i) => i.field_worker_type === "CBBF");

        vhvvalue = vhvvalue == undefined ? {} : vhvvalue;
        cbbfvalue = cbbfvalue == undefined ? {} : cbbfvalue;

        setFieldWorkers({ ...fieldWorkers, vhv: vhvvalue, cbbf: cbbfvalue });
      });
    } catch (error) {
      console.log("fw-error", error);
    }
  }

  function firstLetter(s) {
    return s?.replace(/^.{1}/g, s[0].toUpperCase());
  }

  return (
    <>
      {filter === "camp" ? (
        <CampFilter
          filterObject={(val) => filteredObjectArray(val)}
          hospitalDrop={hospitalName}
          reset={reset}
          fieldWorkers={fieldWorkers}
        />
      ) : filter === "patient" ? (
        <PatientFilter
          filterObject={(val) => filteredObjectArray(val)}
          hospitalDrop={hospitalName}
          reset={reset}
          fieldWorkers={fieldWorkers}
        />
      ) : filter === "event" ? (
        <EventFilter
          filterObject={(val) => filteredObjectArray(val)}
          hospitalDrop={hospitalName}
          reset={reset}
          fieldWorkers={fieldWorkers}
        />
      ) : filter === "referral" ? (
        <ReferralFilter
          filterObject={(val) => filteredObjectArray(val)}
          hospitalDrop={hospitalName}
          reset={reset}
          fieldWorkers={fieldWorkers}
        />
      ) : (
        <div style={{ display: "flex", width: "100%", marginBottom: "1rem" }}>
          {datewiseSearch ? (
            <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  key={fieldattendance?.startDate}
                  value={fieldattendance?.startDate}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      size="small"
                      style={{ width: "15%" }}
                    />
                  )}
                  // value={campStartDate}
                  onChange={(newValue) => {
                    setFieldAttendance((x) => {
                      var date = `${newValue.$y}-${newValue.$M + 1}-${
                        newValue.$D
                      }`;
                      return {
                        ...x,
                        startDate: date,
                      };
                    });

                    // setFieldAttendance({ ...fieldattendance, startDate: newValue });
                    // // var date = `${value.$D}-${value.$M + 1 }-${value.$y}`
                    // var date = `${value.$y}-${value.$M + 1}-${value.$D}`;
                    // setFieldWorkerDate({
                    //   ...fieldWorkerDate,
                    //   startDate: date,
                    // });
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date"
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      size="small"
                      style={{ width: "15%" }}
                    />
                  )}
                  key={fieldattendance?.endDate}
                  // value={campEndDate}
                  value={fieldattendance?.endDate}
                  onChange={(newValue) => {
                    // handleDate(newValue, "end");

                    setFieldAttendance((x) => {
                      var date = `${newValue.$y}-${newValue.$M + 1}-${
                        newValue.$D
                      }`;
                      return {
                        ...x,
                        endDate: date,
                      };
                    });
                  }}
                  minDate={
                    fieldattendance?.startDate
                      ? new Date(moment(fieldattendance.startDate))
                      : ""
                  }
                />
              </LocalizationProvider>
              {/* <TextField variant='outlined' label='Camp place' onChange={(e) => setCampPlace(e.target.value)} /> */}
              {/* <TextField
                    variant="outlined"
                    label="Hospital Name"
                    size="small"
                    value={searchValue?.hospital}
                    onChange={(e) =>
                      searchDataValue(e.target.value, "hospital")
                    }
                  /> */}
              <FormControl style={{ width: "15%" }} size="small">
                <InputLabel id="select-label">Select Hospital</InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  variant="outlined"
                  label="Select Hospital"
                  // onChange={(event) => {
                  //   setFieldValue(field.name, event.target.value);
                  // }}
                  key={reset}
                  value={fieldattendance?.hospital_id}
                  onChange={(e) => {
                    handleHospital(e);
                  }}
                >
                  {hospitalName?.map((option) => (
                    <MenuItem
                      key={option._id}
                      value={option._id}
                      name={option.hospital_name}
                    >
                      {firstLetter(option.hospital_name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Autocomplete
                size="small"
                disablePortal
                className="date"
                variant="standard"
                id="combo-box-demo"
                sx={{
                  input: {
                    "&::placeholder": {
                      // <----- Add this.
                      opacity: 1,
                      marginBottom: "-5px",
                      fontSize: "10px",
                    },
                  },
                  backgroundColor: "#ffffff",
                  margin: " 0 5px",
                  // borderRadius: '5px',
                  // width: '90%'
                  width: "15%",
                }}
                key={fieldreset?.vhv}
                ListboxProps={{
                  sx: { fontSize: 13 },
                }}
                options={
                  fieldWorkers?.vhv?.filed_workerList?.length > 0
                    ? fieldWorkers?.vhv?.filed_workerList
                    : [{ field_worker_name: "No data found" }]
                }
                getOptionLabel={(option) =>
                  firstLetter(option.field_worker_name)
                }
                // defaultValue={searchValue?.fieldworker == "" ? null : searchValue?.fieldworker}
                onChange={(data, value) => {
                  setFieldAttendance({
                    ...fieldattendance,
                    fieldworker: value?.field_worker_name,
                  });
                  setFieldreset({
                    ...fieldreset,
                    cbbf: fieldreset.cbbf + 0.1,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      style: {
                        fontSize: "13px", // Adjust the font size of the label here
                        paddingTop: "5px",
                      },
                    }}
                    color="black"
                    label="Select VHV FieldWorker"
                  />
                )}
              />
              <Autocomplete
                size="small"
                disablePortal
                className="date"
                variant="standard"
                id="combo-box-demo"
                sx={{
                  input: {
                    "&::placeholder": {
                      opacity: 1,
                      marginBottom: "-5px",
                    },
                  },
                  backgroundColor: "#ffffff",
                  margin: " 0 5px",
                  // borderRadius: '5px',
                  // width: '90%'
                  width: "15%",
                }}
                key={fieldreset?.cbbf}
                ListboxProps={{
                  sx: { fontSize: 13 },
                }}
                options={
                  fieldWorkers?.cbbf?.filed_workerList?.length > 0
                    ? fieldWorkers?.cbbf?.filed_workerList
                    : [{ field_worker_name: "No data found" }]
                }
                getOptionLabel={(option) =>
                  firstLetter(option.field_worker_name)
                }
                // defaultValue={searchValue?.fieldworker == "" ? null : searchValue?.fieldWorkers}
                onChange={(data, value) => {
                  setFieldAttendance({
                    ...fieldattendance,
                    fieldworker: value?.field_worker_name,
                  });
                  setFieldreset({
                    ...fieldreset,
                    vhv: fieldreset.vhv + 0.1,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color="black"
                    InputLabelProps={{
                      style: {
                        fontSize: "13px", // Adjust the font size of the label here
                        paddingTop: "5px",
                      },
                    }}
                    /* label="Select CBBF FieldWorker" */
                    label="Select Volunteers FieldWorker"
                  />
                )}
              />
              {/* <TextField
                    variant="outlined"
                    label={referral ? "Referral type" : "FieldWorker"}
                    value={searchValue?.fieldworker}
                    size="small"
                    style={{ width: "15%" }}
                    onChange={(e) => searchDataValue(e.target.value, "field")}
                  /> */}
              <Button
                sx={{ textTransform: "capitalize" }}
                variant="contained"
                style={{ width: "11%" }}
                size="small"
                onClick={() => handleFieldDateSubmit()}
              >
                Search
              </Button>{" "}
              {/*onClick={handleSubmit} */}
              <Button
                sx={{ textTransform: "capitalize" }}
                variant="contained"
                style={{ width: "11%" }}
                size="small"
                onClick={() => handleClearDateChild()}
              >
                Reset
              </Button>
              {/* onClick={handleClose} */}
            </Stack>
          ) : (
            <>
              <TextField
                id="standard-basic"
                placeholder="Search text ..."
                variant="outlined"
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                size="small"
                style={{ width: "30%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <div style={{ display: "flex", marginLeft: "1vw" }}>
                <Button
                  sx={{ textTransform: "capitalize" }}
                  onClick={handleSubmit}
                  style={{
                    cursor: "pointer",
                    textTransform: "capitalize",
                    width: "50%",
                    marginLeft: "2%",
                  }}
                  variant="contained"
                  startIcon={<SearchIcon />}
                >
                  {" "}
                  Search{" "}
                </Button>
                <Button
                  sx={{ textTransform: "capitalize" }}
                  onClick={handleClear}
                  style={{
                    cursor: "pointer",
                    textTransform: "capitalize",
                    marginLeft: "1vw",
                  }}
                  variant="contained"
                  startIcon={<Delete />}
                >
                  {" "}
                  Reset{" "}
                </Button>
              </div>
            </>
          )}
        </div>
      )}
      <div>
        <TableContainer>
          <Table size="small">
            <TableListHead
              stickyHeader
              order={order}
              orderBy={orderBy}
              headLabel={header}
              rowCount={table_data?.length}
              numSelected={selected?.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={(e) => handleSelectAllClick(e)}
            />
            {console.log("table_data", table_data)}
            <TableBody size="small">
              {table_data !== undefined && table_data.length > 0 ? (
                <>
                  {filterData?.map((i, index) => {
                    return (
                      <>
                        <TableRow
                          key={index}
                          style={{ fontFamily: "Helvetica" }}
                          size="small"
                        >
                          <StyledTableCell
                            style={{ marginLeft: "9px", marginTop: "9px" }}
                            align="center"
                          >
                            {index + 1}
                          </StyledTableCell>
                          {header?.map((headers, index_id) => {
                            return (
                              <StyledTableCell
                                key={index_id}
                                // key={headers.id}
                                align={"center"}
                                style={{
                                  textTransform:
                                    headers.value === "email"
                                      ? "lowercase"
                                      : "capitalize",
                                  display:
                                    collapse && header?.length === index_id + 1
                                      ? "flex"
                                      : "table-cell",
                                  justifyContent:
                                    collapse && header?.length === index_id + 1
                                      ? "space-between"
                                      : "center",
                                }}
                              >
                                {headers?.value == "attendance_access" && (
                                  <Tooltip
                                    size="small"
                                    title={
                                      i["status"] == 1
                                        ? "Deny Attendance Access"
                                        : "Allow Attendance Access"
                                    }
                                  >
                                    <IconButton>
                                      <IOSSwitch
                                        sx={{ m: 1 }}
                                        checked={i["status"] == 1}
                                        onChange={() =>
                                          handleAttendanceAccess({
                                            _id: i._id,
                                            attendance_access: i["status"],
                                            attendance_access_lable:
                                              i["status"] == 1
                                                ? "Deny"
                                                : "Allow",
                                          })
                                        }
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {headers.value == "action"
                                  ? ""
                                  : dateFields
                                  ? dateFields.includes(headers.value)
                                    ? typeof i[headers.value] !== "undefined"
                                      ? i[headers.value]?.slice(0, 10)
                                      : ""
                                    : i[headers.value]
                                  : headers?.value === "camp_to_date"
                                  ? i[headers.value]?.slice(0, 10)
                                  : i[headers.value]}
                                {headers.value == "action" ? (
                                  <div>
                                    <IconButton
                                      size="large"
                                      color="inherit"
                                      onClick={(e) => {
                                        handleEdit(i._id);
                                      }}
                                    >
                                      <EditIcon sx={{ mr: 2 }} />
                                    </IconButton>
                                    {mobileIcon === true && (
                                      <Tooltip title="Reset Device Id">
                                        <IconButton>
                                          <MobileOffIcon
                                            sx={{ mr: 2 }}
                                            onClick={(e) => deviceId(i._id)}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                    {activeInactive === true && (
                                      <Tooltip
                                        title={
                                          i["status"] == 1
                                            ? "Inactive User"
                                            : "Active User"
                                        }
                                      >
                                        <IconButton>
                                          <IOSSwitch
                                            sx={{ m: 1 }}
                                            checked={i["status"] == 1}
                                            onChange={() =>
                                              handleFielder({
                                                _id: i._id,
                                                status: i["status"],
                                                activeinactive:
                                                  i["status"] == 1
                                                    ? "Deactivate"
                                                    : "Activate",
                                              })
                                            }
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {collapse && header?.length === index_id + 1 ? (
                                  <KeyboardArrowDown
                                    style={{
                                      marginLeft: "9px",
                                      marginTop: "9px",
                                    }}
                                    sx={{
                                      mr: 1,
                                      opacity: 1,
                                      transform:
                                        i._id == openCollapse
                                          ? "rotate(-180deg)"
                                          : "rotate(0)",
                                      transition: "0.5s",
                                    }}
                                    onClick={() =>
                                      openCollapse == i._id
                                        ? setOpenCollapse(0)
                                        : setOpenCollapse(i._id)
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                              </StyledTableCell>
                            );
                          })}
                        </TableRow>
                        <TableRow style={{ textAlign: "center" }}>
                          {i._id == openCollapse ? (
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={header?.length + 1}
                            >
                              <Collapse
                                in={i._id == openCollapse}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box
                                  sx={{
                                    margin: 1,
                                    minHeight: "100px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                  >
                                    Hospital Id : {i.event_id}
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                  >
                                    Hospital Name : {i.hospital_name}
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                  >
                                    Event Name : {i.event_name}
                                  </Typography>
                                </Box>
                              </Collapse>
                            </TableCell>
                          ) : (
                            ""
                          )}
                        </TableRow>
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  <TableRow>
                    <TableCell align="center" colSpan={header?.length + 1}>
                      No Data found
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>

            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Paper
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="h6" paragraph>
                        Not found
                      </Typography>

                      <Typography variant="body2">
                        No results found for &nbsp;
                        <strong>&quot;{filterName}&quot;</strong>.
                        <br /> Try checking for typos or using complete words.
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={datalength}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>

      <Popover
        open={open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={(data) => handleEdit(data)}>
          <EditIcon sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        {/* <MenuItem sx={{ color: 'error.main' }} onClick={(data) => handleDeactive(2)} > 
          <DeleteForeverIcon sx={{ mr: 2 }} />
           Deactive
        </MenuItem>
        <MenuItem sx={{ color: 'error.main' }} onClick={(data) => handleDeactive(1)} > 
          <DeleteForeverIcon sx={{ mr: 2 }} />
           Active
        <MenuItem sx={{ color: 'error.main' }} onClick={(data) => handleDelete(data)} >
          <NotInterestedIcon sx={{ mr: 2 }} />
          Disable
        </MenuItem> */}
      </Popover>
    </>
  );
}
