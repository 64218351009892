/* Excel upload value convert into json format */

import { read, utils } from "xlsx";

const ExcelUplaod = async ($event) => {
  return new Promise(async (resolve, reject) => {
    try {
      const files = $event.target.files;
      const emptyReturn = { status: true, data: [] };
      if (files && files.length) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(files[0]);
        reader.onload = async (event) => {
          const excel = read(event.target.result);
          if (excel) {
            const sheets = excel.SheetNames;
            if (sheets.length) {
              const rows = utils.sheet_to_json(excel.Sheets[sheets[0]]);
              const lowercaseData = rows.map((row) => {
                const lowercasedRow = {};
                Object.entries(row).forEach(([key, value]) => {
                  lowercasedRow[key] =
                    typeof value === "string" ? value.toLowerCase() : value;
                });
                return lowercasedRow;
              });
              // console.log(lowercaseData,"lowercaseData");
              reader.abort();
              resolve({ status: true, data: lowercaseData });
            } else {
              reader.abort();
              resolve(emptyReturn);
            }
          } else {
            reader.abort();
            resolve(emptyReturn);
          }
        };
      } else {
        resolve(emptyReturn);
      }
    } catch (error) {
      reject({ status: false, message: "Error:" + error, data: [] });
    }
  });
};
export default ExcelUplaod;
