import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Divider,
} from "@mui/material";
import QRCode from "react-qr-code";

function QrComponent({
  qrProps: {
    open = false,
    dialogTitle = null,
    qrTitle = null,
    qrValue = null,
    patientName = null,
    patientId = null,
  } = {},
  onClosePrevent,
  onCloseIcon,
}) {
  const styles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <Dialog open={open} fullWidth onClose={onClosePrevent}>
      <div style={styles}>
        <DialogTitle>{dialogTitle}</DialogTitle>
      </div>
      <Divider />
      <DialogContent sx={styles}>
        {qrValue ? (
          <QRCode title={qrTitle} value={qrValue} />
        ) : (
          <p>No QR code value provided</p>
        )}
      </DialogContent>
      <div style={styles}>
        <DialogTitle sx={{ fontWeight: "bold" }}>{qrValue ?? "NA"}</DialogTitle>
      </div>
      <div style={styles}>
        <span>
          {patientId ?? "NA"} - {patientName ?? "NA"}
        </span>
      </div>
      <div style={styles}>
        <Button
          color="primary"
          type="button"
          variant="contained"
          onClick={onCloseIcon}
          sx={{ mt: 2, mb: 2 }}
        >
          Close
        </Button>
      </div>
    </Dialog>
  );
}

export default QrComponent;
