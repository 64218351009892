import * as yup from "yup";

export const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required!"),
  password: yup.string().required("Password is required!"),
});
export const ReferralSchema = yup.object().shape({
  hospital_id: yup.string().required("Hospital name is required"),
  referral_name: yup.string().required("Referral Type is required"),
  // status: yup.string().required("status is required"),
});
export const StateSchema = yup.object().shape({
  state_name: yup.string().required("State name is required"),
  status: yup.string().required("status is required"),
});
export const RoleSchema = yup.object().shape({
  // role_name: yup.string().required("Role name is required"),
  // _id: yup.object().required("Role Name is required"),
  status: yup.string().required("status is required"),
});
export const DistrictSchema = yup.object().shape({
  state_id: yup.string().required("State name is required"),
  district_name: yup.string().required("District name is required"),
  status: yup.string().required("status is required"),
});

export const MandalSchema = yup.object().shape({
  // taluk_id: yup.object().required("District name is required"),
  mandal_name: yup.string().required("GramPanchayat  name is required"),
  status: yup.string().required("status is required"),
});

export const VillageSchema = yup.object().shape({
  // mandal_id: yup.object().required("Council name is required"),
  village_name: yup.string().required("village name is required"),
  status: yup.string().required("status is required"),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
});
export const newPasswordSchema = yup.object().shape({
  otp: yup
    .string()
    .min(4, "Invalid OTP")
    .max(4, "Invalid OTP")
    .required("OTP is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Minimum 8 characters requires")
    .max(16, "Password cannot exceed 16 characters"),
  confirm_password: yup
    .string()
    .required("Password is required")
    .oneOf([yup.ref("password"), null], "Password doesn't match"),
});

export const ChangePassword = yup.object().shape({
  old_password: yup.string().required("Password is required"),
  new_password: yup
    .string()
    .required("Password confirmation required")
    .min(8, "Minimum 8 characters requires")
    .max(16, "Password cannot exceed 16 characters"),
});
export const ResetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Minimum 8 characters requires")
    .max(16, "Password cannot exceed 16 characters"),
  confirm_password: yup
    .string()
    .required("Password confirmation required")
    .oneOf([yup.ref("password"), null], "Password doesn't match"),
});

export const UserSchema = yup.object().shape({
  username: yup.string().required("Username is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Minimum 8 characters requires")
    .max(16, "Password cannot exceed 16 characters"),
});
export const UpdateUserSchema = yup.object().shape({
  username: yup.string().required("Username is required"),
  email: yup
    .string()
    .email("Invalid email address")

    .required("Email is required"),
});

// old camp schema
/* export const CampSchema = yup.object().shape({
  // camp_number: yup.string().required("Camp Number is required"),
  // camp_place: yup.string().required("Camp place is required"),
  camp_pin_code: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits")
    .required("Pin code required"),
  camp_from_date: yup
    .date()
    .typeError("Invalid date format")
    .required("From date is required"),
  camp_to_date: yup
    .date()
    .typeError("Invalid date format")
    .required("To date is required"),
  // state: yup.object().required("Sate name required"),
  // district: yup.object().required("District name required"),
  // mandal: yup.object().required("Mandal name required"),
  // village: yup.object().required("Village name required"),
  // hospital: yup.object().required("Hospital name required"),
  // field_worker: yup.object().required("Field worker cannot be empty"),
  // co_sponsor: yup.string().required("Co sponsor cannot be empty"),
  household_target: yup.number().typeError("Only integers allowed"),
  // .required("House hold target required"),
  current_longitude: yup.string().required("Camp Longitude cannot be empty"),
  current_latitude: yup.string().required("Camp Latitude cannot be empty"),
  yellow_card_target: yup.number().typeError("Only integers allowed"),
  individual_screening_target: yup.number().typeError("Only integers allowed"),
  project_name: yup.string().required("Please Select Project"),
  // mandal_id: yup
  //   .array()
  //   .min(1, "Please Select Panchayat")
  //   .required("Please Select Panchayat"),
  field_worker_type: yup
    .array()
    .min(1, "Please Select FieldWorker Type")
    .required("Please Select FieldWorker Type"),
  organizations: yup
    .array()
    .min(1, "Please Select Organization")
    .required("Please Select Organization"),
  field_workers: yup
    .array()
    .min(1, "Please Select FieldWorkers")
    .required("Please Select FieldWorkers"),
}); */

// updated camp schema
export const CampSchema = (isValidate, villMan) => {
  const CampSchemaValidation = yup.object().shape({
    // camp_number: yup.string().required("Camp Number is required"),
    // camp_place: yup.string().required("Camp place is required"),
    camp_pin_code: yup
      .string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, "Must be exactly 6 digits")
      .max(6, "Must be exactly 6 digits")
      .required("Pin code required"),
    camp_from_date: yup
      .date()
      .typeError("Invalid date format")
      .required("From date is required"),
    camp_to_date: yup
      .date()
      .typeError("Invalid date format")
      .required("To date is required"),
    // state: yup.object().required("Sate name required"),
    // district: yup.object().required("District name required"),
    // mandal: yup.object().required("Mandal name required"),
    // village: yup.object().required("Village name required"),
    // hospital: yup.object().required("Hospital name required"),
    // field_worker: yup.object().required("Field worker cannot be empty"),
    // co_sponsor: yup.string().required("Co sponsor cannot be empty"),
    household_target: yup.number().typeError("Only integers allowed"),
    // .required("House hold target required"),
    current_longitude: yup.string().required("Camp Longitude cannot be empty"),
    current_latitude: yup.string().required("Camp Latitude cannot be empty"),
    yellow_card_target: yup.number().typeError("Only integers allowed"),
    individual_screening_target: yup
      .number()
      .typeError("Only integers allowed"),
    project_name: yup.string().required("Please Select Project"),
    // mandal_id: yup
    //   .array()
    //   .min(1, "Please Select Panchayat")
    //   .required("Please Select Panchayat"),
    field_worker_type: yup
      .array()
      .min(1, "Please Select FieldWorker Type")
      .required("Please Select FieldWorker Type"),
    field_workers: yup
      .array()
      .min(1, "Please Select FieldWorkers")
      .required("Please Select FieldWorkers"),
  });

  if (!isValidate && !villMan) {
    return CampSchemaValidation;
  } else {
    if (villMan) {
      return CampSchemaValidation.shape({
        village: yup
          .array()
          .min(1, "Please Select Village")
          .required("Please Select Village"),
        organizations: yup
          .array()
          .min(1, "Please Select Organization")
          .required("Please Select Organization"),
      });
    } else {
      return CampSchemaValidation.shape({
        organizations: yup
          .array()
          .min(1, "Please Select Organization")
          .required("Please Select Organization"),
      });
    }
  }
  /* return !isValidate
      ? CampSchemaValidation
      : CampSchemaValidation.shape({
          organizations: yup
            .array()
            .min(1, "Please Select Organization")
            .required("Please Select Organization"),
        }); */
};

export const EventSchema = yup.object().shape({
  event_date: yup
    .date()
    .typeError("Invalid date format")
    .required("Event Date is required"),
  event_name: yup.string().required("Event Title cannot be empty"),
  hospital_id: yup.string().required("Hospital name is required"),
  description: yup.string().required("Description is required"),
});
export const HospitalSchema = yup.object().shape({
  hospital_name: yup.string().required("Hospital name cannot be empty"),
  hospital_code: yup.string().required("Hospital Code Cannot Be Empty "),
  phone: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .required("Phone number name cannot be empty")
    .min(10, "Invalid number")
    .max(10, "Invalid number"),
  // password: yup
  //   .string()
  //   .required("Password is required")
  //   .matches(
  //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //     "Password must contain minimum length 8. combination of character, number, special character"
  //   ),
  email: yup
    .string()
    .email("Invalid email")
    .required("Hospital name is required"),
  latitude: yup
    .string()
    .matches(/^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/, "Invalid format")
    .required("latitude required"),
  longitude: yup
    .string()
    .matches(/^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/, "Invalid format")
    .required("longitude required"),
  survey_radius: yup.string().required("Survey Radius is required"),
  // state_id: yup.object().required("State is required"),
  // district_id: yup.object().required("District is required"),
  hospital_address: yup.string().required("Address is required"),
  // status: yup.string().required("Status is required"),
  pin_code: yup
    .string()
    .required("Pincode is required")
    .matches(/^[0-9]+$/, "Invalid format"),
});

/*----------------- Mobile User Management ---------------------- */

// updated schema 09_apr_2024
export const MobileUserSchemaValidation = (isValidate, isValidateEmpCode) => {
  const MobileUserSchema = yup.object().shape({
    user_name: yup.string().required("Name cannot be empty"),
    phone: yup
      .string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .required("Phone number name cannot be empty")
      .min(10, "Invalid number")
      .max(10, "Invalid number"),
    // password: yup
    //   .string()
    //   .required("Password is required")
    //   .matches(
    //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //     "Password must contain maximum length 8. combination of character, number, special character"
    //   ),
    email: yup
      .string()
      .email("Invalid email")
      .required("Hospital name is required"),
    // state_id: yup.object().required("State is required"),
    // district_id: yup.object().required("District is required"),
    // taluk_id: yup.object().required("Taluk is required"),
    // hospital_id: yup.object().required("Hospital is required"),
    // mandal_id: yup.object().required("mandal is required"),
    // village_id: yup.object().required("Village is required"),
    designation: yup.string().required("designation is required"),
    hospital_address: yup.string().required("hospital address is required "),
    pin_code: yup
      .string()
      .required("pin code is required")
      .min(6, "Invalid number")
      .max(6, "Invalid number"),
    gender: yup.string().required("gender is required"),
    role_id: yup.number().required("required"),
  });

  console.log("isValidate", isValidate);
  console.log("isValidateEmpCode", isValidateEmpCode);

  if (!isValidate) {
    return MobileUserSchema;
  } else {
    if (isValidateEmpCode) {
      return MobileUserSchema.shape({
        emp_code: yup
          .string()
          .required("Employee Code is required")
          .test(
            "minDigits",
            "Employee Code must be at least six numeric characters",
            (value) => (value ? value.toString().length >= 6 : false)
          ),
        organization: yup
          .array()
          .min(1, "Please Select Organisation")
          .required("Please Select Organisation"),
      });
    } else {
      return MobileUserSchema.shape({
        organization: yup
          .array()
          .min(1, "Please Select Organisation")
          .required("Please Select Organisation"),
      });
    }
  }
};

// old schema 09_apr_2024
/* export const MobileUserSchema = yup.object().shape({
  user_name: yup.string().required("Name cannot be empty"),
  emp_code: yup.number().required("Employe Code is required"),
  phone: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .required("Phone number name cannot be empty")
    .min(10, "Invalid number")
    .max(10, "Invalid number"),
  // password: yup
  //   .string()
  //   .required("Password is required")
  //   .matches(
  //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //     "Password must contain maximum length 8. combination of character, number, special character"
  //   ),
  email: yup
    .string()
    .email("Invalid email")
    .required("Hospital name is required"),
  // state_id: yup.object().required("State is required"),
  // district_id: yup.object().required("District is required"),
  // taluk_id: yup.object().required("Taluk is required"),
  // hospital_id: yup.object().required("Hospital is required"),
  // mandal_id: yup.object().required("mandal is required"),
  // village_id: yup.object().required("Village is required"),
  designation: yup.string().required("designation is required"),
  hospital_address: yup.string().required("hospital address is required "),
  pin_code: yup
    .string()
    .required("pin code is required")
    .min(6, "Invalid number")
    .max(6, "Invalid number"),
  gender: yup.string().required("gender is required"),
  role_id: yup.number().required("required"),
}); */

// updated edit schema 09_apr_2024
export const MobileUserSchemaEditValidation = (
  isValidate,
  isValidateEmpCode
) => {
  const MobileUserSchemaEdit = yup.object().shape({
    user_name: yup.string().required("Name cannot be empty"),
    phone: yup
      .string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .required("Phone number name cannot be empty")
      .min(10, "Invalid number")
      .max(10, "Invalid number"),
    newpassword: yup
      .string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain maximum length 8. combination of character, number, special character"
      ),
    email: yup
      .string()
      .email("Invalid email")
      .required("Hospital name is required"),
    // state_id: yup.object().required("State is required"),
    // district_id: yup.object().required("District is required"),
    // taluk_id: yup.object().required("Taluk is required"),
    // hospital_id: yup.object().required("Hospital is required"),
    // mandal_id: yup.object().required("mandal is required"),
    // village_id: yup.object().required("Village is required"),
    designation: yup.string().required("designation is required"),
    hospital_address: yup.string().required("hospital address is required "),
    pin_code: yup
      .string()
      .required("pin code is required")
      .min(6, "Invalid number")
      .max(6, "Invalid number"),
    gender: yup.string().required("gender is required"),
    role_id: yup.number().required("required"),
  });

  if (!isValidate) {
    return MobileUserSchemaEdit;
  } else {
    if (isValidateEmpCode) {
      return MobileUserSchemaEdit.shape({
        emp_code: yup
          .string()
          .required("Employee Code is required")
          .test(
            "minDigits",
            "Employee Code must be at least six numeric characters",
            (value) => (value ? value.toString().length >= 6 : false)
          ),
        organization: yup
          .array()
          .min(1, "Please Select Organisation")
          .required("Please Select Organisation"),
      });
    } else {
      return MobileUserSchemaEdit.shape({
        organization: yup
          .array()
          .min(1, "Please Select Organisation")
          .required("Please Select Organisation"),
      });
    }
  }
};

// old edit schema 09_apr_2024
/* export const MobileUserSchemaEdit = yup.object().shape({
  user_name: yup.string().required("Name cannot be empty"),
  emp_code: yup.number().required("Employe Code is required"),
  phone: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .required("Phone number name cannot be empty")
    .min(10, "Invalid number")
    .max(10, "Invalid number"),
  newpassword: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Password must contain maximum length 8. combination of character, number, special character"
    ),
  email: yup
    .string()
    .email("Invalid email")
    .required("Hospital name is required"),
  // state_id: yup.object().required("State is required"),
  // district_id: yup.object().required("District is required"),
  // taluk_id: yup.object().required("Taluk is required"),
  // hospital_id: yup.object().required("Hospital is required"),
  // mandal_id: yup.object().required("mandal is required"),
  // village_id: yup.object().required("Village is required"),
  designation: yup.string().required("designation is required"),
  hospital_address: yup.string().required("hospital address is required "),
  pin_code: yup
    .string()
    .required("pin code is required")
    .min(6, "Invalid number")
    .max(6, "Invalid number"),
  gender: yup.string().required("gender is required"),
  role_id: yup.number().required("required"),
}); */

/*---- Taluk Schema ------ */

export const TalukSchema = yup.object().shape({
  // district_id: yup.object().required("District Name is required"),
  taluk_name: yup
    .string()
    .required("SubDivison/Taluk/Mandal/Circle/Block  is required"),
  status: yup.string().required("status is required"),
});

/* Project Creation Schema */
export const projectValidationSchema = () => {
  const projectFormSchema = yup.object().shape({
    project_name: yup
      .string()
      .min(3, "Project Name must be more than three characters")
      // .matches(/^[A-Za-z]+$/, "Please Enter a Valid Name")
      .matches(
        /^(?!.*\s{2,})(?!^\s+$)(?!^\s)(?!.*\s$).*$/i,
        "Please Enter a Valid Project Name"
      )
      .required("Please Enter Project Name"),
    units: yup
      .array()
      .min(1, "Please Select Hospital")
      .required("Please Select Hospital"),
    districts: yup
      .array()
      .min(1, "Please Select District")
      .required("Please Select District"),
    targetCount: yup
      .string()
      .matches(/^\d+$/, "Target Count should be numeric characters")
      .required("Please Enter Target Count"),
  });
  return projectFormSchema;
};

export const organisationValidationSchema = () => {
  const organisationFormSchema = yup.object().shape({
    organisation_name: yup
      .string()
      .min(3, "Organisation Name must be more than three characters")
      .matches(
        /^(?!.*\s{2,})(?!^\s+$)(?!^\s)(?!.*\s$).*$/i,
        "Please Enter a Valid Organisation Name"
      )
      .required("Please Enter Organisation Name"),
    contact_person: yup
      .string()
      .min(3, "Contact Person Name must be more than three characters")
      .matches(/^[A-Za-z]+$/, "Please Enter a Valid Contact Person Name")
      .required("Please Enter Contact Person Name"),
    contact_person_mob: yup
      .string()
      // .min(10, "Contact Person Number must be min ten characters")
      // .max(10, "Contact Person Number must be max ten characters")
      .matches(/^\d+$/, "Contact Person Number should be numeric characters")
      .matches(/^\d{10}$/, "Please Enter a Valid Contact Person Number")
      .required("Please Enter Contact Person Number"),
    address: yup.string().required("Please Enter Address"),
    state: yup.string().required("Please Select State"),
    district: yup.string().required("Please Select District"),
    taluk: yup.string().required("Please Select Taluk"),
    panchayat: yup.string().required("Please Select Panchayat"),
  });

  return organisationFormSchema;
};
