import * as XLSX from "xlsx";

const ExcelFileDownload = (columns, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(columns);
  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // // Generate a download link for the Excel file
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.xlsx`);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export default ExcelFileDownload;
