import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import TableComponent from "../components/TableLayout";
import { useAxios } from "../hooks/useAxios";
import moment from "moment";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelUplaod from "../components/ExcelFormat/Excelfileupload";
import ExcelDownload from "../components/ExcelFormat/ExcelFileDownload";
import { toast, Slide } from "react-toastify";
import "./Style.css";
import { ReferralSchema } from "../validations/userSchema";
import { Form, Formik, Field } from "formik";
import { HybridInput, HybridSelect } from "../components/hybrid_inputs";
import ConfirmPopup from "../components/confirmPopup";
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import LowerCase from "./LowerCase";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, CardContent } from "@mui/material";
const hospital = [
  { label: "GOV", value: "Gov" },
  { label: "Base Hospital", value: "Base Hospital" },
  { label: "Sangaram Package", value: "Sangaram Package" },
  { label: "CM Scheme ", value: "CM Package" },
  { label: "Other Private Hospital", value: "other Private Hospital" },
];

const ReferralTypeManagement = () => {
  const { axios } = useAxios();
  const [rows, setRows] = useState([]);

  const [length, setLength] = useState();
  const [open, setOpen] = useState(false);
  const [editState, setEditState] = useState(false);
  const [hospitalName, setHospital] = useState([]);
  const [changeValue, setChangeValue] = useState();
  const [activeId, setActiveId] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  // const [searchValue, setSearchValue] = React.useState('');
  let paginationSetup = {
    page: 1,
    count: 0,
    rowsPerPage: 10,
  };
  const [pageInfo, setPageInfo] = useState(paginationSetup);
  const [formValue, setFormValue] = useState({
    hospital_id: "",
    referral_name: "",
  });
  const [filterValue, setFilterValue] = useState({
    referral_name: "",
    hospital_name: "",
    fromDate: "",
    toDate: "",
  });

  const [header] = useState([
    {
      id: 1,
      label: "Hospital Name ",
      value: "hospital_name",
    },
    {
      id: 2,
      label: "Referral Type",
      value: "referral_name",
    },
    {
      id: 3,
      label: "Action",
      value: "action",
    },
  ]);
  // const [searchValue, setSearchValue] = React.useState("");
  const [DropDown] = useState([
    {
      _id: 1,
      status: "Active",
    },
    {
      _id: 0,
      status: "Inactive",
    },
  ]);
  const [fieldWorkerDate, setFieldWorkerDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [searchValue, setSearchValue] = React.useState({
    fieldworker: "",
    hospital: "",
  });
  const [reset, setReset] = React.useState(false);

  useEffect(
    (values) => {
      fetchData();
    },
    [pageInfo.rowsPerPage, pageInfo.page, reset]
    // [pageInfo.rowsPerPage, pageInfo.page, searchValue]
  );

  const filterResponse = () => {
    setPageInfo((prevState) => ({ ...prevState, page: 1 }));
    if (pageInfo.page === 1) {
      fetchData();
    }
  };

  const handleDates = (value, field) => {
    if (field == "start") {
      // var date = `${value.$D}-${value.$M + 1 }-${value.$y}`
      var date = `${value.$y}-${value.$M + 1}-${value.$D}`;
      setFieldWorkerDate({
        ...fieldWorkerDate,
        startDate: date,
      });
    } else {
      // var date = `${value.$D}-${value.$M + 1 }-${value.$y}`
      var date = `${value.$y}-${value.$M + 1}-${value.$D}`;
      setFieldWorkerDate({
        ...fieldWorkerDate,
        endDate: date,
      });
    }
  };

  function clearValues() {
    setPageInfo(paginationSetup);
    setFieldWorkerDate({
      ...fieldWorkerDate,
      startDate: "",
      endDate: "",
    });
    setSearchValue({
      ...searchValue,
      hospital: "",
      fieldworker: "",
    });

    setReset(!reset);
  }
  const handleAdd = () => {
    setOpen(true);
    setChangeValue();
    setEditState(false);
    setFormValue({ hospital_id: "", referral_name: "" });
  };
  const handleClose = () => setOpen(false);

  // prevent backDrop of dialog
  const handleClosePrevent = (reason) => {
    if (reason == "escapeKeyDown" || reason == "backdropClick") {
      setOpen(true);
    }
  };

  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };
  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };

  /* fetch data from api */

  useEffect(() => {
    loadHospital();
  }, []);

  async function loadHospital() {
    try {
      await axios.post("AllHospitalDropDown").then((res) => {
        let result = LowerCase(res.data.result, "hospital_name");

        setHospital(
          result.map((data) => {
            return {
              _id: data._id,
              hospital_name: data.hospital_name,
            };
          })
        );
      });
    } catch (error) {
      console.log("hos", error);
    }
  }

  function fetchData() {
    let fromDate = fieldWorkerDate?.startDate
      ? moment(fieldWorkerDate?.startDate).format("YYYY-MM-DD")
      : "";
    let toDate = fieldWorkerDate?.endDate
      ? moment(fieldWorkerDate?.endDate).format("YYYY-MM-DD")
      : "";
    // axios.post(`referralList?limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}&search=${searchValue}`)
    axios
      .post(
        `referralList?limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}&fromDate=${fromDate}&toDate=${toDate}&referral_name=${searchValue?.fieldworker}&hospital_name=${searchValue?.hospital}`
      )
      .then((data) => {
        setRows(data.data.result.list);
        setLength(data.data.result.length ? data.data.result.length : 0);
        setPageInfo((prevState) => ({
          ...prevState,
          count: data.data.result.length ? data.data.result.length : 0,
        }));
      });
  }
  /* ------ handle value from new data entered -----*/

  const handleAutoComplete = (e, v) => {
    setFormValue({ ...formValue, ["hospital_id"]: e });
  };
  const handleChange = (e) => {
    setFormValue({ ...formValue, ["referral_name"]: e.target.value });
  };

  const handleSubmit = (values) => {
    var hospital = hospitalName.find((i) => {
      if (i._id == values.hospital_id) {
        return i;
      }
    });

    values.hospital_name = hospital.hospital_name;
    values.hospital_id = values.hospital_id;
    delete values.status;

    if (editState === false) {
      axios.post(`referralCreate`, values).then((res) => {
        if (res.data.success === true) {
          setOpen(false);
          fetchData();
          setFormValue({ hospital_id: "", referral_name: "" });
        }
      });
    } else if (editState === true) {
      let insertData = {
        hospital_name: values.hospital_name,
        hospital_id: values.hospital_id,
        referral_name: values.referral_name,
        _id: activeId,
      };

      axios.post(`referralUpdate`, insertData).then((res) => {
        if (res.data.success === true) {
          setFormValue({ hospital_id: "", referral_name: "" });
          setOpen(false);
          setEditState(false);
          fetchData();
        }
      });
    }
  };

  const handleSearchValue = (values, field) => {
    if (field == "hospital") {
      setSearchValue({
        ...searchValue,
        hospital: values,
      });
    } else {
      setSearchValue({
        ...searchValue,
        fieldworker: values,
      });
    }
  };

  /*---- bulk file upload call  for Excel --- */

  async function handleImport(event) {
    const filedata = await ExcelUplaod(event);
    let dataExcel = filedata.data.map(
      ({ HospitalName: hospital_name, ReferralName: referral_name }) => ({
        hospital_name,
        referral_name,
      })
    );

    axios.post(`referralCreateMany`, dataExcel).then((res) => {
      if (res.data.success === true) {
        fetchData();
        setOpen(false);
        // toast.success("Excel Data Added Sucessfully", {autoClose: 3000,transition: Slide,});
      }
    });
  }

  /*------------------------ Excel Download ---------------*/
  const handleuploadExcel = () => {
    const columns = [{ HospitalName: "", ReferralName: "" }];
    ExcelDownload(columns, "referralTemplate");
  };

  /* ------ handle Edit ------ */
  const handleEdit = (num) => {
    setChangeValue(num);
    setOpen(true);
    setEditState(true);
    //  take parameter passed from Child component
  };
  const editData = (data) => {
    setEditState(true);
    rows.forEach((list) => {
      if (list._id === data) {
        setActiveId(list._id);
        setFormValue({ ...formValue, ...list });
        setChangeValue(data);
        setOpen(true);
      }
    });
  };
  const deleteDate = (data) => {
    setActiveId(data);
    setDeletePopup(true);
  };
  function deleteCancel() {
    setDeletePopup(false);
  }
  function deleteSubmit() {
    let insertData = {
      _id: activeId,
    };
    setDeletePopup(false);
    axios.post("referralDelete", insertData).then((res) => {
      if (res.data.success === true) {
        fetchData();
      }
    });
  }
  // const filterResponse = (val) => {
  //   setFilterValue(val);
  // };
  return (
    <div className="main">
      <div>
        <div className="state_head">
          <Typography variant="h5" color="secondary" className="text-left">
            Referral Management
          </Typography>
          <div>
            <Button
              style={{ marginLeft: "-6%" }}
              variant="contained"
              startIcon={<FileDownloadIcon />}
              onClick={handleuploadExcel}
            >
              Template
            </Button>

            <Button
              sx={{ m: 1, width: "13ch", marginLeft: "5%" }}
              variant="contained"
              component="label"
              startIcon={<FileUploadIcon />}
            >
              Upload
              <input
                hidden
                type="file"
                name="file"
                className="custom-file-input"
                id="inputGroupFile"
                required
                onChange={handleImport}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </Button>
            <Button
              variant="contained"
              sx={{ float: "right", m: 1, width: "10ch" }}
              onClick={handleAdd}
            >
              {" "}
              Add
            </Button>
          </div>
        </div>
      </div>
      <div className="ref-table">
        <TableComponent
          referral={true}
          reset={reset}
          datewiseSearch={true}
          header={header}
          name={"referral Management"}
          date={fieldWorkerDate}
          actions={["edit", "delete"]}
          editData={(id) => editData(id)}
          handleDate={(val, field) => handleDates(val, field)}
          handleFieldDate={() => filterResponse()}
          handleClearDate={() => clearValues()}
          deleteData={(id) => deleteDate(id)}
          datalist={rows}
          datalength={length}
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          searchValue={searchValue}
          handleEdit={handleEdit}
          handleTablePage={handleTablePage}
          handleTableRowsPerPage={handleTableRowsPerPage}
          searchDataValue={(val, field) => handleSearchValue(val, field)}
        />
        {deletePopup ? (
          <ConfirmPopup
            message={"Are you sure, you want to delete this record"}
            deleteCancel={deleteCancel}
            deleteSubmit={deleteSubmit}
          ></ConfirmPopup>
        ) : (
          ""
        )}
      </div>
      <Dialog
        open={open}
        scroll="paper"
        fullWidth={true}
        onClose={handleClosePrevent}
      >
        {" "}
        <Formik
          initialValues={formValue}
          validationSchema={ReferralSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <CardContent>
                <CloseIcon
                  onClick={handleClose}
                  style={{ marginLeft: "33rem" }}
                />

                <div>
                  <Field name="hospital_id">
                    {({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel
                          id="select-label"
                          style={{ marginLeft: "-3%" }}
                        >
                          Select Hospital
                        </InputLabel>
                        <Select
                          labelId="select-label"
                          id="select"
                          variant="standard"
                          {...field}
                          value={field.value}
                          onChange={(event) => {
                            setFieldValue(field.name, event.target.value);
                          }}
                        >
                          {hospitalName?.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.hospital_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                </div>
                <div>
                  <HybridInput
                    // style={{  }}
                    label="Referral Type"
                    name="referral_name"
                    setVal={setFieldValue}
                    inputProps={{ style: { textTransform: "capitalize" } }}
                    fullWidth
                    type="text"
                    variant="standard"
                  />
                  <HybridSelect
                    label="Status"
                    name="status"
                    fullWidth
                    type="text"
                    defaultValue={""}
                    setVal={setFieldValue}
                    variant="standard"
                    options={DropDown}
                    dropKey="status"
                    dropVal="_id"
                  />
                </div>
                <div className="submit">
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      type="button"
                      color="primary"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    <Button variant="contained" type="submit" color="primary">
                      {editState ? "Update" : "Add"}
                    </Button>
                  </Stack>
                </div>
              </CardContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};
export default ReferralTypeManagement;
