// import { TextField, Stack, Button } from "@mui/material";
import {
  TextField,
  Stack,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import React, { useState, useEffect } from "react";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import { useAxios } from "../../hooks/useAxios.js";

const PatientReportFilter = ({ filterObject }) => {
  const [campStartDate, setStartDate] = useState(null);
  const [campEndDate, setEndDate] = useState(null);
  const [hospitalname, setHospitalName] = useState("");
  const [fieldworkerName, setFieldworkerName] = useState("");
  const [campPlace, setCampPlace] = useState("");
  const [campNumber, setCampNumber] = useState("");
  const [hospitals, setHospital] = useState([]);
  const { axios } = useAxios();

  const fW = {
    vhv: null,
    cbbf: null,
  };

  const [fieldWorkers, setFieldWorkers] = useState(fW);

  const [fieldreset, setFieldreset] = useState({
    vhv: 1,
    cbbf: 2,
  });

  const handleSubmit = () => {
    var hospital = hospitals?.find((h) => h._id == hospitalname);

    let obj = {
      campStart: campStartDate
        ? moment(campStartDate.$d).format("YYYY-MM-DD")
        : "",
      campEnd: campEndDate ? moment(campEndDate.$d).format("YYYY-MM-DD") : "",
      hospitalName: hospital == undefined ? "" : hospital?.hospital_name,
      name: fieldworkerName,
      camp_num: campNumber,
      camp_place: campPlace,
    };
    filterObject(obj);
  };

  const handleClose = () => {
    let obj = {
      campStart: "",
      campEnd: "",
      hospitalName: "",
      name: "",
      camp_num: "",
      camp_place: "",
    };
    setStartDate(null);
    setEndDate(null);
    setHospitalName(obj.hospitalName);
    setFieldworkerName(obj.name);
    setCampPlace(obj.camp_place);
    setCampNumber(obj.camp_num);
    filterObject(obj);
    setFieldreset({
      ...fieldreset,
      vhv: fieldreset.vhv + 0.1,
      cbbf: fieldreset?.cbbf + 0.1,
    });
  };

  useEffect(() => {
    loadHospital();
    loadFieldWorkers();
  }, []);

  async function loadHospital() {
    try {
      await axios.post("AllHospitalDropDown").then((res) => {
        let result = res.data.result;
        console.log("result", result);

        setHospital(
          result.map((data) => {
            return {
              _id: data._id,
              hospital_name: data.hospital_name,
            };
          })
        );
      });
    } catch (error) {
      console.log("hos", error);
    }
  }

  async function loadFieldWorkers(data) {
    try {
      await axios.post("getGISFieldWorkerList", data).then((res) => {
        var field_w = res.data.result.result;

        var vhvvalue = field_w?.find((i) => i.field_worker_type === "VHV");
        var cbbfvalue = field_w?.find((i) => i.field_worker_type === "CBBF");

        vhvvalue = vhvvalue == undefined ? {} : vhvvalue;
        cbbfvalue = cbbfvalue == undefined ? {} : cbbfvalue;

        setFieldWorkers({ ...fieldWorkers, vhv: vhvvalue, cbbf: cbbfvalue });
      });
    } catch (error) {
      console.log("fw-error", error);
    }
  }

  function handleHospital(e) {
    setHospitalName(e.target.value);

    var hospital = hospitals?.find((h) => h._id == e.target.value);

    var value = {
      hospital_id: [hospital?._id],
    };

    loadFieldWorkers(value);
  }

  function firstLetter(s) {
    return s?.replace(/^.{1}/g, s[0].toUpperCase());
  }

  return (
    <div style={{ width: "85%" }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            placeholder="From Date"
            renderInput={(props) => (
              <TextField {...props} size="small" sx={{ width: "12%" }} />
            )}
            value={campStartDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            placeholder="To Date"
            renderInput={(props) => (
              <TextField {...props} size="small" sx={{ width: "12%" }} />
            )}
            value={campEndDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
            minDate={campStartDate ? new Date(moment(campStartDate.$d)) : ""}
          />
        </LocalizationProvider>
        {/* <TextField
                    size='small'
                    variant="outlined"
                    placeholder="Hospital Name"
                    style={{ width: '12%' }}
                    value={hospitalname}
                    onChange={(e) => setHospitalName(e.target.value)}
                /> */}

        <FormControl style={{ width: "12%" }} size="small">
          <InputLabel id="select-label">Select Hospital</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            variant="outlined"
            label="Select Hospital"
            // value={hospitalname}
            // onChange={(e) => {
            //     setHospitalName(e.target.value)
            // }
            // }

            value={hospitalname}
            onChange={(e) => {
              handleHospital(e);
            }}
          >
            {hospitals?.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {firstLetter(option.hospital_name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Autocomplete
          size="small"
          disablePortal
          className="date"
          variant="standard"
          id="combo-box-demo"
          sx={{
            input: {
              "&::placeholder": {
                // <----- Add this.
                opacity: 1,
                marginBottom: "-5px",
                fontSize: "10px",
              },
            },
            backgroundColor: "#ffffff",
            margin: " 0 5px",
            // borderRadius: '5px',
            // width: '90%'
            width: "12%",
          }}
          key={fieldreset?.vhv}
          ListboxProps={{
            sx: { fontSize: 13 },
          }}
          options={
            fieldWorkers?.vhv?.filed_workerList?.length > 0
              ? fieldWorkers?.vhv?.filed_workerList
              : [{ field_worker_name: "No data found" }]
          }
          getOptionLabel={(option) => firstLetter(option.field_worker_name)}
          // defaultValue={searchValue?.fieldworker == "" ? null : searchValue?.fieldworker}
          onChange={(data, value) => {
            setFieldworkerName(value?.field_worker_name);
            setFieldreset({ ...fieldreset, cbbf: fieldreset.cbbf + 0.1 });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                style: {
                  fontSize: "13px", // Adjust the font size of the label here
                  paddingTop: "5px",
                },
              }}
              color="black"
              label="Select VHV FieldWorker"
            />
          )}
        />

        <Autocomplete
          size="small"
          disablePortal
          className="date"
          variant="standard"
          id="combo-box-demo"
          sx={{
            input: {
              "&::placeholder": {
                opacity: 1,
                marginBottom: "-5px",
              },
            },
            backgroundColor: "#ffffff",
            margin: " 0 5px",
            // borderRadius: '5px',
            // width: '90%'
            width: "12%",
          }}
          key={fieldreset?.cbbf}
          ListboxProps={{
            sx: { fontSize: 13 },
          }}
          options={
            fieldWorkers?.cbbf?.filed_workerList?.length > 0
              ? fieldWorkers?.cbbf?.filed_workerList
              : [{ field_worker_name: "No data found" }]
          }
          getOptionLabel={(option) => firstLetter(option.field_worker_name)}
          // defaultValue={searchValue?.fieldworker == "" ? null : searchValue?.fieldWorkers}
          onChange={(data, value) => {
            setFieldworkerName(value?.field_worker_name);
            setFieldreset({ ...fieldreset, vhv: fieldreset.vhv + 0.1 });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              color="black"
              InputLabelProps={{
                style: {
                  fontSize: "13px", // Adjust the font size of the label here
                  paddingTop: "5px",
                },
              }}
              /* label="Select CBBF FieldWorker" */
              label="Select Volunteers FieldWorker"
            />
          )}
        />

        {/* <TextField
                    size='small'
                    variant="outlined"
                    style={{ width: '12%' }}
                    placeholder="Fieldworker Name"
                    value={fieldworkerName}
                    onChange={(e) => setFieldworkerName(e.target.value)}
                /> */}

        <TextField
          size="small"
          variant="outlined"
          style={{ width: "12%" }}
          placeholder="Camp Place"
          value={campPlace}
          onChange={(e) => setCampPlace(e.target.value)}
        />
        <TextField
          size="small"
          variant="outlined"
          style={{ width: "12%" }}
          placeholder="Camp Number"
          value={campNumber}
          onChange={(e) => setCampNumber(e.target.value)}
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, "").slice(0, 6);
          }}
        />
        <Button
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          style={{ width: "5%" }}
          onClick={handleSubmit}
        >
          Search
        </Button>
        <Button
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          style={{ width: "5%" }}
          onClick={handleClose}
        >
          Reset
        </Button>
      </Stack>
    </div>
  );
};
export default PatientReportFilter;
