import React, { useState, useEffect, useRef } from "react";

import { FallingLines } from "react-loader-spinner";
import {
  Typography,
  Button,
  Modal,
  Box,
  Paper,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  CardContent,
  Autocomplete,
  TextField,
} from "@mui/material";
import TableComponent from "../components/TableLayout";
import { Form, Formik, Field } from "formik";
import { HybridInput, HybridSelect } from "../components/hybrid_inputs";
import { TalukSchema } from "../validations/userSchema";
import Lowercase from "./LowerCase";
import { useAxios } from "../hooks/useAxios";
import "./Style.css";
import ConfirmPopup from "../components/confirmPopup";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelUplaod from "../components/ExcelFormat/Excelfileupload";
import ExcelDownload from "../components/ExcelFormat/ExcelFileDownload";
import { object } from "yup";
import { toast, Slide } from "react-toastify";

const Subdivison = () => {
  const { axios } = useAxios();
  const [length, setLength] = useState();
  const [formValue, setFormValue] = useState({
    district_id: "",
    taluk_name: "",
    status: "",
    // state: "",
    state_id: "",
    // state_name: ""
  });

  let paginationInfo = {
    page: 1,
    count: 0,
    rowsPerPage: 10,
  };

  const [pageInfo, setPageInfo] = useState(paginationInfo);
  const [header] = useState([
    {
      id: 1,
      label: "District Name ",
      value: "district_name",
    },
    {
      id: 2,
      label: "Subdivison",
      value: "taluk_name",
    },
    // {
    //     id: 3,
    //     label: "Status",
    //     value: "status"
    // },
    {
      id: 3,
      label: "Action",
      value: "action",
    },
  ]);

  const [DropDown] = useState([
    {
      _id: 1,
      status: "Active",
    },
    {
      _id: 0,
      status: "Inactive",
    },
  ]);
  const uploadRef = useRef();

  const [open, setOpen] = useState(false);
  const [districtList, setDistrictList] = useState([]);
  const [talukList, setTalukList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [editState, setEditState] = useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [StateEdit, setStateEdit] = useState([]);
  // const [selectedValues, setSelectedValues] = useState("");
  const [selectedValues, setSelectedValues] = useState("");
  const [districtVal, setDistrictval] = useState(null);
  const [loading, setLoading] = useState(false);
  /*---------- getting taluk  for table -----------*/

  const handleSearchValue = (values) => {
    setPageInfo(paginationInfo);
    setSearchValue(values);
  };
  useEffect(() => {
    handleState();
    callTaluk();
  }, [pageInfo.rowsPerPage, pageInfo.page, searchValue]);

  useEffect(() => {}, [districtList]);

  const callTaluk = () => {
    axios
      .post(
        `talukList?limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}&search=${searchValue}`
      )
      .then((data) => {
        let result = Lowercase(data.data.result.list, "taluk_name");
        setLength(data.data.result.length ? data.data.result.length : 0);
        setTalukList(result);
      })
      .catch(() => {
        setTalukList([]);
        setLength(0);
        setPageInfo(paginationInfo);
      });
  };

  const handleAdd = () => {
    setOpen(true);
    setEditState(false);
    setFormValue({ district_id: "", taluk_name: "" });
  };

  const handleSubmit = (values) => {
    var district = districtList.find((i) => {
      if (i._id == values.district_id) {
        return i;
      }
    });

    var state = stateList.find((i) => {
      if (i._id == values.state_id) {
        return i;
      }
    });

    if (editState === false) {
      values.district_name = district.district_name;
      values.district_id = district._id;
      values.state_id = selectedValues._id;
      values.state_name = selectedValues.state_name;

      axios.post(`talukCreate`, values).then((data) => {
        if (data.data.success == true) {
          setOpen(false);
          callTaluk();
        }
      });
    } else {
      let insertData = {
        district_name: district.district_name,
        district_id: values.district_id,
        taluk_name: values.taluk_name,
        status: values.status,
        state_id: values.state_id._id,
        state_name: values.state_id.state_name,
        _id: activeId,
      };

      axios.post(`talukUpdate`, insertData).then((data) => {
        if (data.data.success === true) {
          setOpen(false);
          callTaluk();
        }
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // prevent backDrop of dialog
  const handleClosePrevent = (reason) => {
    if (reason == "escapeKeyDown" || reason == "backdropClick") {
      setOpen(true);
    }
  };

  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };
  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };
  const deleteDate = (data) => {
    setActiveId(data);
    setDeletePopup(true);
  };
  function deleteCancel() {
    setDeletePopup(false);
  }
  function deleteSubmit() {
    setDeletePopup(false);
    let insertData = {
      _id: activeId,
    };

    axios.post(`talukDelete`, insertData).then((data) => {
      callTaluk();
    });
  }

  const editData = (data) => {
    talukList.forEach((list) => {
      if (list._id === data) {
        setActiveId(list._id);

        var state = "";
        stateList?.map((x) => {
          if (x._id == list.state_id) {
            state = x;
          }
        });

        var district = {
          _id: list.district_id,
          district_name: list?.district_name,
        };

        var val = { _id: list.state_id, state_name: list.state_name };
        handleStateDistrict(val);

        setSelectedValues(state);
        setDistrictval(district);

        var form = {
          status: list?.status,

          state_id: state?._id,
          state_name: state?.state_name,

          district_id: district?._id,
          district_name: district?.district_name,

          taluk_name: list?.taluk_name,
        };

        var forms = { ...formValue, ...form };

        setFormValue(forms);
        setOpen(true);
      }
    });

    setEditState(true);
  };

  /*---- bulk file upload call  for Excel --- */

  async function handleImport(event) {
    try {
      setLoading(true); // Show the loader while uploading

      const filedata = await ExcelUplaod(event);

      const unique = [
        ...new Map(filedata?.data.map((m) => [m.Subdivision, m])).values(),
      ];

      const batchSize = 1000;
      const totalItems = unique.length;
      const batches = [];
      for (let i = 0; i < totalItems; i += batchSize) {
        batches.push(unique.slice(i, i + batchSize));
      }

      const uploadBatch = async (dataExcel) => {
        await axios.post(`talukCreateMany`, dataExcel).then((res) => {
          if (res.data.success === true) {
            setOpen(false);
            callTaluk();
          } else if (
            res.data.success === false &&
            res.data.internalStatus == 0
          ) {
            res.data?.result?.map((user) =>
              toast.error(`${user.message}`, {
                autoClose: 5000,
                transition: Slide,
              })
            );
          }
        });
      };

      for (const batch of batches) {
        let dataExcel = batch.map(
          ({
            StateName: state_name,
            DistrictName: district_name,
            Subdivision: taluk_name,
          }) => ({
            state_name,
            district_name,
            taluk_name,
          })
        );
        await uploadBatch(dataExcel);
      }
    } catch (error) {
    } finally {
      setLoading(false); // Hide the loader after uploading

      uploadRef.current.value = ""; // Reset the input field after uploading
    }

    // const filedata = await ExcelUplaod(event);

    // const unique = [...new Map(filedata?.data.map((m) => [m.Subdivision, m])).values()];

    // let dataExcel = unique?.map(
    //   ({ StateName: state_name, DistrictName: district_name, Subdivision: taluk_name }) => ({
    //     state_name,
    //     district_name,
    //     taluk_name,
    //   })
    // );
    // axios.post(`talukCreateMany`, dataExcel).then((res) => {
    //   if (res.data.success === true) {
    //     setOpen(false);
    //     callTaluk();
    //   }
    // });
  }

  async function handleState() {
    try {
      await axios.post("statesDropDown").then((res) => {
        let result = Lowercase(res.data.result, "state_name");
        setStateList(result);
      });
    } catch (error) {}
  }

  async function handleStateDistrict(value) {
    /*---------- getting district for drop down -----------*/
    setDistrictval("");

    try {
      var data = {
        state_id: value?._id,
      };
      await axios.post(`districtDropDown`, data).then((data) => {
        let result = Lowercase(data.data.result, "district_name");
        setDistrictList(result);
      });
    } catch (error) {
      console.log(error);
    }
  }

  /*------------------------ Excel Download ---------------*/
  const handleuploadExcel = () => {
    const columns = [{ StateName: "", DistrictName: "", Subdivision: "" }];
    ExcelDownload(columns, "SubDivisonTemplate");
  };

  return (
    <div className="taluk-header">
      <div className="state_head">
        <Typography variant="h5" color="secondary" className="text-left">
          Subdivison Management
        </Typography>
        <div>
          <Button
            style={{ marginLeft: "-6%" }}
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={() => handleuploadExcel()}
          >
            Template
          </Button>
          <Button
            sx={{ m: 1, width: "13ch", marginLeft: "5%" }}
            variant="contained"
            component="label"
            startIcon={<FileUploadIcon />}
          >
            Upload
            <input
              hidden
              type="file"
              ref={uploadRef}
              name="file"
              className="custom-file-input"
              id="inputGroupFile"
              required
              onChange={handleImport}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </Button>
          {loading && (
            <div className="loader">
              <FallingLines
                color="#ff7100"
                width="100"
                visible={true}
                ariaLabel="falling-lines-loading"
              />
            </div>
          )}
          <Button
            variant="contained"
            sx={{ float: "right", m: 1, width: "10ch" }}
            onClick={() => handleAdd()}
          >
            {" "}
            Add
          </Button>{" "}
        </div>
      </div>

      <div className="ref-table">
        <TableComponent
          header={header}
          actions={["edit", "delete"]}
          editData={(id) => editData(id)}
          deleteData={(id) => deleteDate(id)}
          datalist={talukList}
          datalength={length}
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          // handleEdit={handleEdit}
          handleTablePage={handleTablePage}
          handleTableRowsPerPage={handleTableRowsPerPage}
          searchDataValue={(val) => handleSearchValue(val)}
          searchValue={() => {
            setSearchValue("");
            setPageInfo(paginationInfo);
          }}
        />{" "}
        {deletePopup ? (
          <ConfirmPopup
            message={"Are you sure, you want to delete this record"}
            deleteCancel={deleteCancel}
            deleteSubmit={deleteSubmit}
          ></ConfirmPopup>
        ) : (
          ""
        )}
      </div>
      <div className="ref-add-edit">
        <Dialog
          open={open}
          scroll="paper"
          fullWidth={true}
          onClose={handleClosePrevent}
        >
          {" "}
          <Formik
            initialValues={formValue}
            validationSchema={TalukSchema}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue, values, formikProps }) => (
              <Form>
                <CardContent>
                  <CloseIcon
                    onClick={handleClose}
                    style={{ marginLeft: "33rem" }}
                  />

                  {/* <Autocomplete
                    size="small"
                    disablePortal
                    className="hide"
                    id="combo-box-demo"
                    sx={{
                      backgroundColor: '#ffffff',
                      borderRadius: '5px',
                      width: '100%',
                      margin: '0',
                      minHeight: '50px'
                    }}
                    value={selectedValues}
                    defaultValue={StateEdit.length > 0 ? StateEdit : []}
                    options={stateList?.length > 0 ? stateList : [{ "state_name": "No data found" }]}
                    getOptionLabel={(option) => option.state_name}
                    onChange={(event, value) => {
                      // setFieldValue(field.name, value._id);
                      handleStateDistrict(value);
                      setSelectedValues(value);
                    }}
                    renderInput={(params) => <TextField
                      variant='standard'
                      {...params} color="black" InputLabelProps={{
                        style: {
                          paddingTop: '5px'
                        }
                      }} label="Select State" />}
                  /> */}

                  {/*                   
                  <Autocomplete
                    style={{ marginTop: "3%" }}
                    id="tags-standard"
                    options={stateList?.length > 0 ? stateList : { "state_name": "No data found" }}
                    freeSolo
                    name={"state_id"}
                    defaultValue={''}
                    value={selectedValues}
                    getOptionLabel={(option) => option.state_name}
                    onChange={(data, value) => {
                      handleStateDistrict(value);
                      setSelectedValues(value);
                      console.log('value',value);
                      setFieldValue('state_id', value?._id || '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="State Name"
                        placeholder=""
                      />
                    )}
                  /> */}

                  <Field name="state_id">
                    {({ field }) => (
                      <Autocomplete
                        size="small"
                        disablePortal
                        className="hide"
                        id="combo-box-demo"
                        sx={{
                          backgroundColor: "#ffffff",
                          borderRadius: "5px",
                          width: "100%",
                          margin: "0",
                          minHeight: "50px",
                        }}
                        // value={field.state_id}
                        value={editState ? selectedValues : field.state_id}
                        defaultValue={
                          field.state_id == "" ? "" : field.state_id
                        }
                        options={
                          stateList?.length > 0
                            ? stateList
                            : [{ state_name: "No data found" }]
                        }
                        getOptionLabel={(option) => option.state_name}
                        onChange={(event, value) => {
                          setFieldValue(field.name, value?._id);
                          handleStateDistrict(value);
                          setSelectedValues(value);
                          setDistrictval(null);
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            color="black"
                            InputLabelProps={{
                              style: {
                                paddingTop: "5px",
                              },
                            }}
                            label="Select State"
                          />
                        )}
                      />
                    )}
                  </Field>

                  <Field name="district_id">
                    {({ field }) => (
                      <Autocomplete
                        size="small"
                        disablePortal
                        className="hide"
                        id="combo-box-demo"
                        sx={{
                          backgroundColor: "#ffffff",
                          borderRadius: "5px",
                          width: "100%",
                          margin: "0",
                          minHeight: "50px",
                        }}
                        value={editState ? districtVal : field.district_id}
                        options={
                          districtList?.length > 0
                            ? districtList
                            : [{ district_name: "No data found" }]
                        }
                        getOptionLabel={(option) => option.district_name}
                        onChange={(event, value) => {
                          setFieldValue(field.name, value?._id);
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            color="black"
                            InputLabelProps={{
                              style: {
                                paddingTop: "5px",
                              },
                            }}
                            label="Select Districts"
                          />
                        )}
                      />
                      // <FormControl fullWidth>
                      //   <InputLabel
                      //     id="select-label"
                      //   >
                      //     Select District
                      //   </InputLabel>
                      //   <Select
                      //     labelId="select-label"
                      //     id="select"
                      //     variant="standard"
                      //     {...field}
                      //     value={field.value}
                      //     onChange={(event) => {
                      //       setFieldValue(field.name, event.target.value);
                      //     }}
                      //   >
                      //     {districtList.map((option) => (
                      //       <MenuItem key={option._id} value={option._id}>
                      //         {option.district_name}
                      //       </MenuItem>
                      //     ))}
                      //   </Select>
                      // </FormControl>
                    )}
                  </Field>
                  <HybridInput
                    label="SubDivison  / Taluk / Mandal / Circle / Block"
                    name="taluk_name"
                    setVal={setFieldValue}
                    inputProps={{ style: { textTransform: "capitalize" } }}
                    fullWidth
                    type="text"
                    variant="standard"
                  />
                  <HybridSelect
                    label="Status"
                    name="status"
                    fullWidth
                    type="text"
                    setVal={setFieldValue}
                    variant="standard"
                    options={DropDown}
                    dropKey="status"
                    dropVal="_id"
                  />
                  <div className="submit">
                    <Stack direction="row" spacing={2}>
                      <Button
                        color="primary"
                        type="button"
                        variant="contained"
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                      <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        onSubmit={handleSubmit}
                      >
                        {editState ? "Update" : "Add"}
                        {/* {changeValue ? "Update Data" : "Add Data"} */}
                      </Button>
                    </Stack>
                  </div>
                </CardContent>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </div>
  );
};

export default Subdivison;
