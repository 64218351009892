import React, { useEffect, useState } from "react";
import "./Style.css";
import { useAxios } from "../../../hooks/useAxios";

/*---------------------- Mui Components --------------------------*/

import { Box, Paper, Typography } from "@mui/material";

const PerformanceHome = (props) => {
  const { axios } = useAxios();
  const { hospitalFilter } = props;
  const [performanceIndicatingUnit, setPerformanceIndicator] = React.useState();

  useEffect(() => {
    performanceIndicator();
  }, [props, hospitalFilter]);

  const performanceIndicator = async () => {
    await axios
      .post(`getAvgPerformancePerDayDetail`, hospitalFilter)
      .then((data) => {
        setPerformanceIndicator(data.data.result);
      });
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: 'space-between', "& > :not(style)": { mt: 1, mb: 1, width: "23%", height: "18vh" } }}        >
      <Paper
        elevation={15}
        style={{ backgroundColor: "#FFDC00", borderColor: "red" }}
      >
        <div className="performance-content">
          <p style={{ fontSize: "1.2rem" }}>Avg. Household Survey done </p>
          <div className="performance-content-value">
            <p style={{ fontSize: "1.8rem", marginTop: "-1.5vh" }}>
              {performanceIndicatingUnit?.Avg_household_per_day
                ? Math.round(
                  performanceIndicatingUnit?.Avg_household_per_day
                )
                : 0}
            </p>
          </div>
        </div>
      </Paper>
      <Paper elevation={15} style={{ backgroundColor: "#6CE1CB" }}>
        <div className="performance-content">
          <p style={{ fontSize: "1.2rem" }}>Avg. Distance Travelled </p>
          <div className="performance-content-value">
            <p style={{ fontSize: "1.8rem", marginTop: "-1.5vh" }}>
              {performanceIndicatingUnit?.distanceAvg ? Math.round(performanceIndicatingUnit?.distanceAvg) : 0} km
            </p>
          </div>
        </div>
      </Paper>
      <Paper elevation={15} style={{ backgroundColor: "#92D050" }}>
        <div className="performance-content">
          <p style={{ fontSize: "1.1rem" }}>
            Avg. No. of Individuals Screened{" "}
          </p>
          <div className="performance-content-value">
            <p style={{ fontSize: "1.8rem", marginTop: "-1.5vh" }}>
              {
                performanceIndicatingUnit?.Avg_individual_screened_per_day ?
                  Math.round(performanceIndicatingUnit?.Avg_individual_screened_per_day)
                  : 0}
            </p>
          </div>
        </div>
      </Paper>
      <Paper elevation={15} style={{ backgroundColor: "#E2E214" }}>
        <div className="performance-content">
          <p style={{ fontSize: "1.2rem" }}>Avg. Yellow Card Issued </p>
          <div className="performance-content-value">
            <p style={{ fontSize: "1.8rem", marginTop: "-1.5vh" }}>
              {performanceIndicatingUnit?.Avg_yellocard_per_day ? Math.round(performanceIndicatingUnit?.Avg_yellocard_per_day) : 0}
            </p>
          </div>
        </div>
      </Paper>
    </Box>
  );
};

export default PerformanceHome;
