import { useContext } from "react";
import AuthContext from "../context/auth_context";

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw Error("Custom Error: useAuth must be used inside the AuthContext");
  }

  return context;
};

// export default useAuth;
