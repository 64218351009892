import React, { useState, useEffect } from "react";
import { useAxios } from "../../hooks/useAxios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FormControl, Typography } from "@mui/material";
import "./Style.css";
import ChartCbbf from "./ChartCbbf";
import CbbfPieChart from "./CbbfPie";
import { TextField, Stack, Button, Select, MenuItem } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import LowerCase from "../LowerCase";

const CbbfDashboard = () => {
  let HospitalModel = {
    _id: "",
    hospital_name: "No data found",
  };
  const { axios } = useAxios();
  const [TableValue, setTableValue] = React.useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [hospital, setHospital] = useState("");
  const [filtervalue, setFilterValue] = useState({
    fromDate: "",
    toDate: "",
    hospital_id: "",
  });
  const [hospitalList, setHospitalList] = useState([HospitalModel]);

  useEffect(() => {
    fetchData();
  }, [filtervalue]);

  useEffect(() => {
    getHospitalList();
  }, []);
  const getHospitalList = async (value) => {
    await axios.post("AllHospitalDropDown").then((data) => {
      if (data.data.result.length > 0) {
        let result = LowerCase(data.data.result, "hospital_name");
        result.sort(function (a, b) {
          return a.hospital_name.localeCompare(b.hospital_name);
        });
        console.log("result", result);
        setHospitalList(result);
      } else {
        setHospitalList([HospitalModel]);
      }
    });
  };
  async function fetchData() {
    try {
      await axios
        .post(
          `getCBBFDetail?fromDate=${filtervalue.fromDate}&toDate=${filtervalue.toDate}&hospital_id=${filtervalue.hospital_id}`
        )
        .then((data) => {
          console.log("cbbf", data.data);
          setTableValue(data.data.result);
        });
    } catch (error) {
      console.error("cbbfdash", error);
    }
  }

  const handleSubmit = () => {
    let obj = {
      fromDate: startDate ? moment(startDate.$d).format("YYYY-MM-DD") : "",
      toDate: endDate ? moment(endDate.$d).format("YYYY-MM-DD") : "",
      ...(hospital && { hospital_id: hospital }),
    };

    setFilterValue(obj);
  };

  const handleClose = () => {
    let obj = {
      fromDate: "",
      toDate: "",
    };
    setFilterValue(obj);
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <div>
      <div
        className="state_head"
        style={{ marginBottom: "1%", marginTop: "-1%" }}
      >
        <Typography variant="h5" color="secondary" className="text-left">
          {/* CBBF Dashboard */}
          Volunteers Screening
        </Typography>
      </div>
      <div>
        <Stack
          direction="row"
          spacing={2}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From Date"
              renderInput={(props) => <TextField {...props} size="small" />}
              defaultValue=""
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To Date"
              renderInput={(props) => <TextField {...props} size="small" />}
              defaultValue=""
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
            />
          </LocalizationProvider>
          <FormControl style={{ width: "20%" }}>
            <Select
              labelId="select-label"
              id="select"
              variant="standard"
              value={hospital}
              placeholder="Select Hospital"
              onChange={(event) => {
                setHospital(event.target.value);
              }}
            >
              {hospitalList.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.hospital_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            style={{ width: "11%" }}
            onClick={handleSubmit}
          >
            Search
          </Button>
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            style={{ width: "11%" }}
            onClick={handleClose}
          >
            Reset
          </Button>
        </Stack>
      </div>
      <div style={{ display: "flex" }}>
        <div>
          {/* <h3> Cbbf Camp Activites</h3> */}
          <h3> Volunteers Activites</h3>
          <CbbfPieChart TableValue={TableValue} />
        </div>
        <div>
          {/* <h3> Cbbf Conversion Indicators</h3> */}
          <h3> Volunteers Conversion Indicators</h3>
          <ChartCbbf TableValue={TableValue} />
        </div>
      </div>
      <div style={{ width: "85%", margin: "0 7.5%" }}>
        <TableContainer sx={{ width: "100%" }}>
          <Table
            aria-label="simple table"
            style={{ width: "100%", marginLeft: "" }}
          >
            <TableHead
              style={{
                backgroundColor: "white",
                fontWeight: "bold",
                fontSize: "25%",
              }}
            >
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  Indicator{" "}
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {" "}
                  Actual / Target{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  Total Districts Covered{" "}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  {" "}
                  {TableValue?.total_district}{" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {" "}
                  Total Taluks Covered{" "}
                </TableCell>
                <TableCell align="center">{TableValue?.total_taluk}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {" "}
                  Total Villages{" "}
                </TableCell>
                <TableCell align="center">
                  {TableValue?.total_village}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {" "}
                  Total Panchayats Covered{" "}
                </TableCell>
                <TableCell align="center">{TableValue?.total_mandal}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {" "}
                  Total No. of HouseHold{" "}
                </TableCell>
                <TableCell align="center">
                  {" "}
                  {TableValue?.total_CBBF_survey}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {" "}
                  Total Members{" "}
                </TableCell>
                <TableCell align="center">
                  {" "}
                  {TableValue?.total_TotalMembers}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {" "}
                  Total Willing to survey{" "}
                </TableCell>
                <TableCell align="center">
                  {" "}
                  {TableValue?.total_WillingSurgery}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {" "}
                  Total Unwilling to survey{" "}
                </TableCell>
                <TableCell align="center">
                  {" "}
                  {TableValue?.total_unWillingSurgery}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {" "}
                  Total Population Screened{" "}
                </TableCell>
                <TableCell align="center">
                  {TableValue?.total_patients_screened}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {" "}
                  Total No. of Camps{" "}
                </TableCell>
                <TableCell align="center">{TableValue?.total_camp}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {" "}
                  Total No. of Doors Closed{" "}
                </TableCell>
                <TableCell align="center">
                  {TableValue?.total_DoorClosed}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {" "}
                  Total No. of Doors open{" "}
                </TableCell>
                <TableCell align="center">
                  {TableValue?.total_DoorOpen}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {" "}
                  Cataracts Surgeries Done / Surgery Advised{" "}
                </TableCell>
                <TableCell align="center">
                  {TableValue?.total_surgery_done} /{" "}
                  {TableValue?.total_cataract}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {" "}
                  Glasses Distributed / Glasses Advised{" "}
                </TableCell>
                <TableCell align="center">
                  {TableValue?.total_galsses_destriputed_done} /{" "}
                  {TableValue?.total_glass_prescription}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {" "}
                  Reported to Vision Center / Refer to Vision Center{" "}
                </TableCell>
                <TableCell align="center">
                  {TableValue?.total_vision_center_done} /{" "}
                  {TableValue?.total_refer_to_vision_centre}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  Reported to Base Hospital / Refer to Base Hospital{" "}
                </TableCell>
                <TableCell align="center">
                  {TableValue?.total_base_hospital_done} /{" "}
                  {TableValue?.total_refer_to_base_hospital}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/* 
                
               
            </div> */}
    </div>
  );
};

export default CbbfDashboard;
