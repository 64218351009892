import {
  forgotPasswordSchema,
  newPasswordSchema,
} from "../validations/userSchema";
import * as React from "react";
import "../assets/styles/other_styles.scss";
import {
  Box,
  Button,
  Paper,
  Divider,
  // Link,
  CardActions,
  CardContent,
  Typography,
  Alert,
} from "@mui/material";
import "./Style.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import image from "../assets/logo.png";
// import "./authentication.scss";
import "../assets/styles/authentication.scss";
// import "../../assets/global.scss";
import "../assets/styles/global.scss";
import { Form, Formik } from "formik";
import { HybridInput } from "../components/hybrid_inputs";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { toast, Slide } from "react-toastify";

export default function ForgotPassword() {
  const navigation = useNavigate();
  const [resError, setResError] = React.useState(false);
  const [resErrorMsg, setResErrorMsg] = React.useState("");
  const [mailSent, setMailSent] = React.useState();
  const [emailId, setEmailId] = React.useState("");

  const passwordSubmit = async (values, actions) => {
    setResErrorMsg("");

    setResError(false);
    let payload = {
      password: values.password,
      otp: values.otp,
      email: emailId,
    };
    await axios
      .post(process.env.REACT_APP_BASE_URL_LOGIN + "restPassword", payload)
      .then((res) => {
        if (res.data.success) {
          setResErrorMsg("");
          // setMailSent(true);
          navigation("/");
          toast.success(res.data.message, {
            autoClose: 1500,
            transition: Slide,
          });
        }
        if (res.data.success === false) {
          setResErrorMsg(res.data.message);
          // setResErrorMsg("");
        }
        if (res.success === false) {
          setResErrorMsg(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setMailSent(false);
        setResError(true);
        setResErrorMsg(err.response.data.message);
      });
  };
  const clearErrorMsg = () => {
    setResError("");
    setResErrorMsg("");
  };
  const formSubmit = async (values, actions) => {
    clearErrorMsg();
    setResError(false);
    setEmailId(values.email);
    // let passBody = {
    //   email: values.email,
    //   redirectURL: process.env.REACT_APP_RESET_PASSWORD_URL,
    // };
    // values["redirectURL"] = process.env.REACT_APP_RESET_PASSWORD_URL;

    await axios
      .post(process.env.REACT_APP_BASE_URL_LOGIN + "forgotPassword", values)
      .then((res) => {

        if (res.data.success) {
          setMailSent(true);
          setResError("");
        } else {
          setResError(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setMailSent(false);
        setResError(true);
        setResError(err.response.data.message);
      });
    // actions.resetForm();
  };
  return (
    <div className="forgot_password">
      {" "}
      {mailSent ? (
        <div className="p-1">
          {" "}
          <div className="d-flex justify-content-between mb-1">
            <Formik
              initialValues={{
                otp: "",
                password: "",
                confirm_password: "",
              }}
              validationSchema={newPasswordSchema}
              onSubmit={passwordSubmit}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Box
                  sx={{
                    width: "50rem",
                    display: "inline-block",
                    p: 1,
                    mx: 1,
                    marginLeft: "10%",
                  }}
                >
                  <Form>
                    <Paper elevation={24}>
                      <div className="forgotpassword-content">
                        <div className="forgotpasswordImage-content align-self-center">
                          <img src={image} width="250" />
                          <p className="Servis">SERVIS</p>
                        </div>
                        <div className="text-content">
                          <div
                            style={{
                              color: "orange",
                              marginTop: "10%",
                              marginBottom: "11%",
                              marginRight: "-50%",
                            }}
                          >
                            {" "}
                            <Typography style={{ fontSize: "2rem" }}>
                              Reset Password{" "}
                            </Typography>
                          </div>
                          <div className="form">
                            <div className="text-field">
                              <HybridInput
                                className="inputRounded"
                                id="outlined-basic"
                                label="OTP"
                                variant="outlined"
                                setVal={setFieldValue}
                                fullwidth
                                name="otp"
                                type="text"
                              />
                              <HybridInput
                                className="inputRounded"
                                id="outlined-basic"
                                label="Password"
                                variant="outlined"
                                setVal={setFieldValue}
                                fullwidth
                                name="password"
                                type="password"
                              />
                              <HybridInput
                                className="inputRounded"
                                id="outlined-basic"
                                label="Confirm Password"
                                variant="outlined"
                                setVal={setFieldValue}
                                fullwidth
                                name="confirm_password"
                                type="password"
                              />
                            </div>
                            <div></div>
                            <div className="divider">
                              <Typography align="left">
                                {resErrorMsg}
                              </Typography>
                              <Divider
                                variant="middle"
                                sx={{ bgcolor: "orange" }}
                              />
                            </div>
                          </div>
                          <div className="link">
                            <span
                              onClick={() => {
                                setMailSent(false);
                                clearErrorMsg();
                              }}
                            >
                              back
                            </span>
                          </div>
                          <div className="reset-password-button">
                            <Button
                              variant="contained"
                              type="submit"
                              fullWidth
                              color="primary"
                              disabled={isSubmitting}
                            >
                              Reset Password
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Paper>
                  </Form>
                </Box>
              )}
            </Formik>
          </div>
          {/* <Typography gutterBottom className="text-left">
            Please open your gmail and click on the reset password link sent
            from sankara
          </Typography>
          <Typography gutterBottom className="text-left colored-header">
            Reset password token will expire in 1 hour after the requesting
          </Typography>
          <Button
            variant="contained"
            type="click"
            href="https://mail.google.com/"
            fullWidth
            color="primary"
          >
            Goto Gmail
          </Button> */}
        </div>
      ) : (
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={forgotPasswordSchema}
          onSubmit={formSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Box
              sx={{
                width: "50rem",
                display: "inline-block",
                p: 1,
                mx: 1,
                marginLeft: "10%",
              }}
            >
              <Form>
                {/* <CardContent className="p-1">
                <Typography
                  variant="h4"
                  gutterBottom
                  className="text-left colored-header"
                >
                  Forgot Passwords
                </Typography>
                {resError ? (
                  <Alert
                    severity="error"
                    onClose={() => {
                      setResError(false);
                    }}
                    className="mb-1"
                  >
                    {resErrorMsg}
                  </Alert>
                ) : (
                  ""
                )}
                <HybridInput
                  label="Email"
                  name="email"
                  type="email"
                  fullWidth
                  variant="standard"
                />
              </CardContent>
              <CardActions className="justify-content-center p-1">
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  color="primary"
                  disabled={isSubmitting}
                >
                  Send Mail
                </Button>
              </CardActions> */}
                <Paper elevation={24}>
                  <div className="forgotpassword-content">
                    <div className="forgotpasswordImage-content">
                      <img src={image} width="250" />
                      <p className="Servis">SERVIS</p>
                    </div>
                    <div className="text-content">
                      <div
                        style={{
                          color: "orange",
                          marginTop: "10%",
                          marginBottom: "11%",
                          marginRight: "-50%",
                        }}
                      >
                        {" "}
                        <Typography style={{ fontSize: "2rem" }}>
                          Forgot Password{" "}
                        </Typography>
                      </div>
                      <div className="form">
                        <div className="text-field">
                          <HybridInput
                            className="inputRounded"
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            setVal={setFieldValue}
                            fullwidth
                            name="email"
                            type="email"
                          />
                        </div>
                        <div></div>
                        <div className="divider">
                          <Typography align="left">{resError}</Typography>
                          <Divider
                            variant="middle"
                            sx={{ bgcolor: "orange" }}
                          />
                        </div>
                        <div className="link">
                          <Link to="/">back</Link>
                        </div>
                      </div>
                      <div className="button">
                        <Button
                          variant="contained"
                          type="submit"
                          fullWidth
                          color="primary"
                          disabled={isSubmitting}
                        >
                          Send Mail
                        </Button>
                      </div>
                    </div>
                  </div>
                </Paper>
              </Form>
            </Box>
          )}
        </Formik>
      )}
    </div>
  );
}
