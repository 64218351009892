import React, { useEffect, useState } from "react";
import "./Style.css";
import { useAxios } from "../../hooks/useAxios";
import LowerCase from "../LowerCase";
import DashboardFilter from "../../components/Filter/dashboardFilter";

/*---------------------- Mui Components --------------------------*/
import Button from "@mui/material/Button";

/*------------------------Mui Icons -----------------------------*/
import FilterListIcon from "@mui/icons-material/FilterList";

/*----------------------------External Components ----------------------*/
import PerformanceHome from "./PerformanceIndicator/PerformanceHome";
import ReferralCoverageYellow from "./ReferralCoverageYellow/Home";
import InduvidalVison from "./InduvidualVision/Home";
import TopPerformer from "./TopPerformer/Home";
import { textTransform } from "@mui/system";
import moment from "moment";

const Home = () => {
  let HospitalModel = {
    _id: "",
    hospital_name: "No data found",
  };
  let fieldWorkerModel = {
    _id: "",
    field_worker_name: "No data found",
  };

  const { axios } = useAxios();
  const [coverage, setCoverageUnit] = useState([]);
  const [open, setOpen] = useState(false);
  const [referral, setReferal] = useState();
  const [yellowCardOrder, setYellowCardOrder] = useState([]);
  const [yellowcard, setYellowCard] = useState();
  const [topPerformer, setTopPerformer] = useState();
  const [InduvidualScreen, setInduvidualScreen] = useState();
  const [visionCenter, setVisionCenter] = useState();
  const [hospitalList, setHospitalList] = useState([HospitalModel]);
  const [fieldWorkerList, setFieldWorkerList] = useState([fieldWorkerModel]);
  const [Cotaract, setCotaract] = useState();

  const [fieldWorker, setfieldWokerFilter] = useState({
    field_worker_id: "",
    hospital_id: "",
    fromDate: "",
    toDate: "",
  });
  const [hospitalFilter, setHospitalFilter] = useState({
    field_worker_id: "",
    hospital_id: "",
    fromDate: "",
    toDate: "",
  });

  /*----------------------------- APi calls -----------------------------*/

  useEffect(() => {
    getCoverageIndicator();
    getReferral();
    getYellowCardCandidate();
    getTopPerformer();
    getInduvidualScreen();
    getVisonCenterPerformance();
    getCotaract();
    getHospitalList();
    getFieldWorkerList();
  }, [fieldWorker, hospitalFilter]);

  const getCoverageIndicator = () => {
    axios.post(`getCoverageIndicator`, fieldWorker).then((data) => {
      setCoverageUnit(data.data.result);
    });
  };
  const getReferral = () => {
    axios.post(`getReferralProgress`, hospitalFilter).then((data) => {
      setReferal(data.data.result);
    });
  };
  const getYellowCardCandidate = () => {
    axios.post(`getTopPerformanceUnitsDetail`, hospitalFilter).then((data) => {
      // var sorted = data.data.result?.list?.sort((a, b) => {
      //   if (a.hospital_name < b.hospital_name) {
      //     return -1
      //   }
      //   if (a.hospital_name > b.hospital_name) {
      //     return 1
      //   }
      //   return 0
      // })
      // if(x?.hospital_name !== "sample hospital " || x?.hospital_name !== "sample hospitals" || x?.hospital_name !== "sample two hospital"  )

      // sorted?.filter((x) => x.hospital_name !== 'sample hospital ' || "sample hospitals" || "sample two hospital" )

      // console.log('data--------',data.data.result);

      // setYellowCardOrder(sorted);

      // console.log('data--------',sorted);

      setYellowCardOrder(data.data.result);
    });
  };
  const getTopPerformer = () => {
    axios.post(`getTopPerformingDetail`, fieldWorker).then((data) => {
      setTopPerformer(data.data.result);
    });
  };

  const getInduvidualScreen = () => {
    console.log("hospitalFilter", hospitalFilter);
    axios.post(`getIndividualScreeningGender`, hospitalFilter).then((data) => {
      setInduvidualScreen(data.data.result);
    });
  };
  const getVisonCenterPerformance = () => {
    axios.post(`getVisionCenterList`, hospitalFilter).then((data) => {
      setVisionCenter(data.data.result);
    });
  };
  const getCotaract = () => {
    axios.post(`getCataractGender`, fieldWorker).then((data) => {
      setCotaract(data.data.result);
    });
  };

  const getHospitalList = async (value) => {
    await axios.post("AllHospitalDropDown").then((data) => {
      if (data.data.result.length > 0) {
        let result = LowerCase(data.data.result, "hospital_name");
        result.sort(function (a, b) {
          return a.hospital_name.localeCompare(b.hospital_name);
        });
        setHospitalList(result);
      } else {
        setHospitalList([HospitalModel]);
      }
    });
  };

  const getFieldWorkerList = async (value) => {
    await axios.post("getGISFieldWorkerList").then((data) => {
      if (data.data.result.result[1].filed_workerList.length > 0) {
        let vhvWorker = data.data?.result?.result.filter((item) => {
          if (item.field_worker_type == "VHV") {
            return item;
          }
        });
        let result = LowerCase(
          vhvWorker[0].filed_workerList,
          "field_worker_name"
        );
        result.sort(function (a, b) {
          return a.field_worker_name.localeCompare(b.field_worker_name);
        });
        setFieldWorkerList(result);
      } else {
        setFieldWorkerList([fieldWorkerModel]);
      }
    });
  };

  /*-------------------------- Filter Code --------------------*/
  const handleOpen = () => {
    setfieldWokerFilter({
      field_worker_id: "",
      hospital_id: "",
      fromDate: "",
      toDate: "",
    });
    setHospitalFilter({
      field_worker_id: "",
      hospital_id: "",
      fromDate: "",
      toDate: "",
    });
    setOpen(!open);
  };
  const filterClose = (val) => {
    setOpen(val);
  };
  const handleFilter = (val) => {
    setfieldWokerFilter(val); // 4
  };
  const handlePerform = (val) => {
    setHospitalFilter(val); //2
  };
  return (
    <div className="main-component">
      <div className="filter">
        {open ? (
          <DashboardFilter
            filterClose={(val) => {
              filterClose(val);
            }}
            filterObject={(val) => {
              handleFilter(val);
            }}
            filterPerform={(val) => {
              handlePerform(val);
            }}
            hospital={hospitalList}
            fieldworkerList={fieldWorkerList}
          />
        ) : (
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            startIcon={<FilterListIcon />}
            onClick={handleOpen}
            style={{ backgroundColor: "gray" }}
          >
            Filter
          </Button>
        )}
      </div>

      <div className="PeformanceIndicator">
        <PerformanceHome hospitalFilter={hospitalFilter} />
      </div>

      <div className="yellow-coverage-referral">
        <ReferralCoverageYellow
          coverage={coverage}
          referral={referral}
          yellowCardOrder={yellowCardOrder}
        />
      </div>

      <div className="induvidualvision">
        <InduvidalVison
          InduvidualScreen={InduvidualScreen}
          visionCenter={visionCenter}
          Cotaract={Cotaract}
        />
      </div>

      <div className="top-perform">
        <p style={{ display: "flex", justifyContent: "flex-start", fontWeight: "bold", marginLeft: "2%", }}        >
          Top Performing Fieldworkers
        </p>
        <TopPerformer topPerformer={topPerformer} />
      </div>
    </div>
  );
};

export default Home;
