import React from "react";
import { Box, Paper } from "@mui/material";
import "./Style.css";

import TopPerformerField from "./TopPerformerFiled";
import Topunits from "./PerformingUnits";
import PerformingUnits from "./PerformingUnits";
import Avatar from "react-avatar";

import HowToRegIcon from "@mui/icons-material/HowToReg";
const Home = (props) => {
  const { topPerformer } = props;
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: 'space-between', "& > :not(style)": { mt: 2, mb: 2, width: "31%", height: "150px", }, }}          >
      <Paper elevation={15} style={{ backgroundColor: "MediumSeaGreen" }}>
        <div className="performance-content">
          <span className="margin-span">
            {" "}
            <HowToRegIcon className="field-worker-icon" />
          </span>{" "}
          <span className="margin-span">
            {topPerformer?.length > 0 ? topPerformer[0]?.name : ""}
          </span>
          <span>
            {topPerformer?.length > 0
              ? topPerformer[0]?.hospital_name
              : ""}{" "}
          </span>
        </div>
      </Paper>
      <Paper elevation={15} style={{ backgroundColor: "#92D050" }}>
        <div className="performance-content">
          {/* <Avatar facebookId="100008343750912" size="40%" /> */}
          <span className="margin-span">
            {" "}
            <HowToRegIcon className="field-worker-icon" />
          </span>
          <span className="margin-span">
            {topPerformer?.length > 0 ? topPerformer[1]?.name : ""}
          </span>

          <span>
            {topPerformer?.length > 0
              ? topPerformer[1]?.hospital_name
              : ""}{" "}
          </span>
        </div>
      </Paper>
      <Paper elevation={15} style={{ backgroundColor: "#6CE1CB" }}>
        <div className="performance-content">
          {/* <Avatar facebookId="100008343750912" size="40%" /> */}
          <span className="margin-span">
            {" "}
            <HowToRegIcon className="field-worker-icon" />
          </span>
          <span className="margin-span">
            {topPerformer?.length > 0 ? topPerformer[2]?.name : ""}
          </span>

          <span>
            {topPerformer?.length > 0
              ? topPerformer[2]?.hospital_name
              : ""}{" "}
          </span>
        </div>
      </Paper>

      {/* <div className='performance-content'>
              <PerformingUnits/>
            </div> */}
    </Box>
  );
};

export default Home;
