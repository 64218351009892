import { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  Checkbox,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useField, Field } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import "../assets/styles/global.scss";
import Select from "react-select";
// import MultiSelect from "elevate-ui/MultiSelect";

export function HybridInput(props) {
  const [field, meta] = useField(props);
  const { setVal, onChange } = props;
  return (
    <div style={{ marginBottom: "10px" }}>
      <TextField
        {...field}
        {...props}
        fullWidth
        style={{ borderRadius: "9px" }}
        onChange={(e) => {
          setVal(field.name, e.target.value);
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        error={meta.touched && meta.error ? true : false}
        helperText={meta.error && meta.touched ? meta.error : ""}
      />
    </div>
  );
}

export function HybridSelect({
  options,
  dropVal,
  dropId,
  dropKey,
  onChange,
  setVal,
  defaultValue,
  initialValues,

  ...otherProps
}) {
  useEffect(() => {}, []);

  const [field, meta] = useField(otherProps);

  return (
    <div>
      <TextField
        {...field}
        {...otherProps}
        select
        // style={{ minHeight: '1.75em' }}
        onChange={(e) => {
          setVal(field.name, e.target.value);
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        // inputProps={}
        // inputProps={{ style: { minHeight: "1.75em" } }}
        error={meta.touched && meta.error ? true : false}
        helperText={meta.error && meta.touched ? meta.error : ""}
      >
        {options !== ""
          ? options.map((option, index) => (
              <MenuItem key={index} value={dropVal ? option[dropVal] : option}>
                {option[dropKey]}
              </MenuItem>
            ))
          : ""}
      </TextField>
    </div>
  );
}

export function HybridCheckbox({ check, label, ...otherProps }) {
  const [field, meta] = useField(otherProps);
  return (
    <div className="mt-1 ">
      <div className="d-flex align-items-center">
        <Checkbox
          {...field}
          {...otherProps}
          inputProps={{ "aria-label": check }}
        />{" "}
        <Typography
          sx={{
            fontWeight: "600",
          }}
          color="secondary"
        >
          {label}
        </Typography>
      </div>
      <Typography
        className="text-left"
        sx={{ color: "#d32f2f", fontSize: "0.75rem" }}
      >
        {meta.error && meta.touched ? meta.error : ""}
      </Typography>
    </div>
  );
}

export function HybridDatePicker({
  setVal,
  variant,
  fullWidth,
  initialDate,
  onChange,
  ...otherProps
}) {
  const [field, meta] = useField(otherProps);
  const [date, setDate] = useState(initialDate);

  useEffect(() => {
    setDate(initialDate);
  }, [initialDate]);
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          {...field}
          {...otherProps}
          value={date}
          inputFormat="DD/MM/YYYY"
          onChange={(newValue) => {
            setDate(newValue);
            setVal(otherProps.name, newValue);
            if (onChange) {
              onChange(newValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={variant}
              fullWidth={fullWidth}
              error={meta.touched && meta.error ? true : false}
              helperText={meta.error && meta.touched ? meta.error : ""}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
}

export function MultiSelect({
  field,
  form,
  options,
  name,
  isMulti,
  placeholder = "Select",
  ...Props
}) {
  var str = JSON.stringify(options);
  str = str.replace(/_id/g, "value");
  str = str.replace(/village_name/g, "label");

  options = JSON.parse(str);

  if (!isMulti) {
    return (
      <Select
        options={options}
        name={field.name}
        value={
          options ? options.find((option) => option.value === field.value) : ""
        }
        onChange={(option) => form.setFieldValue(field.name, option.value)}
        onBlur={field.onBlur}
        placeholder={placeholder}
      />
    );
  } else {
    return (
      <Select
        isMulti
        name={name}
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    );
  }
}

export const SelectField = ({
  label,
  displayName,
  options,
  dropKey,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <FormControl fullWidth error={meta.touched && Boolean(meta.error)}>
      <InputLabel>{label}</InputLabel>
      <Select {...field} {...props}>
        {options.map((option) => (
          <MenuItem key={option[dropKey]} value={option[dropKey]}>
            1
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error && <div>{meta.error}</div>}
    </FormControl>
  );
};
