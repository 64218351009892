// import { TextField, Stack, Button } from "@mui/material";
import {
  TextField,
  Stack,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import React, { useState } from "react";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";

const PatientFilter = ({
  filterObject,
  fieldworker,
  hospitalDrop,
  fieldWorkers,
  reset,
  handleHospital,
}) => {
  const [campStartDate, setStartDate] = useState(null);
  const [campEndDate, setEndDate] = useState(null);
  const [hospitalname, setHospitalName] = useState(null);
  const [patientName, setPatientName] = useState("");
  const [field_worker_name, setFieldWorkerName] = useState("");

  const [fieldreset, setFieldreset] = useState({
    vhv: 1,
    cbbf: 2,
  });

  const handleSubmit = () => {
    var hospital = hospitalDrop?.find((h) => h._id == hospitalname);
    let obj = {
      campStart: campStartDate
        ? moment(campStartDate.$d).format("YYYY-MM-DD")
        : "",
      campEnd: campEndDate ? moment(campEndDate.$d).format("YYYY-MM-DD") : "",
      // hospital_name: hospital?.hospital_name,
      hospital_name: hospital == undefined ? "" : hospital?.hospital_name,
      name: patientName,
      field_worker_name: field_worker_name,
    };
    filterObject(obj);
  };

  const handleClose = () => {
    let obj = {
      campStart: "",
      campEnd: "",
      hospital_name: "",
      name: "",
      field_worker_name: "",
    };
    setStartDate(null);
    setEndDate(null);
    setHospitalName(obj.hospital_name);
    setPatientName(obj.name);
    setFieldWorkerName(obj.field_worker_name);
    // setPatientName("");
    // setHospitalName("");
    // setEndDate("");
    // setStartDate("");
    filterObject(obj);
    setFieldreset({
      ...fieldreset,
      vhv: fieldreset.vhv + 0.1,
      cbbf: fieldreset?.cbbf + 0.1,
    });
    handleHospital();
  };

  function handleHospitalDrop(e) {
    setHospitalName(e.target.value);
    handleHospital(e);
  }

  function firstLetter(s) {
    return s?.replace(/^.{1}/g, s[0].toUpperCase());
  }

  console.log("res", reset);

  return (
    <div style={{ marginBottom: "2%", marginTop: "2%" }}>
      <Stack direction="row" spacing={2} sx={{ width: "85vw" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="From Date"
            renderInput={(props) => <TextField {...props} size="small" />}
            value={campStartDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="To Date"
            renderInput={(props) => <TextField {...props} size="small" />}
            value={campEndDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
            minDate={campStartDate ? new Date(moment(campStartDate.$d)) : ""}
          />
        </LocalizationProvider>
        {/* <TextField
          variant="outlined"
          label="Hospital Name"
          size="small"
          value={hospitalname}
          onChange={(e) => setHospitalName(e.target.value)}
        /> */}

        <FormControl style={{ width: "15%" }} size="small">
          <InputLabel id="select-label">Select Hospitals</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            variant="outlined"
            label="Select Hospital"
            // onChange={(event) => {
            //   setFieldValue(field.name, event.target.value);
            // }}
            key={reset}
            value={hospitalname}
            // onChange={(e) => {
            //   setHospitalName(e.target.value)
            // }
            // }
            onChange={(e) => {
              // setCampPlace(e.target.value)
              handleHospitalDrop(e);
            }}
          >
            {hospitalDrop?.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {firstLetter(option.hospital_name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          label="Patient Name"
          size="small"
          value={patientName}
          onChange={(e) => setPatientName(e.target.value)}
        />
        {!fieldworker ? (
          ""
        ) : (
          // <TextField
          //   variant="outlined"
          //   label="Field Worker Name"
          //   size="small"
          //   value={field_worker_name}
          //   onChange={(e) => setFieldWorkerName(e.target.value)}
          // />
          <>
            <Autocomplete
              size="small"
              disablePortal
              className="date"
              variant="standard"
              id="combo-box-demo"
              sx={{
                input: {
                  "&::placeholder": {
                    // <----- Add this.
                    opacity: 1,
                    marginBottom: "-5px",
                    fontSize: "10px",
                  },
                },
                backgroundColor: "#ffffff",
                margin: " 0 5px",
                // borderRadius: '5px',
                // width: '90%'
                width: "15%",
              }}
              key={fieldreset?.vhv}
              ListboxProps={{
                sx: { fontSize: 13 },
              }}
              options={
                fieldWorkers?.vhv?.filed_workerList?.length > 0
                  ? fieldWorkers?.vhv?.filed_workerList
                  : [{ field_worker_name: "No data found" }]
              }
              getOptionLabel={(option) => firstLetter(option.field_worker_name)}
              // defaultValue={searchValue?.fieldworker == "" ? null : searchValue?.fieldworker}
              onChange={(data, value) => {
                setFieldWorkerName(value?.field_worker_name);
                setFieldreset({ ...fieldreset, cbbf: fieldreset.cbbf + 0.1 });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    style: {
                      fontSize: "13px", // Adjust the font size of the label here
                      paddingTop: "5px",
                    },
                  }}
                  color="black"
                  label="Select VHV FieldWorker"
                />
              )}
            />

            <Autocomplete
              size="small"
              disablePortal
              className="date"
              variant="standard"
              id="combo-box-demo"
              sx={{
                input: {
                  "&::placeholder": {
                    opacity: 1,
                    marginBottom: "-5px",
                  },
                },
                backgroundColor: "#ffffff",
                margin: " 0 5px",
                // borderRadius: '5px',
                // width: '90%'
                width: "15%",
              }}
              key={fieldreset?.cbbf}
              ListboxProps={{
                sx: { fontSize: 13 },
              }}
              options={
                fieldWorkers?.cbbf?.filed_workerList?.length > 0
                  ? fieldWorkers?.cbbf?.filed_workerList
                  : [{ field_worker_name: "No data found" }]
              }
              getOptionLabel={(option) => firstLetter(option.field_worker_name)}
              // defaultValue={searchValue?.fieldworker == "" ? null : searchValue?.fieldWorkers}
              onChange={(data, value) => {
                setFieldWorkerName(value?.field_worker_name);
                setFieldreset({ ...fieldreset, vhv: fieldreset.vhv + 0.1 });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  color="black"
                  InputLabelProps={{
                    style: {
                      fontSize: "13px", // Adjust the font size of the label here
                      paddingTop: "5px",
                    },
                  }}
                  /* label="Select CBBF FieldWorker" */
                  label="Select Volunteers FieldWorker"
                />
              )}
            />
          </>
        )}

        <Button
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          style={{ width: "11%" }}
          onClick={handleSubmit}
        >
          Search
        </Button>
        <Button
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          style={{ width: "11%" }}
          onClick={handleClose}
        >
          Reset
        </Button>
      </Stack>
    </div>
  );
};
export default PatientFilter;
