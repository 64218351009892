import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Dialog, Button, CardContent, Typography } from "@mui/material";
import { useAxios } from "../hooks/useAxios";
import { HybridInput, HybridSelect } from "../components/hybrid_inputs";
import { StateSchema } from "../validations/userSchema";
import { Form, Formik } from "formik";
import ConfirmPopup from "../components/confirmPopup";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import "./Style.css";
import TableComponent from "../components/TableLayout";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const StatesManagement = () => {
  const [length, setLength] = useState();

  const [header] = useState([
    { id: 1, label: "State Name", value: "state_name", alignRight: false },

    { id: 2, label: "Action", value: "action" },
  ]);
  const { axios } = useAxios();
  const [popup, setPopup] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [editState, setEditState] = useState(false);
  const [Editable] = useState(true);
  const [loading, setLoading] = useState(false);

  const [DropDown] = useState([
    {
      _id: 1,
      status: "Active",
    },
    {
      _id: 0,
      status: "In active",
    },
  ]);
  const [initialValues, setInitialValue] = useState({
    state_name: "",
    status: "",
  });
  const [dynamicInputData] = useState([
    {
      text: true,
      select: false,
      label: "State name",
      name: "state_name",
      type: "text",
      variant: "standard",
    },
    {
      text: false,
      select: true,
      label: "Status",
      name: "status",
      type: "text",
      variant: "standard",
      options: ["Active", "Inactive"],
    },
  ]);
  const [stateList, setStateList] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [active, setactive] = useState(false);
  let paginationInfo = {
    page: 1,
    count: 0,
    rowsPerPage: 10,
  };
  const [pageInfo, setPageInfo] = useState(paginationInfo);
  const [open, setOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");

  useEffect(
    (values) => {
      StateList();
    },
    [pageInfo.rowsPerPage, pageInfo.page, searchValue]
  );
  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };
  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };
  const StateList = () => {
    axios
      .post(
        `stateList?limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}&search=${searchValue}`
      )
      .then((data) => {
        setStateList(data.data.result.list);
        setLength(data.data.result.length ? data.data.result.length : 0);
        setPageInfo((prevState) => ({
          ...prevState,
          count: data.data.result.length,
        }));
      })
      .catch(() => {
        setStateList([]);
        setLength(0);
        setPageInfo(paginationInfo);
      });
  };
  const addStates = (actions) => {
    setEditState(false);
    setactive(false);
    reset();
    // actions.resetForm();

    setPopup(true);
  };
  const handleClose = () => {
    setPopup(false);
  };

  // prevent backDrop of dialog
  const handleClosePrevent = (reason) => {
    if (reason == "escapeKeyDown" || reason == "backdropClick") {
      setPopup(true);
    }
  };

  const handleCloseSnack = () => {
    setOpen(false);
  };

  const handleSearchValue = (values) => {
    setPageInfo(paginationInfo);

    setactive(true);

    setSearchValue(values);
  };
  const handleSubmit = (values) => {
    if (editState === false) {
      reset();
      axios.post(`stateCreate`, values).then((data) => {
        setLoading(false);
        if (data.data.success === true) {
          setPopup(false);
          StateList();
        }
      });
    } else {
      let insertData = {
        state_name: values.state_name,
        status: values.status,
        id: activeId,
      };

      axios.post(`stateUpdate`, insertData).then((data) => {
        setLoading(false);

        if (data.data.success === true) {
          setPopup(false);
          StateList();
        }
      });
    }
    // }
  };
  const reset = () => {
    setInitialValue({ state_name: "", status: "" });
  };
  const editData = (data) => {
    setEditState(true);
    stateList.forEach((list) => {
      if (list._id === data) {
        setActiveId(list._id);
        setInitialValue({ ...initialValues, ...list });
        setPopup(true);
      }
    });
  };
  const deleteDate = (data) => {
    setActiveId(data);
    setDeletePopup(true);
  };
  function deleteCancel(params) {
    setDeletePopup(false);
  }
  function deleteSubmit(params) {
    setDeletePopup(false);
    let insertData = {
      _id: activeId,
    };
    axios.post(`stateDelete`, insertData).then((data) => {
      StateList();
      // setStateList(data.data.data);
    });
  }
  function SearchFunction(params) {}
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  console.log("stateList", stateList);

  return (
    <div className="state_">
      <div className="state_head">
        <Typography variant="h5" color="secondary" className="text-left">
          States Management
        </Typography>
        <Button
          sx={{
            float: "right",
            m: 1,
            width: "10ch",
            textTransform: "capitalize",
          }}
          variant="contained"
          onClick={() => addStates()}
        >
          {" "}
          Add
        </Button>
      </div>

      <TableComponent
        header={header}
        // addList={addStates}
        Editable={Editable}
        datalist={stateList}
        datalength={length}
        editData={(id) => editData(id)}
        deleteData={(id) => deleteDate(id)}
        pageInfo={pageInfo}
        actions={["edit", "delete"]}
        setPageInfo={setPageInfo}
        handleTablePage={handleTablePage}
        handleTableRowsPerPage={handleTableRowsPerPage}
        searchDataValue={(val) => handleSearchValue(val)}
        searchValue={() => {
          setPageInfo(paginationInfo);
          setSearchValue("");
        }}
      ></TableComponent>
      {deletePopup ? (
        <ConfirmPopup
          message={"Are you sure, you want to delete this record"}
          deleteCancel={deleteCancel}
          deleteSubmit={deleteSubmit}
        ></ConfirmPopup>
      ) : (
        ""
      )}
      <Dialog
        open={popup}
        scroll="paper"
        fullWidth={true}
        onClose={handleClosePrevent}
      >
        {" "}
        <Formik
          initialValues={initialValues}
          validationSchema={StateSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <CardContent>
                <CloseIcon
                  onClick={handleClose}
                  style={{ marginLeft: "33rem" }}
                />
                <HybridInput
                  label="State Name"
                  name="state_name"
                  // inputProps={{ style: { textTransform: "capitalize" } }}
                  fullWidth
                  type="text"
                  variant="standard"
                  setVal={setFieldValue}
                />
                <HybridSelect
                  label="Status"
                  name="status"
                  fullWidth
                  type="text"
                  defaultValue={""}
                  setVal={setFieldValue}
                  variant="standard"
                  options={DropDown}
                  dropKey="status"
                  dropVal="_id"
                />
                <div className="submit">
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      type="button"
                      color="primary"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    {/* <Button variant="contained" type="submit" color="primary"
                    
                  >
                    {editState?'Update':'Add'} 
                    </Button> */}

                    <LoadingButton
                      variant="contained"
                      type="submit"
                      sx={{ float: "right", m: 1, width: "10ch" }}
                      loading={isSubmitting}
                    >
                      {editState ? "Update" : "Add"}
                    </LoadingButton>
                  </Stack>
                </div>
              </CardContent>
            </Form>
          )}
        </Formik>
      </Dialog>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert
          onClose={handleCloseSnack}
          severity="warning"
          sx={{ width: "100%" }}
        >
          State Name Already Taken !
        </Alert>
      </Snackbar>
    </div>
  );
};
