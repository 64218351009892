import React from "react";

import { Chart } from "react-google-charts";

const ReferralHome = ({ referral }) => {
  let total = referral?.length > 0 ? referral?.[0].YellowCardDistributed : 0;
  let reported_to_camp =
    referral?.length > 0 ? referral?.[0].VisitedCamp : 0 / total;
  var selected_for_surgery =
    referral?.length > 0 ? referral?.[0].selectedSurgery : 0 / total;
  var surgery_done =
    referral?.length > 0 ? referral?.[0].SurgeryDone : 0 / total;

  const data = [
    ["Task", "Hours per Day"],
    ["Reported to Camp", reported_to_camp],
    ["Selected for Surgery", selected_for_surgery],
    ["Surgery Done", surgery_done],
    ["Not Reported", total - reported_to_camp - selected_for_surgery - surgery_done], //- selected_for_surgery - surgery_done
  ];

  const options = {
    slices: {
      0: { color: "#FFDC00" },
      1: { color: "#6CE1CB" },
      2: { color: "#92D050" },
      3: { color: "#00B0F0" },
      4: { color: "OliveDrab" },
    },
    title: `Yellow card Distributed : ${total}`,
    pieHole: 0.5,
    chartArea: { left: "auto", top: "15%", width: "60%", height: "80%" },
    is3D: true,
    legend: {
      position: "top",
      textStyle: { fontSize: 11 },
      maxLines: 25,
      alignment: "left",
    },
  };

  const checkEmpty = () => {
    let flag = false;
    if (referral && referral.length > 0) {
      Object.values(referral[0]).forEach((value) => {
        if (value) {
          flag = true;
        }
      });
    }
    return flag;
  };

  return (
    <div>
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
        }}
      >
        Referral Conversion progress (Overall)
      </p>
      <div>
        {checkEmpty() ? (
          <div>
            <Chart
              style={{ marginLeft: "0%" }}
              chartType="PieChart"
              width="100%"
              height="40vh"
              data={data}
              options={options}
            // hAxis={
            //   viewWindow ={
            //     max : '100px',
            //     min : '100px'
            //   }
            // }
            />
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "40vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            No referral Conversion progress found!
          </div>
        )}
        <div></div>
      </div>
    </div>
  );
};

export default ReferralHome;
