import { TextField, Stack, Button, InputLabel, FormControl, Select, MenuItem } from "@mui/material";
import React, { useState } from "react";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const EventFilter = ({ filterObject,hospitalDrop }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [title, setTitle] = useState("");

  const handleSubmit = () => {
    let obj = {
      eventStart: startDate !== "" ? startDate.$d : "",
      eventEnd: endDate !== "" ? endDate.$d : "",
      hospital: hospitalName,
      eventtitle: title,
    };
    filterObject(obj);
  };

  const handleClose = () => {
    let obj = {
      eventStart: "",
      eventEnd: "",
      hospital: "",
      eventtitle: "",
    };
    console.log('objobjobj',obj);
    setStartDate(null)
    setEndDate(null)
    filterObject(obj);
    setHospitalName("");
    setTitle("");
  };



  function firstLetter(s) {
    return s?.replace(/^.{1}/g, s[0].toUpperCase());
  }


  return (
    <div style={{ marginBottom: "2vh" }}>
      <Stack direction="row" spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="From Date"
            renderInput={(props) => <TextField {...props} size='small' />}
            defaultValue=""
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="To Date"
            renderInput={(props) => <TextField {...props} size='small' />}
            defaultValue=""
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
          />
        </LocalizationProvider>
        {/* <TextField
          size='small'
          variant="outlined"
          label="Hospital Name"
          value={hospitalName}
          onChange={(e) => setHospitalName(e.target.value)}
        /> */}


        <FormControl style={{ width: "15%" }} size="small" >
          <InputLabel
            id="select-label"
          >
            Select Hospital
          </InputLabel>
          <Select
            labelId="select-label"
            id="select"
            variant="outlined"
            label="Select Hospital"
            // onChange={(event) => {
            //   setFieldValue(field.name, event.target.value);
            // }}
            value={hospitalName}
            onChange={(e) => {
              setHospitalName(e.target.value)
              
            }
            }
          >
            {hospitalDrop?.map((option) => (
              <MenuItem key={option._id} value={option.hospital_name}>
                {firstLetter(option.hospital_name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          size='small'
          variant="outlined"
          label="Event Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Button
          sx={{ textTransform: 'capitalize' }}
          variant="contained"
          style={{ width: "11%" }}
          onClick={handleSubmit}
        >
          Search
        </Button>
        <Button
          sx={{ textTransform: 'capitalize' }}
          variant="contained"
          style={{ width: "11%" }}
          onClick={handleClose}
        >
          Reset
        </Button>
      </Stack>
    </div>
  );
};
export default EventFilter;
