const  Lowercase=((data,params)=>{

    const result = data.map(item => ({
      ...item,
      [`${params}`]: item[params]?.toLowerCase().split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
      }))
      return result
})
export default Lowercase;