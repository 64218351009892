import React from 'react'
import './Style.css'

/*----------------------------Mui COmponents ---------------------*/
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const CoverageHome = (props) => {
  const { coverage } = props
  return (
    <div className='coverage-table'>

      {/* <Paper sx={{ width: '100%', }}> */}
      <TableContainer style={{ height: '46vh' }} >
        <Table stickyHeader aria-label="sticky table" size='small'>
          <TableHead sx={{ backgroundColor: 'peru' }} >
            <TableRow >
              <TableCell align="left" style={{ backgroundColor: 'peru', fontWeight: 'bold' }}>Indicator</TableCell>
              <TableCell align="center" style={{ backgroundColor: 'peru', fontWeight: 'bold' }}>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">Total District Covered</TableCell>
              <TableCell align="center">{coverage?.total_district}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Total Taluk Covered</TableCell>
              <TableCell align="center">{coverage?.total_taluk}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Total Village Covered</TableCell>
              <TableCell align="center">{coverage?.total_village}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Total Household Surveyed</TableCell>
              <TableCell align="center">{coverage?.total_HouseHold ? coverage?.total_HouseHold : 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Total Distance Covered</TableCell>
              <TableCell align="center">{coverage?.total_Distance ? Math.round(coverage?.total_Distance) : 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">No. of Fieldworker</TableCell>
              <TableCell align="center">{coverage?.total_fieldWorker}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Total Camp Conducted</TableCell>
              <TableCell align="center">{coverage?.total_camp}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>




    </div>
  )
}

export default CoverageHome