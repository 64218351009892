import * as XLSX from "xlsx-js-style";

import { toast, Slide } from "react-toastify";

const ExcelfileCustomized = (head, fileName = null) => {
  try {
    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.aoa_to_sheet([]);

    const mergeCells = [];
    for (const cells in head) {
      for (const nestCells in head[cells]) {
        XLSX.utils.sheet_add_aoa(ws, [[head[cells][nestCells]?.value]], {
          origin: nestCells,
        });

        if (head[cells][nestCells]?.styles) {
          ws[nestCells].s = head[cells][nestCells]?.styles;
        }

        if (head[cells][nestCells]?.mergeRange) {
          mergeCells.push(head[cells][nestCells]?.mergeRange);
        }
      }
    }

    ws["!merges"] = mergeCells;

    XLSX.utils.book_append_sheet(wb, ws, fileName);

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  } catch (error) {
    console.log("catchBlockCustomizedExcel", error);
    toast.error("Something Went Wrong, Please try again...", {
      autoClose: 1500,
      transition: Slide,
    });
  }
};

export default ExcelfileCustomized;
