import { useNavigate } from "react-router-dom";
import { useAuth } from "./useAuth";

export const useLogout = () => {
  const { dispatch } = useAuth();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("name");
    localStorage.removeItem("token");
    localStorage.removeItem("isLogged");
    localStorage.removeItem("sankara_admin_id");
    localStorage.removeItem("roles");
    localStorage.removeItem("hospital_id");

    dispatch({ type: "LOGOUT" });
    navigate("/");
  };
  return { logout };
};
