import React, { useState, useEffect, useRef } from "react";

import { FallingLines } from "react-loader-spinner";

import TableComponent from "../components/TableLayout";
import { useAxios } from "../hooks/useAxios";
import { HybridInput, HybridSelect } from "../components/hybrid_inputs";
import { VillageSchema } from "../validations/userSchema";
import {
  Typography,
  Button,
  Modal,
  Box,
  Paper,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  CardContent,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Form, Formik, Field } from "formik";

import { toast, Slide } from "react-toastify";
import ConfirmPopup from "../components/confirmPopup";
// import { LowerCase } from "./LowerCase";
import Lowercase from "./LowerCase";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelUplaod from "../components/ExcelFormat/Excelfileupload";
import ExcelDownload from "../components/ExcelFormat/ExcelFileDownload";
import CloseIcon from "@mui/icons-material/Close";

export const VillageManagement = () => {
  const [length, setLength] = useState();
  const [header] = useState([
    {
      id: 1,
      label: "Municipal Corporation/Gram Panchayat",
      value: "mandal_name",
    },
    {
      id: 2,
      label: "Village Name",
      value: "village_name",
    },

    {
      id: 4,
      label: "Action",
      value: "action",
    },
  ]);
  const { axios } = useAxios();
  const uploadRef = useRef();
  const [popup, setPopup] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [editState, setEditState] = useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const [selectedValues, setSelectedValues] = useState(null);
  const [districtVal, setDistrictval] = useState(null);
  const [talukVal, setTalukval] = useState(null);
  const [mandalval, setMandalval] = useState(null);
  const [loading, setLoading] = useState(false);

  const [DropDown] = useState([
    {
      _id: 1,
      status: "Active",
    },
    {
      _id: 0,
      status: "In active",
    },
  ]);

  const [initialValues, setInitialValue] = useState({
    state_id: "",
    district_id: "",
    taluk_id: "",
    mandal_id: "",
    village_name: "",
    status: "",
  });

  const [dynamicInputData] = useState([
    {
      text: true,
      select: false,
      label: "State name",
      name: "state_name",
      type: "text",
      variant: "standard",
    },
    {
      text: false,
      select: true,
      label: "Status",
      name: "status",
      type: "text",
      variant: "standard",
      options: ["Active", "Inactive"],
    },
  ]);

  const [mandalList, setMandalList] = useState([]);
  const [villageList, setVillageList] = useState([]);
  const [talukList, setTalukList] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  let paginationInfo = {
    page: 1,
    count: 0,
    rowsPerPage: 10,
  };
  const [pageInfo, setPageInfo] = useState(paginationInfo);

  useEffect(
    (values) => {
      axios.post("statesDropDown").then((res) => {
        let result = Lowercase(res.data.result, "state_name");
        setStateList(result);
      });

      // MandalList();
      VillageList();
    },
    [pageInfo.rowsPerPage, pageInfo.page, searchValue]
  );
  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };
  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };

  const handleSearchValue = (values) => {
    setPageInfo(paginationInfo);
    setSearchValue(values);
  };
  const VillageList = () => {
    axios
      .post(
        `villageList?limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}&search=${searchValue}`
      )
      .then((data) => {
        setVillageList(data.data.result.list);
        setLength(data.data.result.length ? data.data.result.length : 0);
        setPageInfo((prevState) => ({
          ...prevState,
          count: data.data.result.length ? data.data.result.length : 0,
        }));
      })
      .catch(() => {
        setVillageList([]);
        setLength(0);
        setPageInfo(paginationInfo);
      });
  };
  const addStates = () => {
    setEditState(false);
    reset();
    setPopup(true);
  };
  const handleClose = () => {
    setPopup(false);
  };

  // prevent backDrop of dialog
  const handleClosePrevent = (reason) => {
    if (reason == "escapeKeyDown" || reason == "backdropClick") {
      setPopup(true);
    }
  };

  const searchValues = (values) => {};

  const handleSubmit = (values) => {
    var district = districtList.find((i) => {
      if (i._id == values.district_id) {
        return i;
      }
    });

    var state = stateList.find((i) => {
      if (i._id == values.state_id) {
        return i;
      }
    });

    var taluk = talukList.find((i) => {
      if (i._id == values.taluk_id) {
        return i;
      }
    });

    var mandal = mandalList.find((i) => {
      if (i._id == values.mandal_id) {
        return i;
      }
    });

    if (editState === false) {
      values.district_name = districtVal?.district_name;
      values.district_id = districtVal?._id;
      values.state_id = selectedValues?._id;
      values.state_name = selectedValues?.state_name;

      values.taluk_name = talukVal.taluk_name;
      values.taluk_id = talukVal._id;
      values.mandal_name = mandalval.mandal_name;
      values.mandal_id = mandalval._id;
      axios.post(`villageCreate`, values).then((data) => {
        if (data.data.success == true) {
          setPopup(false);
          VillageList();
        }
      });
    } else {
      let insertData = {
        district_name: values.district_name,
        district_id: values.district_id,
        state_id: values.state_id,
        state_name: state.state_name,
        taluk_name: taluk.taluk_name,
        taluk_id: values.taluk_id,
        mandal_name: mandal.mandal_name,
        mandal_id: values.mandal_id,
        village_name: values.village_name,
        status: values.status,
        _id: activeId,
      };

      axios.post(`villageUpdate`, insertData).then((data) => {
        if (data.data.success == true) {
          setPopup(false);
          VillageList();
        }
      });
    }
  };

  const reset = () => {
    setInitialValue({ mandal_id: "", village_name: "", status: "" });
  };
  const editData = (data) => {
    setEditState(true);
    villageList.forEach((list) => {
      if (list._id === data) {
        setActiveId(list._id);

        var state = "";
        stateList?.map((x) => {
          if (x._id == list.state_id) {
            state = x;
          }
        });

        var val = { _id: list.state_id, state_name: list.state_name };
        var district = {
          _id: list.district_id,
          district_name: list?.district_name,
        };
        var taluk = { _id: list.taluk_id, taluk_name: list?.taluk_name };
        var mandall = { _id: list.mandal_id, mandal_name: list?.mandal_name };

        handleStateDistrict(val);
        handleDistrict(district);
        handleTalukMandal(taluk);

        setSelectedValues(state);
        setDistrictval(district);
        setTalukval(taluk);

        setMandalval(mandall);

        var form = {
          status: list?.status,

          state_id: state?._id,
          state_name: state?.state_name,

          district_id: district?._id,
          district_name: district?.district_name,

          taluk_id: list?.taluk_id,
          taluk_name: list?.taluk_name,

          mandal_id: mandall?._id,
          mandal_name: list?.mandal_name,

          village_name: list?.village_name,
        };

        var forms = { ...initialValues, ...form };

        setInitialValue(forms);
        // setInitialValue({ ...initialValues, ...list });
        setPopup(true);
      }
    });
  };
  const deleteDate = (data) => {
    setActiveId(data);
    setDeletePopup(true);
  };
  function deleteCancel(params) {
    setDeletePopup(false);
  }
  function deleteSubmit(params) {
    setDeletePopup(false);
    let insertData = {
      _id: activeId,
    };
    axios.post(`villageDelete`, insertData).then((data) => {
      if (data.data.success == true) {
        VillageList();
      }
      // setStateList(data.data.data);
    });
  }
  function SearchFunction(params) {}

  /*---- bulk file upload call  for Excel --- */

  async function handleImport(event) {
    try {
      const filedata = await ExcelUplaod(event);

      const unique = [
        ...new Map(filedata?.data.map((m) => [m.villageName, m])).values(),
      ];

      const batchSize = 1000;
      const totalItems = unique.length;
      const batches = [];
      for (let i = 0; i < totalItems; i += batchSize) {
        batches.push(unique.slice(i, i + batchSize));
      }

      const uploadBatch = async (dataExcel) => {
        await axios.post(`villageCreateMany`, dataExcel).then((res) => {
          console.log("res", res.data);
          if (res.data.success === true) {
            setPopup(false);
            VillageList();
          } else if (
            res.data.success === false &&
            res.data.internalStatus == 0
          ) {
            res.data?.result?.map((user) =>
              toast.error(`${user.message}`, {
                autoClose: 5000,
                transition: Slide,
              })
            );
          }
        });
      };
      for (const batch of batches) {
        let dataExcel = batch.map(
          ({
            StateName: state_name,
            DistrictName: district_name,
            Subdivision: taluk_name,
            panchayatName: mandal_name,
            villageName: village_name,
          }) => ({
            state_name,
            district_name,
            taluk_name,
            mandal_name,
            village_name,
          })
        );
        await uploadBatch(dataExcel);
      }
    } catch (error) {
      console.error("Error while uploading:", error);
    } finally {
      setLoading(false); // Hide the loader after uploading

      uploadRef.current.value = ""; // Reset the input field after uploading
    }

    // const filedata = await ExcelUplaod(event);

    // const unique = [...new Map(filedata?.data.map((m) => [m.villageName, m])).values()];

    // let dataExcel = unique.map(
    //   ({ StateName: state_name, DistrictName: district_name, Subdivision: taluk_name, panchayatName: mandal_name, villageName: village_name }) => ({
    //     state_name,
    //     district_name,
    //     taluk_name,
    //     mandal_name,
    //     village_name,
    //   })
    // );

    // axios.post(`villageCreateMany`, dataExcel).then((res) => {
    //   if (res.data.success === true) {
    //     setPopup(false);
    //     VillageList();
    //   }
    // });
  }

  /*------------------------ Excel Download ---------------*/
  const handleuploadExcel = () => {
    const columns = [
      {
        StateName: "",
        DistrictName: "",
        Subdivision: "",
        panchayatName: "",
        villageName: "",
      },
    ];
    ExcelDownload(columns, "villageTemplate");
  };

  async function handleStateDistrict(value) {
    /*---------- getting district for drop down -----------*/
    try {
      var data = {
        state_id: value?._id,
      };
      await axios.post(`districtDropDown`, data).then((data) => {
        let result = Lowercase(data.data.result, "district_name");
        setDistrictList(result);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDistrict(value) {
    try {
      var data = {
        district_id: value?._id,
      };
      await axios.post(`talukDropDown`, data).then((data) => {
        let result = Lowercase(data.data.result, "taluk_name");
        setTalukList(result);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function handleTalukMandal(value) {
    try {
      var data = {
        taluk_id: value?._id,
      };
      await axios
        .post(`mandalDropDown`, data)
        .then((data) => {
          let result = Lowercase(data.data.result, "mandal_name");
          setMandalList(result);
          setLength(data.data.result.length ? data.data.result.length : 0);
        })
        .catch(() => {
          setMandalList([]);
          setLength(0);
          setPageInfo(paginationInfo);
        });
    } catch (error) {
      console.log(error);
    }

    // axios
    //   .post(`AllMandalDropDown`)
    //   .then((data) => {
    //     let result = LowerCase(data.data.result, "mandal_name");
    //     setMandalList(result);
    //     setLength(data.data.result.length ? data.data.result.length : 0);
    //   })
    //   .catch(() => {
    //     setMandalList([]);
    //     setLength(0);
    //     setPageInfo(paginationInfo);
    //   });
  }

  return (
    <div>
      <div className="state_head">
        <Typography variant="h5" color="secondary" className="text-left">
          Village Management
        </Typography>
        <div>
          <Button
            style={{ marginLeft: "-6%" }}
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={handleuploadExcel}
          >
            Template
          </Button>

          <Button
            sx={{ m: 1, width: "13ch", marginLeft: "5%" }}
            variant="contained"
            component="label"
            startIcon={<FileUploadIcon />}
          >
            Upload
            <input
              hidden
              type="file"
              name="file"
              ref={uploadRef}
              className="custom-file-input"
              id="inputGroupFile"
              required
              onChange={handleImport}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </Button>
          {loading && (
            <div className="loader">
              <FallingLines
                color="#ff7100"
                width="100"
                visible={true}
                ariaLabel="falling-lines-loading"
              />
            </div>
          )}
          <Button
            variant="contained"
            sx={{ float: "right", m: 1, width: "10ch" }}
            onClick={() => addStates()}
          >
            {" "}
            Add
          </Button>
        </div>
      </div>

      <TableComponent
        header={header}
        // addList={addStates}
        Editable="false"
        datalist={villageList}
        datalength={length}
        editData={(id) => editData(id)}
        actions={["edit", "delete"]}
        deleteData={(id) => deleteDate(id)}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        handleTablePage={handleTablePage}
        handleTableRowsPerPage={handleTableRowsPerPage}
        searchDataValue={(val) => handleSearchValue(val)}
        searchValue={() => {
          setSearchValue("");
          setPageInfo(paginationInfo);
        }}
      ></TableComponent>
      {deletePopup ? (
        <ConfirmPopup
          message={"Are you sure, you want to delete this record"}
          deleteCancel={deleteCancel}
          deleteSubmit={deleteSubmit}
        ></ConfirmPopup>
      ) : (
        ""
      )}
      <Dialog
        open={popup}
        scroll="paper"
        fullWidth={true}
        onClose={handleClosePrevent}
      >
        {" "}
        <Formik
          initialValues={initialValues}
          validationSchema={VillageSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <CardContent>
                <CloseIcon
                  onClick={handleClose}
                  style={{ marginLeft: "33rem" }}
                />
                {/* 
                <Autocomplete
                  size="small"
                  disablePortal
                  className="hide"
                  id="combo-box-demo"
                  sx={{
                    backgroundColor: '#ffffff',
                    borderRadius: '5px',
                    width: '100%',
                    margin: '0',
                    minHeight: '50px'
                  }}
                  options={stateList?.length > 0 ? stateList : [{ "state_name": "No data found" }]}
                  getOptionLabel={(option) => option.state_name}
                  onChange={(event, value) => {
                    handleStateDistrict(value);
                  }}
                  renderInput={(params) => <TextField
                    variant='standard'
                    {...params} color="black" InputLabelProps={{
                      style: {
                        paddingTop: '5px'
                      }
                    }} label="Select State" />}
                />


                <Autocomplete
                  size="small"
                  disablePortal
                  className="hide"
                  id="combo-box-demo"
                  sx={{
                    backgroundColor: '#ffffff',
                    borderRadius: '5px',
                    width: '100%',
                    margin: '0',
                    minHeight: '50px'
                  }}
                  options={districtList?.length > 0 ? districtList : [{ "district_name": "No data found" }]}
                  getOptionLabel={(option) => option.district_name}
                  onChange={(event, value) => {
                    handleDistrict(value)
                  }}
                  renderInput={(params) => <TextField
                    variant='standard'
                    {...params} color="black" InputLabelProps={{
                      style: {
                        paddingTop: '5px'
                      }
                    }} label="Select Districts" />}
                />

                <Autocomplete
                  size="small"
                  disablePortal
                  className="hide"
                  id="combo-box-demo"
                  sx={{
                    backgroundColor: '#ffffff',
                    borderRadius: '5px',
                    width: '100%',
                    margin: '0',
                    minHeight: '50px'
                  }}
                  options={talukList?.length > 0 ? talukList : [{ "taluk_name": "No data found" }]}
                  getOptionLabel={(option) => option.taluk_name}
                  onChange={(event, value) => {
                    handleTalukMandal(value)
                  }}
                  renderInput={(params) => <TextField
                    variant='standard'
                    {...params} color="black" InputLabelProps={{
                      style: {
                        paddingTop: '5px'
                      }
                    }} label="Select Taluk" />}
                /> */}

                <Field name="state_id">
                  {({ field }) => (
                    <Autocomplete
                      size="small"
                      disablePortal
                      className="hide"
                      id="combo-box-demo"
                      sx={{
                        backgroundColor: "#ffffff",
                        borderRadius: "5px",
                        width: "100%",
                        margin: "0",
                        minHeight: "50px",
                      }}
                      name="state_id"
                      // value={field.state_id}
                      value={editState ? selectedValues : field.state_id}
                      defaultValue={field.state_id == "" ? "" : field.state_id}
                      options={
                        stateList?.length > 0
                          ? stateList
                          : [{ state_name: "No data found" }]
                      }
                      getOptionLabel={(option) => option.state_name}
                      onChange={(event, value) => {
                        setFieldValue(field.name, value?._id);
                        handleStateDistrict(value);
                        setSelectedValues(value);

                        setDistrictval(null);
                        setTalukval(null);
                        setMandalval(null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          color="black"
                          InputLabelProps={{
                            style: {
                              paddingTop: "5px",
                            },
                          }}
                          label="Select State"
                        />
                      )}
                    />
                  )}
                </Field>

                <Field name="district_id">
                  {({ field }) => (
                    <Autocomplete
                      size="small"
                      disablePortal
                      className="hide"
                      id="combo-box-demo"
                      sx={{
                        backgroundColor: "#ffffff",
                        borderRadius: "5px",
                        width: "100%",
                        margin: "0",
                        minHeight: "50px",
                      }}
                      value={editState ? districtVal : field.district_id}
                      options={
                        districtList?.length > 0
                          ? districtList
                          : [{ district_name: "No data found" }]
                      }
                      getOptionLabel={(option) => option.district_name}
                      onChange={(event, value) => {
                        setFieldValue(field.name, value?._id);
                        handleDistrict(value);
                        setDistrictval(value);
                        setTalukval(null);
                        setMandalval(null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          color="black"
                          InputLabelProps={{
                            style: {
                              paddingTop: "5px",
                            },
                          }}
                          label="Select Districts"
                        />
                      )}
                    />
                  )}
                </Field>

                <Field name="taluk_id">
                  {({ field }) => (
                    <Autocomplete
                      size="small"
                      disablePortal
                      className="hide"
                      id="combo-box-demo"
                      // value={talukVal}
                      sx={{
                        backgroundColor: "#ffffff",
                        borderRadius: "5px",
                        width: "100%",
                        margin: "0",
                        minHeight: "50px",
                      }}
                      value={editState ? talukVal : field.taluk_id}
                      defaultValue={field.taluk_id == "" ? "" : field.taluk_id}
                      options={
                        talukList?.length > 0
                          ? talukList
                          : [{ taluk_name: "No data found" }]
                      }
                      getOptionLabel={(option) => option.taluk_name}
                      onChange={(event, value) => {
                        setFieldValue(field.name, value?._id);
                        handleTalukMandal(value);
                        setTalukval(value);
                        setMandalval(null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          color="black"
                          InputLabelProps={{
                            style: {
                              paddingTop: "5px",
                            },
                          }}
                          label="Select SubDivison"
                        />
                      )}
                    />
                  )}
                </Field>

                <Field name="mandal_id">
                  {({ field }) => (
                    <Autocomplete
                      size="small"
                      disablePortal
                      className="hide"
                      id="combo-box-demo"
                      // value={talukVal}
                      sx={{
                        backgroundColor: "#ffffff",
                        borderRadius: "5px",
                        width: "100%",
                        margin: "0",
                        minHeight: "50px",
                      }}
                      value={editState ? mandalval : field.mandal_id}
                      defaultValue={
                        field.mandal_id == "" ? "" : field.mandal_id
                      }
                      options={
                        mandalList?.length > 0
                          ? mandalList
                          : [{ mandal_name: "No data found" }]
                      }
                      getOptionLabel={(option) => option.mandal_name}
                      onChange={(event, value) => {
                        setFieldValue(field.name, value?._id);
                        setMandalval(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          color="black"
                          InputLabelProps={{
                            style: {
                              paddingTop: "5px",
                            },
                          }}
                          label="Select Panchayat"
                        />
                      )}
                    />
                  )}
                </Field>

                {/* <Field name="mandal_id">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel
                        id="select-label"
                        style={{ marginLeft: "-2%" }}
                      >
                        Select Council
                      </InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        variant="standard"
                        {...field}
                        value={editState ? mandalVal : field.value}
                        onChange={(event) => {
                          setFieldValue(field.name, event.target.value);
                          setMandalval(value)
                        }}
                      >
                        {mandalList.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.mandal_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field> */}

                <HybridInput
                  label="Village Name"
                  name="village_name"
                  fullWidth
                  setVal={setFieldValue}
                  type="text"
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  variant="standard"
                />
                <HybridSelect
                  label="Status"
                  name="status"
                  fullWidth
                  type="text"
                  variant="standard"
                  options={DropDown}
                  defaultValue={""}
                  setVal={setFieldValue}
                  dropKey="status"
                  dropVal="_id"
                />
                <div className="submit">
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      type="button"
                      color="primary"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      onSubmit={handleSubmit}
                    >
                      {editState ? "Update" : "Add"}
                      {/* {changeValue ? "Update Data" : "Add Data"} */}
                    </Button>
                  </Stack>
                </div>
              </CardContent>
            </Form>
          )}
        </Formik>
        {/* <DialogTitle>State details</DialogTitle>
        <DialogContent>
          <FormControl fullWidth className="mt-1">
            <TextField
              label="Standard"
              variant="standard"
              name="state_name"
              type="text"
              value={data.state_name}
              onChange={(event) => handleChange(event)}
            />
          </FormControl>
          <HybridInput
            label="State details"
            name="state_name"
            type="text"
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions> 
        */}
      </Dialog>
    </div>
  );
};
