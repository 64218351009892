// react lifecycle hooks
import { useEffect, useState } from "react";

// custom hooks
import { useAxios } from "../hooks/useAxios";

// mui components
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  TextField,
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// custom components
import TableComponent from "../components/TableLayout";

// formik
import { useFormik } from "formik";
import { organisationValidationSchema } from "../validations/userSchema";

// toast
import { toast, Slide } from "react-toastify";

export default function Organisation() {
  // constants
  // table headers
  const header = [
    { id: 1, label: "Organisation Name", value: "organization_name" },
    { id: 2, label: "Name", value: "contact_person" },
    { id: 3, label: "Mobile No", value: "contact_number" },
    // { id: 4, label: "Address", value: "address" },
    { id: 4, label: "State", value: "state_name" },
    { id: 5, label: "District", value: "district_name" },
    { id: 6, label: "Taluk", value: "taluk_name" },
    { id: 7, label: "Panchayat", value: "panchayat_name" },
    { id: 8, label: "Status", value: "status" },
    { id: 9, label: "Action", value: "action" },
  ];

  // pagination default state
  const paginationInfo = { page: 1, count: 0, rowsPerPage: 10 };

  // initialFormState
  const iniFormValues = {
    organisation_name: "",
    contact_person: "",
    contact_person_mob: "",
    address: "",
    state: "",
    district: "",
    taluk: "",
    panchayat: "",
    status: "active",
  };

  // dropdown names
  const iniDropdownsState = {
    state_details: {},
    district_details: {},
    taluk_details: {},
    panchayat_details: {},
  };

  // axion interceptors
  const { axios } = useAxios();

  // state management
  const [organisationList, setOrganisationList] = useState([]);
  const [length, setLength] = useState();
  const [pageInfo, setPageInfo] = useState(paginationInfo);
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [editState, setEditState] = useState(false);
  const [iniFormState, setIniFormState] = useState(iniFormValues);
  const [addOrgani, setAddOrgani] = useState(false);
  const [iniState, setIniState] = useState([]);
  const [iniDistrict, setIniDistrict] = useState([]);
  const [iniTaluk, setIniTaluk] = useState([]);
  const [iniPanchayat, setIniPanchayat] = useState([]);
  const [iniDropDownDetails, setIniDropDownDetails] =
    useState(iniDropdownsState);
  const [organiAdded, setOrganiAdded] = useState(false);
  const [organiUpdated, setOrganiUpdated] = useState(false);
  const [currentOrganisation, setCurrentOrganisation] = useState("");

  // organisationList api
  useEffect(() => {
    try {
      async function callFunc() {
        const response = await axios.get(`organizationList`, {
          params: {
            limit: pageInfo.rowsPerPage,
            page: pageInfo.page,
            search: searchValue,
          },
        });
        console.log("organizationList response", response);
        setOrganisationList(response?.data?.result?.organizations);
        setLength(
          response?.data?.result?.length ? response?.data?.result?.length : 0
        );
        setPageInfo((prevState) => ({
          ...prevState,
          count: response?.data?.result?.length
            ? response?.data?.result?.length
            : 0,
        }));
      }
      callFunc();
    } catch (error) {
      toast.error("Something Went Wrong", {
        autoClose: 1500,
        transition: Slide,
      });
      setLength(0);
      setPageInfo(paginationInfo);
    }
  }, [
    pageInfo.rowsPerPage,
    pageInfo.page,
    searchValue,
    organiAdded,
    organiUpdated,
  ]);

  // state dropdown api call
  useEffect(() => {
    try {
      async function callFunc() {
        const response = await axios.post(`statesDropDown`);
        console.log("statesDropDown response", response);
        const { result, success } = response?.data;
        if (success) {
          setIniState(result);
        }
      }
      callFunc();
    } catch (error) {
      toast.error("Something Went Wrong", {
        autoClose: 1500,
        transition: Slide,
      });
    }
  }, [addOrgani]);

  // update form state and get updated values
  useEffect(() => {
    setValues(iniFormState);
  }, [iniFormState]);

  // get state based districts
  const getDistricts = async (state_id) => {
    try {
      if (state_id) {
        const response = await axios.post(`districtDropDown`, { state_id });
        console.log("districtDropDown response", response);
        const { result, success } = response?.data;
        if (success) {
          setIniDistrict(result);
        }
      }
    } catch (error) {
      toast.error("Something Went Wrong", {
        autoClose: 1500,
        transition: Slide,
      });
    }
  };

  // get district based taluk
  const getTaluk = async (district_id) => {
    try {
      if (district_id) {
        const response = await axios.post(`talukDropDown`, { district_id });
        console.log("talukDropDown response", response);
        const { result, success } = response?.data;
        if (success) {
          setIniTaluk(result);
        }
      }
    } catch (error) {
      toast.error("Something Went Wrong", {
        autoClose: 1500,
        transition: Slide,
      });
    }
  };

  // get taluk based panchayat
  const getPanchayat = async (taluk_id) => {
    try {
      if (taluk_id) {
        const response = await axios.post(`mandalDropDown`, { taluk_id });
        console.log("mandalDropDown response", response);
        const { result, success } = response?.data;
        if (success) {
          setIniPanchayat(result);
        }
      }
    } catch (error) {
      toast.error("Something Went Wrong", {
        autoClose: 1500,
        transition: Slide,
      });
    }
  };

  // formik form management
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: iniFormState,
    validationSchema: organisationValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log("hit FormSumit");
      const finalFormData = {
        organization_name: values.organisation_name,
        contact_person: values.contact_person,
        contact_number: values.contact_person_mob,
        address: values.address,
        state: values.state,
        state_name: iniDropDownDetails?.state_details?.state_name,
        district: values.district,
        district_name: iniDropDownDetails?.district_details?.district_name,
        taluk: values.taluk,
        taluk_name: iniDropDownDetails?.taluk_details?.taluk_name,
        panchayat: values.panchayat,
        panchayat_name: iniDropDownDetails?.panchayat_details?.mandal_name,
        status: values.status,
      };
      console.log("finalFormData", finalFormData);
      let response;
      if (!editState) {
        response = await axios.post(`oraganizationCreate`, finalFormData);
      } else {
        response = await axios.patch(
          `updateOrganization/${currentOrganisation}`,
          finalFormData
        );
      }
      const { success } = response?.data;
      if (success) {
        resetForm();
        setOrganiAdded(true);
        setOrganiUpdated(true);
        setOpen(false);
        setEditState(false);
        setCurrentOrganisation("");
      }
    },
  });

  // to manipoulate state dependency dropdown
  const handleState = (event) => {
    setIniDistrict([]);
    setIniTaluk([]);
    setIniPanchayat([]);

    // get state details
    const state = iniState.find(({ _id, ...rest }) => {
      if (_id == event.target.value) {
        return { _id, ...rest };
      }
    });
    setIniDropDownDetails((prevState) => ({
      ...prevState,
      state_details: state,
      district_details: {},
      taluk_details: {},
      panchayat_details: {},
    }));

    // update formik values
    values.district = "";
    values.taluk = "";
    values.panchayat = "";

    // trigger formik handleChange
    handleChange(event);
  };

  // to manipulate district dependency dropdown
  const handleDistrict = (event) => {
    setIniTaluk([]);
    setIniPanchayat([]);

    // get district details
    const district = iniDistrict.find(({ _id, ...rest }) => {
      if (_id == event.target.value) {
        return { _id, ...rest };
      }
    });
    setIniDropDownDetails((prevState) => ({
      ...prevState,
      district_details: district,
      taluk_details: {},
      panchayat_details: {},
    }));

    // update formik values
    values.taluk = "";
    values.panchayat = "";

    // trigger formik handleChange
    handleChange(event);
  };

  // to manipulate taluks dependency dropdown
  const handleTaluk = (event) => {
    setIniPanchayat([]);

    // get taluk details
    const taluk = iniTaluk.find(({ _id, ...rest }) => {
      if (_id == event.target.value) {
        return { _id, ...rest };
      }
    });
    setIniDropDownDetails((prevState) => ({
      ...prevState,
      taluk_details: taluk,
      panchayat_details: {},
    }));

    // update formik values
    values.panchayat = "";

    // trigger formik handleChange
    handleChange(event);
  };

  // to manupulate panchayat dropdown
  const handlePanchayat = (event) => {
    // get panchayat details
    const panchayat = iniPanchayat.find(({ _id, ...rest }) => {
      if (_id == event.target.value) {
        return { _id, ...rest };
      }
    });
    setIniDropDownDetails((prevState) => ({
      ...prevState,
      panchayat_details: panchayat,
    }));

    // trigger formik handleChange
    handleChange(event);
  };

  // edit data handlers
  const editData = (id) => {
    try {
      setCurrentOrganisation(id);
      setEditState(true);
      setOrganiAdded(false);
      setOrganiUpdated(false);
      const currentOrgani = organisationList?.find(({ _id }) => _id == id);

      console.log("currentOrganisation", currentOrgani);
      const {
        organization_name,
        contact_person,
        contact_number,
        address,
        state,
        district,
        taluk,
        panchayat,
        status,
      } = currentOrgani;

      getDistricts(state);
      getTaluk(district);
      getPanchayat(taluk);

      // get state details
      const states = iniState.find(({ _id, ...rest }) => {
        if (_id == state) {
          return { _id, ...rest };
        }
      });
      // get district details
      const districts = iniDistrict.find(({ _id, ...rest }) => {
        if (_id == district) {
          return { _id, ...rest };
        }
      });
      // get taluk details
      const taluks = iniTaluk.find(({ _id, ...rest }) => {
        if (_id == taluk) {
          return { _id, ...rest };
        }
      });
      // get panchayat details
      const panchayats = iniPanchayat.find(({ _id, ...rest }) => {
        if (_id == panchayat) {
          return { _id, ...rest };
        }
      });

      setIniDropDownDetails((prevState) => ({
        ...prevState,
        state_details: states,
        district_details: districts,
        taluk_details: taluks,
        panchayat_details: panchayats,
      }));

      setIniFormState((prevState) => ({
        ...prevState,
        organisation_name: organization_name,
        contact_person,
        contact_person_mob: contact_number,
        address,
        state,
        district,
        taluk,
        panchayat,
        status,
      }));
      setOpen(true);
    } catch (error) {
      console.log("errors", error);
      toast.error("Something Went Wrong", {
        autoClose: 1500,
        transition: Slide,
      });
    }
  };

  // delete data hanlers - as of now no use
  const deleteDate = () => {};

  // search handlers
  const handleSearchValue = (values) => {
    setPageInfo(paginationInfo);
    setSearchValue(values);
  };

  // handle Status
  const handleStatus = (event) => {
    setIniFormState((prevState) => ({
      ...prevState,
      status: event.target.value,
    }));
  };

  // organisation util handler
  const organisationHandler = () => {
    setOpen(true);
    setAddOrgani(true);
    setOrganiAdded(false);
    setOrganiUpdated(false);
    setIniFormState(iniFormValues);
  };
  const organisationCloseHandler = () => {
    setOpen(false);
    setIniFormState(iniFormValues);
    setCurrentOrganisation("");
    setEditState(false);
  };

  // prevent backDrop of dialog
  const organisationCloseHandlerPrevent = (reason) => {
    if (reason == "escapeKeyDown" || reason == "backdropClick") {
      setOpen(true);
    }
  };

  // upperCase converter for dropdowns
  const firstLetter = (value) => {
    return value?.replace(/^.{1}/g, value[0].toUpperCase());
  };

  // pagination handlers
  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };
  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };

  console.log("formik values", values);

  return (
    <>
      <div className="state_head">
        <Typography variant="h5" color="secondary" className="text-left">
          Organisation Master
        </Typography>
        {
          <Button
            variant="contained"
            sx={{ float: "right", m: 1, width: "10ch" }}
            onClick={() => organisationHandler()}
          >
            Add
          </Button>
        }
      </div>

      <TableComponent
        header={header}
        datalist={organisationList}
        datalength={length}
        actions={[]}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        handleTablePage={handleTablePage}
        handleTableRowsPerPage={handleTableRowsPerPage}
        editData={(id) => editData(id)}
        deleteData={(id) => deleteDate(id)}
        searchDataValue={(val) => handleSearchValue(val)}
        searchValue={() => {
          setSearchValue("");
          setPageInfo(paginationInfo);
        }}
      ></TableComponent>

      <Dialog
        open={open}
        fullWidth
        onClose={organisationCloseHandlerPrevent}
        maxWidth="md"
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>
            {editState ? "Edit Organisation" : "Add Organisation"}
          </DialogTitle>
          <DialogTitle>
            <CloseIcon onClick={organisationCloseHandler} />
          </DialogTitle>
        </div>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid
              sx={{ marginTop: "2%" }}
              container
              rowSpacing={2}
              columnSpacing={2}
            >
              <Grid item xs={6}>
                <TextField
                  type="text"
                  name="organisation_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.organisation_name}
                  id="select-label organisation_name"
                  variant="standard"
                  label="Organisation Name"
                  size="small"
                  fullWidth
                  error={
                    errors?.organisation_name && touched?.organisation_name
                  }
                  helperText={
                    errors?.organisation_name && touched?.organisation_name
                      ? errors?.organisation_name
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  name="contact_person"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.contact_person}
                  id="select-label contact_person"
                  variant="standard"
                  label="Contact Person"
                  size="small"
                  fullWidth
                  error={errors?.contact_person && touched?.contact_person}
                  helperText={
                    errors?.contact_person && touched?.contact_person
                      ? errors?.contact_person
                      : ""
                  }
                />
              </Grid>
            </Grid>
            <Grid
              sx={{ marginTop: "2%" }}
              container
              rowSpacing={2}
              columnSpacing={2}
            >
              <Grid item xs={6}>
                <TextField
                  type="text"
                  name="contact_person_mob"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.contact_person_mob}
                  id="select-label contact_person_mob"
                  variant="standard"
                  label="Contact Person Mobile"
                  size="small"
                  fullWidth
                  error={
                    errors?.contact_person_mob && touched?.contact_person_mob
                  }
                  helperText={
                    errors?.contact_person_mob && touched?.contact_person_mob
                      ? errors?.contact_person_mob
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  name="address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  id="select-label address"
                  variant="standard"
                  label="Address"
                  size="small"
                  fullWidth
                  error={errors?.address && touched?.address}
                  helperText={
                    errors?.address && touched?.address ? errors?.address : ""
                  }
                />
              </Grid>
            </Grid>
            <Grid
              sx={{ marginTop: "2%" }}
              container
              rowSpacing={2}
              columnSpacing={2}
            >
              <Grid item xs={6}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="select-label">Select State</InputLabel>
                  <Select
                    name="state"
                    labelId="select-label"
                    id="select"
                    variant="standard"
                    value={values.state}
                    defaultValue=""
                    onChange={handleState}
                    onBlur={(event) => {
                      getDistricts(event.target.value);
                    }}
                  >
                    {iniState?.map(({ _id, state_name }) => (
                      <MenuItem key={_id} value={_id}>
                        {firstLetter(state_name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors?.state && touched?.state ? (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.state}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="select-label">Select District</InputLabel>
                  <Select
                    name="district"
                    labelId="select-label"
                    id="select"
                    variant="standard"
                    value={values.district}
                    defaultValue=""
                    onChange={handleDistrict}
                    onBlur={(event) => getTaluk(event.target.value)}
                  >
                    {iniDistrict?.map(({ _id, district_name }) => (
                      <MenuItem key={_id} value={_id}>
                        {firstLetter(district_name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors?.district && touched?.district ? (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.district}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid
              sx={{ marginTop: "2%" }}
              container
              rowSpacing={2}
              columnSpacing={2}
            >
              <Grid item xs={6}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="select-label">Select Taluk</InputLabel>
                  <Select
                    name="taluk"
                    labelId="select-label"
                    id="select"
                    variant="standard"
                    value={values.taluk}
                    defaultValue=""
                    onChange={handleTaluk}
                    onBlur={(event) => getPanchayat(event.target.value)}
                  >
                    {iniTaluk?.map(({ _id, taluk_name }) => (
                      <MenuItem key={_id} value={_id}>
                        {firstLetter(taluk_name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors?.taluk && touched?.taluk ? (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.taluk}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="select-label">Select Panchayat</InputLabel>
                  <Select
                    name="panchayat"
                    labelId="select-label"
                    id="select"
                    variant="standard"
                    value={values.panchayat}
                    defaultValue=""
                    onChange={handlePanchayat}
                  >
                    {iniPanchayat?.map(({ _id, mandal_name }) => (
                      <MenuItem key={_id} value={_id}>
                        {firstLetter(mandal_name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors?.panchayat && touched?.panchayat ? (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.panchayat}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Status
                  </InputLabel>
                  <Select
                    name="status"
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={values.status}
                    onChange={handleStatus}
                    label="Status"
                    disabled={!editState ? true : false}
                  >
                    <MenuItem value={"active"}>Active</MenuItem>
                    <MenuItem value={"inactive"}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <div className="submit">
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  type="button"
                  color="primary"
                  onClick={organisationCloseHandler}
                >
                  Close
                </Button>
                <Button color="primary" type="submit" variant="contained">
                  {editState ? "Edit Organisation" : "Add Organisation"}
                </Button>
              </Stack>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
