import React from 'react'
// import { Chart } from "react-google-charts";
import GaugeChart from "react-gauge-chart";

const InduvidualHome = (props) => {


  const { visionCenter } = props
  console.log('props', props);

  const data = [
    ["Vision Center", "Count"],
    ["To Vision Center", visionCenter?.patient_reported_vc],
    ["To Vision Camp", visionCenter?.patient_referred_camp],
    ["To  Base Hospital", visionCenter?.patient_referred_BH],
    ["Received Glass", visionCenter?.patient_received_glass],
  ];

  const options = {
    slices: { 0: { color: '#00B0F0' }, 1: { color: '#92D050' }, 2: { color: '#6CE1CB' }, 3: { color: '#FFDC00' }, 4: { color: 'Olive' } },
  };
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
        <p style={{ margin: ' 10px', fontWeight: 'bold' }}>Vision Center Performance</p>
        <p style={{ margin: '10px' }} >No. of Patient Referred to vision center : {visionCenter?.patient_referred_vc} </p>
      </div>

      <div style={{  height: '80%',display:'flex',flexDirection:'column',justifyContent:'space-evenly' }}>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>

          <div  >
            <span style={{ fontSize: '0.7rem' }}> Patient Reported to Vision Center   </span>
            <GaugeChart
              id="gauge-chart2"
              nrOfLevels={1}
              colors={['#00B0F0']}
              arcWidth={0.1}
              percent={visionCenter != undefined && visionCenter?.patient_reported_vc !== 0 ? (Math.ceil(visionCenter?.patient_reported_vc / visionCenter?.patient_referred_vc) / 100) : 0}
              textColor={'black'}
              style={{ width: '12vw' }}
            // hideText={true} // If you want to hide the text
            />
          </div>

          <div >
            <span style={{ fontSize: '0.7rem', marginLeft: '10%' }}>
              Patient Refered to camp
            </span>
            <GaugeChart
              id="gauge-chart2"
              nrOfLevels={1}
              colors={['#92D050']}
              arcWidth={0.1}
              percent={visionCenter != undefined && visionCenter?.patient_referred_camp !== 0 ? (Math.ceil(visionCenter?.patient_referred_camp / visionCenter?.patient_reported_vc) / 100) : 0}
              // percent={visionCenter?.patient_referred_camp !== 0 ? (Math.ceil(visionCenter?.patient_referred_camp / visionCenter?.patient_reported_vc) / 100) : 0}
              textColor={'black'}
              style={{ width: '12vw' }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>

          <div>
            <span style={{ fontSize: '0.7rem' }}> Patient Refered to Base Hospital</span>
            <GaugeChart
              id="gauge-chart2"
              nrOfLevels={1}
              colors={['#6CE1CB']}
              arcWidth={0.1}
              // percent={visionCenter?.patient_referred_BH !== 0 ? (Math.ceil(visionCenter?.patient_referred_BH / visionCenter?.patient_referred_camp) / 100) : 0}
              percent={visionCenter != undefined && visionCenter?.patient_referred_BH !== 0 ? (Math.ceil(visionCenter?.patient_referred_BH / visionCenter?.patient_referred_camp) / 100) : 0}
              textColor={'black'}
              style={{ width: '12vw' }}
            // hideText={true} // If you want to hide the text
            />
          </div>

          <div>
            <span style={{ fontSize: '0.7rem', marginLeft: '10%' }}> Patient Recived Glass</span>
            <GaugeChart
              id="gauge-chart2"
              nrOfLevels={1}
              colors={['#FFDC00']}
              arcWidth={0.1}
              // percent={visionCenter?.patient_received_glass !== 0 ? (Math.ceil(visionCenter?.patient_received_glass / visionCenter?.patient_referred_BH) / 100) : 0}
              percent={visionCenter != undefined && visionCenter?.patient_received_glass !== 0 ? (Math.ceil(visionCenter?.patient_received_glass / visionCenter?.patient_referred_BH) / 100) : 0}
              textColor={'black'}
              style={{ width: '12vw' }}
            // hideText={true} // If you want to hide the text
            />
          </div>
        </div>
      </div>

    </div>
  )
}

export default InduvidualHome