import { React, useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast, Slide } from "react-toastify";
import {
  Dialog,
  Button,
  CardContent,
  Typography,
  FormControlLabel,
  ListItemButton,
  Divider,
  Stack,
  DialogTitle,
} from "@mui/material";
import { useAxios } from "../hooks/useAxios";
import TableComponent from "../components/TableLayout";
import ConfirmPopup from "../components/confirmPopup";
import { StateSchema, RoleSchema } from "../validations/userSchema";
import { Form, Formik } from "formik";
import { HybridInput, HybridSelect } from "../components/hybrid_inputs";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { ArrowRight, KeyboardArrowDown } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function Roles_management() {
  const [length, setLength] = useState();
  const [searchValue, setSearchValue] = useState("");
  let paginationInfo = {
    page: 1,
    count: 0,
    rowsPerPage: 10,
  };
  const [pageInfo, setPageInfo] = useState(paginationInfo);
  const [DropDown] = useState([
    {
      _id: 1,
      status: "Active",
    },
    {
      _id: 0,
      status: "Inactive",
    },
  ]);
  const [type] = useState({
    2: [
      {
        role_id: 3,
        role_name: "VHV",
      },
      {
        role_id: 4,
        role_name: "Volunteers",
      },
      {
        role_id: 5,
        role_name: "Optometrist",
      },
      {
        role_id: 6,
        role_name: "Campsite Registration",
      },
    ],
    1: [
      {
        role_id: 1,
        role_name: "superAdmin",
      },
      {
        role_id: 2,
        role_name: "Admin",
      },
      {
        role_id: 7,
        role_name: "Unit level admin",
      },
    ],
  });

  // const screen = [
  //   { _id: 0, screen_name: "one", action: [{ id: 0, name: 'a', isChecked: false }, { id: 1, name: 'd', isChecked: false }, { id: 2, name: 'u', isChecked: false }], isChecked: false },
  //   { _id: 1, screen_name: "two", action: [{ id: 0, name: 'a', isChecked: false }, { id: 1, name: 'd', isChecked: false }, { id: 2, name: 'u', isChecked: false }], isChecked: false },
  //   { _id: 2, screen_name: "three", action: [{ id: 0, name: 'a', isChecked: false }, { id: 1, name: 'd', isChecked: false }, { id: 2, name: 'u', isChecked: false }], isChecked: false }
  // ]

  const { axios } = useAxios();
  const [activeId, setActiveId] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [editState, setEditState] = useState(false);
  const [popup, setpopup] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [mobileScreenList, setMobileScreenList] = useState([]);
  // const [mobileScreenList, setMobileScreenList] = useState(screen);
  const [select, setSelect] = useState([]);
  const [open, setOpen] = useState("");
  const [initialValues, setInitialValue] = useState({
    type: "",
    role_id: "",
    permission: [],
    status: "",
  });
  const [editRoles, seteditRoles] = useState([]);

  const [child, setChild] = useState();
  const [activePermission, setActivepermission] = useState([]);
  const [selectedtype, setSelectedType] = useState(1);
  const [header] = useState([
    {
      id: 1,
      label: "Role Name",
      value: "role_name",
    },
    {
      id: 2,
      label: "Role ID",
      value: "role_id",
    },
    // {
    //   id: 3,
    //   label: "Status",
    //   value: "status",
    // },
    {
      id: 4,
      label: "Action",
      value: "action",
    },
  ]);

  const [permission, setPermission] = useState([]);

  useEffect(
    (values) => {
      RoleList();
      MobileScreenList();
    },
    [pageInfo.rowsPerPage, pageInfo.page, searchValue]
  );

  const handleSearchValue = (values) => {
    setPageInfo(paginationInfo);
    setSearchValue(values);
  };

  const RoleList = () => {
    axios
      .post(
        `roleList?role_name=${searchValue}&limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}&search=${searchValue}`
      )
      .then((data) => {
        setRoleList(data.data.result.list);
        setLength(data.data.result.length ? data.data.result.length : 0);
        setPageInfo((prevState) => ({
          ...prevState,
          count: data.data.result.length ? data.data.result.length : 0,
        }));
      })
      .catch(() => {
        setRoleList([]);
        setLength(0);
        setPageInfo(paginationInfo);
      });
  };

  const MobileScreenList = () => {
    axios.post(`ScreenList`, { type: selectedtype }).then((data) => {
      var updated_check = data.data.result.map((i) => {
        return {
          ...i,
          isChecked: false,
        };
      });

      setMobileScreenList(updated_check);
    });
  };
  const addStates = (actions) => {
    setEditState(false);
    reset();
    // actions.resetForm();
    setpopup(true);
  };

  const reset = () => {
    setInitialValue({ role_name: "", role_id: "", status: "" });
    setActivepermission([]);
  };

  async function editData(data) {
    setEditState(true);
    for (const list of roleList) {
      if (list._id === data) {
        setActiveId(list._id);
        setInitialValue({ ...initialValues, ...list });
        setSelectedType(list.type);

        const permissionlist = await axios
          .post(`ScreenList`, { type: list.type })
          .then((data) => {
            var updated_check = data.data.result.map((i) => {
              return {
                ...i,
                isChecked: false,
              };
            });
            return updated_check;
          });

        var edit_permission = list.permission;

        const newArray = permissionlist.map((list) => {
          let smallListPermissionN = list.screen_name
            .replace(/_/g, "")
            .replace(/\s+/g, "")
            .toLowerCase();

          const matchedItem = edit_permission.find(
            (x) =>
              x.screen_name
                .replace(/_/g, "")
                .replace(/\s+/g, "")
                .toLowerCase() === smallListPermissionN
          );
          if (matchedItem) {
            return {
              ...matchedItem,
              isChecked: true,
            };
          }
          return list;
        });

        setMobileScreenList(newArray);
        setPermission(list.permission); //-> purusoth
        // setActivepermission(list.permission);    // -> saran
        setpopup(true);
      }
    }
  }

  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };
  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };
  const deleteDate = (data) => {
    setActiveId(data);
    setDeletePopup(true);
  };
  function deleteCancel(params) {
    setDeletePopup(false);
  }
  function deletesubmit(params) {
    setDeletePopup(false);
    let insertData = {
      id: activeId,
    };
    axios.post(`roleDelete`, insertData).then((data) => {
      if (data.data.success === true) {
        toast.success("Deleted sucessfully", {
          autoClose: 3000,
          transition: Slide,
        });
        RoleList();
      }
    });
  }
  const handleClose = () => {
    setpopup(false);
  };

  // prevent backDrop of dialog
  const handleClosePrevent = (reason) => {
    if (reason == "escapeKeyDown" || reason == "backdropClick") {
      setpopup(true);
    }
  };

  const handleSubmit = (values) => {
    let role_id = values.role_id;

    values["permission"] = permission;
    let val = { ...values, ...values._id };

    // if (role_id == 1 || role_id == 2 || role_id == 7) {
    //   if (role_id == 1) {
    //     values['role_name'] = 'superAdmin'
    //   } else {
    //     values['role_name'] = 'admin'
    //   }
    // }
    //  else {
    //   var role = type?.find((i) => { if (i.role_id == values.role_id) { return i } })
    //   values["role_name"] = role.role_name;
    // }

    var role = type[selectedtype]?.find((i) => {
      if (i.role_id == values.role_id) {
        return i;
      }
    });
    values["role_name"] = role.role_name;

    delete val._id;
    if (editState === false) {
      axios.post(`roleCreate`, val).then((data) => {
        if (data.data.success === true) {
          setpopup(false);
          RoleList();
        }
      });
    } else {
      let insertData = {
        role_name: values.role_name,
        role_id: values.role_id,
        permission: permission,
        status: values.status,
        id: activeId,
      };
      axios.post(`roleUpdated`, insertData).then((data) => {
        if (data.data.success === true) {
          setpopup(false);
          RoleList();
        }
      });
    }
  };

  const handleChange = (event, value) => {
    var selected_id = mobileScreenList.findIndex((i) => i._id == value._id);

    var val = permission?.findIndex((item) => item._id === value._id);
    if (event.target.checked) {
      if (val < 0) {
        var b = {
          _id: value._id,
          screen_name: value?.screen_name,
          action: value?.action,
          display_type: value?.display_type,
        };
        permission.push(b);
      }
      // else {
      //   permission[val].action = array_n
      // }
    } else {
      permission.splice(val, 1);
    }

    setMobileScreenList((prevState) => {
      const updatedScreens = prevState.map((i, index) =>
        index === selected_id // (i) => i.isChecked = event.target.checked)
          ? { ...i, isChecked: event.target.checked }
          : i
      );
      return updatedScreens;
    });

    // else if (i == 'child') {
    //   var check = { _id: value._id, screen_name: value.screen_name, action: [] }

    //   if (permission == "") {
    //     permission.push(check)
    //   }

    //   var indexs = permission?.findIndex((item) => item._id === value._id)

    //   if (event.target.checked) {
    //     if (indexs < 0) {
    //       permission.push(check);
    //       indexs = permission?.findIndex((item) => item._id === value._id)
    //     }
    //     permission[indexs].action.push(sub_action.name)
    //   } else {
    //     var sub = permission[indexs].action.findIndex((i) => i === sub_action.name);

    //     if (sub >= 0) { permission[indexs].action.splice(sub, 1) }
    //   }

    //   setMobileScreenList((prevState) => {
    //     const updatedScreens = prevState.map((i, index) => (
    //       index === selected_id
    //         ? {
    //           ...i, isChecked: false, action: i.action.map((item, ind) => (ind === sub_action.id ? { ...item, isChecked: event.target.checked } : item))
    //         } : i
    //     ));
    //     return updatedScreens;
    //   })

    // }
  };

  function alterScreenName(name) {
    var menu_name = name.replaceAll("_", " ");
    var name = menu_name?.replace(/^.{1}/g, menu_name[0].toUpperCase());
    return name;
  }

  return (
    <div>
      <div className="state_head" style={{ marginBottom: "1rem" }}>
        <Typography variant="h5" color="secondary" className="text-left">
          Role Management
        </Typography>
        {/* <Button variant="contained" sx={{ float: "right", m: 1, width: "10ch" }} onClick={() => addStates()} >  Add</Button> */}
      </div>

      <TableComponent
        header={header}
        // addList={addStates}
        datalist={roleList}
        datalength={length}
        editData={(id) => editData(id)}
        deleteData={(id) => deleteDate(id)}
        pageInfo={pageInfo}
        actions={[]}
        setPageInfo={setPageInfo}
        handleTablePage={handleTablePage}
        handleTableRowsPerPage={handleTableRowsPerPage}
        searchDataValue={(val) => handleSearchValue(val)}
        searchValue={() => {
          setSearchValue("");
          setPageInfo(paginationInfo);
        }}
      ></TableComponent>
      {deletePopup ? (
        <ConfirmPopup
          message={"Are you sure, you want to delete this record"}
          deleteCancel={deleteCancel}
          deleteSubmit={deletesubmit}
        ></ConfirmPopup>
      ) : (
        ""
      )}
      <Dialog
        open={popup}
        scroll="paper"
        fullWidth={true}
        onClose={handleClosePrevent}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle style={{ marginLeft: "12rem" }}>Add Role</DialogTitle>
          <DialogTitle>
            <CloseIcon onClick={handleClose} />
          </DialogTitle>
        </div>{" "}
        <Formik
          initialValues={initialValues}
          validationSchema={RoleSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <CardContent>
                {/* <HybridInput
                  label="Role name"
                  name="role_name"
                  fullWidth
                  type="text"
                  variant="standard"
                /> */}
                <FormControl fullWidth>
                  {/* <InputLabel >Type</InputLabel> */}
                  <Select
                    value={selectedtype}
                    onChange={(e) => {
                      setSelectedType(e.target.value);
                    }}
                    name="type"
                    variant="standard"
                    disabled={editState}
                  >
                    <MenuItem value={1}>Admin</MenuItem>
                    <MenuItem value={2}>Mobile</MenuItem>
                  </Select>
                </FormControl>

                <HybridSelect
                  label="Role Name"
                  name="role_id"
                  fullWidth
                  defaultValue={""}
                  type="text"
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  variant="standard"
                  setVal={setFieldValue}
                  options={type[selectedtype]}
                  dropKey="role_name"
                  dropVal="role_id"
                />
                {/* <HybridSelect
                  label="Status"
                  name="status"
                  fullWidth
                  type="text"
                  defaultValue={""}
                  setVal={setFieldValue}
                  variant="standard"
                  options={DropDown}
                  dropKey="status"
                  dropVal="_id"
                /> */}
                <FormControl
                  style={{ marginTop: "3%", width: "100%", border: "none" }}
                >
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{ color: "#ffffff", textAlign: "center" }}
                          >
                            Screen Name
                          </TableCell>
                          <TableCell
                            sx={{ color: "#ffffff", textAlign: "center" }}
                          >
                            Enable/Disable
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {mobileScreenList?.map((table, index) => (
                          <TableRow>
                            <TableCell sx={{ textAlign: "center" }}>
                              {" "}
                              {alterScreenName(table?.screen_name)}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              <Checkbox
                                onChange={(e) => {
                                  handleChange(e, table);
                                }}
                                checked={table.isChecked}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </FormControl>
                <div className="submit">
                  <Stack direction="row" spacing={2}>
                    <Button
                      color="primary"
                      type="button"
                      variant="contained"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    <Button color="primary" type="submit" variant="contained">
                      {editState ? "Update" : "Add"}
                    </Button>
                  </Stack>
                </div>
              </CardContent>
            </Form>
          )}
        </Formik>
      </Dialog>
      {/* <Dialog
        open={popup}
        scroll="paper"
        fullWidth={true}
        onClose={handleClose}
      >
        <TextField id="standard-basic" label="Role Name" variant="standard" />
        <TextField id="standard-basic" label="Role id" variant="standard" />
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={activePermission}
            onChange={handleChange}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) =>
              selected.map((x) => x.screen_name).join(", ")
            }
            MenuProps={MenuProps}
          >
            {mobileScreenList.map((variant, index) => (
              <MenuItem key={variant._id} value={variant}>
                <Checkbox
                  checked={
                    activePermission.findIndex(
                      (item) => item._id === variant._id
                    ) >= 0
                  }
                />
                <ListItemText primary={variant.screen_name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Dialog> */}
    </div>
  );
}

export default Roles_management;
