import { TextField, Stack, Button } from "@mui/material";
import React, { useState } from "react";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const ReferralFilter = ({ filterObject }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [referralName, SetReferralName] = useState("");

  const handleSubmit = () => {
    let obj = {
      fromDate: startDate !== "" ? startDate.$d : "",
      toDate: endDate !== "" ? endDate.$d : "",
      hospital_name: hospitalName,
      referral_name: referralName,
    };
    filterObject(obj);
  };

  const handleClose = () => {
    let obj = {
      fromDate: "",
      toDate: "",
      hospital_name: "",
      referral_name: "",
    };
    filterObject(obj);
    setHospitalName("");
    SetReferralName("");
  };

  return (
    <div style={{ marginBottom: "2vh" }}>
      <Stack direction="row" spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="From Date"
            renderInput={(props) => <TextField {...props} />}
            defaultValue=""
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="To Date"
            renderInput={(props) => <TextField {...props} />}
            defaultValue=""
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
          />
        </LocalizationProvider>
        <TextField
          variant="outlined"
          label="Hospital Name"
          value={hospitalName}
          onChange={(e) => setHospitalName(e.target.value)}
        />
        <TextField
          variant="outlined"
          label="Referral Name"
          value={referralName}
          onChange={(e) => SetReferralName(e.target.value)}
        />
        <Button
          sx={{ textTransform: 'capitalize' }}
          variant="contained"
          style={{ width: "11%" }}
          onClick={handleSubmit}
        >
          Search
        </Button>
        <Button
          sx={{ textTransform: 'capitalize' }}
          variant="contained"
          style={{ width: "11%"   }}
          onClick={handleClose}
        >
          Reset
        </Button>
      </Stack>
    </div>
  );
};
export default ReferralFilter;
