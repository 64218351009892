import React, { useState, useEffect, useRef } from "react";

import { FallingLines } from "react-loader-spinner";
import {
  Dialog,
  Button,
  CardContent,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import TableComponent from "../components/TableLayout";
import { useAxios } from "../hooks/useAxios";
import DynamicInput from "../components/dy_input";
import { HybridInput, HybridSelect } from "../components/hybrid_inputs";
import { MandalSchema } from "../validations/userSchema";
import { Form, Formik, Field } from "formik";
// import { toast, Slide } from "react-toastify";
import ConfirmPopup from "../components/confirmPopup";
import Stack from "@mui/material/Stack";
import LowerCase from "./LowerCase";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelUplaod from "../components/ExcelFormat/Excelfileupload";
import ExcelDownload from "../components/ExcelFormat/ExcelFileDownload";
import Lowercase from "./LowerCase";
import { toast, Slide } from "react-toastify";

export const Council = () => {
  const [header] = useState([
    {
      id: 1,
      label: "Subdivison",
      value: "taluk_name",
    },
    {
      id: 2,
      label: "Municipal Corporation/Gram Panchayat",
      value: "mandal_name",
    },
    // {
    //   id: 2,
    //   label: "Block Name",
    //   value: "block_name",
    // },
    // {
    //   id: 3,
    //   label: "Status",
    //   value: "status",
    // },
    {
      id: 4,
      label: "Action",
      value: "action",
    },
  ]);
  const { axios } = useAxios();
  const uploadRef = useRef();
  const [popup, setPopup] = useState(false);
  const [length, setLength] = useState();
  const [activeId, setActiveId] = useState("");
  const [editState, setEditState] = useState(false);
  const [searchValue, setSearchValue] = React.useState("");

  const [selectedValues, setSelectedValues] = useState(null);
  const [districtVal, setDistrictval] = useState(null);
  const [talukVal, setTalukval] = useState(null);
  const [loading, setLoading] = useState(false);
  const [DropDown] = useState([
    {
      _id: 1,
      status: "Active",
    },
    {
      _id: 0,
      status: "Inactive",
    },
  ]);
  const [initialValues, setInitialValue] = useState({
    taluk_id: "",
    mandal_name: "",
    block_name: "",
    status: "",
    state_id: "",
    district_id: "",
  });

  const [mandalList, setMandalList] = useState([]);
  const [talukList, setTalukList] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [mandalValue, setMandalValue] = useState("");
  const [blockValue, setBlockValue] = useState("");
  let paginationInfo = {
    page: 1,
    count: 0,
    rowsPerPage: 10,
  };
  const [pageInfo, setPageInfo] = useState(paginationInfo);

  useEffect(
    (values) => {
      axios.post("statesDropDown").then((res) => {
        let result = Lowercase(res.data.result, "state_name");
        setStateList(result);
      });
      MandalList();
      // TalukList();
    },
    [pageInfo.rowsPerPage, pageInfo.page, searchValue]
  );

  useEffect(() => {}, [districtList]);

  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };
  const reset = () => {
    setInitialValue({ taluk_id: "", mandal_name: "", status: "" });
    setMandalValue("");
    setBlockValue("");
  };
  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };
  const MandalList = () => {
    axios
      .post(
        `mandalList?limit=${pageInfo.rowsPerPage}&page=${pageInfo.page}&search=${searchValue}`
      )
      .then((data) => {
        setMandalList(data.data.result.list);
        setLength(data.data.result.length ? data.data.result.length : 0);
        setPageInfo((prevState) => ({
          ...prevState,
          count: data.data.result.length ? data.data.result.length : 0,
        }));
      })
      .catch(() => {
        setMandalList([]);
        setLength(0);
        setPageInfo(paginationInfo);
      });
  };
  // const TalukList = () => {
  //   axios.post(`AllTalukDropdown`).then((data) => {
  //     let result = LowerCase(data.data.result, "taluk_name");
  //     setTalukList(result);
  //   });
  // };
  const addStates = () => {
    setEditState(false);
    reset();
    setPopup(true);
  };
  const handleClose = () => {
    setPopup(false);
  };

  // prevent backDrop of dialog
  const handleClosePrevent = (reason) => {
    if (reason == "escapeKeyDown" || reason == "backdropClick") {
      setPopup(true);
    }
  };

  const handleSearchValue = (values) => {
    setPageInfo(paginationInfo);
    setSearchValue(values);
  };

  /*---- bulk file upload call  for Excel --- */

  async function handleImport(event) {
    // const filedata = await ExcelUplaod(event);

    // console.log('file',filedata);

    // const unique = [...new Map(filedata?.data.map((m) => [m.panchayat, m])).values()];

    // let dataExcel = unique?.map(
    //   ({ StateName: state_name, DistrictName: district_name, Subdivision: taluk_name, panchayat: mandal_name }) => ({
    //     state_name,
    //     district_name,
    //     taluk_name,
    //     mandal_name,
    //   })
    // );
    // axios.post(`mandalCreateMany`, dataExcel).then((res) => {
    //   if (res.data.success === true) {
    //     setPopup(false);
    //     MandalList();
    //   }
    // });
    try {
      setLoading(true); // Show the loader while uploading
      const filedata = await ExcelUplaod(event);

      const unique = [
        ...new Map(filedata?.data.map((m) => [m.panchayat, m])).values(),
      ];

      const batchSize = 1000;
      const totalItems = unique.length;
      const batches = [];
      for (let i = 0; i < totalItems; i += batchSize) {
        batches.push(unique.slice(i, i + batchSize));
      }

      const uploadBatch = async (dataExcel) => {
        await axios.post(`mandalCreateMany`, dataExcel).then((res) => {
          if (res.data.success === true) {
            setPopup(false);
            MandalList();
          } else if (
            res.data.success === false &&
            res.data.internalStatus == 0
          ) {
            res.data?.result?.map((user) =>
              toast.error(`${user.message}`, {
                autoClose: 5000,
                transition: Slide,
              })
            );
          }
        });
      };

      for (const batch of batches) {
        let dataExcel = batch.map(
          ({
            StateName: state_name,
            DistrictName: district_name,
            Subdivision: taluk_name,
            panchayat: mandal_name,
          }) => ({
            state_name,
            district_name,
            taluk_name,
            mandal_name,
          })
        );
        await uploadBatch(dataExcel);
      }
    } catch (error) {
      // Handle any error that occurred during file uploading
      console.error("Error while uploading:", error);
    } finally {
      setLoading(false); // Hide the loader after uploading

      uploadRef.current.value = ""; // Reset the input field after uploading
    }
  }

  /*------------------------ Excel Download ---------------*/
  const handleuploadExcel = () => {
    const columns = [
      { StateName: "", DistrictName: "", Subdivision: "", panchayat: "" },
    ];
    ExcelDownload(columns, "PanchayatTemplate");
  };

  const handleSubmit = (values) => {
    var district = districtList.find((i) => {
      if (i._id == values.district_id) {
        return i;
      }
    });

    var state = stateList.find((i) => {
      if (i._id == values.state_id) {
        return i;
      }
    });

    var taluk = talukList.find((i) => {
      if (i._id == values.taluk_id) {
        return i;
      }
    });
    if (editState === false) {
      values.district_name = districtVal.district_name;
      values.district_id = districtVal._id;
      values.state_id = selectedValues._id;
      values.state_name = selectedValues.state_name;

      values.taluk_name = talukVal.taluk_name;
      values.taluk_id = talukVal._id;
      axios.post(`mandalCreate`, values).then((data) => {
        if (data.data.success == true) {
          setInitialValue({ mandalValue: "", blockValue: "" });
          setPopup(false);
          MandalList();
        }
      });
    } else {
      let insertData = {
        district_name: district.district_name,
        district_id: values.district_id,
        state_id: values.state_id,
        state_name: state.state_name,
        taluk_name: taluk.taluk_name,
        taluk_id: values.taluk_id,
        mandal_name: values.mandal_name,
        status: values.status,
        _id: activeId,
      };

      axios.post(`mandalUpdate`, insertData).then((data) => {
        if (data.data.success === true) {
          setPopup(false);
          // toast.success("Mandal Updated Successfully", {
          //   autoClose: 3000,
          //   transition: Slide,
          // });
          MandalList();
          setInitialValue({ mandalValue: "", blockValue: "" });
        }
      });
    }
  };

  const editData = (data) => {
    setEditState(true);
    mandalList.forEach((list) => {
      if (list._id === data) {
        setActiveId(list._id);

        var state = "";
        stateList?.map((x) => {
          if (x._id == list.state_id) {
            state = x;
          }
        });

        var district = {
          _id: list.district_id,
          district_name: list?.district_name,
        };
        var taluk = { _id: list.taluk_id, taluk_name: list?.taluk_name };

        var val = { _id: list.state_id, state_name: list.state_name };

        handleStateDistrict(val);
        handleDistrict(district);

        setSelectedValues(state);
        setDistrictval(district);
        setTalukval(taluk);

        var form = {
          status: list?.status,

          state_id: state?._id,
          state_name: state?.state_name,

          district_id: district?._id,
          district_name: district?.district_name,

          taluk_id: list?.taluk_id,
          taluk_name: list?.taluk_name,

          mandal_name: list?.mandal_name,
        };

        var forms = { ...initialValues, ...form };

        setInitialValue(forms);
        setPopup(true);
      }
    });
  };

  const deleteDate = (data) => {
    setActiveId(data);
    setDeletePopup(true);
  };
  function deleteCancel(params) {
    setDeletePopup(false);
  }
  function deleteSubmit(params) {
    setDeletePopup(false);
    let insertData = {
      _id: activeId,
    };
    axios.post(`mandalDelete`, insertData).then((data) => {
      MandalList();
      // setStateList(data.data.data);
    });
  }
  const handleMandal = (e) => {
    setMandalValue(e);
  };
  const handleBlock = (e) => {
    setBlockValue(e);
  };
  function SearchFunction(params) {}

  async function handleStateDistrict(value) {
    /*---------- getting district for drop down -----------*/
    try {
      var data = {
        state_id: value?._id,
      };
      await axios.post(`districtDropDown`, data).then((data) => {
        let result = Lowercase(data.data.result, "district_name");
        setDistrictList(result);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDistrict(value) {
    try {
      var data = {
        district_id: value?._id,
      };
      await axios.post(`talukDropDown`, data).then((data) => {
        let result = Lowercase(data.data.result, "taluk_name");
        setTalukList(result);
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      {/* <div className="state_head" >
        <Typography variant="h4" color="secondary" className="text-left">
         Panchayat Management
        </Typography>
        <Button variant="contained" sx={{ float: "right", m: 1, width: "10ch" }} onClick={() => addStates()} >  Add</Button>
      </div> */}

      <div className="state_head">
        <Typography variant="h5" color="secondary" className="text-left">
          Panchayat Management
        </Typography>
        <div>
          <Button
            style={{ marginLeft: "-6%" }}
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={handleuploadExcel}
          >
            Template
          </Button>

          <Button
            sx={{ m: 1, width: "13ch", marginLeft: "5%" }}
            variant="contained"
            component="label"
            startIcon={<FileUploadIcon />}
          >
            Upload
            <input
              hidden
              ref={uploadRef}
              type="file"
              name="file"
              className="custom-file-input"
              id="inputGroupFile"
              required
              onChange={handleImport}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </Button>
          {loading && (
            <div className="loader">
              <FallingLines
                color="#ff7100"
                width="100"
                visible={true}
                ariaLabel="falling-lines-loading"
              />
            </div>
          )}
          {/* {loading && <div>Loading...</div>} Render the loader when `loading` is true */}
          {/* <Button
            sx={{ m: 1, width: "13ch", marginLeft: '5%' }}
            variant="contained"
            component="label"
            startIcon={<FileUploadIcon />}
          >
            Upload
            <input
              hidden
              ref={uploadRef}
              type="file"
              name="file"
              className="custom-file-input"
              id="inputGroupFile"
              required
              onChange={handleImport}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </Button> */}
          <Button
            variant="contained"
            sx={{ float: "right", m: 1, width: "10ch" }}
            onClick={() => addStates()}
          >
            {" "}
            Add
          </Button>
        </div>
      </div>

      <TableComponent
        header={header}
        // addList={addStates}
        Editable="false"
        datalist={mandalList}
        datalength={length}
        actions={["edit", "delete"]}
        editData={(id) => editData(id)}
        deleteData={(id) => deleteDate(id)}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        handleTablePage={handleTablePage}
        handleTableRowsPerPage={handleTableRowsPerPage}
        searchDataValue={(val) => handleSearchValue(val)}
        searchValue={() => {
          setSearchValue("");
          setPageInfo(paginationInfo);
        }}
      ></TableComponent>
      {deletePopup ? (
        <ConfirmPopup
          message={"Are you sure, you want to delete this record"}
          deleteCancel={deleteCancel}
          deleteSubmit={deleteSubmit}
        ></ConfirmPopup>
      ) : (
        ""
      )}
      <Dialog
        open={popup}
        scroll="paper"
        fullWidth={true}
        onClose={handleClosePrevent}
      >
        {" "}
        <Formik
          initialValues={initialValues}
          validationSchema={MandalSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <CardContent>
                <CloseIcon
                  onClick={handleClose}
                  style={{ marginLeft: "33rem" }}
                />
                {/* <HybridSelect
                  label="SubDivison"
                  name="taluk_id"
                  fullWidth
                  type="text"
                  variant="standard"
                  options={talukList}
                  setVal={setFieldValue}
                  dropKey="taluk_name"
                  dropVal=""

                /> */}

                {/* 
                <Autocomplete
                  size="small"
                  disablePortal
                  className="hide"
                  id="combo-box-demo"
                  sx={{
                    backgroundColor: '#ffffff',
                    borderRadius: '5px',
                    width: '100%',
                    margin: '0',
                    minHeight: '50px'
                  }}
                  options={stateList?.length > 0 ? stateList : [{ "state_name": "No data found" }]}
                  getOptionLabel={(option) => option.state_name}
                  onChange={(event, value) => {
                    handleStateDistrict(value);
                  }}
                  renderInput={(params) => <TextField
                    variant='standard'
                    {...params} color="black" InputLabelProps={{
                      style: {
                        paddingTop: '5px'
                      }
                    }} label="Select State" />}
                />


                <Autocomplete
                  size="small"
                  disablePortal
                  className="hide"
                  id="combo-box-demo"
                  sx={{
                    backgroundColor: '#ffffff',
                    borderRadius: '5px',
                    width: '100%',
                    margin: '0',
                    minHeight: '50px'
                  }}
                  options={districtList?.length > 0 ? districtList : [{ "district_name": "No data found" }]}
                  getOptionLabel={(option) => option.district_name}
                  onChange={(event, value) => {
                    handleDistrict(value)
                  }}
                  renderInput={(params) => <TextField
                    variant='standard'
                    {...params} color="black" InputLabelProps={{
                      style: {
                        paddingTop: '5px'
                      }
                    }} label="Select Districts" />}
                /> */}

                <Field name="state_id">
                  {({ field }) => (
                    <Autocomplete
                      size="small"
                      disablePortal
                      className="hide"
                      id="combo-box-demo"
                      sx={{
                        backgroundColor: "#ffffff",
                        borderRadius: "5px",
                        width: "100%",
                        margin: "0",
                        minHeight: "50px",
                      }}
                      // value={field.state_id}
                      value={editState ? selectedValues : field.state_id}
                      defaultValue={field.state_id == "" ? "" : field.state_id}
                      options={
                        stateList?.length > 0
                          ? stateList
                          : [{ state_name: "No data found" }]
                      }
                      getOptionLabel={(option) => option.state_name}
                      onChange={(event, value) => {
                        setFieldValue(field.name, value?._id);
                        handleStateDistrict(value);
                        setSelectedValues(value);
                        setDistrictval(null);
                        setTalukval(null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          color="black"
                          InputLabelProps={{
                            style: {
                              paddingTop: "5px",
                            },
                          }}
                          label="Select State"
                        />
                      )}
                    />
                  )}
                </Field>

                <Field name="district_id">
                  {({ field }) => (
                    <Autocomplete
                      size="small"
                      disablePortal
                      className="hide"
                      id="combo-box-demo"
                      sx={{
                        backgroundColor: "#ffffff",
                        borderRadius: "5px",
                        width: "100%",
                        margin: "0",
                        minHeight: "50px",
                      }}
                      value={editState ? districtVal : field.district_id}
                      defaultValue={
                        field.district_id == "" ? "" : field.district_id
                      }
                      options={
                        districtList?.length > 0
                          ? districtList
                          : [{ district_name: "No data found" }]
                      }
                      getOptionLabel={(option) => option.district_name}
                      onChange={(event, value) => {
                        setFieldValue(field.name, value?._id);
                        handleDistrict(value);
                        setDistrictval(value);
                        setTalukval(null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          color="black"
                          InputLabelProps={{
                            style: {
                              paddingTop: "5px",
                            },
                          }}
                          label="Select Districts"
                        />
                      )}
                    />
                  )}
                </Field>

                <Field name="taluk_id">
                  {({ field }) => (
                    <Autocomplete
                      size="small"
                      disablePortal
                      className="hide"
                      id="combo-box-demo"
                      // value={talukVal}
                      sx={{
                        backgroundColor: "#ffffff",
                        borderRadius: "5px",
                        width: "100%",
                        margin: "0",
                        minHeight: "50px",
                      }}
                      value={editState ? talukVal : field.taluk_id}
                      options={
                        talukList?.length > 0
                          ? talukList
                          : [{ taluk_name: "No data found" }]
                      }
                      getOptionLabel={(option) => option.taluk_name}
                      onChange={(event, value) => {
                        setFieldValue(field.name, value?._id);
                        setTalukval(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          color="black"
                          InputLabelProps={{
                            style: {
                              paddingTop: "5px",
                            },
                          }}
                          label="Select SubDivison"
                        />
                      )}
                    />
                  )}
                </Field>

                <HybridInput
                  label="Municipal Coperation / Gram Panchayat"
                  name="mandal_name"
                  fullWidth
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  type="text"
                  variant="standard"
                  setVal={setFieldValue}
                  disabled={blockValue.length > 0 ? true : false}
                  onChange={(e) => {
                    handleMandal(e);
                  }}
                />
                {/* <HybridInput
                  label="Block Name"
                  name="block_name"
                  fullWidth
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                  type="text"
                  variant="standard"
                  setVal={setFieldValue}
                  disabled={mandalValue.length > 0 ? true : false}
                  onChange={(e) => { handleBlock(e) }}
                /> */}
                <HybridSelect
                  label="Status"
                  name="status"
                  fullWidth
                  type="text"
                  setVal={setFieldValue}
                  variant="standard"
                  options={DropDown}
                  dropKey="status"
                  dropVal="_id"
                />
                <div className="submit">
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      type="button"
                      color="primary"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      onSubmit={handleSubmit}
                    >
                      {editState ? "Update" : "Add"}
                      {/* {changeValue ? "Update Data" : "Add Data"} */}
                    </Button>
                  </Stack>
                </div>
              </CardContent>
            </Form>
          )}
        </Formik>
        {/* <DialogTitle>State details</DialogTitle>
        <DialogContent>
          <FormControl fullWidth className="mt-1">
            <TextField
              label="Standard"
              variant="standard"
              name="state_name"
              type="text"
              value={data.state_name}
              onChange={(event) => handleChange(event)}
            />
          </FormControl>
          <HybridInput
            label="State details"
            name="state_name"
            type="text"
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};
