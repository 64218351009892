import React from "react";

import Gsi from "../../GsiCbbfLink/Home";
import CotaractHome from "../Cotaract/CotaractHome";

import { Box, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router";

import PublicIcon from "@mui/icons-material/Public";
import CabinIcon from "@mui/icons-material/Cabin";

const GsiHome = (props) => {
  const { Cotaract, InduvidualScreen } = props;

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/gis_report");
  };
  const handleCBBFNavigate = () => {
    navigate("/masters/cbbfdashboard");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        height: "100%",
        "& > :not(style)": { width: "80%" },
      }}
    >
      <div style={{ height: "50%", width: "100%" }}>
        <p style={{ margin: "10px", textAlign: "start", fontWeight: "bold" }}>
          Cataract Identified (Gender Wise){" "}
        </p>
        <CotaractHome Cotaract={Cotaract} InduvidualScreen={InduvidualScreen} />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          height: "45%",
          justifyContent: "space-evenly",
        }}
      >
        <Paper
          elevation={15}
          className="performance-content"
          style={{
            backgroundColor: "#92D050",
            width: "45%",
            display: "flex",
            flexDirection: "column",
          }}
          onClick={handleNavigate}
        >
          <p style={{ color: "#fff" }}>GIS Report </p>
          <PublicIcon style={{ fontSize: "48px", fill: "#fff" }} />
        </Paper>
        <Paper
          elevation={15}
          className="performance-content"
          style={{
            backgroundColor: "#00B0F0",
            width: "45%",
            display: "flex",
            flexDirection: "column",
          }}
          onClick={handleCBBFNavigate}
        >
          {/* <p style={{ color: '#fff' }}>CBBF Dashboard   </p> */}
          <p style={{ color: "#fff" }}>Volunteers Screening Dashboard </p>
          <CabinIcon style={{ fontSize: "48px", fill: "#fff" }} />
        </Paper>
      </div>
    </Box>
  );
};

export default GsiHome;
