import Axios from "axios";
import { useAuth } from "./useAuth";
import { toast, Slide } from "react-toastify";
import { useNavigate } from "react-router-dom";
export const useAxios = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";

  if (!token) {
    navigate("/");
  }
  const axios = Axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      // "Access-Control-Allow-Private-Network": "true",
    },
  });

  axios.interceptors.request.use(
    function (request) {
      return request;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    function (response) {
      if (response.data.internalStatus == 1) {
        toast.success(response.data.message, {
          autoClose: 1500,
          transition: Slide,
        });
      }
      return response;
    },
    function (error) {
      console.log(error, "error");
      // if (error.response.data.internalStatus == 1) {
      //   toast.error(error.response.data.message, {
      //     autoClose: 1500,
      //     transition: Slide,
      //   });
      // }
      return Promise.reject(error);
    }
  );
  return { axios };
};
