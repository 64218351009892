// react lifecylce hooks
import { useState, useEffect } from "react";

// mui components
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  TextField,
  Grid,
  FormControl,
  Autocomplete,
  Select,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";

// formik
import { useFormik } from "formik";
import { projectValidationSchema } from "../../src/validations/userSchema";

// Custom components
import TableComponent from "../components/TableLayout";
import LowerCase from "./LowerCase";

// Custom hooks
import { useAxios } from "../hooks/useAxios";

// toast
import { toast, Slide } from "react-toastify";

// Component Mounts
export default function ProjectManagement() {
  // constansts
  // Table headers
  const header = [
    { id: 1, label: "Project Name", value: "project_name" },
    { id: 2, label: "Status", value: "status" },
    { id: 3, label: "Action", value: "action" },
  ];

  // initial form state
  const iniProjFormValues = {
    project_name: "",
    status: "active",
    units: [],
    districts: [],
    targetCount: "",
  };

  // axios interceptors
  const { axios } = useAxios();

  // state management
  const paginationInfo = { page: 1, count: 0, rowsPerPage: 10 };
  const [projectList, setProjectList] = useState([]);
  const [length, setLength] = useState();
  const [pageInfo, setPageInfo] = useState(paginationInfo);
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [iniFormState, setIniFormState] = useState(iniProjFormValues);
  const [iniUnits, setIniUnits] = useState([]);
  const [unitsSelected, setUnitsSelected] = useState([]);
  const [unitsDis, setUnitsDis] = useState([]);
  const [iniDistricts, setIniDistricts] = useState([]);
  const [districtsSelected, setDistrictsSelected] = useState([]);
  const [iniDrop, setIniDrop] = useState(false);
  const [editState, setEditState] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [projAdded, setProjAdded] = useState(false);
  const [projUpdated, setProjUpdated] = useState(false);
  const [disOptions, setDisOptions] = useState([]);

  // projectlist side effects
  useEffect(() => {
    async function callFunc() {
      try {
        const response = await axios.get(`projectList`, {
          params: {
            limit: pageInfo.rowsPerPage,
            page: pageInfo.page,
            search: searchValue,
          },
        });
        console.log("project list response", response);
        setProjectList(response?.data?.result?.populatedProjects);
        setLength(
          response?.data?.result?.length ? response?.data?.result?.length : 0
        );
        setPageInfo((prevState) => ({
          ...prevState,
          count: response?.data?.result?.length
            ? response?.data?.result?.length
            : 0,
        }));
      } catch (error) {
        toast.error("Something Went Wrong", {
          autoClose: 1500,
          transition: Slide,
        });
        setLength(0);
        setPageInfo(paginationInfo);
      }
    }
    callFunc();
  }, [
    pageInfo.rowsPerPage,
    pageInfo.page,
    searchValue,
    projAdded,
    projUpdated,
  ]);

  // unitsdropdown side effects
  useEffect(() => {
    async function calFunc() {
      try {
        const response = await axios.post(`AllHospitalDropDown`);
        console.log("units list response", response);
        if (response?.data?.success) {
          if (response?.data?.result) {
            const { data } = response;
            let result = LowerCase(data?.result, "hospital_name");
            setIniUnits(result);
          }
        }
      } catch (error) {}
    }
    calFunc();
  }, [iniDrop]);

  // update form state on edit action
  useEffect(() => {
    setValues(iniFormState);
  }, [iniFormState]);

  // Units Based district api
  const callDistrictApi = async (hospitalId, unitsName) => {
    let ini = [];
    let toHandlerReqStruc = [];
    for (let i = 0; i <= hospitalId.length - 1; i++) {
      let arrayValue = hospitalId[i];
      let params = {
        hospital_id: arrayValue,
      };
      let response = await axios.post(`HospitalBasedDistrictDropDown`, params);
      console.log("hospital_based_district_dropdown response", response);
      let result = LowerCase(
        response?.data?.result[0].district,
        "district_name"
      );
      ini.push(...result);

      const newArr = result.map(({ district_id, district_name }) => ({
        district_id,
        district_name,
      }));
      toHandlerReqStruc.push({
        hospital_id: hospitalId[i],
        unit_name: unitsName[i],
        district: newArr,
      });
    }
    setIniDistricts(ini);
    setIniFormState((prevState) => ({
      ...prevState,
      units: toHandlerReqStruc,
    }));
  };

  console.log("unitsSelected", unitsSelected);

  // formik form management
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: iniFormState,
    validationSchema: projectValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log("hit onSubmit");
      try {
        // units based districts dropdown (multi-select payload - udated one)
        const finalFormState = {
          project_name: values.project_name,
          status: values.status,
          project_target: values.targetCount,
          units: iniFormState.units.map((units) => ({
            unit_name: units.unit_name,
            hospital_id: units.hospital_id,
            district: units.district.filter((districts) =>
              districtsSelected.some(
                (selected) => selected.district_id == districts.district_id
              )
            ),
          })),
        };

        // units independent dropdown - old one
        /* const finalFormState = {
          project_name: values.project_name,
          status: values.status,
          units: unitsSelected.map(({ unit_id: hospital_id, ...rest }) => ({
            hospital_id,
            ...rest,
          })),
        }; */

        console.log("finalFormState Payload", finalFormState);

        if (editState) {
          const response = await axios.patch(
            `updateProject/${projectId}`,
            finalFormState
          );
          console.log("updateProject response", response);
          if (response?.data?.success) {
            resetForm();
            setProjUpdated(true);
            setEditState(false);
            setOpen(false);
          } else {
            toast.warning(response?.message, {
              autoClose: 1500,
              transition: Slide,
            });
          }
        } else {
          const response = await axios.post(`projecCreate`, finalFormState);
          console.log("projecCreate response", response);

          if (response?.data?.success) {
            resetForm();
            setOpen(false);
            setProjAdded(true);
          } else {
            toast.warning(response?.message, {
              autoClose: 1500,
              transition: Slide,
            });
          }
        }
      } catch (error) {
        toast.error("Something Went Wrong", {
          autoClose: 1500,
          transition: Slide,
        });
      }
    },
  });

  // handle project name
  const handleInput = (event) => {
    setIniFormState((prevState) => ({
      ...prevState,
      project_name: event.target.value,
    }));
  };

  // handle Status
  const handleStatus = (event) => {
    setIniFormState((prevState) => ({
      ...prevState,
      status: event.target.value,
    }));
  };

  // add project handler
  const projectUnitsHandler = () => {
    setOpen(true);
    setIniDrop(true);
    setProjAdded(false);
    setIniFormState(iniProjFormValues);
    setUnitsSelected([]);
    setUnitsDis([]);
    setIniDistricts([]);
    setDistrictsSelected([]);
    setEditState(false);
    setDisOptions([]);
  };

  // project util eventHandler
  const projectHandler = () => {
    setOpen((prevState) => !prevState);
    setIniFormState(iniProjFormValues);
    setUnitsSelected([]);
    setUnitsDis([]);
    setIniDistricts([]);
    setDistrictsSelected([]);
    setEditState(false);
  };

  // prevent backDrop of dialog
  const projectHandlerPrevent = (reason) => {
    if (reason == "escapeKeyDown" || reason == "backdropClick") {
      setOpen(true);
    }
  };

  // pagination handlers
  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };
  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };

  // edit data handlers
  const editData = (id) => {
    setEditState(true);
    setProjUpdated(false);
    setProjectId(id);
    try {
      const currentProject = projectList.find(({ _id }) => _id == id);
      const {
        _id,
        project_name,
        project_target: targetCount,
        units,
        status,
      } = currentProject;
      const unitsS = units.map(
        ({ unit_id, unit_name: hospital_name, hospital_id: _id }) => ({
          unit_id,
          hospital_name,
          _id,
        })
      );
      const districtS = units.flatMap((unit) => unit?.district);

      setIniFormState((prevState) => ({
        ...prevState,
        project_name,
        units,
        districts: districtS,
        status,
        targetCount,
      }));

      const hospitalId = units.map(({ hospital_id }) => {
        return hospital_id;
      });
      const unitsName = units.map(({ unit_name }) => {
        return unit_name;
      });

      callDistrictApi(hospitalId, unitsName);
      setUnitsDis(unitsS);
      setDistrictsSelected(districtS);
      setDisOptions(districtS);
      setOpen(true);
    } catch (error) {
      toast.error("Something Went Wrong", {
        autoClose: 1500,
        transition: Slide,
      });
    }
  };

  // delete data handler - no use as of now
  const deleteDate = () => {};

  // search handlers
  const handleSearchValue = (values) => {
    setPageInfo(paginationInfo);
    setSearchValue(values);
  };

  // unit handlers
  const handleUnits = (value) => {
    let units = value.map(({ _id: unit_id, hospital_name: unit_name }) => ({
      unit_id,
      unit_name,
    }));
    setUnitsSelected(units);
    let unitsId = value.map(({ _id }) => {
      return _id;
    });
    let unitsName = value.map(({ hospital_name }) => {
      return hospital_name;
    });
    callDistrictApi(unitsId, unitsName);
  };

  // districts handler
  const handleDistricts = (value) => {
    let districts = value.map(({ _id: district_id, ...data }) => ({
      district_id,
      ...data,
    }));
    setDistrictsSelected(districts);
  };

  // units selected to disable from main dropdown
  const getOptionDisabled = (option, unitsDis) =>
    unitsDis.some(
      (selectedOption) => selectedOption.hospital_name === option.hospital_name
    );

  // districts selected to disable from main dropdown
  const getOptionDisabledDis = (option, disOptions) =>
    disOptions.some(
      (disOptions) => disOptions.district_name === option.district_name
    );

  console.log("setIniFormState", iniFormState);
  console.log("errors useformik", errors);

  return (
    <>
      <div className="state_head">
        <Typography variant="h5" color="secondary" className="text-left">
          Project Management
        </Typography>
        {
          <Button
            variant="contained"
            sx={{ float: "right", m: 1, width: "10ch" }}
            onClick={() => projectUnitsHandler()}
          >
            Add
          </Button>
        }
      </div>
      <TableComponent
        header={header}
        datalist={projectList}
        datalength={length}
        actions={[]}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        handleTablePage={handleTablePage}
        handleTableRowsPerPage={handleTableRowsPerPage}
        editData={(id) => editData(id)}
        deleteData={(id) => deleteDate(id)}
        searchDataValue={(val) => handleSearchValue(val)}
        searchValue={() => {
          setSearchValue("");
          setPageInfo(paginationInfo);
        }}
      ></TableComponent>

      <Dialog open={open} fullWidth onClose={projectHandlerPrevent}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>
            {editState ? "Edit Project" : "Add Project"}
          </DialogTitle>
          <DialogTitle>
            <CloseIcon onClick={projectHandler} />
          </DialogTitle>
        </div>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              type="text"
              name="project_name"
              onBlur={handleBlur}
              onChange={handleChange}
              onKeyUp={handleInput}
              value={values.project_name}
              id="select-label project_name"
              variant="standard"
              label="Project Name"
              size="small"
              fullWidth
              error={errors?.project_name && touched?.project_name}
              helperText={
                errors?.project_name && touched?.project_name
                  ? errors?.project_name
                  : ""
              }
            />
            <Grid
              sx={{ marginTop: "2%" }}
              container
              rowSpacing={2}
              columnSpacing={2}
            >
              <Grid item xs={12}>
                <Autocomplete
                  required
                  multiple
                  id="tags-standard"
                  name="units"
                  options={iniUnits}
                  getOptionLabel={(option) => option.hospital_name}
                  value={unitsDis}
                  getOptionDisabled={(option) =>
                    getOptionDisabled(option, unitsDis)
                  }
                  onChange={(data, value) => {
                    handleUnits(value);
                    setUnitsDis(value);
                  }}
                  defaultValue={[]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      /* label="Unit Name" */
                      label="Hospital Name"
                      placeholder=""
                    />
                  )}
                />
                {errors?.units && touched?.units ? (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.units}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  required
                  multiple
                  id="tags-standard"
                  name="districts"
                  options={iniDistricts}
                  getOptionLabel={(option) => option?.district_name}
                  onChange={(data, value) => {
                    handleDistricts(value);
                    setFieldValue("districts", value);
                    setDisOptions(value);
                  }}
                  value={disOptions}
                  getOptionDisabled={(option) =>
                    getOptionDisabledDis(
                      option,
                      disOptions.length > 0 ? disOptions : []
                    )
                  }
                  defaultValue={[]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="District Name"
                      placeholder=""
                    />
                  )}
                />
                {errors?.districts && touched?.districts ? (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.districts}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  name="targetCount"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.targetCount}
                  id="select-label targetCount"
                  variant="standard"
                  label="Target Count"
                  size="small"
                  InputProps={{ inputProps: { minlength: 0, maxlength: 7 } }}
                  fullWidth
                  error={errors?.targetCount && touched?.targetCount}
                  helperText={
                    errors?.targetCount && touched?.targetCount
                      ? errors?.targetCount
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Status
                  </InputLabel>
                  <Select
                    name="status"
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={values.status}
                    onChange={handleStatus}
                    label="Status"
                    disabled={!editState ? true : false}
                  >
                    <MenuItem value={"active"}>Active</MenuItem>
                    <MenuItem value={"inactive"}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <div className="submit">
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  type="button"
                  color="primary"
                  onClick={projectHandler}
                >
                  Close
                </Button>
                <Button color="primary" type="submit" variant="contained">
                  {editState ? "Edit Project" : "Add Project"}
                </Button>
              </Stack>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
