import React, { useState, useEffect } from "react";
import TableComponent from "../components/TableLayout";
import { useAxios } from "../hooks/useAxios";
import "react-toastify/dist/ReactToastify.css";
import {
  Typography,
  Button,
  Table,
  TableHead,
  FormControl,
  Select,
  MenuItem,
  Input,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelDownload from "../components/ExcelFormat/ExcelFileDownload";

import { dates } from "../index.js";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

import moment from "moment";

const renderInputs = (props, placeholder, classname) => (
  <Input
    sx={{
      width: "80%",
      input: {
        "&::placeholder": {
          // <----- Add this.
          opacity: 1,
          // marginBottom: '-5px',
          paddingLeft: "10px",
          fontSize: "13px",
          color: "#000000",
        },
        width: "100%",
      },
      backgroundColor: "#ffffff",
      // borderRadius: '5px',
      // maxHeight: '50px'
    }}
    type="text"
    className={classname}
    inputRef={props.inputRef}
    // inputProps={props.inputProps}
    inputProps={{
      ...props.inputProps,
      placeholder: placeholder,
    }}
    value={props.value}
    onClick={props.onClick}
    onChange={props.onChange}
    endAdornment={props.InputProps?.endAdornment}
  />
);

const Attendance = () => {
  const [header] = useState([
    {
      id: 1,
      /* label: "CBBF Staff Name", */
      label: "Volunteers Screening Staff Name",
      value: "field_worker_name",
    },
    {
      id: 2,
      label: "Place Name",
      value: "punch_place",
    },
    {
      id: 3,
      label: "Hospital Name",
      value: "hospital_name",
    },
    {
      id: 4,
      label: "District Name",
      value: "district_name",
    },
    {
      id: 5,
      label: "Taluk Name",
      value: "taluk_name",
    },
    {
      id: 6,
      label: "Device ID",
      value: "device_id",
    },
    {
      id: 7,
      label: "Punch In Time",
      value: "punch_in",
    },
    {
      id: 8,
      label: "Punch Out Time",
      value: "punch_out",
    },
    {
      id: 9,
      label: "Total Hours",
      value: "hours",
    },
    {
      id: 10,
      label: "Total Hours",
      value: "hours",
    },
    {
      id: 11,
      label: "Total Screening Target",
      value: "total_screening_target",
    },
    {
      id: 12,
      label: "Total Screening Done",
      value: "total_screening_done",
    },
    {
      id: 13,
      label: "Percentage Done",
      value: "percentage_done",
    },
  ]);
  const { axios } = useAxios();
  const [attendanceList, setAttendanceList] = useState([]);
  const [length, setLength] = useState();
  let paginationInfo = {
    page: 1,
    count: 0,
    rowsPerPage: 10,
  };
  const [pageInfo, setPageInfo] = useState(paginationInfo);
  let dateFields = ["created_on"];
  const [searchValue, setSearchValue] = React.useState({
    fieldworker: "",
    hospital: "",
  });
  const [fieldWorkerDate, setFieldWorkerDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [reset, setReset] = React.useState(false);
  const [downloadTab, setDownloadTab] = useState(false);
  const [dateSearch, setDateSearch] = useState("");
  const [filterValue, setFilterValue] = useState({
    fieldworker: "",
    hospital: "",
    startDate: "",
    endDate: "",
  });

  useEffect(
    (values) => {
      AttendanceList();
    },
    [pageInfo.rowsPerPage, pageInfo.page, reset, filterValue]
  );
  const handleSearchValue = (values, field) => {
    /* if (field == "hospital") {
      setSearchValue({
        ...searchValue,
        hospital: values,
      });
    } else {
      setSearchValue({
        ...searchValue,
        fieldworker: values,
      });
    } */

    setFilterValue({ ...values });
  };
  const filterResponse = () => {
    setPageInfo((prevState) => ({ ...prevState, page: 1 }));
    if (pageInfo.page === 1) {
      AttendanceList();
    }
  };
  const AttendanceList = () => {
    let id = 5;
    let fromDate = fieldWorkerDate?.startDate
      ? moment(fieldWorkerDate?.startDate).format("YYYY-MM-DD")
      : "";
    let toDate = fieldWorkerDate?.endDate
      ? moment(fieldWorkerDate?.endDate).format("YYYY-MM-DD")
      : "";
    axios
      .post(
        // `AttendanceList?limit=${pageInfo.rowsPerPage}&getcbbf=${id}&page=${pageInfo.page}&fromDate=${fromDate}&toDate=${toDate}&field_worker_name=${searchValue?.fieldworker}&hospital_name=${searchValue?.hospital}`
        `AttendanceList?limit=${pageInfo.rowsPerPage}&getcbbf=${id}&page=${pageInfo.page}&fromDate=${filterValue.startDate}&toDate=${filterValue.endDate}&field_worker_name=${filterValue?.fieldworker}&hospital_name=${filterValue?.hospital}`
      )

      .then((data) => {
        setAttendanceList(data.data.result.list);
        setLength(data.data.result.length);
        setPageInfo((prevState) => ({
          ...prevState,
          count: data.data.result.length,
        }));
      })
      .catch(() => {
        setPageInfo(paginationInfo);
        setLength(0);
        setAttendanceList([]);
      });
  };
  const editData = (data) => {};
  const deleteDate = (data) => {};
  function deleteCancel(params) {}
  function deleteSubmit(params) {}
  const handleTablePage = (page) => {
    setPageInfo((prevState) => ({ ...prevState, page: page + 1 }));
  };
  const handleTableRowsPerPage = (rows) => {
    setPageInfo((prevState) => ({ ...prevState, rowsPerPage: rows }));
  };
  const handleDates = (value, field) => {
    if (field == "start") {
      var date = `${value.$y}-${value.$M + 1}-${value.$D}`;
      setFieldWorkerDate({
        ...fieldWorkerDate,
        startDate: date,
      });
    } else {
      var date = `${value.$y}-${value.$M + 1}-${value.$D}`;
      setFieldWorkerDate({
        ...fieldWorkerDate,
        endDate: date,
      });
    }
  };
  function clearValues() {
    setPageInfo(paginationInfo);
    setFieldWorkerDate({
      ...fieldWorkerDate,
      startDate: "",
      endDate: "",
    });
    setSearchValue({
      ...searchValue,
      hospital: "",
      fieldworker: "",
    });
    setReset(!reset);
  }

  // excel handler
  const handleuploadExcel = async () => {
    // let fromDate = fieldWorkerDate?.startDate
    //   ? moment(fieldWorkerDate?.startDate).format("YYYY-MM-DD")
    //   : "";
    // let toDate = fieldWorkerDate?.endDate
    //   ? moment(fieldWorkerDate?.endDate).format("YYYY-MM-DD")
    //   : "";
    let id = 5;
    try {
      await axios
        .post(
          `AttendanceList?limit=&page=1&fromDate=${searchValue.eventStart}&toDate=${searchValue.eventEnd}&getcbbf=${id}&field_worker_name=${filterValue?.fieldworker}&hospital_name=${filterValue?.hospital}`
        )
        .then((res) => {
          var value = res.data.result.list;

          console.log("val", value);
          let data = value.map((data) => {
            let roles = {
              3: "VHV",
              4: "Volunteers",
              5: "Optometrists",
              6: "Campsite Registration",
            };
            let fwType = roles[data?.role_id] || "";
            return {
              ...data,
              created_on: data.created_on.slice(0, 10),
              date: data.date.slice(0, 10),
              // punch_in: data.punch_in.slice(0, 10),
              // punch_out: data.punch_out.slice(0, 10),
              hospital_name:
                data.hospital_name.charAt(0).toUpperCase() +
                data.hospital_name.slice(1),
              field_worker_type: fwType,
            };
          });

          let newArrayOfObj = data.map(
            ({
              field_worker_name: FieldworkerName,
              field_worker_type: FieldWorkerType,
              hospital_name: HospitalName,
              district_name: DistrictName,
              taluk_name: TalukName,
              device_id: DeviceID,
              punch_in: PunchInTime,
              punch_place: PlaceName,
              punch_out: PunchOutTime,
              punch_out_place: PunchOutPlace,
              hours: TotalWorkingHours,
              remarks: Remarks,
              total_screening_target: TotalScreeningTarget,
              total_screening_done: TotalScreeningDone,
              percentage_done: PercentageDone,
            }) => ({
              HospitalName,
              FieldworkerName,
              FieldWorkerType,
              DistrictName,
              TalukName,
              DeviceID,
              PunchInTime,
              PlaceName,
              PunchOutTime,
              PunchOutPlace,
              TotalWorkingHours,
              Remarks,
              TotalScreeningTarget,
              TotalScreeningDone,
              PercentageDone,
            })
          );
          const newArr = newArrayOfObj.map((obj) => {
            const newObj = { ...obj };
            delete newObj["created_on"];
            delete newObj["current_latitude"];
            delete newObj["current_longitude"];
            delete newObj["field_worker_id"];
            delete newObj["hospital_id"];
            delete newObj["hourDifference"];
            delete newObj["role_id"];
            delete newObj["__v"];
            delete newObj["_id"];

            return newObj;
          });
          ExcelDownload(newArr, "VolunteersFieldworkersAttandance");
        });
    } catch (error) {}
  };

  // excel dates handler
  function handleExcelDates(val) {
    const today = new Date(); // Get the current date
    setDateSearch(val);

    // Format the dates as "DD-MM-YYYY"
    var dateFormat = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    if (val == "last_week") {
      //==============last week ======================

      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7); // Subtract 7 days to get the date for last week

      // Find the start date (Monday) of last week
      const startOfWeek = new Date(lastWeek);
      startOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7));

      // Find the end date (Sunday) of last week
      const endOfWeek = new Date(lastWeek);
      endOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7) + 6);

      // Format the dates
      var formattedStartOfWeek = startOfWeek.toISOString().split("T")[0];
      var formattedEndOfWeek = endOfWeek.toISOString().split("T")[0];

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartOfWeek,
        eventEnd: formattedEndOfWeek,
      }));
    } else if (val == "last_month") {
      //================last month ===============================
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

      // Set the day to 1 to get the first day of the previous month
      lastMonth.setDate(2);

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: lastMonth.toISOString().split("T")[0],
        eventEnd: moment().date(0).toISOString().split("T")[0],
      }));
    } else if (val == "last_3_month") {
      //================last month ===============================

      const threeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        1
      );

      // Set the day to 1 to get the first day of three months ago
      threeMonthsAgo.setDate(1);

      const lastDayOfThreeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );

      const formattedStartDate = threeMonthsAgo.toLocaleDateString("en-IN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "Asia/Kolkata",
      });
      const formattedEndDate = lastDayOfThreeMonthsAgo.toLocaleDateString(
        "en-IN",
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone: "Asia/Kolkata",
        }
      );

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
        eventEnd: formattedEndDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
      }));
    } else if (val == "all") {
      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: "",
        eventEnd: "",
      }));
    }
  }

  return (
    <>
      <div className="state_head">
        <Typography
          align="center"
          style={{ fontSize: "2rem", display: "flex" }}
        >
          {/* CBBF - Fieldworker’s Attendance */}
          Volunteers Screening - Fieldworker’s Attendance
        </Typography>

        <Button
          variant="contained"
          sx={{ m: 1, width: "28ch", textTransform: "capitalize" }}
          startIcon={<FileDownloadIcon />}
          // onClick={handleuploadExcel}
          onClick={() => setDownloadTab((k) => !k)}
        >
          Download Attendance
        </Button>
      </div>{" "}
      {/* excel popup */}
      {downloadTab && (
        <div className="tabbackground">
          <div
            className={dateSearch == "custom" ? "nonreports" : "nonreports1"}
          >
            <div className="table">
              <Table>
                <TableHead>
                  <tr>
                    {/* <th><Checkbox
                      onChange={(e) => {
                        handleSelectAll(e);
                      }}
                    />
                      Select all</th> */}
                    <th> </th>
                    <th>Select Duration </th>
                  </tr>
                </TableHead>
              </Table>
              <div className="filter">
                <FormControl style={{ width: "80%" }} size="small">
                  <Select
                    labelId="select-label"
                    id="select"
                    variant="standard"
                    label="Select Dates"
                    value={dateSearch}
                    onChange={(e) => {
                      handleExcelDates(e.target.value);
                    }}
                  >
                    {dates?.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {dateSearch == "custom" && (
                  <div style={{ width: "100%" }} className="custom">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="from_date"
                        value={
                          searchValue.eventStart == ""
                            ? null
                            : searchValue.eventStart
                        }
                        inputFormat="DD/MM/YYYY"
                        onChange={(e) => {
                          const selectedDate = e.$d;
                          if (selectedDate) {
                            setSearchValue({
                              ...searchValue,
                              eventStart: selectedDate,
                            });
                          } else {
                            setSearchValue({
                              ...searchValue,
                              eventStart: null,
                            });
                          }
                        }}
                        slotProps={{
                          actionBar: {
                            actions: ["clear"],
                          },
                        }}
                        renderInput={(props) =>
                          renderInputs(props, "From date", "date")
                        }
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="from_date"
                        value={
                          searchValue.eventEnd == ""
                            ? null
                            : searchValue.eventEnd
                        }
                        inputFormat="DD/MM/YYYY"
                        onChange={(e) => {
                          setSearchValue({ ...searchValue, eventEnd: e.$d });
                        }}
                        renderInput={(props) =>
                          renderInputs(props, "To date", "date")
                        }
                      />
                    </LocalizationProvider>
                  </div>
                )}
              </div>
            </div>
            <div className="downloadoptions">
              <Button
                variant="contained"
                onClick={() => {
                  setDownloadTab((k) => !k);
                  setDateSearch("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={dateSearch == ""}
                onClick={() => handleuploadExcel()}
              >
                Download
              </Button>{" "}
              {/*onClick={handleuploadExcel}*/}
            </div>
          </div>
        </div>
      )}
      <TableComponent
        header={header}
        // addList={addStates}
        reset={reset}
        datewiseSearch={true}
        date={fieldWorkerDate}
        handleDate={(val, field) => handleDates(val, field)}
        datalist={attendanceList}
        datalength={length}
        editData={(id) => editData(id)}
        handleClearDate={() => clearValues()}
        handleFieldDate={() => filterResponse()}
        actions={[]}
        deleteData={(id) => deleteDate(id)}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        handleTablePage={handleTablePage}
        searchDataValue={(val, field) => handleSearchValue(val, field)}
        handleTableRowsPerPage={handleTableRowsPerPage}
        dateFields={dateFields}
      ></TableComponent>
    </>
  );
};

export default Attendance;
