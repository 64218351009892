import React, { useEffect, useState } from "react";
import "./Style.scss";

// backdrop component
import BackDrop from "../../components/BackDrop.js";

/*---- Components imports ----------- */
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useAxios } from "../../hooks/useAxios";
import format from "date-fns/format";
import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelDownload from "../../components/ExcelFormat/ExcelFileDownload";
import moment from "moment";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
// import dates from '../../index.js'

import { dates } from "../../index";

import {
  Table,
  makeStyles,
  FormControl,
  TableBody,
  TableCell,
  InputLabel,
  Input,
  Select,
  MenuItem,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";

import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridFooter,
  GridToolbarFilterButton,
  GridFooterContainer,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Field } from "formik";
import PatientReportFilter from "../../components/Filter/PatientReportFilter";

const head = [
  { field: "lineNo", headerName: "S.No", isChecked: false },
  { field: "ALL", headerName: "ALL", isChecked: false },
  { field: "hospital_name", headerName: "Hospital Name", isChecked: false },
  { field: "month", headerName: "Month", isChecked: false },
  // { field: 'camp_date', headerName: 'Camp Date', width: 170, headerClassName: 'super-app-theme--header' },
  { field: "camp_date", headerName: "Camp Date", isChecked: false },
  { field: "created_on", headerName: "Entry Date", isChecked: false },
  // { field: 'camp_id', headerName: 'Camp Id', width: 170, headerClassName: 'super-app-theme--header' },
  { field: "camp_place", headerName: "Camp Place", isChecked: false },
  { field: "camp_number", headerName: "Camp Number", isChecked: false },
  {
    field: "field_worker",
    headerName: "Fieldworker Name",
    isChecked: false,
  },
  // { field: 'id', headerName: 'Patient Id', width: 160, headerClassName: 'super-app-theme--header' },
  // { field: 'otherCamp_present_count', headerName: 'Previous Camp Member', width: 160, headerClassName: 'super-app-theme--header' },
  { field: "patient_name", headerName: "Patient Name", isChecked: false },
  { field: "patient_age", headerName: "Age", isChecked: false },
  { field: "patient_sex", headerName: "Gender", isChecked: false },
  { field: "door_name", headerName: "Door Name", isChecked: false },
  { field: "occupation", headerName: "Occupation", isChecked: false },
  { field: "patient_phone", headerName: "Phone Number", isChecked: false },
  { field: "district_name", headerName: "District", isChecked: false },
  {
    field: "taluk_name",
    headerName: "Subdivision/Taluk/Mandal/Tehsil/Circle/Block",
    isChecked: false,
  },
  {
    field: "mandal_name",
    headerName: "Municipal Corporation/ Village/ Gram Panchayat",
    isChecked: false,
  },
  { field: "village_name", headerName: "Ward/Village", isChecked: false },
  {
    field: "patient_latitude",
    headerName: "Patient Latitude",
    isChecked: false,
  },
  {
    field: "patient_longitude",
    headerName: "Patient Langitude",
    isChecked: false,
  },
  { field: "id_proof", headerName: "ID Proof ", isChecked: false },
  { field: "id_proof_number", headerName: "ID Proof Number", isChecked: false },
  { field: "illness", headerName: "Systemic Illness", isChecked: false },
  {
    field: "presenting_left_eye",
    headerName: "Distant Vison [ Left Eye ]",
    isChecked: false,
  },
  {
    field: "presenting_right_eye",
    headerName: "Distant Vison [ Right Eye ]",
    isChecked: false,
  },
  {
    field: "ewl_history_of_surgery",
    headerName: "History of Surgery [ Left Eye ]",
    isChecked: false,
  },
  {
    field: "ewr_history_of_surgery",
    headerName: "History of Surgery [ Right Eye ]",
    isChecked: false,
  },
  {
    field: "ewl_hospital_name",
    headerName: "Surgery Hospital [ Left Eye ]",
    isChecked: false,
  },
  {
    field: "ewr_hospital_name",
    headerName: "Surgery Hospital [ Right Eye ]",
    isChecked: false,
  },
  {
    field: "ewl_complaints",
    headerName: "Complaints [ Left Eye ]",
    isChecked: false,
  },
  {
    field: "ewr_complaints",
    headerName: "Complaints [ Right Eye ]",
    isChecked: false,
  },
  {
    field: "ewl_provisional_diagnosis",
    headerName: "Provisional Diagnosis [ Left Eye ]",
    isChecked: false,
  },
  {
    field: "ewr_provisional_diagnosis",
    headerName: "Provisional Diagnosis [ Right Eye ]",
    isChecked: false,
  },
  /* {
    field: "tll_eye_position",
    headerName: "Torch Light Examination Eye Position [ Left Eye ]",
    isChecked: false,
  },
  {
    field: "tlr_eye_position",
    headerName: "Torch Light Examination Eye Position [ Right Eye ]",
    isChecked: false,
  },
  {
    field: "tll_congestion",
    headerName: "Torch Light Examination Congestion [ Left Eye ]",
    isChecked: false,
  },
  {
    field: "tlr_congestion",
    headerName: "Torch Light Examination Congestion [ Right Eye ]",
    isChecked: false,
  },
  {
    field: "tll_lens",
    headerName: "Torch Light Examination Lens [ Left Eye ]",
    isChecked: false,
  },
  {
    field: "tlr_lens",
    headerName: "Torch Light Examination Lens [ Right Eye ]",
    isChecked: false,
  }, */
  {
    field: "referral_remark",
    headerName: "Referral Remarks",
    isChecked: false,
  },
  { field: "forward_type", headerName: "Referral Type", isChecked: false },
  {
    field: "referral_number",
    headerName: "Patient ID",
    isChecked: false,
  },
  // { field: 'follow_up', headerName: 'Follow Up', isChecked: false },
  {
    field: "Attendance_check",
    headerName: "Patient Reported",
    isChecked: false,
  },
  {
    field: "surgery_status",
    headerName: "Patient Selected For Surgery Status",
    isChecked: false,
  },
  {
    field: "medix_surgery_status",
    headerName: "Surgery Status",
    isChecked: false,
  },
  {
    field: "otherCamp_present_count",
    headerName: "OtherCamp Present Count",
    isChecked: false,
  },
];

const renderInputs = (props, placeholder, classname) => (
  <Input
    sx={{
      width: "100%",
      input: {
        "&::placeholder": {
          // <----- Add this.
          opacity: 1,
          // marginBottom: '-5px',
          paddingLeft: "10px",
          fontSize: "13px",
          color: "#000000",
        },
        width: "100%",
      },
      backgroundColor: "#ffffff",
      // borderRadius: '5px',
      // maxHeight: '50px'
    }}
    type="text"
    className={classname}
    inputRef={props.inputRef}
    // inputProps={props.inputProps}
    inputProps={{
      ...props.inputProps,
      placeholder: placeholder,
    }}
    value={props.value}
    onClick={props.onClick}
    onChange={props.onChange}
    endAdornment={props.InputProps?.endAdornment}
  />
);

const PatientsReports = () => {
  const dateFormat = process.env.REACT_APP_DATE_FORMAT;
  function CustomToolBar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarContainer />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  function CustomFooter() {
    return (
      <GridFooterContainer>
        {/* Add what you want here */}
        <GridFooter
          sx={{
            border: "none", // To delete double border.
          }}
        />
      </GridFooterContainer>
    );
  }

  const { axios } = useAxios();

  // loader state
  const [backDropOpen, setBackDropOpen] = React.useState(false);

  const [rows, setRows] = React.useState([]);
  const [downloadTab, setDownloadTab] = useState(false);
  const [value, setValue] = useState([]);
  const [headerValue, setHeaderValue] = useState(head);
  const [dateSearch, setDateSearch] = useState("");
  const [searchdates, setSearchDates] = useState({
    fromDate: "",
    toDate: "",
  });

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 0,
  });

  const [length, setLength] = React.useState(0);

  const [filterValue, setFilterValue] = React.useState({
    campStart: "",
    campEnd: "",
    hospitalName: "",
    name: "",
    camp_num: "",
    camp_place: "",
  });

  const [searchValue, setSearchValue] = React.useState({
    eventStart: "",
    eventEnd: "",
  });

  const columns = [
    {
      field: "lineNo",
      headerName: "S.No",
      flex: 0,
      editable: false,
      renderCell: (params: GridRenderCellParams<DatasetEntryEntity>) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "hospital_name",
      headerName: "Hospital Name",
      width: 180,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "month",
      headerName: "Month",
      width: 180,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    // { field: 'camp_date', headerName: 'Camp Date', width: 170, headerClassName: 'super-app-theme--header' },
    {
      field: "camp_date",
      headerName: "Camp Date",
      width: 170,
      valueFormatter: (params) => moment(params?.value).format("MM/DD/YYYY"),
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "created_on",
      headerName: "Entry Date",
      width: 170,
      valueFormatter: (params) => moment(params?.value).format("MM/DD/YYYY"),
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    // { field: 'camp_id', headerName: 'Camp Id', width: 170, headerClassName: 'super-app-theme--header' },
    {
      field: "camp_place",
      headerName: "Camp Place",
      width: 160,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "camp_number",
      headerName: "Camp Number",
      width: 130,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "field_worker",
      headerName: "Fieldworker Name",
      width: 160,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    // { field: 'id', headerName: 'Patient Id', width: 160, headerClassName: 'super-app-theme--header' },
    // { field: 'otherCamp_present_count', headerName: 'Previous Camp Member', width: 160, headerClassName: 'super-app-theme--header' },
    {
      field: "patient_name",
      headerName: "Patient Name",
      width: 220,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "patient_age",
      headerName: "Age",
      width: 120,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "patient_sex",
      headerName: "Gender",
      width: 120,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "door_name",
      headerName: "Door Name",
      width: 100,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "occupation",
      headerName: "Occupation",
      width: 100,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "patient_phone",
      headerName: "Phone Number",
      width: 130,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "district_name",
      headerName: "District",
      width: 130,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "taluk_name",
      headerName: "Subdivision/Taluk/Mandal/Tehsil/Circle/Block",
      width: 200,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "mandal_name",
      headerName: "Municipal Corporation/ Village/ Gram Panchayat",
      width: 160,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "village_name",
      headerName: "Ward/Village",
      width: 160,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "patient_latitude",
      headerName: "Patient Latitude",
      width: 160,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "patient_longitude",
      headerName: "Patient Langitude",
      width: 160,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "id_proof",
      headerName: "ID Proof ",
      width: 160,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "id_proof_number",
      headerName: "ID Proof Number",
      width: 160,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "illness",
      headerName: "Systemic Illness",
      width: 160,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "presenting_left_eye",
      headerName: "Distant Vison [ Left Eye ]",
      width: 200,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "presenting_right_eye",
      headerName: "Distant Vison [ Right Eye ]",
      width: 200,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "ewl_history_of_surgery",
      headerName: "History of Surgery [ Left Eye ]",
      width: 220,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "ewr_history_of_surgery",
      headerName: "History of Surgery [ Right Eye ]",
      width: 220,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "ewl_hospital_name",
      headerName: "Surgery Hospital [ Left Eye ]",
      width: 200,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "ewr_hospital_name",
      headerName: "Surgery Hospital [ Right Eye ]",
      width: 200,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "ewl_complaints",
      headerName: "Complaints [ Left Eye ]",
      width: 200,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "ewr_complaints",
      headerName: "Complaints [ Right Eye ]",
      width: 200,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "ewl_provisional_diagnosis",
      headerName: "Provisional Diagnosis [ Left Eye ]",
      width: 240,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "ewr_provisional_diagnosis",
      headerName: "Provisional Diagnosis [ Right Eye ]",
      width: 240,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    /* {
      field: "tll_eye_position",
      headerName: "Torch Light Examination Eye Position [ Left Eye ]",
      width: 260,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "tlr_eye_position",
      headerName: "Torch Light Examination Eye Position [ Right Eye ]",
      width: 260,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "tll_congestion",
      headerName: "Torch Light Examination Congestion [ Left Eye ]",
      width: 260,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "tlr_congestion",
      headerName: "Torch Light Examination Congestion [ Right Eye ]",
      width: 260,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "tll_lens",
      headerName: "Torch Light Examination Lens [ Left Eye ]",
      width: 260,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "tlr_lens",
      headerName: "Torch Light Examination Lens [ Right Eye ]",
      width: 260,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    }, */
    {
      field: "referral_remark",
      headerName: "Referral Remarks",
      width: 160,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "forward_type",
      headerName: "Referral Type",
      width: 160,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "referral_number",
      headerName: "Patient ID",
      width: 160,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    // { field: 'follow_up', headerName: 'Follow Up', width: 160, headerClassName: 'super-app-theme--header', isChecked: false },
    {
      field: "Attendance_check",
      headerName: "Patient Reported",
      width: 160,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "surgery_status",
      headerName: "Patient Selected For Surgery Status",
      width: 160,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "medix_surgery_status",
      headerName: "Surgery Status",
      width: 160,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
    {
      field: "otherCamp_present_count",
      headerName: "OtherCamp Present Count",
      width: 180,
      headerClassName: "super-app-theme--header",
      isChecked: false,
    },
  ];

  // const dates = [
  //   { id: 1, value: 'last_week', label: 'Last Week' },
  //   { id: 2, value: 'last_month', label: 'Last Month' },
  //   { id: 3, value: 'last_3_month', label: 'Last 3 Month' },
  //   { id: 4, value: 'custom', label: 'Custom' },
  // ]

  /*------------------ Rows Value ------------------------*/
  // useEffect(() => {
  //   console.log(searchvalue);
  //   axios.post(`PatientReport?fromDate=${searchvalue.campStart}&toDate=${searchvalue.campEnd}&hospital_name=${searchvalue.hospitalName}&field_worker_name=${searchvalue.name}&camp_number=${searchvalue.camp_num}&camp_place=${searchvalue.camp_place}&limit=${paginationModel.pageSize}&page=${paginationModel.page}`).then((res) => {
  //     console.log(res, "res");
  //     console.log('reslength', res.data.result);

  //     setPaginationModel((prevState) => ({
  //       ...prevState,
  //       rowCount: res?.data?.result?.length,
  //     }));

  //     let newArrayOfObj = res.data.result.list.map(({ patient_id: id, ...rest }) => ({ id, ...rest }));
  //     let LatittudeOne = newArrayOfObj.map((data) => { return { ...data, patient_latitude: data.patient_latitude.slice(0, 10) } })
  //     let LongitutdeOne = LatittudeOne.map((data) => { return { ...data, patient_longitude: data.patient_longitude.slice(0, 10) } })
  //     let campDate = LongitutdeOne.map((data) => { return { ...data, camp_date: data.camp_date.slice(0, 10) } })
  //     setRows(campDate)
  //   })

  // }, [searchvalue])

  useEffect(() => {
    fetchData();
  }, [paginationModel, filterValue]); //searchvalue

  const fetchData = async () => {
    try {
      const res = await axios.post(
        `PatientReport?fromDate=${filterValue.campStart}&toDate=${
          filterValue.campEnd
        }&hospital_name=${filterValue.hospitalName}&field_worker_name=${
          filterValue.name
        }&camp_number=${filterValue.camp_num}&camp_place=${
          filterValue.camp_place
        }&limit=${paginationModel.pageSize}&page=${paginationModel.page + 1}`
      );
      const totalCount = res?.data?.result?.length || 0;
      if (totalCount == 0) {
        setRows([]);
        setLength(totalCount);
      } else {
        setLength(totalCount);
        let newArrayOfObj = res.data.result.list.map(
          ({ patient_id: id, field_worker, created_on, mandal, ...rest }) => ({
            id,
            field_worker: field_worker
              ?.map(({ field_worker_name }) => field_worker_name)
              .toString(),
            mandal_name: mandal
              ?.map(({ mandal_name }) => mandal_name)
              .toString(),
            month: moment(created_on).format("MMM"),
            ...rest,
          })
        );
        let LatittudeOne = newArrayOfObj.map((data) => {
          return {
            ...data,
            patient_latitude: data.patient_latitude.slice(0, 10),
          };
        });
        let LongitutdeOne = LatittudeOne.map((data) => {
          return {
            ...data,
            patient_longitude: data.patient_longitude.slice(0, 10),
          };
        });
        let campDate = LongitutdeOne.map((data) => {
          return { ...data, camp_date: data.camp_date.slice(0, 10) };
        });

        const surgery_ = campDate.map((dat) => {
          return {
            ...dat,
            medix_surgery_status:
              dat?.surgery_status === "Surgery Done" ? "Surgery Done" : "NO",
            surgery_status:
              dat?.surgery_status == "Surgery Done"
                ? "YES"
                : dat?.surgery_status,
          };
        });
        setRows(surgery_);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function firstLetter(s) {
    return s?.replace(/^.{1}/g, s[0].toUpperCase());
  }

  function handleDates(val) {
    const today = new Date(); // Get the current date
    setDateSearch(val);

    // Format the dates as "DD-MM-YYYY"
    var dateFormat = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    if (val == "last_week") {
      //==============last week ======================

      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7); // Subtract 7 days to get the date for last week

      // Find the start date (Monday) of last week
      const startOfWeek = new Date(lastWeek);
      startOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7));

      // Find the end date (Sunday) of last week
      const endOfWeek = new Date(lastWeek);
      endOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7) + 6);

      // Format the dates
      var formattedStartOfWeek = startOfWeek.toISOString().split("T")[0];
      var formattedEndOfWeek = endOfWeek.toISOString().split("T")[0];

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartOfWeek,
        eventEnd: formattedEndOfWeek,
      }));
    } else if (val == "last_month") {
      //================last month ===============================
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

      // Set the day to 1 to get the first day of the previous month
      lastMonth.setDate(2);

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: lastMonth.toISOString().split("T")[0],
        eventEnd: moment().date(0).toISOString().split("T")[0],
      }));
    } else if (val == "last_3_month") {
      //================last month ===============================

      const threeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        1
      );

      // Set the day to 1 to get the first day of three months ago
      threeMonthsAgo.setDate(1);

      const lastDayOfThreeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );

      const formattedStartDate = threeMonthsAgo.toLocaleDateString("en-IN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "Asia/Kolkata",
      });
      const formattedEndDate = lastDayOfThreeMonthsAgo.toLocaleDateString(
        "en-IN",
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone: "Asia/Kolkata",
        }
      );

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
        eventEnd: formattedEndDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
      }));
    } else if (val == "all") {
      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: "",
        eventEnd: "",
      }));
    }
  }

  const handleuploadExcel = async () => {
    setBackDropOpen(true);
    const res = await axios.post(
      `PatientReport?fromDate=${searchValue.eventStart}&toDate=${
        searchValue.eventEnd
      }&hospital_name=${filterValue.hospitalName}&field_worker_name=${
        filterValue.name
      }&camp_number=${filterValue.camp_num}&camp_place=${
        filterValue.camp_place
      }&limit=&page=${1}`
    );

    const row = res?.data?.result?.list?.map((x) => {
      return {
        ...x,
        hospital_name: firstLetter(x.hospital_name),
        // camp_date: format(new Date(x.camp_date), 'yyyy-MM-dd'),
        camp_date: x.camp_date.slice(0, 10),
        // created_on: format(new Date(x.created_on), 'yyyy-MM-dd'),
        created_on: x.created_on.slice(0, 10),
        medix_surgery_status:
          x?.surgery_status === "Surgery Done" ? "Surgery Done" : "NO",
        surgery_status:
          x?.surgery_status == "Surgery Done" ? "YES" : x?.surgery_status,
        field_worker: x?.field_worker
          ?.map(({ field_worker_name }) => field_worker_name)
          .toString(),
        mandal_name: x?.mandal
          ?.map(({ mandal_name }) => mandal_name)
          .toString(),
        month: moment(x?.created_on).format("MMM"),
      };
    });

    if (value.length > 1) {
      value.sort((x, y) => x.position - y.position);
    }

    var result = [];

    row?.map((x) => {
      var data = {};
      value?.map((i) => {
        if ([i.header] == "Referral Card No") {
          data["Patient ID"] = x[i.value];
        } else {
          data[i.header] = x[i.value];
        }
      });
      result.push(data);
    });
    ExcelDownload(result, "PatientsReport");
    setDownloadTab((o) => !o);
    setDateSearch("");
    setValue([]);
    setBackDropOpen(false);
  };

  const filteredObjectArray = (val) => {
    setFilterValue({ ...val });
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  function alterScreenName(name) {
    var menu_name = name.replaceAll("_", " ");
    var name = menu_name?.replace(/^.{1}/g, menu_name[0].toUpperCase());
    return name;
  }

  const handleChange = (e, val, position, headerName) => {
    const index = value?.findIndex((x) => x.value == val);

    // const ind = headerValue

    // setHeaderValue((x) => {
    //   {
    //     ...x,
    //     isChecked : false
    //   }

    // })

    if (e.target.checked) {
      if (index < 0) {
        if (headerName == "ALL") {
          let valueReference = [];
          head.forEach((data, index) => {
            data.isChecked = true;
            var values = {
              position: index,
              value: data?.field,
              header: data?.headerName,
            };
            valueReference.push(values);
            console.log("valueReference", valueReference);
            let sliceData = valueReference.slice(2);
            setValue(sliceData);
          });
          console.log("value", value);
        } else {
          head[position].isChecked = true;
          setValue(() => {
            var values = {
              position: position,
              value: val,
              header: headerName,
            };
            var data = { ...value, values };
            console.log("value------", value);
            return Object.values(data);
          });
        }
      } else {
        // value.splice(index, 1);
        if (headerName == "ALL") {
          head.forEach((data, index) => {
            data.isChecked = false;
          });
          setValue([]);
        } else {
          head[position].isChecked = false;
          value.splice(index, 1);
          setHeaderValue(head);
        }
      }
    } else {
      // value.splice(index, 1);
      if (headerName == "ALL") {
        head.forEach((data, index) => {
          data.isChecked = false;
        });
        setValue([]);
      } else {
        let headReference = [...head];
        headReference[position].isChecked = false;
        value.splice(index, 1);
        setHeaderValue(headReference);
      }
    }
  };

  // function handleSelectAll(e) {
  //   if (e.target.checked) {
  //     setHeaderValue((x) => {

  //       return x?.map((c) => {
  //         return {
  //           ...c,
  //           isChecked: true
  //         }
  //       })
  //     })
  //   } else {
  //     setHeaderValue((x) => {

  //       return x?.map((c) => {
  //         return {
  //           ...c,
  //           isChecked: false
  //         }
  //       })
  //     })
  //   }
  // }

  return (
    <div className="main-report">
      <div>
        <p
          style={{
            fontSize: "1.7rem",
            fontWeight: "500",
            display: "flex",
            margin: "5px 0",
          }}
        >
          Patient Report{" "}
        </p>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <PatientReportFilter filterObject={(val) => filteredObjectArray(val)} />
        <Button
          variant="contained"
          startIcon={<FileDownloadIcon />}
          onClick={() => {
            head.forEach((data) => {
              data.isChecked = false;
            });
            setSearchValue({
              eventStart: "",
              eventEnd: "",
            });
            setDownloadTab((k) => !k);
          }}
          disabled={rows?.length == 0}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "11%",
            marginRight: "1%",
            textTransform: "capitalize",
          }}
        >
          Excel Download
        </Button>{" "}
        {/*onClick={handleuploadExcel}*/}
      </div>
      <div style={{ marginBottom: "1%" }}></div>

      {downloadTab && (
        <div className="tabbackground">
          <div className="downloadtab">
            <div className="table">
              <Table>
                <TableHead>
                  <tr>
                    {/* <th><Checkbox
                      onChange={(e) => {
                        handleSelectAll(e);
                      }}
                    />
                      Select all</th> */}
                    <th> </th>
                    <th>Columns </th>
                  </tr>
                </TableHead>
              </Table>
              <div className="filter">
                <FormControl style={{ width: "90%" }} size="small">
                  <Select
                    labelId="select-label"
                    id="select"
                    variant="standard"
                    label="Select Dates"
                    value={dateSearch}
                    onChange={(e) => {
                      handleDates(e.target.value);
                    }}
                  >
                    {dates?.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {dateSearch == "custom" && (
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "45%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="from_date"
                          value={
                            searchValue.eventStart == ""
                              ? null
                              : searchValue.eventStart
                          }
                          inputFormat="DD/MM/YYYY"
                          onChange={(e) => {
                            const selectedDate = e.$d;
                            if (selectedDate) {
                              setSearchValue({
                                ...searchValue,
                                eventStart: selectedDate,
                              });
                            } else {
                              setSearchValue({
                                ...searchValue,
                                eventStart: null,
                              });
                            }
                          }}
                          slotProps={{
                            actionBar: {
                              actions: ["clear"],
                            },
                          }}
                          renderInput={(props) =>
                            renderInputs(props, "From date", "date")
                          }
                        />
                      </LocalizationProvider>
                    </div>
                    <div style={{ width: "45%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="from_date"
                          value={
                            searchValue.eventEnd == ""
                              ? null
                              : searchValue.eventEnd
                          }
                          inputFormat="DD/MM/YYYY"
                          onChange={(e) => {
                            setSearchValue({ ...searchValue, eventEnd: e.$d });
                          }}
                          renderInput={(props) =>
                            renderInputs(props, "To date", "date")
                          }
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                )}
              </div>
              <Table>
                <TableBody>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  {headerValue?.map((head, index) => {
                    if (head.headerName !== "S no") {
                      return (
                        <TableRow key={index}>
                          <TableCell sx={{ textAlign: "center", width: "25%" }}>
                            <Checkbox
                              checked={head.isChecked}
                              onChange={(e) => {
                                handleChange(
                                  e,
                                  head.field,
                                  index,
                                  head.headerName
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {alterScreenName(head?.headerName)}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </div>
            <div className="downloadoptions">
              <Button
                variant="contained"
                onClick={() => {
                  setDownloadTab((k) => !k);
                  setDateSearch("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={dateSearch == "" || value.length == 0}
                onClick={() => handleuploadExcel()}
              >
                Download
              </Button>{" "}
              {/*onClick={handleuploadExcel}*/}
            </div>
          </div>
        </div>
      )}

      <div className="table" style={{ marginTop: "-0.5%" }}>
        <DataGrid
          style={{ height: "75vh", width: "95vw", overflow: "auto" }}
          className="table-pagination"
          rowCount={length}
          page={paginationModel.page}
          sx={{
            overflowX: "scroll",
            boxShadow: 2,
            border: 2,
            textTransform: "capitalize",
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .super-app-theme--header": {
              backgroundColor: "LightBlue",
            },
          }}
          autoHeight
          pageSizeOptions={[25, 50, 100]}
          rows={rows}
          columns={columns}
          slots={{
            toolbar: CustomToolBar,
          }}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          disableDensitySelector
          initialState={{
            columns: {
              columnVisibilityModel: {
                hospital_name: true,
                camp_date: true,
                camp_created_on: false,
                created_on: false,
                campPlace: false,
                camp_number: true,
                field_worker: true,
                // id: true,
                patient_name: true,
                patient_age: false,
                patient_sex: false,
                door_name: false,
                patient_phone: true,
                district_name: false,
                taluk_name: false,
                mandal_name: false,
                village_name: false,
                patient_latitude: true,
                patient_longitude: true,
                id_proof: false,
                id_proof_number: false,
                illness: false,
                presenting_left_eye: false,
                presenting_right_eye: false,
                ewl_history_of_surgery: false,
                ewr_history_of_surgery: false,
                ewl_hospital_name: false,
                ewr_hospital_name: false,
                SHRightEye: false,
                ewr_complaints: false,
                ewl_complaints: false,
                ewl_provisional_diagnosis: false,
                ewr_provisional_diagnosis: false,
                // tll_eye_position: false,
                // tlr_eye_position: false,
                // tll_congestion: false,
                // tlr_congestion: false,
                // tll_lens: false,
                // tlr_lens: false,
                referral_remark: false,
                referral_type: true,
                referral_number: true,
                status: false,
                // follow_up: true,
                surgeryStatus: true,
                Medixsurgery_status: false,
              },
            },
          }}
        />
      </div>

      {/* backdrop */}
      <BackDrop open={backDropOpen} />
    </div>
  );
};

export default PatientsReports;
