import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Masters from "./layouts/masters";
import theme from "./assets/js_theme/theme";
import { ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";

/*---------------Login Routes --------------------*/
import LoginPage from "./pages/LoginPage";
import ForgotPassword from "./pages/forgot_password";
import ResetPassword from "./pages/reset_password";
import AuthLayout from "./layouts/auth";
import PageNotFound from "./components/404_page";
import OptionalRoutes from "./protectedRoutes/optionalRoutes";

/*------ Dashboard  ----------- */
import CbbfDashboard from "./pages/DashboardLayout/CbbfDashboard";
import DashboardLayout from "./pages/DashboardLayout/Home";
/*------- Adminsitrative  Route -------------- */
import { StatesManagement } from "./pages/states_management";
import { DistrictManagement } from "./pages/district_management";
import Subdivison from "./pages/Subdivison";
import { Council } from "./pages/Council";
import { VillageManagement } from "./pages/village_management";
/*----------------Admin Routes --------------------*/
import AdminManagement from "./pages/admin_management";
import MobileUserManagement from "./pages/mobileUser_management";
import Roles_management from "./pages/roles_management";
/*------------------Camp Routes --------------------*/
import CampManagement from "./pages/camp_management";
import Camp_performance from "./pages/camp_performance";
/*------------------- CBBF Camp --------------*/
import Cbbf_camp from "./pages/cbbf_camp";
import Cbbf_attendance from "./pages/cbbf_attendance";
import CbbfPatient_management from "./pages/CbbfPatientDetails";
import CBBFOptem from "./pages/cbbf_optem.js";
/*---------------------Manage Routes ---------------------*/
import { ManageHospitals } from "./pages/manage_hospitals";
import { EventManagement } from "./pages/event_management";
import Attendance from "../src/pages/attendance";
import HospitalManagement from "./pages/hospital_management";
// Project Creation
import ProjectManagement from "./pages/project_management.js";
// Organisation
import Organisation from "./pages/Organisation.js";
import ReferralTypeManagement from "./pages/referal_type_management";
import Patient_management from "./pages/patient_management";
import VisionCenter_performance from "./pages/visionCenter_performance";
/*--------  Reports ----------- */
import Gis_report from "./pages/gis_report";
import Gis_reports from "./pages/gis_report_new";
import PatientReports from "./pages/Reports/PatientsReports";
import FieldWorkersReports from "./pages/Reports/FieldWorkerReport";

// vs reports
import SurveyReport from "./pages/Reports/CbbfReport/CbbfSurveyReport";
import OptemReport from "./pages/Reports/CbbfReport/CbbfOptemReport";
import MasterReport from "./pages/Reports/CbbfReport/MasterReport.js";
import UnitWisePerformance from "./pages/Reports/UnitWisePerformance.js";
import HistoricalTrend from "./pages/Reports/HistoricalTrend.js";
import GisSummary from "./pages/Reports/GisSummary.js";

// import {data} from './data.js'

// const unique = data
//      .map(e => e['VendorCode'])
//      .map((e, i, final) => final.indexOf(e) === i && i)
//      .filter(obj=> data[obj])
//      .map(e => data[e]);

//      const newval = unique
//      .map(e => e['GSTIN'])
//      .map((e, i, final) => final.indexOf(e) === i && i)
//      .filter(obj=> unique[obj])
//      .map(e => unique[e]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route element={<OptionalRoutes />}>
              <Route path="/" element={<AuthLayout />}>
                <Route path="" element={<LoginPage />} />
                <Route path="forgot_password" element={<ForgotPassword />} />
                <Route
                  path="reset_password/:token"
                  element={<ResetPassword />}
                />
              </Route>
            </Route>

            {localStorage.getItem("token") && (
              <>
                <Route path="masters" element={<Masters />}>
                  <Route path="" element={<DashboardLayout />} />
                  <Route path="cbbfdashboard" element={<CbbfDashboard />} />
                  <Route exact path="state" element={<StatesManagement />} />
                  <Route path="district" element={<DistrictManagement />} />
                  <Route path="council" element={<Council />} />
                  <Route path="subdivison" element={<Subdivison />} />
                  <Route path="village" element={<VillageManagement />} />
                  <Route
                    path="mobile_user_management"
                    element={<MobileUserManagement />}
                  />
                  <Route
                    path="hospital_management"
                    element={<HospitalManagement />}
                  />
                  {/* Project Creation */}
                  <Route
                    path="project_management"
                    element={<ProjectManagement />}
                  />
                  {/* Organisation */}
                  <Route path="organisation" element={<Organisation />} />
                  <Route
                    path="campPerformance"
                    element={<Camp_performance />}
                  />
                  <Route
                    path="visionCenterPerformance"
                    element={<VisionCenter_performance />}
                  />
                  <Route
                    path="manage_hospitals"
                    element={<ManageHospitals />}
                  />
                  <Route
                    path="referral_type"
                    element={<ReferralTypeManagement />}
                  />
                  <Route path="camp_management" element={<CampManagement />} />
                  <Route
                    path="admin_management"
                    element={<AdminManagement />}
                  />
                  <Route path="roles" element={<Roles_management />} />
                  <Route path="attendance" element={<Attendance />} />
                  <Route
                    path="patient_management"
                    element={<Patient_management />}
                  />
                  {/* <Route path="gis_report" element={<Gis_reports />} /> */}
                  <Route
                    path="cbbf_patient_management"
                    element={<CbbfPatient_management />}
                  />
                  <Route path="cbbf_camp" element={<Cbbf_camp />} />
                  <Route path="cbbf_attendance" element={<Cbbf_attendance />} />
                  <Route
                    path="event_management"
                    element={<EventManagement />}
                  />
                  <Route path="patient_report" element={<PatientReports />} />
                  <Route
                    path="fieldworker_report"
                    element={<FieldWorkersReports />}
                  />
                  <Route path="optem_report" element={<OptemReport />} />
                  <Route path="survey_report" element={<SurveyReport />} />
                  <Route path="cbbf_optem" element={<CBBFOptem />} />
                  <Route
                    path="volunteers_master_report"
                    element={<MasterReport />}
                  />
                  <Route
                    path="unitwise_performance"
                    element={<UnitWisePerformance />}
                  />
                  <Route
                    path="historical_trend"
                    element={<HistoricalTrend />}
                  />
                  <Route path="gis_summary" element={<GisSummary />} />
                </Route>
                {/* <Route path="gis_report" element={<Gis_report />} /> */}
                <Route path="gis_report" element={<Gis_report />} />
              </>
            )}

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
